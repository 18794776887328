import { createContext, useContext, useEffect, useState } from 'react'
import { node } from 'prop-types'
import { matchPath, useLocation } from 'react-router-dom'
import { filterObject } from 'utils'
import { useUser } from '../userManagement/userProvider/UserProvider'
import { useRoutes } from './RoutesProvider'

DatiCondivisiTraRotteProvider.propTypes = {
  children: node.isRequired,
}

const DatiCondivisiTraRotteContext = createContext()

function DatiCondivisiTraRotteProvider(props) {
  const { children } = props

  const { getPath } = useRoutes()

  /***** Gestione salvataggio dati condivisi (get, set, elimina) *****/

  const [datiCondivisi, setDatiCondivisi] = useState({})
  
  function getDatoCondiviso(chiave) {
    return datiCondivisi[chiave]?.valore
  }

  function setDatoCondiviso(chiave, valore, options = {}) {
    const {
      chiaveRottaPadreInCuiMantenere,
      pathRottaPadreInCuiMantenere: pathRottaPadreInCuiMantenere_PassatoDaFuori,
    } = options

    let pathRottaPadreInCuiMantenere
    if (pathRottaPadreInCuiMantenere_PassatoDaFuori) {
      pathRottaPadreInCuiMantenere = pathRottaPadreInCuiMantenere_PassatoDaFuori
    } else if (chiaveRottaPadreInCuiMantenere) {
      pathRottaPadreInCuiMantenere = getPath(chiaveRottaPadreInCuiMantenere)
    }

    setDatiCondivisi(dati => ({
      ...dati,
      [chiave]: {
        valore,
        options: { pathRottaPadreInCuiMantenere }
      }
    }))
  }

  function eliminaDatoCondiviso(chiave) {
    setDatiCondivisi(dati => {
      const {
        [chiave]: datoDaEliminare,
        ...restDati
      } = dati
      return restDati
    })
  }

  /*******************************************************************/

  /***** Eventuale eliminazione dati al cambio rotta *****/

  const { pathname: urlCorrente } = useLocation()

  useEffect(() => {
    setDatiCondivisi(dati =>
      filterObject(dati, ([, { options }]) => {
        const { pathRottaPadreInCuiMantenere } = options
        if (!pathRottaPadreInCuiMantenere) return true
        const match = matchPath(urlCorrente, { path: pathRottaPadreInCuiMantenere })
        // Se match = null, vuol dire che l'urlCorrente non è una sottorotta
        // di pathRottaPadreInCuiMantenere. Quindi non mantengo i dati
        return Boolean(match)
      })
    )
  }, [urlCorrente])

  /*******************************************************/

  /***** Eliminazione dati al cambio autenticazione *****/

  const { comandoScelto, isUserLoggato } = useUser()
  const isUserLoggato_Boolean = isUserLoggato()
  
  useEffect(() => {
    setDatiCondivisi({})
  }, [isUserLoggato_Boolean, comandoScelto])
  
  /******************************************************/
  
  const contextValue = {
    getDatoCondiviso,
    setDatoCondiviso,
    eliminaDatoCondiviso
  }

  return (
    <DatiCondivisiTraRotteContext.Provider value={contextValue}>
      {children}
    </DatiCondivisiTraRotteContext.Provider>
  )
}

function useDatiCondivisiTraRotte() {
  return useContext(DatiCondivisiTraRotteContext)
}

export {
  DatiCondivisiTraRotteProvider,
  useDatiCondivisiTraRotte
}