import { useEffect } from 'react'
import { string, bool, object, func, node } from 'prop-types'
import { BaseButtons } from 'inputs'
import { BaseModal, useModal } from 'layout'
import { useEntita } from './EntitaContext'
import { usePin } from './PinProvider'

BottoneCreaEntita.propTypes = {
  nomeEntita: string.isRequired,
  path: string.isRequired,
  creazioneInLista: bool,
  opzioniCreazione: object,
  onCreazioneCompletata: func,
  disabilitaCreazioneAutomatica: bool,
  contenutoSottoBottone: node,

  renderModalCreazione: func,
  titoloModalCreazione: string,
  propsModalCreazione: object
}

export default function BottoneCreaEntita(props) {
  const {
    nomeEntita,
    path,
    creazioneInLista = true,
    opzioniCreazione,
    onCreazioneCompletata,
    disabilitaCreazioneAutomatica,
    contenutoSottoBottone,

    renderModalCreazione,
    titoloModalCreazione,
    propsModalCreazione,
    
    ...restProps
  } = props

  /* Chiamata alla logica di creazione */

  const {
    gestioneModifica: { creaEntita, mostraInfoDebug },
    gestionePermessi: { readOnly }
  } = useEntita()

  const path_DaMandareAlServer = (creazioneInLista && !path.endsWith('[]'))
    ? path.addIndex('')
    : path

  // Calcola il path completo usato solo per le ottimizzazioni sulla coda
  function calcolaPathCreazioneCompleto(entitaAppenaCreata) {
    // Nel caso della creazione di una proprietà normale (non in lista),
    // il path completo è uguale al path da mandare al server
    if (!creazioneInLista) return path_DaMandareAlServer

    // Nel caso della creazione di un elemento in una lista, il path da mandare
    // al server finisce con le [] e non contiene l'uuid dell'entità creata
    // Nella maggioranza dei casi l'entità creata ha un uuid che posso inserire
    const uuid = entitaAppenaCreata.uuid
    if (uuid) return path.addUuid(uuid)
    
    // Se non c'è l'uuid, serve overridare questa funzione per far andare le ottimizzazioni
    console.warn(
      'calcolaPathCreazioneCompleto: l\'entità creata non ha l\'uuid:',
      entitaAppenaCreata,
      'Passare al BottoneCreaEntita un oggetto "opzioniCreazione" con una funzione "calcolaPathCreazioneCompleto" affinché le ottimizzazioni sulla coda funzionino correttamente'
    )
    return path_DaMandareAlServer
  }

  function effettuaCreazione(opzioniAggiuntive) {
    creaEntita(nomeEntita, path_DaMandareAlServer, {
      onCreazioneCompletata,
      calcolaPathCreazioneCompleto,
      ...opzioniCreazione,
      ...opzioniAggiuntive
    })
  }

  /*************************************/

  /* Interazione con l'utente e gestione modal creazione */

  const { modalRef, openModal, closeModal } = useModal()

  function iniziaCreazione() {
    if (renderModalCreazione) openModal()
    else effettuaCreazione()
  }

  function effettuaCreazione_EChiudiModal(entitaDaFondere, altreOpzioni = {}) {
    effettuaCreazione({ entitaDaFondere, ...altreOpzioni })
    // Senza il setTimeout, in qualche situazione stramba
    // non funzionava la chiusura automatica della modal
    setTimeout(() => closeModal())
  }

  const funzioniModalCreazione = {
    onCancel: closeModal,
    onSubmit: effettuaCreazione_EChiudiModal
  }

  const { pathCreazione, setPathCreazione } = usePin()

  useEffect(() => {
    if (!disabilitaCreazioneAutomatica && pathCreazione === path) {
      iniziaCreazione()
      setPathCreazione(null)
    }
  }, [pathCreazione])

  /*******************************************************/

  return (
    <>
      <BaseButtons.Aggiungi
        onClick={iniziaCreazione}
        color='success'
        noMarginY
        disabled={readOnly}
        {...(mostraInfoDebug && {
          title: `DEBUG: ${nomeEntita} - ${path_DaMandareAlServer}`
        })}
        {...restProps}
      />

      {contenutoSottoBottone}

      {renderModalCreazione &&
        <BaseModal
          ref={modalRef}
          titolo={titoloModalCreazione || restProps.testo}
          {...propsModalCreazione}
        >
          {renderModalCreazione(funzioniModalCreazione)}
        </BaseModal>
      }
    </>
  )
}