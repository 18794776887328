import { node, object, oneOf, string } from 'prop-types'
import { Avatar } from '@mui/material'
import { BaseTooltip } from 'feedback'
import useRichiestaServer from '../network/RichiestaServerHook'
import { useUser } from './userProvider/UserProvider'
import { nomeCompleto } from './utilUserManagement'

UserAvatar.propTypes = {
  dimensione: oneOf(['small', 'medium', 'large', 'extra-small', 'extra-large']),
  labelUser: string,
  token: string,
  url: string,
  //nickname: string,
  userInfo: object,
  color: string,
  sx: object,
  style: object,
  children: node
}

export default function UserAvatar(props) {
  const {
    dimensione = 'medium',
    color,
    sx = {},
    style = {},
    user,
    ...rest
  } = props

  const { userAvatarSrc } = useRichiestaServer()
  const { userInfo } = useUser()
  const currentUserInfo = user ?? userInfo

  const { avatar, info } = currentUserInfo
  const { nickname } = info
  if (!nickname) return null

  const labelUser = nomeCompleto(currentUserInfo)
  const completeName = labelUser ? (labelUser.indexOf(' ') >= 0 ? labelUser : labelUser.split('').join(' ')) : nickname.split('').join(' ')
  const dimensionConf = {
    'extra-small': { width: 12, height: 12, fontSize: '6px' },
    'small': { width: 24, height: 24, fontSize: '14px' },
    'medium': { width: 36, height: 36, fontSize: '16px' },
    'large': { width: 48, height: 48, fontSize: '24px' },
    'extra-large': { width: 96, height: 96, fontSize: '55px' },
  }

  const { sx: sxAvatar, avatarLetters } = stringAvatar(completeName)

  return (
    <div style={style}>
      <BaseTooltip contenuto={labelUser ?? nickname}>
        <Avatar
          {...rest}
          sx={{
            ...{ fontFamily: '\'Lucida Console\', Monaco, monospace' },
            ...sxAvatar,
            ...dimensionConf[dimensione],
            ...{ border: '1px solid rgb(204, 204, 204)' },
            ...(color && { bgcolor: color }),
            ...sx
          }}
          src={userAvatarSrc(avatar)}
        >
          {avatarLetters}
        </Avatar>
      </BaseTooltip>
    </div>
  )
}



function stringToColor(string) {
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

function stringAvatar(name = '0 0') {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    avatarLetters: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  }
}
