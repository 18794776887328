import { useEffect, useState } from 'react'

export default function usePromiseSession(options = {}) {
  const { startLoading = false } = options

  const [state, setState] = useState({ loading: startLoading, result: null })

  const adds = (items) => {
    items.forEach(i => { add(i) })
  }

  const add = (item) => {
    if (pm.add(item)) {
      setState({ loading: true, result: null })
      // POTREBBE ESSERE PIÙ CORRETTO MA NON ANCORA TESTATO
      // setState(prevState => ({ loading: true, result: prevState.result }))
    }
  }

  const isComplete = (results) => {
    pLog('completo')
    pLog(results)
    let newResult = { data: {}, error: {} }
    Object.keys(results).forEach(key => {
      const { ok, data, error } = results[key]
      if (ok) {
        if(data === undefined){
          newResult.error[key] = "Dati di ritorno non definiti per " +key
        }else{
          newResult.data[key] = data
        }        
      } else {
        newResult.error[key] = error
      }
    })
    setState({ loading: false, result: newResult })
  }

  const pm = pmSingleton
  const { result, loading } = state

  useEffect(() => {
    pm.setOnComplete(isComplete)
  }, [])

  return { add, adds, result, loading }
}

const pLog = (message) => {
  var date = new Date()
  false && console.log('pro [' + date.getHours() + ':'
    + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes() + ':'
    + (date.getSeconds() < 10 ? '0' : '') + date.getSeconds() + ']  ', message)
}



class PromiseSession {
  list = {}
  constructor() {
    pLog('PromiseSession ')
  }
  setOnComplete(onComplete) {
    this.onComplete = onComplete
  }
  getList() {
    return this.list
  }
  wrapResolve(data, key) {
    pLog('eseguito ' + key)
    this.list[key] = data
  }
  wrapReject(reject, key) {
    pLog('rifiutato ' + key)
    this.list[key] = { ok: false, error: reject.message }
  }
  isRunning() {
    return !Object.values(this.list).every(value => {
      return (value !== null)
    })
  }
  checkComplete() {
    if (!this.isRunning()) {
      this?.onComplete && this.onComplete(this.list)
      this.list = {}
    }
  }
  add(item) {
    const { promise, key } = item
    const isNewSession = !this.isRunning()
    // eslint-disable-next-line no-prototype-builtins
    if (this.list.hasOwnProperty(key)) {
      pLog('Chiave esclusa ' + key)
      return false
    }
    this.list[key] = null
    pLog('add ' + key)
    promise()
      .then((result) => {
        this.wrapResolve(result, key)
        this.checkComplete()
      })
      .catch((error) => {
        this.wrapReject(error, key)
        this.checkComplete()
      })
    return isNewSession
  }
}

const pmSingleton = new PromiseSession()