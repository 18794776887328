import { useMemo } from 'react'
import { bool } from 'prop-types'
import { FormHelperText, FormLabel, Collapse } from '@mui/material'
import { BaseButtons, BaseForm, BaseIconButtons, getNomiCampi, useSubForm } from 'inputs'
import { BaseScatola, BaseGridLayout } from 'layout'
import { sx, useStateWithLabel } from 'utils'
import { CAMPI } from '../../form/CampiForm'

const {
  INDIRIZZO_TIPO, INDIRIZZO_VIA, INDIRIZZO_NUMERO_CIVICO,
  INDIRIZZO_CAP, INDIRIZZO_COMUNE, INDIRIZZO_PROVINCIA,
  INDIRIZZO_NAZIONE, INDIRIZZO_PRESSO, INDIRIZZO_FRONTE_CIVICO
} = CAMPI

const NOMI_CAMPI_PRIMARI = getNomiCampi([
  INDIRIZZO_TIPO, INDIRIZZO_VIA, INDIRIZZO_NUMERO_CIVICO,
  INDIRIZZO_CAP, INDIRIZZO_COMUNE, INDIRIZZO_PROVINCIA
])

const NOMI_CAMPI_SECONDARI = getNomiCampi([
  INDIRIZZO_NAZIONE,
  INDIRIZZO_PRESSO,
  INDIRIZZO_FRONTE_CIVICO
])

InputIndirizzo.propTypes = {
  mostraTuttiCampi: bool,
  campiPrimariTuttiObbligatori: bool
}

const CAMPI_INDIRIZZO = [
  {
    campo: INDIRIZZO_TIPO,
    props: { larghezza: 11 }
  },
  {
    campo: INDIRIZZO_VIA,
    props: { larghezza: 18 }
  },
  {
    campo: INDIRIZZO_NUMERO_CIVICO,
    props: { larghezza: 7 }
  },
  {
    campo: INDIRIZZO_CAP,
    props: { larghezza: 7 }
  },
  {
    campo: INDIRIZZO_COMUNE,
    props: { larghezza: 18 }
  },
  {
    campo: INDIRIZZO_PROVINCIA,
    props: { larghezza: 11 }
  },
  {
    campo: INDIRIZZO_NAZIONE,
    props: { larghezza: 11 }
  },
  {
    campo: INDIRIZZO_PRESSO,
    props: { larghezza: 18 }
  },
  {
    campo: INDIRIZZO_FRONTE_CIVICO
  }
]

export default function InputIndirizzo(props) {
  const {
    mostraTuttiCampi: mostraTuttiCampi_Prop,
    campiObbligatori = [INDIRIZZO_COMUNE],
    campiPrimariTuttiObbligatori,
    stradario,
    ...restProps
  } = props

  const [
    mostraTuttiCampi, setMostraTuttiCampi
  ] = useStateWithLabel(mostraTuttiCampi_Prop ?? false, 'mostraTuttiCampi')

  const campiObbligatori_Definitivi = campiPrimariTuttiObbligatori
    ? NOMI_CAMPI_PRIMARI
    : campiObbligatori

  const {
    label,
    error,
    required,
    helperText,
    propsBaseForm,
    campi,
    ...propsRimanenti
  } = useSubForm({
    descrizioneCampi: CAMPI_INDIRIZZO,
    campiObbligatori: campiObbligatori_Definitivi,
    widthType: 'width',
    ...restProps
  })

  const { campiPrimari, campiSecondari } = useMemo(() => {
    let campiPrimari = []
    let campiSecondari = []
    campi.forEach(campo => {
      if (NOMI_CAMPI_PRIMARI.includes(campo.props.name)) {
        campiPrimari.push(campo)
      } else if (NOMI_CAMPI_SECONDARI.includes(campo.props.name)) {
        campiSecondari.push(campo)
      }
    })
    return { campiPrimari, campiSecondari }
  }, [campi])

  const propsBottoneEspansione = {
    tooltipProps: { disableHoverListener: true },
    sx: {
      p: 0.5,
      position: 'absolute',
      bottom: 0,
      right: 0
    }
  }

  return (
    <BaseScatola
      outlined
      {...propsRimanenti}
      {...sx(propsRimanenti, {
        textAlign: 'left',
        borderWidth: 4,
        position: 'relative'
      })}
    >
      <BaseForm {...propsBaseForm}>
        <BaseGridLayout justifySpaceBetween>
          <>
            <FormLabel required={required} error={error}>
              {label}
            </FormLabel>

            <FormHelperText error={error} sx={{ mb: 1 }}>
              {helperText}
            </FormHelperText>
          </>


        </BaseGridLayout>

        <BaseGridLayout numeroRighe={2}>
          {campiPrimari}
        </BaseGridLayout>

        <Collapse in={mostraTuttiCampi} sx={{ mt: 1 }}>
          <BaseGridLayout alignCenter>
            {campiSecondari}
          </BaseGridLayout>
        </Collapse>

        {mostraTuttiCampi ? (
          <BaseIconButtons.EspandiMeno
            onClick={() => setMostraTuttiCampi(false)}
            {...propsBottoneEspansione}
          />
        ) : (
          <BaseIconButtons.EspandiPiu
            onClick={() => setMostraTuttiCampi(true)}
            {...propsBottoneEspansione}
          />
        )}
      </BaseForm>
    </BaseScatola>
  )
}



function Stradario(props) {
  const {
    stradario,
    onSceltaStrada
  } = props

  if (!stradario) return null

  return (
    <>
      <BaseButtons.Custom
        testo='Stradario'
        color='info.light'
        noMarginY
      />

    </>
  )
}