import { string } from 'prop-types'
import { Box } from '@mui/material'
import { sx } from 'utils'
import { useUser } from './userProvider/UserProvider'

LabelComando.propTypes = {
  label: string,
  color: string
}

export default function LabelComando(props) {
  const {
    label,
    color = 'warning.main',
    ...restProps
  } = props

  const { getLabelComando } = useUser()

  return (
    <Box
      component='span'
      {...restProps}
      {...sx(restProps, {
        color,
        fontWeight: 'fontWeightBold'
      })}
    >
      {label || getLabelComando()}
    </Box>
  )
}