import { groupBy } from 'lodash'
import { MenuItem, ListSubheader } from '@mui/material'
import { sx } from 'utils'

export default function OptionsSelect(props) {
  const { raggruppaOptions, ...restProps } = props

  // Non istanzio i componenti con i tag, per fare in modo che venga 
  // renderizzato solo il loro contenuto SENZA un elemento contenitore
  // Motivo: la select non nativa di Mui vuole i MenuItem come figli diretti
  return raggruppaOptions ? OptionsRaggruppate(restProps) : Options(restProps)
}



function OptionsRaggruppate(props) {
  const { options, renderOption, calcolaPropsMenuItem } = props

  const optionsRaggruppate = groupBy(options, 'group')

  // La select di Mui vuole i <ListSubheader> allo stesso livello dei <MenuItem>
  if (renderOption) {
    let optionsRaggruppate_Renderizzate = []
    
    Object.entries(optionsRaggruppate).forEach(([labelGruppo, optionsGruppo]) => {
      const listSubHeader = (
        <ListSubheader key={labelGruppo} sx={{ fontSize: '1rem' }}>
          {labelGruppo}
        </ListSubheader>
      )
      const menuItems = Options({
        options: optionsGruppo,
        renderOption,
        calcolaPropsMenuItem,
        indenta: true
      })
      optionsRaggruppate_Renderizzate.push(listSubHeader, ...menuItems)
    })

    return optionsRaggruppate_Renderizzate
  }

  // La select nativa vuole degli <optgroup> che contengono le <option>
  return Object.entries(optionsRaggruppate).map(([labelGruppo, optionsGruppo]) =>
    <optgroup label={labelGruppo} key={labelGruppo}>
      {Options({ options: optionsGruppo })}
    </optgroup>
  )
}



function Options(props) {
  const { options, renderOption, calcolaPropsMenuItem, indenta } = props

  return options.map((option, index) => {
    const { value, label, disabled } = option

    // La select di Mui vuole dei <MenuItem>
    if (renderOption) {
      const propsMenuItem = calcolaPropsMenuItem?.(option, index)
      return (
        <MenuItem
          value={value} key={value} disabled={disabled}
          {...propsMenuItem}
          {...sx(propsMenuItem, indenta && { pl: 3 })}
        >
          {renderOption(option, index)}
        </MenuItem>
      )
    }

    // La select nativa vuole delle <option>
    return (
      <option value={value} key={value} disabled={disabled}>
        {label}
      </option>
    )
  })
}