import { number, string, node } from 'prop-types'
import { differenceInDays, fromUnixTime } from 'date-fns'
import { Alert } from '@mui/material'
import { BaseLink } from 'navigation'
import { BaseTesto } from 'text'
import { HumanDate } from 'utils'

export const PASSWORD_WARNING_DAYS = 15
export const PASSWORD_DANGER_DAYS = 5

AlertScadenzaPassword.propTypes = {
  timestampScadenza: number,
  modificaPasswordUrl: string,
  children: node
}

export default function AlertScadenzaPassword(props) {
  const {
    timestampScadenza = null,
    modificaPasswordUrl = null,
    children
  } = props

  if (!timestampScadenza) return null

  const date = fromUnixTime(timestampScadenza / 1000)
  const diffDays = differenceInDays(new Date(), date)

  if (PASSWORD_WARNING_DAYS + diffDays < 0) return null

  const severityAlertTime = PASSWORD_DANGER_DAYS + diffDays >= 0 ? 'error' : 'warning'
  return (
    <Alert
      severity={severityAlertTime}
      style={{ textAlign: 'left' }}
      sx={{ mb: 2 }}
    >
      <BaseTesto>
        Attenzione la password scadrà <HumanDate style={{ textDecoration: '', fontWeight: 'bold' }} datetime={date} />.
        {children}
        {modificaPasswordUrl &&
          <>{' '}cambiare la password al seguente <BaseLink component='a' href={modificaPasswordUrl}>indirizzo</BaseLink></>
        }
      </BaseTesto>
    </Alert>
  )
}