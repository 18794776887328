import { useState } from 'react'
import { BaseButtons, useControllerSeServe, useInputGenerico } from 'inputs'
import { useModal, BaseModal, BaseGridLayout } from 'layout'
import { BaseGrassetto } from 'text'
import ElenchiManager, { ELENCHI } from '../../form/ElenchiManager'
import FormEntitaBase from '../FormEntitaBase'
import { FormIndirizzo } from './IndirizzoModifica'
import { formattaIndirizzo } from './utilIndirizzo'

export default function FormIndirizzoBase(props) {
  const {
    valoriInput,
    onValoriModificati,
    usaStradario,
    stradarioNonObbligatorio,
    indirizziCompilazioneVeloce,
    readOnly,
    mostraDomicilioTemporaneoOAbituale,
    modalitaLight,
    modalitaLightConPresso
  } = props

  let elenchiNecessari = [ELENCHI.INDIRIZZO_TIPO, ELENCHI.NAZIONI]
  if (usaStradario) elenchiNecessari.push(ELENCHI.STRADARIO)

  const [keyRefreshForm, setKeyRefreshForm] = useState(Date.now())

  function compilaIndirizzoEsistente(indirizzo) {
    onValoriModificati(indirizzo)
    setTimeout(() => setKeyRefreshForm(Date.now()), 250)
  }

  return (
    <ElenchiManager
      elenchi={elenchiNecessari}
      render={el => 
        <FormEntitaBase
          valoriInput={valoriInput}
          onValoriModificati={onValoriModificati}
          key={keyRefreshForm}
          {...(readOnly && { readOnly })}
        >
          <FormIndirizzo
            el={el}
            usaStradario={usaStradario}
            stradarioNonObbligatorio={stradarioNonObbligatorio}
            indirizziCompilazioneVeloce={indirizziCompilazioneVeloce}
            compilaIndirizzoEsistente={compilaIndirizzoEsistente}
            {...(readOnly && { readOnly })}
            mostraDomicilioTemporaneoOAbituale={mostraDomicilioTemporaneoOAbituale}
            modalitaLight={modalitaLight}
            modalitaLightConPresso={modalitaLightConPresso}
          />
        </FormEntitaBase>
      }
    />
  )
}



export function FormIndirizzoBase_Modal(props) {
  const { label, propsIndirizzoBase, ...restProps } = props

  const {
    disabled,
    hidden,
    ...inputGenericoProps
  } = useInputGenerico({
    componenteControllato: true,
    underlyingComponent: 'modal',
    ...restProps
  })

  const {
    value,
    onBlur,
    onChange
  } = useControllerSeServe(inputGenericoProps)
  
  const { modalRef, openModal } = useModal()
  
  return (
    <BaseGridLayout alignCenter spacing={1} hidden={hidden}>
      <BaseButtons.Modifica
        testo={label}
        onClick={openModal}
        sx={{ my: 1 }}
      />

      <BaseModal
        ref={modalRef}
        titolo={label}
        maxWidth='lg'
        onClose={onBlur}
      >
        <FormIndirizzoBase
          valoriInput={value}
          onValoriModificati={onChange}
          {...(disabled && { readOnly: true })}
          {...propsIndirizzoBase}
        />
      </BaseModal>

      <BaseGrassetto>{formattaIndirizzo(value)}</BaseGrassetto>
    </BaseGridLayout>
  )
}