import { string, object } from 'prop-types'
import { BasePopoverConfermaOperazione } from 'feedback'
import { BaseButtons } from 'inputs'
import { useEntita } from './EntitaContext'

BottoneEliminaEntita.propTypes = {
  nomeEntita: string.isRequired,
  path: string.isRequired,
  opzioniEliminazione: object,
  propsPopoverConferma: object
}

export default function BottoneEliminaEntita(props) {
  const {
    nomeEntita,
    path,
    opzioniEliminazione,
    propsPopoverConferma,
    ...restProps
  } = props

  const {
    gestioneModifica: { eliminaEntita, mostraInfoDebug },
    gestionePermessi: { readOnly }
  } = useEntita()

  return (
    <BasePopoverConfermaOperazione
      messaggio="Confermare l'eliminazione?"
      onConferma={() => eliminaEntita(nomeEntita, path, opzioniEliminazione)}
      {...propsPopoverConferma}
    >
      <BaseButtons.Elimina
        disabled={readOnly}
        {...(mostraInfoDebug && { title: `DEBUG: ${nomeEntita} - ${path}` })}
        noMarginY
        {...restProps}
      />
    </BasePopoverConfermaOperazione>
  )
}
