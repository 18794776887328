import { ICONE } from 'icons'
import { BaseHeaderConIcona } from 'layout'
import { abbreviaStringaSeServe } from 'utils'

export default function HeaderContenutoMultimediale(props) {
  const { contenuto, ...restProps } = props
  const { titolo, note } = contenuto

  return (
    <BaseHeaderConIcona
      titolo={titolo || '(titolo non inserito)'}
      sottotitolo={abbreviaStringaSeServe(note)}
      icona={<ICONE.VIDEO />}
      {...restProps}
    />
  )
}