import { node, arrayOf, string } from 'prop-types'
import { ReadOnlyProvider } from 'inputs'
import { useUser } from './userProvider/UserProvider'
import { AZIONE_SHAPE, costruisciChiavePermesso, MODULO_SHAPE } from './userProvider/permessiUser/utilPermessiUser'

ApplicaPermessiUser.propTypes = {
  children: node.isRequired,
  fallback: node,

  // VECCHIA GESTIONE
  // Passare azione OPPURE moduli (se passati entrambi, i moduli vengono ignorati)
  azione: AZIONE_SHAPE,
  moduli: arrayOf(MODULO_SHAPE),

  // NUOVA GESTIONE
  chiaviPermessi: arrayOf(string),
  chiaviModuli: arrayOf(string)
}

export default function ApplicaPermessiUser(props) {
  const risultatiUseUser = useUser()

  if (risultatiUseUser.nuovaGestionePermessi) {
    return (function logicaNuovaGestione() {
      const {
        children,
        fallback,
        chiaviPermessi,
        chiaviModuli
      } = props

      const {
        userPuoVedere_AlmenoUnPermessoTra,
        userPuoVedereInSolaLettura_AlmenoUnPermessoTra,
        userPuoVedere_AlmenoUnModuloTra,
        userPuoVedereInSolaLettura_AlmenoUnModuloTra
      } = risultatiUseUser
    
      // - no moduli, no permessi --> posso vedere
      // - si moduli, no permessi --> posso vedere se vedo almeno un modulo
      // - no moduli, si permessi --> posso vedere se vedo almeno un permesso
      // - si moduli, si permessi --> posso vedere se vedo almeno un modulo E vedo almeno un permesso
      const figliSonoVisibili = (
        userPuoVedere_AlmenoUnModuloTra(chiaviModuli) 
        && userPuoVedere_AlmenoUnPermessoTra(chiaviPermessi)
      )

      if (!figliSonoVisibili) return fallback || null
    
      // - no moduli, no permessi --> lettura e scrittura
      // - si moduli, no permessi --> sola lettura se almeno un modulo in sola lettura e nessun modulo in lettura e scrittura
      // - no moduli, si permessi --> sola lettura se almeno un permesso in sola lettura e nessun permesso in lettura e scrittura
      // - si moduli, si permessi --> sola lettura se sola lettura per moduli OPPURE lettura e scrittura per moduli ma sola lettura per permessi
      const figliSonoInSolaLettura = (
        userPuoVedereInSolaLettura_AlmenoUnModuloTra(chiaviModuli)
        || userPuoVedereInSolaLettura_AlmenoUnPermessoTra(chiaviPermessi)
      )

      return (
        <ReadOnlyProvider readOnly={figliSonoInSolaLettura}>
          {children}
        </ReadOnlyProvider>
      )
    })()
  }

  const {
    children,
    fallback,
    azione,
    moduli
  } = props

  const {
    userPuoVedere,
    userPuoVedereInSolaLettura,
    userPuoVedere_AlmenoUnModuloTra,
    userPuoVedereInSolaLettura_AlmenoUnModuloTra
  } = risultatiUseUser

  const chiaveAzione = azione ? costruisciChiavePermesso(azione) : ''

  function figliSonoDaNascondere() {
    if (azione) return !userPuoVedere(chiaveAzione)
    if (moduli) return !userPuoVedere_AlmenoUnModuloTra(moduli)
    return false
  }

  function figliSonoInSolaLettura() {
    if (azione) return userPuoVedereInSolaLettura(chiaveAzione)
    if (moduli) return userPuoVedereInSolaLettura_AlmenoUnModuloTra(moduli)
    return false
  }

  if (figliSonoDaNascondere()) return fallback || null

  return (
    <ReadOnlyProvider readOnly={figliSonoInSolaLettura()}>
      {children}
    </ReadOnlyProvider>
  )
}
