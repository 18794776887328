import { object, node } from 'prop-types'
import { BasePopover, usePopover } from 'feedback'
import { ICONE } from 'icons'
import { BaseButtons } from 'inputs'
import { BaseDivider, BaseScatola } from 'layout'
import { BaseTitolo } from 'text'
import ElenchiManager, { ELENCHI } from '../../form/ElenchiManager'
import FormModificaEntita, { PROP_TYPES_WRAPPER_FORM_MODIFICA } from '../entitaGenerica/components/FormModificaEntita'
import { PERMESSI_ENTITA } from '../entitaGenerica/logica/LogicaPermessiEntita'
import { ENTITA_BASE } from '../EntitaBase'
import SelectSceltaGruppi from './SelectSceltaGruppi'
import SelectSceltaUtenti from './SelectSceltaUtenti'

PermessiEntitaModifica.propTypes = {
  ...PROP_TYPES_WRAPPER_FORM_MODIFICA,
  propsBottone: object,
  propsScatola: object,
  contenutoIniziale: node,
  labelUtenti: node,
  labelGruppi: node
}

PermessiEntitaModifica.defaultConfig = {
  path: 'permissions',
  nomeEntita: ENTITA_BASE.PERMISSION,
  url: 'permissions',
  label: 'Visibilità dei dati'
}

const NOMI = {
  GROUPS: 'groups',
  USERS: 'users'
}

export default function PermessiEntitaModifica(props) {
  const {
    label,
    propsBottone,
    propScatola,
    contenutoIniziale,
    labelUtenti,
    labelGruppi
  } = props

  const { popoverRef, openPopover, closePopover } = usePopover()

  return (
    <>
      <BaseButtons.Custom
        iconaInizio={ICONE.VISIBILE}
        testo={label}
        color='success'
        onClick={openPopover}
        {...propsBottone}
      />

      <BasePopover ref={popoverRef} sx={{ mt: 0.5 }}>
        <BaseScatola
          sx={{
            minWidth: 500, maxWidth: 1000,
            border: 4, borderColor: 'success.main'
          }}
          onClick_BottoneChiudi={closePopover}
          propsBottoneChiudi={{ 
            sx: { m: 1 }, 
            iconProps: { fontSize: 'normal' } 
          }}
          {...propScatola}
        >
          {contenutoIniziale &&
            <>
              {contenutoIniziale}
              <BaseDivider sx={{ my: 2 }} />
            </>
          }

          <ElenchiManager
            elenchi={[ELENCHI.GRUPPI, ELENCHI.UTENTI]}
            render={el =>
              <FormPermessiEntita
                {...props}
                optionsGruppi={el(ELENCHI.GRUPPI).piatti}
                optionsUtenti={el(ELENCHI.UTENTI)}
                labelUtenti={labelUtenti}
                labelGruppi={labelGruppi}
              />
            }
          />
        </BaseScatola>
      </BasePopover>
    </>
  )
}



function FormPermessiEntita(props) {
  const {
    propsEntita,
    optionsGruppi,
    optionsUtenti,
    labelUtenti,
    labelGruppi
  } = props

  const labelUtentiDefinitiva = labelUtenti ?? 'Utenti'
  const labelGruppiDefinitiva = labelGruppi ?? 'Gruppi'
  return (
    <FormModificaEntita
      {...propsEntita}
      trasformaValoriInput={valori =>
        trasformaValoriInput(valori, optionsGruppi, optionsUtenti)
      }
      trasformaValoriOutput={trasformaValoriOutput}
      // IMPORTANTE, con la fusione attiva non si possono eliminare i gruppi
      opzioniModificaEntita={{ fondiConValoreAttuale: false }}
    >
      <SelectSceltaUtenti
        name={NOMI.USERS}
        options={optionsUtenti}
        testoBottoneModifica='Modifica'
        titoloModal={labelUtentiDefinitiva}
        contenutoPrimaDelBottone={
          <BaseTitolo noMargine colorato>{labelUtentiDefinitiva}</BaseTitolo>
        }
      />

      <BaseDivider sx={{ my: 2 }} />

      <SelectSceltaGruppi
        name={NOMI.GROUPS}
        options={optionsGruppi}
        testoBottoneModifica='Modifica'
        titoloModal={labelGruppiDefinitiva}
        contenutoPrimaDelBottone={
          <BaseTitolo noMargine colorato>{labelGruppiDefinitiva}</BaseTitolo>
        }
      />
    </FormModificaEntita>
  )
}



function trasformaValoriInput(permissions, optionsGruppi, optionsUtenti) {
  const { groups, users } = permissions
  return {
    groups: Object.entries(groups)
      .filter(([, { permission }]) => permission === PERMESSI_ENTITA.LETTURA_SCRITTURA)
      .map(([codice]) => optionsGruppi.find(gruppo => codice === gruppo.codice))
      .filter(gruppo => Boolean(gruppo)),
    users: Object.entries(users)
      .filter(([, { permission }]) => permission === PERMESSI_ENTITA.LETTURA_SCRITTURA)
      .map(([username]) => optionsUtenti.find(({ info }) => username === info.nickname))
      .filter(utente => Boolean(utente))
  }
}



function trasformaValoriOutput(permissions) {
  const { groups, users } = permissions
  return {
    groups: groups.reduce(
      (gruppiAccumulatore, gruppoCorrente) => {
        return {
          ...gruppiAccumulatore,
          [gruppoCorrente.codice]: {
            permission: PERMESSI_ENTITA.LETTURA_SCRITTURA,
          }
        }
      },
      {}
    ),
    users: users.reduce(
      (utentiAccumulatore, utenteCorrente) => ({
        ...utentiAccumulatore,
        [utenteCorrente.info.nickname]: {
          permission: PERMESSI_ENTITA.LETTURA_SCRITTURA
        }
      }),
      {}
    )
  }
}