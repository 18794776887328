import { useRef, useCallback, useState, useEffect, Fragment } from 'react'
import { func, array, bool } from 'prop-types'
import { useDrag, useDrop } from 'react-dnd'
import { BaseIconButtons } from 'inputs'
import { BaseDivider } from 'layout'

BaseSortableList.propTypes = {
  list: array.isRequired,
  setList: func.isRequired,
  getKey: func.isRequired,
  renderElement: func.isRequired,
  abilitaEliminazione: bool
}

export default function BaseSortableList(props) {
  const {
    list,
    setList,
    getKey,
    renderElement,
    abilitaEliminazione
  } = props

  const sortList = useCallback((dragIndex, hoverIndex) => {
    setList(currentList => {
      let sortedList = [...currentList]
      sortedList.splice(dragIndex, 1)
      sortedList.splice(hoverIndex, 0, currentList[dragIndex])
      return sortedList
    })
  }, [setList])

  const deleteElement = useCallback(deleteIndex => {
    setList(currentList =>
      currentList.filter((_, index) => index !== deleteIndex)
    )
  }, [setList])

  // Impostato a true se uno qualsiasi degli elementi è in fase di drag
  const [isDraggingGlobale, setIsDraggingGlobale] = useState(false)

  return list.map((element, index) =>
    <Fragment key={getKey(element, index)}>
      <DragDropWrapper
        index={index}
        sortList={sortList}
        {...(abilitaEliminazione && { deleteElement })}
        isDraggingGlobale={isDraggingGlobale}
        setIsDraggingGlobale={setIsDraggingGlobale}
      >
        {renderElement(element, index)}
      </DragDropWrapper>

      <BaseDivider />
    </Fragment>
  )
}



const ACCEPT_TYPE = 'element'

function DragDropWrapper(props) {
  const {
    index,
    sortList,
    deleteElement,
    isDraggingGlobale,
    setIsDraggingGlobale,
    children
  } = props

  /***** Chiamata funzioni della libreria *****/

  const dragRef = useRef(null)
  const previewAndDropRef = useRef(null)

  const [{ isDragging }, drag, preview] = useDrag({
    type: ACCEPT_TYPE,
    item: () => ({ index }),
    collect: monitor => ({ isDragging: monitor.isDragging() })
  })

  const [{ handlerId }, drop] = useDrop({
    accept: ACCEPT_TYPE,
    collect: monitor => ({ handlerId: monitor.getHandlerId() }),
    hover: (item, monitor) => {
      if (!previewAndDropRef.current) return

      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) return

      // Determine rectangle on screen
      const hoverBoundingRect = previewAndDropRef.current?.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return
      // When dragging upwards, only move when the cursor is above 50%
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return

      // Time to actually perform the action
      sortList(dragIndex, hoverIndex)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    }
  })

  drag(dragRef)
  drop(preview(previewAndDropRef))

  /********************************************/

  /*** Disabilito tooltip se drag in corso ***/

  useEffect(() => {
    setIsDraggingGlobale(isDragging)
  }, [isDragging, setIsDraggingGlobale])

  const [showTooltip, setShowTooltip] = useState(false)

  const tooltipProps = {
    disableHoverListener: isDraggingGlobale,
    open: !isDraggingGlobale && showTooltip,
    onOpen: () => setShowTooltip(true),
    onClose: () => setShowTooltip(false)
  }

  /*******************************************/

  return (
    <div
      ref={previewAndDropRef}
      data-handler-id={handlerId}
      style={{
        opacity: isDragging ? 0 : 1,
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'nowrap'
      }}
    >
      <BaseIconButtons.Drag
        inputRef={dragRef}
        contenutoTooltip='Trascina per riordinare'
        tooltipProps={tooltipProps}
        sx={{ cursor: 'move', p: 0, mr: 0.5 }}
      />

      <>{children}</>

      {deleteElement &&
        <BaseIconButtons.Elimina onClick={() => deleteElement(index)} />
      }
    </div>
  )
}