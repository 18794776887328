import { ENTITA_BASE } from '../jconnetEntities/EntitaBase'
import { appiattisciGruppiAlbero } from '../jconnetEntities/permessiEntita/utilPermessiEntita'
import { OPERAZIONI_BASE } from '../network/OperazioniBase'
import { LABEL_TIPI_VISURA, TIPI_VISURA, elencoEntityTipologia, elencoStrumenti } from './utilsElenchi'

const ELENCHI = {
  // Permessi utente
  GRUPPI: 'gruppi',
  UTENTI: 'utenti',

  // Agenti
  AGENTI: 'agenti',
  GRADO_AGENTE: 'agente-gradi',

  // Indirizzo
  COMUNI: 'comuni',
  NAZIONI: 'stati',
  INDIRIZZO_TIPO: 'INDIRIZZO_TIPO',
  STRADARIO: 'stradario',
  TIPO_INTERSEZIONE: 'TIPO_INTERSEZIONE',
  TIPO_LUOGO: 'tipoLuogo',

  // Persona
  DOCUMENTO_TIPO: 'DOCUMENTO_TIPO',
  DOCUMENTO_RILASCIO: 'DOCUMENTO_RILASCIO',
  DOCUMENTO_CQC: 'DOCUMENTO_CQC',
  FOTOSEGNALAZIONE_PRESSO: 'FOTOSEGNALAZIONE_PRESSO',
  SESSO: 'SESSO',
  STATO_SALUTE: 'STATO_SALUTE',
  DETTAGLI_MORTE: 'DETTAGLI_MORTE',
  DESTINAZIONE_SALMA: 'DESTINAZIONE_SALMA',

  // Veicolo
  AUTOVETTURA_TIPO: 'AUTOVETTURA_TIPO',
  AUTOVETTURA_COLORE: 'AUTOVETTURA_COLORE',
  ALIMENTAZIONE: 'ALIMENTAZIONE',
  LATO_GUIDA: 'LATO_GUIDA',
  RAPPORTO_CAMBIO: 'RAPPORTO_CAMBIO',
  STATO_VEICOLO: 'STATO_VEICOLO',
  TIPOLOGIA_TRAGITTO: 'TIPOLOGIA_TRAGITTO',

  // Strumenti
  STRUMENTI: 'STRUMENTI',
  STRUMENTI_ETILOMETRO: 'STRUMENTI_ETILOMETRO',

  // Altro
  VARIABILI: 'vars',
  VISURE_CONFIG: 'VISURE_CONFIG'
}

// Vedi ElenchiProvider per opzioni accettate
const ELENCHI_BASE_CONFIG = {
  /****** Permessi utente ******/

  [ELENCHI.GRUPPI]: {
    legatoAlComando: true,
    trasformaElenco: gruppiAlbero => ({
      albero: gruppiAlbero,
      piatti: appiattisciGruppiAlbero(gruppiAlbero)
    })
  },
  [ELENCHI.UTENTI]: {
    legatoAlComando: true
  },

  /*****************************/

  /********** Agenti **********/

  [ELENCHI.AGENTI]: {
    legatoAlComando: true
  },

  [ELENCHI.GRADO_AGENTE]: {

  },

  /****************************/

  /********** Indirizzo **********/

  [ELENCHI.COMUNI]: {

  },

  [ELENCHI.NAZIONI]: {
    trasformaElenco: nazioni => nazioni.map(({ nazione, nazioneIta }) => ({
      // Devo usare sempre la nazione scritta in inglese perchè
      // i campi dei vari data model usano una singola stringa
      value: nazione,
      label: nazioneIta
    }))
  },

  [ELENCHI.INDIRIZZO_TIPO]: {
    default: [
      { value: 'via', label: 'Via' },
      { value: 'viale', label: 'Viale' },
      { value: 'vialetto', label: 'Vialetto' },
      { value: 'vicolo', label: 'Vicolo' },
      { value: 'piazza', label: 'Piazza' },
      { value: 'piazzale', label: 'Piazzale' },
      { value: 'piazzetta', label: 'Piazzetta' },
      { value: 'corso', label: 'Corso' },
      { value: 'largo', label: 'Largo' },
      { value: 'strada', label: 'Strada' },
      { value: 'traversa', label: 'Traversa' },
      { value: 'sc', label: 'SC' },
      { value: 'sp', label: 'SP' },
      { value: 'sr', label: 'SR' },
      { value: 'ss', label: 'SS' },
      { value: 'raccordo', label: 'Raccordo' },
      { value: 'svincolo', label: 'Svincolo' },
      { value: 'tangenziale', label: 'Tangenziale' },
      { value: 'autostrada', label: 'Autostrada' },
      { value: 'cavalcavia', label: 'Cavalcavia' },
      { value: 'circonvallazione', label: 'Circonvallazione' },
      { value: 'rotatoria', label: 'Rotatoria' },
      { value: 'viadotto', label: 'Viadotto' },
      { value: 'galleria', label: 'Galleria' },
      { value: 'sottopasso', label: 'Sottopasso' },
      { value: 'sovrappasso', label: 'Sovrappasso' },
      { value: 'parcheggio', label: 'Parcheggio' },
      { value: 'parco', label: 'Parco' },
      { value: 'corte', label: 'Corte' },
      { value: 'cascina', label: 'Cascina' },
      { value: 'borgo', label: 'Borgo' },
      { value: 'contrada', label: 'Contrada' },
      { value: 'quartiere', label: 'Quartiere' },
      { value: 'villaggio', label: 'Villaggio' },
      { value: 'frazione', label: 'Frazione' },
      { value: 'localita', label: 'Località' },
      { value: 'regione', label: 'Regione' },
      { value: 'borgata', label: 'Borgata' },
      { value: 'riviera', label: 'Riviera' },
      { value: 'lungolago', label: 'Lungolago' },
      { value: 'ponte', label: 'Ponte' },
      { value: 'scala', label: 'Scala' },
      { value: 'scalinata', label: 'Scalinata' },
      { value: 'salita', label: 'Salita' },
      { value: 'discesa', label: 'Discesa' },
      { value: 'sestriere', label: 'Sestriere' },
      { value: 'campo', label: 'Campo' },
      { value: 'campiello', label: 'Campiello' },
      { value: 'calle', label: 'Calle' },
      { value: 'presso', label: 'Presso' }
    ]
  },

  [ELENCHI.STRADARIO]: {
    legatoAlComando: true
  },

  [ELENCHI.TIPO_INTERSEZIONE]: {
    default: [
      { value: '', label: '' },
      { value: 'T', label: 'Intersezione a T' },
      { value: 'X', label: 'Intersezione a X' },
      { value: 'Y', label: 'Intersezione a Y' }
    ]
  },

  [ELENCHI.TIPO_LUOGO]: elencoEntityTipologia(ENTITA_BASE.TIPO_LUOGO),

  /*******************************/

  /********** Persona **********/

  [ELENCHI.DOCUMENTO_TIPO]: {
    dizionario: 'tipiDocumentoIdentificazione',
    trasformaElenco: tipi => tipi.map(({ first, second }) => ({
      value: first,
      label: second
    }))
  },

  [ELENCHI.DOCUMENTO_RILASCIO]: {
    default: [
      { value: 'mctc', label: 'M.C.T.C.' },
      { value: 'prefettura', label: 'Prefettura' },
      { value: 'uco', label: 'M.I.T. - U.C.O.' },
      { value: 'autorita', label: 'Autorità' },
    ]
  },

  [ELENCHI.DOCUMENTO_CQC]: {
    default: [
      { value: '', label: '' },
      { value: 'M', label: 'Trasporto merci' },
      { value: 'P', label: 'Trasporto persone' },
    ]
  },

  [ELENCHI.FOTOSEGNALAZIONE_PRESSO]: {
    default: [
      { value: '', label: '' },
      { value: 'comando', label: 'Locali polizia municipale' },
      { value: 'altraPolizia', label: 'Altra Forza di Polizia' },
    ]
  },

  [ELENCHI.SESSO]: {
    default: [
      { value: '', label: '' },
      { value: 'M', label: 'Maschio' },
      { value: 'F', label: 'Femmina' },
    ]
  },

  [ELENCHI.STATO_SALUTE]: {
    default: [
      { value: 'BUONA', label: 'Incolume' },
      { value: 'FERITO', label: 'Ferito / Contuso' },
      { value: 'MORTO', label: 'Deceduto' },
    ]
  },

  [ELENCHI.DETTAGLI_MORTE]: {
    default: [
      { value: '', label: '' },
      { value: 'nelle24Ore', label: 'Deceduto nelle 24 ore' },
      { value: 'entro30giorni', label: 'Deceduto entro il trentesimo giorno' },
    ]
  },

  [ELENCHI.DESTINAZIONE_SALMA]: {
    default: [
      { value: '', label: '' },
      { value: 'medicinaLegale', label: 'Medicina legale' },
      { value: 'salmaLibera', label: 'Salma libera' },
    ]
  },

  /*****************************/

  /********** Veicolo **********/

  [ELENCHI.AUTOVETTURA_TIPO]: {
    default: [
      { value: 'AUTOVETTURA', label: 'Autovettura' },
      { value: 'VELOCIPEDE', label: 'Velocipede' },
      { value: 'CICLOMOTORE', label: 'Ciclomotore' },
      { value: 'MOTOCICLO', label: 'Motociclo' },
      { value: 'AUTOBUS', label: 'Autobus' },
      { value: 'AUTOCARRO FINO A 3,5t', label: 'Autocarro fino a 3,5t' },
      { value: 'AUTOCARRO FINO A 12t', label: 'Autocarro fino a 12t' },
      { value: 'AUTOCARRO OLTRE 12t', label: 'Autocarro oltre 12t' },
      { value: 'TRATTORE STRADALE', label: 'Trattore stradale' },
      { value: 'AUTOTRENO', label: 'Autotreno' },
      { value: 'AUTOARTICOLATO', label: 'Autoarticolato' },
      { value: 'AUTOSNODATO', label: 'Autosnodato' },
      { value: 'AUTOCARAVAN', label: 'Autocaravan' },
      { value: 'MEZZO OPERA', label: 'Mezzo d\'opera' },
      { value: 'FILOVEICOLO', label: 'Filoveicolo' },
      { value: 'RIMORCHIO', label: 'Rimorchio' },
      { value: 'RIMORCHIO SPECIALE', label: 'Rimorchio ad uso speciale' },
      { value: 'CARAVAN', label: 'Caravan' },
      { value: 'MACCHINA AGRICOLA SEMOVENTE', label: 'Macchina agricola semov.' },
      { value: 'MACCHINA AGRICOLA TRAINATA', label: 'Macchina agricola trainata' },
      { value: 'MACCHINA OPERATRICE', label: 'Macchina operatrice' },
      { value: 'MONOPATTINO', label: 'Monopattino' },
      { value: 'MOTOVEICOLO EPOCA', label: 'Motoveicolo d\'epoca' },
      { value: 'AUTOVEICOLO EPOCA', label: 'Autoveicolo d\'epoca' },
      { value: 'QUADRICICLO', label: 'Quadriciclo' },
      { value: 'AUTOVETTURA RIMORCHIO', label: 'Autovettura con rimorchio' },
      { value: 'BRACCIA', label: 'Veicolo a braccia' },
      { value: 'SLITTA', label: 'Slitta' },
      { value: 'TRAZIONE ANIMALE', label: 'Veicolo a trazione animale' },
      { value: 'AUTOTRASPORTO PERSONE FINO A 5t', label: 'Veicolo tras. persone fino 5t' },
      { value: 'AUTOTRASPORTO PERSONE OLTRE 5t', label: 'Veicolo tras. persone oltre 5t' },
      { value: 'TRASPORTO MERCI PERICOLOSE', label: 'Trasporto merci pericolose' },
      { value: 'AUTOBUS CON RIMORCHIO', label: 'Autobus con rimorchio' },
      { value: 'TRICICLO', label: 'Triciclo' },
      { value: 'SEMIRIMORCHIO', label: 'Semirimorchio' },
      { value: 'TRATTORE AGRICOLO', label: 'Trattore agricolo' },
      { value: 'VEICOLO SPECIALE', label: 'Veicolo speciale' },
      { value: 'NON CLASSIFICABILE', label: 'Non classificabile' },
      { value: '', label: 'Non disponibile' },
    ]
  },

  [ELENCHI.AUTOVETTURA_COLORE]: {
    default: [
      { value: '', label: '' },
      { value: '#ff6b28', label: 'Arancione' },
      { value: '#ff2e2e', label: 'Rosso' },
      { value: '#FFFFFF', label: 'Bianco' },
      { value: '#ffe944', label: 'Giallo' },
      { value: '#1e56f1', label: 'Blu' },
      { value: '#d0d0d0', label: 'Grigio chiaro' },
      { value: '#ba25ab', label: 'Rosa / Viola' },
      { value: '#aeffff', label: 'Azzurro' },
      { value: '#38c238', label: 'Verde' },
      { value: '#808080', label: 'Grigio scuro' },
      { value: '#a9633f', label: 'Marrone' },
      { value: '#000000', label: 'Nero' },
      { value: '#eeeeee', label: 'Chiaro' },
      { value: '#333333', label: 'Scuro' },
    ]
  },

  [ELENCHI.ALIMENTAZIONE]: {
    default: [
      { value: '', label: '' },
      { value: 'benzina', label: 'Benzina' },
      { value: 'gasolio', label: 'Gasolio' },
      { value: 'gas', label: 'Metano/GPL' },
      { value: 'ibrida', label: 'Ibrida' },
      { value: 'elettrica', label: 'Elettrica' },
      { value: 'pedali', label: 'A pedali' },
    ]
  },

  [ELENCHI.LATO_GUIDA]: {
    default: [
      { value: '', label: '' },
      { value: 'sinistro', label: 'Guida a sinistra' },
      { value: 'centrale', label: 'Guida centrale' },
      { value: 'destro', label: 'Guida a destra' },
    ]
  },

  [ELENCHI.RAPPORTO_CAMBIO]: {
    default: [
      { value: '', label: '' },
      { value: '0', label: 'Altra marcia inserita' },
      { value: 'N', label: 'In folle/N' },
      { value: '1', label: 'In 1° marcia' },
      { value: '2', label: 'In 2° marcia' },
      { value: '3', label: 'In 3° marcia' },
      { value: '4', label: 'In 4° marcia' },
      { value: '5', label: 'In 5° marcia' },
      { value: '6', label: 'In 6° marcia' },
      { value: 'R', label: 'In retromarcia/R' },
      { value: 'D', label: 'In D/A' },
      { value: 'P', label: 'In P' },
      { value: 'NA', label: 'N/A' },
    ]
  },

  [ELENCHI.STATO_VEICOLO]: {
    default: [
      { value: 'non verificato o non funzionante', label: 'non verificato o non funzionante' },
      { value: 'funzionante', label: 'funzionante' },
      { value: 'non funzionante', label: ' non funzionante' },
      { value: 'non presente', label: 'non presente' },
    ]
  },

  [ELENCHI.TIPOLOGIA_TRAGITTO]: {
    default: [
      { value: '', label: '' },
      { value: 'duranteLavoro', label: 'Durante lo svolgimento dell\'attività lavorativa' },
      { value: 'casaLavoro', label: 'Tragitto casa-lavoro o lavoro-casa' },
      { value: 'altroMomento', label: 'In altro momento della giornata' },
    ]
  },

  /*****************************/

  /********* Strumenti *********/

  [ELENCHI.STRUMENTI]: elencoStrumenti(),

  [ELENCHI.STRUMENTI_ETILOMETRO]: elencoStrumenti('etilometro'),

  /*****************************/

  /********** Altro **********/

  [ELENCHI.VARIABILI]: {
    legatoAlComando: true,
    operationId: OPERAZIONI_BASE.VARIABILI_ELENCO,
    trasformaElenco: ({ vars }) => vars.reduce(
      (accumulatoreVars, variabile) => ({
        ...accumulatoreVars,
        [variabile.nome]: variabile
      }),
      {}
    )
  },

  [ELENCHI.VISURE_CONFIG]: {
    legatoAlComando: true,
    operationId: OPERAZIONI_BASE.VISURA_TIPO_VISURE,
    trasformaElenco: ({ tipiVisura }) => {
      const config = {
        veicolo: [],
        anagrafica: []
      }
      tipiVisura.forEach(({ richiesta, tipo_visura, abilitati }) => {
        if (Array.isArray(config[richiesta]) && TIPI_VISURA[tipo_visura] && abilitati.length > 0) {
          config[richiesta].push({
            value: TIPI_VISURA[tipo_visura],
            label: LABEL_TIPI_VISURA[tipo_visura],
            enti: abilitati.map(ente => ({ value: ente, label: ente }))
          })
        }
      })
      return config
    }
  },

  /***************************/
}

export {
  ELENCHI,
  ELENCHI_BASE_CONFIG
}