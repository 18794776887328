import { bool, node, string, shape, any } from 'prop-types'
import { Link as RouterLink, useRouteMatch } from 'react-router-dom'
import { costruisciRotta } from 'utils'
import { useEntita } from './EntitaContext'

LinkPannello.propTypes = {
  to: string.isRequired,
  children: node,
  relativo: bool,
  homeEntita: bool,
  linkRef: shape({ current: any }),
  evidenziaHover: bool
}

export default function LinkPannello(props) {
  const {
    to,
    children,
    relativo,
    homeEntita,
    linkRef,
    evidenziaHover,
    ...restProps
  } = props

  const { urlBase } = useEntita()

  const { url: urlCorrente } = useRouteMatch()

  let toDefinitivo
  if (homeEntita) {
    toDefinitivo = urlBase
  } else if (relativo) {
    toDefinitivo = costruisciRotta(urlCorrente, to)
  } else {
    toDefinitivo = costruisciRotta(urlBase, to)
  }

  return (
    <RouterLink
      to={toDefinitivo}
      ref={linkRef}
      {...restProps}
      {...(evidenziaHover && {
        className: `${restProps.className ?? ''} linkPannelloEvidenziaHover`.trim()
      })}
    >
      {children}
    </RouterLink>
  )
}