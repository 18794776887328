import { useMemo, forwardRef } from 'react'
import { bool, oneOf } from 'prop-types'
import { TextField, InputAdornment } from '@mui/material'
import { ICONE } from 'icons'
import useInputGenerico from './hooks/InputGenericoHook'
import { aggiungiRegoleLunghezzaInput, aggiungiRegolePerNumeri, aggiungiTrimPerStringhe } from './validazione/RegoleSemplici'
import { TIPI_INPUT_VALORI, creaRegoleValidazioneStringa } from './validazione/RegoleCustom'
import { unisciMessaggiERegoleValidazione } from './validazione/UtilValidazione'

/**
 * @typedef {import("../common/inputs/BaseTextInput").BaseTextInputProps} BaseTextInputProps 
 * @typedef {import("../common/inputs/BaseTextInput").BaseTextInput} BaseTextInput 
 */

/**
 * 
 * @param {BaseTextInputProps} props 
 * @type {BaseText}
 */
const BaseTextInput = forwardRef(function BaseTextInput(props, ref) {
  const {
    resizeTextArea = true,
    inputProps = {},
    InputProps,
    startAdornment,
    endAdornment,
    ...restProps
  } = props

  // Imposta alcune prop dell'input (elemento DOM)
  const { multiline, type = 'text' } = props
  let inputPropsDefinitive = { ...inputProps }
  if (multiline && resizeTextArea) {
    inputPropsDefinitive.style = { resize: 'vertical', ...inputProps.style }
  }
  if (type === 'numeroPositivo') {
    inputPropsDefinitive = { min: 0, ...inputPropsDefinitive }
  }
  if (type === 'moneta') {
    inputPropsDefinitive = { step: 0.01, min: 0, ...inputPropsDefinitive }
  }

  // Imposta gli adornments
  let adornments = {}
  if (type === 'moneta') setAdornment(adornments, 'end', '€')
  if (type === 'telefono') setAdornment(adornments, 'end', <ICONE.TELEFONO />)
  if (type === 'email') setAdornment(adornments, 'end', <ICONE.EMAIL />)
  if (startAdornment) setAdornment(adornments, 'start', startAdornment)
  if (endAdornment) setAdornment(adornments, 'end', endAdornment)
  const InputPropsDefinitive = { ...adornments, ...InputProps }

  // Imposta le prop di validazione
  const {
    validazione: validazioneOriginale = {},
    messaggiErrore: messaggiOriginali
  } = restProps

  const typeDefinitivo = (type === 'moneta') ? 'numeroPositivo' : type
  const {
    htmlInputType,
    validate,
    messaggi
  } = useMemo(() => creaRegoleValidazioneStringa(typeDefinitivo), [typeDefinitivo])

  let validazioneOriginaleCopia = { ...validazioneOriginale }
  aggiungiTrimPerStringhe(validazioneOriginaleCopia)
  aggiungiRegoleLunghezzaInput(validazioneOriginaleCopia)
  if (htmlInputType === 'number') {
    aggiungiRegolePerNumeri(validazioneOriginaleCopia)
  } else {
    validazioneOriginaleCopia.max = undefined
    validazioneOriginaleCopia.min = undefined
  }

  // Passa tutte le prop all'input generico
  const inputGenericoProps = useInputGenerico({
    widthType: 'width',
    ...restProps,
    inputProps: inputPropsDefinitive,
    InputProps: InputPropsDefinitive,
    type: htmlInputType,
    ...unisciMessaggiERegoleValidazione(validazioneOriginaleCopia, validate, messaggiOriginali, messaggi)
  })

  return <TextField ref={ref} {...inputGenericoProps} />
})



BaseTextInput.propTypes = {
  type: oneOf(TIPI_INPUT_VALORI),
  multiline: bool,
  resizeTextArea: bool,
  ...useInputGenerico.propTypes
}


export default BaseTextInput;

const CHIAVI_ADORNMENT = { start: 'startAdornment', end: 'endAdornment' }

function setAdornment(adornments, position, content) {
  adornments[CHIAVI_ADORNMENT[position]] = (
    <InputAdornment position={position}>
      {content}
    </InputAdornment>
  )
}