import { BaseTextInput, BaseRadioBoolean } from 'inputs'
import { BaseScatolaConTitolo, BaseGridLayout } from 'layout'
import FormModificaEntita, { PROP_TYPES_WRAPPER_FORM_MODIFICA } from '../entitaGenerica/components/FormModificaEntita'

const NOMI = {
  NOME: 'nome',
  MOTIVAZIONE: 'motivazione'
}

RifiutoTestModifica.propTypes = PROP_TYPES_WRAPPER_FORM_MODIFICA

export default function RifiutoTestModifica(props) {
  const {
    propsEntita,
    label,
    propsInterne_PannelloModificaEntita: {
      gestioneModifica: { creaEntita, eliminaEntita },
      gestionePermessi: { readOnly }
    }
  } = props
  
  const { valoriInput, nomeEntita, pathEntita } = propsEntita

  function creaRifiuto() {
    creaEntita(nomeEntita, pathEntita)
  }

  function eliminaRifiuto() {
    eliminaEntita(nomeEntita, pathEntita)
  }

  return (
    <BaseScatolaConTitolo titolo={label}>
      <BaseRadioBoolean
        label='Rifiuta di eseguire i test'
        nonUsareHookForm
        value={Boolean(valoriInput)}
        onChange={e => e.target.value ? creaRifiuto() : eliminaRifiuto()}
        readOnly={readOnly}
      />

      {valoriInput &&
        <FormModificaEntita {...propsEntita} larghezzaInputs={25}>
          <BaseGridLayout>
            <BaseTextInput
              label='Descrizione rifiuto' name={NOMI.NOME}
              multiline rows={3}
            />
            <BaseTextInput
              label='Motivazione rifiuto' name={NOMI.MOTIVAZIONE}
              multiline rows={3}
            />
          </BaseGridLayout>
        </FormModificaEntita>
      }
    </BaseScatolaConTitolo>
  )
}