import { isValidIBAN } from 'ibantools'

function isPartitaIvaValida(partitaIva) {
  if (partitaIva === '') return true
  if (!/^[0-9]{11}$/.test(partitaIva)) return false

  const cifre = partitaIva.split('').map(cifraChar => Number(cifraChar))
  const cifrePosizioniDispari = cifre.filter((_, index) => index % 2 === 0)
  const cifrePosizioniPari = cifre.filter((_, index) => index % 2 !== 0)

  const x = cifrePosizioniDispari.reduce((somma, cifra) => somma + cifra, 0)
  const y = cifrePosizioniPari.reduce((somma, cifra) => {
    let doppio = 2 * cifra
    if (doppio > 9) doppio -= 9
    return somma + doppio
  }, 0)

  const t = (x + y) % 10
  if (t !== 0) return false

  return true
}

function isIbanValido(iban) {
  if (iban === '') return true
  return isValidIBAN(iban.toUpperCase())
}

function isEmailValida(email) {
  if (email === '') return true
  // eslint-disable-next-line no-useless-escape
  return /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(email)
}

function isNumeroTelefonoValido(telefono) {
  if (telefono === '') return true
  return /^(\d|\s|-|\.)+$/.test(telefono)
}

function isTargaValida(targa) {
  if (targa === '') return true
  return targa.trim().length >= 4
}

/********** Codice fiscale **********/

const CHECK_CODE_ODD = {
  0: 1, 1: 0, 2: 5, 3: 7, 4: 9, 5: 13, 6: 15, 7: 17, 8: 19, 9: 21,
  A: 1, B: 0, C: 5, D: 7, E: 9, F: 13, G: 15, H: 17, I: 19, J: 21,
  K: 2, L: 4, M: 18, N: 20, O: 11, P: 3, Q: 6, R: 8, S: 12, T: 14,
  U: 16, V: 10, W: 22, X: 25, Y: 24, Z: 23
}

const CHECK_CODE_EVEN = {
  0: 0, 1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6, 7: 7, 8: 8, 9: 9,
  A: 0, B: 1, C: 2, D: 3, E: 4, F: 5, G: 6, H: 7, I: 8, J: 9,
  K: 10, L: 11, M: 12, N: 13, O: 14, P: 15, Q: 16, R: 17, S: 18, T: 19,
  U: 20, V: 21, W: 22, X: 23, Y: 24, Z: 25
}

const CHECK_CODE_CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'

const REGEX_CODICE_FISCALE = /^[A-Z]{6}[0-9LMNPQRSTUV]{2}[ABCDEHLMPRST]{1}[0-9LMNPQRSTUV]{2}[A-Z]{1}[0-9LMNPQRSTUV]{3}[A-Z]{1}$/

function getCheckCode(codiceFiscale) {
  const codiceFiscaleUppercase = codiceFiscale.toUpperCase()
  let val = 0
  for (let i = 0; i < 15; i = i + 1) {
    const c = codiceFiscaleUppercase[i]
    val += i % 2 !== 0 ? CHECK_CODE_EVEN[c] : CHECK_CODE_ODD[c]
  }
  val = val % 26
  return CHECK_CODE_CHARS.charAt(val)
}

function isCodiceFiscaleValido(codiceFiscale) {
  if (codiceFiscale === '') return true
  if (typeof codiceFiscale !== 'string' || codiceFiscale.length !== 16) return false

  const codiceFiscaleUppercase = codiceFiscale.toUpperCase()
  if (!REGEX_CODICE_FISCALE.test(codiceFiscaleUppercase)) return false

  const expectedCheckCode = codiceFiscaleUppercase.charAt(15)
  return (getCheckCode(codiceFiscale) === expectedCheckCode)
}

/************************************/

export {
  isPartitaIvaValida,
  isIbanValido,
  isEmailValida,
  isNumeroTelefonoValido,
  isTargaValida,
  isCodiceFiscaleValido
}