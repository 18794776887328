import { ICONE } from 'icons'

const MIME_TYPES = {
  // Documenti di testo
  PDF: {
    label: 'PDF',
    type: 'application/pdf',
    estensione: '.pdf',
    icona: ICONE.PDF_ICON
  },
  PDF_FIRMATO: {
    label: 'PDF firmato digitalmente',
    type: 'application/pkcs7-mime',
    estensione: '.pdf.p7m',
    icona: ICONE.PDF_ICON
  },
  WORD_DOC: {
    label: 'Word',
    type: 'application/msword',
    estensione: '.doc',
    icona: ICONE.WORD_ICON
  },
  WORD_DOCX: {
    label: 'Word',
    type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    estensione: '.docx',
    icona: ICONE.WORD_ICON
  },
  ODT: {
    label: 'ODT',
    type: 'application/vnd.oasis.opendocument.text',
    estensione: '.odt'
  },
  RTF: {
    label: 'RTF',
    type: 'application/rtf',
    estensione: '.rtf'
  },
  TXT: {
    label: 'Testo semplice',
    type: 'text/plain',
    estensione: '.txt'
  },

  // Fogli di calcolo
  EXCEL_XLS: {
    label: 'Excel',
    type: 'application/vnd.ms-excel',
    estensione: '.xls',
    icona: ICONE.EXCEL_ICON
  },
  EXCEL_XLSX: {
    label: 'Excel',
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    estensione: '.xlsx',
    icona: ICONE.EXCEL_ICON
  },
  ODS: {
    label: 'ODS',
    type: 'application/vnd.oasis.opendocument.spreadsheet',
    estensione: '.ods'
  },
  CSV: {
    label: 'CSV',
    type: 'text/csv',
    estensione: '.csv'
  },

  // Immagini
  JPG: {
    label: 'JPG',
    type: 'image/jpeg',
    estensione: '.jpg'
  },
  PNG: {
    label: 'PNG',
    type: 'image/png',
    estensione: '.png'
  },

  // Archivi
  ZIP: {
    label: 'ZIP',
    type: 'application/zip',
    estensione: '.zip'
  },
  '7z': {
    label: '7z',
    type: 'application/x-7z-compressed',
    estensione: '.7z'
  },

  // JSON
  JSON: {
    label: 'JSON',
    type: 'application/json',
    estensione: '.json'
  }
  // : {
  //   label: '',
  //   type: '',
  //   estensione: ''
  // },
}
const MIME_TYPES_VALUES = Object.values(MIME_TYPES)

const GRUPPI_MIME_TYPES = {
  DOCUMENTI_TESTO: [
    MIME_TYPES.PDF,
    MIME_TYPES.PDF_FIRMATO,
    MIME_TYPES.WORD_DOC,
    MIME_TYPES.WORD_DOCX,
    MIME_TYPES.ODT
  ],
  FOGLI_CALCOLO: [
    MIME_TYPES.EXCEL_XLS,
    MIME_TYPES.EXCEL_XLSX,
    MIME_TYPES.ODS,
    MIME_TYPES.CSV
  ],
  IMMAGINI: [
    MIME_TYPES.JPG,
    MIME_TYPES.PNG
  ],
  ARCHIVI: [
    MIME_TYPES.ZIP,
    MIME_TYPES['7z']
  ],
  JSON: [
    MIME_TYPES.JSON
  ]
}
const GRUPPI_MIME_TYPES_VALUES = Object.values(GRUPPI_MIME_TYPES)

function flattenMimeTypesList(mimeTypesList) {
  return mimeTypesList.reduce(
    (flattenedList, mimeType) => {
      const mimeTypesDaConcatenare = Array.isArray(mimeType)
        ? mimeType
        : [mimeType]
      return [...flattenedList, ...mimeTypesDaConcatenare]
    }
    , []
  )
}

function creaLabelListaMimeTypes(flattenedMimeTypesList) {
  if (flattenedMimeTypesList.length === 0) return ''

  const labelTipiFile = flattenedMimeTypesList.map(({ label }) => label)
  const labelTipiFileSenzaDuplicati = new Set(labelTipiFile)
  return Array.from(labelTipiFileSenzaDuplicati).join(', ')
}

function creaAcceptString(flattenedMimeTypesList) {
  if (flattenedMimeTypesList.length === 0) return ''

  return flattenedMimeTypesList
    .map(({ type, estensione }) => `${type},${estensione}`)
    .join(',')
}

function findMimeTypeWith(proprietaCercata, valoreCercato) {
  return MIME_TYPES_VALUES.find(({ [proprietaCercata]: valore }) => valore === valoreCercato)
}

function calcolaFileNameConEstensione(filename, mimetype) {
  const estensione = findMimeTypeWith('type', mimetype)?.estensione || ''
  // Aggiungi l'estensione solo se non è già presente nel nome del file
  return filename.endsWith(estensione) ? filename : `${filename}${estensione}`
}

export {
  MIME_TYPES,
  MIME_TYPES_VALUES,
  GRUPPI_MIME_TYPES,
  GRUPPI_MIME_TYPES_VALUES,
  flattenMimeTypesList,
  creaLabelListaMimeTypes,
  creaAcceptString,
  findMimeTypeWith,
  calcolaFileNameConEstensione
}