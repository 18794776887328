import { BaseIconButtons } from 'inputs'
import { BaseModal, useModal } from 'layout'
import { useEntita } from '../entitaGenerica/components/EntitaContext'
import ListaDocumentiSostituiti from './components/ListaDocumentiSostituiti'
import { costruisciSequenzaDocumentiSostituiti } from './utilDocumenti'

export default function VersioniDocumento(props) {
  const {
    propsEntita,
    trovaTemplateConNome,
    documentoInput
  } = props

  const { pathEntita, nomeEntita } = propsEntita
  const { sostituisce } = documentoInput

  const { openModal, modalRef } = useModal()

  const { risolviRif } = useEntita()

  if (!sostituisce) return null

  const sequenzaDocumentiSostituiti = costruisciSequenzaDocumentiSostituiti(
    documentoInput,
    pathEntita,
    { risolviRif }
  )

  return (
    <>
      <BaseIconButtons.AnnullaModifiche
        contenutoTooltip={`Vedi storia (${sequenzaDocumentiSostituiti.length} versioni)`}
        onClick={openModal}
        sx={{ color: 'black', py: 0 }}
      />

      <BaseModal
        ref={modalRef}
        titolo='Storia documento'
        maxWidth='md'
        contenutoNonCentrato
      >
        <ListaDocumentiSostituiti
          documento={documentoInput}
          path={pathEntita}
          nomeEntita={nomeEntita}
          trovaTemplateConNome={trovaTemplateConNome}
        />
      </BaseModal>
    </>
  )
}