import { nonInProduzione } from 'utils'
import { getSegmentiChiave, costruisciChiavePermesso, aggiungiPrefissoChiaveModulo } from './utilPermessiUser'

// COMMENTI INIZIALI VALIDI SOLO PER VECCHIA GESTIONE

// Formato di una chiave per un permesso utente:
// modulo;azione
// Una chiave può anche avere solo il modulo

// Esempi di chiavi:
// sinistri
// articoli;riattiva

// Ad ogni chiave è associata una stringa di un carattere:
// - 'd' indica che quella cosa è visibile solo in una build di sviluppo (non in produzione)
// - 'h' indica che quella cosa deve essere nascosta
// - 'r' indica che quella cosa deve essere mostrata in sola lettura
// - 'w' indica che quella cosa deve essere mostrata in lettura e scrittura
// L'assenza di una chiave equivale a impostare la chiave a 'w'

// Chi è staff vede tutto quello che non è SOLO_SVILUPPO
// Per rendere qualcosa visibile solo allo staff, impostare NASCOSTO
const TIPOLOGIE_PERMESSI_USER = {
  SOLO_SVILUPPO: 'd', // SOLO VECCHIA GESTIONE
  NASCOSTO: 'h',
  SOLA_LETTURA: 'r',
  LETTURA_E_SCRITTURA: 'w'
}
const { SOLO_SVILUPPO, NASCOSTO, SOLA_LETTURA, LETTURA_E_SCRITTURA } = TIPOLOGIE_PERMESSI_USER

const PERMESSI_VALORI_NUMERICI = {
  [NASCOSTO]: 10,
  [SOLA_LETTURA]: 20,
  [LETTURA_E_SCRITTURA]: 30
}

function useLogicaPermessiUser(props) {
  const {
    permessi,
    nuovaGestionePermessi = false
  } = props

  if (nuovaGestionePermessi) {
    return (function logicaNuovaGestione() {
      // NUOVA GESTIONE: ci sono solo chiavi esatte, non più moduli e azioni

      // Ad ogni chiave è associata una stringa di un carattere:
      // - 'h' indica che quella cosa deve essere nascosta
      // - 'r' indica che quella cosa deve essere mostrata in sola lettura
      // - 'w' indica che quella cosa deve essere mostrata in lettura e scrittura
      // L'assenza di una chiave equivale ad 'h'
      // Essere staff equivale a 'w'

      function permessoMaggioreOUguale(p1, p2) {
        return PERMESSI_VALORI_NUMERICI[p1] >= PERMESSI_VALORI_NUMERICI[p2]
      }

      function userHaPermessoMinimo(chiave, permessoMinimo) {
        return permessoMaggioreOUguale(getPermessoUser(chiave), permessoMinimo)
      }

      function userHaPermessoEsatto(chiave, permessoEsatto) {
        return getPermessoUser(chiave) === permessoEsatto
      }

      function userPuoVedere(chiave) {
        return userHaPermessoMinimo(chiave, SOLA_LETTURA)
      }

      function userPuoVedereInSolaLettura(chiave) {
        return userHaPermessoEsatto(chiave, SOLA_LETTURA)
      }

      function userPuoVedereInLetturaScrittura(chiave) {
        return userHaPermessoEsatto(chiave, LETTURA_E_SCRITTURA)
      }

      function userPuoVedere_AlmenoUnPermessoTra(chiaviPermessi) {
        if (!chiaviPermessi || chiaviPermessi.length === 0) return true
        return almenoUnPermessoCon(chiaviPermessi, chiave => userPuoVedere(chiave))
      }

      function userPuoVedereInSolaLettura_AlmenoUnPermessoTra(chiaviPermessi) {
        if (!chiaviPermessi || chiaviPermessi.length === 0) return false
        return (
          almenoUnPermessoCon(chiaviPermessi, chiave => userPuoVedereInSolaLettura(chiave))
          && !almenoUnPermessoCon(chiaviPermessi, chiave => userHaPermessoEsatto(chiave, LETTURA_E_SCRITTURA))
        )
      }

      function userPuoVedere_AlmenoUnModuloTra(chiaviModuli) {
        if (!chiaviModuli || chiaviModuli.length === 0) return true
        return almenoUnModuloCon(chiaviModuli, chiave => userPuoVedere(chiave))
      }

      function userPuoVedereInSolaLettura_AlmenoUnModuloTra(chiaviModuli) {
        if (!chiaviModuli || chiaviModuli.length === 0) return false
        return (
          almenoUnModuloCon(chiaviModuli, chiave => userPuoVedereInSolaLettura(chiave))
          && !almenoUnModuloCon(chiaviModuli, chiave => userHaPermessoEsatto(chiave, LETTURA_E_SCRITTURA))
        )
      }

      // Cerca un permesso applicabile alla chiave passata
      // Con la nuova gestione, se non trovo il permesso nascondo tutto
      function getPermessoUser(chiave) {
        // Non dovrebbe mai succedere, ma se non c'è l'oggetto dei permessi nascondo tutto
        if (!permessi) return NASCOSTO

        // Se non viene passata una chiave, nascondo
        if (!chiave) {
          // Metto il warning perché non dovrebbe mai succedere
          console.warn('Chiave permesso non passata')
          return NASCOSTO
        }

        // Staff può fare sempre tutto
        if (permessi.is_staff) return LETTURA_E_SCRITTURA

        // Se lo trovo, restituisco il permesso definito per la chiave esatta
        const permessoMatchatoEsattamente = permessi[chiave]
        if (permessoMatchatoEsattamente) return permessoMatchatoEsattamente

        // A questo punto non è definito un permesso per la chiave esatta, quindi nascondo
        return NASCOSTO
      }

      function almenoUnPermessoCon(chiaviPermessi, condizione) {
        return chiaviPermessi.some(chiave => condizione(chiave))
      }

      function almenoUnModuloCon(chiaviModuli, condizione) {
        return chiaviModuli.some(chiave => condizione(aggiungiPrefissoChiaveModulo(chiave)))
      }

      return {
        permessoMaggioreOUguale,
        userHaPermessoMinimo,
        userHaPermessoEsatto,
        userPuoVedere,
        userPuoVedereInSolaLettura,
        userPuoVedereInLetturaScrittura,
        userPuoVedere_AlmenoUnPermessoTra,
        userPuoVedereInSolaLettura_AlmenoUnPermessoTra,
        userPuoVedere_AlmenoUnModuloTra,
        userPuoVedereInSolaLettura_AlmenoUnModuloTra
      }
    })()
  }

  function userPuoVedere(chiave) {
    const permesso = getPermessoUser(chiave)
    if (permesso === SOLO_SVILUPPO && !nonInProduzione()) return false
    if (permessi?.is_staff) return true
    return permesso !== NASCOSTO
  }

  function userPuoVedereInSolaLettura(chiave) {
    const permesso = getPermessoUser(chiave)
    if (permesso === SOLO_SVILUPPO && !nonInProduzione()) return false
    if (permessi?.is_staff) return false
    return permesso === SOLA_LETTURA
  }

  function userPuoVedere_AlmenoUnModuloTra(moduli) {
    if (!moduli || moduli.length === 0) return true
    return moduli.some(modulo =>
      userPuoVedere(costruisciChiavePermesso(modulo))
    )
  }

  function userPuoVedereInSolaLettura_AlmenoUnModuloTra(moduli) {
    if (!moduli || moduli.length === 0) return false
    return moduli.some(modulo =>
      userPuoVedereInSolaLettura(costruisciChiavePermesso(modulo))
    )
  }

  // Cerca un permesso applicabile alla chiave passata
  function getPermessoUser(chiave) {
    // Non dovrebbe mai succedere, ma se non c'è l'oggetto dei permessi nascondo tutto
    if (!permessi) return NASCOSTO

    // Se non viene passata una chiave, faccio vedere in lettura e scrittura
    if (!chiave) {
      // Metto il warning perché non dovrebbe mai succedere
      console.warn('Chiave permesso non passata')
      return LETTURA_E_SCRITTURA
    }

    // Se lo trovo, restituisco il permesso definito per la chiave esatta
    const permessoMatchatoEsattamente = getPermessoPerChiaveEsatta(chiave)
    if (permessoMatchatoEsattamente) return permessoMatchatoEsattamente

    // A questo punto non è definito un permesso per la chiave esatta
    // Se la chiave è relativa ad un'azione, cerco un permesso definito sul modulo
    const [modulo, azione] = getSegmentiChiave(chiave)
    if (azione) {
      const permessoMatchatoEsattamente = getPermessoPerChiaveEsatta(modulo)
      if (permessoMatchatoEsattamente) return permessoMatchatoEsattamente
    }

    // A questo punto non ho trovato nessun permesso applicabile alla chiave
    return LETTURA_E_SCRITTURA
  }

  function getPermessoPerChiaveEsatta(chiave) {
    return permessi[chiave]
  }

  return {
    userPuoVedere,
    userPuoVedereInSolaLettura,
    userPuoVedere_AlmenoUnModuloTra,
    userPuoVedereInSolaLettura_AlmenoUnModuloTra
  }
}

export {
  TIPOLOGIE_PERMESSI_USER,
  useLogicaPermessiUser
}