import { useCallback, useEffect, useRef } from 'react'
import { arrayOf, shape, string, func, number, bool } from 'prop-types'
import { BaseGridLayout } from 'layout'
import { useIsFirstRender } from 'utils'
import BaseImmagine from './BaseImmagine'

BaseListaImmaginiThumbnail.propTypes = {
  immagini: arrayOf(shape({
    titolo: string,
    uuid: string,
    src: shape({
      thumbnail: string.isRequired
    }).isRequired
  })).isRequired,
  onClick: func, // Riceve l'indice dell'immagine cliccata 
  indiceImmagineDaEvidenziare: number,
  riduciOpacitaImmaginiNonEvidenziate: bool,
  noWrap: bool
}

export default function BaseListaImmaginiThumbnail(props) {
  const {
    immagini,
    onClick,
    indiceImmagineDaEvidenziare,
    riduciOpacitaImmaginiNonEvidenziate,
    noWrap,
    ...restProps
  } = props

  const immagineEvidenziataRef = useRef(null)

  const scrollaPerMostrareImmagineEvidenziata = useCallback(() => {
    immagineEvidenziataRef.current?.scrollIntoView()
  }, [])

  const isFirstRender = useIsFirstRender()

  // Fai in modo che l'immagine evidenziata sia sempre visibile nella lista
  useEffect(() => {
    scrollaPerMostrareImmagineEvidenziata()
    if (isFirstRender) {
      setTimeout(() => scrollaPerMostrareImmagineEvidenziata(), 250)
    }
  }, [immagini, indiceImmagineDaEvidenziare, isFirstRender, scrollaPerMostrareImmagineEvidenziata])

  return (
    <BaseGridLayout alignCenter noWrap={noWrap}>
      {immagini.map((immagine, index) =>
        <BaseImmagine
          immagine={immagine}
          thumbnail
          key={immagine.uuid || immagine.src.thumbnail}
          onClick={() => onClick?.(index)}
          style={{
            cursor: 'zoom-in',
            ...(index === indiceImmagineDaEvidenziare && {
              border: '5px solid #ed6c02',
              borderRadius: '4px'
            }),
            ...(riduciOpacitaImmaginiNonEvidenziate && index !== indiceImmagineDaEvidenziare) && {
              opacity: 0.4,
              cursor: 'default'
            }
          }}
          inputRef={(index === indiceImmagineDaEvidenziare) ? immagineEvidenziataRef : null}
          {...restProps}
        />
      )}
    </BaseGridLayout>
  )
}