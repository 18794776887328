import { useRef } from 'react'
import { matchPath, useLocation } from 'react-router-dom'

/**
 * @typedef {import("../common/utils/Routes").findRouteWithPath} findRouteWithPath
 */

// Le routes devono essere specificate in un array di questo tipo:
// const ROUTES_CON_COMANDO = [
//   {
//     pathRelativo: '/impostazioniGenerali',
//     chiave: CHIAVI_ROUTES.IMPOSTAZIONI_GENERALI,
//     label: 'Impostazioni generali',
//     Content: ImpostazioniGenerali,
//     nascostaNeiMenu: true
//   },

//   {
//     pathRelativo: '/sinistri',
//     chiave: CHIAVI_ROUTES.SINISTRI,
//     label: 'Sinistri',
//     Content: Sinistri,
//     Icon: IconaSinistri,
//     subRoutes: [
//       {
//         pathRelativo: '/:uuid',
//         chiave: CHIAVI_ROUTES.SINISTRO,
//         label: 'Sinistro',
//         Content: Sinistro,
//         nascostaNeiMenu: true
//       }
//     ]
//   }
// ]
function setupRoutes(routes) {
  aggiungiRiferimentiParent_E_PathAssoluti(routes)
  const visibiliNeiMenu = filterRoutes(routes, ({ nascostaNeiMenu }) => !nascostaNeiMenu)
  const flattened = flattenRoutes(routes)

  return {
    visibiliNeiMenu,
    flattened,
    findRouteWithPath: pathAssolutoCercato => findRouteWithPath(flattened, pathAssolutoCercato),
    isValidPath: pathAssolutoCercato => isValidPath(flattened, pathAssolutoCercato),
    findRouteWithKey: chiaveCercata => findRouteWithKey(flattened, chiaveCercata),
    getPath: chiaveCercata => findRouteWithKey(flattened, chiaveCercata)?.pathAssoluto
  }
}

// Riceve una lista di oggetti generati da setupRoutes
// Restituisce delle funzioni di ricerca che trovano risultati in tutte le routes
function mergeFunzioniRicerca_DiPiuRoutes_DopoSetup(gruppiRoutesDopoSetup) {
  const flattened_ArrayDiArray = gruppiRoutesDopoSetup.map(({ flattened }) => flattened)
  const flattenedConcatenate = [].concat(...flattened_ArrayDiArray)
  return {
    findRouteWithPath: pathAssolutoCercato => findRouteWithPath(flattenedConcatenate, pathAssolutoCercato),
    isValidPath: pathAssolutoCercato => isValidPath(flattenedConcatenate, pathAssolutoCercato),
    findRouteWithKey: chiaveCercata => findRouteWithKey(flattenedConcatenate, chiaveCercata),
    getPath: chiaveCercata => findRouteWithKey(flattenedConcatenate, chiaveCercata)?.pathAssoluto
  }
}

// Modifica le routes passate
// Aggiunge ad ogni subroute un riferimento alla sua parent route
// Aggiunge a tutte le routes un campo "pathAssoluto", che è la concatenazione dei path relativi
function aggiungiRiferimentiParent_E_PathAssoluti(routes) {
  routes.forEach(aggiungiRiferimentiParent_SingolaRoute)
  routes.forEach(parentRoute => aggiungiPathCompleti_SingolaRoute(parentRoute))

  function aggiungiRiferimentiParent_SingolaRoute(parentRoute) {
    const { subRoutes } = parentRoute
    if (subRoutes) {
      subRoutes.forEach(subRoute => {
        subRoute.parentRoute = parentRoute
        aggiungiRiferimentiParent_SingolaRoute(subRoute)
      })
    }
  }

  function aggiungiPathCompleti_SingolaRoute(parentRoute, pathAssoluto_CostruitoFinOra = '') {
    const { subRoutes, pathRelativo } = parentRoute
    const pathAssoluto_Aggiornato = `${pathAssoluto_CostruitoFinOra}${pathRelativo}`
    parentRoute.pathAssoluto = pathAssoluto_Aggiornato

    if (!subRoutes) return
    subRoutes.forEach(subRoute => {
      aggiungiPathCompleti_SingolaRoute(subRoute, pathAssoluto_Aggiornato)
    })
  }
}

// Parte da una route foglia e restituisce la sequenza delle sue parent routes
// Utile per creare breadcrumbs e cose simili
function getSequenceOfParentRoutes(route, sequenza_CostruitaFinOra = []) {
  const sequenza_Aggiornata = [route, ...sequenza_CostruitaFinOra]

  const { parentRoute } = route
  if (!parentRoute) return sequenza_Aggiornata
  return getSequenceOfParentRoutes(parentRoute, sequenza_Aggiornata)
}

// Le liste di routes filtrate mantengono la struttura innestata originale
function filterRoutes(routes, filter) {
  return routes
    // Aplica il filtro alle parent routes
    .filter(filter)
    // Aplica il filtro alle subroutes
    .map(parentRoute => {
      const { subRoutes } = parentRoute
      if (!subRoutes) return { ...parentRoute }
      return {
        ...parentRoute,
        subRoutes: filterRoutes(subRoutes, filter)
      }
    })
}

// Le liste di routes flattened NON mantengono la struttura innestata originale
// Sono comode per effettuare ricerche e per essere inserite nello Switch di react-router
function flattenRoutes(routes) {
  return routes.reduce(
    // Le subroutes sono messe PRIMA delle rispettive parent routes, 
    // per essere matchate correttamente dallo Switch
    (flattenedParentRoutes, parentRouteCorrente) => {
      const flattenedSubRoutes = flattenRoutes(parentRouteCorrente.subRoutes || [])
      return [...flattenedParentRoutes, ...flattenedSubRoutes, parentRouteCorrente]
    },
    []
  )
}

/**
 * 
 * @type {findRouteWithPath}
 * 
 * @param {string} flattenedRoutes 
 * @param {string | undefined} pathAssolutoCercato 
 * @returns {import('common').Route | undefined}
 */
function findRouteWithPath(flattenedRoutes, pathAssolutoCercato) {
  return flattenedRoutes.find(({ pathAssoluto }) => {
    const match = matchPath(pathAssolutoCercato, { path: pathAssoluto })
    return Boolean(match)
  })
}

function isValidPath(flattenedRoutes, pathAssolutoCercato) {
  // Il path "/" esiste sempre e nel 99.99% dei casi è la rotta home
  if (pathAssolutoCercato === '/') return true

  return flattenedRoutes.some(({ pathAssoluto }) => {
    // Se non mettessi questo controllo, qualsiasi path sarebbe valido perché "/" matcha tutto
    // La rotta home continua a funzionare grazie alla condizione messa prima
    if (pathAssoluto === '/') return false

    const match = matchPath(pathAssolutoCercato, { path: pathAssoluto })
    return Boolean(match)
  })
}

function findRouteWithKey(flattenedRoutes, chiaveCercata) {
  return flattenedRoutes.find(({ chiave }) => chiave === chiaveCercata)
}

function useQueryString() {
  const queryParams_ReactRouter = decodeQueryString(useLocation().search)
  const queryParams_Browser = decodeQueryString(window.location.search)
  return { ...queryParams_Browser, ...queryParams_ReactRouter }
}

function decodeQueryString(queryString) {
  const keyValuePairs = new URLSearchParams(queryString).entries()
  return Array.from(keyValuePairs).reduce(
    (oggettoAccumulatore, [key, value]) => ({ ...oggettoAccumulatore, [key]: value }),
    {}
  )
}

function encodeQueryString(keyValuePairs) {
  const searchParams = new URLSearchParams()
  Object.entries(keyValuePairs).forEach(([key, value]) => searchParams.set(key, value))
  return searchParams.toString()
}

function buildURL_WithQueryString(URL_WithoutQueryString, keyValuePairs) {
  const baseURL = new URL(URL_WithoutQueryString, window.location.origin)
  Object.entries(keyValuePairs).forEach(([key, value]) => baseURL.searchParams.set(key, value))
  return baseURL.href
}

function deleteParamsQueryString(queryString, paramKeys = []) {
  const searchParams = new URLSearchParams(queryString)
  paramKeys.forEach(key => searchParams.delete(key))
  return searchParams.toString()
}

function addParamsQueryString(queryString, params = {}) {
  const searchParams = new URLSearchParams(queryString)
  Object.keys(params).forEach( key => searchParams.set(key, params[key]))
  return searchParams.toString()
}
function useAddHashQueryString() {
  const location_React = useLocation()

  function addParams(paramKeys) {
    if (Object.keys(paramKeys).length === 0) return

    const {
      origin,
      pathname: pathname_Browser,
    } = window.location

    const {
      pathname: pathname_React,
      search: queryString_React
    } = location_React

    let url = new URL(origin + pathname_Browser)
    let hash = `#${pathname_React}`
    let nuovaQueryString_React = addParamsQueryString(queryString_React, paramKeys)
    if (nuovaQueryString_React) hash += `?${nuovaQueryString_React}`
    url.hash = hash
    window.location.replace(url)
  }

  const addParams_Ref = useRef(addParams)
  addParams_Ref.current = addParams

  return { addParams: addParams_Ref.current }
}

function useDeleteQueryString() {
  const location_React = useLocation()

  function deleteParams(...paramKeys) {
    if (paramKeys.length === 0) return

    const {
      origin,
      pathname: pathname_Browser,
      search: queryString_Browser
    } = window.location

    const {
      pathname: pathname_React,
      search: queryString_React
    } = location_React

    let url = new URL(origin + pathname_Browser)
    url.search = deleteParamsQueryString(queryString_Browser, paramKeys)
    let hash = `#${pathname_React}`
    let nuovaQueryString_React = deleteParamsQueryString(queryString_React, paramKeys)
    if (nuovaQueryString_React) hash += `?${nuovaQueryString_React}`
    url.hash = hash
    window.location.replace(url)
  }

  const deleteParams_Ref = useRef(deleteParams)
  deleteParams_Ref.current = deleteParams

  return { deleteParams: deleteParams_Ref.current }
}

function costruisciRotta(...segmentiDaAggiungere) {
  return segmentiDaAggiungere.reduce(
    (rottaInCostruzione, segmentoDaAggiungere) => {
      if (typeof segmentoDaAggiungere !== 'string'
        && typeof segmentoDaAggiungere !== 'number'
        && typeof segmentoDaAggiungere !== 'boolean'
      ) return rottaInCostruzione

      const separatore = String(segmentoDaAggiungere)?.startsWith('/') ? '' : '/'
      return [rottaInCostruzione, segmentoDaAggiungere].join(separatore)
    },
    ''
  )
}

function eliminaUltimoSegmentoRotta(rotta) {
  if (typeof rotta !== 'string') return
  if (!rotta.includes('/')) return ''
  return rotta.slice(0, rotta.lastIndexOf('/'))
}

export {
  setupRoutes,
  mergeFunzioniRicerca_DiPiuRoutes_DopoSetup,
  getSequenceOfParentRoutes,
  filterRoutes,
  findRouteWithPath,
  findRouteWithKey,
  useQueryString,
  decodeQueryString,
  encodeQueryString,
  buildURL_WithQueryString,
  deleteParamsQueryString,
  useDeleteQueryString,
  useAddHashQueryString,
  costruisciRotta,
  eliminaUltimoSegmentoRotta
}