import { useRef, useState } from 'react'
import { Box, LinearProgress } from '@mui/material'
import { BaseFeedbackConIcona } from 'feedback'
import { ICONE } from 'icons'
import { BaseButtons, BaseForm } from 'inputs'
import { useModal, BaseModal, BaseGridLayout, BaseScatolaConTitolo } from 'layout'
import { BaseTesto } from 'text'
import useRichiestaServer from '../network/RichiestaServerHook'

export default function VisuraGenerica(props) {
  const {
    titolo = 'Visura',
    nessunaVisuraDisponibile,
    propsBottoneVisuraEsterno,
    propsModal,
    ...propsContenutoModal
  } = props

  const { modalRef, openModal, closeModal } = useModal()

  return (
    <>
      <BaseButtons.Custom
        testo={titolo}
        color='info'
        onClick={openModal}
        iconaInizio={ICONE.CERCA}
        {...propsBottoneVisuraEsterno}
      />

      <BaseModal
        ref={modalRef}
        maxWidth='md'
        titolo={titolo}
        {...propsModal}
      >
        {nessunaVisuraDisponibile ? (
          <BaseGridLayout justifyCenter>
            <BaseFeedbackConIcona.Attenzione
              messaggio='Nessun servizio di visura abilitato'
            />
          </BaseGridLayout>
        ) : (
          <ContenutoModalVisura
            {...propsContenutoModal}
            closeModal={closeModal}
          />
        )}
      </BaseModal>
    </>
  )
}



const COMPONENTI_FEEDBACK_AVVISO = {
  info: BaseFeedbackConIcona.Info,
  warning: BaseFeedbackConIcona.Attenzione,
  error: BaseFeedbackConIcona.Errore
}

function ContenutoModalVisura(props) {
  const {
    // Props esterne
    defaultValues,
    ContenutoFormVisura,
    useLogicaVisura,
    importaDati,
    RisultatoVisuraCustom,
    propsBaseForm,

    // Props interne
    closeModal
  } = props

  // Deve essere un oggetto del tipo { risultato, html, avvisi }
  const [rispostaVisura, setRispostaVisura] = useState(null)

  const {
    ScatolaMessaggi,
    inviaOperazione,
    inAttesaDelServer
  } = useRichiestaServer()

  const {
    eseguiVisura
  } = useLogicaVisura({
    inviaOperazione,
    setRispostaVisura
  })

  const formRef = useRef()

  return (
    <>
      <Box sx={{ display: rispostaVisura ? 'none' : 'block' }}>
        <BaseForm
          defaultValues={defaultValues}
          onSubmit={eseguiVisura}
          inAttesa={inAttesaDelServer}
          larghezzaInputs='lungo'
          ref={formRef}
          {...propsBaseForm}
        >
          <ContenutoFormVisura formRef={formRef} />
        </BaseForm>

        <BaseGridLayout justifyCenter>
          <BaseFeedbackConIcona.Attenzione
            messaggio='Attenzione: potrebbe comportare dei costi'
          />
        </BaseGridLayout>

        {inAttesaDelServer && <LinearProgress sx={{ mt: 2 }} />}

        {ScatolaMessaggi}
      </Box>

      {rispostaVisura &&
        <>
          <Box sx={{ my: 3, textAlign: 'left' }}>
            {RisultatoVisuraCustom ? (
              <RisultatoVisuraCustom {...rispostaVisura} />
            ) : (
              <BaseScatolaConTitolo titolo='Risultato visura'>
                <div dangerouslySetInnerHTML={{ __html: rispostaVisura.html }} />
              </BaseScatolaConTitolo>
            )}
          </Box>

          <BaseScatolaConTitolo titolo='Avvisi' sx={{ mb: 2 }}>
            {rispostaVisura.avvisi.map(({ level, msg }, index) => {
              const ComponenteFeedback = COMPONENTI_FEEDBACK_AVVISO[level] ?? BaseFeedbackConIcona.Attenzione
              return <ComponenteFeedback messaggio={msg} key={index} />
            })}
            {rispostaVisura.avvisi.length === 0 &&
              <BaseTesto sx={{ textAlign: 'left' }}>Nessun avviso</BaseTesto>
            }
          </BaseScatolaConTitolo>

          <BaseGridLayout alignCenter justifyCenter>
            <BaseButtons.Conferma
              testo='Importa dati'
              onClick={() => {
                importaDati(rispostaVisura.risultato)
                closeModal()
              }}
            />
            <BaseButtons.Chiudi
              testo='Annulla'
              onClick={closeModal}
            />
          </BaseGridLayout>
        </>
      }
    </>
  )
}