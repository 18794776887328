import { formattaDataEOra} from '@lucan/base-ui';
import { Link } from '@mui/material';
import React, { useState } from 'react';


const LastLogins = (props) => {
  
  const {logins} = props;
  
  const renderItem = (i) => <span>{`${i.esito ? 'Valido ' : 'Errato '} del  ${ formattaDataEOra(new Date(i.dataTs))} con ip ${i.remoteHost}`}</span>
 	return (logins ? 
		<ListToggle style={{fontSize:'75%',textAlign:'left', display:'block'}} items={logins} renderItem={renderItem} single={'Ultimo accesso: '} multiple={'Accessi:'} {...props}/>:
    <></>
	);
};

const ListToggle = ({items, renderItem, single, multiple, ...props}) => {
  const [toggle, settoggle] = useState(true)
  const toggleClik = ()=> {
    settoggle(!toggle)
  }
  
  return (
    items.length > 0 &&
    <span {...props}>
    {
      toggle ?
      (
        <>{single}{renderItem(items[0])}  - {items.length > 1 && <Link onClick={toggleClik}>mostra tutti</Link>}</>      
      ):
      (
        <>
          <span>{multiple}</span>
          <ul>
          {items.map((i, index) =><li key={index}>{renderItem(i, index)}</li>)}
          </ul>
          <Link onClick={toggleClik}>x chiudi</Link>
        </>
      )
    }      
    </span>
	);
}

export default LastLogins;