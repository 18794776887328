import { arrayOf, shape, object, string, number, func, bool, oneOf } from 'prop-types'
import { Box, Grid, TableSortLabel } from '@mui/material'
import { BaseScatola } from 'layout'
import { BaseGrassetto } from 'text'
import { sx } from 'utils'

RiassuntoEntita.propTypes = {
  sezioni: arrayOf(shape({
    key: string.isRequired,
    label: string,
    width: number.isRequired,
    sortKey: string,
    Content: func.isRequired
  })).isRequired,
  entita: object,
  soloLegenda: bool,
  activeSortKey: string,
  activeSortDirection: oneOf(['asc', 'desc']),
  onChangeSort: func,
  propsGridContainer: object
}

const PADDING = 1

export default function RiassuntoEntita(props) {
  const {
    sezioni,
    entita,
    soloLegenda,
    activeSortKey,
    activeSortDirection,
    onChangeSort,
    propsGridContainer: propsGridContainer_PassateDaFuori,
    ...restProps
  } = props

  const propsGridContainer = {
    container: true,
    columns: 20,
    spacing: 2,
    ...propsGridContainer_PassateDaFuori
  }

  if (soloLegenda) {
    return (
      <Box
        {...restProps}
        {...sx(restProps, { px: PADDING })}
      >
        <Grid {...propsGridContainer}>
          {sezioni.map(({ key, width, label, sortKey }) =>
            <Grid item xs={width} key={key}>
              <LabelLegendaConSorting
                label={label}
                sortKey={sortKey}
                activeSortKey={activeSortKey}
                activeSortDirection={activeSortDirection}
                onChangeSort={onChangeSort}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    )
  }

  return (
    <BaseScatola
      outlined
      {...restProps}
      {...sx(restProps, { p: PADDING, bgcolor: 'rgba(0, 0, 0, 0.05)' })}
    >
      <Grid {...propsGridContainer}>
        {sezioni.map(({ key, width, Content }) =>
          <Grid item xs={width} key={key}>
            <Content {...entita} />
          </Grid>
        )}
      </Grid>
    </BaseScatola>
  )
}



function LabelLegendaConSorting(props) {
  const {
    label,
    sortKey,
    activeSortKey,
    activeSortDirection,
    onChangeSort
  } = props

  const labelTesto = (
    <BaseGrassetto sx={{ fontStyle: 'italic' }}>
      {label}
    </BaseGrassetto>
  )

  if (!sortKey) return labelTesto

  const isActive = (sortKey === activeSortKey)

  return (
    <TableSortLabel
      active={isActive}
      direction={isActive ? activeSortDirection : 'desc'}
      onClick={() => onChangeSort(
        sortKey,
        (isActive && activeSortDirection === 'desc') ? 'asc' : 'desc'
      )}
      sx={{
        '& .MuiTableSortLabel-icon': { fontSize: '1.75rem' },
        '&.Mui-active .MuiTableSortLabel-icon': { color: 'info.main' },
      }}
    >
      {labelTesto}
    </TableSortLabel>
  )

}