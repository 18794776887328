import { BaseTitolo, BaseTesto, BaseButtons } from '@lucan/base-ui';
import React from 'react';


const AccessoBloccato = (props) => {
	const {messaggio, logout} = props
	return (
		<div><BaseTitolo className="titolo">Attenzione, l'utenza è stata bloccata</BaseTitolo>
		{messaggio && <BaseTesto>{messaggio}</BaseTesto>}
		<br />
		<BaseButtons.Logout testo="torna al login" color={'primary'} variant="outlined" onClick={logout}  className='full' />
		</div>
	);
};

export default AccessoBloccato;