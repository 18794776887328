const STATI_ENTITA = {
  BOZZA: 'BOZZA',
  IN_LAVORAZIONE: 'IN_LAVORAZIONE',
  CHIUSO: 'CHIUSO',
  ANNULLATO: 'ANNULLATO',
  ELIMINATO: 'ELIMINATO'
}

const COLOR_STATI_ENTITA = {
  [STATI_ENTITA.BOZZA]: 'info',
  [STATI_ENTITA.IN_LAVORAZIONE]: 'info',
  [STATI_ENTITA.CHIUSO]: 'success',
  [STATI_ENTITA.ANNULLATO]: 'error',
  [STATI_ENTITA.ELIMINATO]: 'error'
}

const LABEL_STATI_ENTITA = {
  [STATI_ENTITA.BOZZA]: 'Bozza',
  [STATI_ENTITA.IN_LAVORAZIONE]: 'In lavorazione',
  [STATI_ENTITA.CHIUSO]: 'Completato',
  [STATI_ENTITA.ANNULLATO]: 'Annullato',
  [STATI_ENTITA.ELIMINATO]: 'Eliminato'
}

export {
  STATI_ENTITA,
  COLOR_STATI_ENTITA,
  LABEL_STATI_ENTITA
}