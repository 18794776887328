import { object, bool } from 'prop-types'
import { ICONE } from 'icons'
import { BaseAccordion, BaseHeaderConIcona } from 'layout'
import { BaseListaCoppieChiaveValore } from 'text'
import { formattaCognomeNome } from 'utils'

Agente.propTypes = {
  agente: object.isRequired,
  visioneRiassuntiva: bool,
  propsHeader: object
}

export default function Agente(props) {
  const {
    agente,
    visioneRiassuntiva,
    propsHeader,
    ...restProps
  } = props

  if (!agente) return null

  const {
    grado,
    gradoDescr,
    matricola,
    qualifica
  } = agente

  const cognomeNome = formattaCognomeNome(agente)
  const titolo = matricola ? `${cognomeNome} (${matricola})` : cognomeNome
  let gradoFormattato = gradoDescr || grado
  if (qualifica) gradoFormattato += ` --- ${qualifica}`

  const header = (
    <BaseHeaderConIcona
      icona={<ICONE.PERSONA />}
      titolo={titolo}
      sottotitolo={gradoFormattato}
      {...propsHeader}
    />
  )

  if (visioneRiassuntiva) return header

  return (
    <BaseAccordion
      contenutoNonEspanso={header}
      contenutoEspanso={
        <BaseListaCoppieChiaveValore
          titolo='Dati agente'
          coppie={{
            'Cognome e nome': cognomeNome,
            'Grado': gradoFormattato,
            'Matricola': matricola
          }}
        />
      }
      {...restProps}
    />
  )
}