import { useCallback, useEffect, useMemo, useState } from 'react'
import { func, elementType, array, number, string, object } from 'prop-types'
import { Box, List } from '@mui/material'
import { useListaConScrollingInfinito } from 'utils'
import { BaseBarraRicercaConRitardo } from './BaseBarraRicercaConRitardo'

const BaseListaFiltrabile = (props) => {
  const {
    options = [],
    matchFn,
    nascondiFiltroElementi = 4,
    renderOption,
    as = List,
    Filtro = BaseListaFiltrabile.Filtro,
    filtroProps,
    sx,
    onChange,
    listSx = { overflow: 'auto', maxHeight: 'calc(100vh - 390px)' },
    dimensioneBloccoElementi = 20,
    selectOpzione
  } = props

  const As = as
  const {
    listaFiltrata,
    setcriteri,
    mostraFiltroFlag
  } = useListaFiltrabile({
    lista: options,
    matchFn,
    nascondiSeMenoDi: nascondiFiltroElementi
  })

  const [elementoDOMcontenitore, setElementoDOMcontenitore] = useState(null)

  const elementoDOMcontenitore_Ref = useCallback(
    elemento => setElementoDOMcontenitore(elemento),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const [elementoDOMdaScrollare, setElementoDOMdaScrollare] = useState(null)

  const elementoDOMdaScrollare_Ref = useCallback(
    elemento => setElementoDOMdaScrollare(elemento),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  const {
    listaDaVisualizzare,
    spinnerCaricamento
  } = useListaConScrollingInfinito({
    dimensioneBloccoElementi,
    lista: listaFiltrata,
    elementoDOMcontenitore,
    elementoDOMdaScrollare,
    offsetScroll: 50
  })

  useEffect(() => {
    onChange?.(listaFiltrata)
  }, [listaFiltrata])

  const [indiceOpzioneEvidenziata, setIndiceOpzioneEvidenziata] = useState(-1)

  useEffect(() => {
    function keyboardListener(e) {
      switch (e.key) {
        case 'Enter': {
          const opzioneScelta = listaDaVisualizzare[indiceOpzioneEvidenziata]
          if (opzioneScelta) selectOpzione(opzioneScelta)
          break
        }
      }
    }

    window.addEventListener('keydown', keyboardListener)
    return () => window.removeEventListener('keydown', keyboardListener)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indiceOpzioneEvidenziata, listaDaVisualizzare, selectOpzione])

  useEffect(() => {
    setIndiceOpzioneEvidenziata(-1)
  }, [listaDaVisualizzare])

  return (
    <Box sx={sx}>
      {mostraFiltroFlag &&
        <Filtro
          onChange={setcriteri}
          options={options}
          {...filtroProps}
          inputProps={{ autoComplete: 'off' }}
        />
      }

      <Box>
        <Box sx={listSx} ref={elementoDOMdaScrollare_Ref}>
          {listaDaVisualizzare.length > 0 ? (
            <As ref={elementoDOMcontenitore_Ref}>
              {listaDaVisualizzare.map((option, indice) =>
                renderOption(option, {
                  onFocus: () => setIndiceOpzioneEvidenziata(indice),
                  onBlur: () => setIndiceOpzioneEvidenziata(-1),
                })
              )}
            </As>
          ) : (
            <>{mostraFiltroFlag ? 'Nessun elemento corrisponde ai criteri' : 'Nessun elemento'}</>
          )}

          {spinnerCaricamento}
        </Box>
      </Box>
    </Box>
  )
}



const Filtro = (props) => {
  const {
    label = 'Ricerca',
    onChange,
    ...rest
  } = props

  const [testoRicerca_FinitoDiScrivere, setTestoRicerca_FinitoDiScrivere] = useState('')

  return (
    <BaseBarraRicercaConRitardo
      propsRitardo={{ millisecondsToBeStable: 300 }}
      label={label}
      testoRicerca_DefaultValue={testoRicerca_FinitoDiScrivere}
      setTestoRicerca_FinitoDiScrivere={testo => {
        setTestoRicerca_FinitoDiScrivere(testo)
        onChange?.(testo)
      }}
      nonUsareHookForm
      togliPrimaParteMessaggioAiuto={true}
      {...rest}
    />
  )
}



export const useListaFiltrabile = (props) => {
  const {
    lista,
    matchFn,
    nascondiSeMenoDi = 4,
    customCriteriNulli
  } = props

  const [criteri, setcriteri] = useState(null)

  const mostraFiltroFlag = lista.length > nascondiSeMenoDi

  const criterioNulli = (criteri) => {
    if (!mostraFiltroFlag || criteri === undefined || criteri === null) {
      return true
    }
    if (typeof criteri === 'string') {
      return criteri.length === 0
    }
    if (customCriteriNulli) {
      return customCriteriNulli(criteri)
    }
    throw new Error(typeof criteri + 'non implementato')
  }

  const listaFiltrata = useMemo(() => {
    return criterioNulli(criteri)
      ? lista
      : lista.filter(option => matchFn(option, criteri))
  }, [criteri, lista]
  )

  return {
    listaFiltrata,
    setcriteri,
    mostraFiltroFlag,
  }
}



BaseListaFiltrabile.propTypes = {
  label: string,
  options: array,
  nascondiFiltroElementi: number,
  matchFn: func.isRequired,
  renderOption: func.isRequired,
  as: elementType,
  Filtro: elementType,
  filtroProps: object,
  listSx: object,
  sx: object,
}

Filtro.propTypes = {
  label: string,
  onChange: func
}

BaseListaFiltrabile.Filtro = Filtro

export default BaseListaFiltrabile