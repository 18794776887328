import { useMemo } from 'react'
import { bool, array, func, number } from 'prop-types'
import { FixedSizeList as VirtualizedList } from 'react-window'
import { TableBody, useTheme } from '@mui/material'
import { convertiRemInPx } from 'utils'
import { getRowKey } from '../UtilsTabella'
import { RigaSingola } from './RigaSingola'

RigheTabella.propTypes = {
  rows: array.isRequired,
  prepareRow: func.isRequired,
  getTableBodyProps: func.isRequired,
  virtualizza: bool,
  numeroRigheVirtualizzateDaMostrare: number.isRequired,
  paddingCelle: number.isRequired
}

export default function RigheTabella(props) {
  const {
    rows,
    prepareRow,
    getTableBodyProps,
    virtualizza,
    numeroRigheVirtualizzateDaMostrare,
    paddingCelle
  } = props

  const propsRiga = { rows, prepareRow }

  return (
    <TableBody {...getTableBodyProps({ component: 'div' })}>
      {virtualizza ? (
        <RigheVirtualizzate
          propsRiga={propsRiga}
          numeroRigheVirtualizzateDaMostrare={numeroRigheVirtualizzateDaMostrare}
          paddingCelle={paddingCelle}
        />
      ) : (
        rows.map((row, index) =>
          <RigaSingola
            index={index}
            key={getRowKey(row, index)}
            data={propsRiga}
          />
        )
      )}
    </TableBody>
  )
}



function RigheVirtualizzate(props) {
  const {
    propsRiga,
    numeroRigheVirtualizzateDaMostrare,
    paddingCelle
  } = props

  const { rows } = propsRiga
  const numeroTotaleRighe = rows.length

  const { typography } = useTheme()
  const rowHeight = useMemo(() => {
    const lineHeightInRem = typography.body1.lineHeight
    return convertiRemInPx(lineHeightInRem) + 2 * paddingCelle
  }, [paddingCelle, typography.body1.lineHeight])

  return (
    <VirtualizedList
      height={rowHeight * Math.min(numeroRigheVirtualizzateDaMostrare, numeroTotaleRighe)}
      itemCount={numeroTotaleRighe}
      itemSize={rowHeight}
      itemData={propsRiga}
      itemKey={index => getRowKey(rows[index], index)}
      overscanCount={3}
      className='corpoTabellaVirtualizzata' // Regola impostata in index.css
    >
      {RigaSingola}
    </VirtualizedList>
  )
}