import { BaseDateInput } from 'inputs'
import { BaseScatolaConTitolo, BaseGridLayout } from 'layout'
import FormModificaEntita, { PROP_TYPES_WRAPPER_FORM_MODIFICA } from '../entitaGenerica/components/FormModificaEntita'

const NOMI = {
  DATA_REVISIONE: 'dataRevisione',
  DATA_SCADENZA: 'dataScadenza'
}

RevisioneModifica.propTypes = PROP_TYPES_WRAPPER_FORM_MODIFICA

export default function RevisioneModifica(props) {
  const { propsEntita, label } = props

  return (
    <BaseScatolaConTitolo titolo={label}>
      <FormModificaEntita {...propsEntita}>
        <BaseGridLayout>
          <BaseDateInput label='Data revisione' name={NOMI.DATA_REVISIONE} disableFuture />
          <BaseDateInput label='Data scadenza' name={NOMI.DATA_SCADENZA} />
        </BaseGridLayout>
      </FormModificaEntita>
    </BaseScatolaConTitolo>
  )
}