import { object, bool, node } from 'prop-types'
import { Grid, Box } from '@mui/material'
import { ICONE } from 'icons'
import { BaseAccordion, BaseHeaderConIcona } from 'layout'
import Assicurazione from './Assicurazione'
import DatiGenerali from './DatiGenerali'
import PersoneABordo from './PersoneABordo'
import Proprietario from './Proprietario'
import Revisione from './Revisione'
import { creaDescrizioneVeicolo } from './utilVeicolo'

Veicolo.propTypes = {
  veicolo: object.isRequired,
  visioneRiassuntiva: bool,
  propsHeader: object,
  contenutoExtraNonEspanso: node,
  contenutoExtraEspanso: node,
  nascondiPasseggeri: bool
}

export default function Veicolo(props) {
  const {
    veicolo,
    visioneRiassuntiva,
    propsHeader,
    contenutoExtraNonEspanso,
    contenutoExtraEspanso,
    nascondiPasseggeri,
    ...restProps
  } = props
  if (!veicolo) return null

  const {
    assicurazione,
    dataImmatricolazione,
    nazione: { second: nazione },
    passeggeri,
    proprietario,
    revisione,
    targa,
    telaio,
    tipo: { second: tipoVeicolo },
    veicoloNomi: { fabbrica: marca, tipo: modello }
  } = veicolo

  const header = (
    <BaseHeaderConIcona
      icona={<ICONE.VEICOLO />}
      titolo={creaDescrizioneVeicolo(veicolo)}
      sottotitolo={tipoVeicolo}
      {...propsHeader}
    />
  )

  if (visioneRiassuntiva) return header

  const personeABordo = (
    <PersoneABordo
      passeggeri={passeggeri}
      visioneRiassuntiva
    />
  )

  return (
    <BaseAccordion
      contenutoNonEspanso={
        <Grid container alignItems='center'>
          <Grid item md={3}>{header}</Grid>

          <Grid item md={3}>
            <Box padding={2}>
              <Proprietario proprietario={proprietario} visioneRiassuntiva />
            </Box>
          </Grid>

          <Grid item md={contenutoExtraNonEspanso ? 4 : 6} {...(nascondiPasseggeri && { visibility: 'hidden' })}>
            <Box padding={2}>
              {personeABordo}
            </Box>
          </Grid>

          {contenutoExtraNonEspanso &&
            <Grid item md={2}>
              <Box padding={2}>
                {contenutoExtraNonEspanso}
              </Box>
            </Grid>
          }
        </Grid>
      }

      contenutoEspansoHeader={
        <>
          <Grid item>{header}</Grid>

          {/* Contenuto invisibile per mantenere la stessa altezza
            dell'header sia nel caso di accordion espanso, sia non espanso. */}
          <Box padding={2} visibility='hidden'>
            {personeABordo}
          </Box>
        </>
      }

      contenutoEspanso={
        <Grid container spacing={2}>
          <Grid item md={4}>
            <DatiGenerali
              targa={targa}
              marca={marca}
              modello={modello}
              tipoVeicolo={tipoVeicolo}
              dataImmatricolazione={dataImmatricolazione}
              nazione={nazione}
              telaio={telaio}
            />
          </Grid>

          <Grid item md={2}>
            <Proprietario proprietario={proprietario} />
          </Grid>

          <Grid item md={2} {...(nascondiPasseggeri && { visibility: 'hidden' })}>
            <PersoneABordo passeggeri={passeggeri} />
          </Grid>

          <Grid item md={4}>
            <Assicurazione assicurazione={assicurazione} />
            <Box marginTop={1}>
              <Revisione revisione={revisione} />
            </Box>
          </Grid>

          {contenutoExtraEspanso &&
            <Grid item>{contenutoExtraEspanso}</Grid>
          }
        </Grid>
      }

      {...restProps}
    />
  )
}