import { bool, node ,string, object, number, array, oneOfType } from 'prop-types'
import { Box, Typography } from '@mui/material'
import { sx } from 'utils'
import BaseTitoloSkeleton from './BaseTitoloSkeleton'

BaseTitolo.propTypes = {
  isLoading: bool,
  children: node.isRequired,
  noMargine: bool,
  medio: bool,
  grande: bool,
  colorato: bool,
  icona: node,
  bottone: node,
  color: string,
  sx: oneOfType([object, array]),
  fontWeight: number,
}

export default function BaseTitolo(props) {
  const {
    isLoading = false,
    children,
    noMargine,
    medio,
    grande,
    colorato,
    icona,
    bottone,
    className = 'BaseTitolo',
    ...restProps
  } = props

  let variant = 'h6'
  if (medio) variant = 'h4'
  if (grande) variant = 'h2'

  return (
    <Typography
      variant={variant}
      {...restProps}
      {...sx(restProps, {
        mb: noMargine ? 0 : 1,
        ...(colorato && { color: 'primary.main' })
      })}
      className={className}
    >
      {icona && !isLoading && (
        <Box sx={{ mr: 1, verticalAlign: 'middle' }} component='span'>
          {icona}
        </Box>
      )}

      {bottone && !isLoading && (
        <Box sx={{ mr: 1, verticalAlign: 'top' }} component='span'>
          {bottone}
        </Box>
      )}

      {isLoading ? <BaseTitoloSkeleton /> : children}
    </Typography>
  )
}