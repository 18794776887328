import { useFormContext } from 'react-hook-form'
import { BaseButtons, BaseTextInput } from 'inputs'
import { BaseGridLayout } from 'layout'
import ElenchiManager, { ELENCHI } from '../../form/ElenchiManager'
import { OPERAZIONI_BASE } from '../../network/OperazioniBase'
import VisuraGenerica from '../VisuraGenerica'

const NOMI = {
  CODICE_FISCALE_O_PATENTE: 'codiceFiscaleOPatente',
  CAMPO_DA_USARE: 'campoDaUsare'
}

const VALORI_CAMPO_DA_USARE = {
  NON_SCELTO: '',
  PATENTE: 'patente',
  CODICE_FISCALE: 'codiceFiscale',
}

export default function VisuraPersona(props) {
  const { importaPersona } = props

  // Implementazione non del tutto finita
  return null

  return (
    <ElenchiManager
      elenchi={[ELENCHI.VISURE_CONFIG]}
      render={el => {
        const tipiVisura = el(ELENCHI.VISURE_CONFIG).anagrafica
        const nessunaVisuraDisponibile = tipiVisura.length === 0
        return (
          <VisuraGenerica
            titolo='Visura anagrafica'
            nessunaVisuraDisponibile={nessunaVisuraDisponibile}
            defaultValues={{
              [NOMI.CODICE_FISCALE_O_PATENTE]: '',
              [NOMI.CAMPO_DA_USARE]: VALORI_CAMPO_DA_USARE.NON_SCELTO
            }}
            ContenutoFormVisura={FormVisuraPersona}
            useLogicaVisura={useLogicaVisuraPersona}
            importaDati={importaPersona}
          />
        )
      }}
    />
  )
}



function FormVisuraPersona() {
  const { setValue, submit, inAttesa } = useFormContext()

  function visuraAnagrafica(valoreCampoDaUsare) {
    setValue(NOMI.CAMPO_DA_USARE, valoreCampoDaUsare)
    setTimeout(() => submit(), 100)
  }

  const propsBottoni = {
    color: 'info',
    submit: false,
    disabled: inAttesa
  }

  return (
    <BaseGridLayout alignCenter justifyCenter disposizioneRighe={[2, 2]}>
      <BaseTextInput
        label='Patente / Codice fiscale'
        name={NOMI.CODICE_FISCALE_O_PATENTE}
        required
        autoFocus
        larghezza={25}
      />
      <BaseTextInput
        name={NOMI.CAMPO_DA_USARE}
        hidden
      />

      <BaseButtons.Cerca
        {...propsBottoni}
        testo='Visura Patente'
        onClick={() => visuraAnagrafica(VALORI_CAMPO_DA_USARE.PATENTE)}
      />
      <BaseButtons.Cerca
        {...propsBottoni}
        testo='Visura Codice fiscale'
        onClick={() => visuraAnagrafica(VALORI_CAMPO_DA_USARE.CODICE_FISCALE)}
      />
    </BaseGridLayout>
  )
}



function useLogicaVisuraPersona(props) {
  const { inviaOperazione, setRispostaVisura } = props

  async function eseguiVisura(valoriForm) {
    const { codiceFiscaleOPatente, campoDaUsare } = valoriForm
    if (campoDaUsare === VALORI_CAMPO_DA_USARE.NON_SCELTO) return

    const { ok, data } = await inviaOperazione(OPERAZIONI_BASE.VISURA_ANAGRAFICA, {
      [campoDaUsare]: codiceFiscaleOPatente
    })

    if (ok) {
      const { persona, html, avvisi } = data
      setRispostaVisura({ risultato: persona, html, avvisi })
    }
  }

  return { eseguiVisura }
}