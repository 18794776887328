import { forwardRef } from 'react'
import { bool, string, object } from 'prop-types'
import { useFormContext } from 'react-hook-form'
import { BaseContenutoConProgress, BaseToasts } from 'feedback'
import { BaseButton } from './BaseButtons'

export const BaseButtonConExtra = forwardRef(function BaseButtonConExtra(props, ref) {
  const {
    esporta,
    submit,
    inAttesa: inAttesa_Prop,
    ciSonoErrori: ciSonoErrori_Prop,
    messaggioToast,
    propsProgress,
    ...baseButtonProps
  } = props

  // Ottieni inAttesa e le props del bottone submit tramite l'hook dedicato.
  // L'oggetto ritornato sarà {} se il bottone non è submit,
  // ma per le regole degli hooks devo chiamarlo sempre
  const risultatiHookSubmit = useBottoneSubmit({
    submit,
    inAttesa_Prop,
    ciSonoErrori_Prop
  })

  // Inizializza le variabili che mi serviranno alla fine
  let inAttesaDefinitivo = inAttesa_Prop
  let altreProps = {}
  let toast

  if (submit) {
    // Se il bottone è submit, utilizza i valori ritornati dall'hook
    const {
      inAttesa: inAttesa_Hook,
      ...propsBottoneSubmit
    } = risultatiHookSubmit

    inAttesaDefinitivo = inAttesa_Hook
    altreProps = propsBottoneSubmit
  }

  if (esporta) {
    // Se il bottone è esporta, mi interessa anche il toast.
    // inAttesaDefinitivo rimane quello passato tramite prop
    altreProps.disabled = inAttesaDefinitivo
    toast = (
      <BaseToasts.Download
        messaggio={messaggioToast}
        ref={ref}
      />
    )
  }

  return (
    <BaseContenutoConProgress
      inAttesa={inAttesaDefinitivo ?? false}
      progressSotto
      mostraFigli='sempre'
      {...propsProgress}
    >
      <BaseButton {...altreProps} {...baseButtonProps} />

      {toast}
    </BaseContenutoConProgress>
  )
})

BaseButtonConExtra.propTypes = {
  esporta: bool,
  submit: bool,
  inAttesa: bool,
  ciSonoErrori: bool,
  messaggioToast: string,
  propsProgress: object
}



function useBottoneSubmit(props) {
  const {
    submit,
    inAttesa_Prop,
    ciSonoErrori_Prop
  } = props

  // Un eventuale componente BaseForm che contiene questo bottone può controllarne
  // lo stato passando inAttesa e ciSonoErrori tramite context
  const risultatiUseForm = useFormContext()

  // Devo mettere qui il controllo per le regole degli hooks
  if (!submit) return {}

  // Passare inAttesa e ciSonoErrori come props
  // permette di usare questo bottone anche al di fuori di BaseForm
  const inAttesa = inAttesa_Prop ?? risultatiUseForm?.inAttesa
  const ciSonoErrori = ciSonoErrori_Prop ?? risultatiUseForm?.ciSonoErrori

  return ({
    disabled: inAttesa || ciSonoErrori,
    type: 'submit',
    inAttesa
  })
}