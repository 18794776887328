import { BaseGridLayout } from 'layout'
import { useEntita } from './EntitaContext'

export default function ListaSubPanels(props) {
  const { subPanels, ...propsGrid } = props

  const propsInterne = useEntita()

  if (!subPanels || subPanels.length === 0) return null

  return (
    <BaseGridLayout
      vertical
      spacing={3}
      {...propsGrid}
    >
      {subPanels.map(({ SubPanel, propsSubPanel }, index) =>
        <SubPanel
          {...propsSubPanel}
          propsInterne_PannelloModificaEntita={propsInterne}
          key={SubPanel.name + String(index)}
        />
      )}
    </BaseGridLayout>
  )
}