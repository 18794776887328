import { BaseIconaSVG } from 'icons'

export default function IconaComando(props) {
  const { style = {} } = props

  return (
    <BaseIconaSVG viewBox='0 0 256 256' {...props} style={{ ...style, fillRule: 'evenodd' }}>
      <g>
        <path d="M 94.5,-0.5 C 116.833,-0.5 139.167,-0.5 161.5,-0.5C 162.801,0.639972 163.968,1.97331 165,3.5C 165.667,26.8333 165.667,50.1667 165,73.5C 152.49,82.4196 139.823,91.0863 127,99.5C 114.177,91.0863 101.51,82.4196 89,73.5C 88.3333,50.8333 88.3333,28.1667 89,5.5C 89.6831,2.32119 91.5164,0.321186 94.5,-0.5 Z M 126.5,22.5 C 127.376,22.3691 128.043,22.7025 128.5,23.5C 130.372,27.4121 132.372,31.2454 134.5,35C 139.523,35.8927 144.523,36.8927 149.5,38C 145.651,41.6818 141.984,45.5151 138.5,49.5C 139.985,54.0714 140.485,58.7381 140,63.5C 135.667,61.1667 131.333,58.8333 127,56.5C 122.523,59.3385 118.023,61.3385 113.5,62.5C 114.894,57.5802 115.394,52.5802 115,47.5C 111.833,44.3333 108.667,41.1667 105.5,38C 110.454,36.8156 115.454,35.8156 120.5,35C 122.912,31.0095 124.912,26.8429 126.5,22.5 Z" />
        <path d="M 243.5,255.5 C 166.167,255.5 88.8333,255.5 11.5,255.5C 8.65817,253.156 6.82483,250.156 6,246.5C 5.21059,240.763 5.54392,235.097 7,229.5C 10.9241,225.47 15.7574,223.803 21.5,224.5C 21.5,174.5 21.5,124.5 21.5,74.5C 18.059,74.6989 14.7256,74.1989 11.5,73C 9.33333,72.1667 7.83333,70.6667 7,68.5C 5.04564,61.5141 5.04564,54.5141 7,47.5C 7.83333,46.6667 8.66667,45.8333 9.5,45C 31.7424,43.5214 54.0758,43.0214 76.5,43.5C 76.1676,55.8513 76.501,68.1847 77.5,80.5C 93.9172,92.2594 110.584,103.593 127.5,114.5C 144.416,103.593 161.083,92.2594 177.5,80.5C 178.499,68.1847 178.832,55.8513 178.5,43.5C 200.503,43.3334 222.503,43.5 244.5,44C 247.268,45.762 248.768,48.262 249,51.5C 249.851,57.2531 249.517,62.9198 248,68.5C 244.634,73.6863 239.801,75.6863 233.5,74.5C 233.5,124.5 233.5,174.5 233.5,224.5C 237.273,224.263 240.94,224.763 244.5,226C 246.333,226.5 247.5,227.667 248,229.5C 250.22,237.299 249.887,244.966 247,252.5C 245.855,253.636 244.688,254.636 243.5,255.5 Z M 82.5,150.5 C 112.167,150.5 141.833,150.5 171.5,150.5C 171.5,155.167 171.5,159.833 171.5,164.5C 169.167,164.5 166.833,164.5 164.5,164.5C 164.5,184.5 164.5,204.5 164.5,224.5C 139.833,224.5 115.167,224.5 90.5,224.5C 90.5,204.5 90.5,184.5 90.5,164.5C 87.8333,164.5 85.1667,164.5 82.5,164.5C 82.5,159.833 82.5,155.167 82.5,150.5 Z" />
      </g>
    </BaseIconaSVG>
  )
}