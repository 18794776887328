import { formattaData, formattaImportoMonetario, formattaBooleano, generaUUID, getOptionLabel } from 'utils'
import HeaderSingolo from './componentiTabella/HeaderSingolo'
import CellaSingola from './componentiTabella/CellaSingola'
import { creaColonnaAzioniRiga, creaColonnaUUID } from './tabellaModificabile/ColonneExtraTabellaModificabile'

const TIPI_COLONNA = {
  NUMERO: 'numero',
  DATA: 'data',
  MONETA: 'moneta',
  BOOLEANO: 'booleano'
}

function getColumnsDefinitive(columns, datiModificabili, propsColonnaAzioniRiga) {
  const columnsDefinitive = columns.map(colonna => {
    const {
      Header,
      parteHeaderNonGrassetto,
      Cell,
      textAlign,
      tipo,
      formattaCella,
      mappaLabel,
      mappaOptionLabel,
      calcolaCellStyle,
      textEllipsis,
      spiegazione,
      ...restColonna
    } = colonna

    const textAlignDefinitivo = getTextAlignDefinitivo(textAlign, tipo)
    const formattaCellaDefinitivo = getFormattaCellaDefinitivo(formattaCella, mappaLabel, mappaOptionLabel, tipo)
    const calcolaCellStyleDefinitivo = getCalcolaCellStyleDefinitivo(calcolaCellStyle)
    return {
      ...restColonna,
      Header: getHeaderDefinitivo(Header, parteHeaderNonGrassetto, textAlignDefinitivo, spiegazione),
      Cell: getCellDefinitiva(Cell, textAlignDefinitivo, formattaCellaDefinitivo, calcolaCellStyleDefinitivo, textEllipsis)
    }
  })

  if (datiModificabili) {
    columnsDefinitive.push(creaColonnaAzioniRiga(propsColonnaAzioniRiga))
    // Aggiungi la colonna uuid solo se non è già presente
    if (columnsDefinitive.every(({ accessor }) => accessor !== 'uuid')) {
      columnsDefinitive.push(creaColonnaUUID())
    }
  }

  return {
    columnsDefinitive,
    hiddenColumns: getHiddenColumns(columnsDefinitive)
  }
}

function getTextAlignDefinitivo(textAlign_Custom, tipo) {
  // La colonna imposta manualmente un allineamento
  if (textAlign_Custom) return textAlign_Custom

  // Valori numerici e monetari sono allineati a destra
  const { NUMERO, MONETA } = TIPI_COLONNA
  if (tipo === NUMERO || tipo === MONETA) return 'right'

  // Tutti gli altri valori sono allineati a sinistra
  return 'left'
}

function getFormattaCellaDefinitivo(formattaCella_Custom, mappaLabel, mappaOptionLabel, tipo) {
  // La colonna ha una funzione custom per formattare la cella
  if (formattaCella_Custom) return formattaCella_Custom

  // La colonna ha una mappa che associa ad ogni valore una label
  if (mappaLabel) return value => mappaLabel[value] || value

  // La colonna ha una mappa che associa ad ogni valore una label, che proviene da una lista di options
  if (mappaOptionLabel) return value => getOptionLabel(mappaOptionLabel, value)

  // Formattazione booleano con true='Si' e false='No'
  if (tipo === TIPI_COLONNA.BOOLEANO) return formattaBooleano

  // Formattazione data con locale
  if (tipo === TIPI_COLONNA.DATA) {
    return data => {
      const dataFormattata = formattaData(data)
      return dataFormattata === 'Invalid Date' ? '' : dataFormattata
    }
  }

  // Formattazione monetaria con simbolo euro e 2 cifre decimali
  if (tipo === TIPI_COLONNA.MONETA) return formattaImportoMonetario

  // Nessuna formattazione particolare
  return stringa => stringa
}

function getCalcolaCellStyleDefinitivo(calcolaCellStyle_Custom) {
  // Restituisci una funzione che calcola il cell style mettendo insieme stili di default e stile custom
  return tableState => {
    const {
      ultimaRigaInGrassetto,
      valoriNegativiInRosso,
      righeColoriAlternati,
      paddingCelle
    } = tableState

    const stileCustom = calcolaCellStyle_Custom?.(tableState) || {}

    let listaStiliDefaultDaAggiungere = []
    if (ultimaRigaInGrassetto) {
      const calcolaStile_UltimaRigaInGrassetto = ({ rows, row: currentRow }) =>
        (currentRow.index === rows.length - 1) ? { fontWeight: 700 } : {}
      listaStiliDefaultDaAggiungere.push(calcolaStile_UltimaRigaInGrassetto(tableState))
    }
    if (righeColoriAlternati) {
      const calcolaStile_RigheColoriAlternati = ({ row: currentRow }) =>
        (currentRow.index % 2 === 0) ? { backgroundColor: '#f4f4f4' } : {}
      listaStiliDefaultDaAggiungere.push(calcolaStile_RigheColoriAlternati(tableState))
    }
    if (valoriNegativiInRosso) {
      const calcolaStile_ValoriNegativiInRosso = ({ value: currentCellValue }) =>
        (currentCellValue < 0) ? { color: 'red' } : {}
      listaStiliDefaultDaAggiungere.push(calcolaStile_ValoriNegativiInRosso(tableState))
    }
    const stileDefaultDaAggiungere = listaStiliDefaultDaAggiungere.reduce(
      (accumulatoreStile, stile) => ({ ...accumulatoreStile, ...stile }),
      {}
    )

    return {
      padding: paddingCelle,
      ...stileDefaultDaAggiungere,
      ...stileCustom
    }
  }
}

function getHeaderDefinitivo(Header, parteHeaderNonGrassetto, textAlignDefinitivo, spiegazione) {
  // Header non presente
  if (!Header) return ''

  // Header è una stringa
  if (typeof Header === 'string') {
    return () =>
      <HeaderSingolo
        testo={Header}
        testoNonGrassetto={parteHeaderNonGrassetto}
        textAlign={textAlignDefinitivo}
        spiegazione={spiegazione}
      />
  }

  // La colonna ha un funzione custom per renderizzare l'header
  return Header
}

function getCellDefinitiva(
  Cell, textAlignDefinitivo, formattaCellaDefinitivo,
  calcolaCellStyleDefinitivo, textEllipsis
) {
  return tableState => {
    // La colonna ha un funzione custom per renderizzare la cella
    if (Cell) return (
      <div style={{ padding: tableState.paddingCelle }}>
        {Cell(tableState)}
      </div>
    )

    return (
      <CellaSingola
        contenutoCella={formattaCellaDefinitivo(tableState.value, tableState)}
        style={calcolaCellStyleDefinitivo(tableState)}
        textEllipsis={textEllipsis}
        textAlign={textAlignDefinitivo}
      />
    )
  }
}

function getHiddenColumns(columns) {
  return columns
    .filter(({ nascosta }) => nascosta)
    .map(({ accessor }) => accessor)
}

function getRowKey(row, index) {
  const { id, uuid } = row.values

  // React Table assegna come default ad ogni riga una chiave del tipo 'row_{indiceRiga}'
  // Questo può creare spesso bug strani e problemi di performance
  // Quindi vedo se la colonna ha un UUID o un id di altro tipo da usare come chiave
  const key = uuid ?? id ?? index

  return `row_${key}`
}

function aggiungiUUIDATutteLeRighe(data) {
  return data.map(riga => ({
    ...riga,
    uuid: riga.uuid || generaUUID()
  }))
}

export {
  TIPI_COLONNA,
  getColumnsDefinitive,
  getRowKey,
  aggiungiUUIDATutteLeRighe
}