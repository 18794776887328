import { useState } from 'react'
import { Link } from 'react-router-dom'
import { BaseFeedbackConIcona, BasePopoverConfermaOperazione } from 'feedback'
import { BaseButtons, BaseTextInput } from 'inputs'
import { BaseGridLayout, useModal, BaseModal } from 'layout'
import { isNumeroIntero, isNumeroPositivo } from 'utils'

export default function ModificaManualeNumeroRegistro(props) {
  const { numeroAttuale, eseguiModificaNumero, rottaModificaContatore } = props

  const { modalRef, openModal, closeModal } = useModal()

  return (
    <>
      <BaseButtons.Modifica
        testo='Modifica manuale (STAFF)'
        onClick={openModal}
      />

      <BaseModal
        ref={modalRef}
        titolo='Modifica manuale numero di registro (STAFF)'
        maxWidth='md'
      >
        <InserimentoNuovoNumero
          numeroAttuale={numeroAttuale}
          onSalva={nuovoNumero => {
            eseguiModificaNumero(nuovoNumero)
            closeModal()
          }}
          onAnnulla={closeModal}
        />

        {rottaModificaContatore &&
          <BaseGridLayout alignCenter justifyCenter>
            <BaseFeedbackConIcona.Info
              messaggio='Se necessario, modificare anche il contatore che assegna il prossimo numero'
            />
            <Link to={rottaModificaContatore}>
              <BaseButtons.Dettagli testo='Vai' color='info' />
            </Link>
          </BaseGridLayout>
        }
      </BaseModal>
    </>
  )
}



export function InserimentoNuovoNumero(props) {
  const { numeroAttuale, onSalva, onAnnulla, propsBottoneSalva } = props

  const [numero, setNumero] = useState(numeroAttuale)

  const isNumeroValido = (
    numero !== ''
    && isNumeroIntero(numero)
    && isNumeroPositivo(numero)
    && numero > 0
  )

  return (
    <BaseGridLayout alignCenter justifyCenter>
      <BaseTextInput
        label='Nuovo numero'
        type='numeroPositivo'
        value={numero}
        onChange={e => setNumero(e.target.value)}
        larghezza={10}
        autoFocus
        InputLabelProps={{ shrink: true }}
        {...(!isNumeroValido && {
          error: true,
          messaggioAiuto: 'Numero non valido'
        })}
      />

      <BasePopoverConfermaOperazione
        messaggio={`Confermare modifica numero da ${numeroAttuale} a ${numero}?`}
        onConferma={() => onSalva(numero)}
      >
        <BaseButtons.Salva
          submit={false}
          disabled={!isNumeroValido}
          {...propsBottoneSalva}
        />
      </BasePopoverConfermaOperazione>

      {onAnnulla &&
        <BaseButtons.Chiudi
          testo='Annulla'
          onClick={() => onAnnulla()}
        />
      }
    </BaseGridLayout>
  )
}