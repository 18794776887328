import { useState } from 'react'
import { string } from 'prop-types'
import { BaseBottoneEFeedbackEliminazione } from 'feedback'
import { BaseTextInput, BaseButtons } from 'inputs'
import { useModal, BaseModal, BaseGridLayout } from 'layout'
import { BaseRedirectFraPochiSecondi } from 'navigation'
import { useRoutes } from '../../navigation/RoutesProvider'
import { PROP_TYPES_WRAPPER_FORM_MODIFICA } from '../entitaGenerica/components/FormModificaEntita'
import { STATI_ENTITA } from './ConfigurazioneStatiEntita'

// Questo componente va messo nel pannello di modifica della sottoentità che
// contiene lo stato (solitamente i dati generali o qualcosa di simile)
EliminaOAnnullaEntita.propTypes = {
  ...PROP_TYPES_WRAPPER_FORM_MODIFICA,
  nomeProprietaStato: string,
  labelEntita: string.isRequired,
  aiutoAnnullamento: string,
  aiutoEliminazione: string,
  chiaveRottaRedirectEliminazione: string.isRequired,
  messaggioRedirectEliminazione: string
}

export default function EliminaOAnnullaEntita(props) {
  const {
    propsEntita: { valoriInput, nomeEntita, pathEntita },
    propsInterne_PannelloModificaEntita: {
      gestioneModifica: {
        modificaEntita,
        inviaModificheInCoda,
        feedbackModificaEntita: { invioModificheInCorso }
      },
      gestionePermessi: { readOnly }
    },
    nomeProprietaStato = 'stato',
    labelEntita,
    aiutoAnnullamento = 'Dopo l\'assegnazione del numero ad una procedura, questa può essere annullata inserendo una motivazione. A seguito dell\'annullamento, non sarà più possibile modificare i dati inseriti.',
    aiutoEliminazione = 'Una procedeura in stato Bozza (numero non assegnato) può essere eliminata definitivamente dal sistema.',
    chiaveRottaRedirectEliminazione,
    messaggioRedirectEliminazione = 'A breve sarete riportati alla pagina di ricerca...'
  } = props

  const [appenaEliminato, setAppenaEliminato] = useState(false)
  const [appenaAnnullato, setAppenaAnnullato] = useState(false)

  function impostaStato(statoDaImpostare, altreProprietaDaImpostare) {
    modificaEntita(nomeEntita, pathEntita, {
      [nomeProprietaStato]: statoDaImpostare,
      ...altreProprietaDaImpostare
    })
    if (statoDaImpostare === STATI_ENTITA.ANNULLATO) setAppenaAnnullato(true)
    if (statoDaImpostare === STATI_ENTITA.ELIMINATO) setAppenaEliminato(true)
    inviaModificheInCoda({ forzaInvio: true })
  }

  const stato = valoriInput[nomeProprietaStato]
  const propsComuni = {
    impostaStato,
    readOnly,
    invioModificheInCorso,
    labelEntita
  }

  if (stato === STATI_ENTITA.BOZZA || (stato === STATI_ENTITA.ELIMINATO && appenaEliminato)) {
    return (
      <EliminaEntitaBozza
        {...propsComuni}
        aiutoEliminazione={aiutoEliminazione}
        chiaveRottaRedirectEliminazione={chiaveRottaRedirectEliminazione}
        messaggioRedirectEliminazione={messaggioRedirectEliminazione}
      />
    )
  }

  if (stato === STATI_ENTITA.IN_LAVORAZIONE || (stato === STATI_ENTITA.ANNULLATO && appenaAnnullato)) {
    return (
      <AnnullaEntitaInLavorazione
        {...propsComuni}
        aiutoAnnullamento={aiutoAnnullamento}
      />
    )
  }

  return null
}



function EliminaEntitaBozza(props) {
  const {
    impostaStato,
    readOnly,
    invioModificheInCorso,
    labelEntita,
    aiutoEliminazione,
    chiaveRottaRedirectEliminazione,
    messaggioRedirectEliminazione
  } = props

  const [eliminazioneEffettuataConSuccesso, setEliminazioneEffettuataConSuccesso] = useState(false)

  function eliminaFascicoloBozza() {
    impostaStato(STATI_ENTITA.ELIMINATO)
    setEliminazioneEffettuataConSuccesso(true)
  }

  const { getPath } = useRoutes()

  return (
    <BaseBottoneEFeedbackEliminazione
      propsBottoneElimina={{ testo: `Elimina ${labelEntita}`, disabled: readOnly }}
      mostraMessaggioEliminazione={!invioModificheInCorso && eliminazioneEffettuataConSuccesso}
      onConfermaEliminazione={eliminaFascicoloBozza}
      azioneDopoEliminazione={
        <BaseRedirectFraPochiSecondi
          descrizione={messaggioRedirectEliminazione}
          to={getPath(chiaveRottaRedirectEliminazione)}
        />
      }
      tooltipAiuto={aiutoEliminazione}
    />
  )
}



function AnnullaEntitaInLavorazione(props) {
  const {
    impostaStato,
    readOnly,
    invioModificheInCorso,
    labelEntita,
    aiutoAnnullamento
  } = props

  const { modalRef, openModal, closeModal } = useModal()

  const [annullamentoEffettuatoConSuccesso, setAnnullamentoEffettuatoConSuccesso] = useState(false)
  const [motivazione, setMotivazione] = useState('')

  function annullaFascicoloNonBozza() {
    impostaStato(STATI_ENTITA.ANNULLATO, { motivazioneAnnullamento: motivazione })
    closeModal()
    setAnnullamentoEffettuatoConSuccesso(true)
  }

  return (
    <>
      <BaseBottoneEFeedbackEliminazione
        propsBottoneElimina={{ testo: `Annulla ${labelEntita}`, disabled: readOnly }}
        mostraMessaggioEliminazione={!invioModificheInCorso && annullamentoEffettuatoConSuccesso}
        onConfermaEliminazione={openModal}
        propsPopoverConferma={{
          messaggio: 'Sarà richiesta una motivazione. Proseguire con l\'annullamento?',
          propsBottoneConferma: { testo: 'Prosegui' },
          propsBottoneAnnulla: { testo: 'Torna indietro' }
        }}
        messaggioEliminazione='Annullamento effettuato con successo'
        tooltipAiuto={aiutoAnnullamento}
      />

      <BaseModal 
        ref={modalRef} 
        titolo='Motivazione annullamento'
        onClose={() => setMotivazione('')}
      >
        <BaseTextInput
          label='Motivazione annullamento'
          multiline rows={4} larghezza='fullWidth'
          nonUsareHookForm
          value={motivazione}
          onChange={e => setMotivazione(e.target.value)}
          {...(!motivazione && {
            error: true,
            messaggioAiuto: 'Inserire una motivazione'
          })}
        />

        <BaseGridLayout>
          <BaseButtons.Elimina
            testo='Conferma annullamento'
            disabled={!motivazione}
            onClick={annullaFascicoloNonBozza}
          />
          <BaseButtons.Annulla
            testo='Torna indietro'
            color='default'
            onClick={closeModal}
          />
        </BaseGridLayout>
      </BaseModal>
    </>
  )
}