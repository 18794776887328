import { bool, func, node, object } from 'prop-types'
import { Paper } from '@mui/material'
import { BaseIconButtons } from 'inputs'
import { arricchisciPropOggetto, sx } from 'utils'
import useCurrentTheme from '../utils/hooks/useCurrentTheme'

/**
 * @typedef {import("../common/layout/BaseScatola").BaseScatolaProps} BaseScatolaProps 
 * @typedef {import("../common/layout/BaseScatola").BaseScatola} BaseScatola 
 */

BaseScatola.propTypes = {
  children: node.isRequired,
  centraContenuto: bool,
  outlined: bool,
  errore: bool,
  warning: bool,
  success: bool,
  info: bool,
  onClick_BottoneChiudi: func,
  propsBottoneChiudi: object,
  inputRef: object
}

/**
 * 
 * @param {BaseScatolaProps} props 
 * @type {BaseScatola}
 */
export default function BaseScatola(props) {
  const { isAlternativeTheme } = useCurrentTheme()

  const {
    children,
    centraContenuto,
    outlined,
    errore,
    warning,
    success,
    info,
    onClick_BottoneChiudi,
    propsBottoneChiudi,
    inputRef,
    ...restProps
  } = props

  return (
    <Paper
      variant={outlined ? 'outlined' : 'elevation'}
      ref={inputRef}
      {...(!outlined && { elevation: 3 })}
      {...restProps}
      {...sx(restProps, {
        p: 2,
        ...(centraContenuto && { textAlign: 'center' }),
        ...(outlined && { borderWidth: isAlternativeTheme() ? 0 : 2 }),
        ...(errore && {
          bgcolor: 'error.dark',
          color: 'error.contrastText'
        }),
        ...(warning && {
          bgcolor: 'warning.main',
          color: 'warning.contrastText'
        }),
        ...(success && {
          bgcolor: 'success.main',
          color: 'success.contrastText'
        }),
        ...(info && {
          border: 4,
          borderColor: 'info.light'
        })
      })}
    >
      {children}

      {onClick_BottoneChiudi &&
        <BaseIconButtons.Chiudi
          onClick={onClick_BottoneChiudi}
          {...propsBottoneChiudi}
          {...arricchisciPropOggetto(propsBottoneChiudi, 'iconProps', {
            fontSize: 'small'
          })}
          {...arricchisciPropOggetto(propsBottoneChiudi, 'tooltipProps', {
            disableHoverListener: true
          })}
          {...sx(propsBottoneChiudi, {
            p: 0.5,
            position: 'absolute',
            top: 0,
            right: 0
          })}
        />
      }
    </Paper>
  )
}