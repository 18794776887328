import { useState } from 'react'
import { string } from 'prop-types'
import { BaseBottoneEFeedbackSalvataggio } from 'feedback'
import { ICONE } from 'icons'
import { PROP_TYPES_WRAPPER_FORM_MODIFICA } from '../entitaGenerica/components/FormModificaEntita'
import { STATI_ENTITA } from './ConfigurazioneStatiEntita'

// Questo componente va messo nel pannello di modifica della sottoentità che
// contiene lo stato (solitamente i dati generali o qualcosa di simile)
ChiudiORiapriEntita.propTypes = {
  ...PROP_TYPES_WRAPPER_FORM_MODIFICA,
  nomeProprietaStato: string,
  labelEntita: string.isRequired
}

export default function ChiudiORiapriEntita(props) {
  const {
    propsEntita: { valoriInput, nomeEntita, pathEntita },
    propsInterne_PannelloModificaEntita: {
      gestioneModifica: {
        modificaEntita,
        inviaModificheInCoda,
        feedbackModificaEntita: { invioModificheInCorso }
      },
      gestionePermessi: { readOnly, abilitaModificaPermessiCompleta }
    },
    nomeProprietaStato = 'stato',
    labelEntita
  } = props

  const [appenaChiuso, setAppenaChiuso] = useState(false)
  const [appenaRiaperto, setAppenaRiaperto] = useState(false)

  function impostaStato(statoDaImpostare, opzioniModificaEntita) {
    modificaEntita(nomeEntita, pathEntita, {
      [nomeProprietaStato]: statoDaImpostare
    }, opzioniModificaEntita)

    if (statoDaImpostare === STATI_ENTITA.CHIUSO) {
      setAppenaChiuso(true)
      setTimeout(() => setAppenaChiuso(false), 3000)
    }

    if (statoDaImpostare === STATI_ENTITA.IN_LAVORAZIONE) {
      setAppenaRiaperto(true)
      setTimeout(() => setAppenaRiaperto(false), 3000)
    }
    
    inviaModificheInCoda({ forzaInvio: true })
  }

  const stato = valoriInput[nomeProprietaStato]

  const propsComuni = {
    impostaStato,
    invioModificheInCorso,
    labelEntita
  }

  if ((stato === STATI_ENTITA.IN_LAVORAZIONE && !appenaRiaperto) || appenaChiuso) {
    return (
      <ChiudiEntitaInLavorazione
        {...propsComuni}
        readOnly={readOnly}
      />
    )
  }

  if ((stato === STATI_ENTITA.CHIUSO && !appenaChiuso) || appenaRiaperto) {
    return (
      <RiapriEntitaChiusa
        {...propsComuni}
        abilitaModificaPermessiCompleta={abilitaModificaPermessiCompleta} />
    )
  }

  return null
}



function ChiudiEntitaInLavorazione(props) {
  const {
    impostaStato,
    invioModificheInCorso,
    labelEntita,
    readOnly
  } = props

  const [chiusuraEffettuataConSuccesso, setChiusuraEffettuataConSuccesso] = useState(false)

  function chiudiEntita() {
    impostaStato(STATI_ENTITA.CHIUSO)
    setChiusuraEffettuataConSuccesso(true)
  }

  return (
    <BaseBottoneEFeedbackSalvataggio
      propsBottoneSalva={{
        testo: `Completa ${labelEntita}`,
        iconaInizio: ICONE.LUCCHETTO_CHIUSO,
        color: 'info',
        disabled: readOnly
      }}
      mostraMessaggioSalvataggio={!invioModificheInCorso && chiusuraEffettuataConSuccesso}
      onConfermaSalvataggio={chiudiEntita}
      propsPopoverConferma={{
        messaggio: 'Non sarà più possibile modificare i dati inseriti. Completare?',
        propsBottoneConferma: { testo: 'Completa' }
      }}
      messaggioSalvataggio='Completamento effettuato con successo'
    />
  )
}



function RiapriEntitaChiusa(props) {
  const {
    impostaStato,
    invioModificheInCorso,
    labelEntita,
    abilitaModificaPermessiCompleta
  } = props

  const [riaperturaEffettuataConSuccesso, setRiaperturaEffettuataConSuccesso] = useState(false)

  function riapriEntita() {
    impostaStato(STATI_ENTITA.IN_LAVORAZIONE, { ignoraReadOnly: true })
    setRiaperturaEffettuataConSuccesso(true)
  }

  return (
    <BaseBottoneEFeedbackSalvataggio
      propsBottoneSalva={{
        testo: `Riapri ${labelEntita}`,
        iconaInizio: ICONE.LUCCHETTO_APERTO,
        color: 'info',
        disabled: !abilitaModificaPermessiCompleta
      }}
      mostraMessaggioSalvataggio={!invioModificheInCorso && riaperturaEffettuataConSuccesso}
      onConfermaSalvataggio={riapriEntita}
      propsPopoverConferma={{
        messaggio: 'Sarà nuovamente possibile modificare i dati inseriti. Riaprire?',
        propsBottoneConferma: { testo: 'Riapri' }
      }}
      messaggioSalvataggio='Riapertura effettuata con successo'
    />
  )
}