import { shape, string } from 'prop-types'
import { useRouteMatch } from 'react-router-dom'
import { ListItem } from '@mui/material'

/**
 * @typedef {import("../common/navigation/BaseNavigationListItem").BaseNavigationListItem} BaseNavigationListItem 
 * @typedef {import("../common/navigation/BaseNavigationListItem").BaseNavigationListItemProps} BaseNavigationListItemProps 
 */

BaseNavigationListItem.propTypes = {
  route: shape({ pathAssoluto: string }).isRequired,
}

/**
 * 
 * @param {BaseNavigationListItemProps} props
 * @type {BaseNavigationListItem} 
 * @returns 
 */
export default function BaseNavigationListItem(props) {
  const {
    route,
    children,
    sx
  } = props

  // Controllo se la rotta deve essere marcata come attiva
  // Una rotta è attiva se l'utente si trova in questa rotta oppure in una sua sotto-rotta
  const match = useRouteMatch(route.pathAssoluto)
  let selected = false
  if (match) {
    const { path: currentPath, isExact } = match
    if (isExact || (route.pathAssoluto !== '/' && route.pathAssoluto.includes(currentPath))) {
      selected = true
    }
  }

  return (
    <ListItem
      sx={sx}
      key={route.pathAssoluto}
      selected={selected}
    >
      {children}
    </ListItem>
  )
}