import { useMemo, useState } from 'react'
import { func, number, object, bool } from 'prop-types'
import { BaseGridLayout } from 'layout'
import { arricchisciPropOggetto } from 'utils'

RicercaEntitaConRisultati.propTypes = {
  // Di solito è un componente che usa FormRicerca
  Form: func.isRequired,
  // Se si passa dentro le propsForm l'oggetto opzioniRichiesta,
  // è meglio che quest'ultimo sia costante o memoizzato per
  // evitare potenziali richieste inutili al server
  propsForm: object,

  // Di solito è un componente che usa RisultatiRicercaEntita
  Risultati: func.isRequired,
  propsRisultati: object,

  // Se true, vengono chiesti al server tutti i risultati insieme
  noPaginazione: bool,
  // Valore usato come default, comunque modificabile dall'utente
  risultatiPerPagina: number
}

export default function RicercaEntitaConRisultati(props) {
  const {
    Form,
    propsForm,

    Risultati,
    propsRisultati,

    // PAGINAZIONE DISABILITATA FINCHÉ IL SERVER NON MANDERÀ recordsCount CORRETTO
    noPaginazione = true,
    risultatiPerPagina = 25
  } = props

  // Oggetto con varie proprietà:
  // - records: lista di risultati trovati
  // - recordsCount: numero totale di risultati trovati
  // - page: pagina corrente (lo stesso inviato nella richiesta)
  // - redordsPerPage: numero di risultati per pagina (lo stesso inviato nella richiesta)
  // - sortBy: sortKey del criterio di ordinamento (lo stesso inviato nella richiesta)
  // - sortDirection: 'asc' oppure 'desc' (lo stesso inviato nella richiesta)
  const [datiRicerca, setDatiRicerca] = useState(null)

  // Stati intermedi per far scattare una nuova ricerca quando
  // si cambia la pagina o il numero di risultati per pagina
  const [page, setPage] = useState(1)
  const [recordsPerPage, setRecordsPerPage] = useState(risultatiPerPagina)

  // Oggetto con 2 proprietà:
  // - sortBy: sortKey del criterio per cui ordinare
  // - sortDirection: 'asc' oppure 'desc'
  // Serve come stato intermedio per far scattare una nuova ricerca
  // quando si cambia il criterio o la direzione di ordinamento
  const [sortInfo, setSortInfo] = useState(null)

  // La Form deve avere uno useEffect che fa scattare 
  // una nuova ricerca quando cambia questo oggetto
  const opzioniRichiesta = useMemo(() => {
    return {
      page,
      recordsPerPage,
      ...sortInfo,
      ...propsForm?.opzioniRichiesta
    }
  }, [page, recordsPerPage, sortInfo, propsForm?.opzioniRichiesta])

  const [ricercaInCorso, setRicercaInCorso] = useState(false)

  function setDatiRicerca_ConGestionePagine(datiRicerca) {
    setDatiRicerca(prevDatiRicerca => {
      let nuoviDatiRicerca = {
        ...datiRicerca,
        nonCiSonoAltriRisultati: (datiRicerca.records.length < recordsPerPage)
      }
      if (prevDatiRicerca && datiRicerca.page > 1) {
        nuoviDatiRicerca.records = [
          ...prevDatiRicerca.records,
          ...datiRicerca.records
        ]
      }
      return nuoviDatiRicerca
    })
  }

  return (
    <BaseGridLayout vertical noWrap>
      <Form
        setDatiRicerca={setDatiRicerca_ConGestionePagine}
        {...propsForm}
        opzioniRichiesta={opzioniRichiesta}
        setRicercaInCorso={setRicercaInCorso}
        resetPaginazione={() => {
          setPage(1)
          return { ...opzioniRichiesta, page: 1 }
        }}
      />

      {datiRicerca &&
        <Risultati
          noPaginazione={noPaginazione}
          setPage={setPage}
          setRecordsPerPage={recordsPerPage => {
            setPage(1)
            setRecordsPerPage(recordsPerPage)
          }}
          changeSort={(sortBy, sortDirection) => {
            setPage(1)
            setSortInfo({ sortBy, sortDirection })
          }}
          ricercaInCorso={ricercaInCorso}
          {...propsRisultati}
          {...arricchisciPropOggetto(propsRisultati, 'datiRicerca', datiRicerca)}
          
          // NON DOVREBBE SERVIRE PASSARE LE sortInfo ESPLICITAMENTE PERCHÈ IL SERVER
          // DOVREBBE RITORNARLE NELLA RISPOSTA COME PER LE INFO SULLA PAGINAZIONE
          // {...arricchisciPropOggetto(propsRisultati, 'datiRicerca', {
          //   ...datiRicerca,
          //   ...sortInfo
          // })}
        />
      }
    </BaseGridLayout>
  )
}