import { Checkbox, ListItemText, TextField } from '@mui/material'
import { BaseGridLayout } from 'layout'
import { bool, func, number } from 'prop-types'
import { Fragment } from 'react'
import { getOptionLabel, sx } from 'utils'
import { useLarghezzaMinimaInput } from './hooks/AltriHooks'
import useInputConOptions from './hooks/InputConOptionsHook'
import OptionsSelect from './util/OptionsSelect'

/**
 * @typedef {import("../common/inputs/BaseSelectMultipla").BaseSelectMultiplaProps} BaseSelectMultiplaProps 
 * @typedef {import("../common/inputs/BaseSelectMultipla").BaseSelectMultipla} BaseSelectMultipla 
 */

BaseSelectMultipla.propTypes = {
  primaOpzioneSelezionaTutte: bool,
  numeroMaxOpzioniSelezionateDaVisualizzare: number,
  noVirgolaTraOpzioniSelezionate: bool, // Preso in considerazione solo con renderOption
  raggruppaOptions: bool,
  renderOption: func,
  calcolaPropsMenuItem: func,
  ...useInputConOptions.propTypes
}

BaseSelectMultipla.campoConOptions = true

/**
 * @template T type of option
 * @template D type of value
 * 
 * @param {BaseSelectMultiplaProps} props 
 * @type {BaseSelectMultipla<T, D>}
 */
export default function BaseSelectMultipla(props) {
  const {
    primaOpzioneSelezionaTutte,
    numeroMaxOpzioniSelezionateDaVisualizzare = 2,
    noVirgolaTraOpzioniSelezionate,
    raggruppaOptions,
    renderOption,
    calcolaPropsMenuItem,
    ...restProps
  } = props

  const { larghezza, label } = restProps
  const larghezzaMinima = useLarghezzaMinimaInput(larghezza, (label?.length ?? 0) / 1.5)

  const {
    value: opzioniSelezionate,
    onBlur,
    onChange,
    ref,
    propsRimanenti,
    options
  } = useInputConOptions({
    larghezza: larghezzaMinima,
    ...restProps,
    sceltaMultipla: true
  })

  const {
    isOpzioneTutteUnicaSelezionata,
    setOpzioneSelezionaTutte
  } = useOpzioneSelezionaTutte({
    primaOpzioneSelezionaTutte,
    options,
    opzioniSelezionate,
    onChange
  })

  // Concatena le options selezionate per mostrarle nell'input
  // Il numero massimo di options da concatenare è customizzabile con una prop
  function renderOpzioniSelezionate(opzioniSelezionate) {
    const valuesOpzioniDaVisualizzare = opzioniSelezionate.slice(0, numeroMaxOpzioniSelezionateDaVisualizzare)

    if (renderOption) {
      const separatore = !noVirgolaTraOpzioniSelezionate && <>,&nbsp;</>
      return (
        <BaseGridLayout spacing={noVirgolaTraOpzioniSelezionate ? 1 : 0} noWrap>
          {valuesOpzioniDaVisualizzare.map((value, index) =>
            <Fragment key={value}>
              {renderOption(options.find(option => option.value === value), index)}
              {index !== valuesOpzioniDaVisualizzare.length - 1 && separatore}
            </Fragment>
          )}
          {opzioniSelezionate.length > numeroMaxOpzioniSelezionateDaVisualizzare &&
            <Fragment key='...'>{separatore}...</Fragment>
          }
        </BaseGridLayout>
      )
    }

    const stringaDaVisualizzare = valuesOpzioniDaVisualizzare
      .map(value => getOptionLabel(options, value))
      .join(', ')
    return (opzioniSelezionate.length > numeroMaxOpzioniSelezionateDaVisualizzare)
      ? `${stringaDaVisualizzare}, ...`
      : stringaDaVisualizzare
  }

  return (
    <TextField
      // Props fornite dal controller
      value={opzioniSelezionate}
      onBlur={onBlur}
      onChange={e => onChange(e.target.value)}
      inputRef={ref}

      // Props della select
      select
      {...propsRimanenti}
      SelectProps={{
        renderValue: renderOpzioniSelezionate,
        ...propsRimanenti.SelectProps,
        multiple: true,
        native: false,
        MenuProps: {
          ...propsRimanenti.SelectProps?.MenuProps,
          ...(primaOpzioneSelezionaTutte && {
            TransitionProps: {
              // Quando la select viene aperta ed è selezionata l'opzione Tutte, deselezionala.
              // Si presuppone che se l'utente apre la select, vuole selezionare solo alcune opzioni
              // In questo modo l'utente risparmia un click e l'interazione è più intuitiva
              onEnter: () => {
                if (isOpzioneTutteUnicaSelezionata()) setOpzioneSelezionaTutte(false)
              },
              // Quando la select viene chiusa e non è selezionata nessuna opzione, seleziona l'opzione Tutte.
              // In questo modo si mantiene la semantica della select (Tutte OPPURE solo alcune opzioni selezionate)
              onExit: () => {
                if (opzioniSelezionate.length === 0) setOpzioneSelezionaTutte(true)
              }
            }
          })
        }
      }}
    >
      {OptionsSelect({
        raggruppaOptions,
        options,
        renderOption: (option, index) => (
          <>
            <Checkbox checked={opzioniSelezionate.includes(option.value)} />
            {renderOption
              ? renderOption(option, index)
              : <ListItemText primary={option.label} />
            }
          </>
        ),
        calcolaPropsMenuItem: (option, index) => {
          const propsMenuItem = calcolaPropsMenuItem?.(option, index)
          return { ...propsMenuItem, ...sx(propsMenuItem, { py: 0 }) }
        }
      })}
    </TextField>
  )
}



function useOpzioneSelezionaTutte(props) {
  const {
    primaOpzioneSelezionaTutte,
    options,
    opzioniSelezionate,
    onChange
  } = props

  // Ho messo questo controllo qui per le regole degli hooks
  if (!primaOpzioneSelezionaTutte) return {}

  if (isOpzioneTutteSelezionata()) {
    abilitaTutteLeAltreOpzioni(false)
    setOpzioneSelezionaTutte(true)
  } else {
    abilitaTutteLeAltreOpzioni(true)
  }

  function getOpzioneTutte() {
    return options[0].value
  }

  function isOpzioneTutteSelezionata() {
    return opzioniSelezionate.includes(getOpzioneTutte())
  }

  function isOpzioneTutteUnicaSelezionata() {
    return isOpzioneTutteSelezionata() && opzioniSelezionate.length === 1
  }

  function abilitaTutteLeAltreOpzioni(daAbilitare) {
    // La modifica in place non dà problemi perché qui le options sono quelle 
    // uniformate da useInputConOptions, non quelle passate da fuori
    options.slice(1).forEach(option => option.disabled = !daAbilitare)
  }

  // Funzione principale di questo hook
  // Attiva o disattiva l'opzione Tutte
  function setOpzioneSelezionaTutte(daAbilitare) {
    const opzioneTutte = getOpzioneTutte()
    let opzioniSelezionateAggiornate
    if (daAbilitare === true) {
      // Seleziona l'opzione Tutte, se non è già l'unica selezionata
      if (!isOpzioneTutteUnicaSelezionata()) {
        opzioniSelezionateAggiornate = [opzioneTutte]
      }
    } else {
      // Deseleziona l'opzione Tutte, se è l'unica selezionata
      if (isOpzioneTutteUnicaSelezionata()) {
        opzioniSelezionateAggiornate = opzioniSelezionate.filter(opzione => opzione !== opzioneTutte)
      }
    }

    if (opzioniSelezionateAggiornate) onChange(opzioniSelezionateAggiornate)
  }

  return {
    isOpzioneTutteUnicaSelezionata,
    setOpzioneSelezionaTutte
  }
}