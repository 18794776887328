import { Box } from '@mui/material'
import { BaseTesto } from 'text'
import { useEntita } from '../components/EntitaContext'
import LinkPannello from '../components/LinkPannello'
import ListaMessaggiValidazione from '../components/ListaMessaggiValidazione'

function VoceMenuLaterale(props) {
  const {
    children,
    nodoAlbero: { urlPannello, isRoot },
    urlPannelloCorrente
  } = props

  // Non serve il link se la voce del menu si riferisce al pannello corrente
  if (urlPannello === urlPannelloCorrente) return children

  return (
    <LinkPannello
      to={urlPannello}
      homeEntita={isRoot}
    >
      {children}
    </LinkPannello>
  )
}



function LabelVoceMenu(props) {
  const {
    propsMenu: { labelMenu, nascondiContatore, },
    valoriInput
  } = props.nodoAlbero

  return (
    <BaseTesto
      sx={{
        fontSize: '1.1rem',
        lineHeight: 1.1,
        fontWeight: 'fontWeightBold',
        px: 0,
        py: 1.25
      }}
    >
      {labelMenu}
      {!nascondiContatore && Array.isArray(valoriInput) &&
        <Box component='span' sx={{ fontWeight: 'fontWeightRegular' }}>
          &nbsp; ({valoriInput.length})
        </Box>
      }
    </BaseTesto>
  )
}



function IndicatoreStatoVoceMenu(props) {
  const { pathEntita } = props.nodoAlbero

  const {
    gestioneValidazione: { getMessaggiConPathCheIniziaCon }
  } = useEntita()

  return (
    <ListaMessaggiValidazione
      messaggi={getMessaggiConPathCheIniziaCon(pathEntita)}
      soloUnIndicatoreRiassuntivo
      propsIndicatoreRiassuntivo={{ sx: { mx: 1 }, size: 'small' }}
    />
  )
}

export {
  VoceMenuLaterale,
  LabelVoceMenu,
  IndicatoreStatoVoceMenu
}