// Estraggo head e body dall'html
// L'head va tenuto da parte per rimandarlo al server in fase di generazione del pdf
// Il body va passato a ckeditor
function trasformaTestoHtmlInput(stringaHtml) {
  // console.log('html input', stringaHtml)
  let html = document.createElement('html')
  html.innerHTML = stringaHtml

  return {
    head: html.querySelector('head').innerHTML,
    body: html.querySelector('body').innerHTML
  }
}

// Rimetto insieme head e body per avere l'html completo
// Inietto nell'head un ulteriore tag style che contiene gli stili interni di ck editor
// Aggiungo al body una classe "ck-content" per far applicare gli stili correttamente
// Pulisco tutto per correggere tag non chiusi e altre impurità prodotte dall'editor
function trasformaTestoHtmlOutput(headStringa, bodyStringa) {
  let head = document.createElement('head')
  head.innerHTML = headStringa
  const ckEditorStyle = document.createElement('style')
  ckEditorStyle.setAttribute('type', 'css')
  ckEditorStyle.innerHTML = CK_EDITOR_STILI
  head.appendChild(ckEditorStyle)

  let body = document.createElement('body')
  body.innerHTML = bodyStringa
  body.classList.add('ck-content')

  let html = document.createElement('html')
  html.appendChild(head)
  html.appendChild(body)

  const htmlOutput = html.outerHTML
    .replace(/<br>/g, '<br/>')
    .replace(/<hr>/g, '<hr/>')
    .replace(/<img [^>]+>/g, imgTag => `${imgTag.slice(0, imgTag.length - 1)}/>`)
    .replace(/<col [^>]+>/g, colTag => `${colTag.slice(0, colTag.length - 1)}/>`)
    .replaceAll('<o:p>', '') // Robe strane inserite con copia da word
    .replaceAll('</o:p>', '') // Robe strane inserite con copia da word
    // .replaceAll('&quot;', '\'')
  // console.log('html output', htmlOutput)
  return htmlOutput
}

export {
  trasformaTestoHtmlInput,
  trasformaTestoHtmlOutput,
}



// SOLO stili relativi a plugin presenti nella build di ckeditor
// Altri non usati sono sotto
const CK_EDITOR_STILI = `
:root {
    --ck-color-image-caption-background: hsl(0, 0%, 97%);
    --ck-color-image-caption-text: hsl(0, 0%, 20%);
    --ck-color-mention-background: hsla(341, 100%, 30%, 0.1);
    --ck-color-mention-text: hsl(341, 100%, 30%);
    --ck-color-table-caption-background: hsl(0, 0%, 97%);
    --ck-color-table-caption-text: hsl(0, 0%, 20%);
    --ck-highlight-marker-blue: hsl(201, 97%, 72%);
    --ck-highlight-marker-green: hsl(120, 93%, 68%);
    --ck-highlight-marker-pink: hsl(345, 96%, 73%);
    --ck-highlight-marker-yellow: hsl(60, 97%, 73%);
    --ck-highlight-pen-green: hsl(112, 100%, 27%);
    --ck-highlight-pen-red: hsl(0, 85%, 49%);
    --ck-image-style-spacing: 1.5em;
    --ck-inline-image-style-spacing: calc(var(--ck-image-style-spacing) / 2);
    --ck-todo-list-checkmark-size: 16px;
}
.ck-content .image > figcaption {
    display: table-caption;
    caption-side: bottom;
    word-break: break-word;
    color: var(--ck-color-image-caption-text);
    background-color: var(--ck-color-image-caption-background);
    padding: .6em;
    font-size: .75em;
    outline-offset: -1px;
}
.ck-content .image.image_resized {
    max-width: 100%;
    display: block;
    box-sizing: border-box;
}
.ck-content .image.image_resized img {
    width: 100%;
}
.ck-content .image.image_resized > figcaption {
    display: block;
}
.ck-content .image {
    display: table;
    clear: both;
    text-align: center;
    margin: 0.9em auto;
    min-width: 50px;
}
.ck-content .image img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    min-width: 100%;
}
.ck-content .image-inline {
    display: inline-flex;
    max-width: 100%;
    align-items: flex-start;
}
.ck-content .image-inline picture {
    display: flex;
}
.ck-content .image-inline picture,
.ck-content .image-inline img {
    flex-grow: 1;
    flex-shrink: 1;
    max-width: 100%;
}
.ck-content .image-style-block-align-left,
.ck-content .image-style-block-align-right {
    max-width: calc(100% - var(--ck-image-style-spacing));
}
.ck-content .image-style-align-left,
.ck-content .image-style-align-right {
    clear: none;
}
.ck-content .image-style-side {
    float: right;
    margin-left: var(--ck-image-style-spacing);
    max-width: 50%;
}
.ck-content .image-style-align-left {
    float: left;
    margin-right: var(--ck-image-style-spacing);
}
.ck-content .image-style-align-center {
    margin-left: auto;
    margin-right: auto;
}
.ck-content .image-style-align-right {
    float: right;
    margin-left: var(--ck-image-style-spacing);
}
.ck-content .image-style-block-align-right {
    margin-right: 0;
    margin-left: auto;
}
.ck-content .image-style-block-align-left {
    margin-left: 0;
    margin-right: auto;
}
.ck-content p + .image-style-align-left,
.ck-content p + .image-style-align-right,
.ck-content p + .image-style-side {
    margin-top: 0;
}
.ck-content .image-inline.image-style-align-left,
.ck-content .image-inline.image-style-align-right {
    margin-top: var(--ck-inline-image-style-spacing);
    margin-bottom: var(--ck-inline-image-style-spacing);
}
.ck-content .image-inline.image-style-align-left {
    margin-right: var(--ck-inline-image-style-spacing);
}
.ck-content .image-inline.image-style-align-right {
    margin-left: var(--ck-inline-image-style-spacing);
}
.ck-content ol {
    list-style-type: decimal;
}
.ck-content ol ol {
    list-style-type: lower-latin;
}
.ck-content ol ol ol {
    list-style-type: lower-roman;
}
.ck-content ol ol ol ol {
    list-style-type: upper-latin;
}
.ck-content ol ol ol ol ol {
    list-style-type: upper-roman;
}
.ck-content ul {
    list-style-type: disc;
}
.ck-content ul ul {
    list-style-type: circle;
}
.ck-content ul ul ul {
    list-style-type: square;
}
.ck-content ul ul ul ul {
    list-style-type: square;
}
.ck-content .table {
    margin: 0.9em auto;
    display: table;
}
.ck-content .table table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    height: 100%;
    border: 1px double hsl(0, 0%, 70%);
}
.ck-content .table table td,
.ck-content .table table th {
    min-width: 2em;
    padding: .4em;
    border: 1px solid hsl(0, 0%, 75%);
}
.ck-content .table table th {
    font-weight: bold;
    background: hsla(0, 0%, 0%, 5%);
}
.ck-content[dir="rtl"] .table th {
    text-align: right;
}
.ck-content[dir="ltr"] .table th {
    text-align: left;
}
.ck-content .table > figcaption {
    display: table-caption;
    caption-side: top;
    word-break: break-word;
    text-align: center;
    color: var(--ck-color-table-caption-text);
    background-color: var(--ck-color-table-caption-background);
    padding: .6em;
    font-size: .75em;
    outline-offset: -1px;
}
.ck-content .table .ck-table-resized {
    table-layout: fixed;
}
.ck-content .table table {
    overflow: hidden;
}
.ck-content .table td,
.ck-content .table th {
    position: relative;
}
.ck-content hr {
    margin: 15px 0;
    height: 4px;
    background: hsl(0, 0%, 87%);
    border: 0;
}
`


// Stili relativi a plugin NON presenti nella build di ckeditor
// Se viene aggiunto uno di questi plugin, copiare il segmento relativo nell'altra costante 
const CK_EDITOR_STILI_NON_USATI = `
/* @ckeditor/ckeditor5-basic-styles/theme/code.css */
.ck-content code {
    background-color: hsla(0, 0%, 78%, 0.3);
    padding: .15em;
    border-radius: 2px;
}


/* @ckeditor/ckeditor5-font/theme/fontsize.css */
.ck-content .text-tiny {
    font-size: .7em;
}
.ck-content .text-small {
    font-size: .85em;
}
.ck-content .text-big {
    font-size: 1.4em;
}
.ck-content .text-huge {
    font-size: 1.8em;
}


/* @ckeditor/ckeditor5-block-quote/theme/blockquote.css */
.ck-content blockquote {
    overflow: hidden;
    padding-right: 1.5em;
    padding-left: 1.5em;
    margin-left: 0;
    margin-right: 0;
    font-style: italic;
    border-left: solid 5px hsl(0, 0%, 80%);
}
.ck-content[dir="rtl"] blockquote {
    border-left: 0;
    border-right: solid 5px hsl(0, 0%, 80%);
}


/* @ckeditor/ckeditor5-language/theme/language.css */
.ck-content span[lang] {
    font-style: italic;
}


/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list {
    list-style: none;
}
.ck-content .todo-list li {
    margin-bottom: 5px;
}
.ck-content .todo-list li .todo-list {
    margin-top: 5px;
}
.ck-content .todo-list .todo-list__label > input {
    -webkit-appearance: none;
    display: inline-block;
    position: relative;
    width: var(--ck-todo-list-checkmark-size);
    height: var(--ck-todo-list-checkmark-size);
    vertical-align: middle;
    border: 0;
    left: -25px;
    margin-right: -15px;
    right: 0;
    margin-left: 0;
}
.ck-content .todo-list .todo-list__label > input::before {
    display: block;
    position: absolute;
    box-sizing: border-box;
    content: '';
    width: 100%;
    height: 100%;
    border: 1px solid hsl(0, 0%, 20%);
    border-radius: 2px;
    transition: 250ms ease-in-out box-shadow, 250ms ease-in-out background, 250ms ease-in-out border;
}
.ck-content .todo-list .todo-list__label > input::after {
    display: block;
    position: absolute;
    box-sizing: content-box;
    pointer-events: none;
    content: '';
    left: calc( var(--ck-todo-list-checkmark-size) / 3 );
    top: calc( var(--ck-todo-list-checkmark-size) / 5.3 );
    width: calc( var(--ck-todo-list-checkmark-size) / 5.3 );
    height: calc( var(--ck-todo-list-checkmark-size) / 2.6 );
    border-style: solid;
    border-color: transparent;
    border-width: 0 calc( var(--ck-todo-list-checkmark-size) / 8 ) calc( var(--ck-todo-list-checkmark-size) / 8 ) 0;
    transform: rotate(45deg);
}
.ck-content .todo-list .todo-list__label > input[checked]::before {
    background: hsl(126, 64%, 41%);
    border-color: hsl(126, 64%, 41%);
}
.ck-content .todo-list .todo-list__label > input[checked]::after {
    border-color: hsl(0, 0%, 100%);
}
.ck-content .todo-list .todo-list__label .todo-list__label__description {
    vertical-align: middle;
}


/* @ckeditor/ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break {
    position: relative;
    clear: both;
    padding: 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ck-content .page-break::after {
    content: '';
    position: absolute;
    border-bottom: 2px dashed hsl(0, 0%, 77%);
    width: 100%;
}
.ck-content .page-break__label {
    position: relative;
    z-index: 1;
    padding: .3em .6em;
    display: block;
    text-transform: uppercase;
    border: 1px solid hsl(0, 0%, 77%);
    border-radius: 2px;
    font-family: Helvetica, Arial, Tahoma, Verdana, Sans-Serif;
    font-size: 0.75em;
    font-weight: bold;
    color: hsl(0, 0%, 20%);
    background: hsl(0, 0%, 100%);
    box-shadow: 2px 2px 1px hsla(0, 0%, 0%, 0.15);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
@media print {
  .ck-content .page-break {
      padding: 0;
  }
  .ck-content .page-break::after {
      display: none;
  }
}
`