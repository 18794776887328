import { ICONE } from 'icons'
import { BaseHeaderConIcona } from 'layout'
import Indirizzo from './Indirizzo'

export default function HeaderIndirizzo(props) {
  const { indirizzo, ...restProps } = props

  return (
    <BaseHeaderConIcona
      icona={<ICONE.LUOGO />}
      titolo={<Indirizzo indirizzo={indirizzo} senzaBaseTesto />}
      sottotitolo={indirizzo.presso && `Presso: ${indirizzo.presso}`}
      {...restProps}
    />
  )
}
