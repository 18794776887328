import { string, object } from 'prop-types'
import BaseCoppiaChiaveValore from './BaseCoppiaChiaveValore'
import BaseTitolo from './BaseTitolo'

BaseListaCoppieChiaveValore.propTypes = {
  coppie: object.isRequired,
  titolo: string,
  propsTitolo: object,
  propsCoppia: object
}

export default function BaseListaCoppieChiaveValore(props) {
  const {
    coppie,
    titolo,
    propsTitolo,
    propsCoppia
  } = props

  if (!coppie || typeof coppie !== 'object') return null

  return (
    <>
      {titolo &&
        <BaseTitolo noMargine {...propsTitolo}>
          {titolo}
        </BaseTitolo>
      }

      <div>
        {Object.entries(coppie).map(([chiave, valore]) =>
          <BaseCoppiaChiaveValore
            key={chiave}
            chiave={chiave}
            valore={valore}
            {...propsCoppia}
          />
        )}
      </div>
    </>
  )
}