import { useMemo } from 'react'
import { object, element, node } from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
import { Box } from '@mui/material'
import { BaseTitolo } from 'text'
import { sx, useCurrentTheme } from 'utils'
import { useRoutes } from '../navigation/RoutesProvider'
import InfoUtente from '../userManagement/InfoUtente'
import { useContextGenerico } from '../util/ContextGenericoProvider'

/**
 * @typedef {import("./../../common/incloudApps/layoutGenerale/BarraSuperiore").BarraSuperioreProps} BarraSuperioreProps
 * @typedef {import("./../../common/incloudApps/layoutGenerale/BarraSuperiore").BarraSuperiore} BarraSuperiore
 */

BarraSuperiore.propTypes = {
  propsTitolo: object,
  propsInfoUtente: object,
  icon: element,
  elements: node,
}

/**
 * 
 * @param {BarraSuperioreProps} props 
 * @type {BarraSuperiore}
 */
export default function BarraSuperiore(props) {
  const { isDefaultTheme } = useCurrentTheme()

  const { propsTitolo, propsInfoUtente, elements, icon } = props

  const { routeHome } = useRoutes()

  const { titoloApp } = useContextGenerico()

  /**
   * Create element for title. If is default theme, return titleApp string, otherwise explode string and create 
   * complex JSX.Element
   */
  const titoloAppElement = useMemo(() => {
    if (isDefaultTheme()) {
      return titoloApp
    } else {
      const titleParts = titoloApp.split(' ')
      return (
        <>
          {titleParts.map((part, index) =>
            <BaseTitolo
              noMargine
              key={part}
              sx={{
                lineHeight: '0.9rem',
                fontWeight: index === 0 ? 'bold' : 'regular',
                display: index === 0 ? 'inline-block' : { xs: 'none', sm: 'none', md: 'inline-block' },
                mr: 1
              }}
            >
              {part}
            </BaseTitolo>
          )}
        </>
      )
    }
  }, [titoloApp])

  return (
    <>
      <BaseTitolo
        noMargine
        {...propsTitolo}
        {...sx(propsTitolo, { flexGrow: 1 })}
      >
        <RouterLink to={routeHome.pathAssoluto}>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center' }}>
            {icon && (
              <Box sx={{ pr: 1 }}>{icon}</Box>
            )}
            <Box sx={{ lineHeight: '1.2rem' }} className='titolo_app'>
              {titoloAppElement}
            </Box>
          </Box>
        </RouterLink>
      </BaseTitolo>

      {elements}

      <InfoUtente {...propsInfoUtente} />
    </>
  )
}
