import { useFormContext } from 'react-hook-form'

// Ritorna un valore di larghezza default di un input,
// se la larghezza non viene passata né tramite prop, né tramite context
function useLarghezzaMinimaInput(larghezza_Prop, valoreLarghezzaDefault) {
  const risultatiUseForm_Context = useFormContext()
  let larghezza = larghezza_Prop ?? risultatiUseForm_Context?.larghezzaInputs
  return larghezza ?? valoreLarghezzaDefault
}

// Restituisce risultatiUseForm dalle props o dal context
function useRisultatiUseForm(props) {
  const risultatiUseForm_Context = useFormContext()
  return props.risultatiUseForm || risultatiUseForm_Context
}

export {
  useLarghezzaMinimaInput,
  useRisultatiUseForm
}