import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useQueryString, removeFromSessionStorage, getFromLocalStorage, saveInLocalStorage, getFromSessionStorage, saveInSessionStorage, removeFromLocalStorage, useDeleteQueryString } from 'utils'
import { useIsRottaCorrenteSenzaComando } from '../../navigation/utils'
import { useContextGenerico } from '../../util/ContextGenericoProvider'
import { aggiungiPrefissoChiaveModulo } from './permessiUser/utilPermessiUser'
import { useUserUpdate } from './UserProvider'

const PARAMETRO_COMANDO_QUERY_STRING = 'cmd'

const NESSUN_COMANDO = ''

export default function useComandoScelto(props) {
  const {
    richiediEImpostaPermessiUserConComando,
    impostaGruppiUtente,
    isUserLoggato
  } = props

  const {
    impostaPermessiUserConComando,
    eliminaPermessiUserConComando,
    nuovaGestionePermessi
  } = useUserUpdate()

  const {
    CHIAVI_STORAGE: { COMANDI_ABILITATI, COMANDO_SCELTO, COMANDO_SCELTO_NUOVA_TAB }
  } = useContextGenerico()

  /*********** Comandi abilitati ***********/

  const [comandiAbilitati, setComandiAbilitati] = useState(
    () => getFromLocalStorage(COMANDI_ABILITATI, { mask: true }) || []
  )

  useEffect(
    // I dati dei comandi abilitati non sono salvati in chiaro, sono mascherati
    () => saveInLocalStorage(COMANDI_ABILITATI, comandiAbilitati, { mask: true }),
    [comandiAbilitati, COMANDI_ABILITATI]
  )

  /*****************************************/

  /* Comando scelto + ultimo comando scelto */

  const { [PARAMETRO_COMANDO_QUERY_STRING]: comando_QueryString } = useQueryString()
  const { deleteParams } = useDeleteQueryString()

  const [comandoScelto, setComandoScelto] = useState(NESSUN_COMANDO)

  const isUserLoggato_Flag = isUserLoggato()

  // Imposta il valore iniziale del comando scelto
  // Non ho potuto farlo nella funzione di inizializzazione di useState
  // perché handleSceltaComando è una funzione asincrona e ritorna promesse
  useEffect(() => {
    (async function inizializzaComando() {
      await handleSceltaComando(
        comando_QueryString
        || getFromSessionStorage(COMANDO_SCELTO)
        || getFromLocalStorage(COMANDO_SCELTO_NUOVA_TAB)
        || NESSUN_COMANDO
      )
      // Dopo aver impostato il comando, elimino l'eventuale parametro dalla
      // query string per evitare interferenze con successivi cambi di comando
      if (isUserLoggato_Flag && comando_QueryString) deleteParams(PARAMETRO_COMANDO_QUERY_STRING)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserLoggato_Flag, comando_QueryString])

  // Salvare il comando nel session storage permette di ricaricare la pagina senza perdere il comando
  useEffect(() => {
    if (comandoScelto === NESSUN_COMANDO) {
      removeFromSessionStorage(COMANDO_SCELTO)
      return
    }
    saveInSessionStorage(COMANDO_SCELTO, comandoScelto)
  }, [comandoScelto, COMANDO_SCELTO])

  // Usato solo nel momento in cui l'utente vuole cambiare comando,
  // per permettergli di annullare e tornare all'ultimo comando scelto
  const [ultimoComandoScelto, setUltimoComandoScelto] = useState(NESSUN_COMANDO)

  /******************************************/

  /* Funzioni per ottenere le info relative al comando */

  function isUserConPiuComandiAbilitati() {
    return comandiAbilitati.length > 1
  }

  function isComandoAbilitato(comando, comandiAbilitati_Argomento) {
    const comandiAbilitatiDaUsare = comandiAbilitati_Argomento || comandiAbilitati
    return comandiAbilitatiDaUsare.some(({ tag }) => tag === comando)
  }

  function isComandoScelto() {
    return comandoScelto !== NESSUN_COMANDO
  }

  function getLabelComando() {
    const oggettoComando = comandiAbilitati.find(({ tag }) => tag === comandoScelto)
    return oggettoComando?.nome || 'Nessun comando scelto'
  }

  function getTipoComando() {
    const oggettoComando = comandiAbilitati.find(({ tag }) => tag === comandoScelto)
    return oggettoComando?.tipo || ''
  }
  /*****************************************************/

  const isRottaCorrenteSenzaComando = useIsRottaCorrenteSenzaComando()

  const history = useHistory()

  /* Funzioni per modificare le info relative al comando */

  // "comandiAbilitati_Argomento", "userInfo" e "permessi" sono parametri passati
  // solo in fase di login, poiché queste informazioni non sono
  // ancora state caricate nello stato dell'applicazione e quindi
  // non sarebbero disponibili per fare la richiesta dei permessi
  async function handleSceltaComando(nuovoComandoScelto, comandiAbilitati_Argomento, userInfo, permessi) {
    if (comandoScelto !== NESSUN_COMANDO) {
      setUltimoComandoScelto(comandoScelto)
    }

    if (!nuovoComandoScelto || nuovoComandoScelto === NESSUN_COMANDO) {
      eliminaPermessiUserConComando()
      setComandoScelto(NESSUN_COMANDO)
      return
    }

    // Se sono in una rotta senza comando e scelgo un comando, vado per forza alla home
    // Non posso mantenere la rotta corrente perché nel componente Home scatterebbe 
    // automaticamente lo SganciaComando e mi butterebbe subito fuori dal comando
    // Inoltre non avrebbe senso mantenere la rotta corrente in questa situazione,
    // perché non c'è sovrapposizione tra rotte con comando e rotte senza comando
    if (isRottaCorrenteSenzaComando && comandoScelto === NESSUN_COMANDO) {
      history.push('/')
    }

    if (!isComandoAbilitato(nuovoComandoScelto, comandiAbilitati_Argomento)) return

    if (nuovaGestionePermessi) {
      setComandoScelto(nuovoComandoScelto)

      const {
        gruppi,
        permessi = [],
        moduli = []
      } = (comandiAbilitati_Argomento || comandiAbilitati).find(({ tag }) => tag === nuovoComandoScelto)

      impostaGruppiUtente(gruppi)

      let permessiTrasformati = {}
      permessi.forEach(({ nome, valore }) => {
        permessiTrasformati[nome] = valore
      })
      moduli.forEach(({ codice, valore }) => {
        permessiTrasformati[aggiungiPrefissoChiaveModulo(codice)] = valore
      })
      impostaPermessiUserConComando(permessiTrasformati, nuovoComandoScelto)
      return
    }

    const { ok } = await richiediEImpostaPermessiUserConComando(nuovoComandoScelto, userInfo, permessi)
    if (ok) setComandoScelto(nuovoComandoScelto)
  }

  function handleRichiestaCambioComando() {
    setUltimoComandoScelto(comandoScelto)
    handleSceltaComando(NESSUN_COMANDO)
  }

  function handleLogin_OperazioniSulComando(comandoGiaScelto, comandiAbilitati, userInfo, permessi, gruppi) {
    setComandiAbilitati(comandiAbilitati)
    impostaGruppiUtente(gruppi)
    handleSceltaComando(
      comandoGiaScelto || comando_QueryString || NESSUN_COMANDO,
      comandiAbilitati,
      userInfo,
      permessi
    )
  }

  function handleLogout_OperazioniSulComando() {
    setComandiAbilitati([])
    handleSceltaComando(NESSUN_COMANDO)
    setUltimoComandoScelto(NESSUN_COMANDO)
    impostaGruppiUtente([])
  }

  /*******************************************************/

  /* Gestione caso particolare con più tab aperte */

  useComandoNuovaTab(comandoScelto, COMANDO_SCELTO_NUOVA_TAB)

  /************************************************/

  return {
    comandiAbilitati,
    comandoScelto,
    ultimoComandoScelto,
    isUserConPiuComandiAbilitati,
    isComandoScelto,
    getLabelComando,
    getTipoComando,
    handleSceltaComando,
    handleRichiestaCambioComando,
    handleLogin_OperazioniSulComando,
    handleLogout_OperazioniSulComando
  }
}



// Permette ad una nuova tab (che non condivide il session storage) di capire il comando scelto
// L'idea è di controllare se era attiva un'altra tab su un comando, e di usare quello
function useComandoNuovaTab(comandoScelto, chiaveStorage_ComandoNuovaTab) {
  useEffect(() => {
    if (comandoScelto === NESSUN_COMANDO) {
      removeFromLocalStorage(chiaveStorage_ComandoNuovaTab)
      return
    }

    function salvaComandoSceltoPerNuovaTab() {
      saveInLocalStorage(chiaveStorage_ComandoNuovaTab, comandoScelto)
    }
    salvaComandoSceltoPerNuovaTab()

    window.addEventListener('focus', salvaComandoSceltoPerNuovaTab)
    return () => window.removeEventListener('focus', salvaComandoSceltoPerNuovaTab)
  }, [comandoScelto, chiaveStorage_ComandoNuovaTab])
}