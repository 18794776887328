import { node } from 'prop-types'
import { Box } from '@mui/material'
import { sx } from 'utils'

BaseGrassetto.propTypes = {
  children: node.isRequired
}

export default function BaseGrassetto(props) {
  const { children, ...restProps } = props

  return (
    <Box
      component='span'
      {...restProps}
      {...sx(restProps, {
        fontWeight: 'fontWeightBold',
        fontFamily: 'fontFamily'
      })}
    >
      {children}
    </Box>
  )
}
