import { forwardRef } from 'react'
import { BaseIconaSVG } from 'icons'

const IconaRuolo = forwardRef((props, ref) => {
  const { style = {} } = props

  return (
    <BaseIconaSVG viewBox='0 0 261 179' {...props} ref={ref} style={{ ...style, fillRule: 'evenodd' }}>
      <g>
        <path id="svg_2" d="m236.666,0l-212.666,0c-13.233,0 -24,10.767 -24,24l0,130.902c0,13.233 10.767,24 24,24l212.666,0c13.233,0 24,-10.767 24,-24l0,-130.902c0,-13.234 -10.767,-24 -24,-24zm9,154.902c0,4.962 -4.037,9 -9,9l-212.666,0c-4.963,0 -9,-4.038 -9,-9l0,-130.902c0,-4.962 4.037,-9 9,-9l212.666,0c4.963,0 9,4.038 9,9l0,130.902z" />
        <path id="svg_3" d="m216.04,42.821l-68.933,0c-3.314,0 -6,2.687 -6,6s2.686,6 6,6l68.933,0c3.314,0 6,-2.687 6,-6s-2.686,-6 -6,-6z" />
        <path id="svg_4" d="m216.04,124.081l-68.933,0c-3.314,0 -6,2.686 -6,6c0,3.313 2.686,6 6,6l68.933,0c3.314,0 6,-2.687 6,-6c0,-3.314 -2.686,-6 -6,-6z" />
        <path id="svg_5" d="m216.04,77.529l-41.718,0c-3.313,0 -6,2.687 -6,6s2.687,6 6,6l41.718,0c3.314,0 6,-2.687 6,-6s-2.686,-6 -6,-6z" />
        <path id="svg_6" d="m216.04,100.804l-41.718,0c-3.313,0 -6,2.687 -6,6c0,3.314 2.687,6 6,6l41.718,0c3.314,0 6,-2.686 6,-6c0,-3.313 -2.686,-6 -6,-6z" />
        <path id="svg_7" d="m85.163,92.254c17.004,0 30.838,-13.839 30.838,-30.849c0,-17.011 -13.834,-30.85 -30.838,-30.85c-17.009,0 -30.847,13.839 -30.847,30.85c0,17.01 13.838,30.849 30.847,30.849zm0,-46.698c8.733,0 15.838,7.11 15.838,15.85c0,8.739 -7.104,15.849 -15.838,15.849c-8.738,0 -15.847,-7.11 -15.847,-15.849c0,-8.74 7.109,-15.85 15.847,-15.85z" />
        <path id="svg_8" d="m97.097,97.798l-23.682,0c-16.592,0 -30.09,13.497 -30.09,30.088l0,12.961c0,4.142 3.357,7.5 7.5,7.5s7.5,-3.358 7.5,-7.5l0,-12.961c0,-8.319 6.77,-15.088 15.09,-15.088l23.682,0c8.32,0 15.09,6.768 15.09,15.088l0,12.961c0,4.142 3.357,7.5 7.5,7.5s7.5,-3.358 7.5,-7.5l0,-12.961c0,-16.591 -13.499,-30.088 -30.09,-30.088z" />
      </g>
    </BaseIconaSVG>
  )
})

export default IconaRuolo