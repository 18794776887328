import { object, func } from 'prop-types'
import { BaseButtons } from 'inputs'
import ModalConForm from './ModalConForm'

ModalModificaRiga.propTypes = {
  modificaRiga: func.isRequired,
  valoriRigaAttuali: object.isRequired,
  propsModal: object,
  propsModalModificaRiga: object
}

export default function ModalModificaRiga(props) {
  const {
    modificaRiga,
    valoriRigaAttuali,
    propsModal,
    propsModalModificaRiga
  } = props

  return (
    <ModalConForm
      salvaDati={modificaRiga}
      defaultValues={valoriRigaAttuali}
      labelBottoneApriModal='Modifica'
      renderBottoneApriModal={(openModal, labelBottoneApriModal) =>
        <BaseButtons.Modifica
          testo={labelBottoneApriModal}
          onClick={openModal}
        />
      }
      {...propsModal}
      {...propsModalModificaRiga}
    />
  )
}