import { BaseButtons, BaseForm, BasePassword } from 'inputs'
import { BaseGridLayout } from 'layout'
import { func, object, string } from 'prop-types'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { BaseTesto } from 'text'

FormCambioPassword.propTypes = {
  onSubmit: func,
  zxcvbn: func,
  variant: string,
  propsButton: object
}

export default function FormCambioPassword(props) {
  const {
    onSubmit,
    variant = 'outlined',
    zxcvbn,
    propsButton,
    configurazioneValidazione,
    ...restProps
  } = props

  return (
    <BaseForm
      onSubmit={onSubmit}
      larghezzaInputs={20}
      {...restProps}
    >
      <AggiornaPasswordForm
        zxcvbn={zxcvbn}
        variant={variant}
        configurazioneValidazione={configurazioneValidazione}
        propsButton={propsButton}
      />
    </BaseForm>
  )
}

function validaPassword(password, zxcvbn, configurazioneValidazione){
  if(!password){
    return { feedback: { suggestions: [] }, score: -1 }
  }
  let score = 1
  if(configurazioneValidazione.maiuscole){
    if(!/[A-Z]/.test(password)){
      return { feedback: { suggestions: ['Deve contenere almeno una lettera maiuscola'] }, score }
    }
  }  
  if(configurazioneValidazione.minsucole){
    if(!/[a-z]/.test(password)){
      return { feedback: { suggestions: ['Deve contenere almeno una lettera minuscola'] }, score}
    }
  }
  if(configurazioneValidazione.numeri){
    if(!/[0-9]/.test(password)){
      return { feedback: { suggestions: ['Deve contenere almeno un numero'] }, score }
    }
  }
  if(configurazioneValidazione.carattere_speciale){
    if(!/[@$!%*#?&\\-\\_]/.test(password)){
      return { feedback: { suggestions: ['Deve contenere almeno uno tra questi caratteri speciali: @ $ ! % * # ? & - _ '] }, score }
    }
  }
  if(configurazioneValidazione.min_len>0){
    if(password.length < configurazioneValidazione.min_len){
      return { feedback: { suggestions: ['Deve essere lunga almeno ' + configurazioneValidazione.min_len + ' caratteri'] }, score }
    }
  }
  if(configurazioneValidazione.max_len>0){
    if(password.length > configurazioneValidazione.max_len){
      return { feedback: { suggestions: ['Deve essere più corta di ' + configurazioneValidazione.max_len + ' caratteri'] }, score}
    }
  }

  return zxcvbn(password)

}

function AggiornaPasswordForm(props) {
  const { zxcvbn, variant, propsButton, configurazioneValidazione ={ maiuscole: true, minuscole: true,numeri:true,carattere_speciale:false,min_len:5,max_len:20} } = props

  const { watch } = useFormContext()
  const [newPassword, newPasswordRepeat, oldPassword] = watch(['newPassword', 'newPasswordRepeat', 'oldPassword'])
  const pw = validaPassword(newPassword, zxcvbn , configurazioneValidazione)
  const vecchiaUgualeNuova = oldPassword && newPassword && oldPassword === newPassword
  const nonConfermata = newPasswordRepeat !== newPassword
  const disabled = !(pw.score >= 2 && !nonConfermata && !vecchiaUgualeNuova)

  return (
    <BaseGridLayout vertical spacing={0}>
      <BasePassword
        label='Vecchia Password'
        name='oldPassword'
        defaultValue=''
        variant={variant}
        required
      />
      <PasswordStr {...pw} />
      <BasePassword
        label='Nuova Password'
        name='newPassword'
        defaultValue=''
        variant={variant}
        required
        {...(vecchiaUgualeNuova) && {
          error: true,
          helperText: 'La nuova password deve essere diversa della vecchia'
        }}

        {...(pw?.feedback?.suggestions.length ?? false) && {
          error: true,
          helperText: pw?.feedback?.suggestions.join('')
        }}
      />
      <BasePassword
        label='Conferma Password'
        name='newPasswordRepeat'
        defaultValue=''
        variant={variant}
        required

        {...(nonConfermata) && {
          error: true,
          helperText: 'Confema password diversa dalla nuova password '
        }}
      />
      <BaseButtons.Conferma disabled={disabled} submit {...propsButton} />
    </BaseGridLayout>)
}



const scoreConf = [
  { color: '', width: '0%', text: '' },
  { color: 'darkred', width: '20%', text: 'Pericolosa' },
  { color: 'orangered', width: '40%', text: 'Insicura' },
  { color: 'orange', width: '60%', text: 'Debole' },
  { color: 'yellowgreen', width: '80%', text: 'Buona' },
  { color: 'green', width: '100%', text: 'Ottima' },
]

function PasswordStr(props) {
  const { score = 0 } = props

  const [state, setstate] = useState(scoreConf[0])

  useEffect(() => {
    setstate(scoreConf[(typeof scoreConf[score + 1] === 'undefined') ? 0 : score + 1])
  }, [score])

  return (
    <div className='password-str' style={{
      width: '20rem',
      height: '35px',
      paddingTop: '12px',
      flexDirection: 'column',
      position: 'relative'
    }}>
      <BaseTesto style={{ textAlign: 'left' }}>
        {state.text && <>Valutazione: </>}
        <span style={{ color: state.color }}>{state.text}</span>
      </BaseTesto>

      <div style={{
        //position: 'absolute',
        bottom: '-16px',
        backgroundColor: state.color,
        height: '5px',
        width: state.width,
        transition: 'all 300ms ease-in-out'
      }} />
    </div>
  )
}
