import { useStateWithLabel, generaUUID } from 'utils'

export default function useLogicaUploadFile(props) {
  const {
    fileCaricato,
    setFileCaricato,
    defaultValues_FileGiaCaricato,
    uploadFile,
    calcolaAltreInfoPerUpload,
    opzioniUploadFile,
    salvaDati,
    closeModal,
    // Se non viene passato nessun prefisso, vuol dire che non c'è
    // nessuna form aggiuntiva. La stringa vuota per il prefisso fa 
    // in modo che non si schianti niente quando provo a leggere
    // l'oggetto dei campi aggiuntivi nei valori form
    prefissoNomiCampiAggiuntivi = ''
  } = props

  const [
    percentualeCaricata, setPercentualeCaricata
  ] = useStateWithLabel(null, 'percentualeCaricata')

  const [
    modificaDati_EffettuataConSuccesso, setModificaDati_EffettuataConSuccesso
  ] = useStateWithLabel(false, 'modificaDati_EffettuataConSuccesso')

  function onUploadProgress(progressEvent) {
    const { loaded, total } = progressEvent
    setPercentualeCaricata(loaded / total * 100)
  }

  async function caricaFile_OppureModificaInfo(valoriCampi) {
    const {
      file: fileList,
      // Estraggo eventuali dati aggiuntivi così non li mando al server
      [prefissoNomiCampiAggiuntivi]: datiFormAggiuntiva,
      ...altriCampi
    } = valoriCampi
    const file = fileList?.[0]

    let data = fileCaricato ? {
      // Nella form NON c'è l'input file
      // Modifica delle info SENZA modificare il file
      ...fileCaricato,
      ...altriCampi
    } : {
      // Nella form c'è l'input file
      // Modifica delle info + Caricamento di un nuovo file
      // Mantengo lo stesso uuid se esiste un fileGiaCaricato
      uuid: defaultValues_FileGiaCaricato?.uuid || generaUUID(),
      filename: file.name,
      mimetype: file.type,
      ...altriCampi
    }

    // Aggiungo eventuali info impostate da una funzione esterna
    const altreInfoDaMandare = calcolaAltreInfoPerUpload?.(data)
    if (altreInfoDaMandare) data = { ...data, ...altreInfoDaMandare }

    // Se file non esiste, la funzione di upload deve capire
    // che voglio solo modificare le info e non il file
    const { ok } = await uploadFile(file, data, onUploadProgress, opzioniUploadFile)
    if (ok) {
      if (fileCaricato) setModificaDati_EffettuataConSuccesso(true)
      
      // Aspetto un paio di secondi prima di notificare il caricamento
      // e chiudere la modal, così l'utente ha tempo di vedere il feedback
      setTimeout(() => {
        const datiCompleti = prefissoNomiCampiAggiuntivi
          // Reinserisco eventuali dati aggiuntivi per passarli al padre
          ? { ...data, [prefissoNomiCampiAggiuntivi]: datiFormAggiuntiva }
          : data
        salvaDati?.(datiCompleti)
        resetStato()
      }, 1000)
    }
  }

  function resetStato() {
    setFileCaricato(defaultValues_FileGiaCaricato || null)
    setPercentualeCaricata(null)
    setModificaDati_EffettuataConSuccesso(false)
    closeModal?.()
  }
  
  return {
    percentualeCaricata,
    modificaDati_EffettuataConSuccesso,
    caricaFile_OppureModificaInfo
  }
}