import { arrayOf, shape, string, object, elementType, number, oneOfType, objectOf } from 'prop-types'
import { Box } from '@mui/material'
import { BaseLink } from './BaseLink'
import { BaseScatola, BaseGridLayout } from 'layout'
import { BaseTitolo, BaseTesto } from 'text'
import { sx } from 'utils'
import BaseLinkConIcona from './BaseLinkConIcona'

const routeShape = shape({
  label: string.isRequired,
  pathAssoluto: string.isRequired,
  Icon: elementType.isRequired
})
BaseScatoleNavigazione.propTypes = {
  routes: oneOfType([
    arrayOf(routeShape),
    objectOf(routeShape)
  ]).isRequired,
  moltiplicatoreGrandezzaIcona: number,
  moltiplicatoreGrandezzaLabel: number,
  propsLabel: object,
  propsIcona: object
}

export default function BaseScatoleNavigazione(props) {
  const {
    routes,
    moltiplicatoreGrandezzaIcona = 1,
    moltiplicatoreGrandezzaLabel = 1,
    propsLabel,
    propsIcona
  } = props

  let routesDaMostrare = routes
  if (typeof routes === 'object' && !Array.isArray(routes)) {
    routesDaMostrare = Object.values(routes)
  }

  if (!routesDaMostrare || routesDaMostrare.length < 1) {
    return <BaseTesto>Nessun link da mostrare.</BaseTesto>
  }

  const sxLinkRotta = {
    p: 0.5,
    borderRadius: 1,
    '&:hover': { bgcolor: 'grey.200' }
  }

  const scatole = routesDaMostrare.map(({ label, pathAssoluto, Icon, subRoutes }) =>
    <BaseScatola
      key={pathAssoluto}
      sx={{
        p: 1.5,
        width: '20rem',
        '&:hover': { boxShadow: 5 }
      }}
    >
      <BaseLink
        to={pathAssoluto}
        underline='none'
        variant='h6'
        color='black'
      >
        <Box sx={sxLinkRotta}>
          <BaseGridLayout alignCenter >
            <Icon
              {...propsIcona}
              {...sx(propsIcona, {
                fontSize: `${3 * moltiplicatoreGrandezzaIcona}rem`,
                verticalAlign: 'middle'
              })}
            />

            <BaseTitolo
              colorato
              noMargine
              {...propsLabel}
              {...sx(propsLabel, {
                fontSize: `${moltiplicatoreGrandezzaLabel}em`,
                lineHeight: '1.5rem'
              })}
            >
              {label}
            </BaseTitolo>
          </BaseGridLayout>
        </Box>
      </BaseLink>

      {subRoutes?.length > 0 &&
        <BaseGridLayout
          vertical
          spacing={0}
          // Se non accorcio la larghezza, anche impostando mx viene messo solo ml
          sx={({ spacing }) => ({ ml: 1, width: `calc(100% - ${spacing(2)})` })}
        >
          {subRoutes.map(route =>
            <BaseLinkConIcona
              route={route}
              key={route.pathAssoluto}
              disableTooltipIcona
              sx={sxLinkRotta}
            />
          )}
        </BaseGridLayout>
      }
    </BaseScatola>
  )

  return (
    <BaseGridLayout spacing={3}>
      {scatole}
    </BaseGridLayout>
  )
}

