import { cloneElement } from 'react'
import { string, node, bool, oneOf } from 'prop-types'
import { Avatar } from '@mui/material'
import { sx } from 'utils'

BaseAvatar.propTypes = {
  icona: node.isRequired,
  coloreIcona: string,
  iconaNonColorata: bool,
  dimensioneIcona: oneOf(['grande', 'media', 'piccola'])
}

export default function BaseAvatar(props) {
  const {
    iconaNonColorata,
    coloreIcona = 'info.light',
    dimensioneIcona = 'grande',
    icona,
    ...restProps
  } = props

  let iconaDefinitiva
  if (dimensioneIcona === 'piccola') {
    iconaDefinitiva = cloneElement(icona, sx(icona.props, { fontSize: '15px' }))
  } else if (dimensioneIcona === 'media') {
    iconaDefinitiva = cloneElement(icona, sx(icona.props, { fontSize: '25px' }))
  } else {
    iconaDefinitiva = icona
  }

  const propsIconaDefinitive = {
    ...restProps,
    ...sx(restProps, [
      iconaNonColorata ? {
        color: 'text.primary',
        bgcolor: 'transparent',
        mr: -1,
        width: 'inherit',
        height: 'inherit'
      } : {
        color: 'common.black',
        bgcolor: coloreIcona
      },
      dimensioneIcona === 'piccola' ? { width: 25, height: 25 } : {},
      dimensioneIcona === 'media' ? { width: 35, height: 35 } : {}
    ])
  }

  return (
    <Avatar {...propsIconaDefinitive}>
      {iconaDefinitiva}
    </Avatar>
  )
}