import { useCallback, useEffect, useRef } from 'react'
import { Box } from '@mui/material'
import { BasePopoverConfermaOperazione, BaseVoto, VOTI, VOTI_VALUES } from 'feedback'
import { BaseForm, BaseIconButtons, BaseTextInput } from 'inputs'
import { BaseGridLayout } from 'layout'
import { debounce } from 'utils'
import { BaseGrassetto } from 'text'

export default function ModificaMetadatiEdEliminazioneImmagine(props) {
  const {
    immagineCorrente,
    apriModificaBinario,
    onImmagineModificata,
    onImmagineEliminata,
    cambiaImmagineDopoEliminazione,
    ComponentePerDownloadFile,
    nascondiVoti,
    readOnly
  } = props

  function modificaMetadati(nuoviMetadati) {
    if (readOnly) return
    onImmagineModificata({ ...immagineCorrente, ...nuoviMetadati })
  }

  function elimina() {
    if (readOnly) return
    onImmagineEliminata(immagineCorrente)
    cambiaImmagineDopoEliminazione()
  }

  /******* Form titolo e descrizione *******/

  const formRef = useRef()

  useEffect(() => {
    formRef.current.reset(immagineCorrente)
  }, [immagineCorrente])

  const modificaMetadati_Ref = useRef(modificaMetadati)
  modificaMetadati_Ref.current = modificaMetadati

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const modificaMetadati_ConRitardo = useCallback(
    debounce(() => {
      const nuoviMetadati = formRef.current.watch()
      modificaMetadati_Ref.current(nuoviMetadati)
    }, 750),
    []
  )

  /*****************************************/

  /***** Assegnazione voto *****/

  const votoCorrente = immagineCorrente?.voto ?? VOTI.NEUTRO

  function assegnaVoto(nuovoVoto) {
    if (votoCorrente === nuovoVoto) return
    modificaMetadati({ voto: nuovoVoto })
  }

  /*****************************/

  return (
    <BaseGridLayout justifyCenter spacing={4} disposizioneRighe={[3, 1]}>
      <div hidden={nascondiVoti}>
        {readOnly ? (
          <Box sx={{ p: 1 }}>
            <BaseVoto value={votoCorrente} />
          </Box>
        ) : (
          <>
            {VOTI_VALUES.map(value =>
              <BaseVoto
                key={value}
                value={value}
                currentValue={votoCorrente}
                showbutton
                buttonProps={{ onClick: () => assegnaVoto(value) }}
              />
            )}
          </>
        )}
      </div>

      <>
        <span style={{ display: 'inline-block' }}>
          <ComponentePerDownloadFile file={immagineCorrente} />
        </span>
        <BaseIconButtons.Modifica
          contenutoTooltip='Modifica immagine (rotazione)'
          onClick={() => !readOnly && apriModificaBinario()}
          color='primary'
          disabled={readOnly}
        />
        <BasePopoverConfermaOperazione
          messaggio="Confermare l'eliminazione?"
          onConferma={() => !readOnly && elimina()}
        >
          <BaseIconButtons.Elimina disabled={readOnly} />
        </BasePopoverConfermaOperazione>
      </>

      <>
        <span style={{ display: 'inline-block' }}>
          <ComponentePerDownloadFile file={immagineCorrente} forzaAllegatoOriginale />
        </span>
        <BaseGrassetto>Originale</BaseGrassetto>
        <BaseIconButtons.Aiuto contenutoTooltip="Scarica l'immagine in qualità originale (non ridimensionata)" />
      </>

      <BaseForm
        ref={formRef}
        defaultValues={immagineCorrente}
        onChangeTuttiCampi={modificaMetadati_ConRitardo}
        larghezzaInputs={25}
        readOnly={readOnly}
        nonUsareFormTagHtml
      >
        <BaseGridLayout>
          <BaseTextInput
            name='titolo' label='Titolo'
            multiline rows={2} InputLabelProps={{ shrink: true }}
          />
          <BaseTextInput
            name='descrizione' label='Descrizione'
            multiline rows={2} InputLabelProps={{ shrink: true }}
          />
        </BaseGridLayout>
      </BaseForm>
    </BaseGridLayout>
  )
}