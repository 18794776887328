import { useMemo } from 'react'
import { any, array, arrayOf, bool, element, func, number, object, oneOf, oneOfType, shape, string } from 'prop-types'
import { useBlockLayout, useTable } from 'react-table'
import { Paper, Table, TableContainer, useTheme } from '@mui/material'
import { convertiRemInPx } from 'utils'
import CaricaPiuRisultati from './componentiTabella/CaricaPiuRisultati'
import HeaderTabella from './componentiTabella/HeaderTabella'
import MessaggioNessunaRiga from './componentiTabella/MessaggioNessunaRiga'
import RigheTabella from './componentiTabella/RigheTabella'
import TitoloEToolbar from './componentiTabella/TitoloEToolbar'
import ModalAggiuntaRiga from './tabellaModificabile/ModalAggiuntaRiga'
import ModalModificaRiga from './tabellaModificabile/ModalModificaRiga'
import { getColumnsDefinitive, TIPI_COLONNA } from './UtilsTabella'

/**
 * @typedef {import("../common/tables/BaseTabella").BaseTabellaProps} BaseTabellaProps 
 * @typedef {import("../common/tables/BaseTabella").BaseTabella} BaseTabella 
 */


BaseTabella.propTypes = {
  columns: arrayOf(shape({
    accessor: string.isRequired,
    Header: oneOfType([string, func]),
    parteHeaderNonGrassetto: string,
    Cell: func,
    textAlign: oneOf(['left', 'right']),
    tipo: oneOf(Object.values(TIPI_COLONNA)),
    formattaCella: func,
    mappaLabel: object,
    mappaOptionLabel: arrayOf(shape({
      label: string.isRequired,
      value: any.isRequired
    })),
    textEllipsis: bool, // Funziona solo se abilitaLarghezzaManualeColonne è true
    calcolaCellStyle: func,
    nascosta: bool,
    spiegazione: string
  })).isRequired,
  data: array.isRequired,
  virtualizza: bool,
  numeroRigheVirtualizzateDaMostrare: number,
  title: oneOfType([string, element]),
  toolbar: element,
  messaggioNessunaRiga: string,
  onCaricaPiu: func,
  numeroRisultatiDaCaricare_Default: number,

  // Props per tabella modificabile
  // Cose importanti da ricordare:
  // - assicurarsi che ogni riga abbia un campo uuid
  // - passare la funzione renderForm all'interno di propsModal
  //   per avere la stessa form sia in aggiunta che in modifica
  // - si possono funzioni renderForm diverse per aggiunta e modifica
  // - all'interno della form bisogna chiamare la funzione salvaDati
  //   passando un oggetto con i dati del submit, INSIEME ALL'UUID
  datiModificabili: bool,
  aggiungiRiga: func,
  eliminaRiga: func,
  modificaRiga: func,
  propsModal: object, // Props per tutte le modal (sia aggiunta che modifica)
  propsModalModificaRiga: object,
  propsModalAggiuntaRiga: object,
  ComponenteAggiuntaRiga: func, // Sostituisce il componente di default per aggiungere una riga
  ComponenteModificaRiga: func, // Sostituisce il componente di default per modificare una riga
  numeroMassimoRighe: number,
  nascondiComponenteAggiuntaRiga: bool,
  inAttesaEliminazioneRigaConUuid: string,
  disabilitaEliminazioneRiga: bool,
  isRigaEliminabile: func, // Riceve il contenuto della riga e deve ritornare un booleano
  propsColonnaAzioniRiga: object, // Deve essere memoizzato

  // Props di stile
  tableContainerStyle: object,
  tableContainerProps: object,
  ultimaRigaInGrassetto: bool,
  valoriNegativiInRosso: bool,
  righeColoriAlternati: bool,
  abilitaLarghezzaManualeColonne: bool,
  larghezzaColonne_InRem: number, // Adatta la larghezza delle colonne alla dimensione del font del browser
  componentContainer: oneOfType([element, string]),
  contenutoInFondo: element
  // Eventuali altre props sono passate allo stato della tabella,
  // in modo che siano accessibili da vari componenti interni
}

/**
 * 
 * @param {BaseTabellaProps} props 
 * @type {BaseTabella} 
 */
export default function BaseTabella(props) {
  const {
    columns,
    data,
    virtualizza,
    numeroRigheVirtualizzateDaMostrare = 10,
    title,
    toolbar,
    messaggioNessunaRiga,
    onCaricaPiu,
    numeroRisultatiDaCaricare_Default = 10,

    datiModificabili,
    aggiungiRiga,
    eliminaRiga,
    modificaRiga,
    propsModal,
    propsModalModificaRiga,
    propsModalAggiuntaRiga,
    ComponenteAggiuntaRiga = ModalAggiuntaRiga,
    ComponenteModificaRiga = ModalModificaRiga,
    numeroMassimoRighe,
    nascondiComponenteAggiuntaRiga,
    inAttesaEliminazioneRigaConUuid,
    disabilitaEliminazioneRiga,
    isRigaEliminabile = () => true,
    propsColonnaAzioniRiga,

    tableContainerStyle,
    tableContainerProps,
    ultimaRigaInGrassetto,
    valoriNegativiInRosso = true,
    righeColoriAlternati,
    abilitaLarghezzaManualeColonne,
    larghezzaColonne_InRem = 7.5,

    componentContainer = Paper,
    contenutoInFondo,

    ...restProps
  } = props

  const {
    columnsDefinitive,
    hiddenColumns
  } = useMemo(
    () => getColumnsDefinitive(columns, datiModificabili, propsColonnaAzioniRiga),
    [columns, datiModificabili, propsColonnaAzioniRiga]
  )

  const larghezzaManualeColonneAbilitata = abilitaLarghezzaManualeColonne || virtualizza
  const defaultColumn = useMemo(
    () => larghezzaManualeColonneAbilitata ? { width: convertiRemInPx(larghezzaColonne_InRem) } : {},
    [larghezzaManualeColonneAbilitata, larghezzaColonne_InRem]
  )
  const altriParametriUseTable = larghezzaManualeColonneAbilitata ? [useBlockLayout] : []

  const paddingCelle = parseFloat(useTheme().spacing(2))

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns: columnsDefinitive,
    data,
    initialState: { hiddenColumns },
    defaultColumn,

    modificaRiga,
    eliminaRiga,
    propsModal,
    propsModalModificaRiga,
    ComponenteModificaRiga,
    inAttesaEliminazioneRigaConUuid,
    disabilitaEliminazioneRiga,
    isRigaEliminabile,

    ultimaRigaInGrassetto,
    valoriNegativiInRosso,
    righeColoriAlternati,
    paddingCelle,

    ...restProps
  }, ...altriParametriUseTable)

  // Le tabelle non virtualizzate con larghezza fissata devono 
  // avere la larghezza del contenitore adattata al contenuto
  const altriStiliTableContainer = {
    ...((!virtualizza && larghezzaManualeColonneAbilitata) && { width: 'fit-content' })
  }

  // Aggiusta lo scrolling della tabella virtualizzata
  const altriStiliTable = {
    ...(virtualizza && { overflowX: 'auto', display: 'block' })
  }

  return (
    <TableContainer
      component={componentContainer}
      style={{ ...altriStiliTableContainer, ...tableContainerStyle }}
      {...(tableContainerProps?.variant !== 'outlined' && { elevation: 3 })}
      {...tableContainerProps}
    >
      {(title || toolbar) &&
        <TitoloEToolbar title={title} toolbar={toolbar} />
      }

      <Table {...getTableProps({ component: 'div', style: altriStiliTable })}>
        <HeaderTabella
          headerGroups={headerGroups}
          virtualizza={virtualizza}
          paddingInizialePerScrollbar={
            // Aggiusta la posizione dell'header nelle tabelle virtualizzate
            virtualizza && rows.length > numeroRigheVirtualizzateDaMostrare
          }
        />

        {rows.length > 0 &&
          <RigheTabella
            rows={rows}
            prepareRow={prepareRow}
            getTableBodyProps={getTableBodyProps}
            virtualizza={virtualizza}
            numeroRigheVirtualizzateDaMostrare={numeroRigheVirtualizzateDaMostrare}
            paddingCelle={paddingCelle}
          />
        }
      </Table>

      {rows.length === 0 &&
        <MessaggioNessunaRiga
          messaggioNessunaRiga={messaggioNessunaRiga}
          datiModificabili={datiModificabili}
        />
      }

      {datiModificabili &&
        <ComponenteAggiuntaRiga
          aggiungiRiga={aggiungiRiga}
          numeroRigheAttuali={rows.length}
          numeroMassimoRighe={numeroMassimoRighe}
          nascondiComponenteAggiuntaRiga={nascondiComponenteAggiuntaRiga}
          propsModal={propsModal}
          propsModalAggiuntaRiga={propsModalAggiuntaRiga}
        />
      }

      {onCaricaPiu &&
        <CaricaPiuRisultati
          onCaricaPiu={onCaricaPiu}
          numeroRisultatiDaCaricare_Default={numeroRisultatiDaCaricare_Default}
        />
      }

      {contenutoInFondo}
    </TableContainer>
  )
}