import { arrayOf, shape, string, bool, object } from 'prop-types'
import { BaseButtons } from 'inputs'
import { BasePopover, usePopover } from 'feedback'
import { BaseScatola } from 'layout'
import { BaseTesto, BaseGrassetto } from 'text'

BottoneInfoTabella.propTypes = {
  colonne: arrayOf(shape({
    accessor: string.isRequired,
    Header: string,
    spiegazione: string,
    nascosta: bool
  })).isRequired,
  propsPopover: object
}

export default function BottoneInfoTabella(props) {
  const { colonne, propsPopover, ...propsBottone } = props

  const {
    popoverRef,
    openPopover,
    closePopover
  } = usePopover()

  return (
    <>
      <BaseButtons.Legenda
        testo='Informazioni tabella'
        onClick={openPopover}
        {...propsBottone}
      />

      <BasePopover
        ref={popoverRef}
        posizione='top'
        {...propsPopover}
      >
        <BaseScatola
          info sx={{ maxWidth: 500 }}
          onClick_BottoneChiudi={closePopover}
        >
          {colonne.map(({ accessor, Header, spiegazione, nascosta }) =>
            !nascosta && Header && spiegazione && (
              <BaseTesto key={accessor}>
                <li>
                  <BaseGrassetto>{Header}</BaseGrassetto>: {spiegazione}
                </li>
              </BaseTesto>
            )
          )}
        </BaseScatola>
      </BasePopover>
    </>
  )
}
