import { oneOf, string, bool } from 'prop-types'
import { Chip } from '@mui/material'
import { BaseTesto } from 'text'
import { sx } from 'utils'
import { COLOR_STATI_ENTITA, LABEL_STATI_ENTITA, STATI_ENTITA } from './ConfigurazioneStatiEntita'

LabelStatoEntita.propTypes = {
  stato: oneOf(Object.values(STATI_ENTITA)).isRequired,
  motivazioneAnnullamento: string,
  grande: bool,
  mostraAncheInLavorazione: bool
}

export default function LabelStatoEntita(props) {
  const {
    stato,
    motivazioneAnnullamento,
    grande,
    mostraAncheInLavorazione,
    ...restProps
  } = props

  let label
  let color
  if (STATI_ENTITA[stato]) {
    if (mostraAncheInLavorazione || STATI_ENTITA[stato] !== STATI_ENTITA.IN_LAVORAZIONE) {
      label = LABEL_STATI_ENTITA[stato]
      color = COLOR_STATI_ENTITA[stato]
    }
  }

  return (
    <>
      {label && color &&
        <Chip
          label={label}
          color={color}
          size={grande ? 'medium' : 'small'}
          {...restProps}
          {...sx(restProps, grande && { fontSize: '1.25rem' })}
        />
      }

      {stato === STATI_ENTITA.ANNULLATO && motivazioneAnnullamento &&
        <BaseTesto component='span' sx={{ color: 'error.main', ml: 1 }}>
          Motivazione annullamento: {motivazioneAnnullamento}
        </BaseTesto>
      }
    </>
  )
}