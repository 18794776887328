import { useMemo } from 'react'
import { arrayOf, shape, func, bool, string, object } from 'prop-types'
import { useFormContext } from 'react-hook-form'
import { BaseSelect, BaseSelectMultipla } from 'inputs'
import { BaseGridLayout } from 'layout'
import { trovaElementoConUUID } from '../../utils'
import LinkDettagliEntita from './LinkDettagliEntita'

ModificaRif.propTypes = {
  descrittori: arrayOf(shape({
    entita: object.isRequired,
    nomeEntita: string.isRequired,
    path: string.isRequired,
    url: string,
    label: string
  })),
  renderEntita: func,
  senzaOpzioneVuota: bool,
  testoLinkDettagli: string,
  nascondiLinkDettagli: bool,
  sceltaMultipla: bool
}

const getOptionValue = option => option?.path ?? ''
const transformOutput = (_, originalOption) => {
  const { nomeEntita, path, label, entita } = originalOption
  if (!path) return null
  return {
    identificativo: {
      entitaNome: nomeEntita,
      uuid: entita?.uuid ?? ''
    },
    path,
    label
  }
}

const LISTA_VUOTA = []

export default function ModificaRif(props) {
  const {
    descrittori,
    renderEntita,
    senzaOpzioneVuota,
    testoLinkDettagli = 'Dettagli',
    nascondiLinkDettagli,
    sceltaMultipla,
    ...restProps
  } = props

  const options = useMemo(() => {
    return senzaOpzioneVuota || sceltaMultipla
      ? descrittori
      : [{ path: '' }, ...descrittori]
  }, [senzaOpzioneVuota, sceltaMultipla, descrittori])

  const risultatiUseForm = useFormContext()
  const valoreAttuale = (
    restProps.value
    || risultatiUseForm?.watch(restProps.name)
    || (sceltaMultipla ? LISTA_VUOTA : null)
  )
  const url = useMemo(() => {
    if (!valoreAttuale || sceltaMultipla) return ''
    return trovaElementoConUUID(descrittori, valoreAttuale.path, 'path')?.url ?? ''
  }, [descrittori, valoreAttuale, sceltaMultipla])

  const Select = sceltaMultipla ? BaseSelectMultipla : BaseSelect

  return (
    <BaseGridLayout alignCenter spacing={1}>
      <Select
        options={options}
        getOptionValue={getOptionValue}
        transformInput={getOptionValue}
        transformOutput={transformOutput}
        {...(renderEntita && {
          renderOption: ({ value, originalOption }) => value
            ? renderEntita(originalOption.entita, originalOption.label)
            : <div style={{ height: '2rem' }}></div>
        })}
        {...(sceltaMultipla && { noVirgolaTraOpzioniSelezionate: true })}
        {...restProps}
      />

      {!nascondiLinkDettagli && !sceltaMultipla &&
        <LinkDettagliEntita url={url} testo={testoLinkDettagli} />
      }
    </BaseGridLayout>
  )
}