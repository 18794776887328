import { Box } from '@mui/material'
import { BasePopoverConfermaOperazione } from 'feedback'
import { BaseButtons } from 'inputs'
import { BaseModal, useModal } from 'layout'
import BottoneEliminaEntita from '../entitaGenerica/components/BottoneEliminaEntita'
import ListaDocumentiSostituiti from './components/ListaDocumentiSostituiti'

export default function EliminaDocumento(props) {
  const {
    propsEntita,
    documentoInput
  } = props

  const { pathEntita, nomeEntita } = propsEntita
  const { sostituisce } = documentoInput

  return sostituisce ? (
    <ModalEliminaDocumentoConPiuVersioni
      documento={documentoInput}
      path={pathEntita}
      nomeEntita={nomeEntita}
      {...props}
    />
  ) : (
    <BottoneEliminaEntita
      nomeEntita={nomeEntita}
      path={pathEntita}
    />
  )
}



function ModalEliminaDocumentoConPiuVersioni(props) {
  const {
    documento: documentoPiuRecente,
    path: pathDocumentoPiuRecente,
    nomeEntita,
    eliminaCatenaDocumentiSostituiti,
    trovaTemplateConNome,
    readOnly
  } = props

  const { openModal, modalRef } = useModal()

  return (
    <>
      <BaseButtons.Elimina
        onClick={openModal}
        disabled={readOnly}
        noMarginY
      />

      <BaseModal
        ref={modalRef}
        titolo='Elimina documento con più versioni'
        maxWidth='md'
        contenutoNonCentrato
      >
        <ListaDocumentiSostituiti
          documento={documentoPiuRecente}
          path={pathDocumentoPiuRecente}
          nomeEntita={nomeEntita}
          trovaTemplateConNome={trovaTemplateConNome}
          abilitaEliminazione
        />

        <Box sx={{ mt: 2, textAlign: 'right' }}>
          <BasePopoverConfermaOperazione
            messaggio='Tutte le versioni del documento saranno eliminate. Confermare?'
            onConferma={() => eliminaCatenaDocumentiSostituiti(
              documentoPiuRecente,
              pathDocumentoPiuRecente
            )}
          >
            <BaseButtons.Elimina testo='Elimina tutti' />
          </BasePopoverConfermaOperazione>
        </Box>
      </BaseModal>
    </>
  )
}