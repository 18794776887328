import { BaseIconaSVG } from 'icons'

export default function IconaDateTime(props) {
  return (
    <BaseIconaSVG viewBox='0 0 1400 1400' {...props}>
      <g xmlns='http://www.w3.org/2000/svg'>
        <g transform='translate(400,750) scale(0.1,-0.1)'>
          <path d='M1536.9,4964.3c-98-60.7-149.4-179.7-149.4-350.1v-142.4h-291.7c-196,0-317.4-11.7-368.7-32.7c-102.7-42-200.7-144.7-259-270.7l-49-105V1029.7c0-2730.4,4.7-3043.1,37.3-3115.4c58.3-126,151.7-224,273-284.7l109.7-53.7H2041c1019.8,0,1201.8,4.7,1194.8,32.7c-4.7,18.7-14,135.4-21,261.4l-14,231l-1122.5,4.7l-1122.5,7V341.3v2228.6l2749,7l2746.7,4.7v-490.1v-490.1h217c116.7,0,238-7,268.4-14l51.3-14v1197.2c0,707.1-11.7,1241.5-23.3,1299.8c-32.7,140-130.7,277.7-245,345.4c-91,51.3-121.4,56-403.7,56h-308v158.7c0,179.7-49,284.7-161,343.1c-231,116.7-443.4-58.3-445.7-366.4v-135.3H3697.9H1994.3v140c0,77-14,177.4-32.7,221.7C1896.3,4989.9,1686.3,5055.3,1536.9,4964.3z M1431.9,3449.7c39.7-77,163.4-144.7,259-144.7c184.4,0,303.4,147,303.4,378.1l2.3,123.7l77-86.3c182-207.7,170.4-473.7-30.3-674.4c-207.7-207.7-478.4-214.7-693.1-16.3c-198.4,182-219.4,478.4-49,683.8l74.7,91l14-149.3C1396.9,3571.1,1415.6,3480.1,1431.9,3449.7z M5462.1,3435.7c51.3-79.3,149.4-130.7,247.4-130.7c184.3,0,298.7,137.7,298.7,359.4l2.3,142.4l79.4-86.3c266-287,81.7-772.4-317.4-826.1c-459.7-63-749.1,504.1-431.7,849.4l49,56l16.3-151.7C5413.1,3564.1,5438.8,3468.4,5462.1,3435.7z' />
          <path d='M2321,1893.2v-338.4h350h350v338.4v338.4h-350h-350V1893.2z' />
          <path d='M3347.8,1893.2v-338.4h350.1h350v338.4v338.4h-350h-350.1V1893.2z' />
          <path d='M4398,1893.2v-338.4h338.4h338.4v338.4v338.4h-338.4H4398V1893.2z' />
          <path d='M5424.8,1893.2v-338.4h338.4h338.4v338.4v338.4h-338.4h-338.4V1893.2z' />
          <path d='M1294.2,854.7V502.3l345.4,7l343.1,7v338.4v338.4l-343.1,7l-345.4,7V854.7z' />
          <path d='M2325.7,861.7l7-345.4h338.4h338.4l7,345.4l7,343.1h-352.4h-352.4L2325.7,861.7z' />
          <path d='M3357.2,1174.4c-4.7-16.3-7-172.7-4.7-345.4l7-312.7l345.4-7l343.1-7v352.4v350h-338.4C3452.8,1204.8,3366.5,1197.8,3357.2,1174.4z' />
          <path d='M4398,994.7V782.4l149.4,114.3c81.7,63,212.4,154,291.7,203s142.4,91,142.4,95.7c0,4.7-130.7,9.3-291.7,9.3H4398V994.7z' />
          <path d='M6043.2,1088.1C5445.8,966.7,4969.7,710,4535.7,273.6C3982.6-281.8,3697.9-918.9,3658.2-1689c-70-1393.2,870.5-2665,2224-3008.1c714.1-179.7,1435.2-98,2095.6,238c536.7,273,1033.8,772.5,1304.5,1311.5c331.4,662.8,392.1,1549.6,156.4,2256.7C9189-148.8,8654.6,472,7961.5,822c-259,130.7-553.1,231-819.1,277.7C6855.3,1148.7,6316.2,1144.1,6043.2,1088.1z M7179.7,416c840.1-226.4,1449.2-833.1,1680.2-1680.2c74.7-270.7,72.3-847.1-2.4-1124.8c-228.7-854.1-910.1-1509.9-1764.2-1694.2c-224-46.7-683.8-53.7-886.8-11.7c-961.5,198.4-1659.2,896.1-1857.6,1857.6c-42,200.7-35,662.8,14,886.8c114.3,536.7,438.7,1043.2,884.5,1381.5c256.7,193.7,707.1,380.4,1052.5,434C6470.3,493,7002.3,462.7,7179.7,416z' /><path d='M6386.2-169.8c-42-28-95.7-81.7-121.3-123.7c-44.4-72.3-46.7-119-46.7-917.1c0-767.7,4.7-849.4,44.3-935.8c23.3-51.3,70-116.7,102.7-142.4c32.7-25.7,396.7-175,807.5-333.7c800.4-305.7,851.8-317.4,996.4-221.7c186.7,121.4,200.7,427.1,25.7,564.8c-30.3,23.3-329.1,147-665.1,275.4l-611.4,233.4v683.8c0,758.4-7,795.8-147,896.1C6671-118.4,6486.6-109.1,6386.2-169.8z' />
          <path d='M1294.2-172.1v-352.4l345.4,7l343.1,7v338.4v338.4l-343.1,7l-345.4,7V-172.1z' />
          <path d='M2337.4,163.9c-9.3-9.3-16.3-168-16.3-352.4v-333.7h350h352.4l-7,345.4l-7,343.1l-329,7C2500.7,175.6,2346.7,170.9,2337.4,163.9z' />
          <path d='M3357.2,147.6c-4.7-16.3-7-172.7-4.7-345.4c7-296.4,9.3-312.7,56-319.7c39.7-4.7,56,14,88.7,98c23.3,58.3,107.3,217,189,352.4l147,245h-231C3431.8,177.9,3366.5,168.6,3357.2,147.6z' />
          <path d='M1310.6-862.9c-9.3-9.3-16.3-168-16.3-352.4v-336l345.4,7l343.1,7v338.4v338.4l-329,7C1473.9-851.2,1319.9-855.9,1310.6-862.9z' />
          <path d='M2337.4-862.9c-9.3-9.3-16.3-168-16.3-352.4V-1549h350h352.4l-7,345.4l-7,343l-329,7C2500.7-851.2,2346.7-855.9,2337.4-862.9z' />
        </g>
      </g>
    </BaseIconaSVG>
  )
}