import { func, number } from 'prop-types'
import { BaseButtons, BaseTextInput } from 'inputs'
import { BaseGridLayout } from 'layout'
import { useStateWithLabel } from 'utils'

CaricaPiuRisultati.propTypes = {
  onCaricaPiu: func.isRequired,
  numeroRisultatiDaCaricare_Default: number
}

export default function CaricaPiuRisultati(props) {
  const {
    onCaricaPiu,
    numeroRisultatiDaCaricare_Default = 10
  } = props

  const [
    numeroRisultatiDaCaricare,
    setNumeroRisultatiDaCaricare
  ] = useStateWithLabel(numeroRisultatiDaCaricare_Default, 'numeroRisultatiDaCaricare')

  return (
    <BaseGridLayout justifyCenter sx={{ mb: 0 }}>
      <BaseButtons.Aggiungi
        testo='Carica altri risultati'
        color='default'
        onClick={() => onCaricaPiu(numeroRisultatiDaCaricare)}
      />

      <BaseTextInput
        label='Quantità'
        name='numeroRisultatiDaCaricare'
        type='number'
        min='1'
        value={numeroRisultatiDaCaricare}
        onChange={e => setNumeroRisultatiDaCaricare(e.target.value)}
        onBlur={() => setNumeroRisultatiDaCaricare(numeroAttuale => Math.max(1, numeroAttuale))}
        larghezza='cortissimo'
      />
    </BaseGridLayout>
  )
}