import { BaseTextInput, BaseDateInput } from 'inputs'
import { BaseScatolaConTitolo, BaseGridLayout } from 'layout'
import FormModificaEntita, { PROP_TYPES_WRAPPER_FORM_MODIFICA } from '../entitaGenerica/components/FormModificaEntita'

const NOMI = {
  STATO: 'stato',
  COMPAGNIA: 'compagnia',
  AGENZIA: 'agenzia',
  NUMERO: 'numero',
  DATA_ASSICURAZIONE: 'dataAssicurazione',
  DATA_SCADENZA: 'dataScadenza',
  DATA_FINE_COMPORTO: 'dataFineComporto',
  DATA_SOSPENSIONE: 'dataSospensione'
}

AssicurazioneModifica.propTypes = PROP_TYPES_WRAPPER_FORM_MODIFICA

export default function AssicurazioneModifica(props) {
  const { propsEntita, label } = props

  return (
    <BaseScatolaConTitolo titolo={label}>
      <FormModificaEntita {...propsEntita}>
        <BaseGridLayout disposizioneRighe={[3,2]}>
          <BaseTextInput label='Compagnia' name={NOMI.COMPAGNIA} />
          <BaseTextInput label='Agenzia' name={NOMI.AGENZIA} />
          <BaseTextInput label='Numero' name={NOMI.NUMERO} />
          <BaseDateInput label='Data assicurazione' name={NOMI.DATA_ASSICURAZIONE} disableFuture />
          <BaseDateInput label='Data scadenza' name={NOMI.DATA_SCADENZA} />
        </BaseGridLayout>
      </FormModificaEntita>
    </BaseScatolaConTitolo>
  )
}