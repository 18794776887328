import { oneOf, bool, object } from 'prop-types'
import { ICONE } from 'icons'
import { BaseIconButtons } from 'inputs'
import { sx } from 'utils'

export const VOTI = {
  POSITIVO: 0,
  NEUTRO: 1,
  NEGATIVO: 2
}

export const VOTI_VALUES = Object.values(VOTI)

const VOTI_DEFINIZIONI = {
  [VOTI.POSITIVO]: {
    Icon: ICONE.POLLICE_SU,
    color: 'green',
    label: 'Positivo',
    buttonTooltip: 'Assegna voto positivo'
  },
  [VOTI.NEUTRO]: {
    Icon: ICONE.POLLICE_SU,
    style: { transform: 'rotate(-90deg)' },
    color: 'orange',
    label: 'Neutro',
    buttonTooltip: 'Assegna voto neutro'
  },
  [VOTI.NEGATIVO]: {
    Icon: ICONE.POLLICE_GIU,
    color: 'red',
    label: 'Negativo',
    buttonTooltip: 'Assegna voto negativo'
  }
}

BaseVoto.propTypes = {
  value: oneOf(VOTI_VALUES),
  currentValue: oneOf(VOTI_VALUES),
  small: bool,
  showLabel: bool,
  showbutton: bool,
  iconProps: object,
  buttonProps: object
}

export default function BaseVoto(props) {
  const {
    value = VOTI.NEUTRO,
    currentValue,
    small,
    showLabel,
    showbutton,
    iconProps: iconProps_Passate,
    buttonProps
  } = props

  const votoDef = VOTI_DEFINIZIONI[value]
  if (!votoDef) return null

  const { Icon, style, color, label, buttonTooltip } = votoDef

  const colorSx = currentValue === undefined || currentValue === value
    ? { color }
    : { '&:hover': { color } }

  const iconProps = {
    ...(small && { fontSize: 'small' }),
    ...iconProps_Passate,
    ...sx(iconProps_Passate, {
      ...colorSx,
      ...style,
      ...(showLabel && { mr: 1 })
    })
  }

  const renderedIcon = showbutton ? (
    <BaseIconButtons.Custom
      contenutoTooltip={buttonTooltip}
      Icon={Icon}
      iconProps={iconProps}
      {...buttonProps}
    />
  ) : (
    <Icon {...iconProps} />
  )

  if (showLabel) {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {renderedIcon} {label}
      </div>
    )
  }

  return renderedIcon
}