import { Fragment, useCallback, useEffect, useRef } from 'react'
import { string, object, arrayOf, node, func, number, bool } from 'prop-types'
import { Box, Chip, Radio, Checkbox, Grid } from '@mui/material'
import { ICONE } from 'icons'
import { BaseDivider, BaseGridLayout, BaseModal, useModal } from 'layout'
import { BaseGrassetto, BaseTesto } from 'text'
import { contieneTutteLeParole, useIsFirstRender, useListaConScrollingInfinito, useStateWithLabel } from 'utils'
import useControllerSeServe from './hooks/ControllerSeServeHook'
import useInputGenerico from './hooks/InputGenericoHook'
import { BaseBarraRicercaConRitardo } from './BaseBarraRicercaConRitardo'
import { BaseButtons } from './BaseButtons'

/*
  Due modalità di funzionamento:
  - Caricare la lista opzioni già completa e filtraggio lato client
    -> passare options + eventuale filterOptions custom
  - Caricare le opzioni già filtrate sulla base dei filtri di ricerca impostati
    -> passare useFetchOptions 
*/
BaseSelectModal.propTypes = {
  sceltaMultipla: bool,
  label: string,

  // Se sono passate le options, useFetchOptions viene ignorato
  options: arrayOf(object),

  // Anziché passare le options, si può passare un hook che fornisce 
  // tutto il necessario per fare una chiamata ed ottenere le opzioni
  // Deve restituire un oggetto del tipo:
  // {
  //   fetchOptions: func.isRequired, // Chiamata con i valoriFormRicerca
  //   ScatolaMessaggi: node,
  //   inAttesa: bool
  // }
  useFetchOptions: func,

  /*** Tutte queste funzioni ricevono un'opzione ***/

  // Ritorna una stringa che identifica l'opzione
  getOptionKey: func,
  // Ritorna un elemento react da mostrare nella lista
  renderOption: func.isRequired,
  // Ritorna una stringa da mostrare nel riassunto delle opzioni selezionate
  getOptionLabel: func.isRequired,
  // Ritorna una stringa di ricerca in cui sarà cercato il testo inserito dall'utente
  // Obbligatoria solo se si usa la funzione filterOptions di default
  getOptionSearchString: func,
  // Ritorna true se un'opzione si può togglare
  canToggleOpzione: func,
  // Permette di renderizzare l'input di ogni opzione in modo custom
  renderOptionInput: func,

  /*************************************************/

  // Sostituisce la funzione di default per filtrare le opzioni (usata solo se ci sono le options)
  filterOptions: func, // Riceve (options, valoriFormRicerca, getOptionSearchString)
  // Sostituisce la barra di ricerca di default (di solito da usare insieme a filterOptions)
  renderFormPerFiltrare: func, // Riceve { onSubmit, inAttesa }
  /*
    Esempio props da passare per form custom:
    renderFormPerFiltrare={({ onSubmit }) => (
      <BaseCheckbox
        label='Solo agenti bastardi'
        onChange={e => onSubmit({ soloBastardo: e.target.value })}
        nonUsareHookForm
      />
    )}
    filterOptions={(options, { soloBastardo }) => 
      options.filter(({ cognome }) => soloBastardo ? cognome === 'bastardo' : true)
    }
  */

  dimensioneBloccoElementi: number,
  titoloModal: string,
  chipIcon: node,
  labelRicerca: string,
  propsBarraRicerca: object,
  testoBottoneModifica: string,
  propsBottoneModifica: object,
  propsModal: object,
  soloContenutoModal: bool,
  nascondiRiassuntoOpzioniSelezionate: bool,
  boxClickEnabled: bool,
  altezzaBoxOpzioni: number,
  ...useInputGenerico.propTypes
}

BaseSelectModal.campoConOptions = true

function filterOptionsDefault(options, { testoRicerca = '' }, getOptionSearchString) {
  if (!getOptionSearchString) {
    console.error('BaseSelectModal: è obbligatorio passare getOptionSearchString se si vuole usare la funzione filterOptions di default.')
    return
  }

  return options.filter(opzione => {
    if (testoRicerca === '') return true
    const searchString = getOptionSearchString(opzione)
    return contieneTutteLeParole(searchString, testoRicerca)
  })
}

export default function BaseSelectModal(props) {
  const {
    sceltaMultipla,
    label,

    options,
    useFetchOptions = () => ({}),

    getOptionKey = value => value.uuid,
    renderOption,
    getOptionLabel,
    getOptionSearchString,
    canToggleOpzione,
    renderOptionInput,

    filterOptions = filterOptionsDefault,
    renderFormPerFiltrare,
    boxClickEnabled = true,

    dimensioneBloccoElementi = 15,
    titoloModal,
    chipIcon,
    labelRicerca,
    propsBarraRicerca,
    testoBottoneModifica,
    propsBottoneModifica,
    propsModal,
    soloContenutoModal,
    nascondiRiassuntoOpzioniSelezionate,
    altezzaBoxOpzioni = 700,
    contenutoVicinoForm,
    ...restProps
  } = props

  const { modalRef, openModal, closeModal } = useModal()

  /********** Input generico **********/

  const {
    disabled,
    hidden,
    ...inputGenericoProps
  } = useInputGenerico({
    componenteControllato: true,
    underlyingComponent: 'modal',
    ...restProps
  })

  const {
    value,
    onBlur,
    onChange
  } = useControllerSeServe(inputGenericoProps)

  /************************************/

  /******* Logica opzioni selezionate *******/

  let opzioniSelezionate = value ?? []
  if (!sceltaMultipla) opzioniSelezionate = value ? [value] : []

  function isOpzioneSelezionata(opzioneDaControllare) {
    return opzioniSelezionate.some(o => getOptionKey(o) === getOptionKey(opzioneDaControllare))
  }

  function isNessunaOpzioneSelezionata() {
    return opzioniSelezionate.length === 0
  }

  function toggleOpzione(opzioneDaTogglare) {
    if (disabled) return
    if (canToggleOpzione && !canToggleOpzione(opzioneDaTogglare)) return

    if (!sceltaMultipla) {
      const nuovaOpzioneSelezionata = isOpzioneSelezionata(opzioneDaTogglare)
        ? null
        : opzioneDaTogglare

      onChange(nuovaOpzioneSelezionata)
      if (soloContenutoModal) onBlur()
      else if (nuovaOpzioneSelezionata !== null) setTimeout(() => closeModal(), 250)
      return
    }

    const nuoveOpzioniSelezionate = isOpzioneSelezionata(opzioneDaTogglare)
      ? opzioniSelezionate.filter(o => getOptionKey(o) !== getOptionKey(opzioneDaTogglare))
      : [...opzioniSelezionate, opzioneDaTogglare]

    onChange(nuoveOpzioniSelezionate)
    if (soloContenutoModal) onBlur()
  }

  /******************************************/

  // Questo stato si trova qui perché viene modificato dalla
  // FormPerFiltrare e usato dalla ListaOpzioniFiltrate
  const [opzioniFiltrate, setOpzioniFiltrate] = useStateWithLabel([], 'opzioniFiltrate')

  const formPerFiltrare = (
    <FormPerFiltrare
      options={options}
      useFetchOptions={useFetchOptions}
      getOptionSearchString={getOptionSearchString}
      setOpzioniFiltrate={setOpzioniFiltrate}
      filterOptions={filterOptions}
      renderFormPerFiltrare={renderFormPerFiltrare}
      labelRicerca={labelRicerca}
      propsBarraRicerca={propsBarraRicerca}
    />
  )

  const contenutoModal = (
    <BaseGridLayout vertical hidden={hidden} spacing={1}>
      {!nascondiRiassuntoOpzioniSelezionate &&
        <RiassuntoOpzioniSelezionate
          opzioniSelezionate={opzioniSelezionate}
          sceltaMultipla={sceltaMultipla}
          chipIcon={chipIcon}
          isNessunaOpzioneSelezionata={isNessunaOpzioneSelezionata}
          toggleOpzione={toggleOpzione}
          getOptionKey={getOptionKey}
          getOptionLabel={getOptionLabel}
          disabled={disabled}
        />
      }

      {contenutoVicinoForm ? (
        <Grid container alignItems='center'>
          <Grid item xs={9}>
            {formPerFiltrare}
          </Grid>
          <Grid item xs={3}>
            {contenutoVicinoForm}
          </Grid>
        </Grid>
      ) : (
        formPerFiltrare
      )}


      <ListaOpzioniFiltrate
        opzioniFiltrate={opzioniFiltrate}
        sceltaMultipla={sceltaMultipla}
        dimensioneBloccoElementi={dimensioneBloccoElementi}
        isOpzioneSelezionata={isOpzioneSelezionata}
        toggleOpzione={toggleOpzione}
        getOptionKey={getOptionKey}
        renderOptionInput={renderOptionInput}
        renderOption={renderOption}
        disabled={disabled}
        altezzaBoxOpzioni={altezzaBoxOpzioni}
        boxClickEnabled={boxClickEnabled}
      />
    </BaseGridLayout>
  )

  if (soloContenutoModal) return contenutoModal

  return (
    <div
      style={{
        ...(hidden && { display: 'none' }),
        ...(disabled && { cursor: 'not-allowed' })
      }}
    >
      <BaseButtons.Modifica
        onClick={openModal}
        noMarginY
        testo={testoBottoneModifica || label}
        disabled={disabled}
        {...propsBottoneModifica}
      />

      <BaseModal
        ref={modalRef}
        titolo={titoloModal || label}
        onClose={onBlur}
        {...propsModal}
      >
        {contenutoModal}
      </BaseModal>
    </div>
  )
}



function RiassuntoOpzioniSelezionate(props) {
  const {
    opzioniSelezionate,
    sceltaMultipla,
    chipIcon,
    isNessunaOpzioneSelezionata,
    toggleOpzione,
    getOptionKey,
    getOptionLabel,
    disabled
  } = props

  return (
    <BaseGridLayout justifyCenter noWrap>
      <BaseGrassetto>
        {sceltaMultipla ? 'Selezionati' : 'Selezionato'}:
      </BaseGrassetto>

      {isNessunaOpzioneSelezionata() ? (
        <BaseTesto sx={{ fontStyle: 'italic' }}>
          Nessun elemento
        </BaseTesto>
      ) : (
        <BaseGridLayout spacing={1}>
          {opzioniSelezionate.map(opzione =>
            <Chip
              label={getOptionLabel(opzione)}
              icon={chipIcon}
              {...(!disabled && {
                onDelete: () => toggleOpzione(opzione),
                deleteIcon: <ICONE.ELIMINA />
              })}
              key={getOptionKey(opzione)}
              sx={({ palette }) => ({
                '& .MuiChip-deleteIcon': {
                  color: palette.secondary.main,
                  '&:hover': { color: palette.secondary.main }
                }
              })}
            />
          )}
        </BaseGridLayout>
      )}
    </BaseGridLayout>
  )
}



function FormPerFiltrare(props) {
  const {
    options,
    useFetchOptions,
    getOptionSearchString,
    setOpzioniFiltrate,
    filterOptions,
    renderFormPerFiltrare,
    labelRicerca,
    propsBarraRicerca
  } = props

  const [valoriFormRicerca, setValoriFormRicerca] = useStateWithLabel({}, 'valoriFormRicerca')

  const {
    fetchOptions,
    ScatolaMessaggi,
    inAttesa
  } = useFetchOptions()

  const isFirstRender = useIsFirstRender()

  useEffect(() => {
    if (options) {
      setOpzioniFiltrate(filterOptions(options, valoriFormRicerca, getOptionSearchString))
      return
    }

    async function eseguiFetch() {
      const { ok, options: optionsGiaFiltrate } = await fetchOptions(valoriFormRicerca)
      if (ok) setOpzioniFiltrate(optionsGiaFiltrate)
    }
    if (!isFirstRender) eseguiFetch()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, filterOptions, valoriFormRicerca])

  return (
    <>
      {ScatolaMessaggi}

      {renderFormPerFiltrare ? (
        renderFormPerFiltrare({
          onSubmit: setValoriFormRicerca,
          inAttesa
        })
      ) : (
        <BaseBarraRicercaConRitardo
          setTestoRicerca_FinitoDiScrivere={
            testoRicerca => setValoriFormRicerca({ testoRicerca })
          }
          larghezza='fullWidth'
          autoFocus
          nonUsareHookForm
          propsRitardo={{ millisecondsToBeStable: 500 }}
          inAttesa={inAttesa}
          togliPrimaParteMessaggioAiuto={!options}
          {...(labelRicerca && { label: `Ricerca (${labelRicerca})` })}
          {...propsBarraRicerca}
        />
      )}
    </>
  )
}



function ListaOpzioniFiltrate(props) {
  const {
    opzioniFiltrate,
    sceltaMultipla,
    dimensioneBloccoElementi,
    isOpzioneSelezionata,
    toggleOpzione,
    getOptionKey,
    renderOptionInput,
    renderOption,
    disabled,
    altezzaBoxOpzioni,
    boxClickEnabled
  } = props

  /* Salvo gli elementi DOM con useState anziché useRef */

  // Usando useRef, al primo render gli elementi sono null
  // e non avviene un re-render nel momento in cui i ref
  // vengono effettivamente attaccati agli elementi
  // Quindi si creava un bug per cui la lista con scrolling
  // infinito non funzionava correttamente al primo render

  const [
    elementoDOMcontenitore, setElementoDOMcontenitore
  ] = useStateWithLabel(null, 'elementoDOMcontenitore')

  const elementoDOMcontenitore_Ref = useCallback(
    elemento => setElementoDOMcontenitore(elemento),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const [
    elementoDOMdaScrollare, setElementoDOMdaScrollare
  ] = useStateWithLabel(null, 'elementoDOMdaScrollare')

  const elementoDOMdaScrollare_Ref = useCallback(
    elemento => setElementoDOMdaScrollare(elemento),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  /******************************************************/

  /****** Gestione lista con scrolling infinito ******/

  const {
    listaDaVisualizzare: opzioniDaVisualizzare,
    spinnerCaricamento
  } = useListaConScrollingInfinito({
    dimensioneBloccoElementi,
    lista: opzioniFiltrate,
    elementoDOMcontenitore,
    elementoDOMdaScrollare,
    offsetScroll: 50
  })

  // Resetta lo scroll quando cambiano le opzioni filtrate
  useEffect(() => {
    if (elementoDOMdaScrollare) elementoDOMdaScrollare.scrollTop = 0
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opzioniFiltrate])

  /***************************************************/

  /* Gestione opzione evidenziata con mouse o tastiera */

  const opzioneEvidenziataRef = useRef()

  const [
    indiceOpzioneEvidenziata, setIndiceOpzioneEvidenziata
  ] = useStateWithLabel(-1, 'indiceOpzioneEvidenziata')

  // Resetta l'opzione evidenziata quando cambiano le opzioni filtrate
  useEffect(
    () => setIndiceOpzioneEvidenziata(-1),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [opzioniFiltrate]
  )

  // Eventi da tastiera
  useEffect(() => {
    function keyboardListener(e) {
      switch (e.key) {
        case 'ArrowUp':
          setIndiceOpzioneEvidenziata(indiceCorrente =>
            Math.max(0, indiceCorrente - 1)
          )
          break
        case 'ArrowDown':
          setIndiceOpzioneEvidenziata(indiceCorrente =>
            Math.min(indiceCorrente + 1, opzioniDaVisualizzare.length - 1)
          )
          break
        case 'Enter': {
          const opzioneScelta = opzioniDaVisualizzare[indiceOpzioneEvidenziata]
          if (opzioneScelta) toggleOpzione(opzioneScelta)
          break
        }
      }
    }

    window.addEventListener('keydown', keyboardListener)
    return () => window.removeEventListener('keydown', keyboardListener)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indiceOpzioneEvidenziata, opzioniDaVisualizzare, toggleOpzione])

  // Fai in modo che l'immagine evidenziata sia sempre visibile nella lista
  // NON RIESCO A FARLO ANDARE IN TUTTI I CASI
  // const {
  //   ref: opzioneEvidenziataRef,
  //   inView: isOpzioneEvidenziataVisibile,
  //   entry: intersectionObserverEntry
  // } = useInView({
  //   threshold: 1,
  //   root: elementoDOMdaScrollare
  // })

  // useEffect(() => {
  //   if (!isOpzioneEvidenziataVisibile && intersectionObserverEntry) {
  //     const {
  //       target: opzioneEvidenziata,
  //       intersectionRect: { height: altezzaVisibile }
  //     } = intersectionObserverEntry
  //     opzioneEvidenziata.scrollIntoView()
  //     // const altezzaTotale = opzioneEvidenziata.getBoundingClientRect().height
  //     // elementoDOMdaScrollare.scrollBy({
  //     //   top: altezzaTotale - altezzaVisibile,
  //     //   behavior: 'instant'
  //     // })
  //   }
  // }, [isOpzioneEvidenziataVisibile])

  /*****************************************************/

  const Input = sceltaMultipla ? Checkbox : Radio

  return (
    <Box
      sx={{ height: altezzaBoxOpzioni, overflowY: 'auto' }}
      ref={elementoDOMdaScrollare_Ref}
    >
      {opzioniDaVisualizzare.length === 0 ? (
        <BaseTesto sx={{ fontStyle: 'italic', textAlign: 'center' }}>
          Nessun elemento trovato relativo alla ricerca
        </BaseTesto>
      ) : (
        <div ref={elementoDOMcontenitore_Ref}>
          {opzioniDaVisualizzare.map((opzione, indice) => {
            const InputProps = {
              checked: isOpzioneSelezionata(opzione),
              disabled: disabled || opzione?.disabled,
              onClick: () => !boxClickEnabled && !(disabled || opzione?.disabled) && toggleOpzione(opzione),
              sx: {
                mr: 2,
                ...((disabled || opzione?.disabled) && { cursor: 'not-allowed' })
              }
            }
            return (
              <Fragment key={getOptionKey(opzione)}>
                <Box
                  onClick={() => boxClickEnabled && !(disabled || opzione?.disabled) && toggleOpzione(opzione)}
                  onMouseEnter={() => setIndiceOpzioneEvidenziata(indice)}
                  onMouseLeave={() => setIndiceOpzioneEvidenziata(-1)}
                  ref={(indice === indiceOpzioneEvidenziata) ? opzioneEvidenziataRef : null}
                  sx={{
                    textAlign: 'left',
                    cursor: 'pointer',
                    ...(indice === indiceOpzioneEvidenziata && {
                      bgcolor: 'grey.200',
                      boxShadow: 2
                    }),
                    ...((disabled || opzione?.disabled) && { cursor: 'not-allowed' })
                  }}
                >
                  <BaseGridLayout justifySpaceBetween alignCenter noWrap spacing={0} >
                    {renderOption(opzione, Input, InputProps)}
                    {renderOptionInput
                      ? renderOptionInput({ opzione, Input, InputProps })
                      : <Input {...InputProps} />
                    }
                  </BaseGridLayout>
                </Box>

                <BaseDivider />
              </Fragment>
            )
          })}

          {spinnerCaricamento}
        </div>
      )}
    </Box>
  )
}