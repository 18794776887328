import { BaseListaCoppieChiaveValore } from 'text'
import { formattaData } from 'utils'

export default function DatiGenerali(props) {
  const {
    targa,
    marca,
    modello,
    tipoVeicolo,
    dataImmatricolazione,
    nazione,
    telaio
  } = props

  return (
    <BaseListaCoppieChiaveValore
      titolo='Dati veicolo'
      propsTitolo={{ colorato: true }}
      coppie={{
        'Targa': targa,
        'Marca': marca,
        'Modello': modello,
        'Tipo veicolo': tipoVeicolo,
        'Data di immatricolazione': formattaData(dataImmatricolazione),
        'Nazione': nazione,
        'Telaio': telaio
      }}
    />
  )
}