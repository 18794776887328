import { forwardRef } from 'react'
import { bool, string, elementType, shape, oneOfType, node } from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
import { Link } from '@mui/material'
import { sx } from 'utils'

/**
 * @typedef {import("../common/navigation/BaseLink").BaseLinkProps} BaseLinkProps 
 * @typedef {import("../common/navigation/BaseLink").BaseLink} BaseLink 
 */

/**
 * @param {BaseLinkProps} props
 * @type {BaseLink}
 */
export const BaseLink = forwardRef(function BaseLink(props, ref) {
  const {
    children,
    disabled,
    to,
    ...linkProps
  } = props

  const toDefinitivo = (to && typeof to === 'object')
    ? to.pathAssoluto
    : to

  return (
    <Link
      to={toDefinitivo}
      component={RouterLink}
      variant='body1'
      ref={ref}
      {...(disabled && { onClick: e => e.preventDefault() })}
      {...linkProps}
      {...sx(linkProps, disabled && { cursor: 'auto' })}
    >
      {children}
    </Link>
  )
})

BaseLink.propTypes = {
  children: node.isRequired,
  disabled: bool,
  // For router links, 'to' and other router link props will work
  to: oneOfType([
    string,
    shape({ pathAssoluto: string.isRequired })
  ]),
  // Pass a string html element like 'a' or 'span' to have a non-router link
  // or if the router link already wraps this link in some way
  component: oneOfType([elementType, string])
}