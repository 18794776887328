import { string } from 'prop-types'
import { BaseScatola } from 'layout'
import { BaseLink } from 'navigation'
import { BaseTitolo } from 'text'

BasePaginaErrore.propTypes = {
  titolo: string.isRequired,
  sottotitolo: string,
  pathLink_PerTornareIndietro: string,
  labelLink_PerTornareIndietro: string
}

export default function BasePaginaErrore(props) {
  const {
    titolo,
    sottotitolo,
    pathLink_PerTornareIndietro = '/',
    labelLink_PerTornareIndietro = 'Torna alla Home'
  } = props

  return (
    <BaseScatola>
      <BaseTitolo grande>{titolo}</BaseTitolo>

      <BaseTitolo>{sottotitolo}</BaseTitolo>
      
      <BaseLink to={pathLink_PerTornareIndietro} variant='h6'>
        {labelLink_PerTornareIndietro}
      </BaseLink>
    </BaseScatola>
  )
}
