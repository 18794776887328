import { object } from 'prop-types'
import { FormPerUploadFile } from 'files'
import { ICONE } from 'icons'
import { arricchisciPropOggetto, GRUPPI_MIME_TYPES } from 'utils'
import useRichiestaServer from '../../network/RichiestaServerHook'
import { ENTITA_BASE } from '../EntitaBase'
import BottoneCreaEntita from '../entitaGenerica/components/BottoneCreaEntita'
import { PROP_TYPES_WRAPPER_FORM_MODIFICA } from '../entitaGenerica/components/FormModificaEntita'
import SmartPanel from '../entitaGenerica/components/SmartPanel'
import ListaImmagini from './ListaImmagini'

ListaImmaginiModifica.propTypes = {
  ...PROP_TYPES_WRAPPER_FORM_MODIFICA,
  propsFormUpload: object
}

ListaImmaginiModifica.defaultConfig = {
  path: 'immagini',
  nomeEntita: ENTITA_BASE.ALLEGATO,
  url: 'immagini',
  label: 'Immagini'
}

export default function ListaImmaginiModifica(props) {
  const {
    propsEntita: { valoriInput, pathEntita, nomeEntita },
    propsInterne_PannelloModificaEntita: {
      gestioneModifica: { modificaEntita, eliminaEntita },
      gestionePermessi: { readOnly },
      infoEntitaPrincipale
    },
    propsFormUpload,
    ...restProps
  } = props

  function modificaImmagine(immagineModificata) {
    const { src, ...restImmagineModificata } = immagineModificata
    modificaEntita(
      nomeEntita,
      pathEntita.addUuid(immagineModificata.uuid),
      restImmagineModificata,
      { fondiConValoreAttuale: false }
    )
  }

  function eliminaImmagine(immagineEliminata) {
    eliminaEntita(
      nomeEntita,
      pathEntita.addUuid(immagineEliminata.uuid),
    )
  }

  return (
    <SmartPanel conScatola {...props}>
      <ListaImmagini
        immagini={valoriInput}
        renderBottoneCaricaImmagine={({ onImmagineCaricata }) =>
          <BottoneCreaEntita
            nomeEntita={nomeEntita}
            path={pathEntita}
            testo='Carica immagine'
            iconaInizio={ICONE.CARICA}
            renderModalCreazione={({ onSubmit }) => (
              <FormPerUploadFile
                defaultValues={null}
                salvaDati={immagine => onSubmit(immagine)}
                useGestioneFile={useRichiestaServer}
                readOnly={readOnly}
                {...propsFormUpload}
                {...arricchisciPropOggetto(propsFormUpload, 'propsFileInput', {
                  dimensioneMaxInMegabyte: 10,
                  tipiFileAccettati: [GRUPPI_MIME_TYPES.IMMAGINI],
                })}
              />
            )}
            propsModalCreazione={{ disabilitaChiusuraCliccandoFuori: true }}
            opzioniCreazione={{ onCreazioneCompletata: onImmagineCaricata }}
          />
        }
        onImmagineModificata={modificaImmagine}
        onImmagineEliminata={eliminaImmagine}
        readOnly={readOnly}
        infoEntitaPrincipale={infoEntitaPrincipale}
        {...restProps}
      />
    </SmartPanel>
  )
}