import { createContext, useContext, useMemo } from 'react'
import { node, string, elementType, bool, arrayOf, shape, object, objectOf } from 'prop-types'
import { ICONE } from 'icons'
import { setupRoutes, mergeFunzioniRicerca_DiPiuRoutes_DopoSetup } from 'utils'
import Home from '../layoutGenerale/Home'
import { MODULO_SHAPE } from '../userManagement/userProvider/permessiUser/utilPermessiUser'

const routeShape = shape({
  pathRelativo: string.isRequired,
  chiave: string.isRequired,
  label: string.isRequired,
  Content: elementType,
  Icon: elementType,
  nascostaNeiMenu: bool,
  moduli: arrayOf(MODULO_SHAPE), // Moduli per permessi user
  subRoutes: arrayOf(object), // routeShape ricorsivo
})

RoutesProvider.propTypes = {
  children: node.isRequired,

  // Mappa che definisce le chiavi delle rotte
  CHIAVI_ROUTES: objectOf(string).isRequired,

  // Prop per personalizzare la rotta home
  // Di solito usato solo per cambiare l'icona
  propsRouteHome: object,

  // Rotte disponibili se l'utente non è loggato
  ROUTES_NON_LOGGATO: arrayOf(routeShape).isRequired,

  // Rotte disponibili se l'utente è loggato senza comando
  // Se l'applicazione non ha distigue tra loggato con e senza
  // comando, queste saranno le uniche rotte usate dopo il login
  ROUTES_SENZA_COMANDO: arrayOf(routeShape).isRequired,

  // Rotte disponibili solo se l'utente è loggato con comando
  // Passare questa prop abilita tutta la logica di gestione del comando 
  ROUTES_CON_COMANDO: arrayOf(routeShape),

  // Pagina home mostrata se l'utente è loggato senza comando
  // Se l'applicazione non ha distigue tra loggato con e senza
  // comando, questa sarà l'unica pagina home usata dopo il login
  homeSenzaComando: node.isRequired,

  // Pagina home mostrata se l'utente è loggato con comando
  homeConComando: node,

  // Componente custom per il login
  // Deve in qualche modo chiamare handleLogin() di useUser()
  componenteLogin: node,

  // URL su cui redirigere l'utente per il login tramite SSO
  URL_SSO_LOGIN: string,

  // URL su cui redirigere l'utente quando preme il bottone Home (nuvoletta)
  URL_SSO: string,

  // URL base usato per i link che portano su pagine di Connet
  CONNET_BASE_URL: string,

  // Nasconde il breadcrumb
  noBreadcrumb: bool
}

const ROUTE_HOME = {
  pathRelativo: '/',
  pathAssoluto: '/',
  label: 'Home',
  Content: Home,
  Icon: ICONE.LOGO_INCLOUD,
  noBreadcrumb: true,
  // La route home viene gestita in modo particolare, 
  // quindi la nascondo nei menu generati automaticamente
  nascostaNeiMenu: true
}

/**
 * @type {import('../../common/incloudApps/navigation/RoutesProvider').RoutesContext}
 */
const RoutesContext = createContext()

function RoutesProvider(props) {
  const {
    children,
    CHIAVI_ROUTES,
    propsRouteHome = {},
    ROUTES_NON_LOGGATO,
    ROUTES_SENZA_COMANDO,
    ROUTES_CON_COMANDO,
    homeSenzaComando,
    homeConComando,
    componenteLogin,
    URL_SSO_LOGIN,
    URL_SSO,
    CONNET_BASE_URL,
    noBreadcrumb,
    ...restProps
  } = props

  const {
    routeHome,
    routesNonLoggato,
    routesSenzaComando,
    routesConComando,
    findRouteWithPath,
    isValidPath,
    findRouteWithKey,
    getPath
  } = useMemo(() => {
    const routeHomeDefinitiva = {
      ...ROUTE_HOME,
      chiave: CHIAVI_ROUTES.HOME || 'home',
      ...propsRouteHome
    }

    // Aggiungo la rotta home in fondo agli altri gruppi di rotte, in modo 
    // che la rotta home agisca come rotta di fallback in certe situazioni
    // Utile per avere una redirezione trasparente attraverso il login e la scelta comando
    const routesNonLoggatoDefinitive = setupRoutes([...ROUTES_NON_LOGGATO, routeHomeDefinitiva])
    const routesSenzaComandoDefinitive = setupRoutes([...ROUTES_SENZA_COMANDO, routeHomeDefinitiva])

    // L'ordine è importante per la funzione findRouteWithPath
    let tutteRoutes = [routesSenzaComandoDefinitive, routesNonLoggatoDefinitive]

    let routesConComandoDefinitive
    if (ROUTES_CON_COMANDO) {
      routesConComandoDefinitive = setupRoutes([...ROUTES_CON_COMANDO, routeHomeDefinitiva])
      tutteRoutes = [routesConComandoDefinitive, ...tutteRoutes]
    }

    return {
      routeHome: routeHomeDefinitiva,
      routesNonLoggato: routesNonLoggatoDefinitive,
      routesSenzaComando: routesSenzaComandoDefinitive,
      routesConComando: routesConComandoDefinitive,
      ...mergeFunzioniRicerca_DiPiuRoutes_DopoSetup(tutteRoutes)
    }
  }, [CHIAVI_ROUTES.HOME, ROUTES_CON_COMANDO, ROUTES_NON_LOGGATO, ROUTES_SENZA_COMANDO, propsRouteHome])

  const routesContextValue = {
    CHIAVI_ROUTES,
    routeHome,
    routesNonLoggato,
    routesSenzaComando,
    routesConComando,
    findRouteWithPath,
    isValidPath,
    findRouteWithKey,
    getPath,
    homeSenzaComando,
    homeConComando,
    componenteLogin,
    URL_SSO_LOGIN,
    URL_SSO,
    CONNET_BASE_URL,
    noBreadcrumb,
    ...restProps
  }

  return (
    <RoutesContext.Provider value={routesContextValue}>
      {children}
    </RoutesContext.Provider>
  )
}

/**
 * 
 * @returns {import('common').RoutesContext}
 */
function useRoutes() {
  return useContext(RoutesContext)
}

export {
  RoutesProvider,
  useRoutes
}