import { useRef, useEffect } from 'react'
import useIsFirstRender from './IsFirstRender'
import useStateWithLabel from './StateWithLabel'

// A fronte di un valore che può cambiare molto in fretta,
// lo restituisce solo quando non è cambiato per tot millisecondi.
// Restituisce anche un booleano che dice se il valore sta cambiando
export default function useValueWhenStable(props) {
  const {
    value,
    millisecondsToBeStable = 1000,
    isValueValid = () => true,
    skipValue = '',
  } = props

  const [stableValue, setStableValue] = useStateWithLabel(value, 'stableValue')
  const [valueIsChanging, setValueIsChanging] = useStateWithLabel(false, 'valueIsChanging')
  const valueIsChangingTimer_Ref = useRef(null)
  
  const isFirstRender = useIsFirstRender()

  useEffect(() => {
    if (isFirstRender) return
    
    setValueIsChanging(true)
    const timerId = valueIsChangingTimer_Ref.current
    if (timerId !== null) clearTimeout(timerId)

    valueIsChangingTimer_Ref.current = setTimeout(() => {
      valueIsChangingTimer_Ref.current = null
      if (isValueValid(value)) setStableValue(value)
      setValueIsChanging(false)
    }, skipValue === value ? 0 : millisecondsToBeStable)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, millisecondsToBeStable])

  return [stableValue, valueIsChanging]
}