import { node } from 'prop-types'
import { Box } from '@mui/material'
import { sx } from 'utils'

BaseContenitoreContrasto.propTypes = {
  children: node.isRequired
}

export default function BaseContenitoreContrasto(props) {
  const { children, ...restProps } = props

  return (
    <Box
      {...restProps}
      {...sx(restProps, {
        bgcolor: 'grey.200',
        p: 2,
        borderRadius: 1
      })}
    >
      {children}
    </Box>
  )
}
