import { set, isSameDay, parseISO, startOfDay, subDays, subWeeks, subMonths, subYears, addDays, addWeeks, addMonths, addYears, differenceInSeconds, eachDayOfInterval, endOfDay, subHours, addHours, isValid } from 'date-fns'

const DATE_RAPIDE = {
  OGGI: 'oggi',

  IERI: 'ieri',
  UNA_SETTIMANA_FA: 'unaSettimanaFa',
  DUE_SETTIMANE_FA: 'dueSettimaneFa',
  UN_MESE_FA: 'unMeseFa',
  TRE_MESI_FA: 'treMesiFa',
  SEI_MESI_FA: 'seiMesiFa',
  UN_ANNO_FA: 'unAnnoFa',

  DOMANI: 'domani',
  FRA_UNA_SETTIMANA: 'fraUnaSettimana',
  FRA_DUE_SETTIMANE: 'fraDueSettimane',
  FRA_UN_MESE: 'fraUnMese',
  FRA_TRE_MESI: 'fraTreMesi',
  FRA_SEI_MESI: 'fraSeiMesi',
  FRA_UN_ANNO: 'fraUnAnno'
}

const OPZIONI_RAPIDE_DATE_PASSATE = [
  { label: 'Ieri', value: DATE_RAPIDE.IERI },
  { label: 'Una settimana fa', value: DATE_RAPIDE.UNA_SETTIMANA_FA },
  { label: 'Due settimane fa', value: DATE_RAPIDE.DUE_SETTIMANE_FA },
  { label: 'Un mese fa', value: DATE_RAPIDE.UN_MESE_FA },
  { label: 'Tre mesi fa', value: DATE_RAPIDE.TRE_MESI_FA },
  { label: 'Sei mesi fa', value: DATE_RAPIDE.SEI_MESI_FA },
  { label: 'Un anno fa', value: DATE_RAPIDE.UN_ANNO_FA }
]

const OPZIONI_RAPIDE_DATE_FUTURE = [
  { label: 'Domani', value: DATE_RAPIDE.DOMANI },
  { label: 'Fra una settimana', value: DATE_RAPIDE.FRA_UNA_SETTIMANA },
  { label: 'Fra due settimane', value: DATE_RAPIDE.FRA_DUE_SETTIMANE },
  { label: 'Fra un mese', value: DATE_RAPIDE.FRA_UN_MESE },
  { label: 'Fra tre mesi', value: DATE_RAPIDE.FRA_TRE_MESI },
  { label: 'Fra sei mesi', value: DATE_RAPIDE.FRA_SEI_MESI },
  { label: 'Fra un anno', value: DATE_RAPIDE.FRA_UN_ANNO }
]

function oggiOraAttuale() {
  return new Date()
}

function oggiInizioGiornata() {
  return startOfDay(new Date())
}

function oggiFineGiornata() {
  return endOfDay(new Date())
}

function getDataRapida(chiave, opzioni = {}) {
  const {
    oraAttuale = false,
    oraFineGiornata = false,
    numeroOreIndietro = 0,
    numeroOreAvanti = 0
  } = opzioni

  let oggi
  if (oraAttuale) oggi = oggiOraAttuale()
  else if (oraFineGiornata) oggi = oggiFineGiornata()
  else oggi = oggiInizioGiornata() // Caso predefinito

  if (numeroOreIndietro > 0) oggi = subHours(oggi, numeroOreIndietro)
  else if (numeroOreAvanti > 0) oggi = addHours(oggi, numeroOreAvanti)

  switch (chiave) {
    case DATE_RAPIDE.OGGI: return oggi

    case DATE_RAPIDE.IERI: return subDays(oggi, 1)
    case DATE_RAPIDE.UNA_SETTIMANA_FA: return subWeeks(oggi, 1)
    case DATE_RAPIDE.DUE_SETTIMANE_FA: return subWeeks(oggi, 2)
    case DATE_RAPIDE.UN_MESE_FA: return subMonths(oggi, 1)
    case DATE_RAPIDE.TRE_MESI_FA: return subMonths(oggi, 3)
    case DATE_RAPIDE.SEI_MESI_FA: return subMonths(oggi, 6)
    case DATE_RAPIDE.UN_ANNO_FA: return subYears(oggi, 1)

    case DATE_RAPIDE.DOMANI: return addDays(oggi, 1)
    case DATE_RAPIDE.FRA_UNA_SETTIMANA: return addWeeks(oggi, 1)
    case DATE_RAPIDE.FRA_DUE_SETTIMANE: return addWeeks(oggi, 2)
    case DATE_RAPIDE.FRA_UN_MESE: return addMonths(oggi, 1)
    case DATE_RAPIDE.FRA_TRE_MESI: return addMonths(oggi, 3)
    case DATE_RAPIDE.FRA_SEI_MESI: return addMonths(oggi, 6)
    case DATE_RAPIDE.FRA_UN_ANNO: return addYears(oggi, 1)

    default: return null
  }
}

function generaOptionsAnniPassati(numeroAnni, escludiAnnoCorrente = false) {
  let options = []
  const annoCorrente = new Date().getFullYear()
  for (let i = annoCorrente; i > (annoCorrente - numeroAnni); i--) {
    options.push({ value: String(i), label: String(i) })
  }
  return escludiAnnoCorrente ? options.slice(1) : options
}

function calcolaDifferenzaInSecondi(dataPiuAvanti, dataPiuIndietro) {
  return differenceInSeconds(dataPiuAvanti, dataPiuIndietro)
}

function convertiDataSeServe(data) {
  return (data instanceof Date) ? data : parseISO(data)
}

function getTuttiGiorniTra(dataInizio, dataFine) {
  return eachDayOfInterval({
    start: dataInizio,
    end: dataFine
  })
}

function isDataValida(data) {
  return isValid(convertiDataSeServe(data))
}

function convertTimeStringToDate(timeString) {
  if (!/^\d\d:\d\d$/.test(timeString)) return null

  const [hoursString, minutesString] = timeString.split(':')
  const hours = parseInt(hoursString)
  const minutes = parseInt(minutesString)

  if (hours < 0 || hours > 23 || minutes < 0 || minutes > 59) return null
  return set(new Date(), { hours, minutes })
}

function convertDateToTimeString(date) {
  if (!isValid(date)) return ''
  return date.toLocaleTimeString('it-IT', { hour: '2-digit', minute: '2-digit' })
}

function generaOptionsMesi() {
  return [
    { value: '1', label: 'Gennaio' },
    { value: '2', label: 'Febbraio' },
    { value: '3', label: 'Marzo' },
    { value: '4', label: 'Aprile' },
    { value: '5', label: 'Maggio' },
    { value: '6', label: 'Giugno' },
    { value: '7', label: 'Luglio' },
    { value: '8', label: 'Agosto' },
    { value: '9', label: 'Settembre' },
    { value: '10', label: 'Ottobre' },
    { value: '11', label: 'Novembre' },
    { value: '12', label: 'Dicembre' }
  ]
}

function annoCorrente() {
  return new Date().getFullYear()
}

function meseCorrente() {
  return new Date().getMonth() + 1
}

function meseScorso() {
  const mese = meseCorrente()
  return mese === 1 ? 12 : mese - 1
}

function annoDelMeseScorso() {
  const anno = annoCorrente()
  return meseScorso() === 12 ? anno - 1 : anno
}

export {
  DATE_RAPIDE,
  OPZIONI_RAPIDE_DATE_PASSATE,
  OPZIONI_RAPIDE_DATE_FUTURE,
  oggiOraAttuale,
  oggiInizioGiornata,
  oggiFineGiornata,
  getDataRapida,
  generaOptionsAnniPassati,
  calcolaDifferenzaInSecondi,
  convertiDataSeServe,
  getTuttiGiorniTra,
  isSameDay,
  isDataValida,
  convertTimeStringToDate,
  convertDateToTimeString,
  generaOptionsMesi,
  annoCorrente,
  meseCorrente,
  meseScorso,
  annoDelMeseScorso,
}