import { ICONE } from 'icons'
import { BaseHeaderConIcona } from 'layout'
import { creaLabelGruppo } from './utilPermessiEntita'

export default function HeaderGruppo(props) {
  const { gruppo, ignoraLivelloProfondita, ...restProps } = props
  const {
    riferimentoParent,
    livelloProfondita = 0
  } = gruppo

  return (
    <BaseHeaderConIcona
      icona={<ICONE.GRUPPO />}
      titolo={creaLabelGruppo(gruppo)}
      sottotitolo={riferimentoParent &&
        `Sottogruppo di: ${creaLabelGruppo(riferimentoParent)}`
      }
      {...restProps}
      sx={!ignoraLivelloProfondita && { ml: livelloProfondita * 2 }}
    />
  )
}
