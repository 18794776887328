import { useEffect, useMemo } from 'react'
import { useStateWithLabel, saveInLocalStorage, getFromLocalStorage, haAlmenoUnaChiave } from 'utils'
import { useContextGenerico } from '../../../util/ContextGenericoProvider'

const PERMESSI_NON_PRESENTI = {}

export default function useStorePermessiUser(props) {
  const {
    funzioneMappaturaPermessiUser = permessi => permessi
  } = props

  const {
    CHIAVI_STORAGE: { PERMESSI_SENZA_COMANDO }
  } = useContextGenerico()

  /* Permessi senza comando (salvati nel local storage) */

  const [permessiUserSenzaComando, setPermessiUserSenzaComando] = useStateWithLabel(
    () =>
      getFromLocalStorage(PERMESSI_SENZA_COMANDO, { mask: true })
      || PERMESSI_NON_PRESENTI,
    'permessiUserSenzaComando'
  )

  useEffect(
    () => saveInLocalStorage(PERMESSI_SENZA_COMANDO, permessiUserSenzaComando, { mask: true }),
    [permessiUserSenzaComando, PERMESSI_SENZA_COMANDO]
  )

  /******************************************************/

  /* Permessi con comando (salvati solo in memoria) */

  const [permessiUserConComando, setPermessiUserConComando] = useStateWithLabel(
    PERMESSI_NON_PRESENTI,
    'permessiUserConComando'
  )

  function sonoPresenti_PermessiUserConComando(comandoDaControllare) {
    const { permessi, comando } = permessiUserConComando
    if (comando !== comandoDaControllare) return false
    return haAlmenoUnaChiave(permessi)
  }

  /******************************************************/

  /* Permessi completi (merge tra quelli senza comando e quelli con comando) */

  const permessiUserCompleti = useMemo(() => {
    return {
      ...permessiUserSenzaComando.permessi,
      ...permessiUserConComando.permessi
    }
  }, [permessiUserSenzaComando, permessiUserConComando])

  /***************************************************************************/

  /********** Gestione staff e admin **********/

  function isUserStaff() {
    return Boolean(permessiUserCompleti?.is_staff)
  }

  const cmdsAdmin = permessiUserCompleti?.comandiAdmin ?? []

  function findAdminCmd(tag) {
    return cmdsAdmin.filter(cmd => cmd.tag === tag)
  }

  function isAdminAlmenoUnComando() {
    return isUserStaff() || cmdsAdmin.length > 0
  }

  function getAdminCmd(tag) { 
    const cmd = findAdminCmd(tag)  
    return cmd.length === 1 ? cmd[0] : null
  }

  function canAdmin(tag) {
    return isUserStaff() || findAdminCmd(tag).length
  }

  /********************************************/

  /**** Funzioni per impostare i permessi ****/

  // nuoviPermessiUser deve essere un oggetto con le chiavi dei permessi
  // già costruite correttamente. Può contenere anche un booleano is_staff
  function impostaPermessiUser(nuoviPermessiUser, comando) {
    const permessiTutti = funzioneMappaturaPermessiUser(nuoviPermessiUser, comando)

    if (comando) {
      setPermessiUserConComando({ permessi: permessiTutti, comando })
    } else {
      setPermessiUserSenzaComando({ permessi: permessiTutti })
    }
  }

  function impostaPermessiUserSenzaComando(nuoviPermessiUser) {
    impostaPermessiUser(nuoviPermessiUser)
  }

  function impostaPermessiUserConComando(nuoviPermessiUser, comando) {
    impostaPermessiUser(nuoviPermessiUser, comando)
  }

  function eliminaPermessiUserSenzaComando() {
    setPermessiUserSenzaComando(PERMESSI_NON_PRESENTI)
  }

  function eliminaPermessiUserConComando() {
    setPermessiUserConComando(PERMESSI_NON_PRESENTI)
  }

  function setIsUserStaff(isStaffDaImpostare) {
    setPermessiUserSenzaComando({
      permessi: {
        ...permessiUserSenzaComando.permessi,
        is_staff: isStaffDaImpostare
      }
    })
  }

  /*******************************************/

  return {
    permessiUserCompleti,
    sonoPresenti_PermessiUserConComando,
    findAdminCmd,
    isAdminAlmenoUnComando,
    getAdminCmd,
    canAdmin,
    cmdsAdmin,
    isUserStaff,
    impostaPermessiUserSenzaComando,
    impostaPermessiUserConComando,
    eliminaPermessiUserSenzaComando,
    eliminaPermessiUserConComando,
    setIsUserStaff
  }
}