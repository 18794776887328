import { useEffect, useState } from 'react'
import { string } from 'prop-types'
import { BaseContenutoConProgress, BaseFeedbackConIcona } from 'feedback'
import { BaseGridLayout, BaseScatola } from 'layout'
import { BaseGrassetto, BaseTesto, BaseTitolo } from 'text'
import { OPERAZIONI_BASE } from '../../network/OperazioniBase'
import useRichiestaServer from '../../network/RichiestaServerHook'
import { InserimentoNuovoNumero } from './ModificaManualeNumeroRegistro'

const ANNO_CORRENTE = new Date().getFullYear()

ImpostaContatoreNumeroRegistro.propTypes = {
  nomeEntita: string
}

export default function ImpostaContatoreNumeroRegistro(props) {
  const { nomeEntita } = props

  const [contatoreCorrente, setContatoreCorrente] = useState(null)

  const {
    ScatolaMessaggi,
    inviaOperazione,
    inAttesaDelServer
  } = useRichiestaServer()

  useEffect(() => {
    async function getContatoreCorrenteDalServer() {
      const { ok, data } = await inviaOperazione(OPERAZIONI_BASE.ENTITY_GET_CONTATORE_NUM, {
        entityName: nomeEntita,
        anno: ANNO_CORRENTE
      })
      if (ok) {
        setContatoreCorrente(data.contatore + 1)
      }
    }
    getContatoreCorrenteDalServer()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nomeEntita])

  const [contatoreSalvatoConSuccesso, setContatoreSalvatoConSuccesso] = useState(false)
  const [inAttesaSalvataggio, setInAttesaSalvataggio] = useState(false)

  async function setContatoreCorrenteSulServer(nuovoContatore) {
    const { ok, data } = await inviaOperazione(OPERAZIONI_BASE.ENTITY_SET_CONTATORE_NUM, {
      entityName: nomeEntita,
      anno: ANNO_CORRENTE,
      numero: nuovoContatore - 1
    }, {
      setInAttesa: setInAttesaSalvataggio
    })
    if (ok && data.contatoreAggiornato === nuovoContatore - 1) {
      setContatoreCorrente(nuovoContatore)
      setContatoreSalvatoConSuccesso(true)
      setTimeout(() => setContatoreSalvatoConSuccesso(false), 2000)
    }
  }

  return (
    <BaseScatola outlined>
      <BaseTitolo>Contatore numero di registro</BaseTitolo>

      {ScatolaMessaggi}

      <BaseContenutoConProgress inAttesa={inAttesaDelServer}>
        {contatoreCorrente &&
          <BaseGridLayout disposizioneRighe={[1, 2]} alignCenter>
            <BaseTesto>
              Prossimo numero che sarà assegnato:&nbsp;
              <BaseGrassetto>{contatoreCorrente}</BaseGrassetto>
            </BaseTesto>

            <InserimentoNuovoNumero
              numeroAttuale={contatoreCorrente}
              onSalva={setContatoreCorrenteSulServer}
              propsBottoneSalva={
                inAttesaSalvataggio && { inAttesa: true, disabled: true }
              }
            />
            <BaseFeedbackConIcona.TuttoOk
              messaggio='Salvato con successo'
              hidden={!contatoreSalvatoConSuccesso}
            />
          </BaseGridLayout>
        }
      </BaseContenutoConProgress>
    </BaseScatola>
  )
}