import { BaseCheckbox, BaseSelect } from 'inputs'
import { BaseGridLayout, BaseScatolaConTitolo } from 'layout'
import ElenchiManager, { ELENCHI } from '../../form/ElenchiManager'
import FormModificaEntita, { PROP_TYPES_WRAPPER_FORM_MODIFICA } from '../entitaGenerica/components/FormModificaEntita'
import ListaSubPanels from '../entitaGenerica/components/ListaSubPanels'

PasseggeroModifica.propTypes = PROP_TYPES_WRAPPER_FORM_MODIFICA

export default function PasseggeroModifica(props) {
  const {
    propsEntita,
    titolo,
    subPanels,
    nodoAlbero,
    propsInterne_PannelloModificaEntita: {
      gestioneModifica: { modificaEntita }
    }
  } = props

  function togliConducenteDaAltriPassegeri() {
    const nodiAlberoPasseggeri = nodoAlbero.riferimentoPadre.figli
    const nodo_AltroConducenteGiaPresente = nodiAlberoPasseggeri.find(nodo =>
      nodo !== nodoAlbero && nodo?.valoriInput?.conducente === true
    )
    if (!nodo_AltroConducenteGiaPresente) return

    const { nomeEntita, pathEntita } = nodo_AltroConducenteGiaPresente
    modificaEntita(nomeEntita, pathEntita, { conducente: false })
  }

  return (
    <>
      {titolo}

      <BaseGridLayout vertical spacing={3}>
        <ElenchiManager
          elenchi={[ELENCHI.TIPOLOGIA_TRAGITTO]}
          render={el =>
            <PasseggeroPosizione
              propsEntita={propsEntita}
              label='Posizione'
              optionsTipologiaTragitto={el(ELENCHI.TIPOLOGIA_TRAGITTO)}
              togliConducenteDaAltriPassegeri={togliConducenteDaAltriPassegeri}
            />
          }
        />

        <ListaSubPanels subPanels={subPanels} />
      </BaseGridLayout>
    </>
  )
}



const NOMI = {
  CASCO: 'casco',
  CINTURA: 'cintura',
  SISTEMI_RITENUTA: 'sistemiRitenuta',
  CONDUCENTE: 'conducente',
  POSIZIONE_PASSEGGERO: 'posizionePasseggero',
  TIPOLOGIA_TRAGITTO: 'tipologiaTragitto'
}

const OPTIONS_POSIZIONE_PASSEGGERO = [
  { label: 'Anteriore destro', value: 'ANTERIORE_DESTRO' },
  { label: 'Anteriore sinistro', value: 'ANTERIORE_SINISTRO' },
  { label: 'Anteriore centrale', value: 'ANTERIORE_CENTRALE' },
  { label: 'Posteriore destro', value: 'POSTERIORE_DESTRO' },
  { label: 'Posteriore sinistro', value: 'POSTERIORE_SINISTRO' },
  { label: 'Posteriore centrale', value: 'POSTERIORE_CENTRALE' },
  { label: 'Altro', value: 'ALTRO' }
]

function PasseggeroPosizione(props) {
  const {
    propsEntita,
    label,
    optionsTipologiaTragitto,
    togliConducenteDaAltriPassegeri
  } = props

  return (
    <BaseScatolaConTitolo titolo={label}>
      <FormModificaEntita {...propsEntita}>
        <BaseGridLayout alignCenter disposizioneRighe={[3, 3]}>
          <BaseCheckbox
            label='Conducente' name={NOMI.CONDUCENTE}
            onChange={isConducente => isConducente && togliConducenteDaAltriPassegeri()}
          />
          <BaseSelect
            label='Posizione passeggero' name={NOMI.POSIZIONE_PASSEGGERO}
            options={OPTIONS_POSIZIONE_PASSEGGERO}
          />
          <BaseSelect
            label='Tipologia di tragitto' name={NOMI.TIPOLOGIA_TRAGITTO}
            options={optionsTipologiaTragitto} pair
          />

          <BaseCheckbox
            label='Cintura' name={NOMI.CINTURA}
          />
          <BaseCheckbox
            label='Casco' name={NOMI.CASCO}
          />
          <BaseCheckbox
            label='Sistemi di ritenuta' name={NOMI.SISTEMI_RITENUTA}
          />
        </BaseGridLayout>
      </FormModificaEntita>
    </BaseScatolaConTitolo>
  )
}