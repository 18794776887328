import { useMemo } from 'react'
import { useCampiForm, BaseCheckbox, BaseDateInput, BaseRadioGroup, BaseSelect, BaseSelectMultipla, BaseTextInput } from 'inputs'
import { capitalizza, getPropertyWithPath, sx } from 'utils'
import { inputEntitaBase_SelectModal, inputEntitaBase_FormEntita } from '../../jconnetEntities/InputEntitaBase'
import { TYPES, trasformaValoreParamInStringa, isTypeWithEntity, isTypeList } from './TipiParams'

export default function useCampiParams(props) {
  const {
    params,
    prefissoNomi,
    calcolaOptions,
    parametriFissi,
    parametriDefaultValues,
    inputEntitaBase_SelectModal_Custom
  } = props

  /*************** Costruzione campi ***************/

  const {
    strutturaCampi,
    ...restCostruzione
  } = useMemo(
    () => costruisciCampiParams(params, {
      prefissoNomi,
      calcolaOptions,
      parametriFissi,
      parametriDefaultValues,
      inputEntitaBase_SelectModal_Custom
    }),
    [params, prefissoNomi, calcolaOptions, parametriFissi, parametriDefaultValues, inputEntitaBase_SelectModal_Custom]
  )

  const { campi, defaultValues } = useCampiForm(strutturaCampi)

  const defaultValues_ConNomiSenzaPrefisso = useMemo(() => {
    if (!prefissoNomi) return defaultValues
    return Object.entries(defaultValues).reduce(
      (defaultValuesAccumulatore, [nomeConPrefisso, valore]) => ({
        ...defaultValuesAccumulatore,
        // Il + 1 serve per togliere anche il . dopo il prefisso
        [nomeConPrefisso.slice(prefissoNomi.length + 1)]: valore
      }),
      {}
    )
  }, [defaultValues, prefissoNomi])

  /*************************************************/

  return {
    prefissoNomi,
    ciSonoParams: campi.length > 0,
    campiParams: campi,
    defaultValuesParams: defaultValues_ConNomiSenzaPrefisso,
    ...restCostruzione
  }
}



export const FORM_CONTROLS = {
  RADIO: 'radio',
  SELECT: 'select',
  SELECT_MULTI: 'selectmulti',
  SELECT_MODAL: 'selectmodal',
  TEXTAREA: 'textarea',
  FORM_ENTITA: 'formEntita'
}

/*
  Definizione dei params che arrivano dal server:
  {
    campi: [
      {
        "name": "protocollo",
        "type": "string",
        "label": "N° protocollo",
        ...
      },
      {
        "name": "dataDeposito",
        "type": "date",
        "label": "Data deposito",
        ...
      }
    ]
  }
*/
function costruisciCampiParams(params, opzioni = {}) {
  const {
    // Serve a react-hook-form per gestire i campi annidati in un oggetto
    prefissoNomi = '',
    // Funzione che risolve le options delle select tramite delle chiavi
    calcolaOptions = () => ({ options: [] }),
    // Valori di alcuni parametri fissati dal contesto esterno (saranno invisibili all'utente)
    parametriFissi = {},
    // Valori di default di alcuni parametri, passati dall'esterno
    parametriDefaultValues = {},
    inputEntitaBase_SelectModal_Custom
  } = opzioni

  // Lista di definizioni elenchi necessari
  let elenchi = []
  // Oggetto che mappa ogni nome (CON prefisso) alla sua definizione originale
  let mappaDefinizioniParamsOriginali = {}
  // Insieme senza duplicati dei nomi (SENZA prefisso) dei campi che devono
  // essere controllati per determinare la visibilità di altri campi (visible)
  let nomiCampiControlloVisibilita = new Set()
  // Insieme senza duplicati dei nomi (SENZA prefisso) dei campi che devono
  // essere controllati per calcolare le options dinamiche (valuesSrc con custom + dipende)
  let nomiCampiControlloOptionsDinamiche = new Set()

  // Estraggo le definizioni dei campi
  let definizioniCampi
  let layout
  if (!params || !Array.isArray(params.campi)) {
    // Nessun parametro presente
    definizioniCampi = []
    layout = {}
  } else {
    definizioniCampi = params.campi
    layout = params.layout ?? {}
  }

  const strutturaCampi = definizioniCampi.map(param => {
    const {
      name,
      label,
      type,
      formControl,
      required,
      hidden,
      values,
      valuesSrc,
      visible,
      propsComponente
    } = param

    let Input = BaseTextInput
    let defaultValue = ''
    let options
    let props = {}

    let typeDefinitivo = type
    let nomeEntita
    if (isTypeWithEntity(type)) {
      nomeEntita = type.split(':')[1]
    } else if (type.startsWith(TYPES.LIST)) {
      typeDefinitivo = type.split(':')[1]
    }

    switch (typeDefinitivo) {
      case TYPES.INT:
        defaultValue = 0
        props.type = 'numeroIntero'
        break
      case TYPES.DOUBLE:
        defaultValue = 0
        props.type = 'number'
        props.inputProps = { step: 0.01 }
        break
      case TYPES.BOOLEAN:
        Input = BaseCheckbox
        defaultValue = false
        break
      case TYPES.DATE:
        Input = BaseDateInput
        defaultValue = null
        // FLAG PER METTERE DEFAULT = new Date() ???
        break
      case TYPES.DATE_TIME:
        Input = BaseDateInput
        defaultValue = null
        props.dateTime = true
        break
      case TYPES.PAIR:
        defaultValue = { first: '', second: '' }
        props.pair = true
        break
      default:
        break
    }

    // Imposto qui il defaultValue delle liste, perché se lo
    // facessi prima dello switch sarebbe sovrascritto
    if (isTypeList(type)) {
      defaultValue = []
    }

    if (formControl === FORM_CONTROLS.RADIO
      || formControl === FORM_CONTROLS.SELECT
      || formControl === FORM_CONTROLS.SELECT_MULTI
      || formControl === FORM_CONTROLS.SELECT_MODAL
    ) {
      options = (function risolviOptions() {
        // Options schiantate nella definizione del parametro
        if (Array.isArray(values)) return values

        // Oggetto con le info sulla sorgente delle options
        if (typeof valuesSrc !== 'object') return []
        const { custom, dipende, dictKey } = valuesSrc

        // Options da risolvere in modo custom con la funzione calcolaOptions
        if (custom) {
          const {
            options: optionsCalcolate,
            optionCreator
          } = calcolaOptions({ chiave: custom, parametriFissi })
          if (typeof optionCreator === 'function') {
            props.helperText = optionCreator({ definizioneParametro: param })
          }
          if (dipende) {
            nomiCampiControlloOptionsDinamiche.add(dipende)
          }
          return optionsCalcolate
        }

        // Options provenienti da un elenco
        if (dictKey) {
          elenchi.push(dictKey)
          props.elencoPerOptions = dictKey
          return []
        }

        return []
      })()

      if (formControl === FORM_CONTROLS.SELECT) {
        options = [{ value: '', label: '' }, ...options]
      }
    }

    switch (formControl) {
      case FORM_CONTROLS.RADIO:
        Input = BaseRadioGroup
        props.visualizzazioneOrizzontale = true
        break
      case FORM_CONTROLS.SELECT:
        Input = BaseSelect
        break
      case FORM_CONTROLS.SELECT_MULTI:
        Input = BaseSelectMultipla
        props.numeroMaxOpzioniSelezionateDaVisualizzare = 4
        break
      case FORM_CONTROLS.SELECT_MODAL:
        Input = inputEntitaBase_SelectModal(nomeEntita, props, inputEntitaBase_SelectModal_Custom)
        if (isTypeList(type)) {
          props.sceltaMultipla = true
          defaultValue = []
        } else {
          props.sceltaMultipla = false
          defaultValue = null
        }
        props.propsBottoneModifica = {
          ...props.propsBottoneModifica,
          ...sx(props.propsBottoneModifica, { my: 1 })
        }
        break
      case FORM_CONTROLS.FORM_ENTITA:
        Input = inputEntitaBase_FormEntita(nomeEntita, props)
        if (isTypeList(type)) {
          defaultValue = []
        } else {
          defaultValue = null
        }
        break
      case FORM_CONTROLS.TEXTAREA:
        props.multiline = true
        props.rows = 2
        break
      default:
        break
    }

    const nameDefinitivo = prefissoNomi.addPath(name)
    mappaDefinizioniParamsOriginali[nameDefinitivo] = param
    if (visible) nomiCampiControlloVisibilita.add(visible.split('=')[0])

    return {
      campo: {
        label: capitalizza(label || name),
        name: nameDefinitivo,
        Input,
        defaultValue: parametriDefaultValues[name] ?? defaultValue,
        options
      },
      props: {
        required,
        hidden,
        larghezza: 'fullWidth',
        ...props,
        ...propsComponente
      }
    }
  })

  // Trasformo i Set in array
  nomiCampiControlloVisibilita = Array.from(nomiCampiControlloVisibilita)
  nomiCampiControlloOptionsDinamiche = Array.from(nomiCampiControlloOptionsDinamiche)

  // In fase di renderizzazione usare questa funzione passando la funzione el()
  // di ElenchiManager per iniettare nei campi le options provenienti dagli elenchi
  function iniettaOptionsElenchi(campiGenerati, el) {
    return campiGenerati.map(campo => {
      const { elencoPerOptions, ...restProps } = campo.props
      if (!elencoPerOptions) return campo
      const optionsRisolte = el(elencoPerOptions)
      return {
        ...campo,
        props: {
          ...restProps,
          options: Array.isArray(optionsRisolte) ? optionsRisolte : []
        }
      }
    })
  }

  function calcolaOptionsDinamiche(name, arrayValoriControlloOptionsDinamiche, oggettoCompletoValori) {
    const { valuesSrc = {}, formControl } = mappaDefinizioniParamsOriginali[name]
    const { custom, dipende } = valuesSrc
    if (!custom || !dipende) return null

    const indiceName = nomiCampiControlloOptionsDinamiche.indexOf(dipende)
    let valoreDipende = arrayValoriControlloOptionsDinamiche[indiceName]
    if (valoreDipende === undefined) {
      // Fix per il caso in cui arrayValoriControlloOptionsDinamiche contiene valori undefined
      // Succede quando sono in attesa di qualche elenco e i campi non sono registrati
      // Provo a cercare il valoreDipende nell'oggettoCompletoValori
      valoreDipende = getPropertyWithPath(
        prefissoNomi.addPath(dipende),
        oggettoCompletoValori
      )
    }

    let {
      options: optionsCalcolate
    } = calcolaOptions({ chiave: custom, parametriFissi, valoreDipende })

    if (formControl === FORM_CONTROLS.SELECT) {
      optionsCalcolate = [{ value: '', label: '' }, ...optionsCalcolate]
    }

    return optionsCalcolate
  }

  // Controlla se un parametro ha un valore fissato dal contesto esterno
  function isParametroFisso(name) {
    const { hidden } = mappaDefinizioniParamsOriginali[name]
    // Se un parametro è nascosto, per forza di cose è fisso
    if (hidden) return true

    const oggettoParametriFissi = prefissoNomi
      ? { [prefissoNomi]: parametriFissi }
      : parametriFissi
    return getPropertyWithPath(name, oggettoParametriFissi) !== null
  }

  // name: nome del parametro CON il prefisso
  // arrayValoriControlloVisibilita: array di valori parallelo a nomiCampiControlloVisibilita (ritornato dal watch di react-hook-form)
  // oggettoCompletoValori: oggetto ritornato dal getValues di react-hook-form
  function isParametroVisibile(name, arrayValoriControlloVisibilita, oggettoCompletoValori) {
    // Ottengo la condizione di visibilità dalla definizione del parametro corrente
    const { visible } = mappaDefinizioniParamsOriginali[name]

    // Se non è presente nessuna condizione, il parametro è sempre visibile
    if (!visible) return true

    // Faccio il parsing della condizione. Formato: "nomeParam=valore1|valore2|..."
    // Ottengo così il nome del parametro che condiziona la visibilità ed
    // i suoi valori per cui il parametro corrente deve essere visibile
    const [name_ParamVisibilita, stringaValori] = visible.split('=')
    const name_ParamVisibilita_ConPrefisso = prefissoNomi.addPath(name_ParamVisibilita)
    const valoriAmmissibili = stringaValori.split('|')

    // Ottengo il valore corrente del parametro che condiziona la visibilità
    const indiceName = nomiCampiControlloVisibilita.indexOf(name_ParamVisibilita)
    let valoreCorrente_ParamVisibilita = arrayValoriControlloVisibilita[indiceName]
    if (valoreCorrente_ParamVisibilita === undefined) {
      // Fix per il caso in cui arrayValoriControlloVisibilita contiene valori undefined
      // Succede quando sono in attesa di qualche elenco e i campi non sono registrati
      // Provo a cercare il valore del param di visibilità nell'oggettoCompletoValori
      valoreCorrente_ParamVisibilita = getPropertyWithPath(
        name_ParamVisibilita_ConPrefisso,
        oggettoCompletoValori
      )
    }

    // Controllo se il valore corrente del parametro condiziona la visibilità
    // è tra quelli che rendono visibile il parametro corrente. Devo ottenere
    // una rappresentazione in stringa del valore per fare il matching corretto
    const { type } = mappaDefinizioniParamsOriginali[name_ParamVisibilita_ConPrefisso]
    // Se il tipo è una lista, controllo se almeno uno dei valori nella lista è ammissibile
    if (type.startsWith(TYPES.LIST)) {
      const typeElementoLista = type.split(':')[1]
      return valoreCorrente_ParamVisibilita.some(elementoLista =>
        isAmmissibile(elementoLista, typeElementoLista)
      )
    }
    // Se il tipo è un valore singolo, controllo se questo è ammissibile
    return isAmmissibile(valoreCorrente_ParamVisibilita, type)

    function isAmmissibile(valore, type) {
      const valoreStringa = trasformaValoreParamInStringa(valore, type)
      return valoriAmmissibili.includes(valoreStringa)
    }
  }

  return {
    strutturaCampi,
    // Array comodo da passare al watch di react-hook-form
    nomiCampiControlloVisibilita: nomiCampiControlloVisibilita.map(name => prefissoNomi.addPath(name)),
    nomiCampiControlloOptionsDinamiche: nomiCampiControlloOptionsDinamiche.map(name => prefissoNomi.addPath(name)),
    elenchi,
    iniettaOptionsElenchi,
    calcolaOptionsDinamiche,
    isParametroFisso,
    isParametroVisibile
  }
}