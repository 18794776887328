import { string, object, bool } from 'prop-types'
import { Link, generatePath, useHistory } from 'react-router-dom'
import { BaseButtons } from 'inputs'
import useRichiestaServer from '../network/RichiestaServerHook'
import { useRoutes } from './RoutesProvider'

BottoneDettagliEntita.propTypes = {
  chiaveRotta: string.isRequired,
  conCreazione: bool, // Manda una richiesta entity.new e porta l'utente nella pagina dell'entità appena creata
  nomeEntita: string, // Required se conCreazione = true
  opzioniCreazione: object, // Usato se conCreazione = true
  uuid: string,
  altriParams: object,
  linkProps: object
}

export default function BottoneDettagliEntita(props) {
  const {
    chiaveRotta,
    conCreazione,
    nomeEntita,
    opzioniCreazione,
    uuid,
    altriParams,
    linkProps,
    ...propsBaseButton
  } = props

  const { getPath } = useRoutes()

  function costruisciUrlEntita(uuid) {
    return generatePath(
      getPath(chiaveRotta),
      { uuid, ...altriParams }
    )
  }

  if (conCreazione) {
    return (
      <BottoneCreazione
        costruisciUrlEntita={costruisciUrlEntita}
        nomeEntita={nomeEntita}
        opzioniCreazione={opzioniCreazione}
        {...propsBaseButton}
      />
    )
  }

  return (
    <Link to={costruisciUrlEntita(uuid)} {...linkProps}>
      <BaseButtons.Dettagli {...propsBaseButton} />
    </Link>
  )
}



function BottoneCreazione(props) {
  const {
    costruisciUrlEntita,
    nomeEntita,
    opzioniCreazione,
    ...propsBaseButton
  } = props

  const {
    ScatolaMessaggi,
    newEntity,
    inAttesaDelServer
  } = useRichiestaServer()

  const history = useHistory()

  async function creaEntitaEdEffettuaRedirezione() {
    const { ok, data } = await newEntity(nomeEntita, opzioniCreazione)
    if (ok) history.push(costruisciUrlEntita(data.uuid))
  }

  return (
    <>
      {ScatolaMessaggi}

      <BaseButtons.Aggiungi
        color='success'
        onClick={creaEntitaEdEffettuaRedirezione}
        inAttesa={inAttesaDelServer}
        {...propsBaseButton}
      />
    </>
  )
}
