import { BaseLink, BaseTitolo, useSsoIncloud,useQueryString } from "@lucan/base-ui";
import Layout from "components/Layout";
import { useEffect, useMemo } from "react";
import { useTokenInvalidate } from "services/Network";

export default function LoggedOutPage(props) {

  const {
    invia:inviaInvalidate
  } = useTokenInvalidate({})
  const {getToken, expireToken} = useSsoIncloud()
  
  const {app, url, app_key = ""} = useQueryString();
  const toUrl = useMemo(() => {
    if(!url) return '/'

    var queryParams = new URLSearchParams()
    queryParams.set('url',url)
    queryParams.set('app', app ?? url)
    queryParams.set('app_key', app_key)
    return '/?'+queryParams.toString()

  }, [app, url, app_key])

  useEffect(() => {
    const token = getToken();
    try {
      inviaInvalidate(token, app_key);  
    } catch (error) {
    }
    expireToken();
	}, [])


  return (
    <Layout>
      <BaseTitolo>Logout avvenuto con successo</BaseTitolo>
      {app && <BaseTitolo>da <span className="titolo">{app}</span></BaseTitolo>}
      <br />
      <BaseLink to={toUrl} >Torna al login</BaseLink>
    </Layout>
  )
}