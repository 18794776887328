import { addUuid, arricchisciPropOggetto } from 'utils'
import ListaDocumentiModifica from '../documenti/ListaDocumentiModifica'
import { ENTITA_BASE } from '../EntitaBase'
import ListaImmaginiModifica from '../immagine/ListaImmaginiModifica'
import PersonaCondizioneModifica from './PersonaCondizioneModifica'
import ProveEmaticheModifica, { ProvaEmaticaModifica } from './ProveEmaticheModifica'
import RifiutoTestModifica from './RifiutoTestModifica'
import SintomatologiaModifica from './SintomatologiaModifica'
import StatoSaluteModifica from './StatoSaluteModifica'
import TestEtilometroLista, { TestEtilometroModifica } from './TestEtilometroModifica'
import { creaNomeTest, creaTitoloPersonaCondizione } from './utilPersonaCondizione'

const PROPRIETA_PERSONA_CONDIZIONE = {
  STATO: 'condizione',
  ACCERTAMENTI_EMATICI: 'accertamentiEmatici',
  TEST_ETILOMETRO: 'testEtilometro',
  SINTOMATOLOGIA: 'sintomatologia',
  RIFIUTO_TEST: 'rifiuto'
}

const SEZIONI_PERSONA_CONDIZIONE = {
  ...PROPRIETA_PERSONA_CONDIZIONE
}

const VISIBILITA_DEFAULT = {
  [SEZIONI_PERSONA_CONDIZIONE.STATO]: true,
  [SEZIONI_PERSONA_CONDIZIONE.SINTOMATOLOGIA]: true,
  [SEZIONI_PERSONA_CONDIZIONE.TEST_ETILOMETRO]: true,
  [SEZIONI_PERSONA_CONDIZIONE.RIFIUTO_TEST]: true,
  [SEZIONI_PERSONA_CONDIZIONE.ACCERTAMENTI_EMATICI]: true
}

creaAlberoPersonaCondizione.SEZIONI = SEZIONI_PERSONA_CONDIZIONE

/*
  2 modalità per gestire il riferimento alla persona:
  - passare l'opzione "persona" se la persona non deve essere scelta dall'utente,
    ma è implicita nel contesto esterno (es. condizioni di salute di un coinvolto).
    In questo caso il campo personaRif è ignorato e viene usata la persona passata
  - passare le opzioni "creaDescrittoriPersone" + "risolviRif" per permettere
    all'utente di scegliere una persona. In questo caso viene effettivamente
    utilizzato il campo personaRif presente qui nella condizione di salute
*/
export default function creaAlberoPersonaCondizione(condizione, opzioni = {}) {
  const {
    visibilita,
    propsRadice,
    persona,
    creaDescrittoriPersone,
    risolviRif,
    ModificaRifPM
  } = opzioni
  const visibilitaDefinitiva = { ...VISIBILITA_DEFAULT, ...visibilita }

  return {
    path: 'condizione',
    nomeEntita: ENTITA_BASE.PERSONA_CONDIZIONE,
    url: 'condizione',
    label: creaTitoloPersonaCondizione(condizione, { persona, risolviRif }),
    Panel: PersonaCondizioneModifica,
    SubPanel: PersonaCondizioneModifica,
    validate: validaPersonaCondizione,
    ...propsRadice,
    ...arricchisciPropOggetto(propsRadice, 'propsPanel', {
      persona,
      creaDescrittoriPersone
    }),
    ...arricchisciPropOggetto(propsRadice, 'propsSubPanel', {
      label: 'Condizioni di salute',
      persona
    }),
    figli: condizione && [
      {
        path: PROPRIETA_PERSONA_CONDIZIONE.STATO,
        nomeEntita: ENTITA_BASE.PERSONA_CONDIZIONE_STATO,
        url: SEZIONI_PERSONA_CONDIZIONE.STATO,
        label: 'Stato di salute',
        SubPanel: StatoSaluteModifica,
        propsSubPanel: { ModificaRifPM }
      },
      {
        path: PROPRIETA_PERSONA_CONDIZIONE.SINTOMATOLOGIA,
        nomeEntita: ENTITA_BASE.PERSONA_CONDIZIONE_SINTOMATOLOGIA,
        url: SEZIONI_PERSONA_CONDIZIONE.SINTOMATOLOGIA,
        label: 'Sintomi',
        SubPanel: SintomatologiaModifica
      },
      {
        path: PROPRIETA_PERSONA_CONDIZIONE.TEST_ETILOMETRO,
        nomeEntita: ENTITA_BASE.PERSONA_CONDIZIONE_TEST_ETILOMETRO,
        url: SEZIONI_PERSONA_CONDIZIONE.TEST_ETILOMETRO,
        label: 'Test etilometrici',
        SubPanel: TestEtilometroLista,
        figli: condizione.testEtilometro.map((singoloTest, index) => ({
          path: addUuid(singoloTest.uuid),
          nomeEntita: ENTITA_BASE.PERSONA_CONDIZIONE_TEST_ETILOMETRO,
          url: singoloTest.uuid,
          label: creaNomeTest(index),
          Panel: TestEtilometroModifica,
          figli: singoloTest.effettuato && [
            { 
              ...ListaDocumentiModifica.defaultConfig,
              SubPanel: ListaDocumentiModifica,
              propsSubPanel: { disabilitaGenerazioneDaModello: true }
            },
            { 
              ...ListaImmaginiModifica.defaultConfig,
              SubPanel: ListaImmaginiModifica
            }
          ]
        }))
      },
      {
        path: PROPRIETA_PERSONA_CONDIZIONE.RIFIUTO_TEST,
        nomeEntita: ENTITA_BASE.PERSONA_CONDIZIONE_RIFIUTO_TEST,
        url: SEZIONI_PERSONA_CONDIZIONE.RIFIUTO_TEST,
        label: 'Rifiuto test',
        SubPanel: RifiutoTestModifica
      },
      {
        path: PROPRIETA_PERSONA_CONDIZIONE.ACCERTAMENTI_EMATICI,
        nomeEntita: ENTITA_BASE.PERSONA_CONDIZIONE_PROVA_EMATICA,
        url: SEZIONI_PERSONA_CONDIZIONE.ACCERTAMENTI_EMATICI,
        label: 'Prove ematiche',
        SubPanel: ProveEmaticheModifica,
        figli: condizione.accertamentiEmatici.map(provaEmatica => ({
          path: addUuid(provaEmatica.uuid),
          nomeEntita: ENTITA_BASE.PERSONA_CONDIZIONE_PROVA_EMATICA,
          url: provaEmatica.uuid,
          label: provaEmatica.nome,
          Panel: ProvaEmaticaModifica,
          figli: [
            { 
              ...ListaDocumentiModifica.defaultConfig,
              SubPanel: ListaDocumentiModifica,
              propsSubPanel: { disabilitaGenerazioneDaModello: true }
            },
            { 
              ...ListaImmaginiModifica.defaultConfig,
              SubPanel: ListaImmaginiModifica
            }
          ]
        }))
      }
    ].filter(nodo => Boolean(nodo) && visibilitaDefinitiva[nodo.url] === true)
  }
}



function validaPersonaCondizione(utilValidazione) {
  
}