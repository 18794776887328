import { func, bool, object, node } from 'prop-types'
import { Alert } from '@mui/material'
import { BaseButtons } from 'inputs'
import { BaseGridLayout } from 'layout'
import BasePopoverConfermaOperazione from './BasePopoverConfermaOperazione'

BaseBottoneEFeedbackSalvataggio.propTypes = {
  mostraMessaggioSalvataggio: bool.isRequired,
  messaggioSalvataggio: node,
  azioneDopoSalvataggio: node,
  onModificaDopoSalvataggio: func,
  onConfermaSalvataggio: func.isRequired,
  inAttesaSalvataggio: bool,
  propsAlert: object,
  propsBottoneModificaDopoSalvataggio: object,
  propsPopoverConferma: object,
  propsBottoneSalva: object
}

export default function BaseBottoneEFeedbackSalvataggio(props) {
  const {
    mostraMessaggioSalvataggio,
    messaggioSalvataggio = 'Salvataggio effettuato con successo',
    azioneDopoSalvataggio,
    onModificaDopoSalvataggio,
    onConfermaSalvataggio,
    inAttesaSalvataggio = false,
    propsAlert,
    propsBottoneModificaDopoSalvataggio,
    propsPopoverConferma,
    propsBottoneSalva
  } = props

  if (mostraMessaggioSalvataggio) {
    return (
      <div>
        <Alert
          elevation={5}
          variant='filled'
          severity='success'
          {...propsAlert}
        >
          {messaggioSalvataggio}
        </Alert>

        <br />

        <BaseGridLayout vertical>
          {azioneDopoSalvataggio}

          {onModificaDopoSalvataggio &&
            <BaseButtons.Modifica
              testo='Modifica ancora'
              color='default'
              onClick={onModificaDopoSalvataggio}
              {...propsBottoneModificaDopoSalvataggio}
            />
          }
        </BaseGridLayout>
      </div>
    )
  }

  return (
    <BasePopoverConfermaOperazione
      messaggio='Confermare il salvataggio?'
      onConferma={onConfermaSalvataggio}
      {...propsPopoverConferma}
    >
      <BaseButtons.Salva
        inAttesa={inAttesaSalvataggio}
        color='success'
        {...propsBottoneSalva}
      />
    </BasePopoverConfermaOperazione>
  )
}