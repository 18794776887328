import { forwardRef, useMemo } from 'react'
import { TreeItem, TreeView, useTreeItem } from '@mui/lab'
import clsx from 'clsx'
import { ICONE } from 'icons'
import { VoceMenuLaterale, LabelVoceMenu as LabelVoceMenu_Default, IndicatoreStatoVoceMenu } from './VoceMenuLaterale'

const ID_ROOT = '_$_ROOT_$_'

export default function MenuLateraleAlbero(props) {
  const {
    albero,
    alberoPiatto,
    urlPannelloCorrente,
    LabelVoceMenu = LabelVoceMenu_Default
  } = props

  const idNodiEspansiInizialmente = useMemo(() => ([
    ID_ROOT,
    ...alberoPiatto
      .filter(({ propsMenu }) => propsMenu.menuEspanso)
      .map(({ urlPannello }) => urlPannello)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ]), [])

  return (
    <TreeView
      defaultExpanded={idNodiEspansiInizialmente}
      selected={urlPannelloCorrente}
      defaultCollapseIcon={<ICONE.ESPANDI_PIU />}
      defaultExpandIcon={<ICONE.CHEVRON_RIGHT />}
    >
      <AlberoMenu
        nodoAlbero={albero}
        LabelVoceMenu={LabelVoceMenu}
        urlPannelloCorrente={urlPannelloCorrente}
      />
    </TreeView>
  )
}



function AlberoMenu(props) {
  const { nodoAlbero, LabelVoceMenu, urlPannelloCorrente } = props
  const {
    isRoot,
    urlPannello,
    Panel,
    propsMenu: { isFogliaMenu },
    figli
  } = nodoAlbero

  const alberoFigli = !isFogliaMenu && Array.isArray(figli) ? (
    figli.map(figlio => figlio &&
      <AlberoMenu
        nodoAlbero={figlio}
        LabelVoceMenu={LabelVoceMenu}
        urlPannelloCorrente={urlPannelloCorrente}
        key={figlio.urlPannello}
      />
    )
  ) : null

  // Se un nodo non ha un Panel, significa che non esiste una pagina
  // specifica in cui si deve navigare cliccando la voce del menu
  // Quindi salto queste voci e mostro direttamente i figli
  if (!Panel || isRoot) return alberoFigli

  const id = isRoot ? ID_ROOT : urlPannello

  return (
    <TreeItem
      key={id}
      nodeId={id}
      ContentComponent={CustomTreeItemContent}
      ContentProps={{ nodoAlbero, LabelVoceMenu, urlPannelloCorrente }}
      sx={{ '& .MuiTreeItem-content': { p: 0, borderRadius: 1 } }}
    >
      {alberoFigli}
    </TreeItem>
  )
}



// Ho copiato il codice di TreeItemContent dal repository di
// Material UI e ho modificato alcune cose (quelle commentate)
const CustomTreeItemContent = forwardRef(function CustomTreeItemContent(props, ref) {
  const {
    /* Props aggiunte */
    nodoAlbero,
    LabelVoceMenu,
    urlPannelloCorrente,
    /******************/

    classes,
    className,
    displayIcon,
    expansionIcon,
    icon: iconProp,
    nodeId,
    onClick,
    onMouseDown,
    ...restProps
  } = props

  const {
    disabled,
    expanded,
    selected,
    focused,
    handleExpansion,
    handleSelection,
    preventSelection,
  } = useTreeItem(nodeId)

  const icon = iconProp || expansionIcon || displayIcon

  const handleMouseDown = (event) => {
    preventSelection(event)
    if (onMouseDown) onMouseDown(event)
  }

  /*** Separato handleClick in 2 casi: click su icona / click su label ***/

  // const handleClick = (event) => {
  //   handleExpansion(event)
  //   handleSelection(event)
  //   if (onClick) onClick(event)
  // }

  // Cliccare sull'icona è uguale a handleClick
  const handleClickOnIcon = (event) => {
    handleExpansion(event)
    handleSelection(event)
    if (onClick) onClick(event)
  }

  // Cliccare sulla label ha una differenza per quanto riguarda l'espansione
  // Se non è espanso, si espande; se è espanso, rimane espanso
  const handleClickOnLabel = (event) => {
    if (!expanded) handleExpansion(event)
    handleSelection(event)
    if (onClick) onClick(event)
  }

  /***********************************************************************/

  return (
    /***** Aggiunto componente wrapper *****/
    <VoceMenuLaterale
      nodoAlbero={nodoAlbero}
      urlPannelloCorrente={urlPannelloCorrente}
    >
      <div
        className={clsx(className, classes.root, {
          [classes.expanded]: expanded,
          [classes.selected]: selected,

          /* Aggiunto "|| selected" per migliorare lo stile della voce selezionata */
          [classes.focused]: focused || selected,
          /*************************************************************************/

          [classes.disabled]: disabled,
        })}
        onMouseDown={handleMouseDown}
        ref={ref}
        {...restProps}
      >
        <div className={classes.iconContainer} onClick={handleClickOnIcon}>
          {icon}
        </div>

        <div className={classes.label} onClick={handleClickOnLabel}>
          {/* Aggiunto contenuto label custom */}
          {/* Non uso BaseGridLayout perché fa casino con le aree cliccabili dei link */}
          <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
            <LabelVoceMenu nodoAlbero={nodoAlbero} />
            <IndicatoreStatoVoceMenu nodoAlbero={nodoAlbero} />
          </div>
          {/***********************************/}
        </div>
      </div>
    </VoceMenuLaterale>
    /***************************************/
  )
})