import { object, string, bool } from 'prop-types'
import { Table, TableBody, TableCell, TableRow } from '@mui/material'
import { sx } from 'utils'

BaseTabellaChiaveValore.propTypes = {
  data: object.isRequired,
  noValueFallback: string,
  firstColProps: object,
  secondColProps: object,
  showDividers: bool,
}

export default function BaseTabellaChiaveValore(props) {
  const {
    data,
    noValueFallback = '-',
    firstColProps,
    secondColProps,
    showDividers,
    ...restProps
  } = props

  const cellSx = {
    p: 0.5,
    fontSize: '1rem',
    ...(!showDividers && { borderBottom: 'none' })
  }

  return (
    <Table size='small' {...restProps}>
      <TableBody>
        {Object.entries(data).map(([key, value]) =>
          <TableRow key={key}>
            <TableCell
              {...firstColProps}
              {...sx(firstColProps, cellSx)}
            >
              {key}
            </TableCell>

            <TableCell
              {...secondColProps}
              {...sx(secondColProps, [cellSx, { fontWeight: 'bold' }])}
            >
              {value ?? noValueFallback}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}