import { useRef } from 'react'
import { FormPerUploadFile } from 'files'
import { BaseModal } from 'layout'
import FormGeneraDocumento from './form/FormGeneraDocumento'
import { isDocumentoGeneratoDaTemplate } from './utilDocumenti'

export default function ModalModificaDocumento(props) {
  const {
    documentoInput,
    allegatoInput,
    modificaDocumento,
    modificaSoloAllegato,
    template,
    chiudiModifica,

    maxWidthModalCaricamento,
    propsModalModifica,
    propsFormUpload,
    propsFormGenera,
    propsEntita
  } = props

  const isDocumentoGenerato = isDocumentoGeneratoDaTemplate(documentoInput)

  const formGeneraDocumentoRef = useRef()

  function onClickBottoneChiudi() {
    formGeneraDocumentoRef.current?.chiediConfermaSalvaBozza()
  }

  return (
    <BaseModal
      titolo={isDocumentoGenerato
        ? 'Modifica documento generato da modello'
        : 'Modifica documento caricato'
      }
      open={true}
      onClose={chiudiModifica}
      disabilitaChiusuraCliccandoFuori
      {...(isDocumentoGenerato ? {
        fullScreen: true,
        ...(!propsFormGenera.readOnly && {
          propsBottoneChiudi: { onClick: onClickBottoneChiudi }
        })
      } : {
        maxWidth: maxWidthModalCaricamento
      })}
      {...propsModalModifica}
    >
      {isDocumentoGenerato ? (
        <FormGeneraDocumento
          template={template}
          documentoInput={documentoInput}
          salvaDocumentoGenerato={modificaDocumento}
          pathDocumentoModifica={propsEntita.pathEntita}
          chiudiModal={chiudiModifica}
          formGeneraDocumentoRef={formGeneraDocumentoRef}
          {...propsFormGenera}
        />
      ) : (
        <FormPerUploadFile
          defaultValues={allegatoInput}
          salvaDati={modificaSoloAllegato}
          {...propsFormUpload}
        />
      )}
    </BaseModal>
  )
}