function isNumeroRegistroAssegnato(numero) {
  return Number(numero) > 0
}

function creaLabelNumeroEAnno(numero, anno) {
  return isNumeroRegistroAssegnato(numero) ? `${numero}/${anno}` : '(non assegnato)'
}

export {
  isNumeroRegistroAssegnato,
  creaLabelNumeroEAnno
}