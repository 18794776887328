import { useCallback, useRef, useMemo, useEffect } from 'react'
import { BaseForm } from 'inputs'
import { arricchisciPropOggetto, debounce, deepMerge } from 'utils'

// Simile a FormModificaEntita ma semplificata per funzionare fuori da PannelloModificaEntita
export default function FormEntitaBase(props) {
  const {
    children,
    valoriInput,
    onValoriModificati,
    trasformaValoriInput = valori => valori,
    trasformaValoriOutput = valori => valori,
    ritardoDebounceInMillisecondi = 1000,
    ...restProps
  } = props

  // La form usa i defaultValues solo al primo render, quindi non serve ricalcolarli
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultValues = useMemo(() => trasformaValoriInput(valoriInput), [])

  const formRef = useRef()

  function inviaValoriForm(valoriCampiRegistrati) {
    const nuoviValori = deepMerge(defaultValues, valoriCampiRegistrati)
    const nuoviValoriTrasformati = trasformaValoriOutput(nuoviValori)
    onValoriModificati(nuoviValoriTrasformati)
  }

  const inviaValoriForm_Ref = useRef(inviaValoriForm)
  inviaValoriForm_Ref.current = inviaValoriForm

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const inviaValoriForm_ConRitardo = useCallback(
    debounce(() => {
      // Uso watch invece di getValues, perché watch restituisce solo i valori dei campi registrati
      // Invece getValues fa sempre il merge con i defaultValues, anche con shouldUnregister = true
      // Fare il submit non va bene, perché viene fatto solo se non ci sono errori di validazione
      const valoriCampiRegistrati = formRef.current.watch()
      inviaValoriForm_Ref.current(valoriCampiRegistrati)
    }, ritardoDebounceInMillisecondi),
    [ritardoDebounceInMillisecondi]
  )

  useEffect(() => {
    formRef.current.trigger()
  }, [])

  return (
    <BaseForm
      ref={formRef}
      defaultValues={defaultValues}
      onChangeTuttiCampi={inviaValoriForm_ConRitardo}
      larghezzaInputs='lungo'
      nonUsareFormTagHtml
      {...restProps}
      {...arricchisciPropOggetto(restProps, 'opzioniUseForm', {
        mode: 'onChange'
      })}
    >
      {children}
    </BaseForm>
  )
}