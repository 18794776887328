import { useRef } from 'react'

// Ritorna un booleano che dice se il render attuale è il primo 
export default function useIsFirstRender() {
  const isFirstRender_Ref = useRef(true)

  if (isFirstRender_Ref.current === true) {
    isFirstRender_Ref.current = false
    return true
  }

  return false
}