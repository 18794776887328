import { useMemo } from 'react'
import ComandoPlaceholder from 'assets/img/ComandoPlaceholder.png'
import useRichiestaServer from './network/RichiestaServerHook'
import { useUser } from './userManagement/userProvider/UserProvider'

export default function LogoComando(props) {
  const {
    tag,
    size = 'sm',
    wrapperStyle = {},
    className = 'logoComando',
    displayNoCmd = false,
    as = 'div',
    style = {},
    ...rest
  } = props

  const sizeStyles = useMemo(() => {
    switch (size) {
      case 'big':
        return {
          width: '350px',
          height: '214px',
          margin: '20px auto'
        }
      case 'md':
        return {
          width: '30px',
          height: '30px',
          margin: '0 auto'
        }
      case 'xs':
        return {
          width: '7px',
          height: '7px',
          margin: '0 auto'
        }
      default:
        return {
          width: '17px',
          height: '17px',
          margin: '0 auto'
        }
    }
  }, [size])

  const { getComandoScelto } = useUser()
  const cmd = getComandoScelto()

  const { logoComandoSrc } = useRichiestaServer()
  const comandoSrc = logoComandoSrc(tag ?? cmd?.tag ?? '')
  const logoUrl = comandoSrc
    ? comandoSrc
    : (displayNoCmd ? `${ComandoPlaceholder}` : '')

  const As = as
  return (
    <>
      {logoUrl &&
        <As
          className={className}
          style={{ ...sizeStyles, ...wrapperStyle }}
        >
          <img
            src={logoUrl}
            style={{
              objectFit: 'contain',
              height: 'inherit',
              width: 'inherit',
              ...style
            }}
            {...rest}
            alt=''
          />
        </As>
      }
    </>
  )
}