import { useMemo } from 'react'
import { arrayOf, shape, number, string, bool, func } from 'prop-types'
import { useHistory, useParams, generatePath, Redirect } from 'react-router-dom'
import { BaseTabs } from 'layout'
import { useRoutes } from './RoutesProvider'

TabsSincronizzateConRotta.propTypes = {
  tabs: arrayOf(shape({
    chiave: string.isRequired,
    titolo: string.isRequired,
    Contenuto: func.isRequired
  })).isRequired,
  indiceTabDefault: number,
  chiaveRottaBase: string.isRequired,
  disabilitaOttimizzazione_TabNonVisibili: bool
}

export default function TabsSincronizzateConRotta(props) {
  const {
    tabs,
    indiceTabDefault = 0,
    chiaveRottaBase,
    disabilitaOttimizzazione_TabNonVisibili,
    ...propsTabs
  } = props

  const { getPath } = useRoutes()

  const history = useHistory()

  // Ottieni dalla rotta la tab da mostrare
  const { tab } = useParams()
  const indiceTabVisibile = tabs.findIndex(({ chiave }) => chiave === tab)

  // Accorgimento per migliori performance e più consistenza
  const tabsDefinitive = useMemo(
    () => tabs.map(({ titolo, Contenuto }, indice) => ({
      titolo,
      contenuto: disabilitaOttimizzazione_TabNonVisibili
        ? <Contenuto />
        // Eseguo la render function solo della tab visibile. Questo migliora le performance
        // e garantisce che tutto venga ricaricato correttamente al cambio della tab
        : (indiceTabVisibile === indice) && <Contenuto />
    })),
    [tabs, indiceTabVisibile, disabilitaOttimizzazione_TabNonVisibili]
  )

  // Se nella rotta non c'è una tab valida, redirigi su quella di default
  if (indiceTabVisibile === -1) {
    return <Redirect to={costruisciRottaTab(indiceTabDefault)} />
  }

  // Aggiorna la rotta quando l'utente cambia tab
  function onChangeTab(indiceNuovaTab) {
    history.replace(costruisciRottaTab(indiceNuovaTab))
  }

  function costruisciRottaTab(indiceNuovaTab) {
    const chiaveNuovaTab = tabs[indiceNuovaTab].chiave
    return generatePath(getPath(chiaveRottaBase), { tab: chiaveNuovaTab })
  }

  return (
    <BaseTabs
      tabs={tabsDefinitive}
      indiceTabVisibile={indiceTabVisibile}
      onChangeTab={onChangeTab}
      {...propsTabs}
    />
  )
}