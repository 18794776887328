import { string, arrayOf, shape, oneOfType, func, object } from 'prop-types'
import { Breadcrumbs } from '@mui/material'
import { BaseLink } from './BaseLink'
import { getSequenceOfParentRoutes } from 'utils'

const routeShape = shape({
  label: oneOfType([string, func]).isRequired,
  pathAssoluto: string.isRequired,
  parentRoute: object
})
BaseBreadcrumbs.propTypes = {
  // Si può passare una sequenzaRoutes già costruita
  sequenzaRoutes: arrayOf(routeShape),
  // Oppure passare la currentRoute 
  currentRoute: routeShape,
  sx: object,
}

export default function BaseBreadcrumbs(props) {
  const {
    sequenzaRoutes,
    currentRoute,
    ...propsBreadcrumbs
  } = props

  const sequenzaRoutesDaUsare = sequenzaRoutes
    ? sequenzaRoutes
    : getSequenceOfParentRoutes(currentRoute)

  return (
    <Breadcrumbs {...propsBreadcrumbs}>
      {sequenzaRoutesDaUsare.map(({ label, pathAssoluto }, index) => {
        const isUltimoSegmento = (index === sequenzaRoutesDaUsare.length - 1)
        const altreProps = isUltimoSegmento ? {
          color:'inherit',
          underline: 'none',
          disabled: true
        } : {}

        return (
          <BaseLink
            key={pathAssoluto}
            to={pathAssoluto}
            {...altreProps}
          >
            {label}
          </BaseLink>
        )
      })}
    </Breadcrumbs>
  )
}
