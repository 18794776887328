import { BaseGridLayout } from 'layout'
import { arrayOf, bool, node, number, object } from 'prop-types'

// Serve per maggiore compatibilità con Typescript

BaseGridLayoutSafe.propTypes = {
  children: node,
  numeroRighe: number,
  spacing: number,
  vertical: bool,
  justifyCenter: bool,
  justifySpaceBetween: bool,
  alignCenter: bool,
  noWrap: bool,
  disposizioneRighe: arrayOf(number),
  propsGridItem: object,
  hidden: bool
}

export default function BaseGridLayoutSafe(props) {
  return <><BaseGridLayout {...props} /></>
}
