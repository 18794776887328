import { useRef, useCallback, useMemo, useEffect } from 'react'
import { object, func, string, shape, node, any, number } from 'prop-types'
import { BaseForm, BaseIconButtons } from 'inputs'
import { debounce } from 'utils'
import { NOME_CAMPO_GENERICO } from '../logica/LogicaValidazioneEntita'
import { useEntita } from './EntitaContext'
import ListaMessaggiValidazione from './ListaMessaggiValidazione'
import { useVaiAlMessaggio } from './VaiAlMessaggioContext'

FormModificaEntita.propTypes = {
  valoriInput: object.isRequired,
  trasformaValoriInput: func,
  trasformaValoriOutput: func,
  isModificaDaInviare: func,
  pathEntita: string.isRequired,
  nomeEntita: string.isRequired,
  children: node,
  formRef: shape({ current: any }),
  ritardoDebounceInMillisecondi: number,
  opzioniModificaEntita: object,
  propsListaMessaggiValidazione: object
}

const nessunaTrasformazione = valori => valori

export default function FormModificaEntita(props) {
  const {
    valoriInput,
    trasformaValoriInput = nessunaTrasformazione,
    trasformaValoriOutput = nessunaTrasformazione,
    isModificaDaInviare = () => true,
    pathEntita,
    nomeEntita,
    children,
    formRef: formRef_Prop,
    ritardoDebounceInMillisecondi = 1000,
    opzioniModificaEntita,
    propsListaMessaggiValidazione,
    ...restProps
  } = props

  const {
    gestioneModifica: { modificaEntita, mostraInfoDebug },
    gestionePermessi: { readOnly },
    gestioneValidazione: { getMessaggiConPathEsatto }
  } = useEntita()

  const formRef_Locale = useRef()
  const formRef = formRef_Prop || formRef_Locale

  /************* Gestione valori input e output *************/

  // La form usa i defaultValues solo al primo render, quindi non serve ricalcolarli
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultValues = useMemo(() => trasformaValoriInput(valoriInput), [])

  function inviaValoriForm(valoriCampiRegistrati) {
    if (readOnly) return
    const valoriOutput = trasformaValoriOutput(valoriCampiRegistrati, formRef)
    if (!isModificaDaInviare(valoriOutput, valoriInput)) return
    modificaEntita(nomeEntita, pathEntita, valoriOutput, opzioniModificaEntita)
  }

  const inviaValoriForm_Ref = useRef(inviaValoriForm)
  inviaValoriForm_Ref.current = inviaValoriForm

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const inviaValoriForm_ConRitardo = useCallback(
    debounce(() => {
      // Uso watch invece di getValues, perché watch restituisce solo i valori dei campi registrati
      // Invece getValues fa sempre il merge con i defaultValues, anche con shouldUnregister = true
      // Fare il submit non va bene, perché viene fatto solo se non ci sono errori di validazione
      const valoriCampiRegistrati = formRef.current.watch()
      inviaValoriForm_Ref.current(valoriCampiRegistrati)
    }, ritardoDebounceInMillisecondi),
    [ritardoDebounceInMillisecondi]
  )

  /**********************************************************/

  /************ Gestione messaggi di validazione ************/

  const {
    [NOME_CAMPO_GENERICO]: messaggiNonRelativiAdUnCampoSpecifico = [],
    ...messaggiRelativiAdUnCampoSpecifico
  } = getMessaggiConPathEsatto(pathEntita)

  const refInizioForm = useRef()

  const {
    pathForm,
    nomeCampo,
    pulisciInfoVaiAlMessaggio
  } = useVaiAlMessaggio()

  // L'utente può arrivare su questa form da un bottone "Vai al messaggio"
  // Se il pathForm e il nomeCampo del messaggio sono relativi a questa form,
  // scrollo la pagina per mostrare la form ed eventualmente metto il focus su un campo
  useEffect(() => {
    if (pathForm === pathEntita) {
      // Per qualche motivo, senza il setTimeout a volte non scrollava
      setTimeout(() => {
        const {
          top: coordinataY_InizioForm
        } = refInizioForm.current.getBoundingClientRect()

        window.scrollTo({
          // Scrollo un po' prima dell'inizio della form (200 pixel)
          top: coordinataY_InizioForm - 200,
          behavior: 'smooth'
        })
      })

      if (nomeCampo && nomeCampo !== NOME_CAMPO_GENERICO) {
        // Il ritardo rende più facile per l'utente individuare il campo
        setTimeout(() => {
          /*
            Per la funzione setFocus serve che react hook form sia riuscito ad
            attaccare un ref al campo. Questo non dà problemi per gli input
            tradizionali, ma ci possono essere componenti input custom che
            non attaccano il ref da nessuna parte. Per evitare che in questi
            casi si spacchi tutto, catcho l'eccezione e non faccio niente.
            Tanto la possibilità di impostare automaticamente il focus è solo
            un aiuto in più per l'utente, non una funzionalità fondamentale
          */
          try {
            formRef.current.setFocus(nomeCampo)
          } catch (error) { /* empty */ }
        }, 500)
      }

      // Pulisco le info subito dopo averle usate, così 
      // la prossima volta scatta ancora lo useEffect
      pulisciInfoVaiAlMessaggio()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathForm, nomeCampo])

  /**********************************************************/

  return (
    <BaseForm
      ref={formRef}
      defaultValues={defaultValues}
      onChangeTuttiCampi={inviaValoriForm_ConRitardo}
      messaggiValidazioneEsterna={messaggiRelativiAdUnCampoSpecifico}
      larghezzaInputs='lungo'
      nonUsareFormTagHtml
      key={pathEntita}
      {...restProps}
      {...(readOnly && { readOnly })}
    >
      {/*
        Elemento vuoto che serve per poter scrollare
        la pagina nel punto dove inizia la form
      */}
      <span ref={refInizioForm} />

      <ListaMessaggiValidazione
        messaggi={messaggiNonRelativiAdUnCampoSpecifico}
        mostraPrimoMessaggioNelRiassunto
        margineSottoIlRiassunto
        nascondiBottoni_VaiAlMessaggio
        {...propsListaMessaggiValidazione}
      />

      {children}
      
      {/* Cominciavano a darmi fastidio questi messaggi di debug */}
      {/*mostraInfoDebug*/ false &&
        <div>
          DEBUG: {nomeEntita} <BaseIconButtons.Aiuto contenutoTooltip={pathEntita} />
        </div>
      }
    </BaseForm>
  )
}



export const PROP_TYPES_WRAPPER_FORM_MODIFICA = {
  propsEntita: shape({
    pathEntita: string,
    valoriInput: any,
    nomeEntita: string,
    urlPannello: string
  }).isRequired,
  label: string
}