import { bool, func, node } from 'prop-types'

/**
 * @typedef { import("../common/utils/PadreCondizionale").PadreCondizionaleProps } PadreCondizionaleProps
 * @typedef { import("../common/utils/PadreCondizionale").PadreCondizionale } PadreCondizionale
 */

/**
 * @type {PadreCondizionaleProps}
 */
PadreCondizionale.propTypes = {
  abilitato: bool,
  componente: func,
  children: node
}

/**
 * 
 * @param {PadreCondizionaleProps} props 
 * @type {PadreCondizionale}
 */
export default function PadreCondizionale(props) {
  const {
    abilitato,
    componente,
    children,
    ...restProps
  } = props
  
  if (!abilitato) return <>{children}</>

  const Componente = componente
  return <Componente {...restProps}>{children}</Componente>
}