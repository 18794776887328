import { node, string, object, oneOf } from 'prop-types'
import { BaseAvatar } from 'icons'
import BaseGridLayout from './BaseGridLayout'

BaseIconaEContenuto.propTypes = {
  children: node,
  icona: node,
  coloreIcona: string,
  dimensioneIcona: oneOf(['grande', 'media', 'piccola']),
  propsIcona: object
}

export default function BaseIconaEContenuto(props) {
  const {
    children,
    icona,
    coloreIcona,
    dimensioneIcona = 'piccola',
    propsIcona,
    ...restProps
  } = props

  return (
    <BaseGridLayout spacing={1} noWrap {...restProps}>
      <BaseAvatar
        icona={icona}
        coloreIcona={coloreIcona}
        dimensioneIcona={dimensioneIcona}
        {...propsIcona}
      />

      <>{children}</>
    </BaseGridLayout>
  )
}