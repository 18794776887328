import { useImperativeHandle, useRef } from 'react'
import { string, arrayOf, object, func, shape, bool } from 'prop-types'
import { BaseButtons, BaseForm } from 'inputs'
import { BaseGridLayout } from 'layout'
import { useStateWithLabel } from 'utils'
import FeedbackCaricamentoFile from './componentiFormUpload/FeedbackCaricamentoFile'
import FileInputSeFileNonCaricato from './componentiFormUpload/FileInputSeFileNonCaricato'
import FormInformazioniFile from './componentiFormUpload/FormInformazioniFile'
import useLogicaUploadFile from './componentiFormUpload/LogicaUploadFile'

FormPerUploadFile.propTypes = {
  propsFileInput: object,
  tagsDisponibili: arrayOf(string),
  // Hook che implementa le funzioni di upload e download
  useGestioneFile: func.isRequired,
  // Permette di agire sui dati che vengono mandati in fase di upload del file
  // La funzione viene chiamata con i dati che stanno per essere inviati
  calcolaAltreInfoPerUpload: func,
  // Opzioni da passare alla funzione uploadFile
  opzioniUploadFile: object,

  // Queste 3 prop funzionano insieme: CampiAggiuntivi deve essere un
  // componente contenente dei campi, che devono essere registrati
  // con dei nomi del tipo `${prefissoNomiCampiAggiuntivi}.${nome}`
  // Inoltre serve un oggetto con i defaultValues dei campi aggiuntivi
  // In questo modo i dati dei campi aggiuntivi non vengono mandati al
  // server in fase di salvataggio del binario, e vengono passati in
  // modo corretto alla funzione salvaDati per essere usati dal padre
  // IMPORTANTE: non inserire un BaseForm nel componente CampiAggiuntivi
  // IMPORTANTE: il prefisso NON deve essere un path annidato (con dei punti)
  CampiAggiuntivi: func,
  prefissoNomiCampiAggiuntivi: string,
  defaultValuesCampiAggiuntivi: object,

  // Props inserite di solito dalla tabella modificabile
  salvaDati: func.isRequired, // Riceve le info del file, ma non il binario
  defaultValues: shape({
    uuid: string.isRequired,
    filename: string.isRequired,
    mimetype: string,
    titolo: string,
    descrizione: string,
    tags: arrayOf(string)
  }),
  defaultValuesInformazioniFile: shape({
    titolo: string,
    descrizione: string,
    tags: arrayOf(string)
  }),
  nascondiFormInformazioni: bool,
  nascondiBottoneSalva: bool,
  nascondiFeedbackCaricamento: bool,
  refFormUpload: object,
  closeModal: func,
  readOnly: bool
}

const defaultValues_FileNonCaricato = {
  titolo: '',
  descrizione: '',
  tags: [],
  file: null
}

export default function FormPerUploadFile(props) {
  const {
    propsFileInput,
    tagsDisponibili,
    useGestioneFile,
    calcolaAltreInfoPerUpload,
    opzioniUploadFile,

    CampiAggiuntivi,
    prefissoNomiCampiAggiuntivi,
    defaultValuesCampiAggiuntivi,

    salvaDati,
    defaultValues: defaultValues_FileGiaCaricato,
    defaultValuesInformazioniFile,
    nascondiFormInformazioni,
    nascondiBottoneSalva,
    nascondiFeedbackCaricamento,
    refFormUpload,
    closeModal,
    readOnly
  } = props

  const ciSonoCampiAggiuntivi = (
    CampiAggiuntivi
    && prefissoNomiCampiAggiuntivi
    && defaultValuesCampiAggiuntivi
  )

  // La form ha 2 stati principali in base a questa variabile:
  // - se fileCaricato = null, il file input è visibile ed al momento del 
  //   salvataggio viene fatto l'upload del file insieme alle informazioni (titolo, ecc)
  // - se fileCaricato = oggetto, il file input NON è visibile ed al momento del 
  //   salvataggio vengono inviate solo le informazioni (titolo, ecc) SENZA il file
  const [
    fileCaricato, setFileCaricato
  ] = useStateWithLabel(defaultValues_FileGiaCaricato || null, 'fileCaricato')

  const {
    uploadFile,
    ScatolaMessaggi,
    inAttesaDelServer,
    downloadFileWithUUID,
    downloadToastRef
  } = useGestioneFile()

  const {
    percentualeCaricata,
    modificaDati_EffettuataConSuccesso,
    caricaFile_OppureModificaInfo
  } = useLogicaUploadFile({
    fileCaricato,
    setFileCaricato,
    defaultValues_FileGiaCaricato,
    uploadFile,
    calcolaAltreInfoPerUpload,
    opzioniUploadFile,
    salvaDati,
    closeModal,
    ...(ciSonoCampiAggiuntivi && { prefissoNomiCampiAggiuntivi })
  })

  const formRef = useRef()

  useImperativeHandle(refFormUpload, () => ({
    submit: () => formRef.current?.submit()
  }))

  return (
    <>
      {ScatolaMessaggi}

      <BaseForm
        onSubmit={caricaFile_OppureModificaInfo}
        defaultValues={{
          ...defaultValues_FileNonCaricato,
          ...defaultValuesInformazioniFile,
          ...fileCaricato,
          ...(ciSonoCampiAggiuntivi && {
            [prefissoNomiCampiAggiuntivi]: defaultValuesCampiAggiuntivi
          })
        }}
        larghezzaInputs='fullWidth'
        ref={formRef}
        readOnly={readOnly}
      >
        <BaseGridLayout vertical spacing={1}>
          <FormInformazioniFile
            tagsDisponibili={tagsDisponibili}
            fileCaricato={fileCaricato}
            hidden={nascondiFormInformazioni}
          />

          {ciSonoCampiAggiuntivi &&
            <CampiAggiuntivi prefissoNomi={prefissoNomiCampiAggiuntivi} />
          }

          <FileInputSeFileNonCaricato
            fileCaricato={fileCaricato}
            setFileCaricato={setFileCaricato}
            propsFileInput={propsFileInput}
            downloadFileWithUUID={downloadFileWithUUID}
            downloadToastRef={downloadToastRef}
            readOnly={readOnly}
          />

          <BaseButtons.Salva
            disabled={readOnly || percentualeCaricata !== null || inAttesaDelServer}
            // Faccio il submit così per evitare di fare submit
            // su un'eventuale form esterna che contiene questa modal
            submit={false}
            onClick={() => formRef.current?.submit()}
            hidden={nascondiBottoneSalva}
          />

          <FeedbackCaricamentoFile
            fileCaricato={fileCaricato}
            inAttesa={inAttesaDelServer}
            percentualeCaricata={percentualeCaricata}
            modificaDati_EffettuataConSuccesso={modificaDati_EffettuataConSuccesso}
            hidden={nascondiFeedbackCaricamento}
          />
        </BaseGridLayout>
      </BaseForm>
    </>
  )
}