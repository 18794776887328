import { bool, string } from 'prop-types'
import { Box } from '@mui/material'
import { BaseTesto } from 'text'

MessaggioNessunaRiga.propTypes = {
  messaggioNessunaRiga: string,
  datiModificabili: bool
}

export default function MessaggioNessunaRiga(props) {
  const {
    messaggioNessunaRiga,
    datiModificabili
  } = props

  const messaggioNessunaRiga_Definitivo = messaggioNessunaRiga
    || (datiModificabili ? 'Nessun dato inserito' : 'Nessun dato presente')
    
  return (
    <Box
      textAlign='center'
      p={2}
    >
      <BaseTesto style={{ fontStyle: 'italic' }}>
        {messaggioNessunaRiga_Definitivo}
      </BaseTesto>
    </Box>
  )
}
