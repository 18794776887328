import { useState, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { CircularProgress } from '@mui/material'
import { BaseTextInput, BaseAutocompletamentoLibero } from 'inputs'
import { BaseGridLayout } from 'layout'
import { OPERAZIONI_BASE } from '../../network/OperazioniBase'
import useRichiestaServer from '../../network/RichiestaServerHook'
import SceltaNazione from './SceltaNazione'
import { isItaly as isItaly_fun, ITALY } from './utilIndirizzo'

const minCaratteriRicercaPerCAP = 5
const minCaratteriRicercaPerNome = 3

export default function ComuneModifica(props) {
  const {
    nameCap = 'cap',
    nameComune = 'comune',
    nameProvincia = 'provincia',
    nameNazione = 'nazione',
    tipoRicercaNascita = false,
    nazioni,
    readOnly,
    nascondiCampoCap,
    larghezzaDefaultProvinciaENazione
  } = props

  const { watch, setValue, onChange } = useFormContext()
  const [capWatch, comuneWatch, provinciaWatch, nazioneWatch] = watch(
    [nameCap, nameComune, nameProvincia, nameNazione]
  )

  const isItaly = isItaly_fun(nazioneWatch)
  const {
    optionsComuni,
    pulisciOptionsComuni,
    inAttesaRicercaPerCAP,
    inAttesaRicercaPerNome,
    cercaComuniPerCAP,
    cercaComuniPerNome
  } = useRicercaComuni({
    tipoRicercaNascita,
    isItaly,
    readOnly
  })

  const [capInputValue, setCapInputValue] = useState(capWatch)
  const [comuneInputValue, setComuneInputValue] = useState(comuneWatch)

  function compilaCampiDaAutocompletamento(opzioneScelta) {
    const { cap, nome, provincia } = opzioneScelta.comuneCompleto
    setCapInputValue(cap)
    setComuneInputValue(nome)
    setValue(nameCap, cap)
    setValue(nameComune, nome)
    setValue(nameProvincia, provincia)
    setValue(nameNazione, ITALY)
    onChange()
    pulisciOptionsComuni()
  }

  const propsAutocompletamenti = {
    options: optionsComuni,
    onOpzioneScelta: compilaCampiDaAutocompletamento,
    onBlur: pulisciOptionsComuni,
    readOnly
  }

  const propsProvinciaENazione = {
    ...(!larghezzaDefaultProvinciaENazione && { larghezza: 12 }),
    ...(isItaly && { messaggioAiuto: ' ' }),
    readOnly
  }

  const shrinkProp = { InputLabelProps: { shrink: true } }

  const spinnerAdornment = {
    InputProps: {
      endAdornment: <CircularProgress size={25} color='secondary' />
    }
  }

  return (
    <BaseGridLayout alignCenter>
      <BaseAutocompletamentoLibero
        label='CAP' name={nameCap}
        propsAutocomplete={{
          inputValue: capInputValue,
          onInputChange: (_, capInputValue) => {
            setCapInputValue(capInputValue)
            cercaComuniPerCAP(capInputValue)
          }
        }}
        {...propsAutocompletamenti}
        {...(capWatch && shrinkProp)}
        {...(inAttesaRicercaPerCAP && spinnerAdornment)}
        {...(isItaly && {
          messaggioAiuto: `Suggerimenti con ${minCaratteriRicercaPerCAP} caratteri`
        })}
        larghezza={12} hidden={nascondiCampoCap}
      />

      <BaseAutocompletamentoLibero
        label='Comune' name={nameComune}
        propsAutocomplete={{
          inputValue: comuneInputValue,
          onInputChange: (_, comuneInputValue) => {
            setComuneInputValue(comuneInputValue)
            cercaComuniPerNome(comuneInputValue)
          }
        }}
        {...propsAutocompletamenti}
        {...(comuneWatch && shrinkProp)}
        {...(inAttesaRicercaPerNome && spinnerAdornment)}
        {...(isItaly && {
          messaggioAiuto: `Suggerimenti con ${minCaratteriRicercaPerNome} caratteri`
        })}
      />

      <BaseTextInput
        label='Provincia' name={nameProvincia}
        {...propsProvinciaENazione}
        {...(provinciaWatch && shrinkProp)}
      />

      <SceltaNazione
        label='Nazione' name={nameNazione}
        options={nazioni}
        {...propsProvinciaENazione}
        {...(nazioneWatch && shrinkProp)}
      />
    </BaseGridLayout>
  )
}



const NESSUNA_OPTION = []

function useRicercaComuni(props) {
  const { tipoRicercaNascita, isItaly, readOnly } = props

  /***** Gestione lista options comuni *****/

  const [optionsComuni, setOptionsComuni] = useState(NESSUNA_OPTION)

  function creaOptionComune(comuneCompleto) {
    const { cap, nome, provincia } = comuneCompleto
    return {
      value: cap,
      label: `${cap} ${nome} (${provincia})`,
      comuneCompleto
    }
  }

  function pulisciOptionsComuni() {
    setOptionsComuni(NESSUNA_OPTION)
  }

  useEffect(() => {
    if (!isItaly) pulisciOptionsComuni()
  }, [isItaly])

  /*****************************************/

  /***** Richiesta per cercare i comuni *****/

  const { inviaOperazione } = useRichiestaServer()

  async function cercaComuni(parametri, setInAttesa) {
    if (!isItaly || readOnly) return pulisciOptionsComuni()

    const tipo = tipoRicercaNascita ? 'nascita' : 'residenza'
    const { ok, data } = await inviaOperazione(
      OPERAZIONI_BASE.COMUNE_CERCA,
      { tipo, ...parametri },
      { setInAttesa }
    )

    if (ok) setOptionsComuni(data.comuni.map(creaOptionComune))
  }

  /******************************************/

  /***** Logiche ricerca per CAP o nome *****/

  const [inAttesaRicercaPerCAP, setInAttesaRicercaPerCAP] = useState(false)
  const [inAttesaRicercaPerNome, setInAttesaRicercaPerNome] = useState(false)

  async function cercaComuniPerCAP(cap) {
    // Se l'utente ha scritto meno di X caratteri, non ha senso fare la ricerca
    // In questo caso cancello anche eventuali risultati già presenti
    if (cap.length < minCaratteriRicercaPerCAP) return pulisciOptionsComuni()

    // Consento all'utente di continuare a scrivere per filtrare i risultati
    // Non carico altri risultati né cancello quelli già presenti
    if (cap.length > minCaratteriRicercaPerCAP) return

    // Faccio la ricerca con esattamente X caratteri
    cercaComuni({ cap }, setInAttesaRicercaPerCAP)
  }

  async function cercaComuniPerNome(comune) {
    // Se l'utente ha scritto meno di X caratteri, non ha senso fare la ricerca
    // In questo caso cancello anche eventuali risultati già presenti
    if (comune.length < minCaratteriRicercaPerNome) return pulisciOptionsComuni()

    // Consento all'utente di continuare a scrivere per filtrare i risultati
    // Non carico altri risultati né cancello quelli già presenti
    if (comune.length > minCaratteriRicercaPerNome) return

    // Faccio la ricerca con esattamente X caratteri
    cercaComuni({ comune }, setInAttesaRicercaPerNome)
  }

  /******************************************/

  return {
    optionsComuni,
    pulisciOptionsComuni,
    inAttesaRicercaPerCAP,
    inAttesaRicercaPerNome,
    cercaComuniPerCAP,
    cercaComuniPerNome
  }
}