import { bool, object, objectOf, shape, string } from 'prop-types'
import { forEachValue } from 'utils'

/**
 * @typedef {import("../../../../common/incloudApps/userManagement/userProvider/permessiUser/utilPermessiUser").ModuleShape} ModuleShape
 * @typedef {import("../../../../common/incloudApps/userManagement/userProvider/permessiUser/utilPermessiUser").setupModuliPerPermessi} setupModuliPerPermessi
 * @typedef {import("../../../../common/incloudApps/userManagement/userProvider/permessiUser/utilPermessiUser").ActionShape} ActionShape
 */

/**
 * @type {ActionShape}
 */
const AZIONE_SHAPE = shape({
  chiave: string.isRequired,
  label: string.isRequired,
  nascosta: bool,
  // MODULO_SHAPE ricorsivo. Proprietà aggiunta automaticamente usando setupModuliPerPermessi()
  moduloDiAppartenenza: object
})

/**
 * @type {ModuleShape}
 */
const MODULO_SHAPE = shape({
  chiave: string.isRequired,
  label: string.isRequired,
  azioni: objectOf(AZIONE_SHAPE)
})

const SEPARATORE_MODULO_AZIONE = ';'

/**
 * @type {import("../../../../common/incloudApps/userManagement/userProvider/permessiUser/utilPermessiUser").getSegmentiChiave}
 */
function getSegmentiChiave(chiave) {
  return chiave.split(SEPARATORE_MODULO_AZIONE)
}

/**
 * @type {import("../../../../common/incloudApps/userManagement/userProvider/permessiUser/utilPermessiUser").mergeSegmentiChiave}
 */
function mergeSegmentiChiave(...segmenti) {
  return segmenti.join(SEPARATORE_MODULO_AZIONE)
}

// Passare un oggetto con valori che hanno la struttura MODULO_SHAPE (descritta sopra)
/**
 * 
 * @param {{ [x: string]: ModuleShape}} moduli 
 * @type {setupModuliPerPermessi}
 */
function setupModuliPerPermessi(moduli) {
  forEachValue(moduli, moduloCorrente => {
    const { azioni } = moduloCorrente
    if (azioni) {
      forEachValue(azioni, azione => {
        azione.moduloDiAppartenenza = moduloCorrente
      })
    }
  })
  
  return moduli
}

/**
 * @type {import("../../../../common/incloudApps/userManagement/userProvider/permessiUser/utilPermessiUser").costruisciChiavePermesso}
 */
function costruisciChiavePermesso(moduloOAzione_Oggetto) {
  const isAzione = Boolean(moduloOAzione_Oggetto.moduloDiAppartenenza)
  if (isAzione) {
    const azione = moduloOAzione_Oggetto
    return mergeSegmentiChiave(azione.moduloDiAppartenenza.chiave, azione.chiave)
  }

  const modulo = moduloOAzione_Oggetto
  return modulo.chiave
}

/**
 * @type {import("../../../../common/incloudApps/userManagement/userProvider/permessiUser/utilPermessiUser").findModuloConChiave}
 */
function findModuloConChiave(moduli, chiaveModuloCercato) {
  if (!moduli || !chiaveModuloCercato) return null
  return Object.values(moduli).find(({ chiave }) => chiave === chiaveModuloCercato)
}

/**
 * @type {import("../../../../common/incloudApps/userManagement/userProvider/permessiUser/utilPermessiUser").findAzioneConChiave}
 */
function findAzioneConChiave(moduli, chiaveModuloDiAppartenenza, chiaveAzioneCercata) {
  if (!moduli || !chiaveModuloDiAppartenenza || !chiaveAzioneCercata) return null
  const moduloDiAppartenenza = findModuloConChiave(moduli, chiaveModuloDiAppartenenza)
  return Object.values(moduloDiAppartenenza.azioni).find(({ chiave }) => chiave === chiaveAzioneCercata)
}

// SOLO NUOVA GESTIONE
function aggiungiPrefissoChiaveModulo(chiaveModuloSenzaPrefisso) {
  return '$$MODULO$$_' + chiaveModuloSenzaPrefisso
}

export {
  AZIONE_SHAPE,
  MODULO_SHAPE,
  getSegmentiChiave,
  mergeSegmentiChiave,
  setupModuliPerPermessi,
  costruisciChiavePermesso,
  findModuloConChiave,
  findAzioneConChiave,
  aggiungiPrefissoChiaveModulo
}
