/* eslint-disable react/prop-types */
import { useCallback, useState, useEffect, useRef, useImperativeHandle } from 'react'
import { useHistory } from 'react-router-dom'
import { Box, CircularProgress, LinearProgress, Collapse } from '@mui/material'
import { BasePopoverConfermaOperazione } from 'feedback'
import { ICONE } from 'icons'
import { BaseButtons, BaseEditor, BaseForm, BaseIconButtons, BaseTextInput } from 'inputs'
import { BaseGridLayout, BaseScatolaConTitolo, useModal, BaseModal } from 'layout'
import { BaseCoppiaChiaveValore, BaseGrassetto, BaseTesto } from 'text'
import { convertBase64ToString, convertStringToBase64, debounce, calcolaFileNameConEstensione, deepClone, deepEquals, downloadFileBase64, costruisciRotta } from 'utils'
import CampiParams from '../../../form/campiParams/CampiParams'
import useCampiParams from '../../../form/campiParams/LogicaCampiParams'
import ElenchiManager from '../../../form/ElenchiManager'
import { useEntita } from '../../entitaGenerica/components/EntitaContext'
import { usePin } from '../../entitaGenerica/components/PinProvider'
import { OPERAZIONI_BASE } from '../../../network/OperazioniBase'
import useRichiestaServer from '../../../network/RichiestaServerHook'
import { useUser } from '../../../userManagement/userProvider/UserProvider'
import DownloadFile from '../../../util/DownloadFile'
import { isTemplateHtmlForm, trasformaAllegatoOutput } from '../utilDocumenti'
import { trasformaTestoHtmlInput, trasformaTestoHtmlOutput } from '../utilTestoHTML'
import FormHtmlDocumento from './FormHtmlDocumento'

const TIPI_OUTPUT = {
  HTML: 'html',
  PDF: 'pdf'
}

const NOMI = {
  TITOLO: 'allegato.titolo',
  DESCRIZIONE: 'allegato.descrizione',
  PARAMETRI: 'parametri',
  TESTO_HTML_HEAD: 'htmlCustom.first',
  TESTO_HTML_BODY: 'htmlCustom.second',
  PARAMETRI_HTML_FORM: 'parametriHtmlForm'
}

export default function FormGeneraDocumento(props) {
  const {
    template,
    documentoInput,
    salvaDocumentoGenerato,
    chiudiModal,
    infoEntitaPrincipale,
    inviaModificheInCoda,
    tuttoSalvato,
    calcolaOptions,
    toastDocumentoGeneratoRef,
    parametriFissi,
    parametriDefaultValues,
    readOnly,
    htmlFormPathSpecifico,
    pathDocumentoModifica,
    altezzaAnteprima = window.innerHeight - 170,
    formGeneraDocumentoRef,
    ...propsBaseForm
  } = props

  const documentoDefault = {
    allegato: {
      // Non metto l'uuid perché il documentoDefault viene usato
      // nel caso in cui il documento non è mai stato generato
      titolo: template.descr,
      descrizione: ''
    },
    template: template.nome,
    parametri: {},
    htmlCustom: { first: '', second: '' }
  }
  const documento = documentoInput || documentoDefault

  const logicaCampiParams = useCampiParams({
    params: template.params,
    prefissoNomi: NOMI.PARAMETRI,
    calcolaOptions,
    parametriFissi,
    parametriDefaultValues
  })

  const defaultValues = {
    ...documento,
    parametri: {
      ...(!isTemplateHtmlForm(template) && {
        ...logicaCampiParams.defaultValuesParams,
        ...documento.parametri,
        ...parametriFissi
      })
    }
  }

  useEffect(() => {
    if (!tuttoSalvato) inviaModificheInCoda({ forzaInvio: true })
  }, [tuttoSalvato])

  if (!tuttoSalvato) return <LinearProgress />

  return (
    <ElenchiManager
      elenchi={logicaCampiParams.elenchi}
      render={el =>
        <FormGeneraDocumento_Interna
          // Props calcolate in questo componente
          logicaCampiParams={logicaCampiParams}
          defaultValues={defaultValues}
          el={el}

          // Props provenienti dal padre
          template={template}
          salvaDocumentoGenerato={salvaDocumentoGenerato}
          chiudiModal={chiudiModal}
          infoEntitaPrincipale={infoEntitaPrincipale}
          toastDocumentoGeneratoRef={toastDocumentoGeneratoRef}
          readOnly={readOnly}
          htmlFormPathSpecifico={htmlFormPathSpecifico}
          pathDocumentoModifica={pathDocumentoModifica}
          altezzaAnteprima={altezzaAnteprima}
          formGeneraDocumentoRef={formGeneraDocumentoRef}
          {...propsBaseForm}
        />
      }
    />
  )
}



function FormGeneraDocumento_Interna(props) {
  const {
    logicaCampiParams,
    defaultValues,
    el,

    template,
    salvaDocumentoGenerato,
    chiudiModal,
    infoEntitaPrincipale,
    toastDocumentoGeneratoRef,
    readOnly,
    htmlFormPathSpecifico,
    pathDocumentoModifica,
    altezzaAnteprima,
    formGeneraDocumentoRef,
    ...propsBaseForm
  } = props

  const formRef = useRef()

  const isHtmlForm = isTemplateHtmlForm(template)

  /************ Chiamata di generazione ************/

  const {
    ScatolaMessaggi,
    inviaOperazione,
    inAttesaDelServer: inAttesaGenerazioneESalvataggio
  } = useRichiestaServer()

  async function generaDocumento(opzioni = {}) {
    await salvaParametriHtmlForm_SeNecessario()

    const {
      documento = formRef.current.getValues(),
      previewOnly = false,
      output = TIPI_OUTPUT.PDF,
      inputHtml = false,
      setInAttesa
    } = opzioni

    const {
      allegato,
      parametri,
      htmlCustom: { first: htmlCustomHead, second: htmlCustomBody } = {},
      parametriHtmlForm
    } = documento

    const corpoRichiesta = {
      entity: {
        uuid: infoEntitaPrincipale.uuid,
        entitaNome: infoEntitaPrincipale.nome
      },
      allegato: trasformaAllegatoOutput({
        ...defaultValues.allegato,
        ...allegato
      }),
      template: {
        id: 0,
        nome: template.nome,
        previewOnly,
        output
      },
      ...(inputHtml && {
        htmlB64: convertStringToBase64(
          trasformaTestoHtmlOutput(htmlCustomHead, htmlCustomBody)
        )
      }),
      params: (inputHtml || isHtmlForm) ? {} : parametri,
      ...(isHtmlForm && { parametriHtmlForm })
    }

    const opzioniRichiesta = { setInAttesa }

    const { ok, data } = await inviaOperazione(
      OPERAZIONI_BASE.DOCUMENTI_GENERA,
      corpoRichiesta,
      opzioniRichiesta
    )

    if (ok) return {
      allegatoGenerato: data.allegato,
      documentoDiPartenza: documento
    }

    return false
  }

  /*************************************************/

  /************** Gestione HTML form **************/

  const htmlFormRef = useRef()

  async function salvaParametriHtmlForm_SeNecessario() {
    if (isHtmlForm && !modificaManualeAttiva) {
      const parametriHtmlForm = await htmlFormRef.current.getParametriHtmlForm()
      if (parametriHtmlForm) {
        formRef.current.setValue(NOMI.PARAMETRI_HTML_FORM, parametriHtmlForm)
      }
    }
  }

  /************************************************/

  /************ Modifica manuale HTML ************/

  const [modificaManualeAttiva, setModificaManualeAttiva] = useState(
    Boolean(defaultValues.htmlCustom?.first) && Boolean(defaultValues.htmlCustom?.second)
  )
  const [inAttesaHtml, setInAttesaHtml] = useState(false)

  async function iniziaModificaManualeHtml() {
    const rispostaGenerazione = await generaDocumento({
      previewOnly: true,
      output: TIPI_OUTPUT.HTML,
      setInAttesa: setInAttesaHtml
    })
    if (!rispostaGenerazione) return
    const { allegatoGenerato } = rispostaGenerazione

    const testoHtmlCompleto = convertBase64ToString(allegatoGenerato.fileBase64)
    const { head, body } = trasformaTestoHtmlInput(testoHtmlCompleto)
    formRef.current.setValue(NOMI.TESTO_HTML_HEAD, head)
    formRef.current.setValue(NOMI.TESTO_HTML_BODY, body)
    setModificaManualeAttiva(true)
  }

  function annullaModificaManualeHtml() {
    formRef.current.setValue(NOMI.TESTO_HTML_HEAD, '')
    formRef.current.setValue(NOMI.TESTO_HTML_BODY, '')
    setModificaManualeAttiva(false)
  }

  // Valido subito + valido quando viene disabilitata la modifica manuale
  // In questo modo impedisco sempre all'utente di poter generare 
  // l'anteprima se ci sono errori di validazione dei parametri
  useEffect(() => {
    if (!modificaManualeAttiva) formRef.current.trigger()
  }, [modificaManualeAttiva])

  /***********************************************/

  /********** Generazione anteprima PDF **********/

  const [pdfBase64Anteprima, setPdfBase64Anteprima] = useState('')
  const [inAttesaAnteprima, setInAttesaAnteprima] = useState(false)

  // Variabili temporanee usate per resettare l'anteprima quando cambiano 
  // le informazioni di input del documento (parametri o testo html)
  const parametriUltimaAnteprima = useRef(null)
  const testoHtmlUltimaAnteprima = useRef('')

  async function generaAnteprimaPdf() {
    const rispostaGenerazione = await generaDocumento({
      previewOnly: true,
      inputHtml: modificaManualeAttiva,
      setInAttesa: setInAttesaAnteprima
    })
    if (!rispostaGenerazione) return
    const { allegatoGenerato, documentoDiPartenza } = rispostaGenerazione

    setPdfBase64Anteprima(allegatoGenerato.fileBase64)
    if (modificaManualeAttiva) {
      testoHtmlUltimaAnteprima.current = documentoDiPartenza.htmlCustom.second
    } else {
      parametriUltimaAnteprima.current = deepClone(documentoDiPartenza.parametri)
    }
  }

  const resetAnteprima = useCallback(() => {
    setPdfBase64Anteprima('')
    parametriUltimaAnteprima.current = null
    testoHtmlUltimaAnteprima.current = ''
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Quando viene inserita o disinserita la modifica manuale, resetto l'anteprima
  useEffect(resetAnteprima, [modificaManualeAttiva, resetAnteprima])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const resetAnteprima_SeInfoDocumentoSonoCambiate = useCallback(
    debounce(() => {
      const { parametri, htmlCustom } = formRef.current.getValues()

      const daResettare = modificaManualeAttiva
        ? testoHtmlUltimaAnteprima.current !== htmlCustom.second
        : !deepEquals(parametriUltimaAnteprima.current, parametri)

      if (daResettare) resetAnteprima()
    }, 500),
    [modificaManualeAttiva, resetAnteprima]
  )

  /***********************************************/

  /******** Salvataggio documento generato *********/

  async function salvaEScaricaDocumento() {
    const rispostaGenerazione = await generaDocumento({
      inputHtml: modificaManualeAttiva
    })
    if (!rispostaGenerazione) return
    let { allegatoGenerato, documentoDiPartenza } = rispostaGenerazione

    if (!allegatoGenerato.filename) {
      allegatoGenerato.filename = calcolaFileNameConEstensione(template.descr, allegatoGenerato.mimetype)
    }

    const { fileBase64, mimetype, filename } = allegatoGenerato
    downloadFileBase64(fileBase64, mimetype, filename)
    setTimeout(() => toastDocumentoGeneratoRef.current?.mostraToast(), 500)
    salvaDocumentoGenerato({
      ...documentoDiPartenza,
      template: template.nome,
      tipo: { ...documentoDiPartenza.tipo, first: template.docTipo ?? '' },
      allegato: { ...allegatoGenerato, fileBase64: '' },
      isBozza: false
    })
  }

  /*************************************************/

  /**** Salvataggio bozza (non genera documento) ****/

  const history = useHistory()

  const {
    funzioniAlbero: { trovaNodoAlberoConPath_PiuVicinoA },
    urlBase
  } = useEntita()

  const { impostaPin, togliPin, setBottonePinPremuto } = usePin()

  useEffect(() => {
    togliPin()
  }, [])

  async function salvaBozza() {
    await salvaParametriHtmlForm_SeNecessario()

    const documento = formRef.current.getValues()
    const allegato = trasformaAllegatoOutput({
      ...defaultValues.allegato,
      ...documento.allegato
    })

    salvaDocumentoGenerato({
      ...documento,
      template: template.nome,
      tipo: { ...documento.tipo, first: template.docTipo ?? '' },
      allegato: { ...allegato, fileBase64: '' },
      isBozza: true
    })

    impostaPin({
      path: pathDocumentoModifica,
      titolo: 'Documento in bozza',
      tooltip: template.descr,
      propsBottone: {
        testo: 'Torna al documento',
        onClick: () => {
          setTimeout(() => togliPin(), 2000)
          const nodoAlbero = trovaNodoAlberoConPath_PiuVicinoA(pathDocumentoModifica)
          if (!nodoAlbero?.urlPannello) return
          setBottonePinPremuto(true)
          history.push(costruisciRotta(urlBase, nodoAlbero.urlPannello))
          window.scrollTo(0, 0)
        }
      }
    })
  }

  const {
    openModal: openModal_ConfermaSalvaBozza,
    modalRef: modalRef_ConfermaSalvaBozza
  } = useModal()

  useImperativeHandle(formGeneraDocumentoRef, () => ({
    chiediConfermaSalvaBozza: openModal_ConfermaSalvaBozza
  }))

  async function confermaSalvaBozza() {
    await salvaBozza()
    chiudiModal()
  }

  function ignoraModificheBozza() {
    chiudiModal()
  }

  /**************************************************/

  const datiGeneraliDocumento = (
    <DatiGeneraliDocumento
      labelTemplate={template.descr}
      allegatoGeneratoInPrecedenza={defaultValues.allegato}
      isBozza={defaultValues.isBozza}
    />
  )

  return (
    <BaseForm
      defaultValues={defaultValues}
      onSubmit={salvaEScaricaDocumento}
      onChangeTuttiCampi={resetAnteprima_SeInfoDocumentoSonoCambiate}
      nonUsareFormTagHtml
      larghezzaInputs='fullWidth'
      inAttesa={inAttesaGenerazioneESalvataggio}
      ref={formRef}
      opzioniUseForm={{ mode: 'all' }}
      readOnly={readOnly}
      {...propsBaseForm}
    >
      {ScatolaMessaggi}

      <Box sx={{ height: altezzaAnteprima, mt: 0 }}>
        <Box sx={{ display: pdfBase64Anteprima ? 'none' : 'block', height: '90%' }}>
          {/*
            Il display inline-block serve per mostrare la barra di caricamento
            solo per la lunghezza del bottone e non tutta la scatola esterna
          */}
          <Box sx={{ display: 'inline-block' }}>
            {inAttesaAnteprima &&
              <CircularProgress size={25} sx={{ mr: 2, verticalAlign: 'middle' }} />
            }
            <BaseButtons.Custom
              testo='Mostra anteprima'
              color='warning'
              iconaInizio={ICONE.VISIBILE}
              submit
              inAttesa={false} // Gestisco l'attesa manualmente
              onClick={generaAnteprimaPdf}
              {...((readOnly || inAttesaAnteprima) && { disabled: true })}
            />
          </Box>
          {datiGeneraliDocumento}
          <FormParametriOModificaManualeHtml
            modificaManualeAttiva={modificaManualeAttiva}
            template={template}
            altezzaAnteprima={altezzaAnteprima}
            // Props per documenti con parametri tradizionali
            logicaCampiParams={logicaCampiParams}
            el={el}
            // Props per documenti html form
            chiudiModal={chiudiModal}
            htmlFormPathSpecifico={htmlFormPathSpecifico}
            pathDocumentoModifica={pathDocumentoModifica}
            htmlFormRef={htmlFormRef}
            salvaBozza={salvaBozza}
          />
        </Box>
        <Box sx={{ display: pdfBase64Anteprima ? 'block' : 'none', height: '90%' }}>
          <BaseButtons.Custom
            testo='Torna alla compilazione'
            color='warning'
            iconaInizio={ICONE.FRECCIA_INDIETRO}
            onClick={resetAnteprima}
          />
          {datiGeneraliDocumento}
          <AnteprimaPdfGenerato
            inAttesaAnteprima={inAttesaAnteprima}
            pdfBase64Anteprima={pdfBase64Anteprima}
            generaAnteprimaPdf={generaAnteprimaPdf}
            readOnly={readOnly}
            nascondiBottone
            defaultZoom={150}
          />
        </Box>
      </Box>

      <BaseGridLayout justifySpaceBetween sx={{ mt: 0 }}>
        <BottoneToggleModificaManuale
          modificaManualeAttiva={modificaManualeAttiva}
          inAttesaHtml={inAttesaHtml}
          iniziaModificaManualeHtml={iniziaModificaManualeHtml}
          annullaModificaManualeHtml={annullaModificaManualeHtml}
          readOnly={readOnly}
        />

        <BaseGridLayout>
          <BaseButtons.Salva
            testo='Salva bozza'
            onClick={salvaBozza}
            variant='outlined'
            {...(readOnly && { disabled: true })}
          />
          <BaseButtons.Salva
            testo='Salva e scarica documento'
            onClick={() => formRef.current.submit()}
            {...(readOnly && { disabled: true })}
          />
        </BaseGridLayout>
      </BaseGridLayout>

      <BaseModal
        titolo='Salvare la bozza?'
        ref={modalRef_ConfermaSalvaBozza}
      >
        <BaseGridLayout justifyCenter>
          <BaseButtons.Salva
            testo='Salva bozza'
            onClick={confermaSalvaBozza}
            {...(readOnly && { disabled: true })}
          />
          <BaseButtons.Annulla
            testo='Ignora modifiche'
            onClick={ignoraModificheBozza}
            color='default'
          />
        </BaseGridLayout>
      </BaseModal>
    </BaseForm>
  )
}



function DatiGeneraliDocumento(props) {
  const { labelTemplate, allegatoGeneratoInPrecedenza, isBozza } = props

  const [mostra, setMostra] = useState(false)

  const IconButton = mostra ? BaseIconButtons.EspandiMeno : BaseIconButtons.EspandiPiu

  return (
    <>
      <BaseTesto
        onClick={() => setMostra(mostra => !mostra)}
        color='textSecondary'
        sx={{ display: 'inline-block', ml: 2 }}
      >
        <BaseGrassetto sx={{ cursor: 'pointer' }}>
          Dati generali documento
        </BaseGrassetto>
        <IconButton />
      </BaseTesto>

      <Collapse in={mostra}>
        <BaseGridLayout vertical alignCenter spacing={0}>
          <BaseCoppiaChiaveValore
            chiave='Modello'
            valore={labelTemplate}
            sx={{ fontStyle: 'italic' }}
          />

          <BaseGridLayout>
            <BaseTextInput
              label='Titolo' name={NOMI.TITOLO}
              multiline rows={2} larghezza={20}
            />
            <BaseTextInput
              label='Descrizione' name={NOMI.DESCRIZIONE}
              multiline rows={2} larghezza={20}
            />
            {!isBozza && allegatoGeneratoInPrecedenza.uuid &&
              <BaseScatolaConTitolo titolo='Documento attuale' sx={{ mt: 2, p: 1 }}>
                <DownloadFile
                  file={allegatoGeneratoInPrecedenza}
                  maxLunghezzaNomeFileMostrato={20}
                />
              </BaseScatolaConTitolo>
            }
          </BaseGridLayout>
        </BaseGridLayout>
      </Collapse>
    </>
  )
}



function FormParametriOModificaManualeHtml(props) {
  const {
    modificaManualeAttiva,
    template,
    altezzaAnteprima,

    // Props per documenti con parametri
    logicaCampiParams,
    el,

    // Props per documenti html form
    chiudiModal,
    htmlFormPathSpecifico,
    pathDocumentoModifica,
    htmlFormRef,
    salvaBozza
  } = props

  const isTemplateHtml = isTemplateHtmlForm(template)

  return (
    <>
      <BaseTextInput name={NOMI.TESTO_HTML_HEAD} hidden />
      <BaseEditor
        name={NOMI.TESTO_HTML_BODY}
        containerSx={{ width: '100%', height: '100%' }}
        hidden={!modificaManualeAttiva}
        height={altezzaAnteprima - 110}
        attivaTitoli
        attivaListe
        attivaTabelle
        attivaAllineamenti
        attivaImmagini
        attivaFunzioniAvanzate={useUser().isUserStaff()}
      />

      <BaseScatolaConTitolo
        titolo={`Compilazione ${isTemplateHtml ? 'documento' : 'parametri'}`}
        sx={{
          p: 1,
          mt: 2,
          ...(modificaManualeAttiva
            ? { display: 'none' }
            : { height: '100%', boxSizing: 'border-box' }
          ),
          ...(!isTemplateHtml && { maxWidth: 800, mx: 'auto' })
        }}
      >
        {isTemplateHtml ? (
          <FormHtmlDocumento
            template={template}
            chiudiModal={chiudiModal}
            htmlFormPathSpecifico={htmlFormPathSpecifico}
            pathDocumentoModifica={pathDocumentoModifica}
            htmlFormRef={htmlFormRef}
            salvaBozza={salvaBozza}
          />
        ) : (
          <Box sx={{ height: '100%', overflowY: 'auto', p: 1, pt: 0, textAlign: 'left' }}>
            <CampiParams
              {...logicaCampiParams}
              el={el}
              testoSenzaParams='Questo modello di documento non richiede alcun parametro.'
            />
          </Box>
        )}
      </BaseScatolaConTitolo>
    </>
  )
}



export function AnteprimaPdfGenerato(props) {
  const {
    inAttesaAnteprima,
    pdfBase64Anteprima,
    generaAnteprimaPdf,
    readOnly,
    nascondiBottone,
    defaultZoom
  } = props

  return (
    <Box
      sx={{
        width: '100%', height: '100%',
        display: 'flex', alignItems: 'center', justifyContent: 'center',
      }}
    >
      {(() => {
        if (inAttesaAnteprima) return <CircularProgress />

        if (pdfBase64Anteprima) {
          const params = defaultZoom ? `#zoom=${defaultZoom}` : ''
          return (
            <iframe
              src={`data:application/pdf;base64,${pdfBase64Anteprima}${params}`}
              style={{ width: '100%', height: '100%' }}
            />
          )
        }

        if (nascondiBottone) return null

        return (
          <BaseButtons.Custom
            testo='Anteprima'
            color='warning'
            iconaInizio={ICONE.VISIBILE}
            submit
            inAttesa={inAttesaAnteprima}
            onClick={generaAnteprimaPdf}
            {...(readOnly && { disabled: true })}
          />
        )
      })()}
    </Box>
  )
}



function BottoneToggleModificaManuale(props) {
  const {
    modificaManualeAttiva,
    inAttesaHtml,
    iniziaModificaManualeHtml,
    annullaModificaManualeHtml,
    readOnly
  } = props

  // const { isUserStaff } = useUser()

  if (modificaManualeAttiva) {
    return (
      <BasePopoverConfermaOperazione
        messaggio='Le modifiche manuali apportate andranno perse.'
        onConferma={annullaModificaManualeHtml}
      >
        <BaseButtons.AnnullaModifiche
          testo='Annulla modifica manuale'
          color='secondary'
          {...(readOnly && { disabled: true })}
        />
      </BasePopoverConfermaOperazione>
    )
  }

  // NON SONO SICURO SE LIMITARE LA MODIFICA MANUALE A STAFF
  // if (!isUserStaff()) {
  //   return <></>
  // }

  return (
    <BasePopoverConfermaOperazione
      messaggio='Le modifiche apportate ai parametri andranno perse.'
      onConferma={iniziaModificaManualeHtml}
    >
      <BaseButtons.Modifica
        testo='Abilita modifica manuale'
        color='secondary'
        submit
        inAttesa={inAttesaHtml}
        {...(readOnly && { disabled: true })}
      />
    </BasePopoverConfermaOperazione>
  )
}