import { useEffect } from 'react'
import { string } from 'prop-types'
import { Box, Alert } from '@mui/material'
import { BasePopover, usePopover, BaseTooltip } from 'feedback'
import { ICONE } from 'icons'
import { BaseButtons, BaseCheckbox, BaseIconButtons } from 'inputs'
import { BaseGridLayout, BaseScatola } from 'layout'
import { BaseTesto, BaseTitolo } from 'text'
import { nonInProduzione, useStateWithLabel, PadreCondizionale } from 'utils'
import { useRoutes } from '../navigation/RoutesProvider'
import LogoComando from '../LogoComando'
import LabelComando from './LabelComando'
import { SceltaComandoAutocompletamento } from './SceltaComando'
import { useSsoIncloud } from './SsoProvider'
import UserAvatar from './UserAvatar'
import { useUser, useUserUpdate } from './userProvider/UserProvider'

/**
 * @typedef {import('../../common/incloudApps/userManagement/InfoUtente').InfoUtenteProps} InfoUtenteProps
 * @typedef {import('../../common/incloudApps/userManagement/InfoUtente').InfoUtente} InfoUtente
 */

InfoUtente.propTypes = {
  coloreLabelComando: string,
  coloreLabelUtente: string,
  coloreLabelLogout: string,
}

/**
 * 
 * @param {InfoUtenteProps} props 
 * @type InfoUtente
 */
export default function InfoUtente(props) {
  const {
    coloreLabelComando = '#ffffffee',
    coloreLabelUtente = '#ffffffee',
    coloreLabelLogout = '#ffffff'
  } = props

  const {
    getLabelUser,
    getLabelComando,
    getTipoComando,
    isUserLoggato,
    isUserConPiuComandiAbilitati,
    isUserStaff,
    comandoScelto,
    mostraCambioComando
  } = useUser()

  const {
    handleSceltaComando,
    setIsSessioneScaduta,
    setIsUserStaff,
    reloadLogin,
    ssoAbilitato
  } = useUserUpdate()

  const {
    popoverRef,
    openPopover,
    closePopover
  } = usePopover()

  const {
    popoverRef: popoverRefComando,
    openPopover: openPopoverComando,
    closePopover: closePopoverComando
  } = usePopover()

  const {
    popoverRef: popoverRefUtente,
    openPopover: openPopoverUtente,
    closePopover: closePopoverUtente
  } = usePopover()

  const { routesSenzaComando } = useRoutes()
  const ciSonoRotteSenzaComando = routesSenzaComando.flattened.length > 1

  // Accrocchio per testare i permessi utente
  // Una volta attivata la simulazione non staff, l'interfaccia viene visualizzata
  // come utente non staff. In questo modo si può vedere se l'impostazione di un
  // permesso utente ha avuto l'effetto desiderato. Uscire e rientrare dal comando
  // per assicurarsi di ricaricare i permessi aggiornati del comando
  const [
    simulazioneNonStaff_Attiva, setSimulazioneNonStaff_Attiva
  ] = useStateWithLabel(false, 'simulazioneNonStaff_Attiva')

  const isUserLoggato_Boolean = isUserLoggato()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setSimulazioneNonStaff_Attiva(false), [isUserLoggato_Boolean])

  if (!isUserLoggato_Boolean) return null

  const tipoComando = getTipoComando?.()
  const labelUser = getLabelUser()
  const haPiuCmdAbilitati = Boolean(isUserConPiuComandiAbilitati?.())

  const userComandoDescrizione = ssoAbilitato ? (
    <>
      <Box
        sx={{
          mr: 2,
          cursor: haPiuCmdAbilitati ? 'pointer' : 'auto',
          alignItems: 'center',
          display: 'inline-flex'
        }}
      >
        {isUserStaff() && comandoScelto && ciSonoRotteSenzaComando &&
          <BaseTooltip contenuto={'Entra nell\'area riservata a Staff per eseguire operazioni su tutti i comandi'}>
            <span>
              <BaseButtons.Custom
                testo={
                  <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                    Sovra Comando
                  </Box>
                }
                iconaInizio={ICONE.LOGO_INCLOUD_MINI}
                onClick={() => handleSceltaComando(null)}
                variant='outlined'
                sx={{
                  mr: 2,
                  color: coloreLabelLogout,
                  borderColor: coloreLabelLogout,
                  '&:hover': { backgroundColor: '#ffffff22', borderColor: coloreLabelLogout }
                }}
              />
            </span>
          </BaseTooltip>
        }

        {mostraCambioComando &&
          <PadreCondizionale
            abilitato={haPiuCmdAbilitati}
            componente={BaseButtons.Custom}
            onClick={openPopoverComando}
            variant='text'
            sx={{ '&:hover': { backgroundColor: '#ffffff22' } }}
          >
            <>
              <Box
                sx={{
                  mr: 1,
                  color: coloreLabelComando,
                  display: { xs: 'none', sm: 'none', md: 'block' },
                  textAlign: 'right',
                  lineHeight: tipoComando ? '0.8rem' : '0.7rem'
                }}
              >
                {!tipoComando
                  ? ''
                  : <div style={{ fontSize: '0.8rem', color: coloreLabelComando + '8a' }}>{tipoComando} di</div>
                }
                <b>{getLabelComando()}</b>
              </Box>

              <LogoComando
                displayNoCmd={true}
                wrapperStyle={{
                  width: '36px',
                  height: '36px',
                  margin: '0px 4px 0 0'
                }}
                style={{
                  borderRadius: '100px',
                  backgroundColor: '#fff',
                  padding: '3px',
                  border: '1px solid #ccc',
                  marginRight: '3px'
                }}
              />
            </>
          </PadreCondizionale>
        }

        <BaseButtons.Custom
          onClick={openPopoverUtente}
          variant='text'
          sx={{ '&:hover': { backgroundColor: '#ffffff11' } }}
        >
          <>
            <Box
              sx={{
                mr: 1,
                color: coloreLabelUtente,
                display: { xs: 'none', sm: 'none', md: 'block' },
                textAlign: 'right',
                lineHeight: '0.8rem',
                textTransform: 'capitalize'
              }}
            >
              {labelUser}
            </Box>

            <UserAvatar />
          </>
        </BaseButtons.Custom>
      </Box>

      <LogoutSsoBtn coloreLabelLogout={coloreLabelLogout} />
    </>
  ) : (
    <>
      <BaseTesto onClick={openPopover} sx={{ cursor: 'pointer' }}>
        {labelUser}
        {getLabelComando &&
          <>
            {' / '} <LabelComando sx={{ color: coloreLabelComando }} />
          </>
        }
      </BaseTesto>

      <BaseIconButtons.AccountCerchio onClick={openPopover} />
    </>
  )

  return (
    <>
      {simulazioneNonStaff_Attiva &&
        <BaseTesto
          sx={{
            fontWeight: 'fontWeightBold',
            fontSize: '1.25em',
            mr: 2,
            px: 1,
            py: 0,
            border: 2,
            borderColor: 'common.white',
            borderRadius: 1
          }}
        >
          Simulazione NO STAFF attiva
        </BaseTesto>
      }

      {userComandoDescrizione}

      <BasePopover
        ref={popoverRefComando}
        style={{ maxWidth: '60rem' }}
        PaperProps={{ style: { height: '100%' } }}
      >
        <BaseScatola
          style={{ height: '100%', width: '350px' }}
          centraContenuto
          onClick_BottoneChiudi={closePopoverComando}
          propsBottoneChiudi={{ left: 0 }}
        >
          <BaseGridLayout vertical spacing={0}>
            {isUserConPiuComandiAbilitati?.() &&
              <SceltaComandoAutocompletamento
                onChange={comando => {
                  handleSceltaComando(comando)
                  closePopover()
                }}
                listSx={{ overflow: 'auto', maxHeight: 'calc(100vh - 210px)' }}
                larghezza={25}
                value={comandoScelto}
                ultimoSceltoProps={{ sx: { p: 0, mt: 0, mb: 2 } }}
              />
            }
          </BaseGridLayout>
        </BaseScatola>
      </BasePopover>

      <BasePopover
        ref={popoverRefUtente}
        style={{ maxWidth: '60rem' }}
        PaperProps={{ style: { height: '100%' } }}
      >
        <BaseScatola
          style={{ height: '100%', width: '350px' }}
          centraContenuto
          onClick_BottoneChiudi={closePopoverUtente}
          propsBottoneChiudi={{ left: 0 }}
        >
          <BaseGridLayout vertical spacing={0}>
            <UserAvatar dimensione='large' sx={{ margin: '8px auto' }} />

            <BaseTitolo>Gestione profilo</BaseTitolo>
            <BaseTesto>Benvenuto <b>{labelUser}</b></BaseTesto>

            <Alert severity='info'>
              In questa sezione verranno visualizzati messaggi e link alla
              gestione della propria utenza
            </Alert>

            {ssoAbilitato && <LogoutSsoBtn sx={null} />}

            {nonInProduzione() &&
              <>
                <BaseButtons.Aggiorna
                  testo='Riloggati'
                  color='success'
                  onClick={reloadLogin}
                />

                {(isUserStaff() || simulazioneNonStaff_Attiva) &&
                  <BaseCheckbox
                    label='Simula NO STAFF'
                    switch
                    value={simulazioneNonStaff_Attiva}
                    onChange={e => {
                      setSimulazioneNonStaff_Attiva(e.target.value)
                      setIsUserStaff(!e.target.value)
                    }}
                  />
                }
              </>
            }

            {/*ssoAbilitato && <SsoApps  style={{height:'calc(100vh - 430px)', overflowY: 'scroll', width:'324px'}}/>*/}
          </BaseGridLayout>
        </BaseScatola>
      </BasePopover>


      <BasePopover
        ref={popoverRef}
        style={{ maxWidth: '60rem' }}
        PaperProps={{ style: { height: '100%' } }}
      >
        <BaseScatola
          style={{ height: '100%', width: '350px' }}
          centraContenuto
          onClick_BottoneChiudi={closePopover}
        >
          <BaseGridLayout vertical spacing={0}>
            {isUserConPiuComandiAbilitati?.() &&
              <SceltaComandoAutocompletamento
                onChange={comando => {
                  handleSceltaComando(comando)
                  closePopover()
                }}
                larghezza={25}
                value={comandoScelto}
                ultimoSceltoProps={{ sx: { p: 0, mt: 0, mb: 2 } }}
              />
            }

            <BaseButtons.Logout
              onClick={() => {
                closePopover()
                setIsSessioneScaduta(true)
              }}
            />

            {nonInProduzione() &&
              <>
                <BaseButtons.Aggiorna
                  testo='Riloggati'
                  color='success'
                  onClick={reloadLogin}
                />

                {(isUserStaff() || simulazioneNonStaff_Attiva) &&
                  <BaseCheckbox
                    label='Simula NO STAFF'
                    switch
                    value={simulazioneNonStaff_Attiva}
                    onChange={e => {
                      setSimulazioneNonStaff_Attiva(e.target.value)
                      setIsUserStaff(!e.target.value)
                    }}
                  />
                }
              </>
            }
          </BaseGridLayout>
        </BaseScatola>
      </BasePopover>
    </>
  )
}



function LogoutSsoBtn(props) {
  const {
    coloreLabelLogout,
    testo = <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>Logout</Box>,
    variant = 'outlined',
    sx = {
      color: coloreLabelLogout,
      borderColor: coloreLabelLogout,
      '&:hover': { backgroundColor: '#ffffff22', borderColor: coloreLabelLogout }
    }
  } = props

  const { logout } = useSsoIncloud()

  const logoutEpulisci = () => {
    localStorage.clear()
    sessionStorage.clear()
    logout(window.location.href)
  }

  return (
    <BaseButtons.Custom
      sx={sx}
      testo={testo}
      iconaInizio={ICONE.LOGOUT}
      variant={variant}
      onClick={logoutEpulisci}
    />
  )
}



/* ESPERIMENTO MODULI TRAMITE IFRAME BRUTTO BRUTTO
function SsoApps(props) {
  const {  URL_SSO } = useRoutes()
  return (<iframe
    src={`${URL_SSO}apps`}
    frameBorder='0'
    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
    allowfullscreen
    {...props}
  ></iframe>)
}
*/