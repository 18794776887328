function contieneTutteLeParole(stringaInCuiCercare, stringaConParoleCercate) {
  stringaInCuiCercare = stringaInCuiCercare.toLowerCase()
  stringaConParoleCercate = stringaConParoleCercate.toLowerCase()
  
  let words = stringaConParoleCercate.split(' ').filter(e => e)
  const regexMetachars = /[(){[*+?.\\^$|]/g

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].replace(regexMetachars, '\\$&')
    const regex = new RegExp(words[i], 'g')
    if ((stringaInCuiCercare.match(regex) || []).length == 0) {
      return false
    }
    stringaInCuiCercare = stringaInCuiCercare.replace(words[i])
  }

  return true
}

function contieneAlmenoUnaParola(stringaInCuiCercare, stringaConParoleCercate) {
  stringaInCuiCercare = stringaInCuiCercare.toLowerCase()
  stringaConParoleCercate = stringaConParoleCercate.toLowerCase()
  
  let words = stringaConParoleCercate.split(' ').filter(e => e)
  const regexMetachars = /[(){[*+?.\\^$|]/g

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].replace(regexMetachars, '\\$&')
    const regex = new RegExp(words[i], 'g')
    if ((stringaInCuiCercare.match(regex) || []).length > 0) {
      return true
    }
    stringaInCuiCercare = stringaInCuiCercare.replace(words[i])
  }

  return false
}

export {
  contieneTutteLeParole,
  contieneAlmenoUnaParola
}