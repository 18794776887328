import { useState } from 'react'
import { downloadFileBase64 } from 'utils'
import { OPERAZIONI_BASE } from '../../network/OperazioniBase'
import useRichiestaServer from '../../network/RichiestaServerHook'

const OPTIONS_FORMATO_ESPORTAZIONE = [
  { value: 'zip', label: 'Formato ZIP' },
  { value: 'pdf1', label: 'Formato PDF - 1 immagine per pagina' },
  { value: 'pdf2', label: 'Formato PDF - 2 immagini per pagina' }
]

export default function useEsportaImmagini(props) {
  const {
    immaginiDaEsportare,
    nomeSelezioneDaSalvare,
    formatoEsportazione,
    infoExtraPerEsportazione,
    terminaEsportazione
  } = props

  const {
    ScatolaMessaggi,
    inviaOperazione,
    inAttesaDelServer
  } = useRichiestaServer()

  async function generaESalvaEsportazione() {
    const paramsComuni = {
      immaginiUUIDs: immaginiDaEsportare.map(({ uuid }) => uuid),
      ...(nomeSelezioneDaSalvare && { nomeSelezione: nomeSelezioneDaSalvare }),
      ...infoExtraPerEsportazione
    }

    let opId
    let corpoRichiesta
    if (formatoEsportazione === 'zip') {
      opId = OPERAZIONI_BASE.ESPORTAZIONE_IMMAGINI_ZIP
      corpoRichiesta = paramsComuni
    } else {
      opId = OPERAZIONI_BASE.ESPORTAZIONE_IMMAGINI_PDF
      corpoRichiesta = {
        ...paramsComuni,
        imgSize: formatoEsportazione === 'pdf1' ? '750px' : '375px'
      }
    }

    const { ok, data } = await inviaOperazione(opId, corpoRichiesta)

    if (ok) {
      const { base64, contentType, filename } = data
      downloadFileBase64(base64, contentType, filename)
      terminaEsportazione?.()
    }

    return { ok }
  }

  const [selezioniPassate, setSelezioniPassate] = useState([])

  async function fetchSelezioniEsportazioniPassate() {
    const { ok, data } = await inviaOperazione(
      OPERAZIONI_BASE.ESPORTAZIONE_IMMAGINI_SELEZIONI_PASSATE,
      infoExtraPerEsportazione
    )

    if (ok) setSelezioniPassate(data.selezioniPassate)
  }

  return {
    generaESalvaEsportazione,
    inAttesaEsportazione: inAttesaDelServer,
    ScatolaMessaggi,
    optionsFormatoEsportazione: OPTIONS_FORMATO_ESPORTAZIONE,
    selezioniPassate,
    fetchSelezioniEsportazioniPassate
  }
}