import { haAlmenoUnaChiave } from 'utils'

// Riceve un array di regole nel formato { nome, messaggio, funzione } e ritorna 2 oggetti con le stesse chiavi:
// - messaggi: contiene i messaggi di errore
// - validate: contiene le regole custom
function costruisciMessaggiERegoleValidazione(regoleCustom) {
  let messaggi = {}
  let validate = {}

  regoleCustom.forEach(({ nome, messaggio, funzione }) => {
    messaggi[nome] = messaggio
    validate[nome] = funzione
  })

  return { messaggi, validate }
}

// Restituisce un oggetto che contiene 2 oggetti:
// - validazione: validazione originale unita ad un oggetto validate che contiene le regole custom
// - messaggiErrore: messaggi errore originali uniti ai messaggi custom
function unisciMessaggiERegoleValidazione(
  validazioneOriginale = {},
  validateCustom = {},
  messaggiOriginali = {},
  messaggiCustom = {}
) {
  let oggettoDaRitornare = {}

  const messaggiErrore = { ...messaggiOriginali, ...messaggiCustom }
  if (haAlmenoUnaChiave(messaggiErrore)) {
    oggettoDaRitornare.messaggiErrore = messaggiErrore
  }

  const validateOriginale = validazioneOriginale.validate || {}
  const validazione = {
    ...validazioneOriginale,
    validate: {
      ...validateOriginale,
      ...validateCustom
    }
  }
  if (haAlmenoUnaChiave(validazione)) {
    oggettoDaRitornare.validazione = validazione
  }

  return oggettoDaRitornare
}

export {
  costruisciMessaggiERegoleValidazione,
  unisciMessaggiERegoleValidazione
}