import { BaseContenutoConTitoloEFallback } from 'layout'
import { BaseListaCoppieChiaveValore } from 'text'
import { formattaData } from 'utils'

export default function Revisione(props) {
  const {
    revisione: { dataRevisione, dataScadenza }
  } = props

  return (
    <BaseContenutoConTitoloEFallback
      datiPresentiSe={dataRevisione || dataScadenza}
      titolo='Revisione'
      propsTitolo={{ colorato: true }}
    >
      <BaseListaCoppieChiaveValore
        coppie={{
          'Data della revisione': formattaData(dataRevisione),
          'Data di scadenza': formattaData(dataScadenza)
        }}
      />
    </BaseContenutoConTitoloEFallback>
  )
}