import { string, shape, bool, object, number } from 'prop-types'

BaseImmagine.propTypes = {
  immagine: shape({
    titolo: string,
    src: shape({
      fullSize: string,
      thumbnail: string
    }).isRequired
  }).isRequired,
  thumbnail: bool,
  inputRef: object,
  maxWidth: number,
  minWidth: number,
  maxHeight: number,
  minHeight: number
}

export const MAX_WIDTH_IMMAGINE = 1000
export const MAX_HEIGHT_IMMAGINE = 600

export default function BaseImmagine(props) {
  const {
    immagine: { titolo, src },
    thumbnail: mostraThumbnail,
    inputRef,
    maxWidth = MAX_WIDTH_IMMAGINE,
    minWidth,
    maxHeight = MAX_HEIGHT_IMMAGINE,
    minHeight,
    ...restProps
  } = props

  const propsImmagine = mostraThumbnail ? {
    src: src.thumbnail,
    height: 125
  } : {
    src: src.fullSize,
    style: {
      maxWidth,
      minWidth,
      maxHeight,
      minHeight
    }
  }

  return (
    <img
      alt={titolo}
      ref={inputRef}
      {...propsImmagine}
      {...restProps}
    />
  )
}