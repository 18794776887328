import { string, bool, func } from 'prop-types'
import { BaseIconButtons } from 'inputs'
import { BaseGridLayout } from 'layout'
import { BaseTitolo } from 'text'
import { useStateWithLabel } from 'utils'
import ImpostazioneSolaLettura from './ImpostazioneSolaLettura'
import FormModificaImpostazione from './FormModificaImpostazione'

SingolaImpostazione.propTypes = {
  desc: string.isRequired,
  descLunga: string,
  readOnly: bool,
  ComponenteFormModificaImpostazione: func, // Riceve tutte le props
  // Accetta anche tutte le props di ImpostazioneSolaLettura e FormModificaImpostazione
}

export default function SingolaImpostazione(props) {
  const {
    desc,
    descLunga,
    readOnly,
    ComponenteFormModificaImpostazione = FormModificaImpostazione
  } = props

  const [modificaAttiva, setModificaAttiva] = useStateWithLabel(false, 'modificaAttiva')

  function mostraBottoneModifica() {
    if (readOnly) return false
    return !modificaAttiva
  }

  return (
    <>
      <BaseGridLayout>
        <BaseTitolo noMargine>
          {desc}
          {descLunga &&
            <BaseIconButtons.Aiuto
              contenutoTooltip={descLunga}
              tooltipProps={{ placement: 'bottom' }}
            />
          }
        </BaseTitolo>
        {mostraBottoneModifica() &&
          <BaseIconButtons.Modifica
            onClick={() => setModificaAttiva(true)}
            color='primary'
            bordo
          />
        }
      </BaseGridLayout>

      {!modificaAttiva ? (
        <ImpostazioneSolaLettura {...props} />
      ) : (
        <ComponenteFormModificaImpostazione
          annullaModifiche={() => setModificaAttiva(false)}
          {...props}
        />
      )}
    </>
  )
}