import { BaseTesto } from '@lucan/base-ui'
import { Alert, Fade } from '@mui/material'
import { string, shape } from 'prop-types'

MessageBox.propTypes = {
  errorMsg:shape({main:string,secondary:string}),
  severity:string
}

export default function MessageBox(props) {
  const {
    errorMsg,
    severity="error",
    fadeIn=true
  } = props
  
  if(!errorMsg) return null
  const {
    main = '',secondary=''
  } = errorMsg
  return (
    <Fade in={fadeIn} timeout={750}><Alert severity={severity} style={{textAlign:'left'}}><BaseTesto>{main}</BaseTesto>{secondary} </Alert></Fade>
  )
}