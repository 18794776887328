import { string } from 'prop-types'
import { Alert } from '@mui/material'

MessaggioPermessoSolaLettura.propTypes = {
  messaggio: string
}

export default function MessaggioPermessoSolaLettura(props) {
  const {
    messaggio = 'Contenuto visualizzato in sola lettura. Non si hanno i permessi per effettuare modifiche.'
  } = props

  return (
    <Alert
      elevation={5}
      variant='filled'
      severity='warning'
    >
      {messaggio}
    </Alert>
  )
}
