import { number } from 'prop-types'
import { LinearProgress, Box } from '@mui/material'
import { BaseTitolo } from 'text'
import { sx } from 'utils'

BaseLinearProgressConPercentuale.propTypes = {
  percentualeCompletata: number.isRequired
}

export default function BaseLinearProgressConPercentuale(props) {
  const { percentualeCompletata, ...restProps } = props

  return (
    <>
      <LinearProgress
        variant='determinate'
        value={percentualeCompletata}
        {...restProps}
        {...sx(restProps, {
          height: 10,
          borderRadius: 1,
          '& .MuiLinearProgress-barColorPrimary': { bgcolor: 'success.main' }
        })}
      />

      <Box textAlign='center'>
        <BaseTitolo>{Math.round(percentualeCompletata)} %</BaseTitolo>
      </Box>
    </>
  )
}