import { string, oneOf } from 'prop-types'
import { BaseTooltip } from 'feedback'
import { BaseTestoConRef, BaseGrassetto } from 'text'
import { sx } from 'utils'

HeaderSingolo.propTypes = {
  testo: string.isRequired,
  testoNonGrassetto: string,
  textAlign: oneOf(['left', 'right']),
  spiegazione: string
}

export default function HeaderSingolo(props) {
  const {
    testo,
    testoNonGrassetto,
    textAlign,
    spiegazione,
    ...propBaseTesto
  } = props

  const headerElement = (
    <BaseTestoConRef
      textAlign={textAlign}
      {...propBaseTesto}
      {...sx(propBaseTesto, {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        ...(spiegazione && { cursor: 'help' })
      })}
    >
      <BaseGrassetto>{testo}</BaseGrassetto>
      {testoNonGrassetto && ` ${testoNonGrassetto}`}
    </BaseTestoConRef>
  )

  if (spiegazione) {
    return (
      <BaseTooltip contenuto={spiegazione} placement='top'>
        {headerElement}
      </BaseTooltip>
    )
  }

  return headerElement
}
