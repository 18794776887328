import { useState } from 'react'
import { string, node, arrayOf, shape, number, func, object } from 'prop-types'
import { Tabs, Tab, Box } from '@mui/material'
import { sx } from 'utils'

BaseTabs.propTypes = {
  tabs: arrayOf(shape({
    titolo: string.isRequired,
    contenuto: node.isRequired
  })).isRequired,

  // Passando queste 2 prop, il tab visibile può essere 
  // controllato completamente dal padre
  indiceTabVisibile: number,
  onChangeTab: func,

  propsTitoloTab: object,
  propsContenutoTab: object
}

export default function BaseTabs(props) {
  const {
    tabs,
    indiceTabVisibile: indiceTabVisibile_Prop,
    onChangeTab,
    propsTitoloTab,
    propsContenutoTab,
    ...restProps
  } = props

  const [
    indiceTabVisibile_StatoInterno, setIndiceTabVisibile_StatoInterno
  ] = useState(indiceTabVisibile_Prop ?? 0)

  // Tab visibile impostato dal padre vince sullo stato interno
  const indiceTabVisibile_Definitivo = indiceTabVisibile_Prop ?? indiceTabVisibile_StatoInterno

  return (
    <>
      <Tabs
        value={indiceTabVisibile_Definitivo}
        onChange={(_, nuovoIndice) => {
          setIndiceTabVisibile_StatoInterno(nuovoIndice)
          onChangeTab?.(nuovoIndice)
        }}
        indicatorColor='primary'
        {...restProps}
      >
        {tabs.map(({ titolo }) =>
          <Tab
            label={titolo}
            disableRipple
            key={titolo}
            {...propsTitoloTab}
            {...sx(propsTitoloTab, { maxWidth: 'inherit' })}
          />
        )}
      </Tabs>

      {tabs.map(({ titolo, contenuto }, indice) =>
        <TabPanel
          indice={indice}
          indiceTabVisibile={indiceTabVisibile_Definitivo}
          key={titolo}
          {...propsContenutoTab}
        >
          {contenuto}
        </TabPanel>
      )}
    </>
  )
}

function TabPanel(props) {
  const {
    children,
    indiceTabVisibile,
    indice,
    propsBox,
    ...other
  } = props

  return (
    <div
      hidden={indiceTabVisibile !== indice}
      {...other}
    >
      <Box padding={3} {...propsBox}>
        {children}
      </Box>
    </div>
  )
}
