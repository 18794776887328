import { arrayOf, bool, shape, string } from 'prop-types'
import { Collapse } from '@mui/material'
import { BaseIconButtons } from 'inputs'
import { BaseGridLayout } from 'layout'
import { useStateWithLabel } from 'utils'
import BaseLinkConIcona from './BaseLinkConIcona'
import BaseNavigationListItem from './BaseNavigationListItem'

BaseLinkPrimoLivello.propTypes = {
  route: shape({
    subRoutes: arrayOf(shape({ pathAssoluto: string }))
  }).isRequired,
  disableTooltipIcona: bool,
  disableSubListOpen: bool
}

const paddingSubRoutes = 1.5

export default function BaseLinkPrimoLivello(props) {
  const {
    route: { subRoutes = [] },
    sx_ListItemNavigazione,
    disableTooltipIcona,
    disableSubListOpen,
    ...restProps
  } = props

  const [subListOpen, setSubListOpen] = useStateWithLabel(true, 'subListOpen')

  function toggleSubListOpen() {
    setSubListOpen(subListOpen => !subListOpen)
  }

  const CollapseToggleButton = subListOpen ? BaseIconButtons.EspandiMeno : BaseIconButtons.EspandiPiu

  return (
    <>
      <BaseNavigationListItem sx={sx_ListItemNavigazione} route={props.route} {...restProps}>
        <BaseGridLayout spacing={0} justifySpaceBetween alignCenter noWrap>
          <BaseLinkConIcona
            route={props.route}
            disableTooltipIcona={disableTooltipIcona}
          />

          {subRoutes.length > 0 &&
            <CollapseToggleButton
              onClick={toggleSubListOpen}
              tooltipProps={{ placement: 'top' }}
              noMarginY
            />
          }
        </BaseGridLayout>
      </BaseNavigationListItem>

      <Collapse
        in={!disableSubListOpen && subListOpen}
        unmountOnExit
        timeout='auto'
        sx={({ spacing }) => ({
          pl: paddingSubRoutes,
          width: `calc(100% - ${spacing(paddingSubRoutes)})`,
          whiteSpace: 'normal'
        })}
      >
        {subRoutes.map(route =>
          <BaseNavigationListItem
            sx={sx_ListItemNavigazione}
            key={route.pathAssoluto}
            route={route}
          >
            <BaseLinkConIcona
              route={route}
              isSubRoute
              disableTooltipIcona={disableTooltipIcona}
            />
          </BaseNavigationListItem>
        )}
      </Collapse>
    </>
  )
}