import { filterRoutes, nonInProduzione } from 'utils'
import { useRoutes } from '../../navigation/RoutesProvider'
import { UserContext, useUser } from './UserProvider'

/**
 * @typedef {import('../../../common/incloudApps/userManagement/userProvider/UserProviderConPermessiRoutes').UserProviderConPermessiRoutesProps} UserProviderConPermessiRoutesProps
 * @typedef {import('../../../common/incloudApps/userManagement/userProvider/UserProviderConPermessiRoutes').UserProviderConPermessiRoutes} UserProviderConPermessiRoutes
 */

/**
 * @type {UserProviderConPermessiRoutes}
 */
export default function UserProviderConPermessiRoutes(props) {
  const { children } = props

  const { routesNonLoggato } = useRoutes()

  const letturaInfoUser = useUser()
  const {
    isUserLoggato,
    getRoutesLoggatoAttuali,
    userPuoVedere_AlmenoUnModuloTra,
    userPuoVedere_AlmenoUnPermessoTra, // SOLO NUOVA GESTIONE
    isUserStaff,
    isAdmin,
    isAdminAlmenoUnComando,
    nuovaGestionePermessi
  } = letturaInfoUser

  let routesVisibiliNeiMenu_VisibiliDalloUser
  let routesFlattened_VisibiliDalloUser

  if (isUserLoggato()) {
    const filtroPermessiUser = rotta => {
      const {
        moduli, // SOLO VECCHIA GESTIONE
        chiaviModuli, // SOLO NUOVA GESTIONE
        chiaviPermessi, // SOLO NUOVA GESTIONE
        soloStaff,
        soloAdmin,
        soloAdminConComando,
        soloVecchiaGestionePermessi,
        soloSviluppo
      } = rotta

      if (soloSviluppo && !nonInProduzione()) return false
      if (soloVecchiaGestionePermessi && nuovaGestionePermessi) return false
      if (soloStaff) return isUserStaff()

      const isRottaVisibile = nuovaGestionePermessi
        // - no moduli, no permessi --> posso vedere
        // - si moduli, no permessi --> posso vedere se vedo almeno un modulo
        // - no moduli, si permessi --> posso vedere se vedo almeno un permesso
        // - si moduli, si permessi --> posso vedere se vedo almeno un modulo E vedo almeno un permesso
        ? userPuoVedere_AlmenoUnModuloTra(chiaviModuli) && userPuoVedere_AlmenoUnPermessoTra(chiaviPermessi)
        : userPuoVedere_AlmenoUnModuloTra(moduli)

      if (soloAdmin) return isRottaVisibile && isAdmin()
      if (soloAdminConComando) return isRottaVisibile && isAdminAlmenoUnComando()
      return isRottaVisibile
    }

    const routesLoggatoAttuali = getRoutesLoggatoAttuali()
    routesVisibiliNeiMenu_VisibiliDalloUser = filterRoutes(
      routesLoggatoAttuali.visibiliNeiMenu,
      filtroPermessiUser
    )
    routesFlattened_VisibiliDalloUser = filterRoutes(
      routesLoggatoAttuali.flattened,
      filtroPermessiUser
    )
  } else {
    routesVisibiliNeiMenu_VisibiliDalloUser = routesNonLoggato.visibiliNeiMenu
    routesFlattened_VisibiliDalloUser = routesNonLoggato.flattened
  }

  const letturaInfoUser_ConPermessiRoutes = {
    ...letturaInfoUser,
    routesVisibiliNeiMenu_VisibiliDalloUser,
    routesFlattened_VisibiliDalloUser
  }

  return (
    <UserContext.Provider value={letturaInfoUser_ConPermessiRoutes}>
      {children}
    </UserContext.Provider>
  )
}