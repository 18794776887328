import { IconButton, styled } from '@mui/material'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'

/**
 * @typedef {import("../common/inputs/BaseIconButtonRounded").BaseIconButtonRoundedProps} BaseIconButtonRoundedProps 
 * @typedef {import("../common/inputs/BaseIconButtonRounded").BaseIconButtonRounded} BaseIconButtonRounded 
 */

const IconButtonStyled = styled(IconButton)(({ theme, color = 'default' }) => {
  if (color === 'default' || color === 'inherit') {
    return
  } else {
    return {
      backgroundColor: theme.palette[color].main,
      color: theme.palette[color].contrastText,
      '&:hover': {
        backgroundColor: theme.palette[color].dark
      }
    }
  }
})

/**
 * 
 * @param {BaseIconButtonRoundedProps} props 
 * @type {BaseIconButtonRounded}
 */
const BaseIconButtonRounded = forwardRef((props, ref) => {

  const { children, ...otherButtonProps } = props

  return (<IconButtonStyled ref={ref} {...otherButtonProps}>{children}</IconButtonStyled>)
})

BaseIconButtonRounded.propTypes = {
  ...IconButtonStyled.propTypes,
  children: PropTypes.node
}


export default BaseIconButtonRounded