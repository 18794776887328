import { BaseTextInput, BaseInputTags } from 'inputs'
import { BaseTesto } from 'text'

export default function FormInformazioniFile(props) {
  const { tagsDisponibili, fileCaricato } = props

  return (
    <>
      <BaseTesto color='textSecondary'>
        I campi marcati con un asterisco (*) sono obbligatori.
      </BaseTesto>

      <BaseTextInput
        label='Titolo'
        name='titolo'
      />

      <BaseTextInput
        label='Descrizione'
        name='descrizione'
        multiline
        rows={3}
      />

      {tagsDisponibili &&
        <BaseInputTags
          label='Etichette'
          name='tags'
          options={tagsDisponibili.map(tag => ({ label: tag, value: tag }))}
          defaultValue={fileCaricato?.tags || []}
          abilitaCreazioneTag
        />
      }
    </>
  )
}
