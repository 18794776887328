import React, { useEffect, useState } from "react";


const CountDownRedirect = ({url, seconds = 10}) => { 

  
	const [timeLeft, setTimeLeft] = useState(parseInt(seconds))
  if(seconds===0){
      window.location.assign(url);
  }
  useEffect(() => {

    if(timeLeft===0){
       window.location.assign(url);
       return;
    }

    // exit early when we reach 0
    if (!timeLeft) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {

      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => { clearInterval(intervalId);}
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);


  return (
    <span> 
     {timeLeft ?? 0} 
    </span>
   )
  }



export default CountDownRedirect;