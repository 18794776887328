import { useEffect, useRef } from 'react'
import { BaseCoppiaChiaveValore } from 'text'
import { Toast } from '../util/Toast'
import { useUser } from './userProvider/UserProvider'

export default function ToastCambioComando() {
  const {
    getLabelComando,
    comandoScelto,
    isComandoScelto
  } = useUser()

  const toastRef = useRef()

  const isComandoScelto_Boolean = isComandoScelto()
  useEffect(() => {
    // Il timeout serve per impedire che il toast venga mostrato quando
    // l'applicazione viene aperta e la sessione è scaduta. In questo caso,
    // al primo render l'utente risulta loggato, ma subito dopo viene fatto
    // il controllo della sessione e quindi un logout automatico
    // Il timeout fa in modo che il tentativo di mostrare il toast avvenga 
    // quando il logout automatico è già stato fatto
    const timerId = setTimeout(() => {
      if (isComandoScelto_Boolean) toastRef.current?.mostraToast()
    }, 400)
    return () => clearTimeout(timerId)
  }, [comandoScelto, isComandoScelto_Boolean])

  return (
    <Toast
      messaggio={
        <BaseCoppiaChiaveValore
          chiave='Accesso effettuato sul comando'
          valore={getLabelComando()}
        />
      }
      color='success'
      ref={toastRef}
    />
  )
}
