import { useEffect } from 'react'
import { string, func, bool } from 'prop-types'
import { useParams } from 'react-router-dom'
import { Box } from '@mui/material'
import { BaseContenutoConProgress } from 'feedback'
import { BaseScatola } from 'layout'
import { BaseBottoneTornaIndietro } from 'navigation'
import { BaseTesto, BaseTitolo } from 'text'
import { useStateWithLabel } from 'utils'
import useRichiestaServer from '../network/RichiestaServerHook'

DettagliEntitaGenerica.propTypes = {
  nomeEntita: string.isRequired,
  uuid: string,
  titolo: string.isRequired,
  dentroUnAltraPagina: bool,
  mostraBottoneTornaIndietro: bool,

  // Permette di customizzare l'intero componente sotto il titolo
  renderEntitaCustom: func, // Riceve i dati dell'entita quando arrivano dal server

  // Per avere la visualizzazione di default, NON passare renderEntitaCustom e passare renderEntita
  renderEntita: func, // Riceve i dati dell'entita quando arrivano dal server

  renderContenuto_SeDatiNonTrovati: func // Riceve l'uuid dell'entita
}

DettagliEntitaGenerica.defaultProps = {
  renderContenuto_SeDatiNonTrovati: uuid => (
    <>
      <BaseTesto>Non sono stati trovati dati con l'identificativo {uuid}.</BaseTesto>
      <BaseBottoneTornaIndietro />
    </>
  )
}

export default function DettagliEntitaGenerica(props) {
  const {
    nomeEntita,
    uuid: uuid_Prop,
    titolo,
    dentroUnAltraPagina,
    ...propsPerMostrareDettagli
  } = props

  const [datiEntita, setDatiEntita] = useStateWithLabel(null, 'datiEntita')

  const { uuid: uuid_ParamURL } = useParams()
  const uuidDefinitivo = uuid_Prop || uuid_ParamURL

  const {
    ScatolaMessaggi,
    fetchEntity,
    inAttesaDelServer
  } = useRichiestaServer()

  useEffect(() => {
    async function fetchDatiEntita() {
      if (nomeEntita && uuidDefinitivo) {
        const { ok, data } = await fetchEntity(nomeEntita, uuidDefinitivo)
        if (ok) setDatiEntita(data)
      }
    }
    fetchDatiEntita()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nomeEntita, uuidDefinitivo])

  return (
    <>
      {ScatolaMessaggi}

      <BaseTitolo grande={!dentroUnAltraPagina}>{titolo}</BaseTitolo>

      <BaseContenutoConProgress inAttesa={inAttesaDelServer}>
        <MostraDettagli
          datiEntita={datiEntita}
          uuidDefinitivo={uuidDefinitivo}
          dentroUnAltraPagina={dentroUnAltraPagina}
          {...propsPerMostrareDettagli}
        />
      </BaseContenutoConProgress>
    </>
  )
}

function MostraDettagli(props) {
  const {
    datiEntita,
    uuidDefinitivo,
    dentroUnAltraPagina,
    renderEntitaCustom,
    renderEntita,
    renderContenuto_SeDatiNonTrovati,
    mostraBottoneTornaIndietro
  } = props

  if (!datiEntita) return renderContenuto_SeDatiNonTrovati(uuidDefinitivo)
  if (renderEntitaCustom) return renderEntitaCustom(datiEntita)

  const dettagli = (
    <>
      {mostraBottoneTornaIndietro &&
        <Box mb={2}>
          <BaseBottoneTornaIndietro />
        </Box>
      }

      {renderEntita(datiEntita)}
    </>
  )

  if (dentroUnAltraPagina) return dettagli

  return <BaseScatola>{dettagli}</BaseScatola>
}