import { useEffect } from 'react'
import { arrayOf, string, func } from 'prop-types'
import { LinearProgress } from '@mui/material'
import { BaseButtons } from 'inputs'
import { BaseScatola } from 'layout'
import { BaseGrassetto, BaseTesto } from 'text'
import { haAlmenoUnaChiave } from 'utils'
import { ELENCHI } from './Elenchi'
import { useGestioneElenchi } from './ElenchiProvider'

const ELENCHI_PROP_TYPE = arrayOf(string)

ElenchiManager.propTypes = {
  elenchi: ELENCHI_PROP_TYPE.isRequired,
  render: func.isRequired
}

export default function ElenchiManager(props) {
  const { elenchi: chiaviElenchi, render } = props

  const {
    ottieniElenchi,
    checkElenchiPresenti,
    getElencoEsistente: el,
    getElencoEsistenteItem: elItem,
    getErroriElenchi,
    refresh
  } = useGestioneElenchi()

  // Concatena le chiavi degli elenchi richiesti per avere
  // una dipendenza stabile da passare allo useEffect
  const chiaviElenchiConcatenate = chiaviElenchi.join(',')

  // Chiedi di ottenere gli elenchi quando cambiano le chiavi passate,
  // oppure quando il provider indica esplicitamente di rinfrescare
  useEffect(() => {
    if (!chiaviElenchiConcatenate) return
    ottieniElenchi(chiaviElenchiConcatenate.split(','))
  }, [chiaviElenchiConcatenate, refresh])


  // Mostra barra di attesa se gli elenchi richiesti non sono presenti
  // Per ciascun elenco, controlla se ci sono i dati o se ci sono errori
  if (!checkElenchiPresenti(chiaviElenchi)) return <LinearProgress />

  // Mostra eventuali errori relativi ai soli elenchi richiesti
  const errori = getErroriElenchi(chiaviElenchi)
  if (haAlmenoUnaChiave(errori)) return <ErroriElenchi errori={errori} />

  // Renderizza il contenuto fornendo le funzioni per ottenere gli elenchi
  return render(el, elItem)
}



function ErroriElenchi(props) {
  const { errori } = props

  return (
    <BaseScatola errore>
      <BaseGrassetto>Errore: impossibile ottenere dati referenziali</BaseGrassetto>

      <ul>
        {Object.entries(errori).map(([chiave, messaggo]) =>
          <li key={chiave}>
            <BaseTesto>{chiave}: {messaggo}</BaseTesto>
          </li>
        )}
      </ul>

      <BaseButtons.Aggiorna
        testo='Ricarica la pagina'
        onClick={() => window.location.reload()}
        noMarginY
      />
    </BaseScatola>
  )
}

export { ELENCHI, ELENCHI_PROP_TYPE }