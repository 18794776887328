import { trovaElementoConUUID } from '../utils'

function fixListaAgenti(listaAgenti, agentiTutti) {
  return listaAgenti.map(agente => {
    const { matricola, username } = agente

    return getAgenteConMatricola(matricola, agentiTutti)
      ?? getAgenteConUsername(username, agentiTutti)
      ?? agente
  })
}

function getAgenteConMatricola(matricola, agentiTutti) {
  return trovaElementoConUUID(agentiTutti, matricola, 'matricola')
}

function getAgenteConUsername(username, agentiTutti) {
  return trovaElementoConUUID(agentiTutti, username, 'username')
}

export {
  fixListaAgenti,
  getAgenteConUsername
}