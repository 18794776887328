import { useCallback, useMemo } from 'react'
import { arrayOf, object, func, bool } from 'prop-types'
import useControllerSeServe from './ControllerSeServeHook'
import useInputGenerico from './InputGenericoHook'

useInputConOptions.propTypes = {
  // Configurazione options
  options: arrayOf(object).isRequired,
  getOptionValue: func,
  getOptionLabel: func, // Necessaria per la select nativa
  getOptionGroup: func,
  getOptionDisabled: func,

  // Trasformazione input e output
  pair: bool,
  transformInput: func, // Chiamata passando (value)
  transformOutput: func, // Chiamata passando (newValue, originalOption)
  sceltaMultipla: bool,

  ...useInputGenerico.propTypes
}

const getOptionValue_Default = option => option?.value ?? ''
const getOptionLabel_Default = option => option?.label ?? ''
const getOptionGroup_Default = option => option?.group ?? ''
const getOptionDisabled_Default = option => option?.disabled ?? false

const transformInput_Default = value => value
const transformOutput_Default = value => value

export default function useInputConOptions(props) {
  const {
    options,
    getOptionValue = getOptionValue_Default,
    getOptionLabel = getOptionLabel_Default,
    getOptionGroup = getOptionGroup_Default,
    getOptionDisabled = getOptionDisabled_Default,

    pair,
    transformInput: transformInput_Prop,
    transformOutput: transformOutput_Prop,
    sceltaMultipla,

    ...restProps
  } = props

  /***** Input generico *****/
  
  const inputGenericoProps = useInputGenerico({
    componenteControllato: true,
    ...restProps
  })

  const {
    value,
    onBlur,
    onChange,
    ref,
    propsRimanenti
  } = useControllerSeServe(inputGenericoProps)

  /**************************/

  /***** Costruzione options uniformi *****/

  const optionsUniformi = useMemo(
    () => {
      let optionsUniformi = options.map(originalOption => ({
        value: getOptionValue(originalOption),
        label: getOptionLabel(originalOption),
        group: getOptionGroup(originalOption),
        disabled: getOptionDisabled(originalOption),
        originalOption
      }))
      if (sceltaMultipla) {
        // Opzione vuota non ha senso con le scelte multiple, quindi la tolgo se c'è
        optionsUniformi = optionsUniformi.filter(({ value }) => value !== '')
      }
      return optionsUniformi
    },
    [options, getOptionValue, getOptionLabel, getOptionGroup, getOptionDisabled, sceltaMultipla]
  )

  /****************************************/
  
  /***** Pair (caso comune di trasformazione input e output) *****/
  
  const transformInput_Pair = useCallback(pair => {
    return pair?.first
  }, [])
  
  const transformOutput_Pair = useCallback((newValue, originalOption) => {
    return ({
      first: newValue,
      second: getOptionLabel(originalOption)
    })
  }, [getOptionLabel])
  
  /***************************************************************/
  
  /***** Trasformazione input e output *****/

  const transformInput = transformInput_Prop || (pair ? transformInput_Pair : transformInput_Default)
  const transformOutput = transformOutput_Prop || (pair ? transformOutput_Pair : transformOutput_Default)

  const transformInput_Uniforme = useCallback(value => {
    return sceltaMultipla
      ? value.map(singoloValue => transformInput(singoloValue))
      : transformInput(value)
  }, [sceltaMultipla, transformInput])
  
  const transformOutput_Uniforme = useCallback((value, originalOption) => {
    return sceltaMultipla
      ? value.map((singoloValue, index) => transformOutput(singoloValue, originalOption[index]))
      : transformOutput(value, originalOption)
  }, [sceltaMultipla, transformOutput])

  function onChangeConTransformOutput(newValue) {
    if (transformOutput === transformOutput_Default) {
      // transformOutput_Default non fa niente, quindi
      // evito di calcolare inutilmente originalOption
      onChange(newValue)
      return
    }

    // Ricavo la/le originalOption perché possono servire per la trasformazione output
    let originalOption
    if (sceltaMultipla) {
      // newValue è un array che contiene i soli value
      // Costruisco originalOption come un array parallelo a 
      // newValue, contenente le rispettive opzioni originali
      originalOption = newValue.map(val =>
        optionsUniformi.find(({ value }) => value === val).originalOption
      )
    } else {
      // newValue è un singolo value
      // Cerco la singola originalOption corrispondente
      originalOption = optionsUniformi.find(({ value }) => value === newValue).originalOption
    }

    onChange(transformOutput_Uniforme(newValue, originalOption))
  }
  
  /*****************************************/

  return {
    value: transformInput_Uniforme(value),
    onBlur,
    onChange: onChangeConTransformOutput,
    ref,
    propsRimanenti,
    options: optionsUniformi
  }
}