import { useLocation } from 'react-router-dom'
import { Box } from '@mui/material'
import { BasePaginaErrore, BaseListaRoutes, BaseBreadcrumbs } from 'navigation'
import { findRouteWithPath } from 'utils'
import ApplicaPermessiUser from '../userManagement/ApplicaPermessiUser'
import { useUser } from '../userManagement/userProvider/UserProvider'
import { DatiCondivisiTraRotteProvider } from './DatiCondivisiTraRotteProvider'
import { useRoutes } from './RoutesProvider'
import { BaseGridLayout } from 'layout'
import { BaseButtons } from 'inputs'

export default function AppRouter() {
  const {
    isValidPath,
    noBreadcrumb,
    routesNonLoggato,
    routesSenzaComando,
    routesConComando,
  } = useRoutes()

  const {
    routesFlattened_VisibiliDalloUser,
    isUserLoggato,
    isUserLoggatoConComando,
    nuovaGestionePermessi
  } = useUser()

  const { pathname: currentPath } = useLocation()

  // Una rotta è valida se è stata definita nella configurazione iniziale
  // dell'applicazione. Le rotte non valide sono rotte che non hanno
  // senso in questa applicazione, quindi portano su una pagina 404
  if (!isValidPath(currentPath)) {
    return (
      <BasePaginaErrore
        titolo='Pagina non trovata'
        sottotitolo='Il link specificato non è valido.'
      />
    )
  }

  // Se arrivo qui vuol dire che la rotta corrente è valida,
  // ma l'utente potrebbe non avere i permessi per vederla
  // (magari non è loggato o non ha ancora scelto il comando).

  // Tramite il context ottengo le rotte visibili dallo user e
  // le metto in uno Switch di react-router. Quando l'utente 
  // modifica il suo stato effettuando un login o una scelta comando,
  // le rotte nello Switch vengono aggiunte o tolte di conseguenza.

  // Questo meccanismo, unito alla presenza della rotta home nelle
  // rotte senza comando e nelle rotte con comando, permette una
  // redirezione trasparente verso una rotta attraverso le pagine
  // di login e di scelta comando.

  const routesDaControllare = (() => {
    if (!isUserLoggato()) return routesNonLoggato
    return isUserLoggatoConComando?.() ? routesConComando : routesSenzaComando
  })()
  const currentRoute = findRouteWithPath(routesDaControllare.flattened, currentPath)

  const mergedNoBreadcrumb = currentRoute?.noBreadcrumb ?? noBreadcrumb
  const wikiConf = currentRoute?.wikiConf

  return (
    <DatiCondivisiTraRotteProvider>
      <BaseGridLayout justifySpaceBetween>
        {!mergedNoBreadcrumb && isUserLoggato() ? (
          <Box mb={1}>
            <BaseBreadcrumbs currentRoute={currentRoute} />
          </Box>
        ) : (
          <></>
        )}
        
        {wikiConf && isUserLoggato() ? (
          <Box mb={1}>
            <a target='_blank' rel='noreferrer' href={'https://wiki.contnet.eu/it/' + wikiConf.url}>
              <BaseButtons.Legenda
                noMarginY
                testo={'Wiki ' + (wikiConf.label ?? '')}
                color='info'
                size='small'
                variant='outlined'
              />
            </a>
          </Box>
        ) : (
          <></>
        )}
      </BaseGridLayout>
      
      <ApplicaPermessiUser
        {...(nuovaGestionePermessi ? {
          chiaviModuli: currentRoute.chiaviModuli,
          chiaviPermessi: currentRoute.chiaviPermessi
        } : {
          moduli: currentRoute.moduli || []
        })}
        fallback={
          <BasePaginaErrore
            titolo='Accesso non consentito'
            sottotitolo='Non si hanno i permessi per visualizzare questo contenuto.'
          />
        }
      >
        <BaseListaRoutes routes={routesFlattened_VisibiliDalloUser} />
      </ApplicaPermessiUser>
    </DatiCondivisiTraRotteProvider>
  )
}