import { useEffect, useMemo, useState } from 'react'
import { shape, string, object, arrayOf, func, bool } from 'prop-types'
import { Box } from '@mui/material'
import { BaseBarraRicercaConRitardo, BaseInputTabella } from 'inputs'
import { BaseGridLayout } from 'layout'
import { BasePaginazione } from 'navigation'
import { BaseTitolo } from 'text'
import ElenchiManager from '../../form/ElenchiManager'
import FormRecordDizionario from './FormRecordDizionario'
import { creaColonneTabellaDizionario, trasformaRecordsDizionarioInput, useFiltroRecordsDizionario, usePaginazioneRecordsDizionario } from './utilsModificaDizionari'

TabellaModificaDizionario.propTypes = {
  definizione: shape({
    nome: string.isRequired, // Nome dizionario come definito negli elenchi
    label: string.isRequired, // Label del dizionario
    labelElemento: string, // Label del singolo elemento

    // Configurazione della tabella e della form di modifica del singolo record
    attributi: shape({
      pk: string.isRequired, // Nome del campo che fa da chiave
      defaultRecord: object.isRequired, // Default values per la creazione
      campi: arrayOf(shape({
        // Configurare il campo della form con le solite opzioni
        // name, label, type, ecc (vedi LogicaCampiParams)
        propsColonna: object, // Opzioni per la colonna della tabella (vedi BaseTabella)
        modificaSoloStaff: bool // Se true, il campo può essere modificato solo da staff
      })).isRequired
    }).isRequired,

    // Invia operazione OPERAZIONI_BASE.DIZIONARIO_SALVA
    dizionario: string, // Nome dizionario. Se non presente viene usato il nome
    params: object, // Parametri aggiuntivi

    // Invia operazione generica
    operationId: string, // Default = OPERAZIONI_BASE.DIZIONARIO_SALVA
    // Le funzioni ricevono il record trasformato e l'azione (CREA o MODIFICA)
    calcolaOperationData: func, // Usato se != OPERAZIONI_BASE.DIZIONARIO_SALVA
    calcolaOperationOptions: func, // Calcola il terzo parametro di inviaOperazione
    calcolaOperationOptionsHttp: func, // Calcola il quarto parametro di inviaOperazione

    // Trasformazione applicata sul record prima di salvarlo
    trasformaRecordPerSalvataggio: func,

    // Trasformazione applicata sulla chiave del record appena salvato
    trasformaChiaveRecordSalvato: func,

    // Prende un record e restituisce una stringa in cui fare le ricerche
    getRecordSearchString: func,

    // Gestione altri filtri di ricerca
    // Componente che renderizza i filtri. Riceve defaultValuesFiltri e onChangeFiltri
    AltriFiltri: func,
    // Prende un record + i valori attuali dei filtri e calcola se il record deve essere filtrato
    logicaAltriFiltri: func,
    defaultValuesAltriFiltri: object,

    // Promessa generica per salvare il record
    // Riceve la definizione, la funzione inviaOperazione e il record
    promise: func
  }),
  propsFormRecord: object
}

export default function TabellaModificaDizionario(props) {
  const {
    definizione: {
      nome,
      trasformaChiaveRecordSalvato = chiave => chiave,
      defaultValuesAltriFiltri
    }
  } = props

  const [chiaveRecordAppenaSalvato, setChiaveRecordAppenaSalvato] = useState(null)
  const [scrollAppenaSalvato, setScrollAppenaSalvato] = useState(null)
  const [testoRicerca_FinitoDiScrivere, setTestoRicerca_FinitoDiScrivere] = useState('')
  const [valoriAltriFiltri, setValoriAltriFiltri] = useState(defaultValuesAltriFiltri)
  const [paginaCorrente, setPaginaCorrente] = useState(1)

  return (
    <ElenchiManager
      elenchi={[nome]}
      render={el =>
        <TabellaModificaDizionarioConRecords
          {...props}
          records={el(nome)}
          chiaveRecordAppenaSalvato={chiaveRecordAppenaSalvato}
          setChiaveRecordAppenaSalvato={chiave =>
            setChiaveRecordAppenaSalvato(trasformaChiaveRecordSalvato(chiave))
          }
          scrollAppenaSalvato={scrollAppenaSalvato}
          setScrollAppenaSalvato={setScrollAppenaSalvato}
          testoRicerca_FinitoDiScrivere={testoRicerca_FinitoDiScrivere}
          setTestoRicerca_FinitoDiScrivere={setTestoRicerca_FinitoDiScrivere}
          valoriAltriFiltri={valoriAltriFiltri}
          setValoriAltriFiltri={setValoriAltriFiltri}
          paginaCorrente={paginaCorrente}
          setPaginaCorrente={setPaginaCorrente}
        />
      }
    />
  )
}



function TabellaModificaDizionarioConRecords(props) {
  const {
    definizione,
    propsFormRecord,
    records,
    chiaveRecordAppenaSalvato,
    setChiaveRecordAppenaSalvato,
    scrollAppenaSalvato,
    setScrollAppenaSalvato,
    testoRicerca_FinitoDiScrivere,
    setTestoRicerca_FinitoDiScrivere,
    valoriAltriFiltri,
    setValoriAltriFiltri,
    paginaCorrente,
    setPaginaCorrente,
    ...restProps
  } = props

  const {
    label,
    labelElemento = 'elemento',
    attributi: { campi, pk },
    getRecordSearchString,
    AltriFiltri,
    logicaAltriFiltri
  } = definizione

  const colonne = useMemo(() => {
    return creaColonneTabellaDizionario(campi, pk, chiaveRecordAppenaSalvato)
  }, [campi, pk, chiaveRecordAppenaSalvato])

  const righe = useMemo(() => {
    return trasformaRecordsDizionarioInput(records, pk)
  }, [records, pk])

  const {
    recordFiltrati: righeFiltrate
  } = useFiltroRecordsDizionario({
    recordTutti: righe,
    getRecordSearchString,
    testoRicerca_FinitoDiScrivere,
    logicaAltriFiltri,
    valoriAltriFiltri
  })

  const defaultElementiPerPagina = 50
  const abilitaPaginazione = (righe.length > defaultElementiPerPagina)
  const {
    recordPaginaCorrente,
    ...propsPaginazione
  } = usePaginazioneRecordsDizionario({
    recordTutti: righeFiltrate,
    defaultElementiPerPagina,
    paginaCorrente,
    setPaginaCorrente
  })

  const uiPaginazione = abilitaPaginazione && (
    <BaseGridLayout justifyContent='flex-end'>
      <BasePaginazione
        {...propsPaginazione}
        visualizzazioneVerticale
        nascondiOpzioni
      />
    </BaseGridLayout>
  )

  useEffect(() => {
    if (scrollAppenaSalvato) {
      window.scroll({ top: scrollAppenaSalvato })
    }
  }, [scrollAppenaSalvato])

  return (
    <BaseInputTabella
      title={
        <>
          <BaseTitolo>{label}</BaseTitolo>
          <BaseGridLayout>
            {getRecordSearchString &&
              <BaseBarraRicercaConRitardo
                testoRicerca_DefaultValue={testoRicerca_FinitoDiScrivere}
                setTestoRicerca_FinitoDiScrivere={setTestoRicerca_FinitoDiScrivere}
                togliPrimaParteMessaggioAiuto
              />
            }
            {AltriFiltri &&
              <AltriFiltri
                defaultValuesFiltri={valoriAltriFiltri}
                onChangeFiltri={setValoriAltriFiltri}
              />
            }
          </BaseGridLayout>
        </>
      }
      columns={colonne}
      value={recordPaginaCorrente}
      toolbar={uiPaginazione}
      contenutoInFondo={<Box sx={{ p: 2 }}>{uiPaginazione}</Box>}
      propsModal={{
        renderForm: propsInterne =>
          <FormRecordDizionario
            definizione={definizione}
            setChiaveRecordAppenaSalvato={setChiaveRecordAppenaSalvato}
            setScrollAppenaSalvato={setScrollAppenaSalvato}
            {...propsInterne}
            {...propsFormRecord}
          />
      }}
      propsModalModificaRiga={{ titolo: `Modifica ${labelElemento}` }}
      propsModalAggiuntaRiga={{ titolo: `Aggiungi ${labelElemento}` }}
      isRigaEliminabile={() => false}
      {...restProps}
    />
  )
}