
import { Skeleton } from '@mui/lab'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import PropTypes from 'prop-types'
import { Children, cloneElement } from 'react'

/**
 * @typedef {import("../common/layout/BaseTabsAlternative").BaseTabsAlternativeProps} BaseTabsAlternativeProps
 * @typedef {import("../common/layout/BaseTabsAlternative").BaseTabsAlternative} BaseTabsAlternative 
 */

/**
 * Single panel to display. Declared before use.
 * @param {*} props 
 * @returns {JSX.Element} component
 */
function TabPanel(props) {
    const { children, hidden, removeFromDom } = props

    return (
        <div
            role='tabpanel'
            hidden={hidden}
        >
            {
                removeFromDom ? !hidden && (
                    cloneElement(children)
                ) : cloneElement(children)
            }
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node.isRequired,
    removeFromDom: PropTypes.bool.isRequired,
    hidden: PropTypes.bool.isRequired
}

/**
 * 
 * @param {BaseTabsAlternativeProps} props 
 * @type {BaseTabsAlternative}
 */
function BaseTabsAlternative(props) {
    const { isLoading = false, currentTab = 0, children, title, titleOnLeft = true, removeTabFromDom = true, tabs, onChangeTab } = props

    const arrayOfChildren = Children.toArray(children)

    // eslint-disable-next-line no-unused-vars
    const handleChange = (_event, newTab) => {
        onChangeTab(newTab)
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                {title && titleOnLeft && (
                    <Box>
                        {title}
                    </Box>)
                }
                {
                    isLoading ? <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <Skeleton width={60} height={35} sx={{ mr: 1 }} />
                        <Skeleton width={60} height={35} sx={{ mr: 1 }} />
                        <Skeleton width={60} height={35} sx={{ mr: 1 }} />
                    </Box> : <Tabs value={currentTab} onChange={handleChange} >
                        {
                            tabs.map((tab, index) => (
                                <Tab key={index} label={tab.label} />
                            ))
                        }
                    </Tabs>
                }

                {title && !titleOnLeft && (
                    <Box>
                        {title}
                    </Box>)
                }
            </Box>
            {
                arrayOfChildren.map((child, index) => (
                    <TabPanel key={index} hidden={currentTab !== index} removeFromDom={removeTabFromDom} index={index}>
                        {cloneElement(child, { key: undefined, ...child.props })}
                    </TabPanel>
                ))
            }
        </Box>
    )
}

BaseTabsAlternative.propTypes = {
    isLoading: PropTypes.bool,
    currentTab: PropTypes.number,
    onChangeTab: PropTypes.func.isRequired,
    title: PropTypes.node,
    titleOnLeft: PropTypes.bool,
    sx: PropTypes.object,
    children: PropTypes.arrayOf(PropTypes.node).isRequired,
    tabs: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string
    })).isRequired,
    removeTabFromDom: PropTypes.bool
}

export default BaseTabsAlternative