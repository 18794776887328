import { Checkbox, Card, Typography, CardActions, CardContent, CardMedia } from '@mui/material'
import { BaseMenu } from 'inputs'
import { BaseVoto } from 'feedback'
import { BaseGridLayout } from 'layout'
import { BaseGrassetto } from 'text'
import { sx } from 'utils'

export default function ImmagineConVotoEAzioni(props) {
  const {
    immagine: { titolo, src, voto },
    width = 250,
    onClickCard,
    nascondiVoti,
    modalitaSelezioneAttiva,
    isImmagineSelezionata,
    indiceImmagineSelezionata,
    azioni,
    azioniMenu,
    ...restProps
  } = props

  return (
    <Card
      onClick={onClickCard}
      sx={{ width, ...(modalitaSelezioneAttiva && { cursor: 'pointer' }) }}
    >
      <CardMedia
        component='img'
        image={src.thumbnail}
        height={190}
        {...restProps}
        {...sx(restProps, {
          objectFit: 'contain',
          ...(!modalitaSelezioneAttiva && { cursor: 'zoom-in' }),
          ...(isImmagineSelezionata && {
            border: 4,
            borderColor: 'primary.main',
            borderRadius: 1
          })
        })}
      />

      <CardContent sx={{ px: 0.5, py: 1 }}>
        <Typography
          variant='body2'
          color='text.secondary'
          style={{
            'overflow': 'hidden',
            'whiteSpace': 'nowrap',
            'textOverflow': 'ellipsis'
          }}
        >
          {titolo || <>&nbsp;</>}
        </Typography>
      </CardContent>

      <CardActions sx={{ py: 0 }}>
        <BaseGridLayout justifySpaceBetween alignCenter>
          {nascondiVoti ? <></> : <BaseVoto value={voto} small />}

          {modalitaSelezioneAttiva ? (
            <>
              {isImmagineSelezionata &&
                <BaseGrassetto sx={{ color: 'primary.main' }}>
                  {indiceImmagineSelezionata + 1}°
                </BaseGrassetto>
              }
              <Checkbox checked={isImmagineSelezionata} />
            </>
          ) : (
            <BaseGridLayout spacing={0} alignCenter>
              {azioni}
              {azioniMenu &&
                <BaseMenu trePuntini options={azioniMenu} />
              }
            </BaseGridLayout>
          )}
        </BaseGridLayout>
      </CardActions>
    </Card>
  )
}