function trovaElementoConUUID(arrayDiOggetti, valorePropId, nomePropId = 'uuid') {
  if (!Array.isArray(arrayDiOggetti)) return null
  const elementoTrovato = arrayDiOggetti.find(oggetto => {
    if (typeof oggetto !== 'object') return false
    return (oggetto[nomePropId] === valorePropId)
  })
  return elementoTrovato ?? null
}

function checkValoriInputPresenti(valoriInput) {
  if (!valoriInput) return false

  // const proprietaPresenti = Object.keys(valoriInput)
  // if (proprietaPresenti.length === 2
  //   && proprietaPresenti.includes('uuid')
  //   && proprietaPresenti.includes('entitaRev')
  // ) {
  //   return false
  // }

  return true
}

function sonoLaStessaEntita(entita1, entita2, nomePropChiave = 'uuid') {
  if (!entita1 || !entita2) return false
  return entita1[nomePropChiave] === entita2[nomePropChiave] 
}

function trasformaDescrittoriInRifs(descrittori) {
  return descrittori.map(({ entita, nomeEntita, path, label }) => ({
    identificativo: {
      nomeEntita,
      uuid: entita?.uuid ?? ''
    },
    path,
    label
  }))
}

export {
  trovaElementoConUUID,
  checkValoriInputPresenti,
  sonoLaStessaEntita,
  trasformaDescrittoriInRifs
}