/* eslint-disable react/prop-types */
import { useRef, useState } from 'react'
import { BaseButtons, BaseForm, BaseSelect } from 'inputs'
import CampiParams from '../../../form/campiParams/CampiParams'
import useCampiParams from '../../../form/campiParams/LogicaCampiParams'
import ElenchiManager from '../../../form/ElenchiManager'
import { trovaElementoConUUID } from '../../utils'
import { isDocumentoGeneratoDaTemplate } from '../utilDocumenti'

const NOMI = {
  TIPO: 'tipo',
  PARAMETRI_METADATI: 'parametriMetadati'
}

export default function FormMetadatiDocumento(props) {
  const {
    documentoInput,
    salvaDocumentoModificato,
    tipiDocumento,
    calcolaOptions,
    parametriFissi,
    parametriDefaultValues,
    readOnly,
    ...propsBaseForm
  } = props

  const { tipo, parametriMetadati } = documentoInput

  function trovaDefinizioneTipo(tipoCercato) {
    return trovaElementoConUUID(tipiDocumento, tipoCercato.first, 'value')
  }

  const [definizioneTipoScelto, setDefinizioneTipoScelto] = useState(
    trovaDefinizioneTipo(tipo)
  )

  const logicaCampiParams = useCampiParams({
    params: definizioneTipoScelto?.params,
    prefissoNomi: NOMI.PARAMETRI_METADATI,
    calcolaOptions,
    parametriFissi,
    parametriDefaultValues
  })

  const defaultValues = {
    ...documentoInput,
    parametriMetadati: {
      ...logicaCampiParams.defaultValuesParams,
      ...parametriMetadati,
      ...parametriFissi
    }
  }

  return (
    <ElenchiManager
      elenchi={logicaCampiParams.elenchi}
      render={el =>
        <FormMetadatiDocumento_Interna
          // Props calcolate in questo componente
          trovaDefinizioneTipo={trovaDefinizioneTipo}
          definizioneTipoScelto={definizioneTipoScelto}
          setDefinizioneTipoScelto={setDefinizioneTipoScelto}
          logicaCampiParams={logicaCampiParams}
          defaultValues={defaultValues}
          el={el}

          // Props provenienti dal padre
          documentoInput={documentoInput}
          salvaDocumentoModificato={salvaDocumentoModificato}
          tipiDocumento={tipiDocumento}
          readOnly={readOnly}
          {...propsBaseForm}
        />
      }
    />
  )
}



function FormMetadatiDocumento_Interna(props) {
  const {
    trovaDefinizioneTipo,
    definizioneTipoScelto,
    setDefinizioneTipoScelto,
    logicaCampiParams,
    defaultValues,
    el,

    documentoInput,
    salvaDocumentoModificato,
    tipiDocumento,
    readOnly,
    ...propsBaseForm
  } = props

  const formRef = useRef()

  function salva(valoriForm) {
    salvaDocumentoModificato({
      ...valoriForm,
      // Aggiunta del second del tipo, se non passato nei valori della form
      // Succede per i documenti generati che vengono creati solo col first
      ...(!valoriForm.tipo.second && {
        tipo: {
          ...valoriForm.tipo,
          second: definizioneTipoScelto?.label ?? ''
        }
      }),
      // Fix per il raro caso in cui erano salvati dei parametri,
      // e poi l'utente sceglie un altro tipo che non ha nessun
      // parametro associato. In questa situazione React hook form 
      // non ritorna l'oggetto parametriMetadati nei valori, quindi
      // nel merge con i valori precedenti (non fatto qui ma sopra)
      // venivano preservati i valori dei parametri. Inserendo qui
      // un oggetto vuoto, i vecchi parametri sono cancellati
      ...(!valoriForm.parametriMetadati && {
        parametriMetadati: {}
      })
    })
  }

  return (
    <BaseForm
      defaultValues={defaultValues}
      onSubmit={salva}
      ref={formRef}
      nonUsareFormTagHtml
      larghezzaInputs='fullWidth'
      opzioniUseForm={{ mode: 'all' }}
      readOnly={readOnly}
      {...propsBaseForm}
    >
      <BaseSelect
        label='Tipologia documento' name={NOMI.TIPO}
        options={tipiDocumento} pair
        onChange={nuovoTipo => setDefinizioneTipoScelto(
          trovaDefinizioneTipo(nuovoTipo)
        )}
        readOnly={
          isDocumentoGeneratoDaTemplate(documentoInput) || readOnly
        }
      />

      <CampiParams
        {...logicaCampiParams}
        el={el}
        testoSenzaParams='Nessun campo aggiuntivo per questa tipologia di documento.'
        // La key serve per evitare un problema dello useMemo all'interno del componente
        // Praticamente i campi rimanevano invisibili quando veniva cambiato il tipo
        // Per risolvere il problema, forzo la ricreazione del componente al cambio del tipo
        key={definizioneTipoScelto?.value ?? ''}
      />

      <div style={{ textAlign: 'center' }}>
        <BaseButtons.Salva
          onClick={() => formRef.current.submit()}
          {...(readOnly && { disabled: true })}
        />
      </div>
    </BaseForm>
  )
}