const REGOLE_SEMPLICI_LUNGHEZZA_INPUT = {
  'minLength': minLength => `Almeno ${minLength} caratteri`,
  'maxLength': maxLength => `Al massimo ${maxLength} caratteri`,
}

const REGOLE_SEMPLICI_PER_NUMERI = {
  'min': min => `Valore minimo: ${min}`,
  'max': max => `Valore massimo: ${max}`
}

function aggiungiRegolaSemplice(validazione, nomeRegola, messaggioErrore) {
  const valoreRegola = validazione[nomeRegola]
  if (valoreRegola !== undefined) {
    validazione[nomeRegola] = {
      value: valoreRegola,
      message: messaggioErrore
    }
  }
}

// Modifica l'oggetto validazione passato aggiungendo le regole semplici richieste
function aggiungiRegoleSemplici(validazione, regoleSemplici) {
  Object.entries(regoleSemplici).forEach(([nomeRegola, creaMessaggio]) => {
    const valoreRegola = validazione[nomeRegola]
    // Controllo se il valore della regola è un numero,
    // perché se è un oggetto si presuppone che contenga già un messaggio impostato dal padre
    if (typeof valoreRegola === 'number') {
      aggiungiRegolaSemplice(validazione, nomeRegola, creaMessaggio(valoreRegola))
    }
  })
}

function aggiungiTrimPerStringhe(validazione) {
  const setValueAs_GiaPresente = validazione.setValueAs || (value => value)
  validazione.setValueAs = value => {
    value = setValueAs_GiaPresente(value)
    return (typeof value === 'string') ? value.trim() : value
  }
}

function aggiungiRegoleLunghezzaInput(validazione) {
  return aggiungiRegoleSemplici(validazione, REGOLE_SEMPLICI_LUNGHEZZA_INPUT)
}

function aggiungiRegolePerNumeri(validazione) {
  const setValueAs_GiaPresente = validazione.setValueAs || (value => value)
  validazione.setValueAs = value => {
    value = setValueAs_GiaPresente(value)
    // Se il campo è vuoto non trasformo il valore in numero, perché Number('')
    // restituisce 0 e può essere sbagliato. Inoltre ci sono delle funzioni di
    // validazione che si aspettano stringa vuota per capire che il campo è vuoto
    return value === '' ? '' : Number(value)
  }
  return aggiungiRegoleSemplici(validazione, REGOLE_SEMPLICI_PER_NUMERI)
}

export {
  aggiungiTrimPerStringhe,
  aggiungiRegoleLunghezzaInput,
  aggiungiRegolePerNumeri
}