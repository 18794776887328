import { Backdrop, CircularProgress } from '@mui/material'
import { bool, object } from 'prop-types'

Loading.propTypes = {
  loading: bool,
  sx: object
}

export default function Loading(props) {
  const {
    loading = false,
    bdsx = { color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }
  } = props
  
  if(!loading) return null
  
  return (<>
    <Backdrop 
      sx={bdsx}
      open={loading}
      >
      <CircularProgress color="inherit" />
    </Backdrop>
  </>
  )
}