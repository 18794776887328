import { object, bool } from 'prop-types'
import { ICONE } from 'icons'
import { BaseAccordion, BaseHeaderConIcona } from 'layout'
import { BaseListaCoppieChiaveValore } from 'text'
import Indirizzo from '../indirizzo/Indirizzo'

Azienda.propTypes = {
  azienda: object.isRequired,
  visioneRiassuntiva: bool,
  propsHeader: object
}

export default function Azienda(props) {
  const {
    azienda,
    visioneRiassuntiva,
    propsHeader,
    ...restProps
  } = props
  if (!azienda) return null

  const {
    indirizzo,
    nome,
    partitaIva,
    pec
  } = azienda

  const header = (
    <BaseHeaderConIcona
      icona={<ICONE.AZIENDA />}
      titolo={nome}
      sottotitolo='Azienda'
      {...propsHeader}
    />
  )

  if (visioneRiassuntiva) return header

  return (
    <BaseAccordion
      contenutoNonEspanso={header}
      contenutoEspanso={
        <>
          <BaseListaCoppieChiaveValore
            titolo='Dati azienda'
            coppie={{
              'Nome': nome,
              'Partita IVA': partitaIva,
              'PEC': pec
            }}
          />
          <Indirizzo indirizzo={indirizzo} />
        </>
      }
      {...restProps}
    />
  )
}