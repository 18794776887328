import { useEffect } from 'react'
import { string, arrayOf, shape, func, node, object, objectOf } from 'prop-types'
import { Switch, Route, useRouteMatch } from 'react-router-dom'

BaseListaRoutes.propTypes = {
  routes: arrayOf(shape({
    pathAssoluto: string.isRequired,
    Content: func
  })).isRequired,
  specialRoutes: node,
  propsRoute: object,
  propsContent: object,
  propsContent_DivisePerRotta: objectOf(object),
  setRottaCorrente: func
}

export default function BaseListaRoutes(props) {
  const {
    routes,
    specialRoutes,
    propsRoute,
    propsContent,
    propsContent_DivisePerRotta,
    setRottaCorrente,
    ...propsSwitch
  } = props

  // Se una route non ha il Content, di solito è gestita internamente
  // ad un altro componente. Quindi non la inserisco qui
  const routesDaInserire = routes.filter(({ Content }) => Boolean(Content))

  return (
    <Switch {...propsSwitch}>
      {routesDaInserire.map(({ pathAssoluto, Content }) =>
        <Route
          key={pathAssoluto}
          path={pathAssoluto}
          {...propsRoute}
        >
          <>
            {setRottaCorrente &&
              <InformaPadreDellaRottaCorrente setRottaCorrente={setRottaCorrente} />
            }

            <Content
              {...propsContent}
              {...propsContent_DivisePerRotta?.[pathAssoluto]}
            />
          </>
        </Route>
      )}

      {specialRoutes}
    </Switch>
  )
}



function InformaPadreDellaRottaCorrente(props) {
  const { setRottaCorrente } = props

  const { path, url } = useRouteMatch()

  useEffect(
    () => setRottaCorrente({ path, url }),
    [path, url, setRottaCorrente]
  )
  
  return null
}