import { encode, decode } from 'js-base64'

function convertStringToBase64(string) {
  if (typeof string !== 'string') return ''
  return encode(string)
}

function convertBase64ToString(base64) {
  if (typeof base64 !== 'string') return ''
  return decode(base64)
}

const MARKER_ODD_STRINGS = '#####'

// Codifica una stringa usando un semplice metodo:
// - se la stringa ha lunghezza dispari, aggiungi un marker per renderla pari
// - separa i caratteri in posizione pari da quelli in posizione dispari
// - concatena il blocco dei caratteri pari con il blocco dei caratteri dispari
function maskString(string) {
  if (typeof string !== 'string') return ''
  if (string.length < 2) return string

  if (string.length % 2 !== 0) string += MARKER_ODD_STRINGS
  
  const characters = string.split('')
  const charactersWithEvenIndexes = characters.filter((_, i) => i % 2 === 0)
  const charactersWithOddIndexes = characters.filter((_, i) => i % 2 !== 0)

  return [...charactersWithEvenIndexes,...charactersWithOddIndexes].join('')
}

// Inverso della funzione maskString
function unmaskString(string) {
  if (typeof string !== 'string') return ''
  if (string.length < 2) return string

  const characters = string.split('')
  const charactersWithEvenIndexes = characters.slice(0, characters.length / 2)
  const charactersWithOddIndexes = characters.slice(characters.length / 2)

  let originalString = ''
  for (let i = 0; i < charactersWithEvenIndexes.length; i++) {
    originalString += charactersWithEvenIndexes[i]
    originalString += charactersWithOddIndexes[i]
  }

  if (originalString.endsWith(MARKER_ODD_STRINGS)) {
    originalString = originalString.substring(0, originalString.lastIndexOf(MARKER_ODD_STRINGS))
  }

  return originalString
}

export {
  convertStringToBase64,
  convertBase64ToString,
  maskString,
  unmaskString
}