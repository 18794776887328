import { useState } from 'react'
import { useAddHashQueryString } from 'utils'
import { useRoutes } from '../../navigation/RoutesProvider'
import ToastCambioComando from '../ToastCambioComando'
import useComandoScelto from './ComandoScelto'
import useGruppiUtente from './GruppiUtente'
import { useUser, useUserUpdate, UserContext, UserUpdateContext } from './UserProvider'
import usePermessiUserConComando from './permessiUser/PermessiUserConComando'

export default function UserProviderConComando(props) {
  const { children, nonFareRichiestaPermessi } = props

  const { routesConComando, routesSenzaComando } = useRoutes()

  const letturaInfoUser = useUser()
  const {
    isUserLoggato,
    isAdminAlmenoUnComando,
    canAdmin,
    isUserStaff,
    userPuoVedereInSolaLettura,
    userPuoVedereInLetturaScrittura
  } = letturaInfoUser

  const modificaInfoUser = useUserUpdate()
  const {
    handleLogin,
    handleLogout,
    reloadLogin
  } = modificaInfoUser

  const {
    impostaGruppiUtente,
    ...risultatiGestioneGruppiUtente
  } = useGruppiUtente()

  // Non servirà più questo hook una volta passati completamente all'SSO
  const {
    richiediEImpostaPermessiUserConComando,
    ...feedbackRichiestaPermessiUser
  } = usePermessiUserConComando({
    impostaGruppiUtente,
    nonFareRichiestaPermessi
  })

  const {
    isComandoScelto,
    handleSceltaComando,
    handleRichiestaCambioComando,
    handleLogin_OperazioniSulComando,
    handleLogout_OperazioniSulComando,
    ...risultatiGestioneComandoScelto
  } = useComandoScelto({
    richiediEImpostaPermessiUserConComando,
    impostaGruppiUtente,
    isUserLoggato
  })

  const [mostraCambioComando, setMostraCambioComando] = useState(true)

  /* Metodi per ottenere informazioni sullo user */

  function isUserLoggatoSenzaComando() {
    return isUserLoggato() && !isComandoScelto()
  }

  function isUserLoggatoConComando() {
    return isUserLoggato() && isComandoScelto()
  }

  function getRoutesLoggatoAttuali() {
    return isUserLoggatoConComando() ? routesConComando : routesSenzaComando
  }

  function isAdminConComando() {
    const { comandoScelto } = risultatiGestioneComandoScelto
    return isUserLoggatoConComando() ? canAdmin(comandoScelto) : isAdminAlmenoUnComando()
  }

  function isAdminOHaPermesso(permesso, { solaLettura = false }) {
    if (isUserStaff() || isAdminConComando()) return true
    if (solaLettura) return userPuoVedereInSolaLettura(permesso)
    return userPuoVedereInLetturaScrittura(permesso)
  }

  function getComandoScelto() {
    const { comandiAbilitati, comandoScelto } = risultatiGestioneComandoScelto
    return comandiAbilitati?.find(cmd => cmd.tag === comandoScelto) ?? null
  }

  const letturaInfoUser_ConComando = {
    ...letturaInfoUser,
    ...risultatiGestioneGruppiUtente,
    ...risultatiGestioneComandoScelto,
    isAdmin: isAdminConComando,
    isAdminOHaPermesso,
    isComandoScelto,
    isUserLoggatoSenzaComando,
    isUserLoggatoConComando,
    getRoutesLoggatoAttuali,
    getComandoScelto,
    mostraCambioComando
  }

  /***********************************************/

  /* Metodi per modificare informazioni dello user */

  // Ottengo permessiUser e gruppi al login solo se l'utente ha un solo comando
  function handleLogin_Arricchito(userData) {
    const { permessi, gruppi, ...restData } = userData
    handleLogin(restData)

    const { user } = restData
    const {
      cmd: comandoGiaScelto,
      cmds: comandiAbilitati
    } = user

    handleLogin_OperazioniSulComando(
      comandoGiaScelto,
      comandiAbilitati,
      user,
      permessi,
      gruppi
    )
  }

  function handleLogout_Arricchito() {
    handleLogout()
    handleLogout_OperazioniSulComando()
  }

  const { addParams } = useAddHashQueryString()

  function reloadLogin_Arricchito() {
    const { comandoScelto } = risultatiGestioneComandoScelto
    comandoScelto && addParams({ cmd: comandoScelto })
    reloadLogin()
  }

  const modificaInfoUser_ConComando = {
    ...modificaInfoUser,
    ...feedbackRichiestaPermessiUser,
    handleLogin: handleLogin_Arricchito,
    handleLogout: handleLogout_Arricchito,
    reloadLogin: reloadLogin_Arricchito,
    handleSceltaComando,
    handleRichiestaCambioComando,
    setMostraCambioComando
  }

  /*************************************************/

  return (
    <UserContext.Provider
      value={letturaInfoUser_ConComando}
      // Se il comando viene cambiato rimanendo nella stessa pagina,
      // c'è il rischio che le ottimizzazioni di react mantengano i
      // dati già caricati nel comando precedente. Usando il comando
      // scelto come key, mi assicuro che tutti i componenti vengano
      // ricreati così i dati vengono rinfrescati con nuove chiamate
      key={risultatiGestioneComandoScelto.comandoScelto || '$_NESSUN_COMANDO_$'}
    >
      <UserUpdateContext.Provider value={modificaInfoUser_ConComando}>
        {children}
        <ToastCambioComando />
      </UserUpdateContext.Provider>
    </UserContext.Provider>
  )
}