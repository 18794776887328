import { useState, useEffect, useMemo } from 'react'
import { useReadOnly } from 'inputs'
import { useUser } from '../../../userManagement/userProvider/UserProvider'
import { isStatoAnnullatoOEliminato, isStatoChiuso } from '../../statoEntita/utilStatoEntita'

export const PERMESSI_ENTITA = {
  SOLA_LETTURA: 'r',
  LETTURA_SCRITTURA: 'w'
}

export default function usePermessiEntita(props) {
  const {
    entita,
    abilitaModificaPermessiCompleta,
    readOnly_DalServer,
    condizioneReadOnly,
    stato
  } = props

  const { isUserStaff } = useUser()

  const { readOnly: readOnly_Contesto } = useReadOnly()

  const readOnly_Esterno = useMemo(() => {
    if (readOnly_DalServer || readOnly_Contesto) return true
    if (condizioneReadOnly) return condizioneReadOnly(entita)
    if (stato) return isStatoAnnullatoOEliminato(stato) || isStatoChiuso(stato)
    return false
  }, [readOnly_DalServer, readOnly_Contesto, condizioneReadOnly, entita, stato])

  // Stato che forza la visualizzazione in sola lettura
  const [readOnly, setReadOnly] = useState(readOnly_Esterno)

  useEffect(() => setReadOnly(readOnly_Esterno), [readOnly_Esterno])

  return {
    abilitaModificaPermessiCompleta: isUserStaff() || abilitaModificaPermessiCompleta,
    userNonPuoVedereEntita: false,
    readOnly,
    setReadOnly
  }
}