import { BaseRadioBoolean } from 'inputs'
import { BaseScatolaConTitolo } from 'layout'
import { useEntita } from '../entitaGenerica/components/EntitaContext'
import IndirizzoModifica from '../indirizzo/IndirizzoModifica'

export default function IndirizzoDomicilioModifica(props) {
  const { propsEntita, label } = props

  const {
    gestioneModifica: { creaEntita, eliminaEntita },
    gestionePermessi: { readOnly }
  } = useEntita()
  
  const { valoriInput, nomeEntita, pathEntita } = propsEntita

  function creaIndirizzo() {
    creaEntita(nomeEntita, pathEntita)
  }

  function eliminaIndirizzo() {
    eliminaEntita(nomeEntita, pathEntita)
  }

  return (
    <BaseScatolaConTitolo titolo={label}>
      <BaseRadioBoolean
        label='Domicilio coincide con residenza'
        nonUsareHookForm
        value={!valoriInput}
        onChange={e => e.target.value ? eliminaIndirizzo() : creaIndirizzo()}
        readOnly={readOnly}
      />

      {valoriInput &&
        <IndirizzoModifica
          propsEntita={propsEntita} senzaScatola
          mostraDomicilioTemporaneoOAbituale
          modalitaLightConPresso
        />
      }
    </BaseScatolaConTitolo>
  )
}
