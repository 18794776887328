import { BaseTitolo, BaseTesto, BaseButtons } from '@lucan/base-ui';
import MessageBox from 'components/MessageBox';
import React from 'react';


const LoginDisabilitatoTemporanemante = (props) => {
	const {messaggio, logout} = props
	return (
		
		<div>
			<BaseTitolo className="titolo">Accesso bloccato</BaseTitolo>
			<MessageBox severity='warning'  errorMsg={
			{ main: 'Attenzione, l\'utenza è stata temporanemente bloccata, probabilmente per troppi tentativi',
			secondary:messaggio}} />
			<br />
			<BaseButtons.Logout testo="torna al login" color={'primary'} variant="outlined" onClick={logout}  className='full' />
		
		</div>
	);
};

export default LoginDisabilitatoTemporanemante;