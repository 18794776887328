import { useState } from 'react'
import { intervalToDuration, formatDuration } from 'date-fns'
import { it } from 'date-fns/locale'
import { ICONE } from 'icons'
import { BaseButtons } from 'inputs'
import { BaseTesto, BaseGrassetto } from 'text'
import { convertiDataSeServe } from './Date'

function capitalizza(stringa) {
  if (typeof stringa !== 'string') return
  if (stringa === '') return ''
  return `${stringa[0].toUpperCase()}${stringa.slice(1)}`
}

function abbreviaStringaSeServe(stringa, lunghezzaMax = 30) {
  if (typeof stringa !== 'string') return ''
  return (stringa.length <= lunghezzaMax)
    ? stringa
    : `${stringa.slice(0, lunghezzaMax)}...`
}

function getOptionLabel(options, valoreCercato) {
  const optionTrovata = options.find(({ value }) => value === valoreCercato)
  return optionTrovata ? optionTrovata.label : valoreCercato
}

function getArrayOptionLabel(options, valoriCercati) {
  return options
    .filter(({ value }) => valoriCercati.includes(value))
    .map(({ label }) => label)
    .join(', ')
}

function formattaData(data, separatore) {
  let dataStringa = convertiDataSeServe(data).toLocaleDateString('it-IT')
  if (dataStringa === 'Invalid Date') return ''
  return separatore ? dataStringa.replace(/\//g, separatore) : dataStringa
}

function formattaOra(data, separatore) {
  let oraStringa = convertiDataSeServe(data).toLocaleTimeString('it-IT', { hour: '2-digit', minute: '2-digit' })
  if (oraStringa === 'Invalid Date') return ''
  return separatore ? oraStringa.replace(':', separatore) : oraStringa
}

function formattaDataEOra(data, separatoreData, separatoreOra) {
  const dataFormattata = formattaData(data, separatoreData)
  const oraFormattata = formattaOra(data, separatoreOra)
  if (dataFormattata === '' && oraFormattata === '') return ''
  return `${dataFormattata} alle ${oraFormattata}`
}

function formattaIntervalloDate(dataInizio, dataFine, opzioni = {}) {
  const {
    oreMinuti = false
  } = opzioni

  const duration = intervalToDuration({
    start: convertiDataSeServe(dataInizio),
    end: convertiDataSeServe(dataFine)
  })

  let format = ['years', 'months', 'days']
  if (oreMinuti) format.push('hours', 'minutes')
  return formatDuration(duration, {
    locale: it,
    format,
    ...opzioni
  })
}

function formattaNumero(numero) {
  if (typeof numero !== 'number') return numero
  return new Intl.NumberFormat('it-IT', { maximumFractionDigits: 2 }).format(numero)
}

function formattaImportoMonetario(importo, conSimboloEuro = true) {
  if (typeof importo !== 'number') return importo

  const opzioni = conSimboloEuro ? { style: 'currency', currency: 'EUR' } : {}
  return new Intl.NumberFormat('it-IT', {
    ...opzioni,
    maximumFractionDigits: 2
  }).format(importo)
}

function formattaIntervalloMonetario(importoMin, importoMax) {
  const importoMinFormattato = formattaImportoMonetario(importoMin, false)
  const importMaxFormattato = formattaImportoMonetario(importoMax, false)
  return `${importoMinFormattato} - ${importMaxFormattato} €`
}

function formattaBooleano(booleano) {
  if (booleano === false) return 'No'
  if (booleano === true) return 'Si'
  return 'Not a boolean'
}

function formattaFile(file) {
  let fileDefinitivo = (file instanceof FileList) ? file[0] : file
  if (fileDefinitivo instanceof File) return file.name
  return 'Non è un file'
}

function formattaAnagrafiche(anagrafiche) {
  if (!Array.isArray(anagrafiche)) anagrafiche = [anagrafiche]

  return anagrafiche
    .map(anagrafica => {
      const {
        nome,
        cognome,
        check_obbligato_solido,
        check_societa,
        check_trasgressore
      } = anagrafica

      let tipi = []
      if (check_obbligato_solido) tipi.push('obbligato in solido')
      if (check_societa) tipi.push('persona giuridica')
      if (check_trasgressore) tipi.push('trasgressore')
      const tipiStringa = tipi.join(', ')

      return `${capitalizza(cognome)} ${capitalizza(nome)} (${tipiStringa})`
    })
    .join(', ')
}

function formattaArticoli(articoli) {
  if (!Array.isArray(articoli)) articoli = [articoli]

  return articoli
    .map(({ codice, codice_leggibile, importo_min }) =>
      `${codice_leggibile || codice} (importo minimo: ${formattaImportoMonetario(importo_min)})`
    )
    .join(', ')
}

function IntervalloDateFormattato(props) {
  const { labelData, dataInizio, dataFine } = props

  return (
    <BaseTesto>
      <BaseGrassetto>{`${labelData} `}</BaseGrassetto>
      dal <BaseGrassetto>{`${formattaData(dataInizio)} `}</BaseGrassetto>
      al <BaseGrassetto>{formattaData(dataFine)}</BaseGrassetto>
    </BaseTesto>
  )
}

function nascondiSeZero(stringa) {
  if (stringa === 0 || stringa === '0') return ''
  return stringa
}

function formattaCognomeNome(oggettoConNomeCognome) {
  const nome = oggettoConNomeCognome?.nome || ''
  const cognome = oggettoConNomeCognome?.cognome || ''
  if (!nome && !cognome) return '(nome non inserito)'
  return `${capitalizza(cognome)} ${capitalizza(nome)}`
}

function StampaJson(props) {
  const {
    json,
    condizionale = false
  } = props

  const [mostraJson, setMostraJson] = useState(condizionale ? false : true)

  return (
    <div style={{ textAlign: 'left' }}>
      {condizionale &&
        <BaseButtons.Custom
          testo='JSON'
          variant='outlined'
          onClick={() => setMostraJson(mostra => !mostra)}
          iconaFine={mostraJson ? ICONE.ESPANDI_MENO : ICONE.ESPANDI_PIU}
        />
      }
      {mostraJson && <pre>{JSON.stringify(json, null, 2)}</pre>}
    </div>
  )
}

export {
  capitalizza,
  abbreviaStringaSeServe,
  getOptionLabel,
  getArrayOptionLabel,
  formattaData,
  formattaOra,
  formattaDataEOra,
  formattaIntervalloDate,
  formattaNumero,
  formattaImportoMonetario,
  formattaIntervalloMonetario,
  formattaBooleano,
  formattaFile,
  formattaAnagrafiche,
  formattaArticoli,
  IntervalloDateFormattato,
  nascondiSeZero,
  formattaCognomeNome,
  StampaJson
}