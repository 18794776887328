import { Box } from '@mui/material'

export default function RibbonAlfa() {
  return (
    <Box
      sx={({ zIndex }) => ({
        position: 'fixed',
        zIndex: zIndex.drawer + 1,
        top: '24px',
        left: '-38px',
        width: '150px',
        transform: 'rotate(-45deg)',
        fontSize: '20px',
        fontFamily: 'fontFamily',
        fontWeight: 'fontWeightBold',
        padding: '5px',
        color: 'white',
        textAlign: 'center',
        boxShadow: '0 0 5px #888',
        background: 'linear-gradient(hsla(324, 100%, 40%, 0.900000), hsla(324, 100%, 45%, 0.900000), hsla(324, 100%, 40%, 0.900000))',
        boxSizing: 'content-box',
        lineHeight: 'normal'
      })}
    >
      ALFA
    </Box>
  )
}
