import useRichiestaServer from '../../../network/RichiestaServerHook'
import { useUserUpdate } from '../UserProvider'
import { mergeSegmentiChiave } from './utilPermessiUser'

export default function usePermessiUserConComando(props) {
  const {
    impostaGruppiUtente,
    nonFareRichiestaPermessi
  } = props

  const {
    sonoPresenti_PermessiUserConComando,
    impostaPermessiUserConComando
  } = useUserUpdate()

  const {
    ScatolaMessaggi,
    OPERAZIONI: { PERMESSI_UTENTE_ELENCO },
    inviaOperazione,
    inAttesaDelServer
  } = useRichiestaServer()

  async function richiediEImpostaPermessiUserConComando(comando, userInfo, permessiGiaOttenuti) {
    // I permessiGiaOttenuti esistono solo nel caso in cui faccio il login
    // con un utente che ha un solo comando, quindi ricevo i permessi subito
    // in fase di login e non devo fare un'ulteriore richiesta
    if (permessiGiaOttenuti) {
      impostaPermessiUserConComando(trasformaPermessi(permessiGiaOttenuti), comando)
      return { ok: true }
    }

    if (nonFareRichiestaPermessi || sonoPresenti_PermessiUserConComando(comando)) {
      return { ok: true }
    }

    let opzioni = { comando }
    if (userInfo) {
      opzioni.username = userInfo.info.nickname
      opzioni.token = userInfo.token
    }
    const { ok, data } = await inviaOperazione(PERMESSI_UTENTE_ELENCO, null, opzioni)
    if (ok) {
      const { permessi, gruppi } = data
      impostaPermessiUserConComando(trasformaPermessi(permessi), comando)
      impostaGruppiUtente(gruppi)
    }
    return { ok }
  }

  return {
    richiediEImpostaPermessiUserConComando,
    ScatolaMessaggi_RichiestaPermessiUser: ScatolaMessaggi,
    inAttesa_RichiestaPermessiUser: inAttesaDelServer
  }
}



function trasformaPermessi(permessi) {
  return permessi.reduce(
    (permessiAccumulatore, { modulo, azione, permesso }) => {
      const chiave = azione ? mergeSegmentiChiave(modulo, azione) : modulo
      return {
        ...permessiAccumulatore,
        [chiave]: permesso
      }
    },
    {}
  )
}