import { useCallback, useEffect, useRef, useState } from 'react'
import { getData, nonInProduzione, useInterval } from 'utils'

const UN_ORA_IN_MILLISECONDI = 3600000

export default function useControllaVersioneAggiornata(props) {
  const { urlBuildInfo, versione } = props

  const [isVersioneAggiornata, setIsVersioneAggiornata] = useState(true)

  const primaRichiestaFattaRef = useRef(false)
  
  const ricaricaApplicazione = useCallback(() => {
    window.localStorage.clear()
    window.sessionStorage.clear()
    window.location.reload()
  }, [])

  const controllaSeVersioneAggiornata = useCallback(async () => {
    if (nonInProduzione() || !urlBuildInfo || !versione) return
    try {
      // Faccio una chiamata per ottenere le info più aggiornate sulla versione
      // Mando il timestamp attuale nella query string per impedire il caching della risposta
      const { data } = await getData(urlBuildInfo, { params: { ts: Date.now() } })
      // Controllo se la versione del server coincide con quella corrente
      const isAggiornata = data.APP_LATEST_VERSION.trim() === versione.trim()
      // Se ho appena aperto l'applicazione e c'è un aggiornamento, ricarico subito la pagina
      if (primaRichiestaFattaRef.current === false && !isAggiornata) {
        ricaricaApplicazione()
      } else {
        setIsVersioneAggiornata(isAggiornata)
      }
      primaRichiestaFattaRef.current = true
    } catch (error) {
      console.warn('Impossibile ottenere info sulla versione più aggiornata dell\'applicazione. URL usato per la richiesta:', urlBuildInfo)
    }
  }, [urlBuildInfo, versione, ricaricaApplicazione])

  // Controllo appena l'app viene aperta
  useEffect(() => {
    controllaSeVersioneAggiornata()
  }, [controllaSeVersioneAggiornata])

  // Controllo una volta ogni ora
  useInterval(() => {
    controllaSeVersioneAggiornata()
  }, UN_ORA_IN_MILLISECONDI)

  return { isVersioneAggiornata, ricaricaApplicazione }
}