import { createTheme, ThemeProvider, CssBaseline } from '@mui/material'
import { itIT } from '@mui/material/locale'

/**
 * @typedef {import("../../common/incloudApps/util/CustomThemeProvider").CustomThemeProvider } CustomThemeProvider 
 * @typedef {import("../../common/incloudApps/util/CustomThemeProvider").CustomThemeProviderProps } CustomThemeProviderProps 
 */

const DISABLED_CURSOR = { cursor: 'not-allowed' }
const DISABLED_COLOR = { color: 'rgba(0, 0, 0, 0.6)', WebkitTextFillColor: 'rgba(0, 0, 0, 0.6)' }
const DISABLED_BACKGROUND_COLOR = { backgroundColor: 'rgba(0, 0, 0, 0.04)' }

function stileDisabled(...regole) {
  const oggettoConTutteLeRegole = regole.reduce(
    (accumulatoreRegole, regola) => ({ ...accumulatoreRegole, ...regola }),
    {}
  )
  return { '&.Mui-disabled': oggettoConTutteLeRegole }
}

const theme = createTheme({
  name: 'default',
  palette: {
    background: {
      default: '#e0e0e0',
    },
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: { fontSize: '1rem' }
      }
    },
    MuiButton: {
      styleOverrides: {
        containedInherit: {
          '&:hover': { backgroundColor: '#e0e0e0' }
        }
      }
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          '&.MuiStepLabel-alternativeLabel': { marginTop: '8px', fontSize: '1rem' }
        }
      }
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&.Mui-completed': { color: '#2e7d32' }
        }
      }
    },

    // Modifico lo stile di textField, checkbox e radio quando sono disabilitati
    MuiInputBase: {
      styleOverrides: {
        root: stileDisabled(DISABLED_BACKGROUND_COLOR),
        input: stileDisabled(DISABLED_CURSOR, DISABLED_COLOR)
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: stileDisabled(DISABLED_CURSOR)
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        colorPrimary: stileDisabled(DISABLED_COLOR)
      }
    },
    MuiRadio: {
      styleOverrides: {
        colorPrimary: stileDisabled(DISABLED_COLOR)
      }
    },
    MuiFormControlLabel: { // Label di checkbox e radio
      styleOverrides: {
        root: stileDisabled(DISABLED_COLOR, DISABLED_CURSOR),
        label: stileDisabled(DISABLED_COLOR)
      }
    }
  }
}, itIT)

/**
 * 
 * @param {CustomThemeProviderProps} props 
 * @type {CustomThemeProvider}
 */
export default function CustomThemeProvider(props) {
  const { children, theme: altTheme } = props

  return (
    <ThemeProvider theme={altTheme ?? theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}