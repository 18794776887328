import { useController, useForm } from 'react-hook-form'
import { creaFunzioneUnica } from 'utils'

// Questo hook è un workaround per permettere di usare il controller solo se serve
// Per le regole degli hook, devo sempre chiamare useController; se il componente padre
// non vuole usare l'input con hook form, ma come semplice componente controllato, questo
// hook chiama useController con parametri fasulli per poi scartare i suoi risultati e
// ritornare { value, onBlur, onChange } passati come props direttamente dal padre
export default function useControllerSeServe(inputGenericoProps) {
  const {
    // Props passate con hook form
    control: controlEffettivo,
    rules,
    defaultValue,
    shouldUnregister,
    // Props passate senza hook form (semplice componente controllato dal padre)
    value,
    onBlur: onBlur_InputGenerico = () => {}, // Può anche includere la funzione onBlurTuttiCampi di BaseForm 
    onChange: onChange_InputGenerico = () => {}, // Può anche includere la funzione onChangeTuttiCampi di BaseForm 
    // Altre props
    ...propsRimanenti
  } = inputGenericoProps

  const controllerNecessario = Boolean(controlEffettivo)

  // Ottengo un oggetto control fasullo da useForm
  const { control: controlFasullo } = useForm()

  const risultatiUseController = useController(controllerNecessario ? {
    // Passo i parametri effettivi se serve hook form
    name: propsRimanenti.name,
    control: controlEffettivo,
    rules,
    defaultValue,
    shouldUnregister
  } : {
    // Passo dei parametri fasulli se non serve hook form
    name: 'n',
    control: controlFasullo,
    rules: {},
    defaultValue: ''
  })

  const propsGestioneInput = controllerNecessario
    ? calcolaProps_ConController(risultatiUseController, onBlur_InputGenerico, onChange_InputGenerico)
    : calcolaProps_SenzaController(propsRimanenti.name, value, onBlur_InputGenerico, onChange_InputGenerico)

  return {
    controllerNecessario,
    ...propsGestioneInput,
    propsRimanenti
  }
}

function calcolaProps_ConController(risultatiUseController, onBlur_InputGenerico, onChange_InputGenerico) {
  let { field, meta } = risultatiUseController
  // Unisco le funzioni onBlur e onChange del controller a quelle passate dall'input generico
  // (che a loro volta possono unire quelle passate come prop e quelle fornite dal context di BaseForm)
  field.onBlur = creaFunzioneUnica(field.onBlur, onBlur_InputGenerico)
  field.onChange = creaFunzioneUnica(field.onChange, onChange_InputGenerico)
  return { ...field, ...meta }
}

function calcolaProps_SenzaController(name, value, onBlur_InputGenerico, onChange_InputGenerico) {
  return {
    value,
    onBlur: onBlur_InputGenerico,
    // La funzione onChange usata internamente negli input viene chiamata con il solo parametro nuovoValore
    // Invece, la funzione onChange usata esternamente viene chiamata con la classica struttura
    // degli eventi DOM. Il padre può leggere il nuovo valore del campo dentro e.target.value
    onChange: nuovoValore => onChange_InputGenerico({ target: { name, value: nuovoValore } })
  }
}
