import { Children, cloneElement } from 'react'
import { func, string, node, object, bool } from 'prop-types'
import { BaseButtons } from 'inputs'
import { BaseScatola, BaseGridLayout } from 'layout'
import { BaseTitolo } from 'text'
import BaseContenutoConProgress from './BaseContenutoConProgress'
import { BasePopover, usePopover } from './BasePopover'

BasePopoverConfermaOperazione.propTypes = {
  children: node.isRequired,
  eventoTrigger: string,
  messaggio: node,
  onConferma: func.isRequired,
  onAnnulla: func,
  propsPopover: object,
  propsBottoneConferma: object,
  propsBottoneAnnulla: object,
  onAperturaPopover: func,
  inAttesaPrimaDiChiedereConferma: bool
}

export default function BasePopoverConfermaOperazione(props) {
  const {
    children,
    eventoTrigger = 'onClick',
    messaggio = 'Confermare l\'operazione?',
    onConferma,
    onAnnulla,
    propsPopover,
    propsBottoneConferma,
    propsBottoneAnnulla,
    onAperturaPopover,
    inAttesaPrimaDiChiedereConferma = false
  } = props

  const {
    popoverRef,
    openPopover,
    closePopover
  } = usePopover()

  function confermaOperazione() {
    closePopover()
    onConferma()
  }

  function annullaOperazione() {
    closePopover()
    onAnnulla?.()
  }

  function openPopoverConCallback(event) {
    openPopover(event)
    onAperturaPopover()
  }

  const figlioConEventoPerAprirePopover = cloneElement(
    Children.only(children),
    { [eventoTrigger]: onAperturaPopover ? openPopoverConCallback : openPopover }
  )

  return (
    <>
      {figlioConEventoPerAprirePopover}

      <BasePopover ref={popoverRef} {...propsPopover}>
        <BaseScatola
          centraContenuto
          onClick_BottoneChiudi={closePopover}
        >
          <BaseContenutoConProgress
            inAttesa={inAttesaPrimaDiChiedereConferma}
            sx={{ width: 300, m: 2 }}
          >
            <BaseTitolo>{messaggio}</BaseTitolo>

            <BaseGridLayout justifyCenter>
              <BaseButtons.Conferma
                onClick={confermaOperazione}
                {...propsBottoneConferma}
              />
              <BaseButtons.Annulla
                onClick={annullaOperazione}
                color='default'
                {...propsBottoneAnnulla}
              />
            </BaseGridLayout>
          </BaseContenutoConProgress>
        </BaseScatola>
      </BasePopover>
    </>
  )
}