import { BaseTextInput } from 'inputs'
import { BaseScatolaConTitolo, BaseGridLayout } from 'layout'
import { isEmailValida, isNumeroTelefonoValido } from 'utils'
import FormModificaEntita, { PROP_TYPES_WRAPPER_FORM_MODIFICA } from '../entitaGenerica/components/FormModificaEntita'

const NOMI = {
  EMAIL: 'email',
  TELEFONO: 'telefono',
  RELAZIONE_CON_ALTRI_SOGGETTI: 'relazioneConAltriSoggetti',

  // Attenzione: salvato al primo livello della Persona, non nei contatti
  PEC: 'pec'
}

ContattiModifica.propTypes = PROP_TYPES_WRAPPER_FORM_MODIFICA

export default function ContattiModifica(props) {
  const { propsEntita, propsEntitaPersona, label } = props

  return (
    <BaseScatolaConTitolo titolo={label}>
      <FormModificaEntita {...propsEntita}>
        <BaseGridLayout disposizioneRighe={[3]}>
          <BaseTextInput
            label='Email' name={NOMI.EMAIL}
            larghezza={20} type='email'
          />
          {/*
            Il campo pec è salvato al primo livello della Persona, quindi
            devo inserire un'altra form con le propsEntita della Persona
          */}
          <FormModificaEntita {...propsEntitaPersona}>
            <BaseTextInput
              label='PEC' name={NOMI.PEC}
              larghezza={20} type='email'
            />
          </FormModificaEntita>

          <BaseTextInput
            label='Telefono' name={NOMI.TELEFONO}
            larghezza={10} type='telefono'
          />
          <BaseTextInput
            label='Relazione con altri soggetti'
            name={NOMI.RELAZIONE_CON_ALTRI_SOGGETTI}
            larghezza={30} hidden
          />
        </BaseGridLayout>
      </FormModificaEntita>
    </BaseScatolaConTitolo>
  )
}



export function validaPersonaContatti(utilValidazione) {
  const { valoriInput, warn } = utilValidazione
  const { email, telefono } = valoriInput

  if (!isEmailValida(email)) {
    warn('Email non valida', NOMI.EMAIL)
  }
  if (!isNumeroTelefonoValido(telefono)) {
    warn('Telefono non valido', NOMI.TELEFONO)
  }
}