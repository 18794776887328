
/**
 * @typedef {import("../../common/tables/datagrid/dataGridFilters").Operator } Operator 
 * @typedef {import("../../common/tables/datagrid/dataGridFilters").FilterOperator } FilterOperator 
 */

/**
 * Operators available for filtering
 * @type {Operator}
 */
export const Operator = {
    EQ: 0,
    GT: 1,
    LT: 2,
    GTE: 3,
    LTE: 4,
    LIKE: 5,
    IN: 6
}


/**
 * Operators available for filter by string type
 * @type {Array<FilterOperator>}
 */
export const stringOperators = [
    {
        identifier: Operator.EQ,
        name: 'Uguale'
    }
]

/**
 * Operators available for filter by number type
 * @type {Array<FilterOperator>}
 */
export const numberOperators = [
    {
        identifier: Operator.EQ,
        name: '='
    },
    {
        identifier: Operator.GT,
        name: '>'
    },
    {
        identifier: Operator.GTE,
        name: '>='
    },
    {
        identifier: Operator.LT,
        name: '<'
    },
    {
        identifier: Operator.LTE,
        name: '<='
    },
    {
        identifier: Operator.IN,
        name: 'Contenuto'
    }
]
