import { BaseSelectMultipla, BaseSelect, BaseDateInput, BaseTextInput, BaseCheckbox } from 'inputs'
import { generaOptionsAnniPassati } from 'utils'
import { ELENCHI, ELENCHI_BASE_CONFIG } from './Elenchi'

const CAMPO_DA_SCARTARE = '$__SCARTA__$'

const OPTIONS_DATA_SELEZIONE = [
  { value: 'infrazioneData', label: 'Data infrazione' }
]

const OPTIONS_DATA_SELEZIONE_CON_PAGAMENTO = [
  { value: 'pagamentoDataEsecuzione', label: 'Data pagamento' },
  { value: 'pagamentoDataAccredito', label: 'Data accredito' },
  { value: 'infrazioneDataNotifica', label: 'Data notifica' },
  ...OPTIONS_DATA_SELEZIONE
]

const OPTIONS_DATA_SELEZIONE_CON_SPEDIZIONE = [
  ...OPTIONS_DATA_SELEZIONE,
  { value: 'verbalizzazioneData', label: 'Data verbalizzazione' },
  { value: 'notificaData', label: 'Data notifica' },
  { value: 'spedizioneData', label: 'Data spedizione' }
]

const OPTIONS_DATA_RIFERIMENTO = [
  { value: 'notificaData', label: 'Data notifica' },
  { value: 'spedizioneData', label: 'Data spedizione' },
  ...OPTIONS_DATA_SELEZIONE
]

const OPTIONS_ANNO_COMPETENZA_generator = ({ numeroAnniPassati }) => [
  { value: CAMPO_DA_SCARTARE, label: 'Tutti' },
  ...generaOptionsAnniPassati(numeroAnniPassati)
]

const OPTIONS_MODALITA_PAGAMENTO = [
  { value: CAMPO_DA_SCARTARE, label: 'Tutte' },
  { value: 'pagopa', label: 'PagoPA' },
  { value: 'pagopa_pos', label: 'PagoPA POS' },
  { value: 'ccp', label: 'C/C postale' },
  { value: 'cassa', label: 'Cassa' },
  { value: 'bancomat', label: 'Bancomat' },
  { value: 'cc', label: 'Carta di credito' },
  { value: 'ccb', label: 'Bonifico bancario' },
  { value: 'tesoreria', label: 'Tesoreria' },
  { value: 'accertatore', label: 'Accertatore su strada' },
  { value: 'F23', label: 'F23' },
  { value: 'altro_ente', label: 'Altro ente' }
]

const OPTIONS_PROCEDURA = [
  { value: CAMPO_DA_SCARTARE, label: 'Tutte' },
  { value: 'preavviso', label: 'Preavviso' },
  { value: 'verbale', label: 'Verbale' },
  { value: 'sanzione', label: 'Sanzione' },
  { value: 'ordinanza', label: 'Ordinanza' }
]

const OPTIONS_STATO_INFRAZIONE = [
  { value: CAMPO_DA_SCARTARE, label: 'Tutti' },
  { value: 'archiviato', label: 'Archiviato' },
  { value: 'inserito', label: 'Inserito' },
  { value: 'notificato', label: 'Notificato' },
  { value: 'pagato', label: 'Pagato' },
  { value: 'pagato_parz', label: 'Pagato parzialmente' },
  { value: 'pagato_temp', label: 'Pagato senza notifica' },
  { value: 'sospeso', label: 'Sospeso' },
  { value: 'spedito', label: 'Spedito' }
]

const OPTIONS_TIPO_ANAGRAFICA = [
  { value: CAMPO_DA_SCARTARE, label: 'Tutti (fisica, giuridica, sconosciuta)' },
  { value: 'pf', label: 'P. fisica'},
  { value: 'pg', label: 'P. giuridica'},
  { value: 'undef', label: 'Sconosciuta'}
]

const CAMPI = {
  DATA_SELEZIONE_CON_PAGAMENTO: {
    label: 'Data selezione',
    name: 'dataSelezione',
    options: OPTIONS_DATA_SELEZIONE_CON_PAGAMENTO,
    Input: BaseSelect,
    defaultValue: 'pagamentoDataEsecuzione'
  },
  DATA_SELEZIONE_CON_SPEDIZIONE: {
    label: 'Data selezione',
    name: 'dataSelezione',
    options: OPTIONS_DATA_SELEZIONE_CON_SPEDIZIONE,
    Input: BaseSelect,
    defaultValue: 'infrazioneData'
  },
  DATA_RIFERIMENTO: {
    label: 'Data riferimento',
    name: 'dataRiferimento',
    options: OPTIONS_DATA_RIFERIMENTO,
    Input: BaseSelect,
    defaultValue: 'notificaData'
  },


  DATA_INIZIO: {
    label: 'Data inizio',
    name: 'dataInizio',
    Input: BaseDateInput,
    defaultValue: null
  },
  DATA_FINE: {
    label: 'Data fine',
    name: 'dataFine',
    Input: BaseDateInput,
    defaultValue: null,
    props: { portaOraAFineGiornata: true }
  },
  ANNO_COMPETENZA: {
    label: 'Anno competenza',
    name: 'infrazioneAnnoRegistro',
    props: {
      validazione: {
        setValueAs: valore => valore === CAMPO_DA_SCARTARE ? CAMPO_DA_SCARTARE : Number(valore)
      }
    },
    options: OPTIONS_ANNO_COMPETENZA_generator({ numeroAnniPassati: 10 }),
    optionGenerator: OPTIONS_ANNO_COMPETENZA_generator,
    Input: BaseSelect,
    defaultValue: CAMPO_DA_SCARTARE
  },


  MODALITA_PAGAMENTO: {
    label: 'Modalità pagamento',
    name: 'pagamentoTipo',
    options: OPTIONS_MODALITA_PAGAMENTO,
    Input: BaseSelectMultipla,
    defaultValue: [CAMPO_DA_SCARTARE],
    props: { primaOpzioneSelezionaTutte: true }
  },
  PROCEDURA: {
    label: 'Procedura',
    name: 'infrazioneProcedura',
    options: OPTIONS_PROCEDURA,
    Input: BaseSelectMultipla,
    defaultValue: [CAMPO_DA_SCARTARE],
    props: { primaOpzioneSelezionaTutte: true }
  },
  STATO_INFRAZIONE: {
    label: 'Stato violazione',
    name: 'statoViolazione',
    options: OPTIONS_STATO_INFRAZIONE,
    Input: BaseSelectMultipla,
    defaultValue: [CAMPO_DA_SCARTARE],
    props: { primaOpzioneSelezionaTutte: true }
  },
  TIPO_ANAGRAFICA: {
    label: 'Tipo anagrafica',
    name: 'anagraficaTipo',
    options: OPTIONS_TIPO_ANAGRAFICA,
    Input: BaseSelect,
    defaultValue: CAMPO_DA_SCARTARE
  },


  TARGA: {
    label: 'Targa',
    name: 'targa',
    Input: BaseTextInput,
    defaultValue: '',
    props: {
      type: 'targa',
      validazione: {
        setValueAs: valore => valore.toUpperCase()
      }
    }
  },
  NOME: {
    label: 'Nome',
    name: 'nome',
    Input: BaseTextInput,
    defaultValue: ''
  },
  COGNOME: {
    label: 'Cognome',
    name: 'cognome',
    Input: BaseTextInput,
    defaultValue: ''
  },
  AGENTI: {
    label: 'Agenti',
    name: 'agenti',
    Input: BaseTextInput,
    defaultValue: '',
    props: {
      messaggioAiuto: 'Cognomi di uno o più agenti separati da virgole',
      validazione: {
        setValueAs: valore => {
          if (!valore) return []
          return valore.split(',').map(val => val.trim())
        }
      }
    }
  },
  LUOGO: {
    label: 'Luogo',
    name: 'via',
    Input: BaseTextInput,
    defaultValue: '',
    props: {
      messaggioAiuto: 'Solo il nome della via (esempio: "Mazzini")'
    }
  },


  INDIRIZZO_TIPO: {
    label: 'Tipo indirizzo',
    name: 'tipo',
    options: ELENCHI_BASE_CONFIG[ELENCHI.INDIRIZZO_TIPO].default,
    Input: BaseSelect,
    defaultValue: 'via'
  },
  INDIRIZZO_VIA: {
    label: 'Nome via',
    name: 'via',
    Input: BaseTextInput,
    defaultValue: ''
  },
  INDIRIZZO_NUMERO_CIVICO: {
    label: 'N. civico',
    name: 'numeroCivico',
    Input: BaseTextInput,
    defaultValue: ''
  },
  INDIRIZZO_CAP: {
    label: 'CAP',
    name: 'cap',
    Input: BaseTextInput,
    defaultValue: ''
  },
  INDIRIZZO_COMUNE: {
    label: 'Comune',
    name: 'comune',
    Input: BaseTextInput,
    defaultValue: ''
  },
  INDIRIZZO_PROVINCIA: {
    label: 'Provincia',
    name: 'provincia',
    Input: BaseTextInput,
    defaultValue: ''
  },
  INDIRIZZO_NAZIONE: {
    label: 'Nazione',
    name: 'nazione',
    options: [{ label: 'Italia', value: 'Italy' }],
    Input: BaseSelect,
    defaultValue: 'Italy'
  },
  INDIRIZZO_PRESSO: {
    label: 'Presso',
    name: 'presso',
    Input: BaseTextInput,
    defaultValue: ''
  },
  INDIRIZZO_FRONTE_CIVICO: {
    label: 'Fronte civico',
    name: 'fronteCivico',
    Input: BaseCheckbox,
    defaultValue: false
  },


  // : {
  //   label: '',
  //   name: '',
  //   options: ,
  //   optionGenerator: ,
  //   Input: ,
  //   defaultValue: ,
  //   props: {},
  //   aiuto: ''
  // },
}

export {
  CAMPI,
  CAMPO_DA_SCARTARE
}