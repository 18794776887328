import { BaseTextInput } from 'inputs'
import { BaseGridLayout } from 'layout'
import FormModificaEntita, { PROP_TYPES_WRAPPER_FORM_MODIFICA } from '../entitaGenerica/components/FormModificaEntita'
import ScatolaEntita from '../entitaGenerica/components/ScatolaEntita'

const NOMI = {
  RECAPITO_RESIDENZA: 'recapitoResidenza',
  RECAPITO_CASA_ABITAZIONE: 'recapitoCasaAbitazione',
  RECAPITO_LAVORO: 'recapitoLavoro',
  RECAPITO_DOMICILIO: 'recapitoDomicilio',
  ALTRI_RECAPITI: 'altriRecapiti'
}

RecapitiAggiuntiviModifica.propTypes = PROP_TYPES_WRAPPER_FORM_MODIFICA

export default function RecapitiAggiuntiviModifica(props) {
  const { propsEntita } = props

  return (
    <ScatolaEntita
      noModifica noElimina
      propsBottoneCrea={{
        testo: 'Inserisci recapiti aggiuntivi',
        opzioniCreazione: {
          entitaGiaCreata: {
            recapitoResidenza: '',
            recapitoCasaAbitazione: '',
            recapitoLavoro: '',
            recapitoDomicilio: '',
            altriRecapiti: ''
          }
        }
      }}
      {...props}
    >
      {propsEntita.valoriInput &&
        <FormModificaEntita {...propsEntita} larghezzaInputs={40}>
          <BaseGridLayout vertical spacing={0}>
            <BaseTextInput
              label='Recapito telefonico e telematico presso residenza'
              name={NOMI.RECAPITO_RESIDENZA}
            />
            <BaseTextInput
              label='Recapito telefonico e telematico presso casa di abitazione'
              name={NOMI.RECAPITO_CASA_ABITAZIONE}
            />
            <BaseTextInput
              label='Recapito telefonico e telematico presso attività lavorativa'
              name={NOMI.RECAPITO_LAVORO}
            />
            <BaseTextInput
              label='Recapiti telefonici e telematici dei luoghi di temporanea dimora / domicilio'
              name={NOMI.RECAPITO_DOMICILIO}
            />
            <BaseTextInput
              label='Altri recapiti telefonici e telematici nella sua disponibilità'
              name={NOMI.ALTRI_RECAPITI}
            />
          </BaseGridLayout>
        </FormModificaEntita>
      }
    </ScatolaEntita>
  )
}