import { array, object, oneOfType, shape, string } from 'prop-types'
import { BaseScatoleNavigazione } from 'navigation'
import { BaseTitolo } from 'text'
import { findRouteWithKey } from 'utils'
import { useUser } from '../userManagement/userProvider/UserProvider'

/**
 * @typedef {import('../../common/incloudApps/navigation/ScatoleNavigazione').ScatoleNavigazioneProps} ScatoleNavigazioneProps
 * @typedef {import('../../common/incloudApps/navigation/ScatoleNavigazione').ScatoleNavigazione} ScatoleNavigazione
 */


ScatoleNavigazione.propTypes = {
  titolo: string,
  soloSubRoutesDi: oneOfType([
    // Si può passare la chiave della route
    string,
    // Oppure l'oggetto della route
    shape({
      subRoutes: oneOfType([array, object])
    })
  ])
}

/**
 * 
 * @param {ScatoleNavigazioneProps} props 
 * @type {ScatoleNavigazione}
 */
export default function ScatoleNavigazione(props) {
  const {
    titolo,
    soloSubRoutesDi,
    ...propsScatole
  } = props

  const { routesVisibiliNeiMenu_VisibiliDalloUser } = useUser()

  let routesDaMostrare = routesVisibiliNeiMenu_VisibiliDalloUser
  let titoloDefinitivo = titolo

  if (soloSubRoutesDi) {
    const parentRoute = (typeof soloSubRoutesDi === 'string')
      ? findRouteWithKey(routesVisibiliNeiMenu_VisibiliDalloUser, soloSubRoutesDi)
      : soloSubRoutesDi
    routesDaMostrare = parentRoute.subRoutes
    titoloDefinitivo = parentRoute.label
  }

  return (
    <>
      <BaseTitolo grande>{titoloDefinitivo}</BaseTitolo>
      <BaseScatoleNavigazione
        routes={routesDaMostrare}
        {...propsScatole}
      />
    </>
  )
}

