import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { BaseSelect, BaseTextInput, BaseDateInput, BaseCheckbox } from 'inputs'
import { BaseScatolaConTitolo, BaseGridLayout } from 'layout'
import ElenchiManager, { ELENCHI } from '../../form/ElenchiManager'
import { ENTITA_BASE } from '../EntitaBase'
import { useEntita } from '../entitaGenerica/components/EntitaContext'
import FormModificaEntita, { PROP_TYPES_WRAPPER_FORM_MODIFICA } from '../entitaGenerica/components/FormModificaEntita'

const NOMI = {
  DATA_PRIMO_RILASCIO: 'dataPrimoRilascio',
  DATA_RILASCIO: 'dataRilascio',
  DATA_SCADENZA: 'dataScadenza',
  NUMERO: 'numero',
  PATENTE_CQC: 'patenteCQC',
  PATENTE_CQC_TIPO: 'patenteCQCTipo',
  PATENTE_CATEGORIA: 'patenteCategoria',
  PATENTE_REVOCATA: 'patenteRevocata',
  PATENTE_SOSPENSIONE_O_REVOCA_DESC: 'patenteSospensioneORevocaDesc',
  PATENTE_SOSPESA: 'patenteSospesa',
  RILASCIATO_DA_AUTORITA: 'rilasciatoDaAutorita',
  RILASCIATO_DA_LUOGO: 'rilasciatoDaLuogo',
  TIPO: 'tipoDocumento',
  TIPO_ALTRO: 'tipoAltro',
  CUI: 'cui',
  FOTOSEGNALAZIONE_PRESSO: 'fotosegnalazionePresso',
  NOTE: 'note',
  RILIEVI_ANTROPOMETRICI: 'rilieviAntropometrici'
}

// Riporto qui solo quelli che fanno scattare logiche particolari
const OPTIONS_TIPO = {
  PATENTE: 'patente',
  FOTOSEGNALAZIONE: 'fotosegnalazione',
  ALTRO_DOCUMENTO: 'altroDocumento',
  NESSUNO: 'nessuno',
  SEDICENTE: 'sedicente',
  CONFERMATO_DA_ALTRI: 'confermatoDaAltri',
  ALTRA_MODALITA: 'altraModalita'
}

DocumentoModifica.propTypes = PROP_TYPES_WRAPPER_FORM_MODIFICA

export default function DocumentoModifica(props) {
  const { propsEntita, label, mostraRilieviAntropometrici, mostraNumeroCUI, modalitaLight } = props
  const { pathEntita, valoriInput } = propsEntita

  return (
    <BaseScatolaConTitolo titolo={label}>
      <ElenchiManager
        elenchi={[
          ELENCHI.DOCUMENTO_TIPO,
          ELENCHI.DOCUMENTO_RILASCIO,
          ELENCHI.DOCUMENTO_CQC,
          ELENCHI.FOTOSEGNALAZIONE_PRESSO
        ]}
        render={el =>
          <>
            <FormModificaEntita {...propsEntita}>
              <FormDocumento
                el={el}
                modalitaLight={modalitaLight}
                mostraNumeroCUI={mostraNumeroCUI}
              />
            </FormModificaEntita>

            {mostraRilieviAntropometrici &&
              <RilieviAntropometriciModifica
                propsEntita={{
                  valoriInput: valoriInput[NOMI.RILIEVI_ANTROPOMETRICI],
                  nomeEntita: ENTITA_BASE.PERSONA_DOCUMENTO_RILIEVI_ANTROPOMETRICI,
                  pathEntita: pathEntita.addPath(NOMI.RILIEVI_ANTROPOMETRICI)
                }}
              />
            }
          </>
        }
      />
    </BaseScatolaConTitolo>
  )
}



function dettagliDocumentoDaNascondere(tipoDocumento) {
  return (
    tipoDocumento === OPTIONS_TIPO.NESSUNO
    || tipoDocumento === OPTIONS_TIPO.SEDICENTE
    || tipoDocumento === OPTIONS_TIPO.CONFERMATO_DA_ALTRI
    || tipoDocumento === OPTIONS_TIPO.ALTRA_MODALITA
  )
}

export function FormDocumento(props) {
  const { el, modalitaLight, mostraNumeroCUI } = props

  const { watch, setValue } = useFormContext()
  const [
    tipoDocumentoPair,
    patenteCqc,
    patenteRevocata,
    patenteSospesa,
    rilasciatoDaAutorita
  ] = watch([
    NOMI.TIPO,
    NOMI.PATENTE_CQC,
    NOMI.PATENTE_REVOCATA,
    NOMI.PATENTE_SOSPESA,
    NOMI.RILASCIATO_DA_AUTORITA,
    NOMI.NUMERO
  ])

  const tipoDocumento = tipoDocumentoPair?.first ?? ''
  const isNotPatente = (tipoDocumento !== OPTIONS_TIPO.PATENTE)
  const isNotAltroDocumento = (tipoDocumento !== OPTIONS_TIPO.ALTRO_DOCUMENTO)
  const isNotFotosegnalazione = (tipoDocumento !== OPTIONS_TIPO.FOTOSEGNALAZIONE)
  const nascondiDettagliDocumento = dettagliDocumentoDaNascondere(tipoDocumento)

  useEffect(() => {
    if (!isNotPatente && rilasciatoDaAutorita === '') {
      setValue(NOMI.RILASCIATO_DA_AUTORITA, 'mctc')
    }
  }, [isNotPatente])

  const larghezza = 25

  return (
    <BaseGridLayout disposizioneRighe={[4, 1, 1]} alignCenter>
      <BaseSelect
        label='Tipo documento / identificazione' name={NOMI.TIPO} pair
        options={el(ELENCHI.DOCUMENTO_TIPO)} larghezza={larghezza}
      />
      <BaseTextInput
        label='Specificare tipo documento' name={NOMI.TIPO_ALTRO}
        hidden={isNotAltroDocumento} larghezza={20}
      />
      <BaseTextInput
        label='Numero CUI' name={NOMI.CUI}
        hidden={!mostraNumeroCUI} larghezza={10}
      />
      <BaseSelect
        label='Fotosegn. effettuata presso'
        name={NOMI.FOTOSEGNALAZIONE_PRESSO} pair
        options={el(ELENCHI.FOTOSEGNALAZIONE_PRESSO)}
        hidden={isNotFotosegnalazione}
      />

      <BaseGridLayout hidden={nascondiDettagliDocumento} disposizioneRighe={[2, 2, 2]}>
        <BaseTextInput
          label='Numero documento' name={NOMI.NUMERO}
        />
        <BaseTextInput
          label='Luogo rilascio' name={NOMI.RILASCIATO_DA_LUOGO}
        />
        <BaseDateInput
          label='Data rilascio' name={NOMI.DATA_RILASCIO} disableFuture
        />
        <BaseDateInput
          label='Data scadenza' name={NOMI.DATA_SCADENZA}
        />

        <BaseGridLayout hidden={modalitaLight || isNotPatente} disposizioneRighe={[3, 2, 3]} alignCenter>
          <BaseTextInput
            label='Categoria patente' name={NOMI.PATENTE_CATEGORIA}
          />
          <BaseSelect
            label='Ente rilascio' name={NOMI.RILASCIATO_DA_AUTORITA}
            options={el(ELENCHI.DOCUMENTO_RILASCIO)}
            {...(rilasciatoDaAutorita && { InputLabelProps: { shrink: true } })}
          />
          <BaseDateInput
            label='Data abilitazione' name={NOMI.DATA_PRIMO_RILASCIO}
          />

          <BaseCheckbox
            label='Certificato CQC' name={NOMI.PATENTE_CQC}
          />
          <BaseSelect
            label='Tipo CQC' name={NOMI.PATENTE_CQC_TIPO} pair
            options={el(ELENCHI.DOCUMENTO_CQC)} hidden={!patenteCqc}
          />

          <BaseCheckbox
            label='Patente revocata' name={NOMI.PATENTE_REVOCATA}
          />
          <BaseCheckbox
            label='Patente sospesa' name={NOMI.PATENTE_SOSPESA}
          />
          <BaseTextInput
            label='Motivazione revoca / sospensione' name={NOMI.PATENTE_SOSPENSIONE_O_REVOCA_DESC}
            hidden={!patenteRevocata && !patenteSospesa} larghezza={27}
          />
        </BaseGridLayout>
        <></>
      </BaseGridLayout>

      <BaseTextInput
        label='Note' name={NOMI.NOTE}
        multiline rows={2} larghezza={larghezza}
        {...(nascondiDettagliDocumento && { sx: { mt: 4 } })}
      />
    </BaseGridLayout>
  )
}



export function validaPersonaDocumento(utilValidazione) {
  const { required, dateValide, valoriInput } = utilValidazione
  const {
    tipoDocumento: { first: tipoDocumento },
    patenteCQC,
  } = valoriInput

  if (dettagliDocumentoDaNascondere(tipoDocumento)) return

  dateValide(NOMI.DATA_RILASCIO, NOMI.DATA_SCADENZA)

  required('Tipo documento non inserito', NOMI.TIPO)
  required('Numero documento non inserito', NOMI.NUMERO)
  required('Data rilascio documento non inserita', NOMI.DATA_RILASCIO)
  required('Luogo rilascio documento non inserito', NOMI.RILASCIATO_DA_LUOGO)
  if (tipoDocumento === OPTIONS_TIPO.ALTRO_DOCUMENTO) {
    required('Tipo documento non inserito', NOMI.TIPO_ALTRO)
  }

  if (tipoDocumento === OPTIONS_TIPO.PATENTE) {
    dateValide(NOMI.DATA_PRIMO_RILASCIO)

    required('Categoria patente non inserita', NOMI.PATENTE_CATEGORIA)
    if (patenteCQC) {
      required('Tipo CQC non inserito', NOMI.PATENTE_CQC_TIPO)
    }
  }
}



const NOMI_RILIEVI_ANTROPOMETRICI = {
  DISPOSTI_DA: 'dispostiDa',
  ESEGUITI_PRESSO: 'eseguitiPresso',
  ESITO: 'esito',
}

function RilieviAntropometriciModifica(props) {
  const { propsEntita } = props

  const {
    gestioneModifica: { creaEntita, eliminaEntita },
    gestionePermessi: { readOnly }
  } = useEntita()

  const { valoriInput, nomeEntita, pathEntita } = propsEntita

  function creaRilievi() {
    creaEntita(nomeEntita, pathEntita, {
      entitaGiaCreata: {
        dispostiDa: '',
        eseguitiPresso: '',
        esito: ''
      }
    })
  }

  function eliminaRilievi() {
    eliminaEntita(nomeEntita, pathEntita)
  }

  return (
    <>
      <BaseCheckbox
        label='Rilievi antropometrici effettuati'
        nonUsareHookForm
        value={Boolean(valoriInput)}
        onChange={e => e.target.value ? creaRilievi() : eliminaRilievi()}
        readOnly={readOnly}
      />

      {valoriInput &&
        <FormModificaEntita {...propsEntita} larghezzaInputs={25}>
          <BaseGridLayout disposizioneRighe={[2, 1]}>
            <BaseTextInput
              label='Rilievi antropometrici disposti da'
              name={NOMI_RILIEVI_ANTROPOMETRICI.DISPOSTI_DA}
            />
            <BaseTextInput
              label='Rilievi antropometrici eseguiti presso'
              name={NOMI_RILIEVI_ANTROPOMETRICI.ESEGUITI_PRESSO}
            />
            <BaseTextInput
              label='Esito rilievi antropometrici'
              name={NOMI_RILIEVI_ANTROPOMETRICI.ESITO}
              multiline rows={2} larghezza={51}
            />
          </BaseGridLayout>
        </FormModificaEntita>
      }
    </>
  )
}