import { useLocation } from 'react-router-dom'
import { findRouteWithPath } from 'utils'
import { useRoutes } from '../navigation/RoutesProvider'

function useIsRottaCorrenteSenzaComando() {
  const { routesSenzaComando } = useRoutes()

  const { pathname: currentPath } = useLocation()

  const rottaCorrente = findRouteWithPath(routesSenzaComando.flattened, currentPath)
  const isRottaSenzaComando = (rottaCorrente.pathAssoluto !== '/')

  return isRottaSenzaComando
}

export {
  useIsRottaCorrenteSenzaComando
}