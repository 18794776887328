import { convertStringToBase64, convertBase64ToString, maskString, unmaskString } from './StringConversions'

/* Funzioni generiche */
function getFromStorage(storage, key, options = {}) {
  const { mask } = options

  let stringData = storage.getItem(key)
  // Ottieni i dati originali se sono mascherati
  if (mask) stringData = convertBase64ToString(unmaskString(stringData))
  try {
    // Fix per JSON.parse che si schianta passando la stringa vuota
    return (stringData === '') ? '' : JSON.parse(stringData)
  } catch (erroreParsingJSON) {
    // Se c'è un errore nel parsing del JSON, pulisci tutto lo storage e ricarica la pagina
    window.localStorage.clear()
    window.sessionStorage.clear()
    window.location.reload()
  }
}

function saveInStorage(storage, key, value, options = {}) {
  const { mask } = options

  let stringData = JSON.stringify(value)
  // I dati sottoforma di stringa possono essere mascherati per ragioni di sicurezza
  if (mask) stringData = maskString(convertStringToBase64(stringData))
  storage.setItem(key, stringData)
}

function removeFromStorage(storage, key) {
  storage.removeItem(key)
}
/**********************/

/***** Local storage *****/
function getFromLocalStorage(key, options) {
  return getFromStorage(localStorage, key, options)
}

function saveInLocalStorage(key, value, options) {
  saveInStorage(localStorage, key, value, options)
}

function removeFromLocalStorage(key) {
  removeFromStorage(localStorage, key)
}
/*************************/

/***** Session storage *****/
function getFromSessionStorage(key, options) {
  return getFromStorage(sessionStorage, key, options)
}

function saveInSessionStorage(key, value, options) {
  saveInStorage(sessionStorage, key, value, options)
}

function removeFromSessionStorage(key) {
  removeFromStorage(sessionStorage, key)
}
/***************************/

export {
  getFromLocalStorage,
  saveInLocalStorage,
  removeFromLocalStorage,
  getFromSessionStorage,
  saveInSessionStorage,
  removeFromSessionStorage
}