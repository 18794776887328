import { BaseContenutoConTitoloEFallback } from 'layout'
import { BaseListaCoppieChiaveValore } from 'text'

export default function Contatti(props) {
  const {
    contatti: {
      email,
      telefono,
      pec
    }
  } = props

  return (
    <BaseContenutoConTitoloEFallback
      datiPresentiSe={email || telefono || pec}
      titolo='Contatti'
      propsTitolo={{ colorato: true }}
    >
      <BaseListaCoppieChiaveValore
        coppie={{
          'Telefono': telefono,
          'Email': email,
          'PEC': pec
        }}
      />
    </BaseContenutoConTitoloEFallback>
  )
}