import { BaseButtons, BaseTesto, BaseTitolo, capitalizza, AlertScadenzaPassword,  PASSWORD_WARNING_DAYS, PASSWORD_DANGER_DAYS, BaseLink,
   } from "@lucan/base-ui";
import Link from '@mui/material/Link';
import React, { useEffect } from "react";
import LastLogins from "../LastLogins";
import { differenceInDays, fromUnixTime } from 'date-fns'
import CountDownRedirect from "../CountDownRedirect";

const Logged = ({
    app,
    url,
    logout,
    user,
    seconds = 100,
    secondOnWarning = 150,

    lastLogins = [],
  }) => { 
  
  const {scadenzaPasswordTs} = user;
  const date = fromUnixTime(scadenzaPasswordTs/1000);
  const diffDays = differenceInDays(new Date(), date);

  const warningMode = PASSWORD_WARNING_DAYS + diffDays >= 0
  const dangerMode = PASSWORD_DANGER_DAYS + diffDays >= 0
  
  const timeLeftStartSecond = dangerMode ? null : (warningMode ? secondOnWarning : seconds)
  const labelUser = (userInfo) => {
    const { nome, cognome } = userInfo
    return `${capitalizza(nome)} ${capitalizza(cognome)}`
  }
  

  useEffect(() => {
    if(timeLeftStartSecond == 0 ){
      window.location.assign(url)
    }
    const pageReload = (e) => {e.persisted && window.location.reload()}
    window.addEventListener("pageshow",pageReload,false);
  }, [timeLeftStartSecond])
  
  
  return (
  <>{timeLeftStartSecond == 0 ?(
      <BaseTitolo>redirezione in corso...</BaseTitolo>
    ):(
      <> 
        <BaseTitolo className="titolo" medio>Bentornato</BaseTitolo>
        {user?.info &&   <BaseTitolo medio>{labelUser(user.info)}</BaseTitolo> } 
        <br />
        <AlertScadenzaPassword timestampScadenza={scadenzaPasswordTs} modificaPasswordUrl={process.env.REACT_APP_SSO_URL+'cambio-password'} />
        {!dangerMode &&
        <BaseTesto>
          Tra  <b><CountDownRedirect seconds={timeLeftStartSecond} url={url} /></b> secondi tornerà all'applicazione:<br /> <b>{app}</b><br /><br />
          Altrimenti selezionare il seguente link<br />
        </BaseTesto>
        }
        <BaseTesto>Prosegui per</BaseTesto>
        <BaseButtons.Custom href={url} color={'primary'} testo={app} className='full'/>< br/>< br/>
        < br/>< br/>< br/>
        <BaseButtons.Logout color={'primary'} variant="outlined" onClick={logout}  className='full' />< br/>< br/>
        <br />
        <LastLogins logins={lastLogins} className="accessi"/>
        
      </>)}
    </>
   )
  }



export default Logged;