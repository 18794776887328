import { func, bool } from 'prop-types'
import { TextField } from '@mui/material'
import { arricchisciPropOggetto } from 'utils'
import { useLarghezzaMinimaInput } from './hooks/AltriHooks'
import useInputConOptions from './hooks/InputConOptionsHook'
import OptionsSelect from './util/OptionsSelect'

/**
 * @typedef {import("../common/inputs/BaseSelect").BaseSelectProps} BaseSelectProps 
 * @typedef {import("../common/inputs/BaseSelect").BaseSelect} BaseSelect 
 */

BaseSelect.propTypes = {
  raggruppaOptions: bool,
  renderOption: func, // Rende la select non nativa
  calcolaPropsMenuItem: func,
  ...useInputConOptions.propTypes
}

BaseSelect.campoConOptions = true

/**
 * @template T type of option
 * @param {BaseSelectProps} props 
 * @type {BaseSelect<T>}
 */
export default function BaseSelect(props) {
  const {
    raggruppaOptions,
    renderOption,
    calcolaPropsMenuItem,
    ...restProps
  } = props

  const { larghezza, label } = restProps
  const larghezzaMinima = useLarghezzaMinimaInput(larghezza, (label?.length ?? 0) / 1.5)

  const {
    value,
    onBlur,
    onChange,
    ref,
    propsRimanenti,
    options
  } = useInputConOptions({
    larghezza: larghezzaMinima,
    ...restProps
  })

  return (
    <TextField
      // Props fornite dal controller
      value={value}
      onBlur={onBlur}
      onChange={e => onChange(e.target.value)}
      inputRef={ref}

      // Props della select
      select
      {...propsRimanenti}
      {...arricchisciPropOggetto(propsRimanenti, 'SelectProps', {
        native: !renderOption
      })}
    >
      {OptionsSelect({
        raggruppaOptions,
        options,
        renderOption,
        calcolaPropsMenuItem
      })}
    </TextField>
  )
}