import { ICONE } from 'icons'
import { BaseHeaderConIcona } from 'layout'

export default function HeaderAzienda(props) {
  const { azienda, ...restProps } = props
  const { nome, partitaIva } = azienda

  return (
    <BaseHeaderConIcona
      icona={<ICONE.AZIENDA />}
      titolo={nome || '(nome non inserito)'}
      sottotitolo={partitaIva && `Partita IVA: ${partitaIva}`}
      {...restProps}
    />
  )
}