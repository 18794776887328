import { func } from 'prop-types'
import { useFormContext } from 'react-hook-form'
import { BaseCheckbox, BaseSelect, BaseTextInput } from 'inputs'
import { BaseGridLayout, BaseScatolaConTitolo } from 'layout'
import ElenchiManager, { ELENCHI } from '../../form/ElenchiManager'
import FormModificaEntita, { PROP_TYPES_WRAPPER_FORM_MODIFICA } from '../entitaGenerica/components/FormModificaEntita'

StatoSaluteModifica.propTypes = {
  ...PROP_TYPES_WRAPPER_FORM_MODIFICA,
  ModificaRifPM: func
}

export default function StatoSaluteModifica(props) {
  const { propsEntita, ModificaRifPM } = props

  return (
    <BaseScatolaConTitolo titolo='Stato di salute'>
      <ElenchiManager
        elenchi={[
          ELENCHI.STATO_SALUTE,
          ELENCHI.DETTAGLI_MORTE,
          ELENCHI.DESTINAZIONE_SALMA
        ]}
        render={el =>
          <FormModificaEntita
            {...propsEntita}
            trasformaValoriInput={trasformaValoriInput}
          >
            <FormStatoSalute
              optionsStatiSalute={el(ELENCHI.STATO_SALUTE)}
              optionsDettagliMorte={el(ELENCHI.DETTAGLI_MORTE)}
              optionsDestinazioneSalma={el(ELENCHI.DESTINAZIONE_SALMA)}
              ModificaRifPM={ModificaRifPM}
            />
          </FormModificaEntita>
        }
      />
    </BaseScatolaConTitolo>
  )
}



// I campi dettagliMorte e destinazioneSalma sono nullable e potrebbero non esserci
function trasformaValoriInput(valori) {
  const { dettagliMorte, destinazioneSalma } = valori
  return {
    ...valori,
    ...(!dettagliMorte && {
      dettagliMorte: { first: '', second: '' }
    }),
    ...(!destinazioneSalma && {
      destinazioneSalma: { first: '', second: '' }
    })
  }
}

const NOMI = {
  STATO: 'stato',
  LESIONI: 'lesioni',
  RICOVERO: 'ricovero',
  PROGNOSI: 'prognosi',
  PROGNOSI_RISERVATA: 'prognosiRiservata',
  DETTAGLI_MORTE: 'dettagliMorte',
  LUOGO_MORTE: 'luogoMorte',
  RICONOSCIMENTO_SALMA: 'riconoscimentoSalma',
  DESTINAZIONE_SALMA: 'destinazioneSalma',
  PM_AUTORIZZA_RIMOZIONE_SALMA: 'pmAutorizzaRimozioneSalma'
}

function FormStatoSalute(props) {
  const {
    optionsStatiSalute,
    optionsDettagliMorte,
    optionsDestinazioneSalma,
    ModificaRifPM
  } = props

  const { watch } = useFormContext()
  const [statoSalute, prognosiRiservata] = watch([NOMI.STATO, NOMI.PROGNOSI_RISERVATA])
  const isFerito = statoSalute === 'FERITO'
  const isMorto = statoSalute === 'MORTO'

  return (
    <>
      <BaseSelect
        label='Stato di salute' name={NOMI.STATO}
        options={optionsStatiSalute} larghezza={12}
      />
      <BaseSelect
        label='Dettagli decesso' name={NOMI.DETTAGLI_MORTE}
        options={optionsDettagliMorte} pair
        hidden={!isMorto} larghezza={20} sx={{ ml: 2 }}
      />

      <BaseGridLayout
        alignCenter disposizioneRighe={[1, 1, 2]}
        hidden={!isFerito}
      >
        <BaseTextInput
          label='Lesioni visibili o lamentate' name={NOMI.LESIONI}
          multiline rows={2} larghezza={33}
        />
        <BaseTextInput
          label='Ospedale di destinazione' name={NOMI.RICOVERO}
          larghezza={33}
        />
        <BaseCheckbox
          label='Prognosi riservata' name={NOMI.PROGNOSI_RISERVATA}
        />
        <BaseTextInput
          label='Prognosi' name={NOMI.PROGNOSI}
          larghezza={20.9} hidden={prognosiRiservata}
        />
      </BaseGridLayout>

      <BaseGridLayout
        alignCenter disposizioneRighe={[1, 1, 2]}
        hidden={!isMorto}
      >
        <BaseTextInput
          label='Luogo del decesso' name={NOMI.LUOGO_MORTE}
          larghezza={33}
        />
        <BaseTextInput
          label='Riconoscimento salma' name={NOMI.RICONOSCIMENTO_SALMA}
          larghezza={33}
        />
        <BaseSelect
          label='Destinazione salma' name={NOMI.DESTINAZIONE_SALMA}
          options={optionsDestinazioneSalma} pair larghezza={12}
        />
        {ModificaRifPM &&
          <ModificaRifPM
            label='PM che autorizza rimozione salma'
            name={NOMI.PM_AUTORIZZA_RIMOZIONE_SALMA}
            larghezza={20}
          />
        }
      </BaseGridLayout>
    </>
  )
}