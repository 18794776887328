import { createContext, useContext } from 'react'
import { node, object } from 'prop-types'

EntitaProvider.propTypes = {
  children: node.isRequired,
  propsInterne: object
}

const EntitaContext = createContext()

function EntitaProvider(props) {
  const { children, propsInterne } = props

  return (
    <EntitaContext.Provider value={propsInterne}>
      {children}
    </EntitaContext.Provider>
  )
}

function useEntita() {
  return useContext(EntitaContext)
}

export {
  EntitaProvider,
  useEntita
}