import { useEffect, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { BaseTextInput, BaseSelect, BaseButtons } from 'inputs'
import { BaseGridLayout } from 'layout'
import ElenchiManager, { ELENCHI } from '../../form/ElenchiManager'
import { TIPI_VISURA } from '../../form/utilsElenchi'
import { OPERAZIONI_BASE } from '../../network/OperazioniBase'
import VisuraGenerica from '../VisuraGenerica'

const NOMI = {
  TIPO_VISURA: 'tipo_visura',
  ENTE: 'ente',
  TIPO_VEICOLO: 'tipo_veicolo',
  TARGA: 'targa'
}

export default function VisuraVeicolo(props) {
  const {
    targa,
    importaVeicolo,
    chiaveTipoVisuraDefault = TIPI_VISURA.VEICOLO_PROPRIETARI
  } = props

  // Implementazione non del tutto finita
  return null

  return (
    <ElenchiManager
      elenchi={[ELENCHI.VISURE_CONFIG, ELENCHI.AUTOVETTURA_TIPO]}
      render={el => {
        const tipiVisura = el(ELENCHI.VISURE_CONFIG).veicolo
        const nessunaVisuraDisponibile = tipiVisura.length === 0
        const tipoVisuraDefault = chiaveTipoVisuraDefault
          ? tipiVisura.find(({ value }) => value === chiaveTipoVisuraDefault)
          : tipiVisura[0]
        const tipiVeicolo = el(ELENCHI.AUTOVETTURA_TIPO)
        return (
          <VisuraGenerica
            titolo='Visura veicolo'
            nessunaVisuraDisponibile={nessunaVisuraDisponibile}
            defaultValues={!nessunaVisuraDisponibile && {
              [NOMI.TARGA]: targa || '',
              [NOMI.TIPO_VISURA]: tipoVisuraDefault.value,
              [NOMI.ENTE]: tipoVisuraDefault.enti[0].value,
              [NOMI.TIPO_VEICOLO]: tipiVeicolo[0].value,
            }}
            ContenutoFormVisura={() =>
              <FormVisuraVeicolo
                tipiVisura={tipiVisura}
                tipiVeicolo={tipiVeicolo}
              />
            }
            useLogicaVisura={useLogicaVisuraVeicolo}
            importaDati={importaVeicolo}
          />
        )
      }}
    />
  )
}



function FormVisuraVeicolo(props) {
  const { tipiVisura, tipiVeicolo } = props

  const { watch, setValue, inAttesa } = useFormContext()
  const tipoVisuraSelezionato = watch(NOMI.TIPO_VISURA)

  const enti = useMemo(() => {
    return tipiVisura.find(({ value }) => value === tipoVisuraSelezionato).enti
  }, [tipiVisura, tipoVisuraSelezionato])

  useEffect(() => {
    setValue(NOMI.ENTE, enti[0].value)
  }, [tipoVisuraSelezionato, enti])

  return (
    <BaseGridLayout alignCenter justifyCenter disposizioneRighe={[2, 2, 1]}>
      <BaseTextInput
        label='Targa' name={NOMI.TARGA}
        required autoFocus
      />
      <BaseSelect
        label='Tipo veicolo' name={NOMI.TIPO_VEICOLO}
        options={tipiVeicolo}
      />

      <BaseSelect
        label='Tipo visura' name={NOMI.TIPO_VISURA}
        options={tipiVisura}
      />
      <BaseSelect
        label='Ente' name={NOMI.ENTE}
        options={enti} hidden={enti.length === 1}
      />

      <BaseButtons.Cerca
        testo='Visura'
        color='info'
        inAttesa={false}
        disabled={inAttesa}
      />
    </BaseGridLayout>
  )
}



function useLogicaVisuraVeicolo(props) {
  const { inviaOperazione, setRispostaVisura } = props

  async function eseguiVisura(valoriForm) {
    const { ok, data } = await inviaOperazione(OPERAZIONI_BASE.VISURA_VEICOLO, valoriForm)
    if (ok) {
      const { veicolo, html, avvisi } = data
      setRispostaVisura({ risultato: veicolo, html, avvisi })
    }
  }

  return { eseguiVisura }
}