import { useRoutes } from '../navigation/RoutesProvider'
import SganciaComandoSeServe from '../userManagement/SganciaComandoSeServe'
import { useUser } from '../userManagement/userProvider/UserProvider'

export default function Home() {
  const { homeConComando, homeSenzaComando, componenteLogin } = useRoutes()

  const { isUserLoggato, isUserLoggatoConComando } = useUser()

  if (!isUserLoggato()) return componenteLogin

  return isUserLoggatoConComando?.()
    // Gli utenti staff devono poter andare in una rotta senza comando anche  
    // se attualmente il comando è scelto. In questa situazione viene mostrato
    // il componente di homeConComando, perché la rotta senza comando cercata
    // non è tra quelle disponibili. Il componente SganciaComandoSeServe si 
    // accorge di quando si verifica questa situazione e sgancia il comando
    ? <SganciaComandoSeServe>{homeConComando}</SganciaComandoSeServe>
    : homeSenzaComando
}