import { useEffect, useMemo, useState } from 'react'
import { ICONE } from 'icons'
import { BaseIconButtons, BaseMenu } from 'inputs'
import { ENTITA_BASE } from '../EntitaBase'
import { usePin } from '../entitaGenerica/components/PinProvider'
import ModalContenutiMultimediali from './ModalContenutiMultimediali'
import ModalModificaDocumento from './ModalModificaDocumento'
import ModalModificaMetadati from './ModalModificaMetadati'
import { trasformaAllegatoOutput, trasformaDocumentoOutput } from './utilDocumenti'

export default function MenuModificaDocumento(props) {
  const {
    propsEntita,
    modificaEntita,
    trovaTemplateConNome,
    documentoInput,
    caricaDocumentoCheSostituisce,
    abilitaInformazioniAggiuntive,
    abilitaContenutiMultimediali,
    abilitaCaricamentoDocumentoSostituisce,
    uuidBozzaAppenaCreata,
    setUuidBozzaAppenaCreata,
    ...propsComponenteDaMostrare
  } = props

  const { pathEntita } = propsEntita
  const {
    allegato: allegatoInput,
    template: nomeTemplate,
    fileMultimediali = []
  } = documentoInput

  const template = useMemo(() => {
    return trovaTemplateConNome(nomeTemplate)
  }, [nomeTemplate, trovaTemplateConNome])

  const [ComponenteDaMostrare, setComponenteDaMostrare] = useState(null)

  function chiudiModifica() {
    setComponenteDaMostrare(null)
  }

  function modificaDocumento(documentoGenerato) {
    const documentoModificato = {
      ...documentoInput,
      ...documentoGenerato,
      dataAggiornamento: new Date()
    }
    modificaEntita(
      ENTITA_BASE.ALLEGATO_DOCUMENTO,
      pathEntita,
      trasformaDocumentoOutput(documentoModificato, template),
      // Invio già il valore fuso, quindi non serve farlo sopra
      { fondiConValoreAttuale: false }
    )
    chiudiModifica()
  }

  function modificaSoloAllegato(valoriFormAllegato) {
    modificaEntita(
      ENTITA_BASE.ALLEGATO,
      pathEntita.addPath('allegato'),
      trasformaAllegatoOutput({ ...allegatoInput, ...valoriFormAllegato }),
      // Invio già il valore fuso, quindi non serve farlo sopra
      { fondiConValoreAttuale: false }
    )
    chiudiModifica()
  }

  useEffect(() => {
    if (uuidBozzaAppenaCreata === documentoInput.uuid) {
      setComponenteDaMostrare(() => ModalModificaDocumento)
      setUuidBozzaAppenaCreata(null)
    }
  }, [uuidBozzaAppenaCreata])

  const { pinCorrente, bottonePinPremuto, togliPin } = usePin()

  // L'utente può tornare alla modifica del documento cliccando sul pin corrente
  // Se il path del pin corrente è relativo a questo documento, apro la modifica
  useEffect(() => {
    if (bottonePinPremuto && pinCorrente?.path?.endsWith(pathEntita)) {
      setComponenteDaMostrare(() => ModalModificaDocumento)
      togliPin()
    }
  }, [bottonePinPremuto])

  const numeroFileMultimediali = fileMultimediali.length
  return (
    <>
      {abilitaContenutiMultimediali && numeroFileMultimediali > 0 &&
        <BaseIconButtons.Custom
          Icon={ICONE.VIDEO}
          contenutoTooltip={`Vedi contenuti multimediali (${numeroFileMultimediali})`}
          onClick={() => setComponenteDaMostrare(() => ModalContenutiMultimediali)}
          sx={{ color: 'black', mr: 2, ml: -2, py: 0 }}
        />
      }

      <BaseMenu
        options={[
          {
            label: 'Modifica documento',
            onClick: () => setComponenteDaMostrare(() => ModalModificaDocumento)
          },
          ...(abilitaCaricamentoDocumentoSostituisce ? [{
            label: 'Carica documento aggiornato / firmato',
            onClick: () => caricaDocumentoCheSostituisce(documentoInput, pathEntita)
          }] : []),
          ...(abilitaInformazioniAggiuntive ? [{
            label: 'Modifica informazioni aggiuntive',
            onClick: () => setComponenteDaMostrare(() => ModalModificaMetadati)
          }] : []),
          ...(abilitaContenutiMultimediali ? [{
            label: 'Associa contenuti multimediali',
            onClick: () => setComponenteDaMostrare(() => ModalContenutiMultimediali)
          }] : [])
        ]}
        propsOpenButton={{
          testo: 'Modifica',
          iconaInizio: ICONE.MODIFICA,
          noMarginY: true
        }}
      />

      {ComponenteDaMostrare &&
        <ComponenteDaMostrare
          documentoInput={documentoInput}
          allegatoInput={allegatoInput}
          modificaDocumento={modificaDocumento}
          modificaSoloAllegato={modificaSoloAllegato}
          template={template}
          chiudiModifica={chiudiModifica}
          propsEntita={propsEntita}
          {...propsComponenteDaMostrare}
        />
      }
    </>
  )
}