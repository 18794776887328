import { DataGrid, itIT } from '@mui/x-data-grid'
import { Stack } from '@mui/material'
import PropTypes from 'prop-types'
import BaseDataGridFilterButton from './BaseDataGridFilterButton'
import { useState, useRef, useMemo } from 'react'
import BaseDataGridFilterPanel from './BaseDataGridFilterPanel'
import BaseDataGridSkeleton from './BaseDataGridSkeleton'
import { BaseButtons } from 'inputs'
import { ICONE } from 'icons'

/**
 * Custom props with default props of DataGrid
 */
BaseDataGrid.propTypes = {
    isLoading: PropTypes.bool,
    toolbarElementOnRight: PropTypes.node,
    onFiltersChange: PropTypes.func,
    onSelectionsChange: PropTypes.func,
    onLoadMore: PropTypes.func,
    noMoreData: PropTypes.bool,
    ...DataGrid.propTypes
}

/**
 * @typedef {import("../../common/tables/datagrid/BaseDataGrid").BaseDataGridProps} BaseDataGridProps 
 * @typedef {import("../../common/tables/datagrid/BaseDataGrid").BaseDataGrid} BaseDataGrid 
 */


/**
 * 
 * @param {BaseDataGridProps} props 
 * @type {BaseDataGrid}
 */
export default function BaseDataGrid(props) {

    const [showFilters, setShowFilters] = useState(false)
    const [filters, setFilters] = useState([])
    const filterButtonRef = useRef(null)

    const {
        isLoading = false,
        toolbarElementOnRight,
        onFiltersChange,
        onSelectionsChange,
        disableColumnFilter,
        selectionModel,
        onLoadMore,
        noMoreData = false,
        ...otherProps
    } = props

    const columnsFilter = useMemo(
        () =>
            otherProps.columns.reduce((filtrableColumns, column) => {
                if (column.filterable !== false) {
                    filtrableColumns.push({
                        identifier: column.field,
                        name: column.headerName,
                        type: column.type,
                        options: column.valueOptions
                    })
                }
                return filtrableColumns
            }, []),
        [otherProps.columns]
    )


    const handleShowFiltersPanel = () => {
        setShowFilters(!showFilters)
    }

    /**
     * Handle when filters changes
     * @param {Filter} filters 
     */
    const handleFiltersChange = (filters) => {
        onFiltersChange?.(filters)
        setFilters(filters)
    }

    /**
     * Handle when selections changes
     * @param {GridSelectionModel} selection
     * @returns {void}  
     */
    const handleSelectionModelChange = (selection) => {
        onSelectionsChange?.(selection)
    }


    return <>
        {
            isLoading ? <BaseDataGridSkeleton /> : <DataGrid
                aria-label='data-grid'
                disableColumnFilter
                disableColumnMenu
                checkboxSelection
                autoHeight
                onSelectionModelChange={handleSelectionModelChange}
                selectionModel={selectionModel}
                localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
                paginationModel={props.paginationModel}
                onPaginationModelChange={props.onPaginationModelChange}
                sortingOrder={['desc', 'asc']}
                components={{
                    Toolbar: () => (
                        <Stack direction='row' sx={{ mb: 2 }} justifyContent='space-between' alignItems='center' spacing={2}>
                            {!disableColumnFilter && (
                                <BaseDataGridFilterButton
                                    ref={filterButtonRef}
                                    filters={filters}
                                    onClick={handleShowFiltersPanel}
                                    open={showFilters}
                                />
                            )}
                            {toolbarElementOnRight}
                        </Stack>
                    ),
                    FilterPanel: () => (
                        <BaseDataGridFilterPanel
                            initialFilters={filters}
                            columns={columnsFilter}
                            onClose={handleShowFiltersPanel}
                            onFiltersChange={handleFiltersChange}
                        />
                    ),
                    Pagination: () => (<BaseButtons.Custom disabled={noMoreData} onClick={() => onLoadMore?.()} sx={{ mr: 2 }} testo='Carica più'
                        variant='outlined'
                        color='secondary'
                        startIcon={<ICONE.REFRESH />} />)
                }}
                componentsProps={{
                    panel: {
                        anchorEl: () =>
                            filterButtonRef.current !== null ? filterButtonRef.current : undefined
                    }
                }}
                {...otherProps}
            />
        }
    </>
}