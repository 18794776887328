import { useCallback, useMemo, useState } from 'react'
import { Box } from '@mui/material'
import { BaseTesto } from 'text'
import BaseListaImmaginiThumbnail from '../BaseListaImmaginiThumbnail'
import ImmagineCorrenteConNavigazione from './ImmagineCorrenteConNavigazione'
import ModificaBinarioImmagine from './ModificaBinarioImmagine'
import ModificaMetadatiEdEliminazioneImmagine from './ModificaMetadatiEdEliminazioneImmagine'

export default function GalleriaImmagini(props) {
  const {
    immagini: immaginiIniziali,
    ComponentePerDownloadFile,
    useGestioneFile,
    minHeight_ScatolaImmagineCorrente,
    nascondiVoti,

    onImmagineModificata,
    onImmagineEliminata,
    readOnly,

    uuidImmagineDaAprire,
    chiudiGalleria,
    timestampForzaRenderThumbnails,
    forzaRenderThumbnails
  } = props

  /***** Gestione copia locale delle immagini *****/

  /*
    In BaseListaImmagini c'è un filtro sul voto che faceva
    sparire l'immagine quando dall'interno galleria veniva
    assegnato un voto che era escluso dal filtro corrente.
    Quindi creo una copia locale delle immagini e la tengo
    aggiornata quando arrivano dal basso gli eventi di modifica
    ed eliminazione, ma NON aggiorno la lista locale quando
    cambia la lista che arriva dall'alto (quest'ultima è 
    usata solo come valore iniziale per la lista locale)
  */

  const [immagini, setImmagini] = useState(immaginiIniziali)

  function onImmagineModificata_ConAggiornamentoLocale(immagineModificata) {
    setImmagini(immagini =>
      immagini.map(immagine => immagine.uuid === immagineModificata.uuid
        ? immagineModificata
        : immagine
      )
    )
    onImmagineModificata(immagineModificata)
  }

  function onImmagineEliminata_ConAggiornamentoLocale(immagineEliminata) {
    setImmagini(immagini =>
      immagini.filter(immagine => immagine.uuid !== immagineEliminata.uuid)
    )
    onImmagineEliminata(immagineEliminata)
  }

  /************************************************/

  const [uuidImmagineCorrente, setUuidImmagineCorrente] = useState(uuidImmagineDaAprire)
  const [overrideSrcImmagineCorrente, setOverrideSrcImmagineCorrente] = useState(null)
  const [modificaBinarioAttiva, setModificaBinarioAttiva] = useState(false)

  const {
    immagineCorrente,
    indiceImmagineCorrente,
    uuidImmaginePrecedente,
    uuidImmagineSuccessiva
  } = useMemo(() => {
    const indice = immagini.findIndex(({ uuid }) => uuid === uuidImmagineCorrente)
    const immagine = immagini[indice]

    const immagineCorrente = overrideSrcImmagineCorrente ? {
      ...immagine,
      src: { ...immagine.src, fullSize: overrideSrcImmagineCorrente }
    } : immagine

    return {
      immagineCorrente,
      indiceImmagineCorrente: indice,
      uuidImmaginePrecedente: immagini[indice - 1]?.uuid ?? null,
      uuidImmagineSuccessiva: immagini[indice + 1]?.uuid ?? null
    }
  }, [immagini, uuidImmagineCorrente, overrideSrcImmagineCorrente])

  const setIndiceImmagineCorrente = useCallback(indice => {
    setUuidImmagineCorrente(immagini[indice]?.uuid ?? null)
  }, [immagini])

  const indietroDiUna = useCallback(() => {
    if (!modificaBinarioAttiva && uuidImmaginePrecedente) {
      setUuidImmagineCorrente(uuidImmaginePrecedente)
    }
  }, [modificaBinarioAttiva, uuidImmaginePrecedente])

  const avantiDiUna = useCallback(() => {
    if (!modificaBinarioAttiva && uuidImmagineSuccessiva) {
      setUuidImmagineCorrente(uuidImmagineSuccessiva)
    }
  }, [modificaBinarioAttiva, uuidImmagineSuccessiva])

  function cambiaImmagineDopoEliminazione() {
    if (uuidImmagineSuccessiva) {
      setUuidImmagineCorrente(uuidImmagineSuccessiva)
    } else if (uuidImmaginePrecedente) {
      setUuidImmagineCorrente(uuidImmaginePrecedente)
    } else {
      chiudiGalleria()
    }
  }

  return (
    <>
      <BaseTesto color='textSecondary'>
        Usare le frecce direzionali per spostarsi tra le immagini. Premere Esc per chiudere la galleria.
      </BaseTesto>

      <ImmagineCorrenteConNavigazione
        immagineCorrente={immagineCorrente}
        avantiDiUna={avantiDiUna}
        indietroDiUna={indietroDiUna}
        isPrimaImmagine={!uuidImmaginePrecedente}
        isUltimaImmagine={!uuidImmagineSuccessiva}
        minHeight_ScatolaImmagineCorrente={minHeight_ScatolaImmagineCorrente}
        disabilitaNavigazione={modificaBinarioAttiva}
      />

      {modificaBinarioAttiva ? (
        <ModificaBinarioImmagine
          immagineCorrente={immagineCorrente}
          chiudiModificaBinario={() => {
            setModificaBinarioAttiva(false)
            setOverrideSrcImmagineCorrente(null)
          }}
          useGestioneFile={useGestioneFile}
          overrideSrcImmagineCorrente={overrideSrcImmagineCorrente}
          setOverrideSrcImmagineCorrente={setOverrideSrcImmagineCorrente}
          forzaRenderThumbnails={forzaRenderThumbnails}
        />
      ) : (
        <ModificaMetadatiEdEliminazioneImmagine
          immagineCorrente={immagineCorrente}
          apriModificaBinario={() => setModificaBinarioAttiva(true)}
          onImmagineModificata={onImmagineModificata_ConAggiornamentoLocale}
          onImmagineEliminata={onImmagineEliminata_ConAggiornamentoLocale}
          cambiaImmagineDopoEliminazione={cambiaImmagineDopoEliminazione}
          ComponentePerDownloadFile={ComponentePerDownloadFile}
          nascondiVoti={nascondiVoti}
          readOnly={readOnly}
        />
      )}

      <Box sx={{ width: '100%', overflowX: 'auto', mt: 4 }}>
        <BaseListaImmaginiThumbnail
          immagini={immagini}
          {...(modificaBinarioAttiva ? {
            riduciOpacitaImmaginiNonEvidenziate: true
          } : {
            onClick: setIndiceImmagineCorrente
          })}
          indiceImmagineDaEvidenziare={indiceImmagineCorrente}
          key={timestampForzaRenderThumbnails}
          noWrap
        />
      </Box>
    </>
  )
}