import { useQueryString, useSsoIncloud } from '@lucan/base-ui';
import React, { useEffect, useMemo, useState } from 'react';
import * as STATI from '../../constants/Status';


import {Login ,AccessoBloccato, AggiornaPassword, Logged, Login2fa} from '../statiLogin/index';
import Layout from '../Layout';
import { useTokenInvalidate, useTokenValidate } from 'services/Network';
import Loading from '../Loading';
import MessageBox from '../MessageBox';
import { WAIT_REDIRECT_SECONDS, WAIT_REDIRECT_WITH_WARNING_SECONDS } from 'constants/Configurazioni';
import LoginDisabilitatoTemporanemante from 'components/statiLogin/LoginDisabilitatoTemporanemante';
import Login2faInit from 'components/statiLogin/Login2faInit';

//import appsConf from '../../appsConf.json'

IndexPage.propTypes = {
  
}

export default function IndexPage(props) {
 
  const {
    inAttesaDelServer,
    invia
  } = useTokenValidate({})

  const {
    invia:inviaInvalidate
  } = useTokenInvalidate({})

	const {app, url, app_key = "" } = useQueryString();
	const confAppUrl = url ? {app: app ?? url, url, app_key} : {app:"Connet SSO", url:process.env.REACT_APP_SSO_URL, app_key}

	const [ error, setError ] = useState('');

  const {tokenState, getToken, setToken, expireToken} = useSsoIncloud()
  
  const defaultTokenData = {stato: STATI.INIT};
  const [tokenData,setTokenData]  = useState(defaultTokenData); 
	
  const logout = () => { 
    const token = getToken();
    try {
      inviaInvalidate(token);  
    } catch (error) {
    }
    expireToken();
  }


	useEffect(() => {
    //serve a l cambio di tab per controllare lo stato del token
		valutaToken();
	}, [tokenState])

	
	const messageToToken = (errorData, token) => {
		return {
			esito: errorData.esito ??  false,
			stato: errorData.messaggi[0].codice ?? STATI.UND,
			username: errorData.username ??  '',
			messaggio: errorData.messaggi[0].msg ?? null,
			token
		}
	}

  const valutaToken = () => {

		const token = getToken();
    const  run = async (token)=> {
  
      const { ok, data, error:errorRichiesta} = await invia(token)
			setError('')
      if (ok){
        setTokenData(data)
				
      }else{
			
				if(errorRichiesta.hasOwnProperty('esito') && errorRichiesta.hasOwnProperty('messaggi')){
					setTokenData(messageToToken(errorRichiesta, token))
				}else{
					if(errorRichiesta?.response?.status != 401){
						setError(errorRichiesta.message);
					}
				}				        
      }		
    }
    if(token){
      run(token);
    }else{
      setTokenData(defaultTokenData)
    } 
  }

  const loginComplete = (result) => {
    if(result.esito){
      setToken(result.data?.token)
    }
	}

  const innerComponent = useMemo(() => {
		
		const {stato} = tokenData;
		if(STATI.ABILITATO ===  stato){
			return( 
				<Logged 	
					{...tokenData}
					{...confAppUrl}
					seconds={WAIT_REDIRECT_SECONDS} 
					secondOnWarning={WAIT_REDIRECT_WITH_WARNING_SECONDS} 
					logout={logout}
				/>
			)
		}

		//todo gestisco tutti gli altri stati
		if(STATI.BLOCCATO ===  stato || STATI.LOGIN_UTENTE_BLOCCATO ===  stato || STATI.LOGIN_UTENTE_DISABILITATO ===  stato ){
			return( <AccessoBloccato {...tokenData} logout={logout} />
			)
		}
		if(STATI.PASSWORD_SCADUTA ===  stato){
			return( <AggiornaPassword {...tokenData} onComplete={valutaToken} logout={logout}/>)
		}
		if(STATI.LOGIN_2FA ===  stato){
			return( <Login2fa init={STATI.LOGIN_2FA_INIT ===  stato} {...tokenData} logout={logout} onComplete={valutaToken}/>)
		}
		if(STATI.LOGIN_2FA_INIT ===  stato ){
			return( <Login2faInit init={STATI.LOGIN_2FA_INIT ===  stato} {...tokenData} logout={logout} onComplete={valutaToken}/>)
		}
		if(STATI.LOGIN_UTENTE_DISABILITATO_TEMPORANEAMENTE ===  stato){
			return( <LoginDisabilitatoTemporanemante {...tokenData} logout={logout}/>
			)
		}
		return (
			<Login
				{...tokenData}	
				{...confAppUrl}
        onComplete={loginComplete} 
			/>
		);
	}, [tokenData, confAppUrl])


	return (
		<Layout>
      <Loading loading={inAttesaDelServer} />
			<StatusChanger  setTokenData={setTokenData} tokenData={tokenData} />
			{!inAttesaDelServer && <MessageBox  errorMsg={ error != '' ? {main:'Problemi in corso',secondary:' Riprovare più tardi'} : null}/>}	
			{ innerComponent }
		</Layout>
	);
};

/**
 * Per testare velocemente i cambi di token e i diversi stati
 * 
 * @param {*} param0 
 * @returns 
 */
function StatusChanger({setTokenData, tokenData}){
	
	function handleSubmit(e) {
    // Prevent the browser from reloading the page
    e.preventDefault();

    // Read the form data
    const form = e.target;
    const formData = new FormData(form);

    const formJson = Object.fromEntries(formData.entries());
		const newTokenData = JSON.parse(formJson.postContent)
    console.log(formJson.postContent, newTokenData);
		if(newTokenData){
			setTokenData(newTokenData);
		}
  }

	if(process.env.NODE_ENV === 'developmenst'){
		return <form method="post" onSubmit={handleSubmit}><textarea  name="postContent"
		defaultValue={JSON.stringify(tokenData)}
		rows={4}
		cols={40}/><button type="submit">Salva</button></form>
	}
	return null
}