import { Box, Skeleton } from '@mui/material';

/**
 * Custom props with default props of DataGrid
 */
BaseDataGridSkeleton.propTypes = {}

/**
 * @typedef {import("../../common/tables/datagrid/BaseDataGridSkeleton").BaseDataGridSkeleton} BaseDataGridSkeleton 
 */


/**
 * @type {BaseDataGridSkeleton}
 */
export default function BaseDataGridSkeleton() {

    return (
        <Box sx={{ width: 1, mt: 5 }} aria-label='skeleton-app-datagrid'>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 4 }}>
                <Skeleton variant='rounded' width={30} height={30} />
                <Skeleton variant='rounded' width='20%' height={30} sx={{ mr: 1 }} />
                <Skeleton variant='rounded' width='20%' height={30} sx={{ mr: 1 }} />
                <Skeleton variant='rounded' width='20%' height={30} sx={{ mr: 1 }} />
                <Skeleton variant='rounded' width='20%' height={30} />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', mb: 2 }}>
                <Skeleton variant='rounded' height={40} sx={{ mb: 1 }} />
                <Skeleton variant='rounded' height={40} sx={{ mb: 1 }} />
                <Skeleton variant='rounded' height={40} sx={{ mb: 1 }} />
                <Skeleton variant='rounded' height={40} sx={{ mb: 1 }} />
                <Skeleton variant='rounded' height={40} sx={{ mb: 2 }} />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
                <Skeleton variant='rounded' height={30} width={100} />
            </Box>
        </Box>
    )
}