import { arrayOf, shape, bool, object, string, oneOf, func } from 'prop-types'
import { Chip, Box } from '@mui/material'
import { BaseFeedbackConIcona, BasePopover, usePopover } from 'feedback'
import { ICONE } from 'icons'
import { BaseIconButtons } from 'inputs'
import { BaseGridLayout, BaseScatola } from 'layout'
import { BaseTitolo } from 'text'
import { sx } from 'utils'
import { getErrors, getWarnings, LIVELLI_MESSAGGIO } from '../logica/LogicaValidazioneEntita'
import { useVaiAlMessaggio } from './VaiAlMessaggioContext'

ListaMessaggiValidazione.propTypes = {
  messaggi: arrayOf(shape({
    livello: oneOf(Object.values(LIVELLI_MESSAGGIO)).isRequired,
    msg: string.isRequired,
    pathForm: string.isRequired,
    nomeCampo: string.isRequired
  })),
  soloUnIndicatoreRiassuntivo: bool,
  mostraPrimoMessaggioNelRiassunto: bool,
  margineSottoIlRiassunto: bool,
  nascondiBottoni_VaiAlMessaggio: bool,
  onClickBottoni_VaiAlMessaggio: func,
  propsIndicatoreRiassuntivo: object,
  propsPopover: object,
  propsMessaggio: object
}

export default function ListaMessaggiValidazione(props) {
  const {
    messaggi = [],
    soloUnIndicatoreRiassuntivo,
    mostraPrimoMessaggioNelRiassunto,
    margineSottoIlRiassunto,
    nascondiBottoni_VaiAlMessaggio,
    onClickBottoni_VaiAlMessaggio,
    propsIndicatoreRiassuntivo,
    propsPopover,
    propsMessaggio
  } = props

  const {
    popoverRef,
    openPopover,
    closePopover
  } = usePopover()

  if (messaggi.length === 0) return null

  const propsIndicatoreDefinitive = {
    mostraPrimoMessaggio: mostraPrimoMessaggioNelRiassunto,
    ...propsIndicatoreRiassuntivo
  }

  const errors = getErrors(messaggi)
  const almenoUnErrore = errors.length > 0
  const indicatoreRiassuntivoErrors = (
    <IndicatoreRiassuntivoMessaggi
      messaggi={errors}
      livello={LIVELLI_MESSAGGIO.ERROR}
      {...propsIndicatoreDefinitive}
    />
  )

  const warnings = getWarnings(messaggi)
  const almenoUnWarning = warnings.length > 0
  const indicatoreRiassuntivoWarnings = (
    <IndicatoreRiassuntivoMessaggi
      messaggi={warnings}
      livello={LIVELLI_MESSAGGIO.WARNING}
      {...propsIndicatoreDefinitive}
    />
  )

  if (soloUnIndicatoreRiassuntivo) {
    if (almenoUnErrore) return indicatoreRiassuntivoErrors
    if (almenoUnWarning) return indicatoreRiassuntivoWarnings
    return null
  }

  const propsMessaggioDefinitive = {
    nascondiBottoni_VaiAlMessaggio,
    onClickBottoni_VaiAlMessaggio,
    closePopover,
    ...propsMessaggio
  }

  return (
    <>
      <Box
        onClick={openPopover}
        sx={{
          width: 'fit-content',
          cursor: 'pointer',
          // Il margine serve per evitare problemi di interazione col mouse
          // Spesso sotto il riassunto c'è un BaseGridLayout, che usa margini
          // negativi e può impedire di cliccare sugli indicatori riassuntivi
          ...(margineSottoIlRiassunto && { mb: 2 }),
        }}
      >
        {almenoUnErrore && indicatoreRiassuntivoErrors}
        {almenoUnWarning && indicatoreRiassuntivoWarnings}
      </Box>

      <BasePopover ref={popoverRef} {...propsPopover}>
        <BaseScatola centraContenuto onClick_BottoneChiudi={closePopover}>
          <BaseGridLayout vertical>
            {almenoUnErrore &&
              <ListaMessaggi titolo='Errori' messaggi={errors} {...propsMessaggioDefinitive} />
            }

            {almenoUnWarning &&
              <ListaMessaggi titolo='Avvisi' messaggi={warnings} {...propsMessaggioDefinitive} />
            }
          </BaseGridLayout>
        </BaseScatola>
      </BasePopover>
    </>
  )
}



function IndicatoreRiassuntivoMessaggi(props) {
  const {
    messaggi,
    livello,
    mostraPrimoMessaggio,
    ...restProps
  } = props

  const numeroMessaggi = messaggi.length
  if (numeroMessaggi === 0) return null

  let propsChip
  let labelAltriMessaggi
  switch (livello) {
    case LIVELLI_MESSAGGIO.ERROR:
      propsChip = { color: 'error', icon: <ICONE.ERRORE /> }
      labelAltriMessaggi = 'errori'
      break
    case LIVELLI_MESSAGGIO.WARNING:
      propsChip = { color: 'warning', icon: <ICONE.ATTENZIONE /> }
      labelAltriMessaggi = 'avvisi'
      break
    default:
      return null
  }

  function costruisciLabel() {
    if (!mostraPrimoMessaggio) return numeroMessaggi
    const primoMessaggio = messaggi[0].msg
    if (numeroMessaggi === 1) return primoMessaggio
    return (
      <>
        {primoMessaggio}
        <Box component='span' sx={{ color: 'grey.700' }}>
          {`, ... (altri ${numeroMessaggi - 1} ${labelAltriMessaggi})`}
        </Box>
      </>
    )
  }

  return (
    <Chip
      label={costruisciLabel()}
      variant='outlined'
      {...propsChip}
      {...restProps}
      {...sx(restProps, {
        cursor: 'pointer',
        mx: 0.5,
        '& .MuiChip-icon': { ml: 1 },
        '& .MuiChip-label': { fontSize: '1rem', fontWeight: 'fontWeightBold' }
      })}
    />
  )
}



function ListaMessaggi(props) {
  const {
    titolo,
    messaggi,
    nascondiBottoni_VaiAlMessaggio,
    onClickBottoni_VaiAlMessaggio,
    closePopover,
    ...restProps
  } = props

  const { vaiAlMessaggio } = useVaiAlMessaggio()

  return (
    <>
      {titolo && <BaseTitolo noMargine>{titolo}</BaseTitolo>}

      {messaggi.map(messaggio => {
        const { livello, msg, pathForm, nomeCampo } = messaggio

        let ComponenteFeedback
        let tooltipBottoneVai
        switch (livello) {
          case LIVELLI_MESSAGGIO.ERROR:
            ComponenteFeedback = BaseFeedbackConIcona.Errore
            tooltipBottoneVai = 'Vai all\'errore'
            break
          case LIVELLI_MESSAGGIO.WARNING:
            ComponenteFeedback = BaseFeedbackConIcona.Attenzione
            tooltipBottoneVai = 'Vai all\'avviso'
            break
          default:
            return null
        }

        function portaUtenteAlMessaggio() {
          closePopover?.()
          if (onClickBottoni_VaiAlMessaggio) {
            onClickBottoni_VaiAlMessaggio(messaggio)
          } else {
            vaiAlMessaggio(pathForm, nomeCampo)
          }
        }

        return (
          <div
            style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}
            key={pathForm + nomeCampo + msg}
          >
            <ComponenteFeedback messaggio={msg} {...restProps} />

            {!nascondiBottoni_VaiAlMessaggio &&
              <BaseIconButtons.Custom
                contenutoTooltip={tooltipBottoneVai}
                tooltipProps={{ placement: 'top' }}
                Icon={ICONE.FRECCIA_DESTRA_CERCHIATA}
                iconProps={{ color: 'primary' }}
                onClick={portaUtenteAlMessaggio}
              />
            }
          </div>
        )
      })}
    </>
  )
}