import './App.css';
import preval from 'preval.macro'
import packageJSON from '../package.json'
import { AppSkeleton, Footer } from '@lucan/base-ui';
import IndexPage from 'components/pages/IndexPage';
import { CHIAVI_ROUTES, ROUTES_NON_LOGGATO, SSO_COOKIE_APPEND_NAME, OPERAZIONI_BASE_CONFIG, ROTTA_BASE_API, URL_SERVER } from 'Configurazione';

function App() {

  
  return (<AppSkeleton
    //nascondiScheletro
    CHIAVI_ROUTES={CHIAVI_ROUTES}
    ROUTES_SENZA_COMANDO={ROUTES_NON_LOGGATO}
    ROUTES_NON_LOGGATO={ROUTES_NON_LOGGATO}
    URL_SSO_LOGIN={'/'}
    SSO_COOKIE_APPEND_NAME={SSO_COOKIE_APPEND_NAME}
    componenteLogin={<IndexPage />}
    drawerNavigazione={ null}
    barraSuperiore={ null}
    propsRoutesProvider={ { noBreadcrumb: true }}
    homeSenzaComando={<></>}
    mostraRibbonAlfa={process.env.REACT_APP_ENV_NAME !== 'prod'}
    OPERAZIONI_CONFIG={OPERAZIONI_BASE_CONFIG}
    // Api
    ROTTA_BASE_API={ROTTA_BASE_API}
    URL_SERVER={URL_SERVER}
    //URL_SERVER_ENRICO={URL_SERVER_ENRICO}
    OPERAZIONI={{}}
    ENTITA={{}}
    propsDrawerLayout={{ drawerWidth:{whenClosed:0,whenOpen:0},appBarColor: 'success.main', appBarHeight:0, mainContentPadding:0,mainContentBackground:undefined}}
    // User
    //MODULI={MODULI}
    //funzioneMappaturaPermessiUser={funzioneMappaturaPermessiUser}

    // Elenchi
    ELENCHI_CONFIG={{}}
    
    // Altro
    nomeApp='sso_login'
    titoloApp='Connet Login'
    dataBuild={preval`module.exports = new Date().toLocaleString('it-IT',{ 'month': '2-digit', 'day': '2-digit','year':'numeric'})`}
    versione={process.env.REACT_APP_GIT_COMMIT_ID.trim()}
    urlBuildInfo={`${packageJSON.homepage}/buildInfo.json`}
    //MESSAGGI={MESSAGGI}
    //validaEntita={validaEntita}

  />)
}

export default App;
