import { useMemo } from 'react'

/**
 * @typedef {import("../../common/incloudApps/util/ChiaviStorage").useChiaviStorage} useChiaviStorage
 * @typedef {import("../../common/incloudApps/util/ChiaviStorage").ChiaviStorageProps} ChiaviStorageProps
 * @typedef {import("../../common/incloudApps/index").SimpleMapObject} SimpleMapObject
 */

/**
 * @type {SimpleMapObject}
 */
const CHIAVI_STORAGE_DEFAULT = {
  URL_BACKEND: 'urlBackend',
  USER_INFO: 'userInfo',
  COMANDO_SCELTO: 'comandoScelto',
  COMANDO_SCELTO_NUOVA_TAB: 'comandoSceltoNuovaTab',
  // La concatenazione apparentemente inutile è una misura di sicurezza,
  // per rendere più difficile analizzare il codice al fine di capire 
  // come vengono codificati i comandi abilitati nel local storage
  // eslint-disable-next-line no-useless-concat
  COMANDI_ABILITATI: 'f' + 'u' + 'n',
  PERMESSI_SENZA_COMANDO: 'v' + 'a' + 'r',
  DATA_ULTIMA_RICHIESTA: 'dataUltimaRichiesta'
}

/**
 * 
 * @param {ChiaviStorageProps} props 
 * @type {useChiaviStorage}
 */
export default function useChiaviStorage(props) {
  const { nomeApp, CHIAVI_STORAGE_CUSTOM } = props

  const mappaChiaviStorage_ConPrefissoNomeApp = useMemo(
    () => {
      const chiaviTutte = {
        ...CHIAVI_STORAGE_DEFAULT,
        ...CHIAVI_STORAGE_CUSTOM
      }
      return Object.entries(chiaviTutte).reduce(
        (mappaChiaviAccumulatore, [chiaveOggetto, chiaveStorage]) => ({
          ...mappaChiaviAccumulatore,
          [chiaveOggetto]: `${nomeApp}_${chiaveStorage}`
        }),
        {}
      )
    },
    [CHIAVI_STORAGE_CUSTOM, nomeApp]
  )

  return mappaChiaviStorage_ConPrefissoNomeApp
}