import { string, number, bool, object, oneOf, oneOfType, arrayOf, shape } from 'prop-types'
import { BaseScatola } from 'layout'
import { BaseTesto } from 'text'
import { formattaBooleano, formattaNumero } from 'utils'
import { TIPI_VARIABILE_PER_IMPOSTAZIONI } from './TipiVariabilePerImpostazioni'

const { DOUBLE, BOOL, JSON } = TIPI_VARIABILE_PER_IMPOSTAZIONI

ImpostazioneSolaLettura.propTypes = {
  tipo: oneOf(Object.values(TIPI_VARIABILE_PER_IMPOSTAZIONI)).isRequired,
  valore: oneOfType([string, number, bool, object]).isRequired,
  valoriAmmissibili: arrayOf(shape({
    key: string.isRequired,
    label: string.isRequired
  }))
}

export default function ImpostazioneSolaLettura(props) {
  const { tipo, valore, valoriAmmissibili } = props

  if (tipo === JSON) return (
    <BaseScatola outlined sx={{ width: 'fit-content', py: 0 }}>
      <pre>
        <BaseTesto>{window.JSON.stringify(valore, null, 2)}</BaseTesto>
      </pre>
    </BaseScatola>
  )

  let valoreFormattato = valore
  if (tipo === BOOL) valoreFormattato = formattaBooleano(valore)
  if (tipo === DOUBLE) valoreFormattato = formattaNumero(valore)
  if (valoriAmmissibili) valoreFormattato = valoriAmmissibili.find(({ key }) => key === valore).label

  return <BaseTesto>{valoreFormattato}</BaseTesto>
}