import { isAfter, isBefore, isValid } from 'date-fns'
import { costruisciMessaggiERegoleValidazione } from './UtilValidazione'
import { formattaData, convertiDataSeServe, isNumeroPositivo, isNumeroIntero, isEmailValida, isNumeroTelefonoValido, isIbanValido, isPartitaIvaValida, isTargaValida, convertTimeStringToDate } from 'utils'

const TIPI_INPUT = {
  TEXT: 'text',
  PASSWORD: 'password',
  NUMBER: 'number',
  NUMERO_POSITIVO: 'numeroPositivo',
  NUMERO_INTERO: 'numeroIntero',
  MONETA: 'moneta',
  EMAIL: 'email',
  TELEFONO: 'telefono',
  IBAN: 'iban',
  PARTIVA_IVA: 'partitaIva',
  TARGA: 'targa'
}
const TIPI_INPUT_VALORI = Object.values(TIPI_INPUT)

const ERRORI_VALIDAZIONE = {
  NUMERO_NON_POSITIVO: 'Inserire un numero positivo',
  NUMERO_NON_INTERO: 'Inserire un numero senza virgola',
  EMAIL_NON_VALIDA: 'Indirizzo e-mail non valido',
  TELEFONO_NON_VALIDO: 'Inserire solo numeri, punti o trattini',
  IBAN_NON_VALIDO: 'Iban non valido',
  PARTITA_IVA_NON_VALIDA: 'Partita Iva non valida',
  TARGA_NON_VALIDA: 'Targa non valida',
  DATA_NON_VALIDA: 'Formato data non valido',
  DATA_MINIMA: 'Data minima:',
  DATA_MASSIMA: 'Data massima:',
  ORA_NON_VALIDA: 'Formato ora non valido',
  FORMATO_FILE_NON_VALIDO: 'Il formato del file caricato non è valido',
  DIMENSIONE_MASSIMA_FILE_SUPERATA: 'Il file caricato supera la dimensione massima consentita'
}

function creaRegoleValidazioneStringa(inputType) {
  let htmlInputType = inputType
  let regole = []

  switch (inputType) {
    case TIPI_INPUT.NUMERO_POSITIVO:
      htmlInputType = 'number'
      regole.push({
        nome: 'numeroNonPositivo',
        messaggio: ERRORI_VALIDAZIONE.NUMERO_NON_POSITIVO,
        funzione: isNumeroPositivo
      })
      break
    case TIPI_INPUT.NUMERO_INTERO:
      htmlInputType = 'number'
      regole.push({
        nome: 'numeroNonIntero',
        messaggio: ERRORI_VALIDAZIONE.NUMERO_NON_INTERO,
        funzione: isNumeroIntero
      })
      break
    case TIPI_INPUT.EMAIL:
      htmlInputType = 'email'
      regole.push({
        nome: 'emailNonValida',
        messaggio: ERRORI_VALIDAZIONE.EMAIL_NON_VALIDA,
        funzione: isEmailValida
      })
      break
    case TIPI_INPUT.TELEFONO:
      htmlInputType = 'tel'
      regole.push({
        nome: 'telefonoNonValido',
        messaggio: ERRORI_VALIDAZIONE.TELEFONO_NON_VALIDO,
        funzione: isNumeroTelefonoValido
      })
      break
    case TIPI_INPUT.IBAN:
      htmlInputType = 'text'
      regole.push({
        nome: 'ibanNonValido',
        messaggio: ERRORI_VALIDAZIONE.IBAN_NON_VALIDO,
        funzione: isIbanValido
      })
      break
    case TIPI_INPUT.PARTIVA_IVA:
      htmlInputType = 'text'
      regole.push({
        nome: 'partitaIvaNonValida',
        messaggio: ERRORI_VALIDAZIONE.PARTITA_IVA_NON_VALIDA,
        funzione: isPartitaIvaValida
      })
      break
    case TIPI_INPUT.TARGA:
      htmlInputType = 'text'
      regole.push({
        nome: 'targaNonValida',
        messaggio: ERRORI_VALIDAZIONE.TARGA_NON_VALIDA,
        funzione: isTargaValida
      })
      break
    default:
      break
  }

  return {
    htmlInputType,
    ...costruisciMessaggiERegoleValidazione(regole)
  }
}

function creaRegoleValidazioneData(minDate, maxDate) {
  let regole = [
    {
      nome: 'dataNonValida',
      messaggio: ERRORI_VALIDAZIONE.DATA_NON_VALIDA,
      funzione: data => !data || isValid(convertiDataSeServe(data))
    }
  ]

  if (minDate) {
    regole.push({
      nome: 'dataMinima',
      messaggio: `${ERRORI_VALIDAZIONE.DATA_MINIMA} ${formattaData(minDate)}`,
      funzione: data => !isBefore(convertiDataSeServe(data), minDate)
    })
  }

  if (maxDate) {
    regole.push({
      nome: 'dataMassima',
      messaggio: `${ERRORI_VALIDAZIONE.DATA_MASSIMA} ${formattaData(maxDate)}`,
      funzione: data => !isAfter(convertiDataSeServe(data), maxDate)
    })
  }

  return costruisciMessaggiERegoleValidazione(regole)
}

function creaRegoleValidazioneOra() {
  return costruisciMessaggiERegoleValidazione([
    {
      nome: 'oraNonValida',
      messaggio: ERRORI_VALIDAZIONE.ORA_NON_VALIDA,
      funzione: timeString => !timeString || convertTimeStringToDate(timeString) !== null
    }
  ])
}

function creaRegoleValidazioneFile(tipiFileAccettati, dimensioneMaxInMegabyte) {
  let regole = []

  if (tipiFileAccettati.length > 0) {
    regole.push({
      nome: 'tipoFileNonValido',
      messaggio: ERRORI_VALIDAZIONE.FORMATO_FILE_NON_VALIDO,
      funzione: ([fileDaControllare]) => {
        if (!fileDaControllare) return true
        return tipiFileAccettati.some(({ type }) => type === fileDaControllare.type)
      }
    })
  }
  if (dimensioneMaxInMegabyte !== undefined) {
    regole.push({
      nome: 'dimensioneMax',
      messaggio: ERRORI_VALIDAZIONE.DIMENSIONE_MASSIMA_FILE_SUPERATA,
      funzione: ([fileDaControllare]) => {
        if (!fileDaControllare) return true
        const dimensioneInMegabyte = fileDaControllare.size / 1000000
        return dimensioneInMegabyte < dimensioneMaxInMegabyte
      }
    })
  }

  return costruisciMessaggiERegoleValidazione(regole)
}

export {
  TIPI_INPUT_VALORI,
  creaRegoleValidazioneStringa,
  creaRegoleValidazioneData,
  creaRegoleValidazioneOra,
  creaRegoleValidazioneFile
}