import { ICONE } from 'icons'
import { BaseRadioBoolean, BaseTextInput } from 'inputs'
import { BaseGridLayout, BaseHeaderConIcona } from 'layout'
import FormModificaEntita, { PROP_TYPES_WRAPPER_FORM_MODIFICA } from '../entitaGenerica/components/FormModificaEntita'
import ListaScatoleEntita from '../entitaGenerica/components/ListaScatoleEntita'
import ListaSubPanels from '../entitaGenerica/components/ListaSubPanels'

ProveEmaticheModifica.propTypes = PROP_TYPES_WRAPPER_FORM_MODIFICA

export default function ProveEmaticheModifica(props) {
  const { propsEntita, label } = props

  return (
    <ListaScatoleEntita
      label={label}
      propsEntita={propsEntita}
      renderEntita={({ nome, positivo }) =>
        <BaseHeaderConIcona
          icona={<ICONE.SANGUE />}
          titolo={nome}
          sottotitolo={positivo ? 'Positivo' : 'Negativo'}
        />
      }
      testoBottoneCrea='Aggiungi prova ematica'
    />
  )
}



const NOMI = {
  NOME: 'nome',
  PRESSO: 'presso',
  POSITIVO: 'positivo',
  NOTE: 'note'
}

export function ProvaEmaticaModifica(props) {
  const { propsEntita, titolo, subPanels } = props

  return (
    <>
      {titolo}

      <FormModificaEntita {...propsEntita} larghezzaInputs={30}>
        <BaseGridLayout numeroRighe={4}>
          <BaseTextInput
            label='Nome' name={NOMI.NOME}
          />
          <BaseTextInput
            label='Effettuata presso' name={NOMI.PRESSO}
          />
          <BaseRadioBoolean
            name={NOMI.POSITIVO}
            labelTrue='Positivo' labelFalse='Negativo'
          />
          <BaseTextInput
            label='Note' name={NOMI.NOTE}
            multiline rows={2}
          />
        </BaseGridLayout>
      </FormModificaEntita>

      <br/>
      <ListaSubPanels subPanels={subPanels} />
    </>
  )
}