import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { BaseContenutoConProgress } from 'feedback'
import { convertBase64ToString, costruisciRotta } from 'utils'
import { useEntita } from '../../entitaGenerica/components/EntitaContext'
import { usePin } from '../../entitaGenerica/components/PinProvider'
import { OPERAZIONI_BASE } from '../../../network/OperazioniBase'
import useRichiestaServer from '../../../network/RichiestaServerHook'
import HtmlFormIframe from '../components/HtmlFormIframe'

export default function FormHtmlDocumento(props) {
  const {
    template,
    chiudiModal,
    htmlFormPathSpecifico,
    pathDocumentoModifica,
    htmlFormRef,
    salvaBozza
  } = props

  const {
    infoEntitaPrincipale,
    funzioniAlbero: { trovaNodoAlberoConPath_PiuVicinoA },
    urlBase
  } = useEntita()

  /********* Caricamento HTML per iframe *********/

  const [iframeSrcDoc, setIframeSrcDoc] = useState(null)

  const {
    ScatolaMessaggi,
    inviaOperazione,
    inAttesaDelServer
  } = useRichiestaServer()

  useEffect(() => {
    async function caricaTemplateHtmlForm() {
      const { ok, data } = await inviaOperazione(OPERAZIONI_BASE.DOCUMENTI_HTML_FORM_GET, {
        nomeTemplate: template.nome,
        entityName: infoEntitaPrincipale.nome,
        entityUUID: infoEntitaPrincipale.uuid,
        pathDocumentoModifica,
        ...(htmlFormPathSpecifico && {
          pathSpecifico: htmlFormPathSpecifico
        }),
        ...(template.tags && {
          tagsTemplate: template.tags
        })
      })
      if (ok) {
        const { testoB64 } = data
        setIframeSrcDoc(convertBase64ToString(testoB64))
      }
    }
    caricaTemplateHtmlForm()
  }, [infoEntitaPrincipale.nome, infoEntitaPrincipale.uuid, template.nome, htmlFormPathSpecifico, pathDocumentoModifica])

  /***********************************************/

  /************ Navigazione verso rif ************/

  const history = useHistory()

  const { setPathCreazione } = usePin()

  async function navigaVersoRif(navigazioneRif, isCreazione) {
    const nodoAlbero = trovaNodoAlberoConPath_PiuVicinoA(navigazioneRif)
    if (nodoAlbero?.urlPannello) {
      await salvaBozza?.()
      chiudiModal?.()
      if (isCreazione) setPathCreazione(navigazioneRif)
      history.push(costruisciRotta(urlBase, nodoAlbero.urlPannello))
      window.scrollTo(0, 0)
    }
  }

  /***********************************************/

  return (
    <>
      {ScatolaMessaggi}

      <BaseContenutoConProgress inAttesa={inAttesaDelServer}>
        <HtmlFormIframe
          iframeSrcDoc={iframeSrcDoc}
          htmlFormRef={htmlFormRef}
          navigaVersoRif={navigaVersoRif}
        />
      </BaseContenutoConProgress>
    </>
  )
}