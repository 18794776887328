import { oneOf, arrayOf, shape, string, bool } from 'prop-types'
import { Radio, RadioGroup, FormControlLabel, FormHelperText, FormLabel } from '@mui/material'
import useInputGenerico from './hooks/InputGenericoHook'
import useControllerSeServe from './hooks/ControllerSeServeHook'

BaseRadioGroup.propTypes = {
  options: arrayOf(shape({
    // USARE STRINGHE COME VALUE
    value: string.isRequired,
    label: string.isRequired
  })).isRequired,
  posizioneLabel: oneOf(['sopra', 'sotto', 'destra', 'sinistra']),
  visualizzazioneOrizzontale: bool,
  ...useInputGenerico.propTypes
}

BaseRadioGroup.campoConOptions = true

const mappaLabelPlacement = {
  sopra: 'top',
  sotto: 'bottom',
  destra: 'end',
  sinistra: 'start'
}

export default function BaseRadioGroup(props) {
  const {
    label,
    options,
    posizioneLabel = 'destra',
    visualizzazioneOrizzontale,
    ...restProps
  } = props

  const {
    error,
    warning,
    required,
    helperText,
    hidden,
    ...inputGenericoProps
  } = useInputGenerico({
    componenteControllato: true,
    underlyingComponent: 'checkbox_o_radio',
    ...restProps
  })

  const {
    value,
    onBlur,
    onChange,
    propsRimanenti
  } = useControllerSeServe(inputGenericoProps)

  return (
    <div style={{ ...(hidden && { display: 'none' }) }}>
      <FormLabel
        required={required}
        error={error}
        {...(warning && { sx: { color: 'warning.main' } })}
      >
        {label}
      </FormLabel>

      <RadioGroup
        value={value}
        onBlur={onBlur}
        onChange={e => onChange(e.target.value)}
        row={visualizzazioneOrizzontale}
      >
        {options.map(({ value: optionValue, label: optionLabel }) =>
          <FormControlLabel
            key={optionValue}
            value={optionValue}
            label={optionLabel}
            labelPlacement={mappaLabelPlacement[posizioneLabel]}
            control={
              <Radio {...propsRimanenti} />
            }
          />
        )}
      </RadioGroup>
      
      <FormHelperText
        error={error}
        {...(warning && { sx: { color: 'warning.main' } })}
      >
        {helperText}
      </FormHelperText>
    </div>
  )
}
