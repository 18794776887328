import { object, bool, string } from 'prop-types'
import { BaseTesto, BaseGrassetto, BaseCoppiaChiaveValore } from 'text'
import { capitalizza } from 'utils'
import { formattaIndirizzo } from './utilIndirizzo'

Indirizzo.propTypes = {
  indirizzo: object.isRequired,
  visioneRiassuntiva: bool,
  label: string,
  senzaBaseTesto: bool,
  senzaLabel: bool,
  soloVia: bool
}

export default function Indirizzo(props) {
  const {
    indirizzo,
    visioneRiassuntiva = true,
    label = 'Indirizzo',
    senzaBaseTesto,
    senzaLabel,
    soloVia
  } = props
  if (!indirizzo) return null

  const {
    cap,
    comune,
    numeroCivico,
    provincia,
    tipo,
    via
  } = indirizzo

  const tipoFormattato = capitalizza(tipo)

  if (visioneRiassuntiva) {
    const indirizzoFormattato = formattaIndirizzo(indirizzo, soloVia)

    if (senzaBaseTesto) return indirizzoFormattato
    if (senzaLabel) return <BaseTesto>{indirizzoFormattato}</BaseTesto>
    return <BaseCoppiaChiaveValore chiave={label} valore={indirizzoFormattato} />
  }

  return (
    <>
      {!senzaLabel && <BaseGrassetto>{label}</BaseGrassetto>}
      <BaseTesto>{tipoFormattato} {via} {numeroCivico}</BaseTesto>
      <BaseTesto>{comune} ({cap} {provincia})</BaseTesto>
    </>
  )
}
