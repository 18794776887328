import { object, objectOf, shape, arrayOf, string, func } from 'prop-types'
import { TIPI_MESSAGGI } from './hooks/MessaggiBase'
import { BaseIconButtons } from 'inputs'
import { BaseScatola, BaseGridLayout } from 'layout'
import { BaseTesto } from 'text'
import { sx } from 'utils'

BaseScatolaMessaggi.propTypes = {
  MESSAGGI: objectOf(shape({
    key: string.isRequired,
    msg: string
  })).isRequired,
  chiaviMessaggiCorrenti: arrayOf(string).isRequired,
  eliminaMessaggioPassandoChiave: func.isRequired,
  propsTesto: object
}

export default function BaseScatolaMessaggi(props) {
  const {
    MESSAGGI,
    chiaviMessaggiCorrenti,
    eliminaMessaggioPassandoChiave,
    propsTesto,
    ...restProps
  } = props

  return chiaviMessaggiCorrenti.map(key => {
    const messaggioPredefinito = MESSAGGI[key]
    const testo = messaggioPredefinito?.msg || key

    let isError
    let isWarning
    let isInfo
    let isSuccess
    if (messaggioPredefinito) {
      isError = messaggioPredefinito.tipo === TIPI_MESSAGGI.ERROR
      isWarning = messaggioPredefinito.tipo === TIPI_MESSAGGI.WARNING
      isInfo = messaggioPredefinito.tipo === TIPI_MESSAGGI.INFO
      isSuccess = messaggioPredefinito.tipo === TIPI_MESSAGGI.SUCCESS
    } else {
      isError = true
    }

    return (
      <BaseScatola
        errore={isError}
        warning={isWarning}
        info={isInfo}
        success={isSuccess}
        key={key}
        {...restProps}
        {...sx(restProps, { mb: 2 })}
      >
        <BaseGridLayout alignCenter>
          <BaseIconButtons.Chiudi
            onClick={() => eliminaMessaggioPassandoChiave(key)}
            sx={{ p: 0 }}
          />
          <BaseTesto {...propsTesto}>{testo}</BaseTesto>
        </BaseGridLayout>
      </BaseScatola>
    )
  })
}
