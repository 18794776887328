import { ICONE } from 'icons'
import { BaseHeaderConIcona } from 'layout'
import { creaLabelUtente } from './utilPermessiEntita'

export default function HeaderUtente(props) {
  const { utente, ...restProps } = props

  return (
    <BaseHeaderConIcona
      icona={<ICONE.PERSONA />}
      titolo={creaLabelUtente(utente)}
      sottotitolo={utente.info.nickname}
      {...restProps}
    />
  )
}
