import { BaseModal, useRichiestaServer, FormCambioPassword, BaseButtons, BaseTextInput, BaseGridLayout, BaseForm  } from '@lucan/base-ui';
import MessageBox from 'components/MessageBox';
import Loading from 'components/Loading';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { OPERAZIONI } from 'Configurazione';
import Login2faInit from './Login2faInit';
import { Box, Stack } from '@mui/material';



const Login2fa = ({username, onComplete, token, logout}) => {
	// 1 accesso normale + pulsante reset procedura
  // 2 pulsante invio codice via mail
  // 3 campo verifica codice via mail
 

 const [step, setStep] = useState(1)
 const nextStep = () => setStep(step+1)

	return(<>
			{step === 1 && 
		<><Box sx={{maxWidth:'20rem', margin:'0 auto', backgroundColor:'#ddd',borderRadius: '10px'}}>
				<Stack direction="row" spacing={2} sx={{p:3}}>
					{Login2fa.MicrosoftLogo}
					<p style={{textAlign:'left'}}>
					Usa l'applicazione <b>Microsoft Authenticator</b> per ottenere il codice
					</p>
				</Stack>
						<p> 
						{Login2fa.PlayStoreMicrosoft}
						{Login2fa.AppStoreMicrosoft}
						</p>
			</Box>
				<LoginVerifyQRcode token={token} onComplete={onComplete}/>
				<a href="#" style={{fontSize:'0.8em'}} onClick={nextStep}>non sei in possesso del qr code? ripistina l'accesso</a>
				<br /><br />
				<BaseButtons.Logout testo="torna al login" size={'small'} color={'default'} variant="outlined" onClick={logout}  className='full' />
			</>}
			{step === 2 && <Login2faInit username={username} onComplete={ onComplete} token={token} logout={logout} ripristina={true}/>}
		</>)

}

const LoginVerifyQRcode =  ({token, onComplete}) =>{
	const {
    inviaOperazione,
    inAttesaDelServer,
  } = useRichiestaServer()

	
	const [error, setError] = useState('')

	
	useEffect(() => {
   
		function keyboardListener(e) {
			if (e.key === 'Enter') formRef?.current?.submit()
		}

		window.addEventListener('keydown', keyboardListener)
		return () => window.removeEventListener('keydown', keyboardListener)
 
	}, [])

		
	async function validacodice({code}){
		const { ok, data, error:errorRichiesta} = await inviaOperazione(OPERAZIONI.TOTP_VERIFY,{token, code, "applicazione":"login"})

		if (ok){
			if(data.esito){
				onComplete(data)
			}else{
				setError(data.errorMsg)
			}				
		}else{
			setError(errorRichiesta.message);
		}		 
	}

	const formRef = useRef()

	return(<>
			<Loading loading={inAttesaDelServer} />
			{!inAttesaDelServer && error && <MessageBox  errorMsg={{main:error}}/>}
			<BaseForm
				onSubmit={validacodice}
				ref={formRef}
			>
				<BaseGridLayout vertical spacing={0}>
					<BaseTextInput  label='Codice' name='code' defaultValue='' required />
					<BaseButtons.Invia className='full' submit />
				</BaseGridLayout>
			</BaseForm></>)


}

Login2fa.MicrosoftLogo = <img src={process.env.PUBLIC_URL +'/2fa/MicrosoftAuthenticator_App.png'} width="50" height="60"/>
Login2fa.PlayStoreMicrosoft = <a href="https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=it" target="_blank" rel="noopener">
<img src={process.env.PUBLIC_URL +'/2fa/android_142x50-equal.png'} alt="android" width="142" height="50" />
</a>
Login2fa.AppStoreMicrosoft =<a href="https://apps.apple.com/it/app/microsoft-authenticator/id983156458" target="_blank" rel="noopener">
<img src={process.env.PUBLIC_URL +'/2fa/appios_142x50-equal.png'} alt="appios" width="142" height="50"/>
</a>
Login2fa.LoginVerifyQRcode = LoginVerifyQRcode



export default Login2fa;