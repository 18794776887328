import { useEffect } from 'react'
import { arrayOf, shape, object, string, bool, number, func, oneOf } from 'prop-types'
import { CircularProgress, Box } from '@mui/material'
import { BaseGridLayout, BaseScatola } from 'layout'
import { BasePaginazione } from 'navigation'
import { BaseTitolo } from 'text'
import { debounce } from 'utils'
import RiassuntoEntita from './RiassuntoEntita'

RisultatiRicercaEntita.propTypes = {
  datiRicerca: shape({
    records: arrayOf(shape({
      uuid: string.isRequired
    })).isRequired,
    recordsCount: number,
    page: number,
    redordsPerPage: number,
    sortBy: string,
    sortDirection: oneOf(['asc', 'desc']),
    nonCiSonoAltriRisultati: bool
  }).isRequired,
  setPage: func,
  setRecordsPerPage: func,
  changeSort: func,
  ricercaInCorso: bool,

  titolo: string,
  fallback: string,
  sezioniRiassunto: arrayOf(object), // Vedi RiassuntoEntita
  noPaginazione: bool,
  propsTitolo: object,
  propsPaginazione: object,
  propsRiassunto: object,
  propsLegenda: object
}

export default function RisultatiRicercaEntita(props) {
  const {
    datiRicerca: {
      records,
      recordsCount,
      page,
      recordsPerPage,
      sortBy: activeSortKey,
      sortDirection: activeSortDirection,
      nonCiSonoAltriRisultati
    },
    setPage,
    setRecordsPerPage,
    changeSort,
    ricercaInCorso,

    titolo = 'Risultati trovati',
    fallback = 'Nessun risultato trovato per i criteri di ricerca impostati.',
    sezioniRiassunto,
    noPaginazione,
    propsTitolo,
    propsPaginazione,
    propsRiassunto,
    propsLegenda
  } = props

  const totaleRisultati = noPaginazione ? records.length : recordsCount

  useEffect(() => {
    const elementoDOMcontenitore = document.documentElement
    const elementoDOMdaScrollare = window
    const offsetScroll = 200

    function caricaPiuElementiSeServe() {
      if (nonCiSonoAltriRisultati || ricercaInCorso) return

      const {
        clientHeight: altezzaVisibile,
        scrollTop: altezzaGiaScrollata_NonVisibile,
        scrollHeight: altezzaTotaleScrollabile
      } = elementoDOMcontenitore

      // La pagina è scrollata fino in fondo se:
      // altezzaVisibile + altezzaGiaScrollata_NonVisibile === altezzaTotaleScrollabile
      // Quindi (altezzaTotaleScrollabile - offsetScroll) significa che sono arrivato quasi in fondo
      const utenteHaScrollato_QuasiInFondoAlContenitore =
        (altezzaVisibile + altezzaGiaScrollata_NonVisibile >= altezzaTotaleScrollabile - offsetScroll)

      if (utenteHaScrollato_QuasiInFondoAlContenitore) {
        setPage(page => page + 1)
      }
    }

    // Miglioro le performance controllando solo ogni 200 millisecondi
    const caricaPiuElementiSeServe_Throttled = debounce(caricaPiuElementiSeServe, 200)

    elementoDOMdaScrollare.addEventListener('scroll', caricaPiuElementiSeServe_Throttled)
    return () => elementoDOMdaScrollare.removeEventListener('scroll', caricaPiuElementiSeServe_Throttled)
  }, [nonCiSonoAltriRisultati, ricercaInCorso, setPage])

  if (totaleRisultati <= 0) {
    return <BaseTitolo {...propsTitolo}>{fallback}</BaseTitolo>
  }

  const uiPaginazione = !noPaginazione && (
    <BasePaginazione
      paginaCorrente={page}
      totaleElementi={totaleRisultati}
      elementiPerPagina={recordsPerPage}
      onCambioPagina={setPage}
      onCambioElementiPerPagina={setRecordsPerPage}
      nascondiTotaleElementi
      {...propsPaginazione}
    />
  )

  return (
    <BaseScatola>
      <BaseGridLayout vertical noWrap>
        <BaseGridLayout justifySpaceBetween>
          <BaseTitolo {...propsTitolo}>
            {titolo}
            <BaseTitolo component='span' color='textSecondary'>
              {/* &nbsp; (totali: {totaleRisultati}{!noPaginazione && `, visualizzati: ${records.length}`}) */}
              &nbsp; (visualizzati: {records.length})
            </BaseTitolo>
          </BaseTitolo>

          {uiPaginazione}
        </BaseGridLayout>

        <RiassuntoEntita
          sezioni={sezioniRiassunto}
          soloLegenda
          activeSortKey={activeSortKey}
          activeSortDirection={activeSortDirection}
          onChangeSort={changeSort}
          {...propsLegenda}
        />

        {records.map(entita =>
          <RiassuntoEntita
            sezioni={sezioniRiassunto}
            entita={entita}
            key={entita.uuid}
            {...propsRiassunto}
          />
        )}

        {noPaginazione ? (
          !nonCiSonoAltriRisultati && (
            <Box textAlign='center' m={1}>
              <CircularProgress />
            </Box>
          )
        ) : (
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            {uiPaginazione}
          </div>
        )}
      </BaseGridLayout>
    </BaseScatola>
  )
}