import { useRef } from 'react'
import { capitalizza, downloadFile, nonInProduzione, useIsMounted, useStateWithLabel } from 'utils'
import { ERRORI_STANDARD, useMessaggiBase } from './MessaggiBase'

export default function useRichiestaServerConFeedback(props) {
  const {
    MESSAGGI_RICHIESTA_CUSTOM = {},
    condizioneErroreCustom,
    calcolaErroreCustom
  } = props

  const [inAttesaDefault, setInAttesaDefault] = useStateWithLabel(false, 'inAttesaDefault')
  const toastRefDefault = useRef()

  const {
    setMessaggio,
    eliminaTuttiMessaggi,
    ScatolaMessaggi
  } = useMessaggiBase(MESSAGGI_RICHIESTA_CUSTOM)

  function setMessaggioSuccesso(chiaveMessaggioSuccesso) {
    const messaggioSuccesso = MESSAGGI_RICHIESTA_CUSTOM[chiaveMessaggioSuccesso]
    if (messaggioSuccesso) setMessaggio(messaggioSuccesso)
  }

  function setMessaggioErrore(error) {
    const erroreRichiesta = calcolaErroreRichiesta(error)
    if (erroreRichiesta) setMessaggio(erroreRichiesta)
  }

  function calcolaErroreRichiesta(error) {
    // Gestione status 401 per far capire a useRichiestaServer che deve fare il logout
    if (error.response?.status === 401) {
      console.warn('401: inserito messaggio con codice SESSIONE_SCADUTA')
      error.messaggi = [{ msg: 'Sessione scaduta', codice: 'SESSIONE_SCADUTA' }]
    }

    if (condizioneErroreCustom?.(error) && calcolaErroreCustom) {
      return calcolaErroreCustom(error, MESSAGGI_RICHIESTA_CUSTOM)
    }

    if (error.response) {
      // The request was made and the server responded with a status code outside 2xx
      const { status } = error.response
      if (status >= 400 && status < 500) return ERRORI_STANDARD.ERRORE_COMUNICAZIONE_CON_SERVER
      if (status >= 500) return ERRORI_STANDARD.ERRORE_INTERNO_AL_SERVER
    }

    if (error.request) {
      // The request was made but no response was received
      return ERRORI_STANDARD.ERRORE_SERVER_NON_RISPONDE
    }

    // Something happened in setting up the request that triggered an Error
    return ERRORI_STANDARD.ERRORE_GENERICO
  }

  const isMounted = useIsMounted()

  // Funzione generica che ritorna un wrapper di una richiesta al server
  // Questa funzione non dovrebbe mai essere usata direttamente nei componenti
  // Meglio definite delle funzioni di utilità che espongono un'API comoda ai componenti
  /**
   * @type {import("../../common/feedback/hooks/RichiestaServerConFeedback").richiestaConAttesaEGestioneErrori}
   */
  function richiestaConAttesaEGestioneErrori(richiestaPromise, opzioni = {}) {
    const {
      setInAttesa = setInAttesaDefault,
      abilitaCatchDefault = true,
      chiaveMessaggioSuccesso = ''
    } = opzioni

    setInAttesa(true)
    eliminaTuttiMessaggi()

    return richiestaPromise
      .then(data => {
        if (chiaveMessaggioSuccesso) setMessaggioSuccesso(chiaveMessaggioSuccesso)
        return Promise.resolve({ ok: true, data })
      })
      .catch(error => {
        if (abilitaCatchDefault) setMessaggioErrore(error)
        return Promise.resolve({ ok: false, error })
      })
      .finally(() => {
        if (nonInProduzione()) {
          // In sviluppo non faccio il controllo isMounted perché dà
          // problemi con react refresh (quando si salva e avviene il
          // refresh, l'attesa a volte rimane true ed è fastidioso) 
          setInAttesa(false)
        } else {
          if (isMounted) setInAttesa(false)
        }
      })
  }

  // Funzione generica che ritorna un wrapper di una richiesta di download al server
  // Questa funzione non dovrebbe mai essere usata direttamente nei componenti
  // Meglio definite delle funzioni di utilità che espongono un'API comoda ai componenti
  function downloadFileConAttesaEGestioneErrori(url, nomeFileScaricato, opzioni = {}) {
    const {
      toastRef = toastRefDefault,
      abilitaCapitalizzaNomeFile = true,
      ...opzioniRichiesta
    } = opzioni

    if (abilitaCapitalizzaNomeFile) {
      nomeFileScaricato = capitalizza(nomeFileScaricato)
    }

    return richiestaConAttesaEGestioneErrori(
      downloadFile(url, nomeFileScaricato)
        .then(data => {
          toastRef?.current?.mostraToast()
          return Promise.resolve(data)
        }),
      opzioniRichiesta
    )
  }

  return {
    richiestaConAttesaEGestioneErrori,
    downloadFileConAttesaEGestioneErrori,
    inAttesaDelServer: inAttesaDefault,
    downloadToastRef: toastRefDefault,
    ScatolaMessaggi
  }
}