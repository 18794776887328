import { format, formatDistance } from 'date-fns'
import { it } from 'date-fns/locale'
import { instanceOf, object, string } from 'prop-types'

HumanDate.propTypes = {
  datetime: instanceOf(Date),
  className: string,
  style: object
}

export default function HumanDate(props) {
  const { datetime = null, className = '', style } = props

  if (!datetime) return null

  return (
    <span
      className={`human-date ${className}`}
      style={{ textDecoration: 'underline dotted', ...style }}
      title={format(datetime, 'PPpp', { locale: it })}
    >
      {formatDistance(datetime, new Date(), { addSuffix: true, locale: it })}
    </span>
  )
}