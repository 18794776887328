import { useEffect, useRef } from 'react'
import { arrayOf, string, func, shape, oneOfType, bool, object } from 'prop-types'
import { deepEquals, haAlmenoUnaChiave } from 'utils'
import { getNameDaDescrizioneCampo, getNomiCampi } from '../util/AltreUtil'
import useCampiForm from './CampiFormHook'
import useControllerSeServe from './ControllerSeServeHook'
import useInputGenerico from './InputGenericoHook'

useSubForm.propTypes = {
  // Array di nomi di campi, oppure di oggetti che hanno una proprietà name
  // Permette di specificare quali campi devono essere nascosti
  campiDaNascondere: arrayOf(oneOfType([
    string,
    shape({ name: string })
  ])),

  // Array di nomi di campi, oppure di oggetti che hanno una proprietà name
  // Permette di specificare quali campi devono essere required
  campiObbligatori: arrayOf(oneOfType([
    string,
    shape({ name: string })
  ])),

  // Riceve la descrizione dei campi e la restituisce modificata
  // Chiamata dopo che sono state applicate le altre trasformazioni
  trasformaCampi: func,

  formRef: object,
  disabilitaValidazionePrimoRender: bool
}

export default function useSubForm(props) {
  const {
    descrizioneCampi,
    campiDaNascondere,
    campiObbligatori,
    trasformaCampi,
    formRef: formRef_Esterno,
    disabilitaValidazionePrimoRender,
    ...restProps
  } = props

  const { defaultValues } = useCampiForm(descrizioneCampi)

  function isFormNonCompilata(valori) {
    return !valori || !haAlmenoUnaChiave(valori) || deepEquals(defaultValues, valori)
  }

  const { validazione = {}, messaggiErrore } = restProps
  const propsValidazioneSubForm = {
    validazione: {
      ...validazione,
      validate: {
        validazioneSubForm: async valori => {
          if (isFormNonCompilata(valori)) {
            formRef.current.clearErrors()
            return true
          }
          return await formRef.current.trigger()
        },
        ...validazione.validate
      }
    },
    messaggiErrore: {
      validazioneSubForm: 'Sono presenti degli errori',
      ...messaggiErrore
    }
  }

  const {
    disabled,
    ...inputGenericoProps
  } = useInputGenerico({
    componenteControllato: true,
    underlyingComponent: 'subForm',
    ...restProps,
    ...propsValidazioneSubForm
  })

  const {
    value,
    onChange,
    onBlur,
    propsRimanenti
  } = useControllerSeServe(inputGenericoProps)

  let campiDaMostrare = filtraCampiDaNascondere(descrizioneCampi, campiDaNascondere)
  const forzaTuttiNonObbligatori = !propsRimanenti.required && isFormNonCompilata(value)
  campiDaMostrare = impostaCampiObbligatori(campiDaMostrare, campiObbligatori, forzaTuttiNonObbligatori)
  const descrizioneCampiDefinitiva = trasformaCampi?.(campiDaMostrare) || campiDaMostrare

  const { campi } = useCampiForm(descrizioneCampiDefinitiva)

  const formRef = useRef()

  useEffect(() => {
    if (formRef_Esterno) formRef_Esterno.current = formRef.current
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formRef.current])

  useEffect(() => {
    if (!disabilitaValidazionePrimoRender) formRef.current.trigger()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function inviaOggettoAggiornato() {
    const oggettoValori = formRef.current.getValues()
    if (isFormNonCompilata(oggettoValori)) {
      onChange('')
      Object.entries(defaultValues).forEach(([name, value]) => formRef.current.setValue(name, value))
    } else {
      onChange(oggettoValori)
    }
    onBlur()
  }

  return {
    propsBaseForm: {
      ref: formRef,
      defaultValues: value || defaultValues,
      onChangeTuttiCampi: inviaOggettoAggiornato,
      readOnly: disabled,
      nonUsareFormTagHtml: true,
    },
    campi,
    ...propsRimanenti
  }
}



function filtraCampiDaNascondere(descrizioneCampi, campiDaNascondere) {
  if (!campiDaNascondere || campiDaNascondere.length === 0) {
    return descrizioneCampi
  }
  const nomiCampiDaNascondere = getNomiCampi(campiDaNascondere)
  return descrizioneCampi.filter(descrizioneCampo => {
    const name = getNameDaDescrizioneCampo(descrizioneCampo)
    return !nomiCampiDaNascondere.includes(name)
  })
}

function impostaCampiObbligatori(descrizioneCampi, campiObbligatori, forzaTuttiNonObbligatori = false) {
  if (!forzaTuttiNonObbligatori && (!campiObbligatori || campiObbligatori.length === 0)) {
    return descrizioneCampi
  }
  const nomiCampiObbligatori = getNomiCampi(campiObbligatori)
  return descrizioneCampi.map(descrizioneCampo => {
    const name = getNameDaDescrizioneCampo(descrizioneCampo)
    return {
      ...descrizioneCampo,
      props: {
        ...descrizioneCampo.props,
        required: forzaTuttiNonObbligatori ? false : nomiCampiObbligatori.includes(name)
      }
    }
  })
}