import { deepClone } from './Oggetti'

/**
 * Restituisce un array con il path suddiviso
 * @param {string} path
 * @returns
 */
 function pathToArray(path) {
        return path.replace(/\[/g, ".[").split(".");
      }
      
/**
 * Ricrea la stringa del path a partire dall'array dei singoli pezzi
 * @param {string[]} path
 * @returns
 */
function arrayToPath(path) {
return path.join(".").replace(/\.\[/g, "[");
}

/**
 * Restituisce e controlla l'esistenza per la propietà richiesta nella variabile passata. 
 * Se la proprietà non esiste viene ritornato null.
 * @example
 * Esempio a seconda della proprietà:
 *  "value"    {"value": "ok"}       "value"
 *  "[1]"      ["ok", "ok"]          "2"
 *  "[id:1]"   [{id:0}, {id:1}]       "1" indice di dove si trova l'oggetto con la proprietà valore specificato
 * 
 * @param {string} prop 
 * @param {*} obj 
 * @returns string|null
 */
function getProp(prop, obj){
        
        let kop = kindOfProp(prop);
        if(kop.kind == 'simple'){
                return  obj[kop.val] === undefined ? null : kop.val;
        }

        if (!Array.isArray(obj)) return;

        if(kop.kind == 'array'){
                return  obj[kop.val] === undefined ? null : kop.val;
        }
        if(kop.kind == 'object'){
                let res = obj.findIndex((item) => {
                        if (kop.key in item) {
                                return (item[kop.key] == kop.val);
                        }
                        false
                });

                if(res < 0){
                        return null;
                }
                return res;
        }
        return;
}
/**
 * Resituisce in maniera struttura il segmento del path passato come parametro
 * 
 * @example
 * Esempio a seconda della proprietà:
 *  "value"              {kind:simple, val: "value"}
 *  "[value]"            {kind:array, val: "value"}
 *  "[id:prop]"            {kind:object, key: "prop", val: "value"}
 * @param {string} prop 
 * 
 *     
 * 
 * @returns object
 */
function kindOfProp(prop){
        const matches = prop.match(/\[(.*?)\]/); 
        if(matches){ 
                const idprop = matches[1].split(':');
                if (idprop.length === 2) {
                        
                        return {kind:'object', key:idprop[0], val: idprop[1]};
                }
                if (idprop.length === 1) {
                        return {kind:'array', val :  idprop[0]};
                }
                return null;
        }
        return {kind:'simple', val : prop};
}

/**
 * Modifica nell'obj a seconda del pathStr specificato la variabile value. 
 * Viene controllato che il path specificato sia già popolato
 * 
 * 
 * @param {string} pathStr 
 * @param {*} obj 
 * @param {*} value 
 * @returns 
 */
function set(pathStr, obj, value) {
        
        let path = pathToArray(pathStr);
        let cloneObj = clone(obj);
        let toMod = cloneObj;
        if(path.length >1){
                toMod =    get(arrayToPath(path.slice(0, -1)), cloneObj);
        }
        let prop = getProp(path[path.length-1], toMod) ?? null;
        if(null == prop){ // è un nuovo inserimento
                throw new PathUtilsError("path '"+ pathStr+"' non trovato nell'oggetto","set",pathStr, obj, value); 
        }
        toMod[prop] = value;
        // todo forse meglio tornare l'esito x' obj passato è già modificato
        return cloneObj;
}

/**
 * Aggiunge nell'obj a seconda del pathStr specificato la variabile value. 
 * Viene controllato che nel path specificato non sia già popolato
 * 
 * 
 * @param {string} pathStr 
 * @param {*} obj 
 * @param {*} value 
 * @returns 
 */
function create(pathStr, obj, value) {
        
        let path = pathToArray(pathStr);
        let cloneObj = clone(obj);
        let toMod = cloneObj;
        if(path.length >1){
                toMod =    get(arrayToPath(path.slice(0, -1)), cloneObj);
        }
        let prop = getProp(path[path.length-1], toMod) ?? null;
        if(null == prop){ // è un nuovo inserimento
                let kop = kindOfProp(path[path.length-1]);
                
                if(kop.kind == 'simple'){
                        prop = kop.val;
                }else{
                        if (!Array.isArray(toMod)) return;

                        if(kop.kind == 'array'){
                                prop = (kop.val == '' ? toMod.length : kop.val);
                        }

                        if(kop.kind == 'object'){
                                if (! (kop.key in value && value[kop.key] == kop.val)) {
                                        throw new PathUtilsError("variabile creata non coerente con la proprietà e valore d'inserimento nel path",'create',pathStr, obj, value);
                                }
                                prop = toMod.length;
                        }                        
                }

        }else{
                throw new PathUtilsError("variabile già presente nel path designato","create",pathStr, obj, value);    
        }

        toMod[prop] = value;
        // todo forse meglio tornare l'esito x' obj passato è già modificato
        return cloneObj;
}

/**
 * Rimove dall'obj la variabile specificata nel path, e restutuisce la nuova copia
 * 
 * @param {string} pathStr 
 * @param {*} obj 
 * @returns 
 */
function remove(pathStr,obj) {

        let path = pathToArray(pathStr);
        let cloneObj = clone(obj);
        let toMod = cloneObj;
        if(path.length >1){
                toMod = get(arrayToPath(path.slice(0, -1)),toMod);
        }
        let prop = getProp(path[path.length-1], toMod) ?? null;
        if(null == prop){ // non esiste l'elemento da cancellare
                throw new PathUtilsError("variabile non presente nel path designato",'remove',pathStr,obj);    
        }
        
        if (Array.isArray(toMod)) {
                toMod.splice(prop, 1); 
        }else{
                delete toMod[prop];
        }
        return cloneObj;
}

/**
 * Resitituisce la variabile se trovata a seconda del path specificato nel obj. null se non trovata
 * 
 * @example
 * Esempio a seconda della proprietà:
 *  "value"    {"value": "ok"}     => "ok"
 *  "[1]"      ["ok", "ok2"]       => "ok2"
 *  "[id:1]"   [{id:0}, {id:1}]    => {id:1}
 * 
 * 
 * @param {string} pathStr 
 * @param {*} obj 
 * @returns 
 */
function get(pathStr,obj) {
        
        let find = obj;
        let path = pathToArray(pathStr);
        
        for (let i = 0, n = path.length; i < n; ++i) {
                let prop = getProp(path[i], find);
                if(null == prop){
                        return null;
                }

                find = find[prop];

        }
        return find;
}

/**
 * Clona la variabile passata
 * @param {*} obj 
 * @returns 
 */
function clone(obj){
        return deepClone(obj);
}

String.prototype.addUuid = function(val, id = 'uuid') {
  return `${this}[${id}:${val}]`
}

String.prototype.addIndex = function(val) {
  return `${this}[${val}]`
}

String.prototype.addPath = function(path) {
  const nonInserirePunto = (
    String(this) === '' || this.endsWith('.') || path.startsWith('[')
  )
  return `${this}${nonInserirePunto ? '' : '.'}${path}`
}

function addUuid(val, id) {
  return ''.addUuid(val, id)
}

function addIndex(val) {
  return ''.addIndex(val)
}

function addPath(path) {
  return ''.addPath(path)
}

function getPathSenzaUltimoSegmento(path) {
  if (!path) return ''
  const segmenti = pathToArray(path)
  if (segmenti.length < 2) return ''
  const segmentiSenzaUltimo = segmenti.slice(0, segmenti.length - 1)
  return arrayToPath(segmentiSenzaUltimo)
}
      
class PathUtilsError extends Error {
        constructor(message, action, pathStr, obj, value = null) {
          super(message);
          console.error(message, action, pathStr, obj, value);
          this.name = "PathUtilsError";
          this.action = action;
          this.pathStr = pathStr;
          this.obj = obj;
          this.value = value;
        }
}

export {get, set, remove, create, pathToArray, arrayToPath, addUuid, addIndex, addPath, getPathSenzaUltimoSegmento};