import PropTypes from 'prop-types'
import { Badge, Box } from '@mui/material'
import { useGridApiContext } from '@mui/x-data-grid/hooks/utils/useGridApiContext'
import { forwardRef, useEffect } from 'react'
import { ICONE } from 'icons'
import { BaseButtons } from 'inputs'
/**
 * @typedef {import("../../common/tables/datagrid/BaseDataGridFilterButton").BaseDataGridFilterButtonProps} BaseDataGridFilterButtonProps 
 * @typedef {import("../../common/tables/datagrid/BaseDataGridFilterButton").BaseDataGridFilterButton} BaseDataGridFilterButton 
 */


/**
 * Filter component displayed on top toolbar of DataGrid
 * 
 * @param {BaseDataGridFilterButtonProps} props
 * @type {BaseDataGridFilterButton}
 */
const BaseDataGridFilterButton = forwardRef(
    (props, ref) => {
        const { open = false, filters, onClick } = props
        const apiRef = useGridApiContext()

        useEffect(() => {
            showFilterPanel(open)
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [open])

        const showFilterPanel = (open) => {
            open === true ? apiRef.current.showFilterPanel() : apiRef.current.hideFilterPanel()
        }

        const handleOnFilterButtonClick = () => onClick?.()

        return (
            <Box ref={ref}>
                <Badge color='secondary' badgeContent={filters.length}>
                    <BaseButtons.Custom
                        testo='Filtra'
                        variant='contained'
                        color='primary'
                        startIcon={<ICONE.FILTRA_LISTA />}
                        sx={{ borderRadius: 0.5 }}
                        onClick={handleOnFilterButtonClick}
                    />
                </Badge>
            </Box>
        )
    }
)

BaseDataGridFilterButton.displayName = 'BaseDataGridFilterButton'

/**
 * Custom props with default props of DataGrid
 */
BaseDataGridFilterButton.propTypes = {
    open: PropTypes.bool,
    filters: PropTypes.arrayOf(PropTypes.object).isRequired,
    onClick: PropTypes.func,
}

export default BaseDataGridFilterButton