import { useEffect } from 'react'
import { number, string } from 'prop-types'
import { Redirect } from 'react-router-dom'
import { BaseTesto } from 'text'
import { useStateWithLabel } from 'utils'

BaseRedirectFraPochiSecondi.propTypes = {
  secondi: number,
  descrizione: string
}

export default function BaseRedirectFraPochiSecondi(props) {
  const { secondi = 3, descrizione, ...restProps } = props

  const [redirezioneInCorso, setRedirezioneInCorso] = useStateWithLabel(false, 'redirezioneInCorso')

  useEffect(() => {
    const timerId = setTimeout(() => setRedirezioneInCorso(true), secondi * 1000)
    return () => clearTimeout(timerId)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondi])

  return (
    <>
      <BaseTesto>{descrizione}</BaseTesto>
      {redirezioneInCorso && <Redirect {...restProps} /> }
    </>
  )
}
