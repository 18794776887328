import { debounce, throttle } from 'lodash'

// Riceve più funzioni e restituisce un'unica funzione che le chiama tutte consecutivamente
// Gli argomenti con cui è invocata la funzione unica sono passati a tutte le singole funzioni
// La funzione unica non ritorna niente
function creaFunzioneUnica(...funzioni) {
  return (...argomenti) => funzioni.forEach(funzione => funzione?.(...argomenti))
}

export {
  debounce,
  throttle,
  creaFunzioneUnica
}