export const ENTITA_BASE = {
  // Allegato (immagini, documenti)
  ALLEGATO: 'Allegato',
  ALLEGATO_DOCUMENTO: 'DocumentoAllegato',
  DOCUMENTO_FILE_MULTIMEDIALE: 'DocumentoFileMultimediale',

  // Indirizzo
  INDIRIZZO: 'Indirizzo',
  LUOGO: 'SinistroLuogo',
  TIPO_LUOGO: 'TipoLuogo',

  // Persona
  PERSONA: 'Persona',
  PERSONA_DOCUMENTO: 'Documento',
  PERSONA_DOCUMENTO_RILIEVI_ANTROPOMETRICI: 'RilieviAntropometrici',
  PERSONA_CONTATTI: 'Contatti',
  PERSONA_RECAPITI_AGGIUNTIVI: 'PersonaRecapitiAggiuntivi',
  PERSONA_NASCITA: 'Nascita',

  // PersonaCondizione
  PERSONA_CONDIZIONE: 'PersonaCondizione',
  PERSONA_CONDIZIONE_STATO: 'PersonaCondizioneStato',
  PERSONA_CONDIZIONE_PROVA_EMATICA: 'ProvaEmatica',
  PERSONA_CONDIZIONE_RIFIUTO_TEST: 'Rifiuto',
  PERSONA_CONDIZIONE_SINTOMATOLOGIA: 'Sintomatologia',
  PERSONA_CONDIZIONE_TEST_ETILOMETRO: 'Etilometro',

  // Veicolo
  VEICOLO: 'Veicolo',
  VEICOLO_PASSEGGERO: 'PersonaVeicolo',
  VEICOLO_ASSICURAZIONE: 'VeicoloAssicurazione',
  VEICOLO_REVISIONE: 'VeicoloRevisione',
  VEICOLO_SCHEDA_H: 'SchedaH',

  // Altro
  PERSONA_O_AZIENDA: 'PersonaOAzienda',
  PERSONA_COINVOLTA: 'PersonaInfrazione',
  AZIENDA: 'Azienda',
  AGENTI: 'Agenti',
  PERMISSION: 'Permission',
  STRUMENTO: 'Strumento',
}