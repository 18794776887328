import { Component } from 'react'
// import StackTrace from 'stacktrace-js'
import { BaseButtons } from 'inputs'
import { BaseScatola } from 'layout'
import { BaseTitolo } from 'text'
import { nonInProduzione } from 'utils'

// const sendErr = (backtrace, error_name, error_msg) => {
//   inviaOp({
//     opId: 'log.Create',
//     data,
//   })
// }

// window.onerror = function (msg, file, line, col, error) {
//   StackTrace.fromError(error).then((err) => {
//     sendErr(err, error.name, msg.replace('Uncaught Error: ', ''))
//   })
// }

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { error: null }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error })
    // StackTrace.fromError(error).then((err) => {
    //   var myarr = error.toString().split(":")
    //   sendErr(err, myarr[0], myarr[1])
    // })
  }

  render() {
    if (!this.state.error) return this.props.children
    
    return (
      <BaseScatola style={{ margin: 16 }}>
        <BaseTitolo grande>Spiacenti, si è verificato un errore</BaseTitolo>
        <BaseTitolo>Premere il bottone per ricaricare la pagina. Se il problema persiste, contattare l'assistenza.</BaseTitolo>
        <BaseButtons.Aggiorna
          testo='Ricarica la pagina'
          color='success'
          onClick={() => {
            if (!nonInProduzione()) {
              window.localStorage.clear()
              window.sessionStorage.clear()
            }
            window.location.reload()
          }}
        />
      </BaseScatola>
    )
  }
}