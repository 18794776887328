import { ICONE } from 'icons'
import { BaseHeaderConIcona } from 'layout'
import { BaseListaCoppieChiaveValore } from 'text'
import ElenchiManager, { ELENCHI } from '../../form/ElenchiManager'
import { useEntita } from '../entitaGenerica/components/EntitaContext'
import { creaDescrizionePersonaCondizione, creaDescrizioneRisultatoEtilometro, creaNomeTest } from './utilPersonaCondizione'

export default function HeaderPersonaCondizione(props) {
  const { condizione, persona, ...restProps } = props

  const { risolviRif } = useEntita()

  return (
    <ElenchiManager
      elenchi={[ELENCHI.STATO_SALUTE]}
      render={el =>
        <BaseHeaderConIcona
          icona={<ICONE.SALUTE />}
          titolo={creaDescrizionePersonaCondizione(condizione, el, { persona, risolviRif })}
          sottotitolo={<DescrizioneTest {...condizione} />}
          {...restProps}
        />
      }
    />
  )
}



function DescrizioneTest(props) {
  const {
    testEtilometro,
    accertamentiEmatici,
    rifiuto
  } = props

  if (rifiuto) return 'Rifiuta di eseguire i test'

  const descEtilometro = testEtilometro
    .map((etilometro, index) => {
      const nomeTest = creaNomeTest(index, true)
      const risultatoTest = creaDescrizioneRisultatoEtilometro(etilometro)
      return `${nomeTest}: ${risultatoTest}`
    })
    .join(' -- ')

  const numAccertamenti = accertamentiEmatici.length
  const numAccertamentiPositivi = accertamentiEmatici.filter(({ positivo }) => positivo).length
  const descAccertamentiEmatici = (numAccertamenti > 0)
    ? `effettuate: ${numAccertamenti} -- positive: ${numAccertamentiPositivi}`
    : 'non effettuate'

  return (
    <BaseListaCoppieChiaveValore
      coppie={{
        'Test etilometrici': descEtilometro,
        'Prove ematiche': descAccertamentiEmatici
      }}
      propsCoppia={{
        grassettoChiave: true,
        grassettoValore: false,
        piccolo: true
      }}
    />
  )
}