import { node, oneOf } from 'prop-types'
import { forwardRef, useImperativeHandle, useRef } from 'react'
import { Popover } from '@mui/material'
import { useStateWithLabel } from 'utils'

const BasePopover = forwardRef(function BasePopover(props, ref) {
  const {
    children,
    posizione = 'bottom',
    ...popoverProps
  } = props

  const [popoverAnchorElement, setPopoverAnchorElement] = useStateWithLabel(null, 'popoverAnchorElement')
  const popoverOpen = Boolean(popoverAnchorElement)

  useImperativeHandle(ref, () => ({
    open,
    close
  }))

  function open(event) {
    setPopoverAnchorElement(event.currentTarget)
  }

  function close() {
    setPopoverAnchorElement(null)
  }

  function calcolaPosizione(posizione) {
    if (posizione === 'top') {
      return {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        transformOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        }
      }
    }

    if (posizione === 'bottom') {
      return {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'center',
        }
      }
    }

    if (posizione === 'left') {
      return {
        anchorOrigin: {
          vertical: 'center',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'center',
          horizontal: 'right',
        }
      }
    }

    if (posizione === 'right') {
      return {
        anchorOrigin: {
          vertical: 'center',
          horizontal: 'right',
        },
        transformOrigin: {
          vertical: 'center',
          horizontal: 'left',
        }
      }
    }
    
    return {}
  }

  return (
    <Popover
      anchorEl={popoverAnchorElement}
      open={popoverOpen}
      onClose={close}
      {...calcolaPosizione(posizione)}
      {...popoverProps}
    >
      {children}
    </Popover>
  )
})

BasePopover.propTypes = {
  children: node.isRequired,
  posizione: oneOf(['top', 'bottom', 'left', 'right', 'custom'])
}

// Hook comodo che fornisce tutto quello che serve per usare il popover
function usePopover() {
  const popoverRef = useRef()
  const openPopover = event => popoverRef.current?.open(event)
  const closePopover = () => popoverRef.current?.close()

  return {
    popoverRef,
    openPopover,
    closePopover
  }
}

export {
  BasePopover,
  usePopover
}