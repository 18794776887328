import { forwardRef, useImperativeHandle } from 'react'
import { object, elementType, oneOf, node, func } from 'prop-types'
import { Snackbar, Alert } from '@mui/material'
import { ICONE } from 'icons'
import { useStateWithLabel } from 'utils'

/**
 * @typedef {import("../common/feedback/BaseToasts").BaseToastProps} BaseToastProps 
 * @typedef {import("../common/feedback/BaseToasts").BaseToast} BaseToast 
 * @typedef {import("../common/feedback/BaseToasts").BaseToastsType} BaseToastsType 
 */

/**
 * 
 * @param {BaseToastProps} props 
 * @type {BaseToast}
 */
const BaseToast = forwardRef(function BaseToast(props, ref) {
  const {
    messaggio,
    color = 'info',
    Icona,
    snackbarProps,
    onClose,
    ...alertProps
  } = props

  const [open, setOpen] = useStateWithLabel(false)

  // Il componente padre può mostrare il toast in modo imperativo,
  // attaccando un ref al toast e chiamando ref.current.mostraToast()
  useImperativeHandle(ref, () => ({
    mostraToast: () => setOpen(true),
    chiudiToast: () => setOpen(false)
  }))

  function handleClose(_, reason) {
    if (reason === 'clickaway') return
    setOpen(false)
    onClose?.()
  }

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      autoHideDuration={3000}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      {...snackbarProps}
    >
      <Alert
        elevation={5}
        variant='filled'
        severity={color}
        icon={Icona && <Icona />}
        {...alertProps}
      >
        {messaggio}
      </Alert>
    </Snackbar>
  )
})

BaseToast.propTypes = {
  messaggio: node.isRequired,
  color: oneOf(['error', 'info', 'success', 'warning']),
  Icona: elementType,
  snackbarProps: object,
  onClose: func
}

/**
 * @type {BaseToastsType}
 */
export const BaseToasts = {
  Download: forwardRef(function Download(props, ref) {
    return (
      <BaseToast
        messaggio='File scaricato.'
        Icona={ICONE.FRECCIA_GIU}
        ref={ref}
        {...props}
      />
    )
  }),

  Custom: forwardRef(function Custom(props, ref) {
    return <BaseToast ref={ref} {...props} />
  })
}