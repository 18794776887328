import { bool, object } from 'prop-types'
import { BaseSelect, BaseTextInput } from 'inputs'
import ElenchiManager, { ELENCHI } from '../../form/ElenchiManager'
import { ENTITA_BASE } from '../EntitaBase'
import FormModificaEntita, { PROP_TYPES_WRAPPER_FORM_MODIFICA } from '../entitaGenerica/components/FormModificaEntita'
import ListaScatoleEntita from '../entitaGenerica/components/ListaScatoleEntita'
import SmartPanel from '../entitaGenerica/components/SmartPanel'
import HeaderIndirizzo from '../indirizzo/HeaderIndirizzo'
import IndirizzoModifica, { validaIndirizzo } from '../indirizzo/IndirizzoModifica'

LuogoModifica.propTypes = {
  ...PROP_TYPES_WRAPPER_FORM_MODIFICA,
  mostraSoloIndirizzi: bool,
  consentiEliminazionePrimoIndirizzo: bool,
  propsIndirizzoModifica: object
}

LuogoModifica.defaultConfig = {
  path: 'luogo',
  nomeEntita: ENTITA_BASE.LUOGO,
  url: 'luogo',
  label: 'Luogo',
  validate: validaLuogo
}

const NOMI = {
  INDIRIZZI: 'indirizzi',
  DESCRIZIONE: 'descrizione',
  TIPO_INTERSEZIONE: 'tipoIntersezione',
  TIPO_LUOGO: 'tipoLuogo'
}

export default function LuogoModifica(props) {
  const {
    propsEntita,
    mostraSoloIndirizzi,
    consentiEliminazionePrimoIndirizzo,
    propsIndirizzoModifica
  } = props
  const { valoriInput, pathEntita } = propsEntita

  const soloUnIndirizzoVuoto = (
    valoriInput.indirizzi.length === 1
    && valoriInput.indirizzi[0].via === ''
  )

  return (
    <SmartPanel {...props}>
      {!mostraSoloIndirizzi &&
        <ElenchiManager
          elenchi={[ELENCHI.TIPO_INTERSEZIONE, ELENCHI.TIPO_LUOGO]}
          render={el =>
            <FormModificaEntita {...propsEntita}>
              <BaseSelect
                label='Tipo intersezione' name={NOMI.TIPO_INTERSEZIONE}
                options={el(ELENCHI.TIPO_INTERSEZIONE)} pair
                larghezza={15} hidden
              />
              {/* <br /> */}
              <BaseSelect
                label='Tipologia luogo' name={NOMI.TIPO_LUOGO}
                options={el(ELENCHI.TIPO_LUOGO)} pair
                larghezza={30}
              />
              <br />
              <BaseTextInput
                label='Descrizione' name={NOMI.DESCRIZIONE}
                multiline rows={2} larghezza={30}
              />
              <br />
              <br />
            </FormModificaEntita>
          }
        />
      }

      <ElenchiManager
        elenchi={[ELENCHI.VARIABILI]}
        render={el =>
          <ListaScatoleEntita
            label='Indirizzi'
            propsEntita={{
              pathEntita: pathEntita.addPath(NOMI.INDIRIZZI),
              valoriInput: valoriInput.indirizzi,
              nomeEntita: ENTITA_BASE.INDIRIZZO
            }}
            renderEntita={indirizzo =>
              <HeaderIndirizzo indirizzo={indirizzo} />
            }
            renderFormModifica={({ propsEntita }) =>
              <IndirizzoModifica
                propsEntita={propsEntita}
                senzaScatola
                usaStradario={el(ELENCHI.VARIABILI)['stradario.abilitato']?.valore ?? false}
                {...propsIndirizzoModifica}
              />
            }
            testoBottoneCrea='Aggiungi indirizzo'
            {...(soloUnIndirizzoVuoto && {
              propsBottoneCrea: { disabled: true }
            })}
            {...(!consentiEliminazionePrimoIndirizzo && {
              calcolaPropsScatolaEntita: (_, index) => index === 0 && { noElimina: true }
            })}
          />
        }
      />
    </SmartPanel>
  )
}



function validaLuogo(utilValidazione) {
  const { valoriInput, getUtilValidazioneAnnidato } = utilValidazione
  const { indirizzi } = valoriInput
  
  indirizzi.forEach(({ uuid }) => {
    const pathRelativoIndirizzo = NOMI.INDIRIZZI.addUuid(uuid)
    validaIndirizzo(getUtilValidazioneAnnidato(pathRelativoIndirizzo))
  })
}