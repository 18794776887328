import { itIT } from '@mui/material/locale';
import {
  createTheme,
  tableCellClasses,
  tableHeadClasses,
  typographyClasses,
  tableRowClasses,
  tableBodyClasses
} from '@mui/material';
/**
 * Color definitions
 */
const PRIMARY = '#1C2536'
const SECONDARY = '#6366F1'
const MAIN_BACKGROUND = '#FAFAFA'
// const SIDEBAR_SELECTED = '#27303F'
const TEXT_PRIMARY = '#111927'
const GREY_50 = '#F2F4F7'
const GREY_100 = '#D9D9D9'
const GREY_200 = '#C4C4C4'
const GREY_400 = '#808080'
const GREY_500 = '#9DA4AE'
const GREY_600 = '#8B8D97'
const GREY_700 = '#6D747D'
const ERROR = '#D32F2F'
const FONT = '\'Roboto\', sans-serif'
// const WHITE = '#FFFFFF'
const INFO_LIGHT = '#E2ECF9'
const WARNING_LIGHT = '#FCE5CA'
const SUCCESS_LIGHT = '#C3DFBE'
const CARD_INFO = '#DAE3EF'


const { palette } = createTheme();
const { augmentColor } = palette;

/**
 * Create custom color
 * @param {string} mainColor 
 * @returns {import('@mui/material').PaletteColor} colors
 */
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });

/**
 * Partial theme with only palette, to reuse in second time.
 * See below.
 *
 * @returns {import('@mui/material').Theme}
 */
const themeWithOnlyPalette = createTheme({
  name: 'alternative',
  palette: {
    background: {
      default: MAIN_BACKGROUND,
    },
    primary: {
      main: PRIMARY,
    },
    secondary: {
      main: SECONDARY,
    },
    error: {
      main: ERROR,
    },
    warning: {
      light: WARNING_LIGHT,
      main: '#ed6c02'
    },
    success: {
      light: SUCCESS_LIGHT,
      main: '#2e7d32'
    },
    info: {
      light: INFO_LIGHT,
      main: '#0288d1'
    },
    text: {
      primary: TEXT_PRIMARY,
    },
    grey: {
      50: GREY_50,
      100: GREY_100,
      200: GREY_200,
      400: GREY_400,
      500: GREY_500,
      600: GREY_600,
      700: GREY_700,
      A100: MAIN_BACKGROUND
    },
    altLight: {
      light: '#F5F7FF',
      main: CARD_INFO
    },
    divider: GREY_50
  },
})

/**
 * Alternative theme from Cloudmind Prototype
 *
 * @type {import('@mui/material').Theme}
 * @author mts
 */
export const ALTERNATIVE_THEME = createTheme(
  themeWithOnlyPalette,
  {
    typography: {
      fontFamily: FONT,
      h2: {
        fontSize: '2.75rem',
        fontWeight: 700
      },
      h4: {
        fontWeight: 700
      }
    },
    shape: {
      borderRadius: 8
    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: themeWithOnlyPalette.palette.primary.main,
          },
        },
      },
      MuiButton: {
        defaultProps: {
          disableElevation: true
        },
        styleOverrides: {
          root: {
            textTransform: 'inherit'
          }
        }
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: '8px',
            boxShadow: '0px 3px 4px rgba(0, 0, 0, 0.1)',
          }
        }
      },
      MuiChip: {
        styleOverrides: {
          root: {
            '&.MuiChip-colorError': {
              backgroundColor: themeWithOnlyPalette.palette.error.light,
              color: '#000'
            },
            '&.MuiChip-colorInfo': {
              backgroundColor: themeWithOnlyPalette.palette.info.light,
              color: '#000'
            },
            '&.MuiChip-colorSuccess': {
              backgroundColor: themeWithOnlyPalette.palette.success.light,
              color: '#000'
            },
            '&.MuiChip-colorWarning': {
              backgroundColor: themeWithOnlyPalette.palette.warning.light,
              color: '#000'
            },
            '& .MuiSvgIcon-root': {
              color: '#000',
              '&:hover': {
                color: themeWithOnlyPalette.palette.grey[700]
              }
            }
          }
        }
      },
      MuiDataGrid: {
        styleOverrides: {
          root: {
            border: 0,
            '& .MuiDataGrid-sortIcon': {
              color: themeWithOnlyPalette.palette.altLight.light
            },
            '& .MuiDataGrid-columnSeparator svg': {
              color: 'transparent'
            },
            '& .MuiDataGrid-columnHeaders': {
              borderBottom: `2px solid ${themeWithOnlyPalette.palette.grey[200]}`,
              backgroundColor: themeWithOnlyPalette.palette.primary.main,
              color: themeWithOnlyPalette.palette.altLight.light,
              '& .MuiCheckbox-root .MuiSvgIcon-root': {
                color: themeWithOnlyPalette.palette.altLight.light
              }
            },
            '& .MuiDataGrid-columnHeader': {
              '&:focus': {
                outline: 'none'
              }
            },
            '& .MuiDataGrid-cell:focus-within': {
              outline: 'none'
            },
            '& .MuiDataGrid-row': {
              backgroundColor: '#FFFFFF',
              border: '1px solid rgba(0, 0, 0, 0.12)',
              borderBottom: 0,
              '&.Mui-selected': {
                backgroundColor: 'inherit',
                '&:hover': {
                  backgroundColor: 'inherit'
                }
              },
              '& > .MuiDataGrid-cell:last-child': {
                borderRight: '2px inset rgba(0, 0, 0, 0.12)'
              }
            },
            '& .MuiDataGrid-footerContainer': {
              backgroundColor: '#FFFFFF',
              border: '1px solid rgba(0, 0, 0, 0.12)',
              boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.12);'
            }
          }
        }
      },
      MuiDrawer: {
        styleOverrides: {
          root: {
            '& .MuiDrawer-paper': {
              backgroundColor: themeWithOnlyPalette.palette.primary.main,
              '& > .MuiDrawer-open .MuiListItem-root': {
                paddingLeft: '16px',
                paddingRight: '16px',
              },
              '&  .MuiListItem-root': {
                boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.12)'
              },
            },
            '& .MuiSvgIcon-root': {
              fill: '#9DA4AE',
              maxWidth: '32px',
              width: '18px'
            },
            '& .MuiTypography-root': {
              color: '#9DA4AE',
              fontWeight: 400,
            },
            '& .MuiListItemIcon-root': {
              minWidth: '18px',
              marginRight: '20px',
              marginLeft: '15px',
            },/*
            '& .MuiDrawer-HomeLink .MuiListItemIcon-root': {
              marginLeft: 0,
              minWidth: 'none',
              '& .MuiSvgIcon-root': {
                //width: '30px'
              }
            },*/
            '& .MuiDrawer-HomeLink .MuiSvgIcon-root': {
                minWidth: 'none',
                width:'1em'
            },
            '&.MuiDrawer-docked': {
              '& .MuiListItemIcon-root': {
                marginLeft: '11px',
              },
              '& .MuiDrawer-HomeLink .MuiListItemIcon-root': {
                marginLeft: 0
              },
              '& .Mui-selected': {
                backgroundColor: '#27303F',
                '& .MuiSvgIcon-root': {
                  fill: '#6366F1'
                },
                '& .MuiTypography-root': {
                  color: '#FFFfff'
                }
              }
            }
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontStyle: 'italic'
          }
        }
      },
      MuiLink: {
        styleOverrides: {
          root: {
            color: themeWithOnlyPalette.palette.secondary.main
          }
        }
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            '& .MuiTabs-indicator': {
              backgroundColor: themeWithOnlyPalette.palette.secondary.main,
              height: '5px'
            },
            '& .MuiButtonBase-root': {
              textTransform: 'initial',
              color: themeWithOnlyPalette.palette.grey['500'],
              fontSize: '1rem',
              fontWeight: 700,
              letterSpacing: 0,
              '&.Mui-selected': {
                color: themeWithOnlyPalette.palette.secondary.main,
              }
            }
          }
        }
      },
      MuiTable: {
        styleOverrides: {
          root: {
            [`.${tableBodyClasses.root}`]: {
              [`> .${tableRowClasses.root}`]: {
                '&:nth-of-type(odd)': {
                  backgroundColor: themeWithOnlyPalette.palette.info.light,
                },
                ':hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
                },
                [`.${tableCellClasses.root}`]: {
                  [`.${typographyClasses.root}`]: {
                    fontSize: '14px !important',
                    fontWeight: '400'
                  }
                }
              }
            },
            [`.${tableHeadClasses.root}`]: {
              [`.${tableCellClasses.root}`]: {
                ['.MuiBox-root']: {
                  fontSize: '14px !important',
                  fontWeight: '500 !important'
                }
              }
            },
          }
        }
      }
    },
  },
  itIT
);
