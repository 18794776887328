import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'
import { string, func, bool, object, arrayOf, shape } from 'prop-types'
import { BaseContenutoConProgress } from 'feedback'
import { BaseBottoneTornaIndietro, BasePaginaErrore } from 'navigation'
import { BaseTesto } from 'text'
import { getPropertyWithPath, nonInProduzione } from 'utils'
import ElenchiManager, { ELENCHI_PROP_TYPE } from '../../form/ElenchiManager'
import { useRoutes } from '../../navigation/RoutesProvider'
import useRichiestaServer from '../../network/RichiestaServerHook'
import { EntitaProvider } from './components/EntitaContext'
import ErroreSalvataggio from './components/ErroreSalvataggio'
import LayoutEntita from './layout/LayoutEntita'
import useAlberoEntita from './logica/LogicaAlberoEntita'
import useModificaEntita from './logica/LogicaModificaEntita'
import usePermessiEntita from './logica/LogicaPermessiEntita'
import useValidazioneEntita from './logica/LogicaValidazioneEntita'

PannelloModificaEntita.propTypes = {
  // Nome dell'entità da chiedere al server
  nomeEntita: string.isRequired,
  // Funzione che renderizza il titolo del pannello
  // Riceve l'entità e restituisce un nodo React
  creaTitolo: func.isRequired,
  // Funzione che genera l'albero (vedi LogicaAlberoEntita)
  // Riceve l'entità e restituisce l'albero
  creaAlbero: func.isRequired,
  // Funzione usata per generare delle liste di options customizzate, 
  // usate di solito nelle form dei parametri dei documenti
  // Riceve un oggetto { chiave, nomeEntita } e restituisce le options [{ value, label }]
  calcolaOptions: func,
  // Oggetto di contesto da passare alle funzioni di validazione dell'entità
  contestoValidazione: object,
  // Flag che indica se l'utente ha dei privilegi elevati. Un utente staff
  // li ha sempre. Di solito usato per dei gruppi particolari. Se = true:
  // - nell'elenco delle operazioni svolte, vede i nomi di tutti
  // - nella modifica dei permessi dell'entità, può scegliere tra
  //   tutti i gruppi e non solo tra quelli a cui appartiene
  abilitaModificaPermessiCompleta: bool,
  // Funzione custom che dice se il pannello deve essere in sola lettura
  // Riceve l'entità e restituisce un booleano
  condizioneReadOnly: func,
  // Componente custom per renderizzare le label delle voci del menu (vedi VoceMenuLaterale)
  LabelVoceMenu: func,
  // Flag che indica se mostrare molte info di debug, sia nella ui che nella console
  mostraInfoDebug: bool,
  // Flag che indica se disabilitare il salvataggio automatico delle modifiche
  // Di default è true in sviluppo e false in produzione
  disabilitaSalvataggioAutomatico: bool,
  // Di solito l'uuid dell'entità viene preso dall'url, ma può essere anche passato
  uuid: string,
  // Funzione che trasforma l'entità appena arriva dal server
  trasformaEntita: func,
  // Chiave della rotta a cui l'utente può tornare se l'entità non esiste
  chiaveRottaTornaIndietro: string,
  // Elenchi da richiedere per poter renderizzare l'entità correttamente
  elenchiNecessari: ELENCHI_PROP_TYPE,
  // Path dello stato all'interno dell'entità. Se passato, viene usato per:
  // - mostrare la label dello stato vicino al titolo
  // - mostrare l'intero pannello in sola lettura se lo stato è annullato o eliminato
  //   (in ogni caso se arriva un'informazione più precisa dal server, vince quella)
  pathStato: string,
  // Lista di opzioni per il bottone di creazione rapida
  opzioniCreazioneRapida: arrayOf(shape({
    path: string.isRequired,
    label: string.isRequired
  }))
}

export default function PannelloModificaEntita(props) {
  const {
    nomeEntita,
    uuid: uuid_Prop,
    trasformaEntita = entita => entita,
    chiaveRottaTornaIndietro,
    elenchiNecessari = [],
    pathStato
  } = props

  const [entita, setEntita] = useState(null)
  const [readOnly_DalServer, setReadOnly_DalServer] = useState(false)
  const [erroreSalvataggio, setErroreSalvataggio] = useState(null)
  const [keyRefresh, setKeyRefresh] = useState(Date.now())

  const { uuid: uuid_Param } = useParams()
  const uuid = uuid_Prop || uuid_Param

  const {
    ScatolaMessaggi,
    fetchEntity,
    inAttesaDelServer
  } = useRichiestaServer()
  
  const fetchDatiEntita = useCallback(async (forzaKeyRefresh = false) => {
    if (nomeEntita && uuid) {
      const { ok, data } = await fetchEntity(nomeEntita, uuid)
      if (ok) {
        setEntita(trasformaEntita(data))
        if (data.readOnly === true) setReadOnly_DalServer(true)
        setErroreSalvataggio(false)
        if (forzaKeyRefresh) setKeyRefresh(new Date())
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nomeEntita, uuid])

  useEffect(fetchDatiEntita, [fetchDatiEntita])

  const { getPath } = useRoutes()

  return (
    <>
      {ScatolaMessaggi}

      <ElenchiManager
        elenchi={elenchiNecessari}
        render={el =>
          <BaseContenutoConProgress inAttesa={inAttesaDelServer}>
            {entita ? (
              <PannelloConEntitaPresente
                {...props}
                entita={entita}
                setEntita={setEntita}
                readOnly_DalServer={readOnly_DalServer}
                uuid={uuid}
                elenchi={el}
                entitaRicaricataDaZero={!erroreSalvataggio}
                setErroreSalvataggio={setErroreSalvataggio}
                stato={pathStato && getPropertyWithPath(pathStato, entita)}
                forzaFetchEntita={() => fetchDatiEntita(true)}
                key={keyRefresh}
              />
            ) : (
              <>
                <BaseTesto>I dati richiesti non sono stati trovati.</BaseTesto>
                <BaseBottoneTornaIndietro
                  {...(chiaveRottaTornaIndietro && {
                    rotta: getPath(chiaveRottaTornaIndietro)
                  })}
                />
              </>
            )}
          </BaseContenutoConProgress>
        }
      />

      <ErroreSalvataggio
        erroreSalvataggio={erroreSalvataggio}
        forzaFetchEntita={() => fetchDatiEntita(true)}
      />
    </>
  )
}



function PannelloConEntitaPresente(props) {
  const {
    // Props passate direttamente dall'esterno
    nomeEntita,
    creaTitolo,
    creaAlbero,
    calcolaOptions,
    contestoValidazione,
    abilitaModificaPermessiCompleta,
    condizioneReadOnly,
    LabelVoceMenu,
    opzioniCreazioneRapida,
    mostraInfoDebug: mostraInfoDebug_Prop = nonInProduzione(),
    disabilitaSalvataggioAutomatico: disabilitaSalvataggioAutomatico_Prop = nonInProduzione(),

    // Props preparate dal componente qui sopra
    entita,
    setEntita,
    readOnly_DalServer,
    uuid,
    elenchi,
    entitaRicaricataDaZero,
    setErroreSalvataggio,
    stato,
    forzaFetchEntita
  } = props

  const [mostraInfoDebug, setMostraInfoDebug] = useState(mostraInfoDebug_Prop)

  const [
    disabilitaSalvataggioAutomatico, setDisabilitaSalvataggioAutomatico
  ] = useState(disabilitaSalvataggioAutomatico_Prop)

  // Ogni volta che invio una modifica al server devo aggiornare la revisione
  // Per migliorare le performance, tengo uno stato separato per la revisione
  // così posso aggiornarla indipendentemente dall'entità (cambiare l'entità 
  // implica ricalcolare albero, validazione, e altra roba pesante)
  const [revisione, setRevisione] = useState(entita.entitaRev.rev)

  // Se il flag entitaRicaricataDaZero cambia e diventa true,
  // riallineo lo stato della revisione con la revisione nell'entità
  // Utile ad esempio quando si ricarica a seguito di un conflitto
  useEffect(() => {
    if (entitaRicaricataDaZero) setRevisione(entita.entitaRev.rev)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entitaRicaricataDaZero])

  const risolviRif = useCallback(oggettoRif => {
    if (!oggettoRif || !oggettoRif.path) return null
    return getPropertyWithPath(oggettoRif.path, entita)
  }, [entita])

  const {
    albero,
    alberoPiatto,
    funzioniAlbero
  } = useAlberoEntita({
    creaAlbero,
    entita,
    mostraInfoDebug,
    elenchi,
    risolviRif
  })

  const infoEntitaPrincipale = {
    nome: nomeEntita,
    uuid,
    revisione,
    isEntitaAppenaCreata: revisione === 0,
    entita
  }

  const gestionePermessi = usePermessiEntita({
    entita,
    abilitaModificaPermessiCompleta,
    readOnly_DalServer,
    condizioneReadOnly,
    stato
  })

  const gestioneModifica = useModificaEntita({
    setEntita,
    infoEntitaPrincipale,
    setRevisione,
    mostraInfoDebug,
    readOnly: gestionePermessi.readOnly,
    setErroreSalvataggio
  })

  const gestioneValidazione = useValidazioneEntita({
    albero,
    entita,
    contesto: contestoValidazione,
    mostraInfoDebug,
    risolviRif
  })

  // Memoizzo calcolaOptions per ottimizzare i componenti sotto che dipendono da lei
  const calcolaOptions_OggettoMemoizzato = useMemo(() => {
    return calcolaOptions && {
      calcolaOptions: argomenti => calcolaOptions(argomenti, funzioniAlbero, elenchi)
    }
  }, [calcolaOptions, funzioniAlbero, elenchi])

  const propsInterne = {
    urlBase: useRouteMatch().url,
    funzioniAlbero,
    infoEntitaPrincipale,
    gestioneModifica: {
      mostraInfoDebug,
      setMostraInfoDebug,
      disabilitaSalvataggioAutomatico,
      setDisabilitaSalvataggioAutomatico,
      forzaFetchEntita,
      ...gestioneModifica
    },
    gestioneValidazione,
    gestionePermessi,
    ...calcolaOptions_OggettoMemoizzato,
    elenchi,
    risolviRif
  }

  if (gestionePermessi.userNonPuoVedereEntita) {
    return (
      <BasePaginaErrore
        titolo='Accesso non consentito'
        sottotitolo='Non si hanno i permessi per visualizzare questo contenuto.'
      />
    )
  }

  return (
    <EntitaProvider propsInterne={propsInterne}>
      <LayoutEntita
        titolo={creaTitolo(entita)}
        stato={stato}
        albero={albero}
        alberoPiatto={alberoPiatto}
        LabelVoceMenu={LabelVoceMenu}
        opzioniCreazioneRapida={opzioniCreazioneRapida}
      />
    </EntitaProvider>
  )
}