import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { any, bool, func, node, objectOf, shape, string, object } from 'prop-types'
import { filterObject, nonInProduzione, usePromiseSession } from 'utils'
import useRichiestaServer from '../network/RichiestaServerHook'
import { useUser } from '../userManagement/userProvider/UserProvider'
import { ELENCHI_BASE_CONFIG } from './Elenchi'
import { elencoPromessaStandard } from './utilsElenchi'

ElenchiProvider.propTypes = {
  children: node,
  ELENCHI_CONFIG: objectOf(shape({
    // Dati schiantati che vengono ritornati senza nessuna promessa
    default: any,

    // Invia operazione OPERAZIONI_BASE.DIZIONARIO_GET
    dizionario: string, // Nome dizionario. Se non presente viene usata la chiave
    params: object, // Parametri aggiuntivi

    // Invia operazione generica 
    operationId: string, // default = OPERAZIONI_BASE.DIZIONARIO_GET
    operationData: object,
    operationOptions: object, // Usabile anche con OPERAZIONI_BASE.DIZIONARIO_GET

    // Trasformazione applicata sui dati restituiti dall'operazione
    // Usabile anche con OPERAZIONI_BASE.DIZIONARIO_GET
    trasformaElenco: func,

    // Promessa generica per ottenere l'elenco
    // Gli viene passata la configurazione dell'elenco e la funzione inviaOperazione
    promise: func,

    // Altre configurazioni non relative all'ottenimento dei dati
    legatoAlComando: bool, // Se = true, i dati vengono cancellati al cambio comando
  }))
}

const ContextElenchi = createContext()

function ElenchiProvider(props) {
  const {
    children,
    ELENCHI_CONFIG: ELENCHI_ESTERNI_CONFIG
  } = props

  /*** Unione config base con config esterna ***/

  const ELENCHI_CONFIG = useMemo(() => {
    // In sviluppo lancio un errore se un elenco esterno sovrascrive un elenco base
    if (nonInProduzione() && ELENCHI_ESTERNI_CONFIG) {
      Object.keys(ELENCHI_ESTERNI_CONFIG).forEach(chiaveElencoEsterno => {
        if (ELENCHI_BASE_CONFIG[chiaveElencoEsterno]) {
          console.error(`ElenchiProvider: la chiave ${chiaveElencoEsterno} presente in ELENCHI_CONFIG sovrascrive una chiave omonima già presente in ELENCHI_BASE_CONFIG. Scegliere un'altra chiave per evitare brutti errori`)
        }
      })
    }
    return { ...ELENCHI_BASE_CONFIG, ...ELENCHI_ESTERNI_CONFIG }
  }, [ELENCHI_ESTERNI_CONFIG])

  /*********************************************/

  /***** Ottenimento e salvataggio elenchi *****/

  const [elenchiGiaPresenti, setElenchiGiaPresenti] = useState({})
  const [elenchiInErrore, setElenchiInErrore] = useState({})

  const { add, result } = usePromiseSession()
  const { inviaOperazione } = useRichiestaServer()

  // Aggiungi le promesse degli elenchi richiesti (se non sono già stati ottenuti)
  function ottieniElenchi(chiavi) {
    chiavi.forEach(chiave => {
      if (elenchiGiaPresenti[chiave]) return
      add({ promise: () => getElenco(chiave), key: chiave })
    })
  }

  // Imposta i risultati (dati e/o errori) delle promesse aggiunte
  useEffect(() => {
    if (!result) return
    setElenchiGiaPresenti(prevState => ({ ...prevState, ...result.data }))
    setElenchiInErrore(prevState => {
      // Tolgo dagli errori gli elenchi che sono stati ottenuti con successo
      const prevStatePulito = filterObject(prevState, ([chiave]) => !result.data[chiave])
      return { ...prevStatePulito, ...result.error }
    })
  }, [result])

  // Ottieni i dati di un elenco sulla base della sua configurazione
  async function getElenco(chiave) {
    // Ottieni la configurazione dell'elenco
    const configElenco = ELENCHI_CONFIG[chiave]
    if (!configElenco) {
      const errorMessage = `Chiave elenco ${chiave} non trovata nella configurazione`
      if (nonInProduzione()) console.error(errorMessage)
      return { ok: false, error: errorMessage }
    }
    const {
      default: defaultValue = null,
      promise: elencoPromessa = elencoPromessaStandard,
      ...restConfig
    } = configElenco

    // Se è presente un defaultValue, ritornalo
    if (defaultValue) return { ok: true, data: defaultValue }

    // Esegui la promessa per ottenere l'elenco
    const { ok, data, error } = await elencoPromessa({ chiave, inviaOperazione, ...restConfig })
    if (ok) return { ok: true, data }
    return { ok: false, error }
  }

  /*********************************************/

  /*** Funzioni per accedere a dati ed errori ***/

  function checkElenchiPresenti(chiavi) {
    return chiavi.every(chiave => Boolean(elenchiGiaPresenti[chiave]) || Boolean(elenchiInErrore[chiave]))
  }

  const getElencoEsistente = useCallback(chiave => {
    return elenchiGiaPresenti[chiave] ?? null
  }, [elenchiGiaPresenti])

  const getElencoEsistenteItem = useCallback((valoreProprieta, chiaveProprieta, chiaveElenco) => {
    const list = getElencoEsistente(chiaveElenco)
    return list?.find?.(elemento => elemento[chiaveProprieta] === valoreProprieta) ?? null
  }, [getElencoEsistente])

  function getErroriElenchi(chiavi) {
    return filterObject(elenchiInErrore, ([chiave]) => chiavi.includes(chiave))
  }

  /**********************************************/

  /*** Invalidazione elenchi salvati localmente ***/

  const { comandoScelto } = useUser()

  // Impostato con il timestamp attuale quando sono invalidati gli elenchi
  // Aiuta ElenchiManager a capire quando richiedere di nuovo gli elenchi
  const [refresh, setRefresh] = useState(Date.now())

  // Quando cambia il comando, invalida gli elenchi legati al comando
  useEffect(() => {
    invalidaElenchiConCondizione(chiave => ELENCHI_CONFIG[chiave].legatoAlComando)
  }, [comandoScelto, ELENCHI_CONFIG])

  function invalidaElenchi(chiaviElenchiDaInvalidare) {
    invalidaElenchiConCondizione(chiave => chiaviElenchiDaInvalidare.includes(chiave))
  }

  function invalidaElenchiConCondizione(condizione) {
    setElenchiGiaPresenti(elenchi =>
      filterObject(elenchi, ([chiave]) => !condizione(chiave))
    )
    setRefresh(Date.now())
  }

  /************************************************/

  const contextValue = {
    ottieniElenchi,
    checkElenchiPresenti,
    getElencoEsistente,
    getElencoEsistenteItem,
    getErroriElenchi,
    refresh,
    invalidaElenchi,
  }

  return (
    <ContextElenchi.Provider value={contextValue}>
      {children}
    </ContextElenchi.Provider>
  )
}



function useGestioneElenchi() {
  return useContext(ContextElenchi)
}

export {
  ElenchiProvider,
  useGestioneElenchi
}