import { addUuid } from 'utils'
import { CHIAVI_CONTESTO_VALIDAZIONE } from '../ContestoValidazione'
import { ENTITA_BASE } from '../EntitaBase'
import PersonaModifica from '../persona/PersonaModifica'
import creaAlberoPersonaCondizione from '../personaCondizione/AlberoPersonaCondizione'
import PersonaOAziendaModifica from '../personaOAzienda/PersonaOAziendaModifica'
import AssicurazioneModifica from './AssicurazioneModifica'
import CondizioniModifica from './CondizioniModifica'
import { validaVeicoloDatiGenerali } from './DatiGeneraliModifica'
import PasseggeriModifica from './PasseggeriModifica'
import PasseggeroModifica from './PasseggeroModifica'
import RevisioneModifica from './RevisioneModifica'
import { creaDescrizionePasseggero, creaDescrizioneProprietario, creaDescrizioneVeicolo } from './utilVeicolo'
import VeicoloModifica from './VeicoloModifica'

export const PROPRIETA_VEICOLO = {
  ASSICURAZIONE: 'assicurazione',
  PASSEGGERI: 'passeggeri',
  PROPRIETARIO: 'proprietario',
  REVISIONE: 'revisione',
  RIMORCHIO: 'rimorchio',
  SCHEDA_H: 'schedaH'
}

export const SEZIONI_VEICOLO = {
  ...PROPRIETA_VEICOLO
}

const VISIBILITA_DEFAULT = {
  [SEZIONI_VEICOLO.PROPRIETARIO]: true,
  [SEZIONI_VEICOLO.PASSEGGERI]: true,
  [SEZIONI_VEICOLO.ASSICURAZIONE]: true,
  [SEZIONI_VEICOLO.REVISIONE]: true,
  [SEZIONI_VEICOLO.SCHEDA_H]: false,
  [SEZIONI_VEICOLO.RIMORCHIO]: true
}

creaAlberoVeicolo.SEZIONI = SEZIONI_VEICOLO

export default function creaAlberoVeicolo(veicolo, opzioni = {}) {
  const { propsRadice, visibilita, isRimorchio } = opzioni
  const visibilitaDefinitiva = { ...VISIBILITA_DEFAULT, ...visibilita }

  return {
    path: addUuid(veicolo?.uuid),
    nomeEntita: ENTITA_BASE.VEICOLO,
    url: veicolo?.uuid,
    label: creaDescrizioneVeicolo(veicolo),
    Panel: veicolo && VeicoloModifica,
    SubPanel: VeicoloModifica,
    validate: validaVeicoloDatiGenerali,
    isFogliaMenu: true,
    ...propsRadice,
    propsPanelSottoAlbero: { veicolo },
    figli: veicolo && [
      {
        ...PersonaOAziendaModifica.defaultConfig,
        path: PROPRIETA_VEICOLO.PROPRIETARIO,
        url: SEZIONI_VEICOLO.PROPRIETARIO,
        label: creaDescrizioneProprietario(veicolo.proprietario),
        Panel: PersonaOAziendaModifica,
        SubPanel: PersonaOAziendaModifica,
        propsSubPanel: { testoBottoneCrea: 'Aggiungi proprietario' },
        contestoValidazioneSottoAlbero: {
          [CHIAVI_CONTESTO_VALIDAZIONE.OBBLIGO_DOCUMENTO]: false
        }
      },
      !isRimorchio && {
        path: PROPRIETA_VEICOLO.PASSEGGERI,
        nomeEntita: ENTITA_BASE.VEICOLO_PASSEGGERO,
        url: SEZIONI_VEICOLO.PASSEGGERI,
        label: 'Conducente - Passeggeri',
        SubPanel: PasseggeriModifica,
        propsSubPanel: {
          personaProprietario: veicolo?.proprietario?.persona
        },
        figli: veicolo.passeggeri.map(passeggero => ({
          path: addUuid(passeggero.uuid),
          nomeEntita: ENTITA_BASE.VEICOLO_PASSEGGERO,
          url: passeggero.uuid,
          label: creaDescrizionePasseggero(passeggero),
          Panel: PasseggeroModifica,
          figli: [
            {
              ...PersonaModifica.defaultConfig,
              Panel: PersonaModifica,
              SubPanel: PersonaModifica,
              propsPanel: { label: `Anagrafica ${passeggero.conducente ? 'conducente' : 'passeggero'}` },
              propsSubPanel: { noElimina: true }
            },
            creaAlberoPersonaCondizione(passeggero.condizione, {
              persona: passeggero.persona
            })
          ]
        }))
      },
      {
        path: PROPRIETA_VEICOLO.ASSICURAZIONE,
        nomeEntita: ENTITA_BASE.VEICOLO_ASSICURAZIONE,
        url: SEZIONI_VEICOLO.ASSICURAZIONE,
        label: 'Assicurazione',
        SubPanel: AssicurazioneModifica
      },
      {
        path: PROPRIETA_VEICOLO.REVISIONE,
        nomeEntita: ENTITA_BASE.VEICOLO_REVISIONE,
        url: SEZIONI_VEICOLO.REVISIONE,
        label: 'Revisione',
        SubPanel: RevisioneModifica
      },
      {
        path: PROPRIETA_VEICOLO.SCHEDA_H,
        nomeEntita: ENTITA_BASE.VEICOLO_SCHEDA_H,
        url: SEZIONI_VEICOLO.SCHEDA_H,
        label: 'Condizioni del veicolo',
        SubPanel: CondizioniModifica
      },
      !isRimorchio && creaAlberoVeicolo(veicolo.rimorchio, {
        isRimorchio: true,
        propsRadice: {
          path: PROPRIETA_VEICOLO.RIMORCHIO,
          url: SEZIONI_VEICOLO.RIMORCHIO,
          label: 'Rimorchio',
          propsSubPanel: { testoBottoneCrea: 'Aggiungi rimorchio' }
        }
      })
    ].filter(nodo => Boolean(nodo) && visibilitaDefinitiva[nodo.url] === true)
  }
}