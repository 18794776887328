import { arrayOf, shape, string, object, func } from 'prop-types'
import { TextField, Autocomplete } from '@mui/material'
import useInputGenerico from './hooks/InputGenericoHook'
import useControllerSeServe from './hooks/ControllerSeServeHook'

BaseAutocompletamentoLibero.propTypes = {
  options: arrayOf(shape({
    label: string.isRequired,
    value: string.isRequired
  })).isRequired,
  onOpzioneScelta: func,
  propsAutocomplete: object
}

export default function BaseAutocompletamentoLibero(props) {
  const {
    options,
    onOpzioneScelta,
    propsAutocomplete,
    ...restProps
  } = props

  const inputGenericoProps = useInputGenerico({
    componenteControllato: true,
    widthType: 'width',
    ...restProps
  })

  const {
    value,
    onBlur,
    onChange,
    ref,
    propsRimanenti
  } = useControllerSeServe(inputGenericoProps)

  return (
    <Autocomplete
      freeSolo
      options={options}
      value={value}
      onChange={(_, opzioneScelta, reason) => {
        // Come valore invio su sempre una stringa
        onChange(opzioneScelta?.value ?? '')
        if (reason === 'selectOption') {
          onOpzioneScelta?.(opzioneScelta)
        }
      }}
      disabled={propsRimanenti.disabled}
      autoHighlight
      {...propsAutocomplete}
      // Invio gli eventi di onChange anche quando l'utente scrive
      // nel campo, non solo quando viene selezionata una option 
      onInputChange={(event, inputValue, action) => {
        onChange(inputValue)
        propsAutocomplete?.onInputChange?.(event, inputValue, action)
      }}
      renderInput={params =>
        <TextField
          {...params}
          inputRef={ref}
          onBlur={onBlur}
          {...propsRimanenti}
        />
      }
    />
  )
}