
import { useTheme } from "@mui/material";

/**
 * @typedef {import("../../common/utils/hooks/useCurrentTheme").IUseCurrentTheme } IUseCurrentTheme 
 * @typedef {import("../../common/utils/hooks/useCurrentTheme").useCurrentTheme } useCurrentTheme 
 */



/**
 * Helper hook that expose methods to determine what theme is in use.
 * Returns also Theme
 * 
 * @type {useCurrentTheme}
 * @returns {IUseCurrentTheme}
 */
export default function useCurrentTheme() {

    const theme = useTheme()

    /**
     * Check if current theme is 'alternative'
     * 
     * @returns {boolean}
     */
    const isAlternativeTheme = () => {
        return theme.name === 'alternative'
    }

    /**
     * Check if current theme is 'default'
     * 
     * @returns {boolean}
     */
    const isDefaultTheme = () => {
        return theme.name === 'default'
    }

    return {
        theme,
        isAlternativeTheme,
        isDefaultTheme
    };
}