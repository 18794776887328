import { useEffect } from 'react'
import { string, bool, number, object, func } from 'prop-types'
import { Box } from '@mui/material'
import { BaseGridLayout, BaseScatolaConTitolo } from 'layout'
import { costruisciRotta, creaFunzioneUnica, useStateWithLabel } from 'utils'
import { sonoLaStessaEntita as sonoLaStessaEntita_Default } from '../../utils'
import BottoneCreaEntita from './BottoneCreaEntita'
import { PROP_TYPES_WRAPPER_FORM_MODIFICA } from './FormModificaEntita'
import ScatolaEntita from './ScatolaEntita'

ListaScatoleEntita.propTypes = {
  ...PROP_TYPES_WRAPPER_FORM_MODIFICA,
  label: string,

  // Tutte queste funzioni ricevono un'entità della lista e l'index
  // A parte renderEntita, le altre sono usate raramente quando le funzioni di default non vanno bene
  renderEntita: func.isRequired,
  calcolaPathEntita: func,
  calcolaNomeEntita: func,
  calcolaValoriInput: func,
  calcolaUrlModifica: func,
  calcolaPropsScatolaEntita: func,
  calcolaKey: func,

  // Props per creazione
  noCrea: bool,
  numeroMassimoEntita: number,
  testoBottoneCrea: string,
  renderModalCreazione: func, // Passata al BottoneCreaEntita
  propsBottoneCrea: object,
  renderAltriBottoniCrea: func, // Chiamata con le props comuni a tutti i bottoni di creazione
  sonoLaStessaEntita: func, // Riceve 2 entità e ritorna un booleano
  ultimaEntitaCreata: object

  // Tutte le altre props sono passate a ciascuna ScatolaEntita
}

export default function ListaScatoleEntita(props) {
  const {
    propsEntita: { pathEntita, nomeEntita, valoriInput, urlPannello }
  } = props

  const {
    label,

    renderEntita,
    calcolaPathEntita = ({ uuid }) => pathEntita.addUuid(uuid),
    calcolaNomeEntita = () => nomeEntita,
    calcolaValoriInput = entita => entita,
    calcolaUrlModifica = ({ uuid }) => costruisciRotta(urlPannello, uuid),
    calcolaPropsScatolaEntita = () => ({}),
    calcolaKey = (entita, index) => entita.uuid ?? index,

    noCrea,
    numeroMassimoEntita,
    testoBottoneCrea,
    renderModalCreazione,
    propsBottoneCrea = {},
    renderAltriBottoniCrea,
    sonoLaStessaEntita = sonoLaStessaEntita_Default,
    ultimaEntitaCreata: ultimaEntitaCreata_Prop,

    ...restProps
  } = props

  const [
    ultimaEntitaCreata, setUltimaEntitaCreata
  ] = useStateWithLabel(null, 'ultimaEntitaCreata')

  useEffect(() => {
    setUltimaEntitaCreata(ultimaEntitaCreata_Prop)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ultimaEntitaCreata_Prop])

  const { onCreazioneCompletata, ...restPropsBottoneCrea } = propsBottoneCrea

  const propsBottoneCrea_Interne = {
    nomeEntita,
    path: pathEntita,
    onCreazioneCompletata: creaFunzioneUnica(
      // Se viene passata una prop ultimaEntitaCreata, significa che 
      // il padre vuole gestire direttamente l'ultima entità creata 
      // Quindi disabilito la gestione interna in questo caso
      !ultimaEntitaCreata_Prop && setUltimaEntitaCreata,
      onCreazioneCompletata
    )
  }

  const numeroMassimoEntita_NonRaggiunto = typeof numeroMassimoEntita === 'number'
    ? valoriInput.length < numeroMassimoEntita
    : true
  const mostraBottoneCrea = !noCrea && numeroMassimoEntita_NonRaggiunto

  return (
    <BaseScatolaConTitolo titolo={label}>
      <BaseGridLayout vertical>
        {valoriInput.map((entita, index) =>
          <ScatolaEntita
            senzaScatola
            // Se non c'è sotto il bottone crea, tolgo il divider all'ultimo elemento
            conDivider={mostraBottoneCrea || index !== valoriInput.length - 1}
            noCrea
            {...restProps}
            {...calcolaPropsScatolaEntita(entita, index)}
            key={calcolaKey(entita, index)}
            isEntitaAppenaCreata={sonoLaStessaEntita(entita, ultimaEntitaCreata)}
            propsEntita={{
              pathEntita: calcolaPathEntita(entita, index),
              nomeEntita: calcolaNomeEntita(entita, index),
              valoriInput: calcolaValoriInput(entita, index),
              urlPannello: calcolaUrlModifica(entita, index)
            }}
          >
            {renderEntita(entita, index)}
          </ScatolaEntita>
        )}
      </BaseGridLayout>

      {mostraBottoneCrea &&
        <Box sx={{ mt: (valoriInput.length > 0) ? 2 : 0 }}>
          <BaseGridLayout>
            <BottoneCreaEntita
              testo={testoBottoneCrea}
              renderModalCreazione={renderModalCreazione}
              {...propsBottoneCrea_Interne}
              {...restPropsBottoneCrea}
            />

            {renderAltriBottoniCrea?.(propsBottoneCrea_Interne)}
          </BaseGridLayout>
        </Box>
      }
    </BaseScatolaConTitolo>
  )
}