import { Link as RouterLink } from 'react-router-dom'
import { Box } from '@mui/material'
import { ICONE } from 'icons'
import { useRoutes } from '../navigation/RoutesProvider'
import { useUser, useUserUpdate } from './userProvider/UserProvider'

export default function LinkHomeIcon(props) {
  const { sx, ...rest } = props

  const { routeHome, URL_SSO_LOGIN, URL_SSO } = useRoutes()
  const { ssoAbilitato } = useUserUpdate()
  const { comandoScelto } = useUser()

  const urlEsterno = ssoAbilitato ?
    URL_SSO
      ? `${URL_SSO}${comandoScelto ? '?cmd=' + comandoScelto : ''}`
      : URL_SSO_LOGIN // fallback se non viene passato URL_SSO
    : null

  return (
    <Box>
      <Box
        component={ssoAbilitato ? 'a' : RouterLink}
        href={urlEsterno}
        to={routeHome.pathAssoluto}
        sx={sx}
      >
        <ICONE.LOGO_INCLOUD {...rest} sx={{ verticalAlign: 'middle' }} />
      </Box>
    </Box>
  )
}
