import { arrayOf, shape, string, func, object } from 'prop-types'
import { FormHelperText, FormLabel, ToggleButton, ToggleButtonGroup } from '@mui/material'
import useInputGenerico from './hooks/InputGenericoHook'
import useControllerSeServe from './hooks/ControllerSeServeHook'

ButtonGroup.propTypes = {
  options: arrayOf(shape({
    // USARE STRINGHE COME VALUE
    value: string.isRequired,
    renderIcon: func.isRequired
  })).isRequired,
  size: string,
  orientation: string,
  toggleButtonGroupProps: object,
  ...useInputGenerico.propTypes
}

ButtonGroup.campoConOptions = true

export default function ButtonGroup(props) {
  const {
    label,
    options,
    size = 'medium',
    orientation = 'horizontal',
    toggleButtonGroupProps = {},
    ...restProps
  } = props

  const {
    error,
    warning,
    required,
    helperText,
    hidden,
    ...inputGenericoProps
  } = useInputGenerico({
    componenteControllato: true,
    underlyingComponent: 'button_group',
    ...restProps
  })

  const {
    value,
    onBlur,
    onChange,
    propsRimanenti
  } = useControllerSeServe(inputGenericoProps)

  return (
    <div style={{ ...(hidden && { display: 'none' }) }}>
      <FormLabel
        required={required}
        error={error}
        {...(warning && { sx: { color: 'warning.main' } })}
      >
        {label}
      </FormLabel>

      <ToggleButtonGroup
        value={value}
        onBlur={onBlur}
        exclusive
        onChange={(e, newValue) => { onChange(newValue) }}
        orientation={orientation}
        size={size}
        {...toggleButtonGroupProps}
      >
        {options.map(({ value: optionValue, renderIcon }) =>
          <ToggleButton
            key={optionValue}
            value={optionValue}
            {...propsRimanenti}
          >
            {renderIcon({
              ...propsRimanenti,
              value,
              optionValue,
              selected: value === optionValue
            })}
          </ToggleButton>
        )}
      </ToggleButtonGroup>

      {(helperText || error) &&
        <FormHelperText
          error={error}
          {...(warning && { sx: { color: 'warning.main' } })}
        >
          {helperText}
        </FormHelperText>
      }
    </div>
  )
}
