import { useFormContext } from 'react-hook-form'
import { BaseSelect, BaseTextInput, BaseDateInput, BaseCheckbox } from 'inputs'
import { BaseScatolaConTitolo, BaseGridLayout } from 'layout'
import { isTargaValida } from 'utils'
import ElenchiManager, { ELENCHI } from '../../form/ElenchiManager'
import FormModificaEntita, { PROP_TYPES_WRAPPER_FORM_MODIFICA } from '../entitaGenerica/components/FormModificaEntita'
import SceltaNazione from '../indirizzo/SceltaNazione'

const NOMI = {
  TARGA: 'targa',
  TARGA_PROVA: 'targaProva',
  TARGA_ASSENTE: 'targaAssente',
  NAZIONE: 'nazione',
  TIPO: 'tipo',
  COLORE: 'colore',
  TELAIO: 'telaio',
  DESTINAZIONE: 'destinazione',
  USO: 'uso',
  SPECIFICA: 'specifica',
  ALIMENTAZIONE: 'alimentazione',
  LATO_GUIDA: 'latoGuida',
  MCPC: 'mcpc',
  CARICO: 'carico',
  DATA_IMMATRICOLAZIONE: 'dataImmatricolazione',
  FABBRICA: 'veicoloNomi.fabbrica',
  VEICOLO_TIPO: 'veicoloNomi.tipo',
  SERIE: 'veicoloNomi.serie',
  EXTRA: 'veicoloNomi.extra',
}

DatiGeneraliModifica.propTypes = PROP_TYPES_WRAPPER_FORM_MODIFICA

export default function DatiGeneraliModifica(props) {
  const { propsEntita, label } = props

  return (
    <BaseScatolaConTitolo titolo={label}>
      <ElenchiManager
        elenchi={[
          ELENCHI.AUTOVETTURA_TIPO,
          ELENCHI.NAZIONI,
          ELENCHI.AUTOVETTURA_COLORE,
          ELENCHI.LATO_GUIDA,
          ELENCHI.ALIMENTAZIONE
        ]}
        render={el => (
          <FormModificaEntita {...propsEntita}>
            <FormDatiGenerali el={el} />
          </FormModificaEntita>
        )}
      />
    </BaseScatolaConTitolo>
  )
}



function FormDatiGenerali(props) {
  const { el } = props

  const { watch } = useFormContext()
  const [targa, targaAssente] = watch([NOMI.TARGA, NOMI.TARGA_ASSENTE])
  const isTargaVuota = (targa === '')

  return (
    <BaseGridLayout disposizioneRighe={[3, 3, 3, 3, 2, 2]} alignCenter>
      <BaseTextInput label='Targa' name={NOMI.TARGA} autoFocus={isTargaVuota} hidden={targaAssente} />
      <BaseCheckbox label='Targa Prova' name={NOMI.TARGA_PROVA} />
      <BaseCheckbox label='Targa Assente' name={NOMI.TARGA_ASSENTE} />

      <BaseSelect pair label='Tipo' name={NOMI.TIPO} options={el(ELENCHI.AUTOVETTURA_TIPO)} InputLabelProps={{ shrink: true }} />
      <BaseTextInput label='Marca' name={NOMI.FABBRICA} />
      <BaseTextInput label='Modello' name={NOMI.VEICOLO_TIPO} />

      <SceltaNazione label='Nazione' name={NOMI.NAZIONE} options={el(ELENCHI.NAZIONI)} pair />
      <BaseDateInput label='Data prima immatricolaz.' name={NOMI.DATA_IMMATRICOLAZIONE} disableFuture />
      <BaseTextInput label='Numero di telaio' name={NOMI.TELAIO} />

      <BaseSelect pair label='Colore' name={NOMI.COLORE} options={el(ELENCHI.AUTOVETTURA_COLORE)} />
      <BaseTextInput label='Destinazione' name={NOMI.DESTINAZIONE} />
      <BaseTextInput label='Uso' name={NOMI.USO} />

      <BaseTextInput label='Carico' name={NOMI.CARICO} />
      <BaseTextInput label='Massa compl. pieno carico' name={NOMI.MCPC} />

      <BaseSelect pair label='Lato guida' name={NOMI.LATO_GUIDA} options={el(ELENCHI.LATO_GUIDA)} />
      <BaseSelect pair label='Alimentazione' name={NOMI.ALIMENTAZIONE} options={el(ELENCHI.ALIMENTAZIONE)} />
    </BaseGridLayout>
  )
}



export function validaVeicoloDatiGenerali(utilValidazione) {
  const { valoriInput, warn, required } = utilValidazione
  const { targa, targaAssente } = valoriInput

  if (!targaAssente) {
    required('Targa non inserita', NOMI.TARGA)
  }
  required('Marca non inserita', NOMI.FABBRICA)

  if (!isTargaValida(targa)) {
    warn('Targa non valida', NOMI.TARGA)
  }
}