import { forwardRef } from 'react'
import { node, oneOf, bool } from 'prop-types'
import { Typography } from '@mui/material'
import { sx } from 'utils'

const propTypes = {
  children: node,
  textAlign: oneOf(['inherit', 'left', 'center', 'right']),
  piccolo: bool,
  inline: bool
}

BaseTesto.propTypes = propTypes

function BaseTesto(props) {
  const {
    children,
    textAlign = 'inherit',
    piccolo,
    inline,
    ...restProps
  } = props

  return (
    <Typography
      variant='body1'
      {...(inline && { component: 'span' })}
      {...restProps}
      {...sx(restProps, {
        textAlign,
        ...(piccolo && { fontSize: '0.9em' })
      })}
    >
      {children}
    </Typography>
  )
}



// Il forward ref serve in casi molto rari dove serve un tooltip (o cose simili)
// Aggiungere il forward ref è quasi sempre un overhead inutile, quindi 
// ho creato un componente uguale a BaseTesto con l'aggiunta del ref
const BaseTestoConRef = forwardRef(function BaseTestoConRef(props, ref) {
  const {
    children,
    textAlign = 'inherit',
    piccolo,
    inline,
    ...restProps
  } = props

  return (
    <Typography
      ref={ref}
      variant='body1'
      {...(inline && { component: 'span' })}
      {...restProps}
      {...sx(restProps, {
        textAlign,
        ...(piccolo && { fontSize: '0.9em' })
      })}
    >
      {children}
    </Typography>
  )
})

BaseTestoConRef.propTypes = propTypes



export {
  BaseTesto,
  BaseTestoConRef
}