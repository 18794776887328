import { useMemo } from 'react'
import { arrayOf, string, bool } from 'prop-types'
import { BaseSelect, BaseSelectMultipla } from 'inputs'
import LabelStatoCustom, { PROP_TYPE_STATO_CUSTOM } from './LabelStatoCustom'

SceltaStatoCustom.propTypes = {
  optionsStatiCustom: arrayOf(PROP_TYPE_STATO_CUSTOM).isRequired,
  nomeEntita: string.isRequired,
  sceltaMultipla: bool
}

const getOptionValue = option => option ? String(option.id) : '-1'
const transformOutput = (_, originalOption) => originalOption

export default function SceltaStatoCustom(props) {
  const { 
    optionsStatiCustom,
    nomeEntita,
    sceltaMultipla,
    ...restProps
  } = props

  const optionsDefinitive = useMemo(() => {
    if (sceltaMultipla) return optionsStatiCustom
    return [
      {
        id: -1,
        nome: '',
        descr: '',
        colore: '',
        attivo: true,
        entity: nomeEntita
      },
      ...optionsStatiCustom
    ]
  }, [sceltaMultipla, optionsStatiCustom, nomeEntita])

  const Select = sceltaMultipla ? BaseSelectMultipla : BaseSelect

  return (
    <Select
      options={optionsDefinitive}
      getOptionValue={getOptionValue}
      transformInput={getOptionValue}
      transformOutput={transformOutput}
      renderOption={({ originalOption }) =>
        <LabelStatoCustom
          statoCustom={originalOption}
          visibilityHiddenSeMancaDescrizione
        />
      }
      // Mostra l'opzione vuota con un'altezza simile alle altre opzioni
      calcolaPropsMenuItem={({ originalOption }) =>
        !originalOption.descr && { sx: { height: '2rem' } }
      }
      {...(sceltaMultipla && { noVirgolaTraOpzioniSelezionate: true })}
      {...restProps}
    />
  )
}