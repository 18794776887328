import { useMemo } from 'react'
import { string, func } from 'prop-types'
import BaseSelect from './BaseSelect'
import useControllerSeServe from './hooks/ControllerSeServeHook'
import useInputGenerico from './hooks/InputGenericoHook'

BaseSelectBoolean.propTypes = {
  labelFalse: string,
  labelTrue: string,
  renderFalse: func,
  renderTrue: func,
  ...useInputGenerico.propTypes
}

export default function BaseSelectBoolean(props) {
  const {
    labelFalse = 'No',
    labelTrue = 'Si',
    renderFalse,
    renderTrue,
    ...restProps
  } = props

  const options = useMemo(() => ([
    { value: 'false', label: labelFalse },
    { value: 'true', label: labelTrue }
  ]), [labelFalse, labelTrue])

  const inputGenericoProps = useInputGenerico({
    componenteControllato: true,
    ...restProps
  })

  const {
    value: valueBoolean,
    onBlur,
    onChange,
    propsRimanenti
  } = useControllerSeServe(inputGenericoProps)

  function onChangeConConversioneInBoolean(e) {
    const nuovoValore = e.target.value
    onChange(nuovoValore === 'true' ? true : false)
  }

  return (
    <BaseSelect
      nonUsareHookForm
      options={options}
      value={valueBoolean ? 'true' : 'false'}
      onBlur={onBlur}
      onChange={onChangeConConversioneInBoolean}
      {...(renderFalse && renderTrue && {
        renderOption: ({ value }) => value === 'true' ? renderTrue() : renderFalse()
      })}
      {...propsRimanenti}
    />
  )
}