import { node, bool, func, object, number } from 'prop-types'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { BaseIconButtons } from 'inputs'
import { useStateWithLabel, useScrollaGiuSeServe, sx } from 'utils'
import BaseContenitoreContrasto from './BaseContenitoreContrasto'
import BaseDivider from './BaseDivider'

BaseAccordion.propTypes = {
  contenutoNonEspanso: node.isRequired,
  contenutoEspanso: node.isRequired,
  contenutoEspansoHeader: node,
  contenutoExtraInFondo: node,
  senzaContenitore: bool,
  espanso: bool,
  onChangeEspansione: func,
  bloccaEspansione: bool,
  TransitionProps: object,
  sx: object,
  disabilitaScrollGiuAutomatico: bool,
  accordionSummaryProps: object,
  accordionDetailsProps: object,
  elevation: number,
}

export default function BaseAccordion(props) {
  const {
    contenutoNonEspanso,
    contenutoEspanso,
    contenutoEspansoHeader,
    contenutoExtraInFondo,
    senzaContenitore,
    espanso: espanso_Passato,
    onChangeEspansione,
    bloccaEspansione,
    TransitionProps,
    disabilitaScrollGiuAutomatico,
    accordionSummaryProps,
    accordionDetailsProps,
    ...restProps
  } = props

  const [espanso, setEspanso] = useStateWithLabel(espanso_Passato ?? false, 'espanso')
  const espanso_Definitivo = espanso_Passato ?? espanso

  const {
    refElementoDaScrollare,
    scrollaGiuSeServe,
    scrollReset
  } = useScrollaGiuSeServe(disabilitaScrollGiuAutomatico)

  function onChangeStatoEspansione(_, nuovoStatoEspansione) {
    if (bloccaEspansione) return
    setEspanso(nuovoStatoEspansione)
    onChangeEspansione?.(nuovoStatoEspansione)
  }

  const accordion = (
    <Accordion
      expanded={espanso_Definitivo}
      onChange={onChangeStatoEspansione}
      elevation={3}
      ref={refElementoDaScrollare}
      TransitionProps={{
        onEntered: scrollaGiuSeServe,
        onExited: scrollReset,
        ...TransitionProps
      }}
      {...restProps}
    >
      <AccordionSummary
        expandIcon={!bloccaEspansione &&
          <BaseIconButtons.EspandiPiu
            contenutoTooltip={espanso_Definitivo ? 'Minimizza' : 'Espandi'}
            iconProps={{ fontSize: 'large' }}
          />
        }
        {...accordionSummaryProps}
        {...sx(accordionSummaryProps, {
          cursor: bloccaEspansione ? 'inherit !important' : 'cursor',
          '& .MuiAccordionSummary-content': { m: '0 !important' }
        })}
      >
        {(contenutoEspansoHeader && espanso_Definitivo)
          ? contenutoEspansoHeader
          : contenutoNonEspanso
        }
      </AccordionSummary>

      <AccordionDetails
        {...accordionDetailsProps}
        {...sx(accordionDetailsProps, { px: 4, pt: 0, pb: 4 })}
      >
        {contenutoEspanso}

        {contenutoExtraInFondo &&
          <BaseDivider sx={{ mt: 2, mb: 1 }} />
        }
        {contenutoExtraInFondo}
      </AccordionDetails>
    </Accordion>
  )

  if (senzaContenitore) return accordion

  return <BaseContenitoreContrasto>{accordion}</BaseContenitoreContrasto>
}