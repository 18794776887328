import { BaseContenutoConProgress } from 'feedback'
import { useUserUpdate } from './userProvider/UserProvider'

export default function FeedbackRichiestaPermessiUser(props) {
  const { children } = props

  const {
    ScatolaMessaggi_RichiestaPermessiUser,
    inAttesa_RichiestaPermessiUser = false
  } = useUserUpdate()

  return (
    <>
      {ScatolaMessaggi_RichiestaPermessiUser}

      <BaseContenutoConProgress inAttesa={inAttesa_RichiestaPermessiUser}>
        {children}
      </BaseContenutoConProgress>
    </>
  )
}
