import { BaseSelect, BaseTextInput } from 'inputs'
import { BaseGridLayout } from 'layout'
import ElenchiManager, { ELENCHI } from '../../form/ElenchiManager'
import FormModificaEntita, { PROP_TYPES_WRAPPER_FORM_MODIFICA } from '../entitaGenerica/components/FormModificaEntita'
import ScatolaEntita from '../entitaGenerica/components/ScatolaEntita'

const NOMI = {
  KM_PERCORSI: 'kmPercorsi',
  MARCIA_INSERITA: 'marciaInserita',
  ANOMALIE: 'anomalie',
  CLACSON: 'statoVeicolo.clacson_verificato',
  CRONOTACHIGRAFO: 'statoVeicolo.cronotachigrafo',
  CARRELLO_APPENDICE: 'statoVeicolo.carrello_appendice',
  GANCIO_TRAINO: 'statoVeicolo.gancio_traino',
  ATTIVAZIONE_PRETENSIONAMENTO: 'statoVeicolo.attivazione_pretensionamento',
  ATTIVAZIONE_AIRBAG: 'statoVeicolo.attivazione_airbag',
  RETROVISORE_INTERNO: 'statoVeicolo.retrovisore_interno',
  RETROVISORE_ESTERNO: 'statoVeicolo.retrovisore_esterno',
  ABS: 'statoVeicolo.abs',
  FRENI_VERIFICATI: 'statoVeicolo.freni_verificati',
  LUCI_FUNZIONANTI: 'statoVeicolo.luci_funzionanti',
  PNEUM_REGOLARI: 'statoVeicolo.pneum_regolari'
}

CondizioniModifica.propTypes = PROP_TYPES_WRAPPER_FORM_MODIFICA

const NON_VERIFICATO = 'non verificato o non funzionante'

export default function CondizioniModifica(props) {
  const { propsEntita } = props

  return (
    <ScatolaEntita 
      testoBottoneCrea='Aggiungi condizioni'
      noElimina noModifica
      propsBottoneCrea={{
        opzioniCreazione: {
          entitaDaFondere: {
            statoVeicolo: {
              clacson_verificato: NON_VERIFICATO,
              cronotachigrafo: NON_VERIFICATO,
              carrello_appendice: NON_VERIFICATO,
              gancio_traino: NON_VERIFICATO,
              attivazione_pretensionamento: NON_VERIFICATO,
              attivazione_airbag: NON_VERIFICATO,
              retrovisore_interno: NON_VERIFICATO,
              retrovisore_esterno: NON_VERIFICATO,
              abs: NON_VERIFICATO,
              freni_verificati: NON_VERIFICATO,
              luci_funzionanti: NON_VERIFICATO,
              pneum_regolari: NON_VERIFICATO
            }
          }
        }
      }}
      {...props}
    >
      <ElenchiManager
        elenchi={[ELENCHI.RAPPORTO_CAMBIO, ELENCHI.STATO_VEICOLO]}
        render={el => {
          const propsComuni = { options: el(ELENCHI.STATO_VEICOLO) }
          return (
            <FormModificaEntita {...propsEntita}>
              <BaseGridLayout disposizioneRighe={[2, 3, 3, 3, 3, 1]}>
                <BaseTextInput
                  label='Km del veicolo' name={NOMI.KM_PERCORSI}
                  larghezza={17.5}
                />
                <BaseSelect
                  label='Rapporto al cambio' name={NOMI.MARCIA_INSERITA}
                  options={el(ELENCHI.RAPPORTO_CAMBIO)} pair
                  larghezza={17.5}
                />

                <BaseSelect
                  label='Clacson' name={NOMI.CLACSON}
                  {...propsComuni}
                />
                <BaseSelect
                  label='Cronotachigrafo' name={NOMI.CRONOTACHIGRAFO}
                  {...propsComuni}
                />
                <BaseSelect
                  label='Carrello appendice' name={NOMI.CARRELLO_APPENDICE}
                  {...propsComuni}
                />

                <BaseSelect
                  label='Gancio di traino' name={NOMI.GANCIO_TRAINO}
                  {...propsComuni}
                />
                <BaseSelect
                  label='Pretens. attivato' name={NOMI.ATTIVAZIONE_PRETENSIONAMENTO}
                  {...propsComuni}
                />
                <BaseSelect
                  label='Airbag attivato' name={NOMI.ATTIVAZIONE_AIRBAG}
                  {...propsComuni}
                />

                <BaseSelect
                  label='Retrovisore interno' name={NOMI.RETROVISORE_INTERNO}
                  {...propsComuni}
                />
                <BaseSelect
                  label='Retrovisore esterno' name={NOMI.RETROVISORE_ESTERNO}
                  {...propsComuni}
                />
                <BaseSelect
                  label='ABS' name={NOMI.ABS}
                  {...propsComuni}
                />

                <BaseSelect
                  label='Freni verificati' name={NOMI.FRENI_VERIFICATI}
                  {...propsComuni}
                />
                <BaseSelect
                  label='Luci verificate' name={NOMI.LUCI_FUNZIONANTI}
                  {...propsComuni}
                />
                <BaseSelect
                  label='Pneumatici regolari' name={NOMI.PNEUM_REGOLARI}
                  {...propsComuni}
                />

                <BaseTextInput
                  label='Anomalie rilevate' name={NOMI.ANOMALIE}
                  multiline rows={2} larghezza={36}
                />
              </BaseGridLayout>
            </FormModificaEntita>
          )
        }}
      />
    </ScatolaEntita>
  )
}