function getNameDaDescrizioneCampo(descrizioneCampo) {
  const { campo, custom, props } = descrizioneCampo
  if (custom) return custom.props.name
  return props?.name || campo.name
}

function getNomiCampi(listaNomiCampi_ChePotrebberoEssereOggetti) {
  return listaNomiCampi_ChePotrebberoEssereOggetti.map(
    campo => (typeof campo === 'string') ? campo : campo.name
  )
}

export {
  getNameDaDescrizioneCampo,
  getNomiCampi
}