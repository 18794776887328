import { useMemo, useEffect, useState } from 'react'
import { debounce } from 'lodash'

/**
 * @typedef {import("../../common/utils/hooks/useDebounceFilter").IUseDebounceFilter } IUseDebounceFilter 
 * @typedef {import("../../common/utils/hooks/useDebounceFilter").useDebounceFilter } useDebounceFilter 
 */

/**
 * Helper function to set a filter using a debounced function. 
 * The scope of this hook is optimize and handle
 * debounced search for filter-in-client.
 * 
 * If filter param in `searchHandler` change before `debounceTime` is go, older debounced execution is ended
 * and new debounced execution is started.
 * 
 * @example
 * ```js
 * import { useDebounceFilter } from 'hooks'
 * 
 * const {searchHandler, querySearch} = useDebounceFilter(200)
 * 
 * .... other parts of component
 * 
 * useEffect(() => {
 *  console.log(`My Query: ${querySearch} is changed in debounced way`)
 * }, [querySearch])
 * 
 * return (
 *  <input type="text" onChange={(e) => searchHandler(e.target.value)} />
 * )
 * 
 * ```
 * 
 * 
 * @param {number | undefined} debounceTime
 * @type {useDebounceFilter}
 * @returns {IUseDebounceFilter}
 */
export default function useDebounceFilter(debounceTime = 500) {
    const [querySearch, setQuerySearch] = useState('')

    const searchHandler = useMemo(() => {
        return debounce(setQuerySearch, debounceTime)
    }, [])

    // On unmount, clear pending debounced search
    useEffect(() => {
        return () => {
            searchHandler.cancel()
        }
    }, [])

    return {
        querySearch,
        searchHandler
    }
}