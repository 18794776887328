import { useEffect } from 'react'
import { getFromLocalStorage, calcolaDifferenzaInSecondi, useStateWithLabel, useIsFirstRender } from 'utils'
import { useContextGenerico } from '../../util/ContextGenericoProvider'

const MASSIMO_PERIODO_INATTIVITA_SECONDI_DEFAULT = 60 * 60 * 3.5 // 3,5 ore
const FREQUENZA_CONTROLLO_SCADENZA_SECONDI = 60 * 5 // 5 minuti

// Ogni volta che mando una richiesta al server, salvo nel local storage il timestamp
// Al primo render, ogni volta che la window riceve il focus ed anche ad intervalli regolari,
// controllo che dall'ultima richiesta non sia passato troppo tempo, ed in caso faccio il logout
export default function useScadenzaSessione(props) {
  const {
    isUserLoggato,
    massimoPeriodoInattivita_InSecondi = MASSIMO_PERIODO_INATTIVITA_SECONDI_DEFAULT
  } = props

  const [isSessioneScaduta, setIsSessioneScaduta] = useStateWithLabel(false, 'isSessioneScaduta')

  const {
    CHIAVI_STORAGE: { DATA_ULTIMA_RICHIESTA }
  } = useContextGenerico()

  const isFirstRender = useIsFirstRender()
  const isUserLoggato_Boolean = isUserLoggato()
  useEffect(() => {
    if (isUserLoggato_Boolean && massimoPeriodoInattivita_InSecondi !== undefined) {
      function controllaEImpostaSessioneScaduta() {
        setIsSessioneScaduta(
          controllaSeSessioneScaduta(massimoPeriodoInattivita_InSecondi, DATA_ULTIMA_RICHIESTA)
        )
      }

      if (isFirstRender) controllaEImpostaSessioneScaduta()
      window.addEventListener('focus', controllaEImpostaSessioneScaduta)
      const timerId = setInterval(
        controllaEImpostaSessioneScaduta,
        FREQUENZA_CONTROLLO_SCADENZA_SECONDI * 1000
      )

      return () => {
        window.removeEventListener('focus', controllaEImpostaSessioneScaduta)
        clearInterval(timerId)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserLoggato_Boolean, massimoPeriodoInattivita_InSecondi])

  return {
    isSessioneScaduta,
    setIsSessioneScaduta
  }
}

function controllaSeSessioneScaduta(massimoPeriodoInattivita_InSecondi, chiaveStorage_DataUltimaRichiest) {
  const dataUltimaRichiesta = getFromLocalStorage(chiaveStorage_DataUltimaRichiest)
  if (!dataUltimaRichiesta) return false
  
  const adesso = new Date()
  const dataUltimaRichiestaParsata = new Date(dataUltimaRichiesta)
  const periodoInattivita_InSecondi = calcolaDifferenzaInSecondi(adesso, dataUltimaRichiestaParsata)
  return periodoInattivita_InSecondi > massimoPeriodoInattivita_InSecondi
}