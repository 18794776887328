import { useCallback, useEffect, useRef, useState, useMemo } from 'react'
import { object, arrayOf, string, bool, node, func } from 'prop-types'
import { BaseContenutoConProgress, BaseToasts } from 'feedback'
import { FormPerUploadFile } from 'files'
import { ICONE } from 'icons'
import { BaseGridLayout } from 'layout'
import { BaseTesto } from 'text'
import { arricchisciPropOggetto, MIME_TYPES } from 'utils'
import useRichiestaServer from '../../network/RichiestaServerHook'
import { ENTITA_BASE } from '../EntitaBase'
import { PROP_TYPES_WRAPPER_FORM_MODIFICA } from '../entitaGenerica/components/FormModificaEntita'
import ListaScatoleEntita from '../entitaGenerica/components/ListaScatoleEntita'
import { trovaElementoConUUID } from '../utils'
import BottoneGeneraDocumento from './BottoneGeneraDocumento'
import { costruisciSequenzaDocumentiSostituiti, filtraDocumentiSostituiti, isTemplateHtmlForm, ORIGINE_PARAMETRO, trasformaAllegatoOutput, trasformaDocumentoInput, useFetchListaTemplate } from './utilDocumenti'
import HeaderDocumentoAllegato from './components/HeaderDocumentoAllegato'
import EliminaDocumento from './EliminaDocumento'
import MenuModificaDocumento from './MenuModificaDocumento'
import VersioniDocumento from './VersioniDocumento'

ListaDocumentiModifica.propTypes = {
  ...PROP_TYPES_WRAPPER_FORM_MODIFICA,

  // Permette di scegliere solo i template con questi ambiti
  ambitiTemplate: arrayOf(string),
  // Permette di scegliere solo i template con questi nomi
  nomiTemplate: arrayOf(string),
  // Permette di scegliere solo i template con nome diverso da questi
  nomiTemplateEsclusi: arrayOf(string),
  // Permette di scegliere solo i template con almeno uno di questi tags
  // L'utente può comunque decidere di disabilitare questo filtro
  tagsTemplate: arrayOf(string),
  labelModelliFiltratiPerTag: string,
  disabilitaCaricamentoDocumento: bool,
  disabilitaEliminazione: func, // riceve ogni documento e torna un booleano
  disabilitaGenerazioneDaModello: bool,
  // Valori dei parametri fissati dal contesto esterno,
  // che l'utente non può né vedere né modificare
  // IMPORTANTE: passare un oggetto opportunamente annidato
  // se i nomi dei campi lo richiedono (es. "attivita.path")
  parametriFissi: object,
  parametriFissiMetadati: object,
  // Valori di default dei parametri, passati dall'esterno
  parametriDefaultValues: object,
  parametriDefaultValuesMetadati: object,
  // Informazione opzionale per html form
  htmlFormPathSpecifico: string,

  maxWidthModalCaricamento: string, // sm, md, lg, xl
  propsModalMetadati: object,
  propsModalModifica: object,
  propsFormUpload: object,
  propsFormGenera: object,
  propsFormMetadati: object,
  propsListaScatoleEntita: object,
  aiuto: node,

  abilitaInformazioniAggiuntive: bool,
  abilitaContenutiMultimediali: bool,
  renderContenutoCustom_HeaderDocumento: func
}

ListaDocumentiModifica.defaultConfig = {
  path: 'documenti',
  nomeEntita: ENTITA_BASE.ALLEGATO_DOCUMENTO,
  url: 'documenti',
  label: 'Documenti'
}

export default function ListaDocumentiModifica(props) {
  const {
    propsEntita,
    titolo,
    label,
    isSubPanel,
    propsInterne_PannelloModificaEntita: {
      infoEntitaPrincipale,
      calcolaOptions,
      gestioneModifica: {
        modificaEntita,
        eliminaEntita,
        inviaModificheInCoda,
        isCodaVuota,
        feedbackModificaEntita: { invioModificheInCorso }
      },
      gestionePermessi: { readOnly },
      gestioneValidazione: { esisteAlmenoUnErrore },
      risolviRif
    },

    ambitiTemplate = [],
    nomiTemplate = [],
    nomiTemplateEsclusi = [],
    tagsTemplate = [],
    labelModelliFiltratiPerTag,
    disabilitaCaricamentoDocumento,
    disabilitaEliminazione = () => false,
    disabilitaGenerazioneDaModello,
    parametriFissi,
    parametriFissiMetadati,
    parametriDefaultValues,
    parametriDefaultValuesMetadati,
    htmlFormPathSpecifico,

    maxWidthModalCaricamento = 'sm',
    propsModalMetadati,
    propsModalModifica,
    propsFormUpload: propsFormUpload_PassateDaFuori,
    propsFormGenera: propsFormGenera_PassateDaFuori,
    propsFormMetadati: propsFormMetadati_PassateDaFuori,
    propsListaScatoleEntita,
    aiuto,

    abilitaInformazioniAggiuntive,
    abilitaContenutiMultimediali,
    renderContenutoCustom_HeaderDocumento
  } = props

  /********** Caricamento template disponibili **********/

  const {
    templateDisponibili,
    ScatolaMessaggi,
    inAttesaDelServer,
  } = useFetchListaTemplate({
    ambitiTemplate,
    nomiTemplate,
    nomiTemplateEsclusi
  })

  const trovaTemplateConNome = useCallback(nome => {
    return trovaElementoConUUID(templateDisponibili, nome, 'nome')
  }, [templateDisponibili])

  /******************************************************/

  /***** Preparazione props dei componenti interni *****/

  const propsFormUpload = {
    useGestioneFile: useRichiestaServer,
    readOnly,
    ...propsFormUpload_PassateDaFuori,
    ...arricchisciPropOggetto(propsFormUpload_PassateDaFuori, 'propsFileInput', {
      dimensioneMaxInMegabyte: 10,
      tipiFileAccettati: [MIME_TYPES.PDF],
    })
  }

  const toastDocumentoGeneratoRef = useRef()
  const propsFormGenera = {
    infoEntitaPrincipale,
    inviaModificheInCoda,
    tuttoSalvato: !invioModificheInCorso && isCodaVuota(),
    calcolaOptions,
    toastDocumentoGeneratoRef,
    parametriFissi,
    parametriDefaultValues,
    readOnly,
    htmlFormPathSpecifico,
    ...propsFormGenera_PassateDaFuori
  }

  const propsFormMetadati = {
    calcolaOptions,
    parametriFissi: parametriFissiMetadati,
    parametriDefaultValues: parametriDefaultValuesMetadati,
    readOnly,
    ...propsFormMetadati_PassateDaFuori
  }

  const ciSonoErroriValidazione = esisteAlmenoUnErrore()

  /*****************************************************/

  /*** Gestione documenti sostituiti (da nascondere) ***/

  const propsEntita_SenzaDocumentiSostituiti = useMemo(() => {
    return {
      ...propsEntita,
      valoriInput: filtraDocumentiSostituiti(propsEntita.valoriInput)
    }
  }, [propsEntita])

  /*
    Per caricare un documento che sostituisce un altro, serve premere
    dal codice il bottone "Carica documento". Una volta confermata la
    creazione, bisogna inserire nel nuovo documento il riferimento
    al documento sostituito. Ora funziona così:
    - attacco al bottone un ref per poterlo cliccare dal codice
    - aggiungo su ogni documento un'azione che imposta lo stato
      infoDocumentoSostituisce. Al successivo render scatta lo
      useEffect che clicca il bottone
    - l'onSubmit della modalCreazione inserisce la proprietà nel nuovo documento
    - alla chiusura della modalCreazione viene pulito infoDocumentoSostituisce
  */

  const [infoDocumentoSostituisce, setInfoDocumentoSostituisce] = useState(null)

  const refBottoneCarica = useRef()

  useEffect(() => {
    if (infoDocumentoSostituisce) refBottoneCarica.current.click()
  }, [infoDocumentoSostituisce])

  function caricaDocumentoCheSostituisce(documento, pathDocumento) {
    const {
      uuid,
      allegato: { titolo, descrizione },
      tipo,
      parametriOriginali,
      fileMultimediali
    } = documento

    setInfoDocumentoSostituisce({
      rif: {
        identificativo: { nomeEntita: ENTITA_BASE.ALLEGATO_DOCUMENTO, uuid },
        path: pathDocumento,
        label: titolo
      },
      info: {
        allegato: { titolo, descrizione },
        tipo,
        parametri: Object.values(parametriOriginali).filter(
          ({ origine }) => origine === ORIGINE_PARAMETRO.TIPO
        ),
        fileMultimediali
      }
    })
  }

  function eliminaCatenaDocumentiSostituiti(documentoDiPartenza, pathDocumentoDiPartenza) {
    const catenaDocumenti = costruisciSequenzaDocumentiSostituiti(
      documentoDiPartenza,
      pathDocumentoDiPartenza,
      { risolviRif }
    )
    catenaDocumenti.forEach(({ path }) => {
      eliminaEntita(ENTITA_BASE.ALLEGATO_DOCUMENTO, path)
    })
  }

  /*****************************************************/

  const [uuidBozzaAppenaCreata, setUuidBozzaAppenaCreata] = useState(null)

  return (
    <>
      {ScatolaMessaggi}

      {titolo}

      {aiuto && <BaseTesto color='textSecondary'>{aiuto}</BaseTesto>}

      <BaseContenutoConProgress inAttesa={inAttesaDelServer}>
        {templateDisponibili &&
          <ListaScatoleEntita
            propsEntita={propsEntita_SenzaDocumentiSostituiti}
            {...(isSubPanel && { label })}

            // Header in sola lettura per mostrare le info del DocumentoAllegato
            renderEntita={documento =>
              <HeaderDocumentoAllegato
                documento={documento}
                trovaTemplateConNome={trovaTemplateConNome}
                gridProps={{ sx: { maxWidth: 600 } }}
                renderContenutoCustom={renderContenutoCustom_HeaderDocumento}
              />
            }

            // Gestione custom di modifica ed eliminazione del documento
            noModifica
            noElimina
            renderAltreAzioni={propsInterne => {
              const documentoInput = trasformaDocumentoInput(propsInterne.propsEntita.valoriInput)
              const propsComuni = { trovaTemplateConNome, documentoInput, ...propsInterne }
              return (
                <BaseGridLayout>
                  <VersioniDocumento
                    {...propsComuni}
                  />
                  <MenuModificaDocumento
                    {...propsComuni}
                    modificaEntita={modificaEntita}
                    caricaDocumentoCheSostituisce={caricaDocumentoCheSostituisce}
                    maxWidthModalCaricamento={maxWidthModalCaricamento}
                    propsModalModifica={propsModalModifica}
                    propsFormUpload={propsFormUpload}
                    propsFormGenera={propsFormGenera}
                    propsModalMetadati={propsModalMetadati}
                    propsFormMetadati={propsFormMetadati}
                    abilitaInformazioniAggiuntive={abilitaInformazioniAggiuntive}
                    abilitaContenutiMultimediali={abilitaContenutiMultimediali}
                    abilitaCaricamentoDocumentoSostituisce={!disabilitaCaricamentoDocumento}
                    uuidBozzaAppenaCreata={uuidBozzaAppenaCreata}
                    setUuidBozzaAppenaCreata={setUuidBozzaAppenaCreata}
                  />
                  {!disabilitaEliminazione(documentoInput) &&
                    <EliminaDocumento
                      {...propsComuni}
                      eliminaCatenaDocumentiSostituiti={eliminaCatenaDocumentiSostituiti}
                      readOnly={readOnly}
                    />
                  }
                </BaseGridLayout>
              )
            }}

            // Bottone e modal per caricare un documento
            propsBottoneCrea={{
              testo: 'Carica documento',
              iconaInizio: ICONE.ALLEGATO,
              renderModalCreazione: ({ onSubmit }) => (
                <FormPerUploadFile
                  // Passare null fa capire alla form che il binario non è stato ancora caricato
                  defaultValues={null}
                  defaultValuesInformazioniFile={infoDocumentoSostituisce?.info.allegato}
                  salvaDati={allegato =>
                    onSubmit({
                      allegato: trasformaAllegatoOutput(allegato),
                      ...(infoDocumentoSostituisce && {
                        sostituisce: infoDocumentoSostituisce.rif,
                        tipo: infoDocumentoSostituisce.info.tipo,
                        parametri: infoDocumentoSostituisce.info.parametri,
                        fileMultimediali: infoDocumentoSostituisce.info.fileMultimediali
                      })
                    })
                  }
                  {...propsFormUpload}
                />
              ),
              propsModalCreazione: {
                disabilitaChiusuraCliccandoFuori: true,
                maxWidth: maxWidthModalCaricamento,
                ...(infoDocumentoSostituisce && {
                  onClose: () => setInfoDocumentoSostituisce(null),
                  titolo: 'Carica documento aggiornato / firmato'
                })
              },
              inputRef: refBottoneCarica,
              onCreazioneCompletata: ({ uuid, isBozza, template: nomeTemplate }) => {
                const template = trovaTemplateConNome(nomeTemplate)
                if (isBozza && isTemplateHtmlForm(template)) {
                  setUuidBozzaAppenaCreata(uuid)
                }
              }
            }}

            // Bottone e modal per generare un documento da modello
            {...(!disabilitaGenerazioneDaModello && {
              renderAltriBottoniCrea: propsInterne =>
                <BottoneGeneraDocumento
                  templateDisponibili={templateDisponibili}
                  tagsTemplate={tagsTemplate}
                  labelModelliFiltratiPerTag={labelModelliFiltratiPerTag}
                  propsFormGenera={propsFormGenera}
                  propsInterne={propsInterne}
                  ciSonoErroriValidazione={ciSonoErroriValidazione}
                />
            })}

            disabilitaPortaUtenteAllaModifica
            {...disabilitaCaricamentoDocumento && { noCrea: true }}
            {...propsListaScatoleEntita}
          />
        }
      </BaseContenutoConProgress>

      <BaseToasts.Download ref={toastDocumentoGeneratoRef} />
    </>
  )
}



/* CASISTICHE DA TESTARE
htmlForm vs normale
crea vs modifica
manuale vs parametri
bozza vs scarica

COMBINAZIONI
normale crea parametri bozza
normale crea manuale bozza
normale modifica parametri bozza
normale modifica manuale bozza

normale crea parametri scarica
normale crea manuale scarica
normale modifica parametri scarica
normale modifica manuale scarica

htmlForm crea parametri bozza
htmlForm crea manuale bozza
htmlForm modifica parametri bozza
htmlForm modifica manuale bozza

htmlForm crea parametri scarica
htmlForm crea manuale scarica
htmlForm modifica parametri scarica
htmlForm modifica manuale scarica
*/