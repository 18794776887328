import { string, elementType, shape, bool } from 'prop-types'
import { Link as RouterLink, useRouteMatch } from 'react-router-dom'
import { ListItemIcon } from '@mui/material'
import { BaseLink } from './BaseLink'
import { BaseTooltip } from 'feedback'
import { BaseDivider, BaseGridLayout } from 'layout'

BaseLinkConIcona.propTypes = {
  route: shape({
    label: string.isRequired,
    pathAssoluto: string.isRequired,
    Icon: elementType.isRequired,
  }).isRequired,
  isSubRoute: bool,
  disableTooltipIcona: bool,
  urlEsterno: string
}

export default function BaseLinkConIcona(props) {
  const {
    route: {
      label,
      pathAssoluto,
      Icon: RouteIcon
    },
    isSubRoute,
    disableTooltipIcona,
    urlEsterno,
    ...restProps
  } = props

  // Controllo se la rotta deve essere marcata come attiva
  // Una rotta è attiva se l'utente si trova in questa rotta oppure in una sua sotto-rotta
  const match = useRouteMatch(pathAssoluto)
  let isActive = false
  if (match) {
    const { path: currentPath, isExact } = match
    if (isExact || (pathAssoluto !== '/' && pathAssoluto.includes(currentPath))) {
      isActive = true
    }
  }

  const contenutoLink = (
    <BaseGridLayout spacing={0} alignCenter noWrap {...restProps}>
      {isSubRoute &&
        <BaseDivider
          vertical
          sx={{ mr: 0.5, borderColor: 'grey.500', height: '2rem' }}
        />
      }

      <BaseTooltip
        contenuto={label}
        placement='right'
        disableHoverListener={disableTooltipIcona}
      >
        <ListItemIcon sx={({ spacing }) => ({ minWidth: spacing(6) })}>
          <RouteIcon
            fontSize='large'
            htmlColor='black'
            {...(isActive && { color: 'primary' })}
          />
        </ListItemIcon>
      </BaseTooltip>

      <BaseLink
        component='span'
        underline='none'
        sx={{
          lineHeight: 1,
          color: isActive ? 'info.main' : 'common.black',
          fontWeight: isSubRoute ? 'fontWeightLight' : 'fontWeightMedium'
        }}
      >
        {label}
      </BaseLink>
    </BaseGridLayout>
  )

  if (urlEsterno) {
    return (
      <a href={urlEsterno}>
        {contenutoLink}
      </a>
    )
  }

  return (
    <RouterLink to={pathAssoluto}>
      {contenutoLink}
    </RouterLink>
  )
}
