import { array, string, node, oneOfType } from 'prop-types'
import { BaseTesto } from 'text'

BaseLista.propTypes = {
  lista: array,
  seVuota: oneOfType([string, node]),
  children: node
}

export default function BaseLista(props) {
  const { lista, seVuota, children } = props

  if (lista?.length > 0) return children

  return (typeof seVuota === 'string')
    ? <BaseTesto>{seVuota}</BaseTesto>
    : seVuota
}
