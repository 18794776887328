import IndexPage from "components/pages/IndexPage";
import LoggedOutPage from "components/pages/LoggedOutPage";

export const CHIAVI_ROUTES = {
  LOGGEDOUT: 'logout',
  LOGIN: 'login'
};
export const ROUTES_NON_LOGGATO = [
  {
    pathRelativo: '/logout',
    chiave: CHIAVI_ROUTES.LOGGEDOUT,
    label: 'LoggedOut',
    Content: () => <LoggedOutPage />
  }, {
    pathRelativo: '/',
    chiave: CHIAVI_ROUTES.LOGIN,
    label: '',
    Content: () => <IndexPage />
  },{
    pathRelativo: '/reset2fa',
    chiave: CHIAVI_ROUTES.LOGGEDOUT,
    label: 'LoggedOut',
    Content: () => <LoggedOutPage />
  }
];
export const URL_SERVER = process.env.REACT_APP_BE_URL;
export const ROTTA_BASE_API = 'op/';
export const SSO_COOKIE_APPEND_NAME = process.env.REACT_APP_SSO_COOKIE_APPEND_NAME;
export const OPERAZIONI = {
  TOTP_SETUP: 'TOTP_SETUP',
  TOTP_VERIFY: 'TOTP_VERIFY',
  TOTP_SEND_MAIL: 'TOTP_SEND_MAIL',
  TOTP_VERIFY_MAIL: 'TOTP_VERIFY_MAIL',
};
export const OPERAZIONI_BASE_CONFIG = {
  [OPERAZIONI.TOTP_SETUP]: {
    rottaBaseApi: 'totp/',
    endpoint: 'setup',
    httpMethod: 'post'
  },
  [OPERAZIONI.TOTP_VERIFY]: {
    rottaBaseApi: 'totp/',
    endpoint: 'verify',
    httpMethod: 'post'
  },
  [OPERAZIONI.TOTP_SEND_MAIL]: {
    rottaBaseApi: 'totp/',
    endpoint: 'sendMail2FA',
    httpMethod: 'post'
  },
  [OPERAZIONI.TOTP_VERIFY_MAIL]: {
    rottaBaseApi: 'totp/',
    endpoint: 'verifyMail2FA',
    httpMethod: 'post'
  }
};
