import { useEffect, useRef } from 'react'
import { useInView } from 'react-intersection-observer'

// Workaround per innescare l'evento onBlur in certe situazioni
function onBlurFix(inputId) {
  const input = document.getElementById(inputId)
  input.focus()
  input.blur()
}

function convertiRemInPx(valoreInRem) {
  if (typeof valoreInRem !== 'number') return 'valoreInRem deve essere un numero'

  const documentFontSize = getComputedStyle(document.documentElement).fontSize
  const valoreInPx_1rem = parseFloat(documentFontSize)
  return valoreInRem * valoreInPx_1rem
}

function getScrollbarWidth() {
  // Thanks to https://davidwalsh.name/detect-scrollbar-width
  const scrollDiv = document.createElement('div')
  scrollDiv.setAttribute('style', 'width: 100px; height: 100px; overflow: scroll; position:absolute; top:-9999px;')
  document.body.appendChild(scrollDiv)
  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth
  document.body.removeChild(scrollDiv)
  return scrollbarWidth
}

function useScrollaGiuSeServe(disabilitaScrollGiu) {
  const scrollAutomaticoEffettuato_Ref = useRef(false) 

  const {
    ref: refElementoDaScrollare,
    inView: elementoCompletamenteVisibile,
    entry: intersectionObserverEntry
  } = useInView({ threshold: 1 })

  function scrollaGiuSeServe() {
    if (disabilitaScrollGiu) return
    
    // Si verificano cose strane se la window ha la scrollbar orizzontale,
    // a volte scrolla giù troppo, a volte troppo poco. Quindi disabilito
    // la funzionalità dello scrolling automatico in questo caso
    const { scrollWidth, clientWidth } = document.documentElement
    const windowHaScrollbarOrizzontale = (scrollWidth > clientWidth)
    if (windowHaScrollbarOrizzontale) return

    if (!elementoCompletamenteVisibile) {
      const {
        target: DOMelement,
        intersectionRect: { height: altezzaVisibile }
      } = intersectionObserverEntry

      const altezzaTotale = DOMelement.getBoundingClientRect().height
      const marginBottomString = window.getComputedStyle(DOMelement).marginBottom
      const marginBottom = parseFloat(marginBottomString)

      window.scrollBy({
        top: altezzaTotale - altezzaVisibile + marginBottom,
        behavior: 'smooth'
      })
      scrollAutomaticoEffettuato_Ref.current = true
    }
  }

  // Risolve un problema che succedeva quando si usa questo 
  // hook insieme alle liste con scrolling infinito
  // Facendo un mini scroll di 1 pixel si innesca l'evento onscroll, 
  // che in certe situazioni sfortunate non si sarebbe innescato
  function scrollReset() {
    if (scrollAutomaticoEffettuato_Ref.current === true) {
      window.scrollBy(0, 1)
      scrollAutomaticoEffettuato_Ref.current = false
    }
  }

  return {
    refElementoDaScrollare,
    scrollaGiuSeServe,
    scrollReset
  }
}

function useComunicazioneConIframe(props = {}) {
  const { onMessaggioDaIframe } = props

  const iframeRef = useRef()

  function inviaMessaggioIframe(tipo, contenuto, portaPerRispondere) {
    const iframe = iframeRef.current.contentWindow
    iframe.postMessage({ tipo, contenuto }, window.location.origin, [portaPerRispondere])
  }

  function inviaRichiestaIframe(tipo, contenuto) {
    return new Promise(resolve => {
      const { port1, port2 } = new MessageChannel()

      port1.onmessage = ({ data }) => {
        resolve(data.error
          ? { ok: false, error: data }
          : { ok: true, data }
        )
        port1.close()
      }

      inviaMessaggioIframe(tipo, contenuto, port2)
    })
  }

  const onMessaggioDaIframe_Ref = useRef(onMessaggioDaIframe)
  onMessaggioDaIframe_Ref.current = onMessaggioDaIframe

  useEffect(() => {
    if (!onMessaggioDaIframe_Ref.current) return 

    const onMessage = ({ origin, data }) => {
      if (origin !== window.location.origin) return
      onMessaggioDaIframe_Ref.current(data)
    }

    window.addEventListener('message', onMessage)
    return () => window.removeEventListener('message', onMessage)
  }, [])

  return {
    iframeRef,
    inviaMessaggioIframe,
    inviaRichiestaIframe
  }
}

export {
  onBlurFix,
  convertiRemInPx,
  getScrollbarWidth,
  useScrollaGiuSeServe,
  useComunicazioneConIframe
}