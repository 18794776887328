import { getData, postData, useRichiestaServer, useRichiestaServerConFeedback } from "@lucan/base-ui";

/**
 * 
 * async function eseguiAggiornaPassword(credenziali) {
		const {newPassword, oldPassword} = credenziali;
		const { ok, error:errorRichiesta} = await inviaOperazione(
			"password.update", { newPassword, oldPassword },{token, username})
		if (ok){
			onComplete()	
		}else{
			setErrorOp(errorRichiesta.messaggi[0]);
		}		 
  }@param {
 * } param0 
 * @returns 
 */

export function useTokenValidate({MESSAGGI_RICHIESTA_CUSTOM={}} ){

  const {
    inviaOperazione,
    inAttesaDelServer,
  } = useRichiestaServer()

  async function invia(token){
    return inviaOperazione("login.token.validate",{},{ token })
  }
  
  return {invia, inAttesaDelServer}
}

export function useTokenInvalidate({MESSAGGI_RICHIESTA_CUSTOM={}}){
  const {
    inAttesaDelServer,
    richiestaConAttesaEGestioneErrori
  } = useRichiestaServerConFeedback({
    MESSAGGI_RICHIESTA_CUSTOM
  })

  async function invia(token,app_key){
    return richiestaConAttesaEGestioneErrori(makePost('tokenInvalidate',{token,application: app_key}));
  }
  
  return {invia, inAttesaDelServer}
}

export function useLogin({MESSAGGI_RICHIESTA_CUSTOM={}} ){
  const {
    inAttesaDelServer,
    richiestaConAttesaEGestioneErrori
  } = useRichiestaServerConFeedback({
    MESSAGGI_RICHIESTA_CUSTOM
  })

  async function invia(username, password ='',app_key){
    return richiestaConAttesaEGestioneErrori(makePost('login',{username,password, application: app_key}));
  }
  
  return {invia, inAttesaDelServer}
}

const makePost = async (endpoint,payload,options) =>  postData(makeEndPoint(endpoint),payload,options).then(data => data.data);
const makeEndPoint = (partial) =>   `${process.env.REACT_APP_BE_URL}base/${partial}`


export async function ping() {
  try {
    const response = await getData(makeEndPoint(`ping`)
      )
    return response.data
  } catch (error) {
    return error;
  }
}



