import { node } from 'prop-types'
import { BaseGridLayout } from 'layout'
import { BaseTitolo } from 'text'

TitoloEToolbar.propTypes = {
  title: node,
  toolbar: node
}

export default function TitoloEToolbar(props) {
  const { title, toolbar } = props

  return (
    <BaseGridLayout justifySpaceBetween sx={{ px: 2, pt: 2 }}>
      {typeof title === 'string'
        ? <BaseTitolo>{title}</BaseTitolo>
        : title
      }

      {toolbar}
    </BaseGridLayout>
  )
}