import { forwardRef } from 'react'
import { node, number } from 'prop-types'
import { SvgIcon } from '@mui/material'
import { sx } from 'utils'

/**
 * @typedef {import("../common/icons/BaseIconaSVG").BaseIconaSVGProps} BaseIconaSVGProps
 * @typedef {import("../common/icons/BaseIconaSVG").BaseIconaSVG} BaseIconaSVG
 */


/**
 * @param {BaseIconaSVGProps} props
 * @type {BaseIconaSVG}
 */
const BaseIconaSVG = forwardRef((props, ref) => {
  const {
    children,
    size,
    ...restProps
  } = props

  return (
    <SvgIcon
      ref={ref}
      fontSize='large'
      {...restProps}
      {...sx(restProps, size && { fontSize: `${size}rem` })}
    >
      {children}
    </SvgIcon>
  )
})

BaseIconaSVG.propTypes = {
  children: node.isRequired,
  size: number
}

export default BaseIconaSVG