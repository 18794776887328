import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import BaseDateInput from '../../inputs/BaseDateInput'
import { ICONE } from 'icons'
import { BaseTextInput } from 'inputs'
import PropTypes from 'prop-types'
import BaseSelect from '../../inputs/BaseSelect'


/**
 * @typedef {import("../../common/tables/datagrid/BaseDataGridFilterElement").BaseDataGridFilterElementProps} BaseDataGridFilterElementProps 
 * @typedef {import("../../common/tables/datagrid/BaseDataGridFilterElement").BaseDataGridFilterElement} BaseDataGridFilterElement 
 */


/**
 * 
 * @param {BaseDataGridFilterElement} props 
 * @type {BaseDataGridFilterElement}
 */
function BaseDataGridFilterElement({
  id,
  columns,
  operators,
  filter,
  onColumnChange,
  onOperatorChange,
  onDelete,
  onValueChange
}) {
  const handleColumnChange = (event) => {
    const selectedColumn = columns.find((c) => c.identifier === event.target.value)
    if (selectedColumn && onColumnChange) {
      onColumnChange(selectedColumn, id)
    }
  }

  const handleOperatorChange = (event) => {
    const selectedOperator = operators.find((o) => o.identifier === (event.target.value))
    if (selectedOperator && onOperatorChange) {
      onOperatorChange(selectedOperator.identifier, id)
    }
  }

  const handleOnValueChange = (value) => {
    if (onValueChange) {
      onValueChange(value, id)
    }
  }

  const onDeleteClick = () => {
    if (onDelete) {
      onDelete(id)
    }
  }

  const renderInputValue = () => {
    switch (filter?.column?.type) {
      case 'number':
        return (
          <BaseTextInput
            type='number'
            label='Valore'
            resizeTextArea={false}
            larghezza='piccolo'
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*'
            }}
            value={filter?.value ?? ''}
            onChange={(e) => handleOnValueChange(e.target.value)}
            autoComplete='off'
          />
        )

      case 'date':
        return (
          <BaseDateInput
            id='value'
            disabilitaToolbarCustom
            label='Valore'
            larghezza='medio'
            value={filter.value}
            onChange={(e) => handleOnValueChange(e.target.value)}
          />
        )
      case 'singleSelect':
        return (
          <>
            <BaseSelect
              label='Colonna'
              value={filter?.value ?? ''}
              options={filter.column.options}
              renderOption={(c) => <>{c}</>}
              onChange={(e) => {
                handleOnValueChange(e.target.value)
              }}
            />
          </>
        )
      case 'string':
      default:
        return (
          <BaseTextInput
            label='Valore'
            larghezza='medio'
            value={filter?.value ?? ''}
            onChange={(e) => {
              handleOnValueChange(e.target.value)
            }}
            autoComplete='off'
          />
        )
    }
  }

  return (
    <>
      <Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={3} mb={1}>
        <IconButton aria-label='delete' onClick={onDeleteClick}>
          <ICONE.CHIUDI_SEMPLICE color='error' />
        </IconButton>
        <BaseSelect
          label='Colonna'
          id='column'
          larghezza='medio'
          value={filter?.column?.identifier ?? ''}
          options={columns.map((c) => ({ label: c.name, name: c.identifier }))}
          getOptionValue={({ name }) => name}
          renderOption={({ label }) => <>{label}</>}
          onChange={handleColumnChange}
        />
        <BaseSelect
          label='Operatore'
          id='operator'
          larghezza='medio'
          value={filter?.operator ?? ''}
          options={operators.map((c) => ({ label: c.name, name: c.identifier }))}
          getOptionValue={({ name }) => name}
          renderOption={({ label }) => <>{label}</>}
          onChange={handleOperatorChange}
        />

        {renderInputValue()}
      </Stack>
    </>
  )
}


const Column = PropTypes.shape({
  identifier: PropTypes.string.isRequired,
  name: PropTypes.string,
  type: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date)
  ]))
})

const Operator = PropTypes.shape({
  identifier: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired
})

const Filter = PropTypes.shape({
  column: Column,
  operator: PropTypes.number,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date)
  ])
});

BaseDataGridFilterElement.propTypes = {
  columns: PropTypes.arrayOf(Column).isRequired,
  operators: PropTypes.arrayOf(Operator).isRequired,
  id: PropTypes.oneOfType([PropTypes.string,
  PropTypes.number,]).isRequired,
  filter: Filter,
  onColumnChange: PropTypes.func,
  onOperatorChange: PropTypes.func,
  onDelete: PropTypes.func,
  onValueChange: PropTypes.func,
}

export default BaseDataGridFilterElement
