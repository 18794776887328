
import { Skeleton } from '@mui/lab';
import { Box, Button, Card, CardActions, CardContent, CardHeader, styled } from '@mui/material';
import { BaseTooltip } from 'feedback';
import { ICONE } from 'icons';
import { BaseIconButtonRounded } from 'inputs';
import PropTypes from 'prop-types';
import BaseDivider from './BaseDivider';

const CardHeaderStyled = styled(CardHeader)(({ theme }) => ({
    width: 300,
    color: theme.palette.grey['700'],
    '& .MuiCardHeader-title': {
        fontSize: '24px'
    },
    paddingBottom: 0
}))

const ActionButtonStyled = styled(Button)(() => ({
    textTransform: 'initial'
}))


const CardContentStyled = styled(CardContent)(() => ({
    fontSize: '26px',
    fontWeight: 700
}))

BaseCard.propTypes = {
    isLoading: PropTypes.bool,
    headerIcon: PropTypes.element,
    headerTitle: PropTypes.string,
    onClickFooterAction: PropTypes.func,
    footerActionLabel: PropTypes.string,
    onClickButtonAction: PropTypes.func,
    buttonActionTitle: PropTypes.string,
    buttonActionIcon: PropTypes.element,
    sx: PropTypes.object,
    children: PropTypes.node
}

/**
 * @typedef {import("../common/layout/BaseCard").BaseCardProps} BaseCardProps 
 * @typedef {import("../common/layout/BaseCard").BaseCard} BaseCard 
 */

/**
 * Base Card to display and present data. Can accept actions or not.
 * 
 * @param {BaseCardProps} props 
 * @type {BaseCard}
 */
export default function BaseCard(props) {
    const AddIcon = ICONE.AGGIUNGI_SEMPLICE

    const {
        isLoading = false,
        headerIcon,
        headerTitle,
        onClickFooterAction,
        footerActionLabel,
        buttonActionTitle,
        onClickButtonAction,
        buttonActionIcon = <AddIcon />,
        sx,
        children
    } = props

    return <Card sx={{ minWidth: 275, ...sx }}>
        {
            headerTitle && (
                <CardHeaderStyled
                    avatar={isLoading ? <Skeleton variant='rounded' width={40} height={40} /> : headerIcon}
                    title={isLoading ? <Skeleton variant='rounded' height={25} width={120} /> : headerTitle}
                />
            )
        }
        <CardContentStyled>
            <Box>
                {isLoading ? <Skeleton variant='rounded' height={20} width={100} sx={{ alignSelf: 'center' }} /> : children}
            </Box>
            <Box sx={{ position: 'relative' }}>
                {
                    onClickButtonAction && (
                        <Box sx={{ position: 'absolute', right: 0, top: '-4px' }}>
                            {
                                isLoading ? <Skeleton variant='circular' width={40} height={40} sx={{ mr: 0, ml: 'auto' }} /> :
                                    (

                                        <BaseTooltip contenuto={buttonActionTitle} >
                                            <BaseIconButtonRounded color='secondary' elevation={0} onClick={onClickButtonAction}>
                                                {buttonActionIcon}
                                            </BaseIconButtonRounded>
                                        </BaseTooltip>
                                    )
                            }
                        </Box>
                    )
                }
            </Box>
        </CardContentStyled>
        <BaseDivider />
        <CardActions>
            {
                footerActionLabel && (
                    isLoading ? <Skeleton variant='rounded' width={100} height={30} /> :
                        <ActionButtonStyled size='small' onClick={onClickFooterAction} endIcon={<ICONE.FRECCIA_A_DESTRA />}>{footerActionLabel}</ActionButtonStyled>
                )
            }
        </CardActions>
    </Card>
}