import { useEffect, useRef } from 'react'
import { Alert, Box, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import ComandoPlaceholder from 'assets/img/ComandoPlaceholder.png'
import { BaseTooltip } from 'feedback'
import { BaseButtons, BaseListaFiltrabile, BaseAutocompletamento } from 'inputs'
import { BaseScatola } from 'layout'
import { BaseTesto, BaseTitolo } from 'text'
import { contieneTutteLeParole, nonInProduzione } from 'utils'
import LogoComando from '../LogoComando'
import { useUser, useUserUpdate } from './userProvider/UserProvider'

export default function SceltaComando(props) {
  const { sx = {}, children = [], ...rest } = props

  const { handleSceltaComando } = useUserUpdate()
  const { isUserLoggato, getComandoScelto, comandiAbilitati } = useUser()
  const cmd = getComandoScelto()

  return (
    <BaseScatola
      sx={{
        maxWidth: '500px',
        margin: '0 auto',
        minHeight: '270px',
        backgroundRepeat: 'no-repeat',
        backgroundPositionY: '-10px',
        backgroundPositionX: 'center',
        backgroundImage: cmd ? 'none' : `url(${ComandoPlaceholder})`,
        boxShadow: '-1px 0px 10px -1px rgb(0 0 0 / 12%)',
        textAlign: 'center',
        borderRadius: '40px',
        ...sx
      }}
    >
      {comandiAbilitati.length > 0 ? (
        <>
          <BaseTitolo medio sx={{ fontSize: '0.925rem', textAlign: 'center' }}>
            Comando
          </BaseTitolo>

          {isUserLoggato && cmd ? (
            <Box position='relative'>
              <LogoComando
                size='big'
                wrapperStyle={{ width: '190px', height: '150px', margin: '20px auto' }}
              />
            </Box>
          ) : (
            <Box style={{ textAlign: 'center' }}>
              <BaseTesto sx={{ mt: 2 }}>
                Selezionare il comando desiderato dalla lista.
              </BaseTesto>
              <SceltaComandoAutocompletamento
                onChange={value => handleSceltaComando(value)}
                larghezza={25}
                //gridProps={{vertical: true, alignItems: 'center'}}
                {...rest}
              />
            </Box>
          )}
        </>
      ) : (
        <Alert severity='warning' sx={{ mt: 6, fontSize: '1.2rem', width: '100%' }}>
          Siamo spiacenti, ma non ci risulta nessun comando associato alla tua utenza.
          <br />
          Contatta il tuo amministratore di comando o la nostra assistenza
        </Alert>
      )}

      {children}
    </BaseScatola>
  )
}



export const SceltaComandoAutocompletamento = (props) => {
  const {
    filtroCustom = () => true,
    onChange,
    ultimoSceltoProps = { sx: { p: 2 } },
    value,
    ...rest
  } = props

  const { ssoAbilitato } = useUserUpdate()

  const inputRef = useRef()
  useEffect(() => inputRef.current?.focus(), [inputRef.current])

  const { comandiAbilitati, ultimoComandoScelto, getComandoScelto } = useUser()

  const comandiPuliti = nonInProduzione()
    ? comandiAbilitati.filter(({ tag }) => !tag.startsWith('xunittest'))
    : comandiAbilitati

  const optionsComandi = comandiPuliti.filter(filtroCustom)
  const tagComandoScelto = value ?? getComandoScelto()?.tag

  return (
    <>
      {ultimoComandoScelto && ultimoComandoScelto !== tagComandoScelto &&
        <BaseTooltip contenuto={'Clicca per tornare al precedente comando'}>
          <span>
            <BaseButtons.AnnullaModifiche
              onClick={() => onChange(ultimoComandoScelto)}
              variant='text'
              testo={
                <>
                  {ultimoComandoScelto &&
                    <>
                      <LogoComando tag={ultimoComandoScelto} size='md' />
                      <span style={{ fontWeight: 'bold', color: '#797979' }}>
                        {optionsComandi.find(x => x.tag === ultimoComandoScelto)?.nome}
                      </span>
                    </>
                  }
                </>
              }
              {...ultimoSceltoProps}
            />
          </span>
        </BaseTooltip>
      }

      <BaseListaFiltrabile
        options={optionsComandi.sort(x => x.tag === tagComandoScelto ? -1 : 0)}
        matchFn={(option, criteri) => contieneTutteLeParole(`${option.tipo} ${option.nome}`, criteri)}
        nascondiFiltroElementi={8}
        filtroProps={{ label: 'Comando', sx: { width: '100%!important' }, inputRef }}
        {...rest}
        selectOpzione={(option) => onChange(option.tag)}
        renderOption={(option, rest) => {
          const optionValue = option.tag
          const selected = optionValue === tagComandoScelto
          const { ref, ...restItem } = rest
          return (
            <ListItem
              key={optionValue}
              disablePadding
              onClick={() => onChange(optionValue)}
              sx={selected ? { borderBottom: '2px solid #e1e1e1' } : {}}
              {...restItem}
            >
              <ListItemButton selected={selected} autoFocus={selected} ref={ref}>
                <ListItemIcon>
                  <LogoComando
                    tag={option.tag}
                    wrapperStyle={{
                      width: '50px',
                      height: '50px',
                      margin: '0px 8px 0 0',
                    }}
                    style={{
                      borderRadius: '100px',
                      backgroundColor: '#fff',
                      padding: '3px',
                      border: '1px solid #ccc',
                      marginRight: '3px ',
                    }}
                  />
                </ListItemIcon>

                <ListItemText
                  disableTypography={true}
                  primary={ssoAbilitato
                    ? <span style={{ display: 'block', fontSize: '0.875rem', color: 'rgba(0, 0, 0, 0.6)', }}>{option.tipo} di</span>
                    : ''
                  }
                  secondary={
                    <span style={{ fontSize: '1rem', color: 'rgba(0, 0, 0, 0.87)', }}>{option.nome}</span>
                  }
                />
              </ListItemButton>
            </ListItem>
          )
        }}
      />
    </>
  )
}



export const SceltaComandoAutocompletamentoInput = (props) => {
  const { filtroCustom = () => true, propsAutocomplete, ...rest } = props

  const { comandiAbilitati } = useUser()

  const comandiPuliti = nonInProduzione()
    ? comandiAbilitati.filter(({ tag }) => !tag.startsWith('xunittest'))
    : comandiAbilitati

  const optionsComandi = comandiPuliti
    .filter(filtroCustom)
    .map(({ tag, nome, tipo }) => ({
      value: tag,
      label: tipo ? `${tipo} di ${nome}` : nome
    }))

  return (
    <BaseAutocompletamento
      label='Scelta Comando'
      options={optionsComandi}
      propsAutocomplete={{
        // Di default la key usata dal componente MUI è la label, ma questo
        // dava problemi se arrivano comandi con tag diversi e label uguale
        // Quindi passo un renderOption custom che usa il tag come key
        renderOption: (props, option) => (
          <Box component='li' {...props} key={option.value}>
            <LogoComando
              tag={option.value}
              wrapperStyle={{
                width: '30px',
                height: '30px',
                margin: '0px 8px 0 0',
              }}
              style={{
                borderRadius: '100px',
                backgroundColor: '#fff',
                padding: '3px',
                border: '1px solid #ccc',
                marginRight: '3px ',
              }}
            />
            {option.label}
          </Box>
        ),
        ...propsAutocomplete
      }}
      {...rest}
    />
  )
}