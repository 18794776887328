import { capitalizza } from 'utils'

const ITALY = 'ITALY'

function isItaly(nazione) {
  const nazioneUppercase = nazione.toUpperCase()
  return nazioneUppercase === ITALY || nazioneUppercase === 'ITALIA'
}

function formattaIndirizzo(indirizzo, soloVia = false) {
  const {
    cap,
    comune,
    numeroCivico,
    provincia,
    tipo,
    via
  } = indirizzo

  let indirizzoFormattato

  const tipoFormattato = capitalizza(tipo)
  if (!via && !comune) {
    indirizzoFormattato = 'Nessun indirizzo'
  } else {
    let viaFormattata = tipoFormattato ? `${tipoFormattato} ${via}` : via
    if (numeroCivico) viaFormattata += ` ${numeroCivico}`
    const comuneFormattato = comune ? `, ${comune} (${cap} ${provincia})` : ''
    indirizzoFormattato = soloVia ? viaFormattata : `${viaFormattata}${comuneFormattato}`
  }

  return indirizzoFormattato
}

function formattaCapEQuartiere(indirizzo) {
  const { cap, quartiere } = indirizzo
  if (!quartiere) return cap
  return `${cap}, quartiere: ${quartiere}`
}

function formattaTipoEVia(indirizzo) {
  const { tipo, via } = indirizzo
  return `${capitalizza(tipo)} ${via}`
}

export {
  ITALY,
  isItaly,
  formattaIndirizzo,
  formattaCapEQuartiere,
  formattaTipoEVia
}