import { string, bool, number } from 'prop-types'
import { Box } from '@mui/material'
import { sx } from 'utils'

BaseIndicatoreStato.propTypes = {
  error: bool,
  warning: bool,
  success: bool,
  info: bool,
  color: string,
  sizeInRem: number
}

export default function BaseIndicatoreStato(props) {
  const {
    error,
    warning,
    success,
    info,
    color,
    sizeInRem = 1,
    ...restProps
  } = props

  let coloreDefinitivo
  if (error) coloreDefinitivo = 'error.main'
  else if (warning) coloreDefinitivo = 'warning.light'
  else if (success) coloreDefinitivo = 'success.main'
  else if (info) coloreDefinitivo = 'info.main'
  else if (color) coloreDefinitivo = color
  else coloreDefinitivo = 'common.black'

  const size = `${sizeInRem}rem`

  return (
    <Box
      {...restProps}
      {...sx(restProps, {
        borderRadius: '50%',
        bgcolor: coloreDefinitivo,
        height: size,
        width: size,
        boxShadow: 3
      })}
    />
  )
}
