import { node, string, object } from 'prop-types'
import { BaseTitolo } from 'text'
import { sx } from 'utils'
import BaseScatola from './BaseScatola'

BaseScatolaConTitolo.propTypes = {
  children: node,
  titolo: string,
  propsTitolo: object
}

export default function BaseScatolaConTitolo(props) {


  const {
    children,
    titolo,
    propsTitolo,
    ...restProps
  } = props

  return (
    <BaseScatola
      outlined
      {...restProps}
      {...sx(restProps, {
        position: 'relative',
        mt: 1,

      })}
    >
      {titolo &&
        <BaseTitolo
          colorato
          {...propsTitolo}
          {...sx(propsTitolo, {
            position: 'absolute',
            top: -20,
            bgcolor: 'white',
            px: 1
          })}
        >
          {titolo}
        </BaseTitolo>
      }

      {children}
    </BaseScatola>
  )
}
