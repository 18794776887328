import { bool } from 'prop-types'
import { Divider } from '@mui/material'
import { sx, useCurrentTheme } from 'utils'

BaseDivider.propTypes = {
  vertical: bool
}

export default function BaseDivider(props) {
  const { vertical, ...restProps } = props
  const { isAlternativeTheme } = useCurrentTheme()

  return (
    <Divider
      orientation={vertical ? 'vertical' : 'horizontal'}
      flexItem={vertical}
      {...restProps}
      {...sx(restProps, { borderColor: isAlternativeTheme() ? 'grey.50' : 'grey.300' })}
    />
  )
}