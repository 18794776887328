import { useState } from 'react'
import { string } from 'prop-types'
import { BaseFeedbackConIcona, BasePopoverConfermaOperazione } from 'feedback'
import { BaseButtons, BaseSelect } from 'inputs'
import { BaseGridLayout, useModal, BaseModal } from 'layout'
import { useUser } from '../../userManagement/userProvider/UserProvider'
import { PROP_TYPES_WRAPPER_FORM_MODIFICA } from '../entitaGenerica/components/FormModificaEntita'
import { LABEL_STATI_ENTITA, STATI_ENTITA } from './ConfigurazioneStatiEntita'
import LabelStatoEntita from './LabelStatoEntita'

// Questo componente va messo nel pannello di modifica della sottoentità che
// contiene lo stato (solitamente i dati generali o qualcosa di simile)
ModificaManualeStatoEntita.propTypes = {
  ...PROP_TYPES_WRAPPER_FORM_MODIFICA,
  nomeProprietaStato: string,
  nomeProprietaNumero: string,
  nomeProprietaAnno: string,
}

export default function ModificaManualeStatoEntita(props) {
  const { modalRef, openModal, closeModal } = useModal()

  const { isUserStaff } = useUser()
  if (!isUserStaff()) return null

  const {
    propsEntita: { valoriInput, nomeEntita, pathEntita },
    propsInterne_PannelloModificaEntita: {
      gestioneModifica: { modificaEntita, inviaModificheInCoda }
    },
    nomeProprietaStato = 'stato',
    nomeProprietaNumero = 'numero',
    nomeProprietaAnno = 'annoRiferimento',
  } = props

  function impostaStato(statoDaImpostare) {
    modificaEntita(nomeEntita, pathEntita, {
      [nomeProprietaStato]: statoDaImpostare,
      ...(serveTogliereNumero(statoDaImpostare) && {
        [nomeProprietaNumero]: '',
        [nomeProprietaAnno]: 0,
      })
    }, { ignoraReadOnly: true })

    inviaModificheInCoda({ forzaInvio: true })
  }

  const stato = valoriInput[nomeProprietaStato]

  return (
    <>
      <BaseButtons.Modifica
        testo='Modifica manuale stato (STAFF)'
        onClick={openModal}
      />

      <BaseModal
        ref={modalRef}
        titolo='Modifica manuale stato (STAFF)'
        maxWidth='md'
      >
        <ModificaStato
          statoAttuale={stato}
          onSalva={nuovoStato => {
            impostaStato(nuovoStato)
            closeModal()
          }}
          onAnnulla={closeModal}
        />
      </BaseModal>
    </>
  )
}



function serveTogliereNumero(stato) {
  return stato === STATI_ENTITA.BOZZA || stato === STATI_ENTITA.ELIMINATO
}

const OPTIONS_STATI_ENTITA = Object.values(STATI_ENTITA).map(value => ({
  value,
  label: LABEL_STATI_ENTITA[value]
}))

function ModificaStato(props) {
  const { statoAttuale, onSalva, onAnnulla } = props

  const [stato, setStato] = useState(statoAttuale)

  return (
    <BaseGridLayout alignCenter justifyCenter>
      <BaseSelect
        label='Nuovo stato'
        options={OPTIONS_STATI_ENTITA}
        renderOption={({ value }) =>
          <LabelStatoEntita stato={value} mostraAncheInLavorazione />
        }
        value={stato}
        onChange={e => setStato(e.target.value)}
      />

      <BasePopoverConfermaOperazione
        messaggio={
          <>
            Confermare modifica stato
            da&nbsp;<LabelStatoEntita stato={statoAttuale} mostraAncheInLavorazione />&nbsp;
            a&nbsp;<LabelStatoEntita stato={stato} mostraAncheInLavorazione />&nbsp;?
          </>
        }
        onConferma={() => onSalva(stato)}
      >
        <BaseButtons.Salva submit={false} />
      </BasePopoverConfermaOperazione>

      <BaseButtons.Chiudi
        testo='Annulla'
        onClick={() => onAnnulla()}
      />

      <BaseFeedbackConIcona.Attenzione
        messaggio={`Impostando lo stato ${LABEL_STATI_ENTITA[stato]} verrà anche tolto il numero di registro.`}
        hidden={!serveTogliereNumero(stato)}
      />
    </BaseGridLayout>
  )
}