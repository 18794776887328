import { string, shape, oneOf, oneOfType, bool } from 'prop-types'
import { BaseDownloadLink } from 'files'
import { MIME_TYPES_VALUES, openFilePreviewFromUrl } from 'utils'
import useRichiestaServer from '../network/RichiestaServerHook'

DownloadFile.propTypes = {
  file: shape({
    uuid: string.isRequired,
    mimetype: oneOfType([string, oneOf(MIME_TYPES_VALUES)]).isRequired,
    filename: string.isRequired
  }),
  mostraBottoneVisualizzaFile: bool,
  forzaAllegatoOriginale: bool
}

export default function DownloadFile(props) {
  const {
    file: {
      uuid,
      mimetype,
      filename
    },
    mostraBottoneVisualizzaFile,
    forzaAllegatoOriginale,
    ...restProps
  } = props

  const {
    ScatolaMessaggi,
    downloadFileWithUUID,
    downloadToastRef,
    inAttesaDelServer,
    getAttachmentURL
  } = useRichiestaServer()

  return (
    <>
      {ScatolaMessaggi}

      <BaseDownloadLink
        nomeFile={filename}
        mimetype={mimetype}
        onClick={() => {
          const opzioni = {
            ...(forzaAllegatoOriginale && { original: true })
          }
          downloadFileWithUUID(uuid, filename || uuid, opzioni)
        }}
        ref={downloadToastRef}
        inAttesa={inAttesaDelServer}
        {...(mostraBottoneVisualizzaFile && {
          visualizzaFile: () => {
            const url = getAttachmentURL(uuid, false, forzaAllegatoOriginale)
            openFilePreviewFromUrl(url, filename || uuid)
          }
        })}
        {...restProps}
      />
    </>
  )
}