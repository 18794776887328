import { oneOf, bool, object } from 'prop-types'
import { Checkbox, FormControlLabel, FormHelperText, Switch } from '@mui/material'
import useInputGenerico from './hooks/InputGenericoHook'
import useControllerSeServe from './hooks/ControllerSeServeHook'

BaseCheckbox.propTypes = {
  switch: bool,
  posizioneLabel: oneOf(['sopra', 'sotto', 'destra', 'sinistra']),
  formControlLabelProps: object,
  ...useInputGenerico.propTypes
}

const mappaLabelPlacement = {
  sopra: 'top',
  sotto: 'bottom',
  destra: 'end',
  sinistra: 'start'
}

export default function BaseCheckbox(props) {
  const {
    switch: renderComeSwitch,
    label,
    posizioneLabel = 'destra',
    formControlLabelProps,
    ...restProps
  } = props

  const {
    error,
    warning,
    helperText,
    hidden,
    ...inputGenericoProps
  } = useInputGenerico({
    componenteControllato: true,
    underlyingComponent: 'checkbox_o_radio',
    ...restProps
  })

  const {
    value,
    onBlur,
    onChange,
    ref,
    propsRimanenti
  } = useControllerSeServe(inputGenericoProps)

  const Input = renderComeSwitch ? Switch : Checkbox
  
  return (
    <div style={{ ...(hidden && { display: 'none' }) }}>
      <FormControlLabel
        label={label}
        labelPlacement={mappaLabelPlacement[posizioneLabel]}
        control={
          <Input
            checked={value}
            onBlur={onBlur}
            onChange={e => onChange(e.target.checked)}
            inputRef={ref}
            {...propsRimanenti}
          />
        }
        {...formControlLabelProps}
      />

      <FormHelperText
        error={error}
        {...(warning && { sx: { color: 'warning.main' } })}
      >
        {helperText}
      </FormHelperText>
    </div>
  )
}