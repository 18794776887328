import { oneOf, arrayOf, string, shape, bool, node } from 'prop-types'
import { Checkbox, FormControlLabel, FormHelperText, FormLabel, FormGroup } from '@mui/material'
import useInputGenerico from './hooks/InputGenericoHook'
import useControllerSeServe from './hooks/ControllerSeServeHook'

BaseCheckboxArray.propTypes = {
  options: arrayOf(shape({
    value: string.isRequired,
    label: node.isRequired
  })).isRequired,
  posizioneLabel: oneOf(['sopra', 'sotto', 'destra', 'sinistra']),
  visualizzazioneOrizzontale: bool,
  ...useInputGenerico.propTypes
}

BaseCheckboxArray.campoConOptions = true

const mappaLabelPlacement = {
  sopra: 'top',
  sotto: 'bottom',
  destra: 'end',
  sinistra: 'start'
}

export default function BaseCheckboxArray(props) {
  const {
    options,
    label,
    posizioneLabel = 'destra',
    visualizzazioneOrizzontale,
    ...restProps
  } = props

  const {
    error,
    warning,
    required,
    helperText,
    hidden,
    ...inputGenericoProps
  } = useInputGenerico({
    componenteControllato: true,
    underlyingComponent: 'checkbox_o_radio',
    ...restProps
  })

  const {
    value: opzioniSelezionate,
    onBlur,
    onChange,
    propsRimanenti
  } = useControllerSeServe(inputGenericoProps)

  function aggiornaOpzioniSelezionate(valueOpzioneModificata, checked) {
    const opzioniSelezionateAggiornate = (checked === true)
      ? [...opzioniSelezionate, valueOpzioneModificata]
      : opzioniSelezionate.filter(value => value !== valueOpzioneModificata)
      
    onChange(opzioniSelezionateAggiornate)
  }

  return (
    <div style={{ ...(hidden && { display: 'none' }) }}>
      <FormLabel
        required={required}
        error={error}
        {...(warning && { sx: { color: 'warning.main' } })}
      >
        {label}
      </FormLabel>

      <FormGroup row={visualizzazioneOrizzontale}>
        {options.map(({ label, value }) =>
          <FormControlLabel
            key={value}
            label={label}
            labelPlacement={mappaLabelPlacement[posizioneLabel]}
            control={
              <Checkbox
                checked={opzioniSelezionate.includes(value)}
                onBlur={onBlur}
                onChange={e => aggiornaOpzioniSelezionate(value, e.target.checked)}
                {...propsRimanenti}
              />
            }
          />
        )}
      </FormGroup>

      <FormHelperText
        error={error}
        {...(warning && { sx: { color: 'warning.main' } })}
      >
        {helperText}
      </FormHelperText>
    </div>
  )
}