import { useEffect, useState } from 'react'
import { BaseContenutoConProgress } from 'feedback'
import { BaseModal } from 'layout'
import { OPERAZIONI_BASE } from '../../network/OperazioniBase'
import useRichiestaServer from '../../network/RichiestaServerHook'
import { trasformaTipiDocumento } from './utilDocumenti'
import FormMetadatiDocumento from './form/FormMetadatiDocumento'

export default function ModalModificaMetadati(props) {
  const {
    documentoInput,
    modificaDocumento,
    chiudiModifica,

    propsModalMetadati,
    propsFormMetadati
  } = props

  // Salvo qui anziché in ContenutoModalMetadati, per evitare che venga
  // rifatta la richiesta al server ogni volta che si apre la modal
  const [tipiDocumento, setTipiDocumento] = useState(null)

  return (
    <BaseModal
      titolo='Modifica informazioni documento'
      open={true}
      onClose={chiudiModifica}
      disabilitaChiusuraCliccandoFuori
      contenutoNonCentrato
      {...propsModalMetadati}
    >
      <ContenutoModalMetadati
        documentoInput={documentoInput}
        propsFormMetadati={propsFormMetadati}
        tipiDocumento={tipiDocumento}
        setTipiDocumento={setTipiDocumento}
        salvaDocumentoModificato={modificaDocumento}
      />
    </BaseModal>
  )
}



function ContenutoModalMetadati(props) {
  const {
    documentoInput,
    propsFormMetadati,
    tipiDocumento,
    setTipiDocumento,
    salvaDocumentoModificato
  } = props

  const {
    tipo: { first: tipo },
    template
  } = documentoInput

  const {
    ScatolaMessaggi,
    inviaOperazione,
    inAttesaDelServer
  } = useRichiestaServer()

  useEffect(() => {
    (async function fetchTipiDocumento() {
      if (tipiDocumento) return

      // Per i documenti generati, il tipo viene impostato 
      // in fase di generazione e non è modificabile
      const isTipoNonModificabile = (template && tipo)
      const { ok, data } = await inviaOperazione(OPERAZIONI_BASE.DOCUMENTI_TIPI, {
        // Se il tipo non è modificabile, chiedo al server solo la
        // definizione di quel tipo (non mi servono gli altri)
        ...(isTipoNonModificabile && { tipo })
      })

      if (ok) {
        // Il server risponde con un singolo oggetto se chiedo un solo tipo
        const tipi = isTipoNonModificabile ? [data] : data.tipi
        setTipiDocumento(trasformaTipiDocumento(tipi))
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tipiDocumento, template, tipo])

  return (
    <>
      {ScatolaMessaggi}

      <BaseContenutoConProgress inAttesa={inAttesaDelServer}>
        {tipiDocumento &&
          <FormMetadatiDocumento
            documentoInput={documentoInput}
            salvaDocumentoModificato={salvaDocumentoModificato}
            tipiDocumento={tipiDocumento}
            {...propsFormMetadati}
          />
        }
      </BaseContenutoConProgress>
    </>
  )
}