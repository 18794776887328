import { node, func, object } from 'prop-types'
import { BaseTitolo } from 'text'
import ElenchiManager, { ELENCHI } from '../../form/ElenchiManager'
import FormModificaEntita, { PROP_TYPES_WRAPPER_FORM_MODIFICA } from '../entitaGenerica/components/FormModificaEntita'
import ListaSubPanels from '../entitaGenerica/components/ListaSubPanels'
import ScatolaEntita from '../entitaGenerica/components/ScatolaEntita'
import ModificaRifPersona from '../persona/ModificaRifPersona'
import HeaderPersonaCondizione from './HeaderPersonaCondizione'

PersonaCondizioneModifica.propTypes = {
  ...PROP_TYPES_WRAPPER_FORM_MODIFICA,
  BottoneTornaIndietro: node,
  persona: object,
  creaDescrittoriPersone: func
}

const NOMI = {
  PERSONA_RIF: 'personaRif'
}

export default function PersonaCondizioneModifica(props) {
  const {
    propsEntita,
    label,
    isSubPanel,
    subPanels,
    BottoneTornaIndietro,
    persona,
    creaDescrittoriPersone
  } = props
  const { valoriInput } = propsEntita

  if (isSubPanel) {
    return (
      // ElenchiManager inserito come workaround per un bug della gestione elenchi
      // che si verificava alla creazione delle condizioni di salute e faceva
      // rimanere in loading un altro componente che chiedeva lo stesso elenco
      <ElenchiManager
        elenchi={[ELENCHI.STATO_SALUTE]}
        render={() =>
          <ScatolaEntita testoBottoneCrea='Aggiungi condizioni di salute' {...props}>
            {valoriInput &&
              <HeaderPersonaCondizione condizione={valoriInput} persona={persona} />
            }
          </ScatolaEntita>
        }
      />
    )
  }

  return (
    <>
      {BottoneTornaIndietro}

      <BaseTitolo medio>{label}</BaseTitolo>

      {!persona && creaDescrittoriPersone &&
        <FormModificaEntita {...propsEntita} ritardoDebounceInMillisecondi={500}>
          <ModificaRifPersona
            name={NOMI.PERSONA_RIF}
            creaDescrittoriPersone={creaDescrittoriPersone}
          />
          <br />
        </FormModificaEntita>
      }

      <ListaSubPanels subPanels={subPanels} />
    </>
  )
}