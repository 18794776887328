import { string, node, bool } from 'prop-types'
import BaseGrassetto from './BaseGrassetto'
import { BaseTesto } from './BaseTesto'

BaseCoppiaChiaveValore.propTypes = {
  chiave: string.isRequired,
  valore: node,
  grassettoChiave: bool,
  grassettoValore: bool
}

export default function BaseCoppiaChiaveValore(props) {
  const {
    chiave,
    valore,
    grassettoChiave = false,
    grassettoValore = true,
    ...restProps
  } = props

  if (typeof valore !== 'number' && !valore) return null

  const chiaveDaMostrare = grassettoChiave
    ? <BaseGrassetto>{chiave}</BaseGrassetto>
    : chiave

  const valoreDaMostrare = grassettoValore
    ? <BaseGrassetto>{valore}</BaseGrassetto>
    : valore

  return (
    <BaseTesto {...restProps}>
      {chiaveDaMostrare}: {valoreDaMostrare}
    </BaseTesto>
  )
}