import { BaseContenutoConTitoloEFallback } from 'layout'
import { BaseListaCoppieChiaveValore } from 'text'
import { formattaData } from 'utils'

export default function Assicurazione(props) {
  const {
    assicurazione: {
      agenzia,
      compagnia,
      dataAssicurazione,
      // dataFineComporto,
      dataScadenza,
      numero,
      stato
    }
  } = props

  return (
    <BaseContenutoConTitoloEFallback
      datiPresentiSe={
        agenzia
        || compagnia
        || dataAssicurazione
        // || dataFineComporto
        || dataScadenza
        || numero
        || stato
      }
      titolo='Assicurazione'
      propsTitolo={{ colorato: true }}
    >
      <BaseListaCoppieChiaveValore
        coppie={{
          'Agenzia': agenzia,
          'Compagnia': compagnia,
          'Data di assicurazione': formattaData(dataAssicurazione),
          // 'Data di fine comporto': formattaData(dataFineComporto),
          'Data di scadenza': formattaData(dataScadenza),
          'Numero': numero,
          'Stato': stato
        }}
      />
    </BaseContenutoConTitoloEFallback>
  )
}