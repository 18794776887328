import { memo } from 'react'
import { number, object, shape, array, func } from 'prop-types'
import { areEqual } from 'react-window'
import { TableCell, TableRow } from '@mui/material'
import { getRowKey } from '../UtilsTabella'

export const RigaSingola = memo(function Riga(props) {
  const {
    index,
    style,
    data: {
      rows,
      prepareRow
    }
  } = props

  const row = rows[index]
  prepareRow(row)
  const { cells, getRowProps } = row

  const propsRiga = {
    component: 'div',
    key: getRowKey(row, index),
    style
  }

  return (
    <TableRow {...getRowProps(propsRiga)}>
      {cells.map(({ render, getCellProps, column }) => {
        const propsCella = {
          component: 'div',
          key: `cell_${propsRiga.key}_${column.id}`,
          // Il padding lo inserisco da un'altra parte, perciò qui lo tolgo
          style: { padding: 0 }
        }

        return (
          // eslint-disable-next-line react/jsx-key
          <TableCell {...getCellProps(propsCella)}>
            {render('Cell')}
          </TableCell>
        )
      })}
    </TableRow>
  )
}, areEqual)

RigaSingola.propTypes = {
  index: number.isRequired,
  style: object,
  data: shape({
    rows: array.isRequired,
    prepareRow: func.isRequired
  }).isRequired
}