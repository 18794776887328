import { element, arrayOf, object } from 'prop-types'
import BaseCheckbox from '../BaseCheckbox'
import BaseCheckboxArray from '../BaseCheckboxArray'
import BaseDateInput from '../BaseDateInput'
import BaseFileInput from '../BaseFileInput'
import BaseRadioGroup from '../BaseRadioGroup'
import BaseSelect from '../BaseSelect'
import BaseSelectMultipla from '../BaseSelectMultipla'
import { BaseCoppiaChiaveValore } from 'text'
import { formattaData, formattaBooleano, getOptionLabel, getArrayOptionLabel, formattaFile } from 'utils'

RiepilogoValoriCampi.propTypes = {
  campiGenerati: arrayOf(element),
  mappaNomiCampi_ValoriCampi: object
}

export default function RiepilogoValoriCampi(props) {
  const {
    campiGenerati,
    mappaNomiCampi_ValoriCampi
  } = props

  return campiGenerati.map(({ type, props }) => {
    const { label, name, options } = props

    let funzioneFormattazione
    const funzionePerOptions_SelezioneSingola = valore => getOptionLabel(options, valore)
    const funzionePerOptions_SelezioneMultipla = valori => getArrayOptionLabel(options, valori)
    
    switch (type) {
      case BaseCheckbox:
        funzioneFormattazione = formattaBooleano
        break
      case BaseCheckboxArray:
        funzioneFormattazione = funzionePerOptions_SelezioneMultipla
        break
      case BaseDateInput:
        funzioneFormattazione = formattaData
        break
      case BaseFileInput:
        funzioneFormattazione = formattaFile
        break
      case BaseRadioGroup:
        funzioneFormattazione = funzionePerOptions_SelezioneSingola
        break
      case BaseSelect:
        funzioneFormattazione = funzionePerOptions_SelezioneSingola
        break
      case BaseSelectMultipla:
        funzioneFormattazione = funzionePerOptions_SelezioneMultipla
        break
      default:
        funzioneFormattazione = valore => valore
        break
    }
  
    return (
      <BaseCoppiaChiaveValore
        chiave={label}
        valore={funzioneFormattazione(mappaNomiCampi_ValoriCampi[name])}
        key={name}
      />
    )
  })
}