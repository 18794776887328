import { useMemo } from 'react'
import { arrayOf, shape, string, object, bool } from 'prop-types'
import { TextField, Autocomplete, Checkbox } from '@mui/material'
import useInputGenerico from './hooks/InputGenericoHook'
import useControllerSeServe from './hooks/ControllerSeServeHook'
import { CheckBoxOutlineBlank, CheckBoxRounded } from '@mui/icons-material'

BaseAutocompletamentoMultiplo.propTypes = {
  options: arrayOf(
    shape({
      label: string.isRequired,
      value: string.isRequired,
    })
  ).isRequired,
  pair: bool,
  controlloValueCaseInsensitive: bool,
  propsAutocomplete: object,
  ...useInputGenerico.propTypes,
}

const icon = <CheckBoxOutlineBlank fontSize='small' />
const checkedIcon = <CheckBoxRounded fontSize='small' />

BaseAutocompletamentoMultiplo.campoConOptions = true

export default function BaseAutocompletamentoMultiplo(props) {
  const {
    options,
    controlloValueCaseInsensitive = false,
    // propsAutocomplete,
    ...restProps
  } = props

  const inputGenericoProps = useInputGenerico({
    componenteControllato: true,
    widthType: 'width',
    ...restProps,
  })

  const { value, onBlur, onChange, ref, propsRimanenti } =
    useControllerSeServe(inputGenericoProps)

  const valueDefinitivo = useMemo(() => {
    const valoriAttuali = value
    const opzioniRelativeAlValoreAttuale = options.filter((option) => {
      for (let i = 0; i < valoriAttuali.length; i++) {
        if (controlloValueCaseInsensitive) {
          if (option.value.toUpperCase() === valoriAttuali[i].value.toUpperCase()) {
            return true
          }
        } else {
          if (option.value === valoriAttuali[i].value) {
            return true
          }
        }
      }
      return false
    })
    // Al componente MUI piace value = null quando nessuna opzione è selezionata
    return opzioniRelativeAlValoreAttuale || null
  }, [value, options, controlloValueCaseInsensitive])

  function onChangeConConversioneInPair(_, nuovoValore) {
    if (nuovoValore) {
      //   const { value, label } = nuovoValore
      onChange(nuovoValore)
    } else {
      onChange([])
    }
  }

  return (
    <Autocomplete
      multiple
      options={options}
      value={valueDefinitivo}
      limitTags={2}
      onChange={onChangeConConversioneInPair}
      getOptionLabel={(option) => option.label}
      disableCloseOnSelect
      // {...propsAutocomplete}
      renderInput={(params) => (
        <TextField
          {...params}
          inputRef={ref}
          onBlur={onBlur}
          {...propsRimanenti}
        />
      )}
      renderOption={(props, option, { selected }) => {
        return (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.label}
          </li>
        )
      }}
    />
  )
}
