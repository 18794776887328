import { useState } from 'react'
import { string } from 'prop-types'
import { BaseFeedbackConIcona } from 'feedback'
import { ICONE } from 'icons'
import { BaseButtons } from 'inputs'
import { BaseGridLayout } from 'layout'

BottoneCopiaAppunti.propTypes = {
  testoDaCopiare: string.isRequired
}

export default function BottoneCopiaAppunti(props) {
  const { testoDaCopiare, ...restProps } = props

  const [copiato, setCopiato] = useState(false)
  const [erroreCopia, setErroreCopia] = useState(false)

  async function copiaNegliAppunti() {
    try {
      await navigator.clipboard.writeText(testoDaCopiare)
      setCopiato(true)
      setErroreCopia(false)
      setTimeout(() => {
        setCopiato(false)
      }, 2000)
    } catch (error) {
      console.log(error)
      setErroreCopia(true)
      setCopiato(false)
      setTimeout(() => {
        setErroreCopia(false)
      }, 2000)
    }
  }

  return (
    <BaseGridLayout alignCenter>
      <BaseButtons.Custom
        testo='Copia negli appunti'
        iconaInizio={ICONE.COPIA}
        onClick={copiaNegliAppunti}
        {...restProps}
      />
      <BaseFeedbackConIcona.TuttoOk
        messaggio='Copiato'
        hidden={!copiato}
      />
      <BaseFeedbackConIcona.Errore
        messaggio='Impossibile copiare'
        hidden={!erroreCopia}
      />
    </BaseGridLayout>
  )
}