
/**
 * @type {import("../../common/incloudApps/index").Operations}
 */
export const OPERAZIONI_BASE = {
  // Entita
  ENTITY_NEW: 'entity.new',
  ENTITY_GET: 'entity.get',
  ENTITY_SEARCH: 'entity.search',
  ENTITY_UPDATE: 'entity.update',
  ENTITY_RICHIEDI_NUMERO: 'entity.richiediNum',
  ENTITY_ELENCO_OPERAZIONI: 'entity.operazioni',
  ENTITY_GET_CONTATORE_NUM: 'entity.getContatoreNum',
  ENTITY_SET_CONTATORE_NUM: 'entity.setContatoreNum',

  // Gestione utenti
  PERMESSI_UTENTE_ELENCO: 'permessi.elenco',
  TOKEN_VALIDATE: 'token.validate',
  LOGOUT: 'logout',

  // Binario
  BINARIO_SALVA: 'binario.salva',
  BINARIO_ELIMINA: 'binario.elimina',
  
  // Generazione documenti
  DOCUMENTI_LISTA_TEMPLATE: 'docs.templates',
  DOCUMENTI_GENERA: 'docs.generate',
  DOCUMENTI_TIPI: 'docs.tipi',
  DOCUMENTI_GENERA_SPEDIZIONE: 'docs.generaSpedizione',
  DOCUMENTI_HTML_FORM_GET: 'docs.htmlForm.get',

  // Esportazione immagini
  ESPORTAZIONE_IMMAGINI_ZIP: 'esportazione.immagini.zip',
  ESPORTAZIONE_IMMAGINI_PDF: 'esportazione.immagini.pdf',
  ESPORTAZIONE_IMMAGINI_SELEZIONI_PASSATE: 'esportazione.immagini.selezioni',

  // Elenchi
  DIZIONARIO_GET: 'dizionario.get',
  DIZIONARIO_SALVA: 'dizionario.salva',
  VARIABILI_ELENCO: 'variabili.elenco',
  COMUNE_CERCA: 'comune.cerca',

  // Visure
  VISURA_ANAGRAFICA: 'visura.anagrafica',
  VISURA_VEICOLO: 'visura.veicolo',
  VISURA_TIPO_VISURE: 'visura.tipo-visure',
}



export const OPERAZIONI_BASE_CONFIG = {
  [OPERAZIONI_BASE.ESPORTAZIONE_IMMAGINI_ZIP]: {
    rottaBaseApi: '/api/v3/',
    endpoint: 'sinistri/immagini/zip'
  },

  [OPERAZIONI_BASE.ESPORTAZIONE_IMMAGINI_PDF]: {
    rottaBaseApi: '/api/v3/',
    endpoint: 'sinistri/fascicoloFotografico'
  }
}