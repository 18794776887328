import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { BaseTesto } from 'text'

export default function CampiParams(props) {
  const {
    //prefissoNomi,
    ciSonoParams,
    campiParams,
    // defaultValuesParams,
    nomiCampiControlloVisibilita,
    nomiCampiControlloOptionsDinamiche,
    elenchi,
    iniettaOptionsElenchi,
    calcolaOptionsDinamiche,
    isParametroFisso,
    isParametroVisibile,

    el,
    testoSenzaParams = 'Nessun parametro.'
  } = props

  const { watch, getValues } = useFormContext()

  /*************** Visibilità campi ***************/

  const arrayValoriControlloVisibilita = watch(nomiCampiControlloVisibilita)

  function calcolaCampiVisibili(campi, oggettoCompletoValori) {
    return campi.map(campo => {
      const { props } = campo
      const { name } = props
      const hidden = (
        isParametroFisso(name)
        || !isParametroVisibile(name, arrayValoriControlloVisibilita, oggettoCompletoValori)
      )
      return {
        ...campo,
        props: { ...props, hidden }
      }
    })
  }

  /************************************************/

  /*************** Options dinamiche ***************/

  const arrayValoriControlloOptionsDinamiche = watch(nomiCampiControlloOptionsDinamiche)

  function iniettaOptionsDinamiche(campi, oggettoCompletoValori) {
    return campi.map(campo => {
      const { props } = campo
      const { name } = props
      const optionsDinamiche = calcolaOptionsDinamiche(name, arrayValoriControlloOptionsDinamiche, oggettoCompletoValori)
      if (!optionsDinamiche) return campo
      return {
        ...campo,
        props: { ...props, options: optionsDinamiche }
      }
      // Da fare: quando cambia il valore di un campo da cui dipendono 
      // le options di un altro, quest'ultimo deve essere pulito
    })
  }

  /************************************************/

  // Le dipendenze di questo useMemo sono i valori dei campi che hanno un
  // effetto sulla visibilità o sulle options dinamiche di altri campi
  // Così ricalcolo la visibilità e le options dinamiche solo quando serve
  // ATTENZIONE: passare un array di dipendenze che può potenzialmente cambiare
  // lunghezza e/o ordine potrebbe causare errori. Chi usa questo componente
  // deve stare attento e passare una key per forzare la ricreazione nel caso
  // in cui cambia la definizione dei parametri (es. in FormMetadatiDocumento)
  const campiVisibili = useMemo(() => {
    const oggettoCompletoValori = getValues()
    const campiVisibili = calcolaCampiVisibili(campiParams, oggettoCompletoValori)
    return iniettaOptionsDinamiche(campiVisibili, oggettoCompletoValori)
  }, [...arrayValoriControlloVisibilita, ...arrayValoriControlloOptionsDinamiche])

  if (!ciSonoParams) {
    return (
      <BaseTesto sx={{ textAlign: 'left' }}>
        {testoSenzaParams}
      </BaseTesto>
    )
  }

  if (elenchi?.length > 0) {
    return iniettaOptionsElenchi(campiVisibili, el)
  }

  return campiVisibili
}