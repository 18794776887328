import { useEffect } from 'react'
import { BaseIconButtons } from 'inputs'
import BaseImmagine, { MAX_HEIGHT_IMMAGINE } from '../BaseImmagine'

export default function ImmagineCorrenteConNavigazione(props) {
  const {
    immagineCorrente,
    indietroDiUna,
    avantiDiUna,
    isPrimaImmagine,
    isUltimaImmagine,
    minHeight_ScatolaImmagineCorrente = MAX_HEIGHT_IMMAGINE + 25,
    disabilitaNavigazione
  } = props

  useEffect(() => {
    function keyboardListener(e) {
      // Quando l'utente sta scrivendo in un campo di testo potrebbe voler usare
      // le frecce, senza far scattare per sbaglio la navigazione tra le immagini
      const nomeTag_ElementoInFocus = document.activeElement?.tagName.toLowerCase()
      const utenteStaScrivendo = ['textarea', 'input'].includes(nomeTag_ElementoInFocus)
      if (disabilitaNavigazione || utenteStaScrivendo) return
      switch (e.key) {
        case 'ArrowLeft':
          indietroDiUna()
          break
        case 'ArrowRight':
          avantiDiUna()
          break
      }
    }

    window.addEventListener('keydown', keyboardListener)
    return () => window.removeEventListener('keydown', keyboardListener)
  }, [indietroDiUna, avantiDiUna, disabilitaNavigazione])

  const propsBottoniNavigazione = {
    iconProps: {
      style: { fontSize: '3rem' }
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'nowrap',
        // L'altezza minima crea più spazio per l'immagine corrente,
        // così si elimina il problema della modal che cambia altezza
        // qando le immagini sono di dimensioni e orientamento diverso
        minHeight: minHeight_ScatolaImmagineCorrente
      }}
    >
      <BaseIconButtons.Indietro
        onClick={indietroDiUna}
        disabled={disabilitaNavigazione || isPrimaImmagine}
        {...propsBottoniNavigazione}
      />

      {immagineCorrente &&
        <div style={{ alignSelf: 'flex-end' }}>
          <BaseImmagine immagine={immagineCorrente} />
        </div>
      }

      <BaseIconButtons.Avanti
        onClick={avantiDiUna}
        disabled={disabilitaNavigazione || isUltimaImmagine}
        {...propsBottoniNavigazione}
      />
    </div>
  )
}