import { arrayOf, object, bool } from 'prop-types'
import { BaseGridLayout, BaseContenutoConTitoloEFallback } from 'layout'
import Agente from './Agente'

ListaAgenti.propTypes = {
  agenti: arrayOf(object).isRequired,
  visioneRiassuntiva: bool
}

export default function ListaAgenti(props) {
  const { agenti, visioneRiassuntiva } = props

  const gridProps = visioneRiassuntiva
    ? { spacing: 4 }
    : { vertical: true }

  const listaAgenti = (
    <BaseGridLayout {...gridProps}>
      {agenti.map(agente =>
        <Agente
          agente={agente}
          visioneRiassuntiva
          propsHeader={{ iconaNonColorata: true }}
          key={agente.id || agente.matricola}
        />
      )}
    </BaseGridLayout>
  )

  if (visioneRiassuntiva) return listaAgenti

  return (
    <BaseContenutoConTitoloEFallback
      datiPresentiSe={agenti.length > 0}
      titolo='Agenti'
      propsTitolo={{ colorato: true }}
    >
      {listaAgenti}
    </BaseContenutoConTitoloEFallback>
  )
}