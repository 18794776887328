import { ICONE } from 'icons'
import { BaseButtons, BaseIconButtons } from 'inputs'
import { BaseGridLayout } from 'layout'
import { parseDataUrlBase64 } from 'utils'
import { rotateImage } from '../manipolationLib'

export default function ModificaBinarioImmagine(props) {
  const {
    immagineCorrente,
    chiudiModificaBinario,
    useGestioneFile,
    overrideSrcImmagineCorrente,
    setOverrideSrcImmagineCorrente,
    forzaRenderThumbnails
  } = props

  const {
    uploadFile,
    ScatolaMessaggi,
    inAttesaDelServer,
  } = useGestioneFile()

  async function salvaBinarioImmagine() {
    if (!overrideSrcImmagineCorrente) return

    const { mimetype, base64 } = parseDataUrlBase64(overrideSrcImmagineCorrente)
    let data = { ...immagineCorrente, mimetype, base64 }
    delete data.src
    delete data.tags
    
    const { ok } = await uploadFile(null, data)
    if (ok) {
      chiudiModificaBinario()
      forzaRenderThumbnails()
    }
  }

  function ruota(gradi) {
    rotateImage(
      immagineCorrente.src.fullSize,
      gradi,
      ({ src }) => setOverrideSrcImmagineCorrente(src),
      { mimetype: immagineCorrente.mimetype }
    )
  }

  return (
    <BaseGridLayout justifyCenter disposizioneRighe={[3, 2, 1]}>
      <BaseIconButtons.Custom
        contenutoTooltip='Ruota di 90 gradi in senso antiorario'
        Icon={ICONE.RUOTA_90_GRADI_SENSO_ANTIORARIO}
        onClick={() => ruota(-90)}
      />
      <BaseIconButtons.Custom
        contenutoTooltip='Ruota di 180 gradi'
        Icon={ICONE.RUOTA_180_GRADI}
        onClick={() => ruota(180)}
      />
      <BaseIconButtons.Custom
        contenutoTooltip='Ruota di 90 gradi in senso orario'
        Icon={ICONE.RUOTA_90_GRADI_SENSO_ORARIO}
        onClick={() => ruota(90)}
      />

      <BaseButtons.Salva
        testo='Salva immagine'
        onClick={() => salvaBinarioImmagine()}
        inAttesa={inAttesaDelServer}
      />
      <BaseButtons.AnnullaModifiche
        color='default'
        onClick={() => chiudiModificaBinario()}
      />
      {ScatolaMessaggi}
    </BaseGridLayout>
  )
}