import { oneOfType, string, instanceOf, bool } from 'prop-types'
import { BaseTesto } from 'text'
import { formattaData, sx } from 'utils'

DataCreazione.propTypes = {
  dataCreazione: oneOfType([string, instanceOf(Date)]),
  nascondiCreatoIl: bool
}

export default function DataCreazione(props) {
  const { dataCreazione, nascondiCreatoIl, ...restProps } = props

  if (!dataCreazione) return null

  return (
    <BaseTesto
      inline
      {...restProps}
      {...sx(restProps, { fontStyle: 'italic', fontSize: '0.85em' })}
    >
      {!nascondiCreatoIl && 'Creato il '}
      <BaseTesto inline sx={{ fontWeight: 'bold', fontSize: '1em' }}>
        {formattaData(dataCreazione)}
      </BaseTesto>
    </BaseTesto>
  )
}