import { forwardRef, useEffect, useImperativeHandle } from 'react'
import { func, element, number, string, object, bool } from 'prop-types'
import { CircularProgress } from '@mui/material'
import { ICONE } from 'icons'
import { BaseIconButtons } from 'inputs'
import { useValueWhenStable, useStateWithLabel } from 'utils'
import BaseTextInput from './BaseTextInput'

export const BaseBarraRicercaConRitardo = forwardRef(function BaseBarraRicercaConRitardo(props,ref) {
  const {
    testoRicerca_DefaultValue = '',
    setTestoRicerca_FinitoDiScrivere,
    inAttesa,
    iconaAdornment = <ICONE.CERCA />,
    numeroMinimoCaratteriRicerca = 2,
    propsRitardo,
    togliPrimaParteMessaggioAiuto,
    ...restProps
  } = props

  const [
    testoRicerca, setTestoRicerca
  ] = useStateWithLabel(testoRicerca_DefaultValue, 'testoRicerca')

  function isTestoRicercaValido(testoRicerca) {
    return (
      testoRicerca?.length === 0
      || testoRicerca?.trim().length >= numeroMinimoCaratteriRicerca
    )
  }

  const [testoRicerca_FinitoDiScrivere, utenteStaScrivendo] = useValueWhenStable({
    value: testoRicerca,
    isValueValid: isTestoRicercaValido,
    ...propsRitardo
  })

  useEffect(
    () => setTestoRicerca_FinitoDiScrivere(testoRicerca_FinitoDiScrivere),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [testoRicerca_FinitoDiScrivere]
  )

  function cleanInput() {
    setTestoRicerca('')
    setTestoRicerca_FinitoDiScrivere('')
  }

  useImperativeHandle(ref, () => ({
    cleanInput
  }))

  const iconaAdornment_Definitiva = testoRicerca_FinitoDiScrivere ? (
    <BaseIconButtons.Pulisci
      onClick={cleanInput}
      style={{ padding: 0 }}
    />
  ) : iconaAdornment

  return (
    <BaseTextInput
      label='Ricerca'
      value={testoRicerca}
      onChange={e => setTestoRicerca(e.target.value)}
      messaggioAiuto={
        `${togliPrimaParteMessaggioAiuto ? '' : 'Lasciare vuoto per vedere tutti i risultati. '}
        Almeno ${numeroMinimoCaratteriRicerca} caratteri per effettuare una ricerca.`
      }
      larghezza={18}
      error={!utenteStaScrivendo && !isTestoRicercaValido(testoRicerca)}
      endAdornment={utenteStaScrivendo || inAttesa
        ? <CircularProgress size={25} color='secondary' />
        : iconaAdornment_Definitiva
      }
      inputProps={{ autoComplete: 'new-password' }}
      {...restProps}
    />
  )
})

BaseBarraRicercaConRitardo.propTypes = {
  testoRicerca_DefaultValue: string,
  setTestoRicerca_FinitoDiScrivere: func.isRequired,
  inAttesa: bool,
  iconaAdornment: element,
  numeroMinimoCaratteriRicerca: number,
  propsRitardo: object,
  togliPrimaParteMessaggioAiuto: bool
}