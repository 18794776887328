import { string, object } from 'prop-types'
import { ICONE } from 'icons'
import { BaseButtons } from 'inputs'
import LinkPannello from './LinkPannello'

LinkDettagliEntita.propTypes = {
  url: string,
  testo: string,
  propsBottone: object
}

export default function LinkDettagliEntita(props) {
  const { url, testo, propsBottone, ...restProps } = props

  if (!url) return null

  return (
    <LinkPannello to={url} {...restProps}>
      <BaseButtons.Custom
        testo={testo}
        iconaFine={ICONE.FRECCIA_DESTRA_CERCHIATA}
        variant='text'
        color='info'
        {...propsBottone}
      />
    </LinkPannello>
  )
}