import { BaseScatola, BaseTesto } from '@lucan/base-ui';
import { Box, Chip, Popover, Typography } from '@mui/material';
import React from 'react';
import vecchioLogin from './vecchio login.PNG';

const Layout = ({showLog=true, children }) => {
	return (

			<div className="login-inner">
				<BaseScatola className="scatola">	
				{showLog && 
					<Box style={{textAlign:'center',margin:'0 auto'}}>
						<img className="logo" src={process.env.PUBLIC_URL + '/LOGO_2021.svg'} alt="logo"/>
					</Box>
				}
				<div>{ children }</div>
				</BaseScatola>
			</div>

	);
};



export default Layout;