import { useState } from 'react'
import { object, bool } from 'prop-types'
import { Box } from '@mui/material'
import { BaseRadioGroup } from 'inputs'
import { creaFunzioneUnica } from 'utils'
import { ENTITA_BASE } from '../EntitaBase'
import AziendaModifica, { validaAzienda } from '../azienda/AziendaModifica'
import { PROP_TYPES_WRAPPER_FORM_MODIFICA } from '../entitaGenerica/components/FormModificaEntita'
import ScatolaEntita from '../entitaGenerica/components/ScatolaEntita'
import PersonaModifica, { validaPersona } from '../persona/PersonaModifica'

PersonaOAziendaModifica.propTypes = {
  ...PROP_TYPES_WRAPPER_FORM_MODIFICA,
  propsBottoneElimina: object,
  // Se true, premendo il bottone Elimina viene eliminata solo l'entità interna 
  // corrente (persona o azienda), mentre il wrapper PersonaOAzienda rimane
  // Così si mantiene la coerenza con la logica del backend, che spesso crea 
  // un oggetto PersonaOAzienda con azienda = null e persona = null
  eliminaSoloEntitaInterna: bool
}

PersonaOAziendaModifica.defaultConfig = {
  nomeEntita: ENTITA_BASE.PERSONA_O_AZIENDA,
  validate: validaPersonaOAzienda
}

export default function PersonaOAziendaModifica(props) {
  const {
    propsEntita: { valoriInput, pathEntita },
    propsInterne_PannelloModificaEntita: {
      gestioneModifica: { creaEntita }
    },
    propsBottoneElimina,
    eliminaSoloEntitaInterna = true
  } = props

  const pathPersona = pathEntita.addPath('persona')
  const pathAzienda = pathEntita.addPath('azienda')

  // Visto che la creazione avviene con una ScatolaEntita diversa da
  // quelle che mostrano la persona o l'azienda create, devo tenere
  // traccia qui di quando l'entità è stata appena creata (serve per
  // portare automaticamente l'utente all'entità appena creata)
  const [appenaCreata, setAppenaCreata] = useState(false)

  // Se non esiste il wrapper PersonaOAzienda, oppure esiste ma
  // sia persona sia azienda sono null, mostro una ScatolaEntita 
  // apposita che gestisce la creazione in entrambi i casi  
  if (!valoriInput || (!valoriInput.azienda && !valoriInput.persona)) {
    return (
      <ScatolaEntita
        // Questa scatola deve sempre essere nella modalità di creazione
        checkValoriInputPresenti={() => false}
        renderModalCreazione={({ onSubmit }) =>
          <ModalCreazionePersonaOAzienda
            onSubmit={onSubmit}
            setAppenaCreata={setAppenaCreata}
            creaEntita={creaEntita}
            pathPersona={pathPersona}
            pathAzienda={pathAzienda}
            valoriInput={valoriInput}
          />
        }
        {...props}
      />
    )
  }

  // Se arrivo qui, esiste o la persona o l'azienda
  const { persona, azienda } = valoriInput

  const propsBottoneElimina_Definitive = {
    // Tramite la prop eliminaSoloEntitaInterna si può decidere se eliminare solo
    // l'entità interna, oppure tutto il wrapper PersonaOAzienda. Nel secondo caso
    // sovrascrivo il nomeEntita e il path inserendo quelli del wrapper
    ...(!eliminaSoloEntitaInterna && {
      nomeEntita: ENTITA_BASE.PERSONA_O_AZIENDA,
      path: pathEntita
    }),
    ...propsBottoneElimina,
    opzioniEliminazione: {
      ...propsBottoneElimina?.opzioniEliminazione,
      // Dopo l'eliminazione, aggiungo una funzione per resettare il flag appenaCreata
      onEliminazioneCompletata: creaFunzioneUnica(
        propsBottoneElimina?.opzioniEliminazione?.onEliminazioneCompletata,
        () => setAppenaCreata(false)
      )
    }
  }

  if (persona) {
    return (
      <PersonaModifica
        {...props}
        isEntitaAppenaCreata={appenaCreata}
        propsBottoneElimina={propsBottoneElimina_Definitive}
        propsEntita={{
          ...props.propsEntita,
          valoriInput: persona,
          nomeEntita: ENTITA_BASE.PERSONA,
          pathEntita: pathPersona
        }}
      />
    )
  }

  if (azienda) {
    return (
      <AziendaModifica
        {...props}
        isEntitaAppenaCreata={appenaCreata}
        propsBottoneElimina={propsBottoneElimina_Definitive}
        propsEntita={{
          ...props.propsEntita,
          valoriInput: azienda,
          nomeEntita: ENTITA_BASE.AZIENDA,
          pathEntita: pathAzienda
        }}
      />
    )
  }
}



function ModalCreazionePersonaOAzienda(props) {
  const {
    onSubmit,
    setAppenaCreata,
    creaEntita,
    pathPersona,
    pathAzienda,
    valoriInput
  } = props

  const opzioniCreazione = { onCreazioneCompletata: () => setAppenaCreata(true) }

  // Crea solo la persona o l'azienda dentro un wrapper PersonaOAzienda già esistente
  function creaSoloEntitaInterna(tipo) {
    if (tipo === 'PF') {
      creaEntita(ENTITA_BASE.PERSONA, pathPersona, opzioniCreazione)
    } else if (tipo === 'PG') {
      creaEntita(ENTITA_BASE.AZIENDA, pathAzienda, opzioniCreazione)
    }
  }

  // Crea il wrapper PersonaOAzienda completo, già inizializzato con la persona o l'azienda
  function creaEntitaCompleta(tipo) {
    // Invio al server il tipo scelto, così può creare l'entità interna
    // Passo null come primo argomento, perché l'entità che arriva
    // dal server è già completa e non devo fondere niente
    onSubmit(null, {
      optionsCreazioneSulServer: { tipo },
      ...opzioniCreazione
    })
  }

  return (
    <Box sx={{ display: 'inline-block' }}>
      <BaseRadioGroup
        options={[
          { label: 'Persona', value: 'PF' },
          { label: 'Azienda', value: 'PG' }
        ]}
        onChange={e => {
          const tipo = e.target.value
          if (valoriInput) creaSoloEntitaInterna(tipo)
          else creaEntitaCompleta(tipo)
        }}
        visualizzazioneOrizzontale
      />
    </Box>
  )
}



export function validaPersonaOAzienda(utilValidazione) {
  const { valoriInput, getUtilValidazioneAnnidato } = utilValidazione
  const { persona, azienda } = valoriInput

  if (persona) validaPersona(getUtilValidazioneAnnidato('persona'))
  if (azienda) validaAzienda(getUtilValidazioneAnnidato('azienda'))
}