import { node, object, bool, oneOf } from 'prop-types'
import { LinearProgress } from '@mui/material'
import { sx } from 'utils'

BaseContenutoConProgress.propTypes = {
  children: node,
  inAttesa: bool.isRequired,
  progressSotto: bool,
  propsProgress: object,
  noMargineY: bool,
  mostraFigli: oneOf(['sempre', 'seInAttesa', 'seNonInAttesa'])
}

export default function BaseContenutoConProgress(props) {
  const {
    children,
    inAttesa,
    progressSotto = false,
    noMargineY = false,
    mostraFigli = 'seNonInAttesa',
    ...propsProgress
  } = props

  const marginDirection = progressSotto ? 'mt' : 'mb'
  const progress = (
    inAttesa && (
      <LinearProgress
        {...propsProgress}
        {...sx(propsProgress, { [marginDirection]: noMargineY ? 0 : 1 })}
      />
    )
  )

  let childrenDaMostrare
  switch (mostraFigli) {
    case 'sempre':
      childrenDaMostrare = children
      break
    case 'seInAttesa':
      childrenDaMostrare = inAttesa && children
      break
    case 'seNonInAttesa':
      childrenDaMostrare = !inAttesa && children
      break
    default:
      childrenDaMostrare = !inAttesa && children
      break
  }

  if (progressSotto) {
    return (
      <>
        {childrenDaMostrare}
        {progress}
      </>
    )
  }

  return (
    <>
      {progress}
      {childrenDaMostrare}
    </>
  )
}
