import { BaseAutocompletamento } from 'inputs'
import ElenchiManager, { ELENCHI } from '../../form/ElenchiManager'

export default function SceltaNazione(props) {
  const { options, ...restProps } = props

  const propsComuni = {
    messaggioAiuto: '',
    controlloValueCaseInsensitive: true,
    ...restProps
  }

  if (options) {
    return (
      <BaseAutocompletamento
        options={options}
        {...propsComuni}
      />
    )
  }

  return (
    <ElenchiManager
      elenchi={[ELENCHI.NAZIONI]}
      render={el => (
        <BaseAutocompletamento
          options={el(ELENCHI.NAZIONI)}
          {...propsComuni}
        />
      )}
    />
  )
}
