import { useStateWithLabel } from 'utils'

export default function useGruppiUtente() {
  const [gruppiUtente, setGruppiUtente] = useStateWithLabel([], 'gruppiUtente')

  function getGruppiUtente() {
    return gruppiUtente
  }

  function isUtenteAssociatoAPiuGruppi() {
    return gruppiUtente.length > 1
  }

  function utenteHaAlmenoUnGruppoConProprieta(nomePropCercata, valorePropCercata) {
    return gruppiUtente.some(({ [nomePropCercata]: valore }) => valore === valorePropCercata)
  }

  function impostaGruppiUtente(gruppi) {
    if (Array.isArray(gruppi)) setGruppiUtente(gruppi)
  }

  return {
    getGruppiUtente,
    isUtenteAssociatoAPiuGruppi,
    utenteHaAlmenoUnGruppoConProprieta,
    impostaGruppiUtente
  }
}