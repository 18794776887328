import { arrayOf, object, bool } from 'prop-types'
import { useFormContext } from 'react-hook-form'
import { BaseSelectModal } from 'inputs'
import { BaseGridLayout } from 'layout'
import { arricchisciPropOggetto } from 'utils'
import HeaderIndirizzo from './HeaderIndirizzo'
import Indirizzo from './Indirizzo'
import { formattaCapEQuartiere, formattaTipoEVia } from './utilIndirizzo'

SceltaViaDaStradario.propTypes = {
  stradario: arrayOf(object),
  indirizzoScelto: object,
  nascondiIndirizzoScelto: bool,
}

SceltaViaDaStradario.campoConOptions = true

export default function SceltaViaDaStradario(props) {
  const {
    stradario,
    indirizzoScelto,
    nascondiIndirizzoScelto,
    ...propsSelect
  } = props

  const risultatiUseForm = useFormContext()

  const indirizzoSceltoDefinitivo = (
    indirizzoScelto
    || risultatiUseForm?.watch(propsSelect.name)
    || null
  )

  return (
    <BaseGridLayout alignCenter>
      <BaseSelectModal
        options={stradario}
        getOptionKey={({ codice }) => codice}
        renderOption={indirizzo =>
          <HeaderIndirizzo
            indirizzo={indirizzo}
            titolo={formattaTipoEVia(indirizzo)}
            sottotitolo={formattaCapEQuartiere(indirizzo)}
            senzaPadding={false}
          />
        }
        getOptionLabel={indirizzo => formattaTipoEVia(indirizzo)}
        getOptionSearchString={
          ({ tipo, via, cap, quartiere }) => [tipo, via, cap, quartiere].join(' ')
        }

        label='Stradario'
        labelRicerca='via, cap, quartiere'
        {...propsSelect}
        {...arricchisciPropOggetto(propsSelect, 'propsBottoneModifica', {
          noMarginY: false
        })}
      />

      {!nascondiIndirizzoScelto && indirizzoSceltoDefinitivo &&
        <Indirizzo
          indirizzo={indirizzoSceltoDefinitivo}
          visioneRiassuntiva senzaLabel
        />
      }
    </BaseGridLayout>
  )
}