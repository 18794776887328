import { useState } from 'react'
import { Box } from '@mui/material'
import { BaseContenutoConTitoloEFallback, BaseGridLayout, BaseModal, useModal } from 'layout'
import { BaseTitolo } from 'text'
import Persona from '../persona/Persona'

export default function PersoneABordo(props) {
  const { passeggeri, visioneRiassuntiva } = props

  const gridProps = visioneRiassuntiva
    ? { spacing: 4 }
    : { vertical: true }

  const { openModal, modalRef } = useModal()
  const [passeggeroEspanso, setPasseggeroEspanso] = useState(null)

  const listaPersone = (
    <BaseGridLayout {...gridProps}>
      {passeggeri.map(({ persona, conducente, uuid }) =>
        <Box
          key={uuid}
          {...(!visioneRiassuntiva && {
            sx: { cursor: 'pointer', '&:hover': { border: 1, borderColor: 'info.main', borderRadius: 1 } },
            onClick: () => {
              setPasseggeroEspanso({ persona, conducente })
              openModal()
            }
          })}
        >
          <Persona
            persona={persona}
            visioneRiassuntiva
            propsHeader={{
              sottotitolo: conducente ? 'Conducente' : 'Passeggero',
              iconaNonColorata: true
            }}
          />
        </Box>
      )}
    </BaseGridLayout>
  )

  if (visioneRiassuntiva) return listaPersone

  return (
    <BaseContenutoConTitoloEFallback
      datiPresentiSe={passeggeri.length > 0}
      titolo='Persone a bordo'
      propsTitolo={{ colorato: true }}
    >
      {listaPersone}

      <BaseModal
        ref={modalRef}
        // Il ritardo serve per avere un miglior effetto visivo alla chiusura della modals
        onClose={() => setTimeout(() => setPasseggeroEspanso(null), 500)}
        maxWidth='lg'
      >
        {passeggeroEspanso ? (
          <>
            <BaseTitolo>Dettaglio {passeggeroEspanso.conducente ? 'Conducente' : 'Passeggero'}</BaseTitolo>
            <div style={{ textAlign: 'left' }}>
              <Persona
                persona={passeggeroEspanso.persona}
                propsHeader={{ senzaPadding: false }}
                espanso
              />
            </div>
          </>
        ) : <></>}
      </BaseModal>
    </BaseContenutoConTitoloEFallback>
  )
}