import { useMemo } from 'react'
import { string } from 'prop-types'
import BaseRadioGroup from './BaseRadioGroup'
import useControllerSeServe from './hooks/ControllerSeServeHook'
import useInputGenerico from './hooks/InputGenericoHook'

BaseRadioBoolean.propTypes = {
  labelFalse: string,
  labelTrue: string,
  color: string,
  ...useInputGenerico.propTypes
}

export default function BaseRadioBoolean(props) {
  const {
    labelFalse = 'No',
    labelTrue = 'Si',
    ...restProps
  } = props

  const options = useMemo(() => ([
    { value: 'false', label: labelFalse },
    { value: 'true', label: labelTrue }
  ]), [labelFalse, labelTrue])

  const inputGenericoProps = useInputGenerico({
    componenteControllato: true,
    underlyingComponent: 'checkbox_o_radio',
    ...restProps
  })

  const {
    value: valueBoolean,
    onBlur,
    onChange,
    propsRimanenti
  } = useControllerSeServe(inputGenericoProps)

  function onChangeConConversioneInBoolean(e) {
    const nuovoValore = e.target.value
    onChange(nuovoValore === 'true' ? true : false)
  }

  return (
    <BaseRadioGroup
      nonUsareHookForm
      options={options}
      value={valueBoolean ? 'true' : 'false'}
      onBlur={onBlur}
      onChange={onChangeConConversioneInBoolean}
      visualizzazioneOrizzontale
      {...propsRimanenti}
    />
  )
}

