import { costruisciRotta, formattaCognomeNome } from 'utils'
import { ENTITA_BASE } from '../EntitaBase'
import { PROPRIETA_VEICOLO, SEZIONI_VEICOLO } from './AlberoVeicolo'

function creaDescrizioneVeicolo(veicolo) {
  if (!veicolo) return ''
  if (veicolo.targaAssente) return 'Targa assente'
  return veicolo.targa || '(targa non inserita)'
}

function creaDescrizionePasseggero(passeggero) {
  const { conducente, persona } = passeggero
  return `${conducente ? 'Conducente' : 'Passeggero'} - ${formattaCognomeNome(persona)}`
}

function creaDescrizioneProprietario(proprietario) {
  let desc = 'Proprietario'
  if (proprietario?.azienda) desc += ' (azienda)'
  return desc
}

function creaDescrittoriPersoneVeicolo(veicolo, pathVeicolo, urlVeicolo) {
  let descrittoriPersone = []

  function aggiungiDescrittorePersona(entitaConPersona, pathBase, urlBase, labelRuoloPersona) {
    const { persona } = entitaConPersona
    descrittoriPersone.push({
      entita: persona,
      nomeEntita: ENTITA_BASE.PERSONA,
      path: pathBase.addPath('persona'),
      url: costruisciRotta(urlBase, 'persona'),
      label: `${formattaCognomeNome(persona)} (${labelRuoloPersona} veicolo ${creaDescrizioneVeicolo(veicolo)})`
    })
  }

  const { proprietario, passeggeri } = veicolo
  if (proprietario.persona) {
    aggiungiDescrittorePersona(
      proprietario,
      pathVeicolo.addPath(PROPRIETA_VEICOLO.PROPRIETARIO),
      costruisciRotta(urlVeicolo, SEZIONI_VEICOLO.PROPRIETARIO),
      'Proprietario'
    )
  }
  passeggeri.forEach(passeggero => {
    const { conducente, uuid } = passeggero
    aggiungiDescrittorePersona(
      passeggero,
      pathVeicolo.addPath(PROPRIETA_VEICOLO.PASSEGGERI).addUuid(uuid),
      costruisciRotta(urlVeicolo, SEZIONI_VEICOLO.PASSEGGERI, uuid),
      conducente ? 'Conducente' : 'Passeggero'
    )
  })

  return descrittoriPersone
}

function calcolaOptionsPersoneVeicolo(pathVeicolo, funzioniAlbero) {
  const { calcolaOptionsNodoLista, trovaNodoAlberoConPath } = funzioniAlbero
  const veicolo = trovaNodoAlberoConPath(pathVeicolo).valoriInput
  const descrizioneVeicolo = `veicolo ${creaDescrizioneVeicolo(veicolo)}`
  let optionsPersoneVeicolo = []

  if (veicolo.proprietario.persona) {
    optionsPersoneVeicolo.push({
      value: pathVeicolo.addPath(PROPRIETA_VEICOLO.PROPRIETARIO).addPath('persona'),
      label: `${formattaCognomeNome(veicolo.proprietario.persona)} (Proprietario ${descrizioneVeicolo})`
    })
  }

  function creaOptionLabelPasseggero(nodoAlbero) {
    const { valoriInput: { persona, conducente } } = nodoAlbero
    return `${formattaCognomeNome(persona)} (${conducente ? 'Conducente' : 'Passeggero'} ${descrizioneVeicolo})`
  }
  optionsPersoneVeicolo.push(
    ...calcolaOptionsNodoLista(pathVeicolo.addPath(PROPRIETA_VEICOLO.PASSEGGERI), {
      pathDaAppendere: 'persona',
      creaLabel: creaOptionLabelPasseggero
    })
  )

  return optionsPersoneVeicolo
}

export {
  creaDescrizioneVeicolo,
  creaDescrizionePasseggero,
  creaDescrizioneProprietario,
  creaDescrittoriPersoneVeicolo,
  calcolaOptionsPersoneVeicolo
}