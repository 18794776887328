import { elementType, string, object, bool } from 'prop-types'
import { ICONE } from 'icons'
import { BaseGridLayout } from 'layout'
import { BaseTesto } from 'text'
import { sx } from 'utils'

FeedbackConIcona.propTypes = {
  Icona: elementType.isRequired,
  messaggio: string.isRequired,
  messaggino: string,
  noWrap: bool,
  propsIcona: object,
  propsMessaggio: object,
  propsMessaggino: object
}

function FeedbackConIcona(props) {
  const {
    Icona,
    messaggio,
    messaggino,
    noWrap,
    propsIcona,
    propsMessaggio,
    propsMessaggino
  } = props

  return (
    <div>
      <BaseGridLayout spacing={1} alignCenter noWrap={noWrap}>
        <Icona {...propsIcona} />

        <BaseTesto
          {...propsMessaggio}
          {...sx(propsMessaggio, {
            fontWeight: 'fontWeightBold',
            ...(noWrap && { whiteSpace: 'noWrap' })
          })}
        >
          {messaggio}
        </BaseTesto>
      </BaseGridLayout>

      {messaggino &&
        <BaseTesto
          variant='body2'
          color='textSecondary'
          {...propsMessaggino}
        >
          {messaggino}
        </BaseTesto>
      }
    </div>
  )
}

function aggiungiColoreMessaggio(props = {}, color) {
  const { propsMessaggio } = props
  return {
    ...propsMessaggio,
    ...sx(propsMessaggio, { color })
  }
}

export const BaseFeedbackConIcona = {
  TuttoOk(props) {
    return (
      <FeedbackConIcona
        Icona={ICONE.OK}
        messaggio='Tutto OK'
        {...props}
        propsMessaggio={aggiungiColoreMessaggio(props, 'success.main')}
      />
    )
  },

  Errore(props) {
    return (
      <FeedbackConIcona
        Icona={ICONE.ERRORE}
        messaggio='Errore'
        {...props}
        propsMessaggio={aggiungiColoreMessaggio(props, 'error.main')}
      />
    )
  },

  Attenzione(props) {
    return (
      <FeedbackConIcona
        Icona={ICONE.ATTENZIONE}
        messaggio='Attenzione'
        {...props}
        propsMessaggio={aggiungiColoreMessaggio(props, 'warning.main')}
      />
    )
  },

  Info(props) {
    return (
      <FeedbackConIcona
        Icona={ICONE.INFO}
        messaggio='Informazioni'
        {...props}
        propsMessaggio={aggiungiColoreMessaggio(props, 'info.main')}
      />
    )
  },

  Custom(props) {
    return <FeedbackConIcona {...props} />
  }
}