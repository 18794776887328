import { BaseContenutoConTitoloEFallback } from 'layout'
import { BaseListaCoppieChiaveValore } from 'text'
import { formattaData } from 'utils'

export default function Documento(props) {
  const {
    documento: {
      dataRilascio,
      dataScadenza,
      numero,
      patenteCategoria,
      tipoDocumento: { second: tipoDocumento }
    }
  } = props

  return (
    <BaseContenutoConTitoloEFallback
      datiPresentiSe={numero || tipoDocumento}
      titolo='Documento'
      propsTitolo={{ colorato: true }}
    >
      <BaseListaCoppieChiaveValore
        coppie={{
          'Tipo': tipoDocumento,
          'Categoria patente': patenteCategoria,
          'Numero': numero,
          'Data di rilascio': formattaData(dataRilascio),
          'Data di scadenza': formattaData(dataScadenza)
        }}
      />
    </BaseContenutoConTitoloEFallback>
  )
}