import { useEffect, createContext, useContext, useState } from 'react'
import { func, node, objectOf, bool } from 'prop-types'
import { getFromLocalStorage, saveInLocalStorage, removeFromLocalStorage, capitalizza, deepMerge } from 'utils'
import { useRoutes } from '../../navigation/RoutesProvider'
import { useContextGenerico } from '../../util/ContextGenericoProvider'
import { TIPOLOGIE_PERMESSI_USER, useLogicaPermessiUser } from './permessiUser/LogicaPermessiUser'
import { MODULO_SHAPE } from './permessiUser/utilPermessiUser'
import useStorePermessiUser from './permessiUser/StorePermessiUser'
import useScadenzaSessione from './ScadenzaSessione'

const NESSUN_UTENTE = { token: '' }

const UserContext = createContext()
const UserUpdateContext = createContext()

UserProvider.propTypes = {
  children: node,

  // Oggetto che descrive i moduli e le azioni per i permessi user
  MODULI: objectOf(MODULO_SHAPE),

  // Funzione che prende i permessi e li manipola in qualche modo
  // Può essere utile in casi particolari, ad esempio per inserire
  // dei permessi nel codice che sovrascrivono quelli del server
  funzioneMappaturaPermessiUser: func,

  // Flag che indica se usare la nuova logica di gestione dei permessi dell'SSO 
  nuovaGestionePermessi: bool
}

function UserProvider(props) {
  const {
    children,
    MODULI,
    funzioneMappaturaPermessiUser,
    nuovaGestionePermessi
  } = props

  const {
    CHIAVI_STORAGE: { USER_INFO, DATA_ULTIMA_RICHIESTA }
  } = useContextGenerico()

  const [userInfo, setUserInfo] = useState(
    () => migraUserInfoSeServe(getFromLocalStorage(USER_INFO)) || NESSUN_UTENTE,
  )

  useEffect(
    () => saveInLocalStorage(USER_INFO, userInfo),
    [USER_INFO, userInfo]
  )

  const { routesSenzaComando } = useRoutes()

  const {
    permessiUserCompleti,
    findAdminCmd,
    isAdminAlmenoUnComando,
    getAdminCmd,
    canAdmin,
    cmdsAdmin,
    isUserStaff,
    impostaPermessiUserSenzaComando,
    eliminaPermessiUserSenzaComando,
    ...altreFunzioniPermessiUser
  } = useStorePermessiUser({
    funzioneMappaturaPermessiUser
  })

  const funzioniControlloPermessiUser = useLogicaPermessiUser({
    permessi: permessiUserCompleti,
    nuovaGestionePermessi
  })

  const {
    isSessioneScaduta,
    setIsSessioneScaduta
  } = useScadenzaSessione({
    isUserLoggato,
    massimoPeriodoInattivita_InSecondi: userInfo.massimoPeriodoInattivita
  })

  /* Metodi per ottenere informazioni sullo user */

  function isUserLoggato() {
    return userInfo && userInfo.token !== ''
  }

  function getLabelUser() {
    const { info: { nome, cognome, nickname } } = userInfo
    return `${nome}${cognome}` ? `${capitalizza(nome)} ${capitalizza(cognome)}` : nickname
  }

  function getRoutesLoggatoAttuali() {
    return routesSenzaComando
  }

  const letturaInfoUser = {
    userInfo,
    MODULI,
    findAdminCmd,
    isAdminAlmenoUnComando,
    getAdminCmd,
    canAdmin,
    cmdsAdmin,
    isUserStaff,
    isAdmin: isAdminAlmenoUnComando,
    ...funzioniControlloPermessiUser,
    nuovaGestionePermessi,
    isSessioneScaduta,
    isUserLoggato,
    getLabelUser,
    getRoutesLoggatoAttuali
  }

  /***********************************************/

  /* Metodi per modificare informazioni dello user */

  function updateUserInfo(userInfoDaUnire) {
    setUserInfo(userInfoAttuale => deepMerge(userInfoAttuale, userInfoDaUnire))
  }

  function handleLogin(userData) {
    const { user, lastLogins, stato, tokenScadenzaTs } = userData
    const {
      info: { password, ...info }, // Scarto il campo password (è sempre vuoto)
      is_staff,
      cmd, // Scarto perché è gestito da UserProviderConComando
      cmds,
      ...restUserInfo
    } = user

    setUserInfo({ ...restUserInfo, lastLogins, stato, tokenScadenzaTs, info })

    const ADM_PERMESSO = 'admin'
    const comandiAdmin = is_staff
      ? cmds
      : cmds.filter(cmd => cmd?.permessi.some(permesso => permesso.nome === ADM_PERMESSO && permesso.valore === TIPOLOGIE_PERMESSI_USER.LETTURA_E_SCRITTURA))
    impostaPermessiUserSenzaComando({ is_staff, comandiAdmin })

    setIsSessioneScaduta(false)
  }

  function handleLogout() {
    setUserInfo(NESSUN_UTENTE)
    eliminaPermessiUserSenzaComando()
    setIsSessioneScaduta(false)
    removeFromLocalStorage(DATA_ULTIMA_RICHIESTA)
  }

  function reloadLogin() {
    window.localStorage.clear()
    window.sessionStorage.clear()
    window.location.reload()
  }

  const modificaInfoUser = {
    ...altreFunzioniPermessiUser,
    setIsSessioneScaduta,
    nuovaGestionePermessi,
    updateUserInfo,
    handleLogin,
    handleLogout,
    reloadLogin
  }

  /*************************************************/

  return (
    <UserContext.Provider value={letturaInfoUser}>
      <UserUpdateContext.Provider value={modificaInfoUser}>
        {children}
      </UserUpdateContext.Provider>
    </UserContext.Provider>
  )
}

function useUser() {
  return useContext(UserContext)
}

function useUserUpdate() {
  return useContext(UserUpdateContext)
}

// Migra la vecchia struttura di userInfo a quella nuova
// Vecchia: { nickname, nome, cognome, token }
// Nuova: { info: { nickname, nome, cognome}, token }
function migraUserInfoSeServe(userInfo) {
  if (!userInfo) return null
  const { info, nickname, nome, cognome, token } = userInfo
  return info
    ? userInfo
    : { info: { nickname, nome, cognome }, token }
}

export {
  UserContext,
  UserUpdateContext,
  UserProvider,
  useUser,
  useUserUpdate
}