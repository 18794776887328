import { func, number, bool } from 'prop-types'
import { Pagination } from '@mui/material'
import { BaseButtons, BaseForm, BaseIconButtons, BaseTextInput } from 'inputs'
import { BaseGridLayout, BaseModal, useModal } from 'layout'
import { BaseTesto } from 'text'

BasePaginazione.propTypes = {
  paginaCorrente: number.isRequired,
  totaleElementi: number.isRequired,
  elementiPerPagina: number.isRequired,
  onCambioPagina: func.isRequired,
  onCambioElementiPerPagina: func.isRequired,
  nascondiTotaleElementi: bool,
  visualizzazioneVerticale: bool,
  nascondiOpzioni: bool
}

export default function BasePaginazione(props) {
  const {
    paginaCorrente,
    totaleElementi,
    elementiPerPagina,
    onCambioPagina,
    onCambioElementiPerPagina,
    nascondiTotaleElementi,
    visualizzazioneVerticale,
    nascondiOpzioni,
    ...restProps
  } = props

  const totalePagine = Math.ceil(totaleElementi / elementiPerPagina)

  const ultimoElemento_PaginaPrecedente = (paginaCorrente - 1) * elementiPerPagina
  const primo = (totalePagine === 0) ? 0 : Math.max(1, ultimoElemento_PaginaPrecedente + 1)
  const ultimo = Math.min(ultimoElemento_PaginaPrecedente + elementiPerPagina, totaleElementi)

  const { modalRef, openModal, closeModal } = useModal()

  return (
    <div>
      <BaseGridLayout alignCenter {...(visualizzazioneVerticale && { disposizioneRighe: [1, 2] })}>
        <BaseTesto color='textSecondary' variant='subtitle2'>
          Risultati da {primo} a {ultimo} {!nascondiTotaleElementi && `(totali: ${totaleElementi})`}
        </BaseTesto>

        <Pagination
          count={totalePagine}
          page={paginaCorrente}
          onChange={(_, nuovaPagina) => onCambioPagina(nuovaPagina)}
          color='primary'
          size='small'
          shape='rounded'
          {...restProps}
        />

        <BaseIconButtons.Opzioni
          onClick={openModal}
          sx={{ p: 0 }}
          hidden={nascondiOpzioni}
        />
      </BaseGridLayout>

      <BaseModal ref={modalRef} titolo='Opzioni paginazione'>
        <ModificaNumeroElementiPerPagina
          elementiPerPagina={elementiPerPagina}
          onCambioElementiPerPagina={onCambioElementiPerPagina}
          closeModal={closeModal}
        />
      </BaseModal>
    </div>
  )
}



function ModificaNumeroElementiPerPagina(props) {
  const {
    elementiPerPagina,
    onCambioElementiPerPagina,
    closeModal
  } = props

  function conferma(valoriForm) {
    onCambioElementiPerPagina(valoriForm.elementiPerPagina)
    closeModal()
  }

  return (
    <BaseForm
      defaultValues={{ elementiPerPagina }}
      onSubmit={conferma}
    >
      <BaseGridLayout alignCenter justifyCenter>
        <BaseTextInput
          label='Numero risultati per pagina'
          name='elementiPerPagina'
          larghezza='lungo'
          required type='numeroPositivo'
          validazione={{
            validate: {
              interoNonZero: value => value > 0 && Number.isInteger(value)
            }
          }}
          messaggiErrore={{
            interoNonZero: 'Inserire un numero intero maggiore di zero'
          }}
        />

        <BaseButtons.Conferma submit />
      </BaseGridLayout>
    </BaseForm>
  )
}