import { useMemo, useState } from 'react'
import { bool, node, object, objectOf, oneOf, shape, string } from 'prop-types'
import { HashRouter as ReactRouter } from 'react-router-dom'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TIPI_MESSAGGI } from 'feedback'
import { BaseDrawerLayout, BaseFooterLayout } from 'layout'
import { PadreCondizionale } from 'utils'
import useCurrentTheme from '../utils/hooks/useCurrentTheme'
import { ElenchiProvider } from './form/ElenchiProvider'
import BarraSuperiore from './layoutGenerale/BarraSuperiore'
import DrawerNavigazione from './layoutGenerale/DrawerNavigazione'
import Footer from './layoutGenerale/Footer'
import RibbonAlfa from './layoutGenerale/RibbonAlfa'
import AppRouter from './navigation/AppRouter'
import { RoutesProvider } from './navigation/RoutesProvider'
import { ApiRequestProvider } from './network/ApiRequestProvider'
import EseguiLogoutSeSessioneScaduta from './userManagement/EseguiLogoutSeSessioneScaduta'
import FeedbackRichiestaPermessiUser from './userManagement/FeedbackRichiestaPermessiUser'
import LoginConCredenziali from './userManagement/LoginConCredenziali'
import LoginConSso from './userManagement/LoginConSso'
import { SsoProvider } from './userManagement/SsoProvider'
import { UserProvider } from './userManagement/userProvider/UserProvider'
import UserProviderConComando from './userManagement/userProvider/UserProviderConComando'
import UserProviderConPermessiRoutes from './userManagement/userProvider/UserProviderConPermessiRoutes'
import { ContextGenericoProvider } from './util/ContextGenericoProvider'
import ControllaVersioneAggiornata from './util/ControllaVersioneAggiornata'
import CustomThemeProvider from './util/CustomThemeProvider'
import ErrorBoundary from './util/ErrorBoundary'

/**
 * @typedef {import("../common/incloudApps/AppSkeleton").AppSkeletonProps} AppSkeletonProps
 * @typedef {import("../common/incloudApps/AppSkeleton").AppSkeleton} AppSkeleton
 * @typedef {import('../common/incloudApps/AppSkeleton').MainAppLayoutProps} MainAppLayoutProps
 * @typedef {import('../common/incloudApps/AppSkeleton').MainAppLayout} MainAppLayout
 */

/**
 * @type {AppSkeletonProps}
 */
AppSkeleton.propTypes = {
  // Personalizza i dati ritornati dal RoutesProvider
  propsRoutesProvider: object,

  // Personalizza le funzioni che implementano le richieste all'api
  propsApiRequestProvider: object,

  // Personalizza i componenti del layout generale (barra superiore, drawer, footer)
  barraSuperiore: node,
  propsBarraSuperiore: object,
  drawerNavigazione: node,
  propsDrawerNavigazione: object,
  propsDrawerLayout: object,
  mostraRibbonAlfa: bool,
  footer: node,
  nascondiScheletro: bool,

  // Chiave usata nel codice per identificare l'applicazione
  nomeApp: string.isRequired,

  // Nome dell'applicazione che viene mostrato nella barra superiore e nel footer
  titoloApp: string.isRequired,

  // La data in cui l'applicazione viene buildata
  // Può essere calcolata automaticamente con preval.macro
  dataBuild: string.isRequired,

  // Hash di git che identifica la versione dell'applicazione 
  // Può essere passato come variabile d'ambiente al comando di build
  versione: string.isRequired,

  // URL da chiamare per ottenere un JSON con informazioni sulla build
  // Il JSON deve contenere un oggetto con queste proprietà:
  // - APP_LATEST_VERSION
  //   Id della versione più aggiornata dell'app. Se è diverso da quello
  //   passato tramite prop, dico all'utente di ricaricare la pagina
  urlBuildInfo: string,

  // Messaggi custom che possono essere usati con useMessaggi
  MESSAGGI: objectOf(shape({
    key: string.isRequired,
    msg: string.isRequired,
    tipo: oneOf(Object.values(TIPI_MESSAGGI)).isRequired
  })),

  // Oggetto che permette di customizzare le chiavi per session e local storage
  CHIAVI_STORAGE: objectOf(string),

  isLoading: bool,

  // Tutte le altre props passate sono passate al ContextGenerico
  // e sono accessibili a tutti i componenti dell'applicazione
}

/**
 * 
 * @param {AppSkeletonProps} props 
 * @type {AppSkeleton}
 */
export default function AppSkeleton(props) {
  const {
    // Props RoutesProvider
    CHIAVI_ROUTES,
    propsRouteHome,
    ROUTES_NON_LOGGATO,
    ROUTES_SENZA_COMANDO,
    ROUTES_CON_COMANDO,
    homeSenzaComando,
    homeConComando,
    componenteLogin,
    URL_SSO_LOGIN = '/login/#/',
    URL_SSO = '/sso/#/',
    URL_SSO_BE = '/sso/sso/api',
    SSO_COOKIE_APPEND_NAME,
    CONNET_BASE_URL,
    noBreadcrumb,
    propsRoutesProvider,

    // Props ApiRequestProvider
    ROTTA_BASE_API,
    URL_SERVER,
    URL_SERVER_ENRICO,
    URL_SERVER_LUCA,
    MAPPA_ENDPOINTS,
    OPERAZIONI,
    ENTITA,
    OPERAZIONI_CONFIG,
    propsApiRequestProvider,

    // Componenti layout generale
    barraSuperiore,
    propsBarraSuperiore,
    drawerNavigazione,
    propsDrawerNavigazione,
    propsDrawerLayout,
    mostraRibbonAlfa,
    footer,
    nascondiScheletro,

    // Props UserProvider
    MODULI,
    funzioneMappaturaPermessiUser,
    nonFareRichiestaPermessi,

    // Props ElenchiProvider
    ELENCHI_CONFIG,

    // Provider generico 
    MainAppProvider,

    // Props ContextGenericoProvider
    nomeApp,
    titoloApp,
    dataBuild,
    versione,
    urlBuildInfo,
    MESSAGGI,
    CHIAVI_STORAGE,
    theme,
    isLoading = false,
    ...restProps
  } = props

  const ssoAbilitato = Boolean(URL_SSO_LOGIN)

  const componenteLoginDefinitivo = (
    componenteLogin
    || (ssoAbilitato && <LoginConSso />)
    || <LoginConCredenziali />
  )

  return (
    <ErrorBoundary>
      <DndProvider backend={HTML5Backend}>
        <CustomThemeProvider theme={theme}>
          <ContextGenericoProvider
            nomeApp={nomeApp}
            titoloApp={titoloApp}
            dataBuild={dataBuild}
            versione={versione}
            versioneLibreria={process.env.LIBRARY_GIT_COMMIT_ID}
            urlBuildInfo={urlBuildInfo}
            MESSAGGI={MESSAGGI}
            CHIAVI_STORAGE={CHIAVI_STORAGE}
            {...restProps}
          >
            <RoutesProvider
              CHIAVI_ROUTES={CHIAVI_ROUTES}
              propsRouteHome={propsRouteHome}
              ROUTES_NON_LOGGATO={ROUTES_NON_LOGGATO}
              ROUTES_SENZA_COMANDO={ROUTES_SENZA_COMANDO}
              ROUTES_CON_COMANDO={ROUTES_CON_COMANDO}
              homeSenzaComando={homeSenzaComando}
              homeConComando={homeConComando}
              componenteLogin={componenteLoginDefinitivo}
              URL_SSO_LOGIN={URL_SSO_LOGIN}
              URL_SSO={URL_SSO}
              CONNET_BASE_URL={CONNET_BASE_URL}
              noBreadcrumb={noBreadcrumb}
              {...propsRoutesProvider}
            >
              <ApiRequestProvider
                ROTTA_BASE_API={ROTTA_BASE_API}
                URL_SERVER={URL_SERVER}
                URL_SERVER_ENRICO={URL_SERVER_ENRICO}
                URL_SERVER_LUCA={URL_SERVER_LUCA}
                MAPPA_ENDPOINTS={MAPPA_ENDPOINTS}
                OPERAZIONI={OPERAZIONI}
                ENTITA={ENTITA}
                URL_SSO_BE={URL_SSO_BE}
                OPERAZIONI_CONFIG={OPERAZIONI_CONFIG}
                {...propsApiRequestProvider}
              >
                {/*
                Uso un pattern stile decorator per lo UserProvider
                In pratica degli UserProvider più specifici possono essere inseriti
                più in basso nell'albero dei componenti, in modo da aggiungere
                nuovi dati e/o overridare alcuni dati ritornati dallo UserProvider
                base, senza cambiare l'api per i componenti sotto
              */}
                <UserProvider
                  MODULI={MODULI}
                  funzioneMappaturaPermessiUser={funzioneMappaturaPermessiUser}
                  nuovaGestionePermessi={ssoAbilitato}
                >
                  <PadreCondizionale
                    componente={SsoProvider}
                    abilitato={ssoAbilitato}
                    loginEndPoint={URL_SSO_LOGIN}
                    titoloApp={titoloApp}
                    nomeApp={nomeApp}
                    cookieAppendName={SSO_COOKIE_APPEND_NAME}
                  >
                    <ReactRouter>
                      <PadreCondizionale
                        componente={UserProviderConComando}
                        abilitato={Boolean(ROUTES_CON_COMANDO)}
                        nonFareRichiestaPermessi={nonFareRichiestaPermessi}
                      >
                        <UserProviderConPermessiRoutes>
                          <ElenchiProvider ELENCHI_CONFIG={ELENCHI_CONFIG}>
                            <PadreCondizionale
                              componente={MainAppProvider}
                              abilitato={Boolean(MainAppProvider)}
                            >
                              <MainAppLayout
                                isLoading={isLoading}
                                barraSuperiore={barraSuperiore}
                                propsBarraSuperiore={propsBarraSuperiore}
                                drawerNavigazione={drawerNavigazione}
                                propsDrawerNavigazione={propsDrawerNavigazione}
                                mostraRibbonAlfa={mostraRibbonAlfa}
                                urlBuildInfo={urlBuildInfo}
                                footer={footer}
                                propsDrawerLayout={propsDrawerLayout}
                                nascondiScheletro={nascondiScheletro}
                              />
                            </PadreCondizionale>
                          </ElenchiProvider>
                        </UserProviderConPermessiRoutes>
                      </PadreCondizionale>
                    </ReactRouter>
                  </PadreCondizionale>
                </UserProvider>
              </ApiRequestProvider>
            </RoutesProvider>
          </ContextGenericoProvider>
        </CustomThemeProvider>
      </DndProvider>
    </ErrorBoundary>
  )
}


/**
 * 
 * @param {MainAppLayoutProps} props 
 * @type {MainAppLayout}
 */
function MainAppLayout(props) {
  const {
    isLoading,
    barraSuperiore,
    propsBarraSuperiore,
    drawerNavigazione,
    propsDrawerNavigazione,
    mostraRibbonAlfa,
    urlBuildInfo,
    footer,
    propsDrawerLayout,
    nascondiScheletro,
  } = props

  const scheletroVisibile_Prop = !nascondiScheletro

  const { isAlternativeTheme, theme } = useCurrentTheme()
  const [barraVisibile, setBarraVisibile] = useState(scheletroVisibile_Prop)
  const [drawerVisibile, setDrawerVisibile] = useState(scheletroVisibile_Prop)
  const [footerVisibile, setFooterVisibile] = useState(scheletroVisibile_Prop)
  const [wrapperMainContentVisibile, setWrapperMainContentVisibile] = useState(scheletroVisibile_Prop)

  function setScheletroVisibile(visibile) {
    setBarraVisibile(visibile)
    setDrawerVisibile(visibile)
    setFooterVisibile(visibile)
    setWrapperMainContentVisibile(visibile)
  }

  const contextInfo = {
    setBarraVisibile,
    setDrawerVisibile,
    setFooterVisibile,
    setWrapperMainContentVisibile,
    setScheletroVisibile
  }

  function sezione(flagVisibilita, componenteCustom, componenteDefault) {
    if (!flagVisibilita) return null
    return componenteCustom === undefined ? componenteDefault : componenteCustom
  }

  const drawerWidth = useMemo(() => {
    return isAlternativeTheme() ? { whenClosed: 7, whenOpen: 32 } : { whenClosed: 7, whenOpen: 28 }
  }, [])

  return (
    <BaseDrawerLayout
      isLoading={isLoading}
      appBarContent={sezione(barraVisibile, barraSuperiore, <BarraSuperiore {...propsBarraSuperiore} />)}
      drawerWidth={drawerWidth}
      drawerContent={sezione(drawerVisibile, drawerNavigazione, <DrawerNavigazione {...propsDrawerNavigazione} />)}
      mainContent={
        <BaseFooterLayout
          mainContent={
            <>
              {mostraRibbonAlfa && <RibbonAlfa />}
              
              {/* Per ora nascosto per evitare incomprensioni da parte degli utenti */}
              {false && urlBuildInfo && <ControllaVersioneAggiornata />}

              <EseguiLogoutSeSessioneScaduta />

              {/* 
                Non faccio vedere il contenuto principale dell'applicazione
                se sono in attesa di ricevere i permessi user dal server
              */}
              <FeedbackRichiestaPermessiUser>
                <AppRouter />
              </FeedbackRichiestaPermessiUser>
            </>
          }
          footer={sezione(footerVisibile, footer, <Footer />)}
        />
      }
      wrapperMainContentVisibile={wrapperMainContentVisibile}
      contextInfo={contextInfo}
      appBarColor={isAlternativeTheme() ? 'primary.main' : undefined}
      appBarElevation={isAlternativeTheme() ? 0 : undefined}
      mainContentBackground={theme.palette.background.default}
      {...propsDrawerLayout}
    />
  )
}