import { useImperativeHandle } from 'react'
import { useComunicazioneConIframe } from 'utils'

const TIPI_MESSAGGI_RICEVUTI_DA_IFRAME = {
  INVIO_MODEL: 'invioModel',
  NAVIGAZIONE_RIF: 'navigazioneRif',
  INVIO_DOCUMENTAZIONE: 'invioDocumentazione',
  NAVIGAZIONE_INPUT: 'navigazioneInput'
}

const TIPI_MESSAGGI_INVIATI_AD_IFRAME = {
  RICHIESTA_MODEL: 'richiestaModel',
  RICHIESTA_DOCUMENTAZIONE: 'richiestaDocumentazione',
  MOSTRA_INPUT: 'mostraInput'
}

export default function HtmlFormIframe(props) {
  const {
    iframeSrcDoc,
    htmlFormRef,
    navigaVersoRif,
    navigaVersoInput
  } = props

  /* Ricezione messaggi da iframe (mandati di sua iniziativa) */

  function onMessaggioDaIframe(data) {
    let { tipo, contenuto } = data

    if (navigaVersoRif) {
      const navigazioneRif = contenuto?.navigazioneRif
      const isCreazione = contenuto?.isCreazione
      if (tipo === TIPI_MESSAGGI_RICEVUTI_DA_IFRAME.NAVIGAZIONE_RIF && navigazioneRif) {
        console.log('app esterna - navigazione verso rif', navigazioneRif, '- isCreazione', isCreazione)
        navigaVersoRif(navigazioneRif, isCreazione)
        return
      }
    }

    if (navigaVersoInput) {
      const inputName = contenuto?.inputName
      if (tipo === TIPI_MESSAGGI_RICEVUTI_DA_IFRAME.NAVIGAZIONE_INPUT && inputName) {
        console.log('app esterna - navigazione verso input con name', inputName)
        navigaVersoInput(inputName)
        return
      }
    }
  }

  /************************************************************/

  /**** Comunicazione richiesta-risposta con iframe ****/

  const {
    iframeRef,
    inviaRichiestaIframe
  } = useComunicazioneConIframe({
    onMessaggioDaIframe
  })

  async function getParametriHtmlForm(opzioni) {
    console.log('app esterna - richiesta model')
    const { ok, data, error } = await inviaRichiestaIframe(
      TIPI_MESSAGGI_INVIATI_AD_IFRAME.RICHIESTA_MODEL,
      opzioni // Oggetto { soloSeModificato }
    )

    if (ok) {
      let { tipo, contenuto } = data
      if (tipo === TIPI_MESSAGGI_RICEVUTI_DA_IFRAME.INVIO_MODEL && contenuto) {
        console.log('app esterna - ricevuto model', contenuto)
        return contenuto // Oggetto { model, rifs, config }
      }
    }

    console.log('app esterna - errore in richiesta model', error)
  }

  async function getDocumentazioneHtmlForm(opzioni) {
    console.log('app esterna - richiesta documentazione')
    const { ok, data, error } = await inviaRichiestaIframe(
      TIPI_MESSAGGI_INVIATI_AD_IFRAME.RICHIESTA_DOCUMENTAZIONE,
      opzioni // Oggetto { optionsDocumentationAmbito }
    )

    if (ok) {
      let { tipo, contenuto } = data
      if (tipo === TIPI_MESSAGGI_RICEVUTI_DA_IFRAME.INVIO_DOCUMENTAZIONE && contenuto) {
        console.log('app esterna - ricevuta documentazione', contenuto)
        return contenuto // Oggetto { optionsDocumentation: [{ ambito, label, html }, ...] }
      }
    }

    console.log('app esterna - errore in richiesta documentazione', error)
  }

  function mostraInput(opzioni) {
    console.log('app esterna - richiesta di mostrare input')
    inviaRichiestaIframe(
      TIPI_MESSAGGI_INVIATI_AD_IFRAME.MOSTRA_INPUT,
      opzioni // Oggetto { inputName }
    )
  }

  useImperativeHandle(htmlFormRef, () => ({
    getParametriHtmlForm,
    getDocumentazioneHtmlForm,
    mostraInput
  }))

  /*****************************************************/

  if (!iframeSrcDoc) return null

  return (
    <iframe
      srcDoc={iframeSrcDoc}
      ref={iframeRef}
      style={{
        padding: 8,
        border: 0,
        width: '100%',
        height: '100%'
      }}
    />
  )
}