import { any, string, bool, object, oneOf, oneOfType, number, shape, func, instanceOf, objectOf } from 'prop-types'
import { useFormContext } from 'react-hook-form'
import { creaFunzioneUnica, getPropertyWithPath, haAlmenoUnaChiave, sx } from 'utils'
// import { useReadOnly } from '../util/ReadOnlyProvider'

const numeroORegola = oneOfType([number, shape({ value: number, message: string })])
useInputGenerico.propTypes = {
  name: string,
  label: string,
  larghezza: oneOfType([
    number,
    oneOf(['fullWidth', 'lungo', 'medio', 'corto', 'cortissimo'])
  ]),
  messaggioAiuto: string,
  nonUsareHookForm: bool,
  risultatiUseForm: object,
  // Oggetto validazione di hook form
  validazione: shape({
    required: string,
    max: numeroORegola,
    min: numeroORegola,
    minLength: numeroORegola,
    maxLength: numeroORegola,
    pattern: shape({ value: instanceOf(RegExp), message: string }),
    setValueAs: func,
    validate: objectOf(func)
  }),
  required: bool,
  // Messaggi errore per validazioni custom
  // Le chiavi devono essere le stesse dell'oggetto validate all'interno della validazione
  messaggiErrore: objectOf(string),
  readOnly: bool,
  hidden: bool,
  defaultValue: any,
  value: any,
  onChange: func,
  helperText: string,
  error: bool,
}

useInputGenerico.propTypesInterne = {
  componenteControllato: bool,
  widthType: oneOf(['width', 'minWidth', 'maxWidth']),
  underlyingComponent: oneOf(['textField', 'checkbox_o_radio', 'subForm'])
}

const LARGHEZZE = {
  'lungo': 16,
  'medio': 12,
  'corto': 8,
  'cortissimo': 6
}

export default function useInputGenerico(props) {
  const {
    readOnly: readOnly_Prop,
    disabled: disabled_Prop,
    ...tutteAltreProps
  } = props

  const risultatiUseForm_Context = useFormContext()

  // Avevo provato a disabilitare brutalmente tutti gli input se più in alto è presente
  // un ReadOnlyProvider che torna readOnly = true. Per ora lo commento perché rischia 
  // di disabilitare per sbaglio le cose (ad esempio i campi di una form di ricerca 
  // devono essere comunque usabili se un modulo è in sola lettura)
  // const readOnly_Context = useReadOnly()

  const disabled = disabled_Prop ?? readOnly_Prop ?? risultatiUseForm_Context?.readOnly //?? readOnly_Context?.readOnly
  // Se disabled, assicuro che l'input non faccia niente in risposta ai cambiamenti
  // Questa è una misura di estrema sicurezza, perché ogni input implementa il
  // disabled in modo da bloccare l'interazione da parte dell'utente. Ma teoricamente 
  // uno potrebbe modificare l'html per togliere il disabled e interagire col campo
  const propsSeDisabled = disabled ? {
    onBlur: () => {},
    onChange: () => {}
  } : {}

  const {
    styleProps,
    nonStyleProps,
    isTextField
  } = getStyleProps(tutteAltreProps, risultatiUseForm_Context?.larghezzaInputs)

  const {
    nonUsareHookForm,
    risultatiUseForm: risultatiUseForm_Prop,
    ...restProps
  } = nonStyleProps

  const risultatiUseForm = risultatiUseForm_Prop || risultatiUseForm_Context
  const hookFormNecessario = !nonUsareHookForm && Boolean(risultatiUseForm)

  const propsDefinitive = hookFormNecessario
    ? getPropsConHookForm(restProps, risultatiUseForm)
    : getPropsSenzaHookForm(restProps)

  return {
    disabled,
    ...propsDefinitive,
    ...styleProps,
    ...propsSeDisabled,
    // Aggiungo qui lo stile per i textField in stato warning
    // Non lo posso fare in getStyleProps() perché viene chiamata prima
    // di getPropsConHookForm(), dove avviene il calcolo degli errori
    ...(isTextField && propsDefinitive.warning && {
      color: 'warning',
      ...sx(propsDefinitive, {
        '& .MuiOutlinedInput-root': {
          '& .MuiOutlinedInput-notchedOutline': { borderColor: 'warning.main' },
        },
        '& .MuiOutlinedInput-root:hover': {
          '& .MuiOutlinedInput-notchedOutline': { borderColor: 'warning.main' }
        },
        '& .MuiOutlinedInput-root.Mui-focused': {
          '& .MuiOutlinedInput-notchedOutline': { borderColor: 'warning.main' }
        },
        '& .MuiInputLabel-root': { color: 'warning.main' },
        '& .MuiFormHelperText-root': { color: 'warning.main' }
      })
    }),
    // Evito di passare il booleano "warning" al TextField, tanto non lo riconosce
    ...(isTextField && { warning: undefined }) 
  }
}

function getStyleProps(props, larghezzaInputs) {
  const {
    underlyingComponent = 'textField',
    style,
    // Prop per textField
    margin = 'normal',
    size = 'small',
    variant = 'outlined',
    larghezza: larghezza_Prop,
    widthType = 'minWidth',
    // Prop per checkbox o radio
    color = 'primary',
    hidden,
    ...nonStyleProps
  } = props

  let styleDefinitivo = {}
  let otherStyleProps = {}

  function gestioneLarghezza(impostaPropFullWidth) {
    const larghezza = larghezza_Prop ?? larghezzaInputs
    if (larghezza === undefined) return
    if (larghezza === 'fullWidth') {
      if (impostaPropFullWidth) otherStyleProps.fullWidth = true
      else styleDefinitivo[widthType] = '100%'
      return
    }
    const larghezzaDefinitiva = (typeof larghezza === 'number') ? larghezza : LARGHEZZE[larghezza]
    styleDefinitivo[widthType] = `${larghezzaDefinitiva}rem`
  }

  switch (underlyingComponent) {
    case 'textField':
      otherStyleProps = { margin, size, variant }
      if (hidden) styleDefinitivo.display = 'none'
      gestioneLarghezza(true)
      break
    case 'checkbox_o_radio':
      otherStyleProps = { color, hidden }
      break
    case 'subForm':
      gestioneLarghezza()
      styleDefinitivo.boxSizing = 'border-box'
      break
    default:
      otherStyleProps = { hidden }
      break
  }

  return {
    nonStyleProps,
    styleProps: {
      ...otherStyleProps,
      style: {
        ...styleDefinitivo,
        ...style
      }
    },
    isTextField: underlyingComponent === 'textField'
  }
}

function getPropsSenzaHookForm(restProps) {
  const {
    messaggioAiuto,
    // Tolgo alcune prop che potrebbero essere impostate,
    // ma che non hanno senso senza hook form
    validazione,
    messaggiErrore,
    componenteControllato,
    ...propsDefinitive
  } = restProps

  return {
    helperText: messaggioAiuto,
    ...propsDefinitive
  }
}

function getPropsConHookForm(restProps, risultatiUseForm) {
  const {
    messaggioAiuto,
    componenteControllato = false,
    validazione = {},
    messaggiErrore: messaggiErroreCustom,
    onBlur: onBlur_Prop,
    onChange: onChange_Prop,
    ...propsInput
  } = restProps

  const {
    name,
    required = false
  } = restProps

  const {
    register,
    control,
    formState: { errors },
    onBlur: onBlur_Context,
    onChange: onChange_Context,
    shouldUnregister,
    messaggiValidazioneEsterna
  } = risultatiUseForm

  // Se viene passata la prop booleana required, imposta l'apposita regola di validazione
  const validazioneCompleta = required
    ? { required: 'Campo obbligatorio', ...validazione }
    : { ...validazione }

  // Imposta le prop per registrare l'input, a seconda della tipologia (controllato o non controllato)
  let propsPerRegistrare
  let onBlur_Register = () => {}
  let onChange_Register = () => {}
  if (componenteControllato) {
    // Nei componenti controllati usare l'hook useControllerSeServe
    // passandogli i risultati di useInputGenerico, così
    // control e rules saranno passati a useController
    // Avrei voluto chiamare useController direttamente qui, 
    // ma le regole degli hooks non lo permettono
    propsPerRegistrare = { control, rules: validazioneCompleta, shouldUnregister }
  } else {
    // Componente non controllato
    const risultatiRegister = register(name, { ...validazioneCompleta, shouldUnregister })
    propsPerRegistrare = { inputRef: risultatiRegister.ref }
    onBlur_Register = risultatiRegister.onBlur
    onChange_Register = risultatiRegister.onChange
  }

  // Controlla se ci sono errori o warning in questo campo ed imposta l'helper text
  // Se viene passato tramite il context un oggetto messaggiValidazioneEsterna,
  // gli errori di hook form sono ignorati (anche se messaggiValidazioneEsterna = {})
  let ciSonoErrori
  let ciSonoWarning
  let helperText = messaggioAiuto
  if (messaggiValidazioneEsterna) {
    const erroriCampoCorrente = messaggiValidazioneEsterna[name]
    if (Array.isArray(erroriCampoCorrente)) {
      const listaErrori = erroriCampoCorrente.filter(({ livello }) => livello === 'ERROR')
      const listaWarning = erroriCampoCorrente.filter(({ livello }) => livello === 'WARNING')
      ciSonoErrori = listaErrori.length > 0
      ciSonoWarning = listaWarning.length > 0
      if (ciSonoErrori) helperText = listaErrori[0].msg
      else if (ciSonoWarning) helperText = listaWarning[0].msg
    }
  } else {
    const erroreCampoCorrente = getPropertyWithPath(name, errors) || {}
    ciSonoErrori = haAlmenoUnaChiave(erroreCampoCorrente)
    if (ciSonoErrori) {
      helperText = erroreCampoCorrente.message
        || messaggiErroreCustom?.[erroreCampoCorrente.type]
        || 'Hai dimenticato di passare un messaggio di errore'
    }
  }

  // Metti insieme tutte le prop
  return {
    error: ciSonoErrori,
    warning: ciSonoWarning,
    helperText,
    onBlur: creaFunzioneUnica(onBlur_Register, onBlur_Prop, onBlur_Context),
    onChange: creaFunzioneUnica(onChange_Register, onChange_Prop, onChange_Context),
    ...propsPerRegistrare,
    ...propsInput
  }
}