import { forwardRef, useRef } from 'react'
import { number, string, func, object, oneOf, oneOfType, bool } from 'prop-types'
import { CircularProgress, Box } from '@mui/material'
import { BaseToasts } from 'feedback'
import { BaseIconButtons } from 'inputs'
import { BaseGridLayout } from 'layout'
import { BaseLink } from 'navigation'
import { abbreviaStringaSeServe, findMimeTypeWith, MIME_TYPES_VALUES } from 'utils'

export const BaseDownloadLink = forwardRef(function BaseDownloadLink(props, toastRefPassatoDalPadre) {
  const {
    nomeFile,
    mimetype,
    onClick,
    toastProps,
    inAttesa,
    nascondiIconaFormatoFile,
    nascondiNomeFile,
    maxLunghezzaNomeFileMostrato,
    visualizzaFile: visualizzaFile_PassataDaFuori,
    gridProps,
    ...baseLinkProps
  } = props

  const toastRef = useRef()

  function scaricaFile(event) {
    // Se l'utente clicca sul download, non faccio scattare altri
    // eventuali onClick oltre a quello esplicitamente passato 
    event.stopPropagation()
    onClick(event)

    if (!toastRefPassatoDalPadre) {
      toastRef?.current?.mostraToast()
    }
  }

  let mimetypeDefinitivo = mimetype
  if (typeof mimetype === 'string') {
    mimetypeDefinitivo = findMimeTypeWith('type', mimetype)
  }
  const iconaFormatoFile = mimetypeDefinitivo?.icona

  function visualizzaFile(event) {
    event.stopPropagation()
    visualizzaFile_PassataDaFuori()
  }

  return (
    <>
      <BaseGridLayout alignCenter noWrap {...gridProps}>
        <BaseLink
          onClick={scaricaFile}
          component='span'
          underline='none'
          {...baseLinkProps}
        >
          <BaseGridLayout
            alignCenter
            propsGridItem={iconaFormatoFile && { sx: { pb: 0 } }}
            noWrap
          >
            {!nascondiIconaFormatoFile && iconaFormatoFile &&
              <img
                src={iconaFormatoFile}
                height={30}
                alt=''
              />
            }

            {!nascondiNomeFile &&
              <Box component='span' sx={{ cursor: 'pointer' }}>
                {maxLunghezzaNomeFileMostrato
                  ? abbreviaStringaSeServe(nomeFile, maxLunghezzaNomeFileMostrato)
                  : nomeFile
                }
              </Box>
            }

            {inAttesa ? (
              <CircularProgress
                color='secondary' size={25}
                style={{ position: 'relative', left: -8 }}
              />
            ) : (
              <BaseIconButtons.Scarica />
            )}
          </BaseGridLayout>
        </BaseLink>

        {visualizzaFile_PassataDaFuori &&
          <BaseIconButtons.Visibile
            contenutoTooltip='Visualizza'
            onClick={visualizzaFile}
            iconProps={{ style: { color: 'black' } }}
          />
        }
      </BaseGridLayout>

      <BaseToasts.Download
        ref={toastRefPassatoDalPadre || toastRef}
        {...toastProps}
      />
    </>
  )
})

BaseDownloadLink.propTypes = {
  nomeFile: string.isRequired,
  mimetype: oneOfType([string, oneOf(MIME_TYPES_VALUES)]),
  onClick: func.isRequired,
  toastProps: object,
  inAttesa: bool,
  nascondiIconaFormatoFile: bool,
  nascondiNomeFile: bool,
  maxLunghezzaNomeFileMostrato: number,
  visualizzaFile: func,
  gridProps: object
}