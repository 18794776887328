import { memo } from 'react'
import { bool, node, object } from 'prop-types'
import { BaseTooltip } from 'feedback'
import { BaseTesto, BaseTestoConRef } from 'text'
import { deepEquals } from 'utils'

CellaSingola.propTypes = {
  contenutoCella: node,
  textEllipsis: bool,
  style: object
}

const PLACEHOLDER = '$_$'

function CellaSingola(props) {
  const {
    contenutoCella,
    textEllipsis,
    style,
    ...propsBaseTesto
  } = props

  let contenutoCellaCorretto = contenutoCella
  if (contenutoCella === '' || contenutoCella === undefined || contenutoCella === null) {
    contenutoCellaCorretto = PLACEHOLDER
  }

  let styleCompleto = { ...style }
  if (contenutoCellaCorretto === PLACEHOLDER) {
    styleCompleto = {
      ...styleCompleto,
      color: 'rgba(0,0,0,0)',
      userSelect: 'none'
    }
  }

  if (textEllipsis) {
    if (contenutoCellaCorretto === PLACEHOLDER) {
      return (
        <BaseTesto {...propsBaseTesto} style={styleCompleto}>
          {PLACEHOLDER}
        </BaseTesto>
      )
    }

    styleCompleto = {
      ...styleCompleto,
      cursor: 'zoom-in',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }

    return (
      <BaseTooltip contenuto={contenutoCellaCorretto}>
        <BaseTestoConRef {...propsBaseTesto} style={styleCompleto}>
          {contenutoCellaCorretto}
        </BaseTestoConRef>
      </BaseTooltip>
    )
  }

  return (
    <BaseTesto {...propsBaseTesto} style={styleCompleto}>
      {contenutoCellaCorretto}
    </BaseTesto>
  )
}

export default memo(CellaSingola, deepEquals)