import { Chip } from '@mui/material'
import { BaseTooltip } from 'feedback'
import { isTemplateHtmlForm } from '../utilDocumenti'

export default function BadgeHtmlForm(props) {
  const { template, ...rest } = props
  
  if (!template || !isTemplateHtmlForm(template)) {
    return null
  } 
  
  return (
    <BaseTooltip contenuto='Schermata di compilazione dei dati che rispecchia il documento'>
      <Chip size='small' label='Compilazione contestuale' color='info' {...rest} />
    </BaseTooltip>
  )
}