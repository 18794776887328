import { useState } from 'react'
import { object } from 'prop-types'
import { TextField } from '@mui/material'
import { DesktopTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { it } from 'date-fns/locale'
import { convertTimeStringToDate } from 'utils'
import useControllerSeServe from './hooks/ControllerSeServeHook'
import useInputGenerico from './hooks/InputGenericoHook'
import { creaRegoleValidazioneOra } from './validazione/RegoleCustom'
import { unisciMessaggiERegoleValidazione } from './validazione/UtilValidazione'

BaseTimeInput.propTypes = {
  propsTimePicker: object,
  ...useInputGenerico.propTypes
}

export default function BaseTimeInput(props) {
  const {
    propsTimePicker,
    ...restProps
  } = props

  const {
    validazione: validazioneOriginale = {},
    messaggiErrore: messaggiOriginali
  } = restProps

  const { validate, messaggi } = creaRegoleValidazioneOra()

  const inputGenericoProps = useInputGenerico({
    componenteControllato: true,
    widthType: 'width',
    ...restProps,
    ...unisciMessaggiERegoleValidazione(validazioneOriginale, validate, messaggiOriginali, messaggi)
  })

  const {
    value: value_TimeString, // hh:mm
    onBlur,
    onChange: onChange_Esterno,
    ref,
    propsRimanenti,
    // controllerNecessario
  } = useControllerSeServe(inputGenericoProps)

  function transformInput(timeString) {
    if (!timeString) {
      return { date: null, errorInvalidTime: false }
    }
    const convertedDate = convertTimeStringToDate(timeString)
    if (convertedDate === null) {
      return { date: null, errorInvalidTime: true }
    } else {
      return { date: convertedDate, errorInvalidTime: false }
    }
  }

  const [dateTmp, setDateTmp] = useState(
    () => transformInput(value_TimeString)
  )

  // NON SONO RIUSCITO A FAR FUNZIONARE LA SINCRONIZZAZIONE
  // DELLO STATO LOCALE QUANDO CAMBIA IL VALUE DALL'ESTERNO
  // useEffect(() => {
  //   if (controllerNecessario) return
  //   setDateTmp(transformInput(value_TimeString))
  // }, [value_TimeString, controllerNecessario])

  function onChange(newDate, newTimeString) {
    if (!newTimeString) {
      setDateTmp({ date: null, errorInvalidTime: false })
      onChange_Esterno('')
      return
    }
    const convertedNewDate = convertTimeStringToDate(newTimeString)
    if (convertedNewDate === null) {
      setDateTmp({ date: newDate, errorInvalidTime: true })
      onChange_Esterno('')
    } else {
      setDateTmp({ date: newDate, errorInvalidTime: false })
      onChange_Esterno(newTimeString)
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={it}>
      <DesktopTimePicker
        // Props fornite dal controller
        value={dateTmp.date}
        onChange={onChange}
        inputRef={ref}

        // Campo da renderizzare
        renderInput={params =>
          <TextField
            {...params}
            onBlur={onBlur}
            {...propsRimanenti}
            {...(dateTmp.errorInvalidTime && {
              error: true,
              helperText: 'Formato ora non valido'
            })}
          />
        }

        // Altre props del time picker
        disableOpenPicker // PER ORA NON FUNZIONA IL POPUP DEL PICKER 
        {...propsTimePicker}
      />
    </LocalizationProvider>
  )
}