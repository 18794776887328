import { useRef, useState } from 'react'
import { BaseFeedbackConIcona } from 'feedback'
import { ICONE } from 'icons'
import BottoneCreaEntita from '../entitaGenerica/components/BottoneCreaEntita'
import { isTemplateHtmlForm, trasformaDocumentoOutput } from './utilDocumenti'
import SceltaTemplate from './components/SceltaTemplate'
import FormGeneraDocumento from './form/FormGeneraDocumento'

export default function BottoneGeneraDocumento(props) {
  const {
    templateDisponibili,
    tagsTemplate,
    labelModelliFiltratiPerTag,
    propsFormGenera,
    propsInterne,
    ciSonoErroriValidazione
  } = props

  const [templateScelto, setTemplateScelto] = useState(null)

  const formGeneraDocumentoRef = useRef()

  function onClickBottoneChiudi() {
    formGeneraDocumentoRef.current?.chiediConfermaSalvaBozza()
  }

  return (
    <BottoneCreaEntita
      testo='Genera documento da modello'
      iconaInizio={ICONE.TEMPLATE_DOCUMENTO}
      renderModalCreazione={({ onSubmit, onCancel }) =>
        !templateScelto ? (
          <SceltaTemplate
            templateDisponibili={templateDisponibili}
            tagsTemplate={tagsTemplate}
            labelModelliFiltratiPerTag={labelModelliFiltratiPerTag}
            onTemplateScelto={template => {
              if (isTemplateHtmlForm(template)) {
                onSubmit({
                  allegato: { titolo: template.descr },
                  template: template.nome,
                  isBozza: true
                })
                onCancel()
              } else {
                setTemplateScelto(template)
              }
            }}
          />
        ) : (
          <FormGeneraDocumento
            template={templateScelto}
            documentoInput={null}
            salvaDocumentoGenerato={documento =>
              onSubmit(trasformaDocumentoOutput(documento, templateScelto))
            }
            chiudiModal={onCancel}
            formGeneraDocumentoRef={formGeneraDocumentoRef}
            {...propsFormGenera}
          />
        )
      }
      propsModalCreazione={{
        // Alla chiusura della modal, resetto lo stato per permettere la generazione
        // di un nuovo documento. Applico un leggero ritardo per evitare che la modal
        // faccia un brutto effetto grafico durante la chiusura
        onClose: () => setTimeout(() => setTemplateScelto(null), 500),
        ...(templateScelto && {
          fullScreen: true,
          ...(!propsFormGenera.readOnly && {
            propsBottoneChiudi: { onClick: onClickBottoneChiudi }
          })
        }),
        disabilitaChiusuraCliccandoFuori: true
      }}
      {...(ciSonoErroriValidazione && {
        disabled: true,
        contenutoSottoBottone: <BaseFeedbackConIcona.Errore messaggio='Correggere errori in altre sezioni' />
      })}
      {...propsInterne}
    />
  )
}