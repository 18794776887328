import { BaseListaCoppieChiaveValore, BaseCoppiaChiaveValore } from 'text'
import { formattaData } from 'utils'
import Indirizzo from '../indirizzo/Indirizzo'
import { isItaly } from '../indirizzo/utilIndirizzo'

export default function DatiAnagrafici(props) {
  const {
    cognomeNome,
    codiceFiscale,
    sesso,
    indirizzoResidenza,
    nascita,
    cittadinanza,
    nascondiCittadinanza
  } = props

  return (
    <>
      <BaseListaCoppieChiaveValore
        titolo='Dati anagrafici'
        propsTitolo={{ colorato: true }}
        coppie={{
          'Cognome e nome': cognomeNome,
          'Codice fiscale': codiceFiscale
        }}
      />
      <DataELuogoDiNascita nascita={nascita} />
      <Indirizzo
        indirizzo={indirizzoResidenza}
        label='Residenza'
      />
      <BaseListaCoppieChiaveValore
        coppie={{
          'Sesso': sesso.toUpperCase(),
          ...(!nascondiCittadinanza && { 'Cittadinanza': cittadinanza })
        }}
      />
    </>
  )
}

export function DataELuogoDiNascita(props) {
  const {
    nascita: {
      dataNascita,
      luogo: {
        cap,
        comune,
        nazione,
        provincia
      }
    },
    visioneRiassuntiva
  } = props

  let luogoNascitaFormattato = ''
  let luogoNascitaFormattato_Corto = ''
  if (nazione && !comune && !cap && !provincia) {
    luogoNascitaFormattato = nazione
    luogoNascitaFormattato_Corto = nazione
  } else {
    luogoNascitaFormattato = `${comune} (${cap} ${provincia})`
    luogoNascitaFormattato_Corto = comune
    if (nazione && !isItaly(nazione)) {
      luogoNascitaFormattato = `${luogoNascitaFormattato}, ${nazione}`
    }
  }

  if (visioneRiassuntiva) {
    const nascitaFormattata = dataNascita
      ? `${formattaData(dataNascita)}, ${luogoNascitaFormattato_Corto}`
      : luogoNascitaFormattato_Corto
    return (
      <BaseCoppiaChiaveValore
        chiave='Nascita'
        valore={nascitaFormattata}
      />
    )
  }

  return (
    <BaseListaCoppieChiaveValore
      coppie={{
        'Data di nascita': formattaData(dataNascita),
        'Luogo di nascita': luogoNascitaFormattato
      }}
    />
  )
}