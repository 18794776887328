import { formattaCognomeNome } from 'utils'

function arricchisciGruppi(albero, livelloProfondita, parents) {
  const { children, ...alberoRest } = albero
  albero.livelloProfondita = livelloProfondita
  albero.parents = parents
  if (children) {
    children.forEach(ramo => {
      ramo.riferimentoParent = alberoRest
      arricchisciGruppi(ramo, livelloProfondita + 1, [...parents, albero.id])
    })
  }
}

function appiattisciGruppi(albero) {
  return albero.reduce(
    (accumulatoreGruppiPiatti, gruppoCorrente) => {
      const alberoPiatto = appiattisciGruppi(gruppoCorrente.children || [])
      return [...accumulatoreGruppiPiatti, gruppoCorrente, ...alberoPiatto]
    },
    []
  )
}

function appiattisciGruppiAlbero(gruppiAlbero, livelloProfondita = 0) {
  gruppiAlbero.forEach(albero => {
    arricchisciGruppi(albero, livelloProfondita, [])
  })
  return appiattisciGruppi(gruppiAlbero, livelloProfondita)
}

function creaLabelGruppo(gruppo) {
  if (!gruppo) return '(nessun gruppo)'
  const { descr, nome } = gruppo
  if (!descr && !nome) return '(nessun gruppo)'
  return descr ? `${descr} --- ${nome}` : nome
}

function creaLabelUtente(utente) {
  return formattaCognomeNome(utente.info)
}

export {
  appiattisciGruppiAlbero,
  creaLabelGruppo,
  creaLabelUtente
}