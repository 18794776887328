import { useEffect, useRef, useState } from "react";
/**
 * Wrapper di getRotatedImage, fornisce l'HTMLImageElement partendo da un src dell'immagine
 * 
 * @param  {string}  imageBase64 - imageBase64 o url dell'immagine
 * @param {number} rotation - gradi di rotazione
 * @param {function} callback
 * @param {object} options
 */
const  rotateImage = (imageBase64, rotation, callback, options) => {
  var img = new Image();
  img.crossOrigin="anonymous"
  img.src = imageBase64;
  img.onload = () => {
    const newimg =  getRotatedImage(img, rotation, options)
    callback  && callback(newimg)
  };
}

var canvas = null;
var canvasCtx2D = null;

// https://levelup.gitconnected.com/how-to-rotate-an-image-in-javascript-and-keep-its-aspect-ratio-e42060190889
/**
 * Ruota immagine dell'HTMLImageElement fornito
 * 
 * @param {HTMLImageElement} image immagine html o img
 * @param {number} rotation - gradi di rotazione
 * @param {object} options
 * @returns 
 */
var getRotatedImage = function (image, rotation, options = {}) {
    const { mimetype } = options
    if (!canvas) {
        canvas = document.createElement('canvas');
        canvasCtx2D = canvas.getContext('2d');
    }
    if (!image || !canvasCtx2D)
        return null;

    var imageWidth = image.width, imageHeight = image.height, currentSrc = image.currentSrc;
    var degree = rotation % 360;
    if (!degree) {
        return {
            src: currentSrc,
            width: imageWidth,
            height: imageHeight,
        };
    }
    var PI = Math.PI, sin = Math.sin, cos = Math.cos, abs = Math.abs;
    var angle = (degree * PI) / 180;
    var sinAngle = sin(angle);
    var cosAngle = cos(angle);
    canvas.width = abs(imageWidth * cosAngle) + abs(imageHeight * sinAngle);
    canvas.height = abs(imageWidth * sinAngle) + abs(imageHeight * cosAngle);
    // The width and height of the canvas will be automatically rounded.
    var canvasWidth = canvas.width, canvasHeight = canvas.height;
    canvasCtx2D.clearRect(0, 0, canvasWidth, canvasHeight);
    canvasCtx2D.translate(canvasWidth / 2, canvasHeight / 2);
    canvasCtx2D.rotate(angle);
    canvasCtx2D.drawImage(image, -imageWidth / 2, -imageHeight / 2, imageWidth, imageHeight);
    var src = canvas.toDataURL(mimetype);
    canvas.width = 0;
    canvas.height = 0;
    return {
        src: src,
        width: canvasWidth,
        height: canvasHeight,
    };
};


/**
 * Hook per rotazione dell'immagine
 * 
 * @param {*} imageSrc - imageBase64 o url dell'immagine
 * @param {*} rotation - gradi di rotazione
 * @returns 
 */
const useRotateImage = (imageSrc, rotation) =>{
  const imageEle = useRef(null);
  const [rotatedImage, setRotatedImage] = useState(null);

  useEffect(() => {
    if (typeof rotation === 'number') {
      let currImage = imageEle.current;

      if (currImage?.currentSrc !== imageSrc) {
        currImage = new Image();
        imageEle.current = currImage;

        currImage.src = imageSrc;
      }

      currImage.decode().then(
        () => setRotatedImage(getRotatedImage(currImage, rotation)),
        () => setRotatedImage(null),
      );
    }
  }, [imageSrc, rotation]);

  return rotatedImage;
};

export {rotateImage, getRotatedImage, useRotateImage}