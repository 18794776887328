import { ICONE } from 'icons'
import { BaseTextInput } from 'inputs'
import { BaseHeaderConIcona } from 'layout'
import { abbreviaStringaSeServe } from 'utils'
import FormModificaEntita, { PROP_TYPES_WRAPPER_FORM_MODIFICA } from '../entitaGenerica/components/FormModificaEntita'
import ScatolaEntita from '../entitaGenerica/components/ScatolaEntita'

SintomatologiaModifica.propTypes = PROP_TYPES_WRAPPER_FORM_MODIFICA

export default function SintomatologiaModifica(props) {
  const {
    propsEntita: { valoriInput }
  } = props

  return (
    <ScatolaEntita
      renderFormModifica={FormSintomatologia}
      testoBottoneCrea='Aggiungi sintomi'
      {...props}
    >
      {valoriInput &&
        <BaseHeaderConIcona
          icona={<ICONE.SALUTE />}
          titolo='Sintomi'
          sottotitolo={abbreviaStringaSeServe(valoriInput.sintomi, 50)}
        />
      }
    </ScatolaEntita>
  )
}



const NOMI = {
  // NOME: 'nome',
  SINTOMI: 'sintomi'
}

function FormSintomatologia(props) {
  const { propsEntita } = props

  return (
    <FormModificaEntita {...propsEntita}>
      <BaseTextInput
        label='Descrizione sintomi' name={NOMI.SINTOMI}
        multiline rows={4} larghezza={30}
      />
    </FormModificaEntita>
  )
}