import { Fragment } from 'react'
import { BaseDivider, BaseGridLayout, BaseScatolaConTitolo } from 'layout'
import { BaseGrassetto, BaseTesto } from 'text'
import BottoneEliminaEntita from '../../entitaGenerica/components/BottoneEliminaEntita'
import { useEntita } from '../../entitaGenerica/components/EntitaContext'
import { costruisciSequenzaDocumentiSostituiti } from '../utilDocumenti'
import HeaderDocumentoAllegato from './HeaderDocumentoAllegato'

export default function ListaDocumentiSostituiti(props) {
  const {
    documento: documentoPiuRecente,
    path: pathDocumentoPiuRecente,
    nomeEntita,
    trovaTemplateConNome,
    abilitaEliminazione
  } = props

  const { risolviRif } = useEntita()

  const sequenzaDocumentiSostituiti = costruisciSequenzaDocumentiSostituiti(
    documentoPiuRecente,
    pathDocumentoPiuRecente,
    { risolviRif }
  )
  const totaleDocumenti = sequenzaDocumentiSostituiti.length

  return (
    <BaseScatolaConTitolo titolo='Versioni documento' sx={{ mt: 2 }}>
      <BaseGridLayout vertical>
        {sequenzaDocumentiSostituiti.map(({ documento, path }, index) =>
          <Fragment key={documento.uuid}>
            <BaseGridLayout justifySpaceBetween alignCenter noWrap>
              <HeaderDocumentoAllegato
                documento={documento}
                trovaTemplateConNome={trovaTemplateConNome}
              />

              <BaseGridLayout alignCenter>
                {abilitaEliminazione && index === 0 &&
                  <BottoneEliminaEntita
                    nomeEntita={nomeEntita}
                    path={path}
                    propsPopoverConferma={{
                      messaggio: "Dopo l'eliminazione verrà ripristinata la versione precedente. Confermare?"
                    }}
                  />
                }
                <LabelVersioneDocumento index={index} tot={totaleDocumenti} />
              </BaseGridLayout>
            </BaseGridLayout>

            {index !== totaleDocumenti - 1 && <BaseDivider sx={{ mt: 2 }} />}
          </Fragment>
        )}
      </BaseGridLayout>
    </BaseScatolaConTitolo>
  )
}



function LabelVersioneDocumento(props) {
  const { index, tot } = props

  return (
    <BaseTesto>
      {tot - index}^ versione
      {index === 0 &&
        <BaseGrassetto sx={{ fontStyle: 'italic' }}>
          <br />(ultima)
        </BaseGrassetto>
      }
    </BaseTesto>
  )
}