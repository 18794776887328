import { formattaNumero } from 'utils'

const TYPES = {
  /*** Tipi semplici ***/

  STRING: 'string',
  INT: 'int',
  DOUBLE: 'double',
  BOOLEAN: 'boolean',
  DATE: 'date',
  DATE_TIME: 'datetime',

  /*********************/

  /*** Tipi complessi ***/

  // Il valore è una stringa JSON che contiene un pair string-string
  PAIR: 'pair',

  // Dopo i due punti: tipo semplice o pair (es. "list:string")
  // Il valore è una stringa JSON che contiene un array di valori del tipo specificato
  LIST: 'list:',

  // Dopo i due punti: nome entità (es. "ent:Persona")
  // Il valore è una stringa JSON che contiene un oggetto
  ENTITY: 'ent:',

  // Dopo i due punti: nome entità (es. "entlist:Persona")
  // Il valore è una stringa JSON che contiene un array di oggetti
  ENTITY_LIST: 'entlist:',

  // Dopo i due punti: nome entità (es. "path:Persona")
  // Il valore è una stringa che contiene un path (NON JSON)
  PATH: 'path:',

  // Dopo i due punti: nome entità (es. "pathlist:Persona")
  // Il valore è una stringa JSON che contiene un array di stringhe, ognuna è un path
  PATH_LIST: 'pathlist:'

  /**********************/
}

function trasformaValoreParamInStringa(valore, type) {
  switch (type) {
    case TYPES.INT: return formattaNumero(valore)
    case TYPES.DOUBLE: return formattaNumero(valore)
    case TYPES.BOOLEAN: return valore ? 'true' : 'false'
    // case TYPES.DATE: 
    // case TYPES.DATE_TIME: 
    case TYPES.PAIR: return valore?.first ?? ''
    default: return String(valore)
  }
}

function isTypeWithEntity(type) {
  return (
    type.startsWith(TYPES.ENTITY)
    || type.startsWith(TYPES.ENTITY_LIST)
    || type.startsWith(TYPES.PATH)
    || type.startsWith(TYPES.PATH_LIST)
  )
}

function isTypeList(type) {
  return (
    type.startsWith(TYPES.LIST)
    || type.startsWith(TYPES.ENTITY_LIST)
    || type.startsWith(TYPES.PATH_LIST)
  )
}

export {
  TYPES,
  trasformaValoreParamInStringa,
  isTypeWithEntity,
  isTypeList
}