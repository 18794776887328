import { abbreviaStringaSeServe, formattaCognomeNome, formattaNumero } from 'utils'
import { trovaElementoConUUID } from '../utils'
import { ELENCHI } from '../../form/ElenchiManager'

function creaDescrizionePersonaRif(condizione, persona, risolviRif) {
  if (!condizione) return ''
  if (persona) return formattaCognomeNome(persona)
  const personaRisolta = risolviRif(condizione.personaRif)
  return personaRisolta ? formattaCognomeNome(personaRisolta) : '(persona non scelta)'
}

function creaTitoloPersonaCondizione(condizione, { persona, risolviRif } = {}) {
  let titolo = 'Condizioni di salute'
  if (!condizione) return titolo
  const labelPersona = creaDescrizionePersonaRif(condizione, persona, risolviRif)
  if (labelPersona) titolo = `${titolo} - ${labelPersona}`
  return titolo
}

function creaDescrizionePersonaCondizione(condizione, elenchi, { persona, risolviRif } = {}) {
  if (!condizione) return ''
  const { condizione: statoSalute } = condizione
  let desc = creaDescrizioneStatoSalute(statoSalute, elenchi)
  const labelPersona = creaDescrizionePersonaRif(condizione, persona, risolviRif)
  if (labelPersona) desc = `${labelPersona} - ${desc}`
  return desc
}

function creaDescrizioneStatoSalute(statoSalute, elenchi) {
  const { stato, lesioni } = statoSalute
  const statiTutti = elenchi(ELENCHI.STATO_SALUTE)
  let desc = statiTutti
    ? trovaElementoConUUID(statiTutti, stato, 'value')?.label || stato
    : stato
  if (stato !== 'BUONA' && lesioni) desc += ` (${abbreviaStringaSeServe(lesioni)})`
  return desc
}

function creaNomeTest(index, breve = false) {
  let nomeBreve
  switch (index) {
    case 0: nomeBreve = 'Pretest'; break
    case 1: nomeBreve = '1° test'; break
    case 2: nomeBreve = '2° test'; break
  }
  return breve ? nomeBreve : `${nomeBreve} etilometro`
}

function creaDescrizioneRisultatoEtilometro(etilometro, breve = false) {
  const { effettuato, positivo, valoreAsDuble } = etilometro
  if (!effettuato) return 'non effettuato'
  let desc = positivo ? 'positivo' : 'negativo'
  if (!breve && valoreAsDuble > 0) desc += ` (${formattaNumero(valoreAsDuble)} g/l)`
  return desc
}

export {
  creaTitoloPersonaCondizione,
  creaDescrizionePersonaCondizione,
  creaDescrizioneStatoSalute,
  creaNomeTest,
  creaDescrizioneRisultatoEtilometro
}