import { BaseButtons, BaseFileInput } from 'inputs'
import { BaseGridLayout } from 'layout'
import { BaseTitolo } from 'text'
import { BaseDownloadLink } from '../BaseDownloadLink'

export default function FileInputSeFileNonCaricato(props) {
  const {
    fileCaricato,
    setFileCaricato,
    propsFileInput,
    downloadFileWithUUID,
    downloadToastRef,
    readOnly
  } = props

  if (fileCaricato) {
    const { filename, mimetype, uuid } = fileCaricato

    return (
      <>
        <BaseTitolo colorato>File caricato</BaseTitolo>
        <BaseGridLayout justifyCenter>
          <BaseDownloadLink
            nomeFile={filename}
            mimetype={mimetype}
            onClick={() => downloadFileWithUUID(uuid, filename)}
            ref={downloadToastRef}
          />
          <BaseButtons.Modifica
            testo='Sostituisci file'
            color='secondary'
            onClick={() => setFileCaricato(null)}
            disabled={readOnly}
          />
        </BaseGridLayout>
      </>
    )
  }

  return (
    <BaseFileInput
      label='File da caricare'
      name='file'
      required
      {...propsFileInput}
    />
  )
}