import { createContext, useContext } from 'react'
import { node, objectOf, string } from 'prop-types'
import useChiaviStorage from './ChiaviStorage'
import useControllaVersioneAggiornata from './ControllaVersioneAggiornataHook'

/**
 * @typedef {import("../../common/incloudApps/util/ContextGenericoProvider").ContextGenericoProviderProps} ContextGenericoProviderProps
 * @typedef {import("../../common/incloudApps/util/ContextGenericoProvider").ContextGenerico} ContextGenerico
 * @typedef {import("../../common/incloudApps/util/ContextGenericoProvider").ContextGenericoProvider} ContextGenericoProvider
 */

ContextGenericoProvider.propTypes = {
  children: node.isRequired,
  nomeApp: string.isRequired,
  CHIAVI_STORAGE: objectOf(string) 
}

/**
 * @type {ContextGenerico}
 */
const ContextGenerico = createContext()

/**
 * 
 * @param {ContextGenericoProviderProps} props 
 * @type {ContextGenericoProvider}
 */
function ContextGenericoProvider(props) {
  const {
    children,
    nomeApp,
    CHIAVI_STORAGE: CHIAVI_STORAGE_CUSTOM,
    ...restProps
  } = props

  const risultatiControlloVersione = useControllaVersioneAggiornata(restProps)

  const mappaChiaviStorage_ConPrefissoNomeApp = useChiaviStorage({
    nomeApp,
    CHIAVI_STORAGE_CUSTOM
  })

  const contextGenericoValue = {
    nomeApp,
    CHIAVI_STORAGE: mappaChiaviStorage_ConPrefissoNomeApp,
    ...risultatiControlloVersione,
    ...restProps
  }

  return (
    <ContextGenerico.Provider value={contextGenericoValue}>
      {children}
    </ContextGenerico.Provider>
  )
}

function useContextGenerico() {
  return useContext(ContextGenerico)
}

export {
  ContextGenericoProvider,
  useContextGenerico
}
