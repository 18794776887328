import { useIsRottaCorrenteSenzaComando } from '../navigation/utils'
import SganciaComando from './SganciaComando'
import { useUser } from './userProvider/UserProvider'

export default function SganciaComandoSeServe(props) {
  const { children } = props

  const { isUserStaff } = useUser()

  const isRottaCorrenteSenzaComando = useIsRottaCorrenteSenzaComando()

  return (isUserStaff() && isRottaCorrenteSenzaComando)
    ? <SganciaComando>{children}</SganciaComando>
    : children
}