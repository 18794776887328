import { generaUUID } from 'utils'

export default function useTabellaModificabile(props) {
  const {
    setData,

    // Queste funzioni devono ritornare una promise con il valore booleano ok
    // Se ok = true, eseguono le corrispondenti modifiche sulla tabella
    primaDiAggiungereRiga = async () => ({ ok: true }),
    primaDiEliminareRiga = async () => ({ ok: true }),
    primaDiModificareRiga = async () => ({ ok: true }),

    // Funzioni eseguite dopo le corrispondenti modifiche fatte sulla tabella
    dopoAverAggiuntoRiga,
    dopoAverEliminatoRiga,
    dopoAverModificatoRiga
  } = props

  async function aggiungiRiga(nuovaRiga) {
    const nuovaRigaConUuid = {
      ...nuovaRiga,
      uuid: nuovaRiga.uuid || generaUUID()
    }
    const { ok } = await primaDiAggiungereRiga(nuovaRigaConUuid)
    if (ok) {
      setData(data => [...data, nuovaRigaConUuid])
      dopoAverAggiuntoRiga?.(nuovaRigaConUuid)
    } 
  }

  async function eliminaRiga(rigaDaEliminare) {
    const { ok } = await primaDiEliminareRiga(rigaDaEliminare)
    if (ok) {
      const uuidRigaDaEliminare = rigaDaEliminare.uuid
      setData(data => data.filter(({ uuid }) => uuid !== uuidRigaDaEliminare))
      dopoAverEliminatoRiga?.(rigaDaEliminare)
    }
  }

  async function modificaRiga(rigaModificata) {
    const { ok } = await primaDiModificareRiga(rigaModificata)
    if (ok) {
      const uuidRigaModificata = rigaModificata.uuid
      setData(data =>
        data.map(riga =>
          (riga.uuid === uuidRigaModificata) ? { ...rigaModificata } : riga
        )
      )
      dopoAverModificatoRiga?.(rigaModificata)
    }
  }

  return {
    datiModificabili: true,
    aggiungiRiga,
    eliminaRiga,
    modificaRiga
  }
}