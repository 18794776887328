import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { node, objectOf, string, shape, oneOf, bool } from 'prop-types'
import { getFromSessionStorage, nonInProduzione, removeFromSessionStorage, saveInSessionStorage } from 'utils'
import { ENTITA_BASE } from '../jconnetEntities/EntitaBase'
import { useContextGenerico } from '../util/ContextGenericoProvider'
import useImplementazioneRichiesteApi from './ImplementazioneRichiesteApiHook'
import { OPERAZIONI_BASE, OPERAZIONI_BASE_CONFIG } from './OperazioniBase'

/**
 * @typedef {import("../../common/incloudApps/network/ApiRequestProvider").ApiRequestProviderProps} ApiRequestProviderProps
 * @typedef {import("../../common/incloudApps/network/ApiRequestProvider").ApiRequestProvider} ApiRequestProvider
 * @typedef {import("../../common/incloudApps/network/ApiRequestProvider").useApiRequest} useApiRequest
 */

/**
 * @type {ApiRequestProviderProps}
 */
ApiRequestProvider.propTypes = {
  children: node.isRequired,
  ROTTA_BASE_API: string.isRequired,
  URL_SERVER: string.isRequired,
  URL_SERVER_ENRICO: string,
  URL_SERVER_LUCA: string,
  URL_SSO_BE: string,
  // Vedi useImplementazioneRichiesteApi per sapere quali endpoint si possono overridare
  MAPPA_ENDPOINTS: objectOf(string),
  OPERAZIONI: objectOf(string).isRequired,
  ENTITA: objectOf(string),
  OPERAZIONI_CONFIG: objectOf(shape({
    httpMethod: oneOf(['post', 'get', 'put', 'delete']),
    rottaBaseApi: string,
    endpoint: string
  })),
  noVersioneInLoginApplication: bool
}

const ApiRequestContext = createContext()

/**
 * 
 * @param {ApiRequestProviderProps} props 
 * @type {ApiRequestProvider}
 */
function ApiRequestProvider(props) {
  const {
    children,
    ROTTA_BASE_API,
    URL_SERVER,
    URL_SERVER_ENRICO,
    URL_SERVER_LUCA,
    URL_SSO_BE,
    MAPPA_ENDPOINTS,
    OPERAZIONI = {},
    ENTITA = {},
    OPERAZIONI_CONFIG: OPERAZIONI_ESTERNE_CONFIG,
    noVersioneInLoginApplication,
    ...restProps
  } = props

  const { CHIAVI_STORAGE, nomeApp, versione } = useContextGenerico()
  const CHIAVE_STORAGE_URL = CHIAVI_STORAGE.URL_BACKEND

  const [urlServer, setUrlServer] = useState(
    () => getFromSessionStorage(CHIAVE_STORAGE_URL) || URL_SERVER
  )

  // Salva nel session storage l'urlServer quando cambia, ma
  // SOLO se è stato cambiato rispetto a quello di default
  useEffect(() => {
    if (urlServer === URL_SERVER) {
      removeFromSessionStorage(CHIAVE_STORAGE_URL)
      return
    }
    saveInSessionStorage(CHIAVE_STORAGE_URL, urlServer)
  }, [urlServer, URL_SERVER, CHIAVE_STORAGE_URL])

  function resetDefaultUrlServer() {
    setUrlServer(URL_SERVER)
    return URL_SERVER
  }

  function isDefaultUrlServer(urlToCheck) {
    return urlToCheck === URL_SERVER
  }

  function isUrlServerSaved(urlToCheck) {
    return urlToCheck === urlServer
  }

  const OPERAZIONI_CONFIG = useMemo(() => {
    // In sviluppo lancio un errore se una operazione esterna sovrascrive una operazione base
    if (nonInProduzione() && OPERAZIONI_ESTERNE_CONFIG) {
      Object.keys(OPERAZIONI_ESTERNE_CONFIG).forEach(chiaveOperazioneEsterna => {
        if (OPERAZIONI_BASE_CONFIG[chiaveOperazioneEsterna]) {
          console.error(`ApiRequestProvider: la chiave ${chiaveOperazioneEsterna} presente in OPERAZIONI_CONFIG sovrascrive una chiave omonima già presente in OPERAZIONI_BASE_CONFIG. Scegliere un'altra chiave per evitare brutti errori`)
        }
      })
    }
    return { ...OPERAZIONI_BASE_CONFIG, ...OPERAZIONI_ESTERNE_CONFIG }
  }, [OPERAZIONI_ESTERNE_CONFIG])

  const apiRequestContextValue = {
    ...useImplementazioneRichiesteApi(ROTTA_BASE_API, urlServer, MAPPA_ENDPOINTS, nomeApp, versione, OPERAZIONI_CONFIG, noVersioneInLoginApplication),
    OPERAZIONI: { ...OPERAZIONI_BASE, ...OPERAZIONI },
    ENTITA: { ...ENTITA_BASE, ...ENTITA },
    urlServer,
    setUrlServer,
    resetDefaultUrlServer,
    isDefaultUrlServer,
    isUrlServerSaved,
    URL_SERVER_ENRICO,
    URL_SERVER_LUCA,
    URL_SSO_BE,
    ...restProps
  }

  return (
    <ApiRequestContext.Provider value={apiRequestContextValue}>
      {children}
    </ApiRequestContext.Provider>
  )
}


/**
 * @type {useApiRequest}
 */
function useApiRequest() {
  return useContext(ApiRequestContext)
}

export {
  ApiRequestProvider,
  useApiRequest
}