import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useMemo } from 'react';

BaseSwitch.propTypes = {
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    labelOnChecked: PropTypes.string,
    labelOnUnchecked: PropTypes.string,
    formLabelProps: PropTypes.object,
    switchProps: PropTypes.object
}

/**
 * @typedef {import("../common/inputs/BaseSwitch").BaseSwitchProps} BaseSwitchProps 
 * @typedef {import("../common/inputs/BaseSwitch").BaseSwitch} BaseSwitch 
 */

/**
 * Base Card to display and present data. Can accept actions or not.
 * 
 * @param {BaseSwitchProps} props 
 * @type {BaseSwitch}
 */
export default function BaseSwitch(props) {

    const {
        checked = false,
        onChange,
        labelOnChecked = "",
        labelOnUnchecked = "",
        formLabelProps,
        switchProps
    } = props

    const switcher = useMemo(() => {
        return <Stack direction="row" spacing={1} alignItems="center" marginLeft={2}>
            {labelOnUnchecked && <Typography fontWeight="700">{labelOnUnchecked}</Typography>}
            <Switch
                checked={checked}
                onChange={onChange}
                inputProps={{ 'aria-label': 'controlled' }}
                {...switchProps} />
            {labelOnChecked && <Typography fontWeight="700">{labelOnChecked}</Typography>}
        </Stack>
    }, [checked, onChange, labelOnChecked, labelOnUnchecked, switchProps])

    return formLabelProps ? (<FormControlLabel control={switcher} {...formLabelProps} />) : (switcher)
}