import { useMemo } from 'react'
import { isAfter, isValid } from 'date-fns'
import { convertiDataSeServe, deepClone, deepMerge, getPropertyWithPath } from 'utils'

/*
  Struttura dell'oggetto dei messaggi di validazione:
  - le chiavi sono i path delle form che modificano le sottoentità
  - i valori sono degli oggetti che mappano ogni nome di un campo con la sua lista di messaggi
    I messaggi non relativi ad un campo specifico vengono associati ad un campo generico "*"
  Esempio:
  {
    'indagati[uuid:77c004f6-c15d-4f58-bf42-a5ab70e62d61].persona': {
      'codiceFiscale': [
        {
          'livello': 'WARNING',
          'msg': 'Codice fiscale non inserito',
          'pathForm': 'indagati[uuid:77c004f6-c15d-4f58-bf42-a5ab70e62d61].persona',
          'nomeCampo': 'codiceFiscale'
        }
      ]
    },
    'indagati[uuid:77c004f6-c15d-4f58-bf42-a5ab70e62d61].persona.documento': {
      '*': [
        {
          'livello': 'ERROR',
          'msg': 'Numero = 1 non va bene',
          'pathForm': 'indagati[uuid:77c004f6-c15d-4f58-bf42-a5ab70e62d61].persona.documento',
          'nomeCampo': '*'
        },
        {
          'livello': 'WARN',
          'msg': 'Rilasciato da = 2 non va bene',
          'pathForm': 'indagati[uuid:77c004f6-c15d-4f58-bf42-a5ab70e62d61].persona.documento',
          'nomeCampo': '*'
        }
      ],
      'tipoDocumento': [
        {
          'livello': 'ERROR',
          'msg': 'Tipo documento non specificato',
          'pathForm': 'indagati[uuid:77c004f6-c15d-4f58-bf42-a5ab70e62d61].persona.documento',
          'nomeCampo': 'tipoDocumento'
        }
      ],
      'numero': [
        {
          'livello': 'ERROR',
          'msg': 'Numero documento non inserito',
          'pathForm': 'indagati[uuid:77c004f6-c15d-4f58-bf42-a5ab70e62d61].persona.documento',
          'nomeCampo': 'numero'
        }
      ]
    }
  }
*/

export default function useValidazioneEntita(props) {
  const {
    albero,
    entita,
    contesto = {},
    mostraInfoDebug,
    risolviRif
  } = props

  const messaggiValidazione = useMemo(() => {
    const messaggi = validaAlberoEntita(albero, {
      contesto: deepClone(contesto),
      entitaCompleta: entita,
      risolviRif,
      messaggi: {} // Inizializzo un oggetto vuoto per i messaggi
    })
    if (mostraInfoDebug) console.log('Validazione entità', messaggi)
    return messaggi
  }, [albero, entita, contesto, mostraInfoDebug, risolviRif])

  function getMessaggiConPathEsatto(pathCercato) {
    return messaggiValidazione[pathCercato] || {}
  }

  function getMessaggiConPathCheIniziaCon(pathCercato) {
    if (!pathCercato) return []

    let listaOggettiMessaggi = []
    Object.entries(messaggiValidazione).forEach(([path, oggettoMessaggi]) => {
      if (path.startsWith(pathCercato)) listaOggettiMessaggi.push(oggettoMessaggi)
    })

    return appiattisciListaOggettiMessaggi(listaOggettiMessaggi)
  }

  function esisteAlmenoUnErrore() {
    const messaggiPiatti = appiattisciListaOggettiMessaggi(Object.values(messaggiValidazione))
    return getErrors(messaggiPiatti).length > 0
  }

  return {
    messaggiValidazione,
    getMessaggiConPathEsatto,
    getMessaggiConPathCheIniziaCon,
    esisteAlmenoUnErrore
  }
}



/*
  Funzione ricorsiva che valida un intero albero entità
  Per ogni nodo chiama la funzione "validate" passando le funzioni di utilità
  Ritorna un oggetto con i messaggi strutturato come descritto sopra
*/
function validaAlberoEntita(nodoAlbero, opzioni) {
  if (nodoAlbero) {
    const {
      validate,
      figli,
      valoriInput,
      contestoValidazioneSottoAlbero
    } = nodoAlbero

    // Se il nodo ha un contestoValidazioneSottoAlbero, questo deve
    // essere unito al contesto esistente e deve valere solo per il
    // sottoalbero con radice il nodo corrente.
    // L'oggetto opzioni che contiene il contesto è uno solo per
    // tutto l'albero, quindi devo sovrascriverlo. Salvo una copia 
    // del contesto vecchio in una variabile locale per poterlo
    // ripristinare una volta finita la validazione del sottoalbero
    let contestoVecchio
    if (contestoValidazioneSottoAlbero) {
      contestoVecchio = deepClone(opzioni.contesto)
      opzioni.contesto = deepMerge(opzioni.contesto, contestoValidazioneSottoAlbero)
    }

    // Valido il nodo (solo se esistono i valoriInput)
    if (validate && valoriInput) {
      const utilValidazione = getUtilValidazione(nodoAlbero, opzioni)
      validate(utilValidazione)
    }

    // Valido ricorsivamente i figli del nodo
    figli?.forEach(figlio => validaAlberoEntita(figlio, opzioni))

    // Ripristino il contesto vecchio se lo avevo sovrascritto
    if (contestoVecchio) {
      opzioni.contesto = contestoVecchio
    }
  }

  return opzioni.messaggi
}



const LIVELLI_MESSAGGIO = {
  ERROR: 'ERROR',
  WARNING: 'WARNING',
  // INFO: 'INFO',
  // DEBUG: 'DEBUG'
}

const NOME_CAMPO_GENERICO = '*'

/*
  Per scrivere le validazioni in modo veloce e conciso, creo delle funzioni di
  utilità da iniettare nella funzione "validate" che implementa le validazioni
  Le funzioni di utilità fanno leva sulle closure per avere accesso ad alcune
  informazioni del nodo (pathEntita, valoriInput), mantenendole nascoste all'esterno
  I messaggi vengono inseriti nell'oggetto "messaggi" passato nelle opzioni
*/
function getUtilValidazione(nodoAlbero, opzioni) {
  const { pathEntita, valoriInput } = nodoAlbero
  let { contesto, entitaCompleta, risolviRif, messaggi } = opzioni

  /*
    Aggiunge un messaggio all'oggetto dei messaggi, usando come chiave il pathBase corrente
    Il messaggio può essere associato ad un campo passando nomeCampo,
    altrimenti viene associato di default al campo generico "*"
    Questa funzione non viene chiamata direttamente, ma tramite le funzioni wrapper definite sotto
  */
  function aggiungiMessaggio(livello, msg, nomeCampo = NOME_CAMPO_GENERICO) {
    const messaggioDaAggiungere = { livello, msg, pathForm: pathEntita, nomeCampo }

    if (typeof messaggi[pathEntita] !== 'object') {
      messaggi[pathEntita] = {}
    }
    if (!Array.isArray(messaggi[pathEntita][nomeCampo])) {
      messaggi[pathEntita][nomeCampo] = []
    }
    messaggi[pathEntita][nomeCampo].push(messaggioDaAggiungere)
  }

  // Aggiunge un messaggio di errore
  function error(msg, nomeCampo) {
    aggiungiMessaggio(LIVELLI_MESSAGGIO.ERROR, msg, nomeCampo)
  }

  // Aggiunge un messaggio di warning
  function warn(msg, nomeCampo) {
    aggiungiMessaggio(LIVELLI_MESSAGGIO.WARNING, msg, nomeCampo)
  }

  /*
    Aggiunge un messaggio di campo obbligatorio sul campo passato, solo se il
    valore del campo è "assente" ('', null, undefined, pair con first = '')
    Di default il messaggio è un errore, ma si può passare la funzione warn per impostare un warning
  */
  function required(msg, nomeCampo, funzioneCheAggiungeMessaggio = error) {
    const valoreCampo = getPropertyWithPath(nomeCampo, valoriInput)
    if (valoreCampo === ''
      || valoreCampo === undefined
      || valoreCampo === null
      || valoreCampo?.first === ''
    ) {
      funzioneCheAggiungeMessaggio(msg, nomeCampo)
    }
  }

  /*
    Per ogni nome campo passato, aggiunge un messaggio di data non valida se il 
    valore del campo è una data presente ma non valida (null è considerata valida)
    Non passare un array di nomi, ma normali argomenti
  */
  function dateValide_Generica(msg, nomiCampiData) {
    nomiCampiData.forEach(nome => {
      const valoreCampoData = getPropertyWithPath(nome, valoriInput)
      if (valoreCampoData && !isValid(convertiDataSeServe(valoreCampoData))) {
        error(msg, nome)
      }
    })
  }

  // Usare per campi data senza ora
  function dateValide(...nomiCampiData) {
    dateValide_Generica('Data non valida', nomiCampiData)
  }

  // Usare per campi data e ora
  function dateOraValide(...nomiCampiDataOra) {
    dateValide_Generica('Data e ora non valida', nomiCampiDataOra)
  }

  /*
    Aggiunge un messaggio generico se i valori dei due campi passati sono 
    delle date presenti e valide, ma la prima data è dopo la seconda data
    Passando dei flag nelle opzioni si può specificare se gli argomenti
    passati devono essere interpretati come nomi di campi da cui risolvere
    i valori (caso standard) oppure come valori già risolti (casi speciali)
  */
  function dataNonDopo(msg, nomeCampo_dataInizio, nomeCampo_dataFine, opzioni = {}) {
    const {
      dataInizioGiaRisolta = false,
      dataFineGiaRisolta = false
    } = opzioni
    const valoreCampo_DataInizio = dataInizioGiaRisolta
      ? nomeCampo_dataInizio
      : getPropertyWithPath(nomeCampo_dataInizio, valoriInput)
    const valoreCampo_DataFine = dataFineGiaRisolta
      ? nomeCampo_dataFine
      : getPropertyWithPath(nomeCampo_dataFine, valoriInput)

    if (!valoreCampo_DataInizio || !valoreCampo_DataFine) return
    const dataInizio = convertiDataSeServe(valoreCampo_DataInizio)
    const dataFine = convertiDataSeServe(valoreCampo_DataFine)
    if (isValid(dataInizio) && isValid(dataFine) && isAfter(dataInizio, dataFine)) {
      error(msg)
    }
  }

  /*
    Restituisce le funzioni di utilità per un'entità annidata
    Ad esempio se sto validando una Persona con path = "persona", potrei voler
    validare il suo Documento con pathRelativo = "documento" e quindi mi servono
    le funzioni di utilità che scrivono i messaggi nel path "persona.documento"
    Normalmente non serve usare questa funzione se l'albero ha i nodi per tutte le 
    entità annidate, ma a volte capita che l'albero si fermi prima; in questi casi
    è necessario usare questa funzione per validare le entità annidate
  */
  function getUtilValidazioneAnnidato(pathRelativo) {
    const nodoAlbero_FiglioFinto = {
      pathEntita: pathEntita.addPath(pathRelativo),
      valoriInput: getPropertyWithPath(pathRelativo, valoriInput)
    }
    return getUtilValidazione(nodoAlbero_FiglioFinto, opzioni)
  }

  return {
    valoriInput,
    nodoAlbero,
    contesto,
    entitaCompleta,
    risolviRif,
    messaggi,
    error,
    warn,
    required,
    dateValide,
    dateOraValide,
    dataNonDopo,
    getUtilValidazioneAnnidato
  }
}

// Riceve una lista di oggetti messaggio con la suddivisione interna
// per nome campo, e restituisce la lista piatta dei soli messaggi
function appiattisciListaOggettiMessaggi(listaOggettiMessaggi = []) {
  let tuttiMessaggi = []
  listaOggettiMessaggi.forEach(oggettoMessaggi => {
    Object.values(oggettoMessaggi).forEach(listaMessaggi => {
      tuttiMessaggi.push(...listaMessaggi)
    })
  })
  return tuttiMessaggi
}

// Cerca in una lista piatta di messaggi quelli in cui una proprietà ha un certo valore
function filtraMessaggi(listaMessaggi = [], nomePropCercata, valorePropCercata) {
  return listaMessaggi.filter(({ [nomePropCercata]: valore }) => valore === valorePropCercata)
}

// Estrae i soli errori da una lista piatta di messaggi
function getErrors(listaMessaggi) {
  return filtraMessaggi(listaMessaggi, 'livello', LIVELLI_MESSAGGIO.ERROR)
}

// Estrae i soli warning da una lista piatta di messaggi
function getWarnings(listaMessaggi) {
  return filtraMessaggi(listaMessaggi, 'livello', LIVELLI_MESSAGGIO.WARNING)
}

export {
  LIVELLI_MESSAGGIO,
  NOME_CAMPO_GENERICO,
  getErrors,
  getWarnings
}