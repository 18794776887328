import { Box, LinearProgress, Alert } from '@mui/material'
import { BaseLinearProgressConPercentuale } from 'feedback'
import { BaseGrassetto } from 'text'

export default function FeedbackCaricamentoFile(props) {
  const {
    fileCaricato,
    inAttesa,
    percentualeCaricata,
    modificaDati_EffettuataConSuccesso
  } = props

  if (fileCaricato) {
    // In attesa per la richiesta di modifica delle info (senza mandare il file)
    if (inAttesa) {
      return (
        <Box m={2}>
          <LinearProgress />
        </Box>
      )
    }

    // Fine attesa per la modifica
    if (modificaDati_EffettuataConSuccesso) {
      return (
        <Box m={2}>
          <Alert
            elevation={5}
            variant='filled'
            severity='success'
          >
            Modifiche effettuate con successo
          </Alert>
        </Box>
      )
    }

    // Nessuna richiesta in corso
    return null
  }

  // Nessun file ancora caricato e nessun caricamento in corso
  if (percentualeCaricata === null) return null

  // Caricamento del file in corso
  return (
    <Box mt={3}>
      <BaseGrassetto>
        {percentualeCaricata < 100 ? 'Caricamento del file...' : 'File caricato con successo'}
      </BaseGrassetto>
      <BaseLinearProgressConPercentuale percentualeCompletata={percentualeCaricata} />
    </Box>
  )
}