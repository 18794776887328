import { useState } from 'react'
import { Box } from '@mui/material'
import { BaseSortableList } from 'dragDrop'
import { BaseCheckbox, BaseRadioGroup, BaseTextInput } from 'inputs'
import { BaseGridLayout } from 'layout'
import { BaseWizard } from 'navigation'
import { BaseTesto } from 'text'
import BaseImmagine from '../BaseImmagine'

const WIZARD_STEPS = [
  {
    key: 'ordinamentoImmagini',
    label: 'Ordinamento immagini',
    Content: OrdinamentoImmagini
  },
  // {
  //   key: 'salvataggioSelezione',
  //   label: 'Salvataggio selezione',
  //   Content: SalvataggioSelezione,
  //   validate: {
  //     condition: ({ salvaSelezione, nomeSelezioneDaSalvare }) => salvaSelezione && !nomeSelezioneDaSalvare,
  //     message: 'Nome non inserito'
  //   }
  // },
  {
    key: 'formatoEsportazione',
    label: 'Formato esportazione',
    Content: FormatoEsportazione,
    validate: {
      condition: ({ formatoEsportazione }) => !formatoEsportazione,
      message: 'Formato non scelto'
    }
  }
]

export default function EsportazioneImmagini(props) {
  const {
    immaginiTutte,
    uuidImmaginiDaEsportare,
    useEsportaImmagini,
    infoExtraPerEsportazione,
    terminaEsportazione
  } = props

  const [immaginiDaEsportare, setImmaginiDaEsportare] = useState(() => {
    return uuidImmaginiDaEsportare.map(uuidImmagine =>
      immaginiTutte.find(({ uuid }) => uuid === uuidImmagine)
    )
  })

  const [salvaSelezione, setSalvaSelezione] = useState(false)
  const [nomeSelezioneDaSalvare, setNomeSelezioneDaSalvare] = useState('')

  const [formatoEsportazione, setFormatoEsportazione] = useState('')

  const {
    generaESalvaEsportazione,
    inAttesaEsportazione,
    ScatolaMessaggi,
    optionsFormatoEsportazione
  } = useEsportaImmagini({
    immaginiDaEsportare,
    ...(salvaSelezione && { nomeSelezioneDaSalvare }),
    formatoEsportazione,
    infoExtraPerEsportazione,
    terminaEsportazione
  })

  return (
    <BaseWizard
      steps={WIZARD_STEPS}
      propsContent={{
        immaginiDaEsportare,
        setImmaginiDaEsportare,
        salvaSelezione,
        setSalvaSelezione,
        nomeSelezioneDaSalvare,
        setNomeSelezioneDaSalvare,
        formatoEsportazione,
        setFormatoEsportazione,
        optionsFormatoEsportazione
      }}
      finishButtonProps={{
        testo: 'Salva esportazione',
        onClick: generaESalvaEsportazione,
        inAttesa: inAttesaEsportazione,
        disabled: inAttesaEsportazione
      }}
      FinalContent={() => null}
      contentUnderStepper={ScatolaMessaggi}
    />
  )
}



function OrdinamentoImmagini(props) {
  const {
    immaginiDaEsportare,
    setImmaginiDaEsportare
  } = props

  return (
    <>
      <BaseTesto color='textSecondary'>
        Se necessario, è possibile riordinare le immagini da esportare.
        Usare le icone sulla sinistra per spostare le immagini.
      </BaseTesto>
      <Box sx={{ maxHeight: 800, overflow: 'auto' }}>
        <BaseSortableList
          list={immaginiDaEsportare}
          setList={setImmaginiDaEsportare}
          getKey={({ uuid }) => uuid}
          renderElement={immagine =>
            <Box sx={{ p: 1 }}>
              <BaseImmagine
                immagine={immagine}
                height={200}
                thumbnail
              />
            </Box>
          }
        />
      </Box>
    </>
  )
}



function SalvataggioSelezione(props) {
  const {
    salvaSelezione,
    setSalvaSelezione,
    nomeSelezioneDaSalvare,
    setNomeSelezioneDaSalvare
  } = props

  return (
    <BaseGridLayout justifyCenter alignCenter>
      <BaseCheckbox
        label='Salva selezione per future esportazioni'
        value={salvaSelezione}
        onChange={e => setSalvaSelezione(e.target.value)}
      />
      <BaseTextInput
        label='Nome selezione'
        value={nomeSelezioneDaSalvare}
        onChange={e => setNomeSelezioneDaSalvare(e.target.value)}
        readOnly={!salvaSelezione}
        larghezza={25}
      />
    </BaseGridLayout>
  )
}



function FormatoEsportazione(props) {
  const {
    formatoEsportazione,
    setFormatoEsportazione,
    optionsFormatoEsportazione
  } = props

  return (
    <Box sx={{ display: 'inline-block' }}>
      <BaseRadioGroup
        value={formatoEsportazione}
        onChange={e => setFormatoEsportazione(e.target.value)}
        options={optionsFormatoEsportazione}
      />
    </Box>
  )
}