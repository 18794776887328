import { useMemo } from 'react'
import { array, bool } from 'prop-types'
import { TableHead, TableCell, TableRow } from '@mui/material'
import { getScrollbarWidth } from 'utils'

HeaderTabella.propTypes = {
  headerGroups: array.isRequired,
  paddingInizialePerScrollbar: bool
}

export default function HeaderTabella(props) {
  const {
    headerGroups,
    paddingInizialePerScrollbar
  } = props

  const scrollbarWidth = useMemo(() => getScrollbarWidth(), [])

  return (
    <TableHead component='div'>
      {headerGroups.map(({ headers, getHeaderGroupProps }, index) => {
        const headerGroupProps = {
          component: 'div',
          style: (paddingInizialePerScrollbar && index === 0) ? { paddingLeft: scrollbarWidth } : {}
        }

        return (
          // eslint-disable-next-line react/jsx-key
          <TableRow {...getHeaderGroupProps(headerGroupProps)}>
            {headers.map(({ render, getHeaderProps }) =>
              // eslint-disable-next-line react/jsx-key
              <TableCell {...getHeaderProps({ component: 'div' })}>
                {render('Header')}
              </TableCell>
            )}
          </TableRow>
        )
      })}
    </TableHead>
  )
}
