import { useFormContext } from 'react-hook-form'
import { ICONE } from 'icons'
import { BaseTextInput, BaseDateInput, BaseSelect, BaseRadioBoolean, BaseSelectModal } from 'inputs'
import { BaseGridLayout, BaseHeaderConIcona, BaseScatolaConTitolo } from 'layout'
import { capitalizza } from 'utils'
import ElenchiManager, { ELENCHI } from '../../form/ElenchiManager'
import FormModificaEntita from '../entitaGenerica/components/FormModificaEntita'
import ListaScatoleEntita from '../entitaGenerica/components/ListaScatoleEntita'
import ListaSubPanels from '../entitaGenerica/components/ListaSubPanels'
import { creaDescrizioneRisultatoEtilometro, creaNomeTest } from './utilPersonaCondizione'

export default function TestEtilometroLista(props) {
  const { propsEntita, label } = props

  return (
    <ListaScatoleEntita
      label={label}
      propsEntita={propsEntita}
      renderEntita={(etilometro, index) =>
        <BaseHeaderConIcona
          icona={<ICONE.DRINK />}
          titolo={creaNomeTest(index)}
          sottotitolo={capitalizza(creaDescrizioneRisultatoEtilometro(etilometro))}
        />
      }
      noCrea
      noElimina
    />
  )
}



export function TestEtilometroModifica(props) {
  const { propsEntita, titolo, subPanels } = props

  return (
    <>
      {titolo}

      <BaseGridLayout vertical spacing={3}>
        <ElenchiManager
          elenchi={[ELENCHI.STRUMENTI_ETILOMETRO]}
          render={el =>
            <FormModificaEntita
              trasformaValoriInput={trasformaValoriInput}
              trasformaValoriOutput={trasformaValoriOutput}
              {...propsEntita}
            >
              <FormTestEtilometro
                pretest={propsEntita.valoriInput.pretest}
                listaStrumenti={el(ELENCHI.STRUMENTI_ETILOMETRO)}
              />
            </FormModificaEntita>
          }
        />

        <ListaSubPanels subPanels={subPanels} />
      </BaseGridLayout>
    </>
  )
}



const NOMI = {
  // PRETEST: 'pretest',
  TIPO_STRUMENTO: 'strumento.tipo',
  NUMERO_SERIALE_STRUMENTO: 'strumento.numeroSeriale',
  NOME_STRUMENTO: 'strumento.nome',
  OMOLOGAZIONE_STRUMENTO: 'strumento.omologazione',
  DATA_REVISIONE_STRUMENTO: 'strumento.dataRevisione',
  // NOME: 'nome',
  PRESSO_COMANDO: 'luogo.pressoComando',
  SUL_POSTO: 'luogo.sulPosto',
  PRESSO: 'luogo.presso',
  SCONTRINO_NUM: 'scontrinoNum',
  VALORE_AS_STR: 'valoreAsStr',
  VALORE_AS_DUBLE: 'valoreAsDuble',
  POSITIVO: 'positivo',
  EFFETTUATO: 'effettuato',
  DATAORA: 'ora'
}

function FormTestEtilometro(props) {
  const { pretest, listaStrumenti = [] } = props

  const { watch, setValue, onChange } = useFormContext()
  const effettuato = watch(NOMI.EFFETTUATO)
  // "luogoSelect" è un campo di utilità che non esiste nel data model
  // Tramite le funzioni di trasformazione valori input e output, uso il 
  // suo valore per impostare due flag "luogo.sulPosto" e "luogo.presso"
  const luogoSelect = watch('luogoSelect')

  function compilaCampiDaSceltaStrumento(strumento) {
    const { nome, numeroSeriale, omologazione, dataRevisione = null } = strumento
    setValue(NOMI.NOME_STRUMENTO, nome)
    setValue(NOMI.NUMERO_SERIALE_STRUMENTO, numeroSeriale)
    setValue(NOMI.OMOLOGAZIONE_STRUMENTO, omologazione)
    setValue(NOMI.DATA_REVISIONE_STRUMENTO, dataRevisione)
    onChange()
  }

  const propsCampiStrumento = {
    larghezza: 15,
    InputLabelProps: { shrink: true }
  }

  return (
    <>
      <BaseRadioBoolean
        name={NOMI.EFFETTUATO} labelTrue='Effettuato' labelFalse='Non effettuato'
      />

      <BaseGridLayout
        alignCenter
        disposizioneRighe={[3, 3, 1]}
        hidden={!effettuato}
      >
        {/***** Data e luogo del test *****/}

        <BaseDateInput label='Data / ora' name={NOMI.DATAORA} dateTime />
        <BaseSelect
          label='Luogo' name='luogoSelect'
          options={[
            { label: 'Sul posto', value: 'sulPosto' },
            { label: 'Presso il comando', value: 'pressoComando' },
            { label: 'Presso altro luogo', value: 'pressoAltroLuogo' }
          ]}
        />
        <BaseTextInput
          label='Presso altro luogo' name={NOMI.PRESSO}
          hidden={luogoSelect !== 'pressoAltroLuogo'}
        />

        {/***** Esito del test *****/}

        <BaseRadioBoolean
          name={NOMI.POSITIVO} labelTrue='Positivo' labelFalse='Negativo'
        />
        <BaseTextInput
          label='Valore' name={NOMI.VALORE_AS_DUBLE}
          type='numeroPositivo' inputProps={{ step: 0.01 }}
          larghezza={8} endAdornment='g/l' hidden={pretest}
        />
        <BaseTextInput
          label='N° scontrino' name={NOMI.SCONTRINO_NUM}
          larghezza={9.5}
        />
        <BaseTextInput
          label='Note' name={NOMI.VALORE_AS_STR}
          multiline rows={2} larghezza={33}
        />
      </BaseGridLayout>

      <BaseScatolaConTitolo
        titolo='Strumento'
        sx={{
          mt: 3, width: '100%', boxSizing: 'border-box',
          ...(!effettuato && { display: 'none' })
        }}
      >
        {/***** Scelta etilometro da lista *****/}

        <BaseSelectModal
          nonUsareHookForm
          onChange={e => compilaCampiDaSceltaStrumento(e.target.value)}

          options={listaStrumenti}
          getOptionKey={({ id }) => id}
          renderOption={({ nome, numeroSeriale }) =>
            <BaseHeaderConIcona
              icona={<ICONE.STRUMENTO_MISURA />}
              titolo={nome}
              sottotitolo={numeroSeriale}
              senzaPadding={false}
            />
          }
          getOptionLabel={({ nome }) => nome}
          getOptionSearchString={
            ({ nome, numeroSeriale }) => [nome, numeroSeriale].join(' ')
          }

          label='Scegli strumento'
          nascondiRiassuntoOpzioniSelezionate
          labelRicerca='nome, numero seriale'
          propsBottoneModifica={{ noMarginY: false }}
        />

        {/***** Compilazione manuale dati etilometro *****/}

        <BaseGridLayout numeroRighe={2}>
          <BaseTextInput
            label='Nome strumento' name={NOMI.NOME_STRUMENTO}
            {...propsCampiStrumento} larghezza={31}
          />
          <BaseTextInput
            label='Matricola / Numero seriale' name={NOMI.NUMERO_SERIALE_STRUMENTO}
            {...propsCampiStrumento}
          />
          <BaseTextInput
            label='Omologazione' name={NOMI.OMOLOGAZIONE_STRUMENTO}
            {...propsCampiStrumento}
          />
          <BaseDateInput
            label='Data revisione' name={NOMI.DATA_REVISIONE_STRUMENTO}
            {...propsCampiStrumento}
          />
        </BaseGridLayout>
      </BaseScatolaConTitolo>
    </>
  )
}



function trasformaValoriInput(etilometro) {
  const {
    luogo: { pressoComando, sulPosto }
  } = etilometro

  let luogoSelect
  if (sulPosto) luogoSelect = 'sulPosto'
  else if (pressoComando) luogoSelect = 'pressoComando'
  else luogoSelect = 'pressoAltroLuogo'

  return { ...etilometro, luogoSelect }
}

function trasformaValoriOutput(etilometro) {
  const { luogoSelect, ...restEtilometro } = etilometro

  return {
    ...restEtilometro,
    luogo: {
      ...restEtilometro.luogo,
      pressoComando: luogoSelect === 'pressoComando',
      sulPosto: luogoSelect === 'sulPosto'
    }
  }
}