import { node } from 'prop-types'
import { Box } from '@mui/material'
import { BaseTextInput } from 'inputs'
import { BaseGridLayout } from 'layout'
import { BaseTitolo } from 'text'
import { isEmailValida, isPartitaIvaValida } from 'utils'
import { CHIAVI_CONTESTO_VALIDAZIONE } from '../ContestoValidazione'
import { ENTITA_BASE } from '../EntitaBase'
import FormModificaEntita, { PROP_TYPES_WRAPPER_FORM_MODIFICA } from '../entitaGenerica/components/FormModificaEntita'
import ScatolaEntita from '../entitaGenerica/components/ScatolaEntita'
import IndirizzoModifica, { validaIndirizzo } from '../indirizzo/IndirizzoModifica'
import HeaderAzienda from './HeaderAzienda'

const NOMI = {
  NOME: 'nome',
  PARTITA_IVA: 'partitaIva',
  PEC: 'pec',
  INDIRIZZO: 'indirizzo'
}

AziendaModifica.propTypes = {
  ...PROP_TYPES_WRAPPER_FORM_MODIFICA,
  BottoneTornaIndietro: node
}

AziendaModifica.defaultConfig = {
  path: 'azienda',
  nomeEntita: ENTITA_BASE.AZIENDA,
  url: 'azienda',
  label: 'Azienda',
  validate: validaAzienda
}

export default function AziendaModifica(props) {
  const {
    propsEntita,
    label,
    isSubPanel,
    BottoneTornaIndietro
  } = props

  const { valoriInput, pathEntita } = propsEntita

  if (isSubPanel) {
    return (
      <ScatolaEntita testoBottoneCrea='Aggiungi azienda' {...props}>
        {valoriInput &&
          <HeaderAzienda azienda={valoriInput} />
        }
      </ScatolaEntita>
    )
  }

  const isNomeVuoto = (propsEntita.valoriInput.nome === '')

  return (
    <>
      {BottoneTornaIndietro}

      <BaseTitolo medio>{label}</BaseTitolo>

      <FormModificaEntita
        nomeEntita={ENTITA_BASE.AZIENDA}
        larghezzaInputs={20}
        {...propsEntita}
      >
        <FormAzienda isNomeVuoto={isNomeVuoto} />
      </FormModificaEntita>

      <Box sx={{ mt: 4 }}>
        <IndirizzoModifica
          propsEntita={{
            valoriInput: valoriInput[NOMI.INDIRIZZO],
            nomeEntita: ENTITA_BASE.INDIRIZZO,
            pathEntita: pathEntita.addPath(NOMI.INDIRIZZO)
          }}
          label='Indirizzo'
          modalitaLight
        />
      </Box>
    </>
  )
}



export function FormAzienda(props) {
  const { isNomeVuoto } = props

  return (
    <BaseGridLayout disposizioneRighe={[1, 2]}>
      <BaseTextInput
        label='Nome azienda' name={NOMI.NOME}
        larghezza={41} autoFocus={isNomeVuoto}
      />

      <BaseTextInput
        label='Partita IVA' name={NOMI.PARTITA_IVA} type='partitaIva'
      />
      <BaseTextInput
        label='PEC' name={NOMI.PEC} type='email'
      />
    </BaseGridLayout>
  )
}



export function validaAzienda(utilValidazione) {
  const { required, warn, valoriInput, getUtilValidazioneAnnidato, contesto } = utilValidazione
  const { partitaIva, pec } = valoriInput

  required('Nome non inserito', NOMI.NOME)

  if (contesto[CHIAVI_CONTESTO_VALIDAZIONE.OBBLIGO_CODICE_FISCALE] === true) {
    required('Partita IVA non inserita', NOMI.PARTITA_IVA)
  }

  if (!isPartitaIvaValida(partitaIva)) {
    warn('Partita IVA non valida', NOMI.PARTITA_IVA)
  }
  if (!isEmailValida(pec)) {
    warn('PEC non valida', NOMI.PEC)
  }

  if (contesto[CHIAVI_CONTESTO_VALIDAZIONE.OBBLIGO_RESIDENZA] === true) {
    validaIndirizzo(getUtilValidazioneAnnidato(NOMI.INDIRIZZO))
  }
}