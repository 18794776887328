import { useRef, useImperativeHandle, forwardRef } from 'react'
import { node, object, bool, func } from 'prop-types'
import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import { BaseIconButtons } from 'inputs'
import { arricchisciPropOggetto, sx, useStateWithLabel } from 'utils'

/**
 * @typedef {import("../common/layout/BaseModal").BaseModalProps} BaseModalProps 
 * @typedef {import("../common/layout/BaseModal").BaseModal} BaseModal 
 * @typedef {import("../common/layout/BaseModal").UseModal} UseModal 
 */

/**
 * @param {BaseModalProps} props 
 * @type {BaseModal}
 */
const BaseModal = forwardRef(function BaseModal(props, ref) {
  const {
    titolo,
    children,
    onOpen,
    onClose,
    propsTitolo,
    propsContenuto,
    propsBottoneChiudi,
    disabilitaChiusuraCliccandoFuori,
    nascondiBottoneChiudi,
    contenutoNonCentrato,
    onCloseStopPropagation,
    ...restProps
  } = props

  const [open, setOpen] = useStateWithLabel(false, 'open')

  function openModal() {
    setOpen(true)
    onOpen?.()
  }

  function closeModal(event, reason) {
    if (onCloseStopPropagation) event?.stopPropagation?.()
    if (disabilitaChiusuraCliccandoFuori && reason === 'backdropClick') {
      return
    }
    setOpen(false)
    onClose?.()
  }

  useImperativeHandle(ref, () => ({
    openModal,
    closeModal
  }))

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      maxWidth='sm'
      fullWidth
      {...restProps}
      {...sx(restProps, { textAlign: 'center' })}
    >
      {titolo &&
        <DialogTitle {...propsTitolo}>
          {titolo}
        </DialogTitle>
      }

      <DialogContent
        {...propsContenuto}
        {...sx(propsContenuto, contenutoNonCentrato && { textAlign: 'left' })}
      >
        {children}
      </DialogContent>

      {!nascondiBottoneChiudi &&
        <BaseIconButtons.Chiudi
          onClick={closeModal}
          {...propsBottoneChiudi}
          {...arricchisciPropOggetto(propsBottoneChiudi, 'tooltipProps', {
            disableHoverListener: true
          })}
          {...sx(propsBottoneChiudi, {
            p: 2,
            position: 'absolute',
            top: 0,
            right: 0
          })}
        />
      }
    </Dialog>
  )
})

BaseModal.propTypes = {
  titolo: node,
  children: node.isRequired,
  onOpen: func,
  onClose: func,
  propsTitolo: object,
  propsContenuto: object,
  propsBottoneChiudi: object,
  disabilitaChiusuraCliccandoFuori: bool,
  nascondiBottoneChiudi: bool,
  contenutoNonCentrato: bool,
  onCloseStopPropagation: bool
}

/**
 * Hook comodo che fornisce tutto quello che serve per usare la modal
 * @returns {UseModal} hook type
 */
function useModal() {
  const modalRef = useRef()
  const openModal = () => modalRef.current?.openModal()
  const closeModal = () => modalRef.current?.closeModal()

  return { modalRef, openModal, closeModal }
}

export {
  BaseModal,
  useModal
}