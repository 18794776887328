import { useMemo } from 'react'
import { string, number, arrayOf, shape, object, func, bool } from 'prop-types'
import { TextField, Autocomplete, createFilterOptions } from '@mui/material'
import { getOptionLabel, useStateWithLabel } from 'utils'
import useInputGenerico from './hooks/InputGenericoHook'
import useControllerSeServe from './hooks/ControllerSeServeHook'

const funzioneFiltraggioDefault = createFilterOptions({ trim: true })

BaseInputTags.propTypes = {
  options: arrayOf(shape({
    label: string.isRequired,
    value: string.isRequired
  })).isRequired,
  abilitaCreazioneTag: bool,
  onCreazioneTag: func, // Chiamata con il tag appena creato
  numeroMaxOpzioniSelezionateDaVisualizzare: number,
  propsAutocomplete: object,
  ...useInputGenerico.propTypes
}

BaseInputTags.campoConOptions = true

export default function BaseInputTags(props) {
  const {
    options,
    abilitaCreazioneTag,
    onCreazioneTag,
    numeroMaxOpzioniSelezionateDaVisualizzare,
    propsAutocomplete,
    ...restProps
  } = props

  const inputGenericoProps = useInputGenerico({
    componenteControllato: true,
    messaggioAiuto: 'Scrivere per avere suggerimenti',
    widthType: 'width',
    ...restProps
  })

  const {
    value: opzioniSelezionate_SoloValues,
    onBlur,
    onChange,
    ref,
    propsRimanenti
  } = useControllerSeServe(inputGenericoProps)

  const {
    optionsTutte,
    aggiungiSuggerimentoCreazioneTag,
    creaNuovoTag
  } = useGestioneCreazioneTag({
    options,
    onCreazioneTag,
    abilitaCreazioneTag
  })

  // Mostra i suggerimenti all'utente, con la possibilità di creare un nuovo tag
  function filterOptions_ConSuggerimentoCreazioneTag(options, params) {
    let suggerimenti = funzioneFiltraggioDefault(options, params)
    if (abilitaCreazioneTag) aggiungiSuggerimentoCreazioneTag?.(suggerimenti, params)
    return suggerimenti
  }

  // Invia i tag selezionati all'esterno, creando se necessario un nuovo tag
  function onChange_ConCreazioneTag(_, nuoveOpzioniSelezionate) {
    if (abilitaCreazioneTag) creaNuovoTag?.(nuoveOpzioniSelezionate)

    // All'esterno mando le opzioni selezionate come array di stringhe (solo values)
    const nuoveOpzioniSelezionate_SoloValues = nuoveOpzioniSelezionate.map(({ value }) => value)
    onChange(nuoveOpzioniSelezionate_SoloValues)
  }

  // Dall'esterno ricevo le opzioni selezionate come un array di stringhe (solo values) 
  // Invece all'interno le converto sempre in un array di oggetti { value, label }
  const opzioniSelezionate_OggettiConLabels = useMemo(
    () => opzioniSelezionate_SoloValues.map(value => ({
      value,
      label: getOptionLabel(optionsTutte, value)
    })),
    [optionsTutte, opzioniSelezionate_SoloValues]
  )

  return (
    <Autocomplete
      value={opzioniSelezionate_OggettiConLabels}
      onChange={onChange_ConCreazioneTag}
      multiple
      options={optionsTutte}
      getOptionLabel={option => option.label}
      isOptionEqualToValue={(option1, option2) => option1.value === option2.value}
      filterSelectedOptions
      filterOptions={filterOptions_ConSuggerimentoCreazioneTag}
      disabled={propsRimanenti.disabled}
      limitTags={numeroMaxOpzioniSelezionateDaVisualizzare}
      noOptionsText={
        `Nessuna opzione ${abilitaCreazioneTag ? '(scrivere per creare una nuova etichetta)' : ''}`
      }
      clearText='Elimina tutto'
      autoHighlight
      {...propsAutocomplete}
      renderInput={params => (
        <TextField
          {...params}
          inputRef={ref}
          onBlur={onBlur}
          {...propsRimanenti}
        />
      )}
    />
  )
}



function useGestioneCreazioneTag(props) {
  const {
    options,
    onCreazioneTag,
    abilitaCreazioneTag
  } = props

  // Tags creati localmente dall'utente
  const [tagsCreati, setTagsCreati] = useStateWithLabel([], 'tagsCreati')

  // Costruisco le options complete, unendo le options
  // passate dall'esterno con i tags creati localmente
  const optionsTutte = useMemo(() => {
    if (!abilitaCreazioneTag) return options

    let listaOptions = [...options]
    tagsCreati.forEach(tag => {
      if (listaOptions.some(({ value }) => value === tag)) return
      listaOptions.push({ value: tag, label: tag })
    })
    return listaOptions
  }, [options, tagsCreati, abilitaCreazioneTag])

  if (!abilitaCreazioneTag) return { optionsTutte }

  function aggiungiSuggerimentoCreazioneTag(suggerimenti, params) {
    // Se l'utente ha scritto qualcosa, suggerisco la creazione di un nuovo tag
    const { inputValue } = params
    const inputValueLowercase = inputValue?.trim().toLowerCase()
    if (inputValueLowercase !== '') {
      // Impedisco la creazione se la stringa inserita corrisponde ad un tag già presente
      const tagGiaPresente = optionsTutte
        .some(({ value }) => value === inputValueLowercase)
      if (!tagGiaPresente) {
        suggerimenti.push({
          value: inputValueLowercase,
          label: `Crea nuova etichetta "${inputValueLowercase}"`,
          // Questo flag indica alla funzione creaNuovoTag di effettuare la creazione
          isOptionDaCreare: true
        })
      }
    }
  }

  function creaNuovoTag(nuoveOpzioniSelezionate) {
    // Se trovo un'opzione selezionata con il flag
    // isOptionDaCreare = true, creo il nuovo tag
    const optionDaCreare = nuoveOpzioniSelezionate
      .find(({ isOptionDaCreare }) => isOptionDaCreare)
    if (optionDaCreare) {
      const tagDaCreare = optionDaCreare.value
      setTagsCreati(tagsCreati => [...tagsCreati, tagDaCreare])
      onCreazioneTag?.(tagDaCreare)
    }
  }

  return {
    optionsTutte,
    aggiungiSuggerimentoCreazioneTag,
    creaNuovoTag
  }
}