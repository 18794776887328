import { shape, string, bool, object, number } from 'prop-types'
import { Box } from '@mui/material'
import { BaseTesto } from 'text'
import { sx } from 'utils'

export const PROP_TYPE_STATO_CUSTOM = shape({
  id: number,
  nome: string,
  descr: string,
  colore: string,
  attivo: bool,
  entity: string
})

LabelStatoCustom.propTypes = {
  statoCustom: PROP_TYPE_STATO_CUSTOM,
  conLabelIniziale: bool,
  propsBaseTesto: object,
  visibilityHiddenSeMancaDescrizione: bool
}

export default function LabelStatoCustom(props) {
  const {
    statoCustom: { descr, colore },
    conLabelIniziale,
    propsBaseTesto,
    visibilityHiddenSeMancaDescrizione,
    ...restProps
  } = props

  const stileSeMancaDescrizione = visibilityHiddenSeMancaDescrizione
    ? { visibility: 'hidden' }
    : { display: 'none' }

  const chip = (
    <Box
      {...restProps}
      {...sx(restProps, {
        ...(!descr && stileSeMancaDescrizione),
        borderColor: colore,
        borderWidth: 1,
        borderRadius: 3,
        borderStyle: 'solid',
        padding: '3px 5px',
        fontSize: '0.85rem',
        color: colore,
        fontFamily: 'fontFamily',
        width: 'fit-content'
      })}
    >
      {descr}
    </Box>
  )

  if (conLabelIniziale) {
    return (
      <BaseTesto {...propsBaseTesto}>
        Stato: {descr ? chip : 'non specificato'}
      </BaseTesto>
    )
  }

  return descr ? chip : null
}
