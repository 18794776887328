import { useEffect, useMemo, useRef, useState } from 'react'
import { arrayOf, shape, string, object, node, number, bool, func } from 'prop-types'
import { BaseToasts, BaseVoto, VOTI_VALUES } from 'feedback'
import { ICONE } from 'icons'
import { BaseButtons, BaseCheckbox, BaseCheckboxArray, BaseSelect } from 'inputs'
import { BaseGridLayout, BaseModal, useModal } from 'layout'
import { BaseTitolo, BaseTesto } from 'text'
import EsportazioneImmagini from './EsportazioneImmagini'
import GalleriaImmagini from './GalleriaImmagini'
import ImmagineConVotoEAzioni from './ImmagineConVotoEAzioni'

BaseListaImmagini.propTypes = {
  immagini: arrayOf(shape({
    uuid: string,
    titolo: string,
    descrizione: string,
    src: shape({
      fullSize: string.isRequired,
      thumbnail: string.isRequired
    }).isRequired
  })).isRequired,
  ComponentePerDownloadFile: func.isRequired,
  useGestioneFile: func.isRequired,

  useEsportaImmagini: func,
  infoExtraPerEsportazione: object,

  renderBottoneCaricaImmagine: func,
  onImmagineModificata: func,
  onImmagineEliminata: func,
  readOnly: bool,

  propsThumbnails: object,
  titoloGalleria: node,
  propsModal: object,
  minHeight_ScatolaImmagineCorrente: number,
  nascondiVoti: bool
}

export default function BaseListaImmagini(props) {
  const {
    immagini,
    ComponentePerDownloadFile,
    useGestioneFile,

    useEsportaImmagini,
    infoExtraPerEsportazione,

    renderBottoneCaricaImmagine,
    onImmagineModificata,
    onImmagineEliminata,
    readOnly,

    propsThumbnails,
    titoloGalleria = 'Immagini',
    propsModal,
    minHeight_ScatolaImmagineCorrente,
    nascondiVoti
  } = props

  /***** Apertura e chiusura galleria *****/

  const [galleriaAperta, setGalleriaAperta] = useState(false)
  const [uuidImmagineDaAprire, setUuidImmagineDaAprire] = useState(null)

  function apriGalleria(immagine) {
    if (modalitaSelezioneAttiva) return
    setGalleriaAperta(true)
    setUuidImmagineDaAprire(immagine.uuid)
  }

  function chiudiGalleria() {
    setGalleriaAperta(false)
    setUuidImmagineDaAprire(null)
  }

  useEffect(() => {
    if (galleriaAperta) {
      function keyboardListener(e) {
        if (e.key === 'Escape') chiudiGalleria()
      }

      window.addEventListener('keydown', keyboardListener)
      return () => window.removeEventListener('keydown', keyboardListener)
    }
  }, [galleriaAperta])

  /****************************************/

  /******* Filtro immagini per voto *******/

  const [votiDaMostrare, setVotiDaMostrare] = useState(VOTI_VALUES)

  const immaginiFiltrate = useMemo(() => {
    return immagini.filter(({ voto }) => votiDaMostrare.includes(voto))
  }, [immagini, votiDaMostrare])

  function onImmagineCaricata(nuovaImmagine) {
    // Le nuove immagini caricate partono con un voto, quindi sarebbero
    // nascoste se il filtro corrente nasconde le immagini con quel voto
    // Questo comportamento può confondere l'utente, quindi appena viene
    // caricata un'immagine rendo visibili le immagini con quel voto
    const { voto } = nuovaImmagine
    if (!votiDaMostrare.includes(voto)) {
      setVotiDaMostrare(voti => [...voti, voto])
    }
  }

  /****************************************/

  /***** Forzatura render thumbnails *****/

  // Dopo la modifica di un binario, serve forzare il render delle liste di
  // thumbnail per scaricare quelli aggiornati dal server. Una volta salvato un
  // binario sul server, questo stato viene impostato con il timestamp attuale
  // e passato come key alle liste di thumbnail (sia dentro la galleria, sia fuori)
  const [timestampForzaRenderThumbnails, setTimestampForzaRenderThumbnails] = useState(0)

  function forzaRenderThumbnails() {
    setTimestampForzaRenderThumbnails(Date.now())
  }

  /***************************************/

  /*** Selezione immagini ed esportazione ***/

  const useEsportaImmagini_DaUsare = useEsportaImmagini ?? (() => ({}))

  const {
    fetchSelezioniEsportazioniPassate,
    selezioniPassate
  } = useEsportaImmagini_DaUsare({
    infoExtraPerEsportazione
  })

  const {
    modalitaSelezioneAttiva,
    uuidImmaginiSelezionate,
    deselezionaTutteImmagini,
    toggleModalitaSelezione,
    toggleSelezioneImmagine,
    tutteImmaginiSelezionate,
    nessunaImmagineSelezionata,
    toggleSelezionaTutteImmagini,
    isImmagineSelezionata,
    getIndiceImmagineSelezionata,
    importaSelezionePassata,
    selezionePassataCoerente
  } = useSelezionaImmagini({
    immaginiFiltrate,
    selezioniPassate
  })

  const { modalRef, openModal, closeModal } = useModal()

  const toastEsportazioneRef = useRef()

  function terminaEsportazione() {
    setTimeout(() => toastEsportazioneRef.current?.mostraToast(), 500)
    toggleModalitaSelezione()
    closeModal()
  }

  /******************************************/

  if (immagini.length === 0) {
    return renderBottoneCaricaImmagine
      ? renderBottoneCaricaImmagine({ onImmagineCaricata })
      : <BaseTesto>Non sono presenti immagini.</BaseTesto>
  }

  return (
    <>
      <BaseGridLayout justifySpaceBetween>
        {!nascondiVoti &&
          <BaseCheckboxArray
            label='Filtra per voto'
            value={votiDaMostrare.map(String)}
            onChange={e => setVotiDaMostrare(e.target.value.map(Number))}
            options={VOTI_VALUES.map(value => ({
              value: String(value),
              label: <BaseVoto value={value} small showLabel />
            }))}
            visualizzazioneOrizzontale
          />
        }

        {(useEsportaImmagini && !modalitaSelezioneAttiva) ? (
          <BaseButtons.Custom
            testo='Esportazione immagini'
            iconaInizio={ICONE.ESPORTA}
            onClick={() => {
              // fetchSelezioniEsportazioniPassate() // Non c'è il backend per ora
              toggleModalitaSelezione()
            }}
          />
        ) : <></>}
      </BaseGridLayout>

      <br />

      {modalitaSelezioneAttiva &&
        <BaseGridLayout alignCenter disposizioneRighe={[3, 3]}>
          <BaseTitolo colorato>Seleziona immagini da esportare</BaseTitolo>
          <BaseButtons.Conferma
            testo='Conferma selezione'
            onClick={openModal}
            disabled={nessunaImmagineSelezionata()}
          />
          <BaseButtons.Annulla
            testo='Annulla esportazione'
            color='default'
            onClick={toggleModalitaSelezione}
          />
          <BaseCheckbox
            label='Tutte le immagini attualmente mostrate'
            value={tutteImmaginiSelezionate()}
            onChange={toggleSelezionaTutteImmagini}
          />
          <BaseSelect
            label='Immagini esportazione passata'
            value={selezionePassataCoerente()}
            onChange={e => importaSelezionePassata(e.target.value)}
            options={[
              { value: '', label: '' },
              ...selezioniPassate.map(({ uuid, nome }) => ({
                value: uuid,
                label: nome
              }))
            ]}
            hidden // Non c'è il backend per ora
          />
          <BaseButtons.AnnullaModifiche
            testo='Deseleziona tutte'
            color='default'
            variant='outlined'
            onClick={deselezionaTutteImmagini}
          />
        </BaseGridLayout>
      }

      <br />

      {immaginiFiltrate.length > 0 ? (
        <BaseGridLayout alignCenter key={timestampForzaRenderThumbnails}>
          {immaginiFiltrate.map(immagine =>
            <ImmagineConVotoEAzioni
              key={immagine.uuid}
              immagine={immagine}
              onClick={() => apriGalleria(immagine)}
              nascondiVoti={nascondiVoti}
              {...(modalitaSelezioneAttiva && {
                modalitaSelezioneAttiva: true,
                onClickCard: () => toggleSelezioneImmagine(immagine),
                isImmagineSelezionata: isImmagineSelezionata(immagine),
                indiceImmagineSelezionata: getIndiceImmagineSelezionata(immagine)
              })}
              azioni={
                <span style={{ display: 'inline-block' }}>
                  <ComponentePerDownloadFile file={immagine} />
                </span>
              }
              {...(!readOnly && {
                azioniMenu: [
                  {
                    label: 'Modifica',
                    onClick: () => apriGalleria(immagine),
                    Icon: <ICONE.MODIFICA color='primary' />
                  },
                  {
                    label: 'Elimina',
                    onClick: () => onImmagineEliminata(immagine),
                    Icon: <ICONE.ELIMINA color='secondary' />,
                    conferma: true
                  }
                ]
              })}
              {...propsThumbnails}
            />
          )}

          {renderBottoneCaricaImmagine &&
            <div
              style={{
                display: 'flex', alignItems: 'center', justifyContent: 'center',
                minWidth: 250, minHeight: 250
              }}
            >
              {renderBottoneCaricaImmagine?.({ onImmagineCaricata })}
            </div>
          }
        </BaseGridLayout>
      ) : (
        <BaseTesto>Nessuna immagine trovata con i criteri impostati.</BaseTesto>
      )}

      <BaseModal
        open={galleriaAperta}
        onClose={chiudiGalleria}
        maxWidth='lg'
        titolo={titoloGalleria}
        disabilitaChiusuraCliccandoFuori
        {...propsModal}
      >
        <GalleriaImmagini
          immagini={immaginiFiltrate}
          ComponentePerDownloadFile={ComponentePerDownloadFile}
          useGestioneFile={useGestioneFile}
          minHeight_ScatolaImmagineCorrente={minHeight_ScatolaImmagineCorrente}
          nascondiVoti={nascondiVoti}

          onImmagineModificata={onImmagineModificata}
          onImmagineEliminata={onImmagineEliminata}
          readOnly={readOnly}

          uuidImmagineDaAprire={uuidImmagineDaAprire}
          chiudiGalleria={chiudiGalleria}
          timestampForzaRenderThumbnails={timestampForzaRenderThumbnails}
          forzaRenderThumbnails={forzaRenderThumbnails}
        />
      </BaseModal>

      {useEsportaImmagini &&
        <>
          <BaseModal
            ref={modalRef}
            titolo='Esportazione immagini'
            disabilitaChiusuraCliccandoFuori
            maxWidth='lg'
            {...propsModal}
          >
            <EsportazioneImmagini
              immaginiTutte={immaginiFiltrate}
              uuidImmaginiDaEsportare={uuidImmaginiSelezionate}
              useEsportaImmagini={useEsportaImmagini}
              infoExtraPerEsportazione={infoExtraPerEsportazione}
              terminaEsportazione={terminaEsportazione}
            />
          </BaseModal>

          <BaseToasts.Download ref={toastEsportazioneRef} />
        </>
      }
    </>
  )
}



function useSelezionaImmagini(props) {
  const { immaginiFiltrate, selezioniPassate } = props

  const [modalitaSelezioneAttiva, setModalitaSelezioneAttiva] = useState(false)

  const [uuidImmaginiSelezionate, setUuidImmaginiSelezionate] = useState([])

  useEffect(() => {
    setUuidImmaginiSelezionate(lista =>
      lista.filter(uuidImmagine =>
        immaginiFiltrate.some(({ uuid }) => uuid === uuidImmagine)
      )
    )
  }, [immaginiFiltrate])

  function deselezionaTutteImmagini() {
    setUuidImmaginiSelezionate([])
  }

  function toggleModalitaSelezione() {
    deselezionaTutteImmagini()
    setModalitaSelezioneAttiva(attiva => !attiva)
  }

  function toggleSelezioneImmagine(immagine) {
    if (!modalitaSelezioneAttiva) return
    const { uuid: uuidImmagine } = immagine
    setUuidImmaginiSelezionate(lista =>
      lista.includes(uuidImmagine)
        ? lista.filter(uuid => uuid !== uuidImmagine)
        : [...lista, uuidImmagine]
    )
  }

  function tutteImmaginiSelezionate() {
    return immaginiFiltrate.every(isImmagineSelezionata)
  }

  function nessunaImmagineSelezionata() {
    return uuidImmaginiSelezionate.length === 0
  }

  function toggleSelezionaTutteImmagini() {
    if (tutteImmaginiSelezionate()) {
      deselezionaTutteImmagini()
    } else {
      setUuidImmaginiSelezionate(immaginiFiltrate.map(({ uuid }) => uuid))
    }
  }

  function isImmagineSelezionata(immagine) {
    return uuidImmaginiSelezionate.includes(immagine.uuid)
  }

  function getIndiceImmagineSelezionata(immagine) {
    return uuidImmaginiSelezionate.findIndex(uuid => uuid === immagine.uuid)
  }

  function importaSelezionePassata(uuidSelezioneScelta) {
    const selezioneScelta = selezioniPassate.find(({ uuid }) => uuid === uuidSelezioneScelta)
    if (selezioneScelta) setUuidImmaginiSelezionate(selezioneScelta.uuidImmagini)
  }

  function selezionePassataCoerente() {
    const selezioneCoerente = selezioniPassate.find(({ uuidImmagini }) =>
      uuidImmagini.every((uuid, index) => uuidImmaginiSelezionate[index] === uuid)
    )
    return selezioneCoerente?.uuid || ''
  }

  return {
    modalitaSelezioneAttiva,
    uuidImmaginiSelezionate,
    deselezionaTutteImmagini,
    toggleModalitaSelezione,
    toggleSelezioneImmagine,
    tutteImmaginiSelezionate,
    nessunaImmagineSelezionata,
    toggleSelezionaTutteImmagini,
    isImmagineSelezionata,
    getIndiceImmagineSelezionata,
    importaSelezionePassata,
    selezionePassataCoerente
  }
}