import { string, object, bool } from 'prop-types'
import { PROP_TYPES_WRAPPER_FORM_MODIFICA } from '../entitaGenerica/components/FormModificaEntita'
import { STATI_ENTITA } from '../statoEntita/ConfigurazioneStatiEntita'
import RichiestaNumeroRegistro from './RichiestaNumeroRegistro'

// Questo componente va messo nel pannello di modifica della sottoentità che contiene 
// numero, annoRiferimento ed eventualmente stato (solitamente i dati generali o qualcosa di simile)
// Se non si passa statoInLavorazioneDopoAssegnazione = false, lo stato diventa IN_LAVORAZIONE
NumeroRegistroModifica.propTypes = {
  ...PROP_TYPES_WRAPPER_FORM_MODIFICA,
  propsInterne_PannelloModificaEntita: object.isRequired,
  labelNumero: string,
  nomeProprietaNumero: string,
  nomeProprietaAnno: string,
  nomeProprietaStato: string,
  rottaModificaContatore: string,
  statoInLavorazioneDopoAssegnazione: bool
}

export default function NumeroRegistroModifica(props) {
  const {
    propsEntita: { valoriInput, nomeEntita, pathEntita },
    propsInterne_PannelloModificaEntita: {
      infoEntitaPrincipale,
      gestioneModifica: {
        modificaEntita,
        inviaModificheInCoda,
        isCodaVuota,
        feedbackModificaEntita: { invioModificheInCorso },
        forzaFetchEntita
      },
      gestionePermessi: { readOnly }
    },
    labelNumero = 'di registro',
    nomeProprietaNumero = 'numero',
    nomeProprietaAnno = 'annoRiferimento',
    nomeProprietaStato = 'stato',
    rottaModificaContatore,
    statoInLavorazioneDopoAssegnazione = true
  } = props

  function applicaModificaNumeroRegistro(numeroEAnno, scaricaNuovaVersioneDalServer = false) {
    if (scaricaNuovaVersioneDalServer) {
      forzaFetchEntita()
      return
    }

    modificaEntita(nomeEntita, pathEntita, {
      [nomeProprietaNumero]: numeroEAnno.numero,
      [nomeProprietaAnno]: numeroEAnno.annoRiferimento,
      ...(statoInLavorazioneDopoAssegnazione && {
        [nomeProprietaStato]: STATI_ENTITA.IN_LAVORAZIONE
      })
    })
    inviaModificheInCoda({ forzaInvio: true })
  }

  return (
    <RichiestaNumeroRegistro
      nomeEntita={infoEntitaPrincipale.nome}
      uuidEntita={infoEntitaPrincipale.uuid}
      numeroEAnnoAttuali={{
        numero: valoriInput[nomeProprietaNumero],
        annoRiferimento: valoriInput[nomeProprietaAnno]
      }}
      onNumeroOttenuto={applicaModificaNumeroRegistro}
      onErroreOttenimentoNumero={() => setTimeout(() => forzaFetchEntita(), 3000)}
      labelNumero={labelNumero}
      rottaModificaContatore={rottaModificaContatore}
      readOnly={readOnly}
      tuttoSalvato={!invioModificheInCorso && isCodaVuota()}
      forzaSalvataggio={() => inviaModificheInCoda({ forzaInvio: true })}
    />
  )
}