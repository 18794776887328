import { useImperativeHandle, useMemo } from 'react'
import { node, arrayOf, func, oneOfType, oneOf, number, object } from 'prop-types'
import { FormHelperText, InputLabel, Box } from '@mui/material'
import fileDownload from 'js-file-download'
import { BaseDownloadLink } from 'files'
import { BaseGridLayout } from 'layout'
import { BaseGrassetto } from 'text'
import { useStateWithLabel, generaUUID, MIME_TYPES_VALUES, GRUPPI_MIME_TYPES_VALUES, flattenMimeTypesList, creaLabelListaMimeTypes, creaAcceptString } from 'utils'
import { useRisultatiUseForm } from './hooks/AltriHooks'
import useInputGenerico from './hooks/InputGenericoHook'
import { creaRegoleValidazioneFile } from './validazione/RegoleCustom'
import { unisciMessaggiERegoleValidazione } from './validazione/UtilValidazione'
import { BaseButtons } from './BaseButtons'
import { BaseIconButtons } from './BaseIconButtons'

BaseFileInput.propTypes = {
  tipiFileAccettati: arrayOf(oneOfType([
    oneOf(MIME_TYPES_VALUES),
    oneOf(GRUPPI_MIME_TYPES_VALUES)
  ])),
  dimensioneMaxInMegabyte: number,
  // Fornisce al padre il file caricato prima del submit
  // Utile per creare e mostrare un'anteprima all'utente
  onFileCorretto: func,
  anteprimaFileCaricato: node,
  refFileInput: object
}

export default function BaseFileInput(props) {
  const {
    tipiFileAccettati = [],
    dimensioneMaxInMegabyte,
    onFileCorretto,
    anteprimaFileCaricato,
    refFileInput,
    validazione: validazioneOriginale = {},
    messaggiErrore: messaggiOriginali,
    ...restProps
  } = props

  const { name, required } = restProps
  const risultatiUseForm = useRisultatiUseForm(props)
  const triggerValidazione = risultatiUseForm?.trigger
  const setValueHookForm = risultatiUseForm?.setValue
  const clearErrorsHookForm = risultatiUseForm?.clearErrors

  const tipiFileAccettatiDefinitivi = flattenMimeTypesList(tipiFileAccettati)
  const {
    messaggi,
    validate
  } = creaRegoleValidazioneFile(tipiFileAccettatiDefinitivi, dimensioneMaxInMegabyte)
  const labelTipiFileAccettati = creaLabelListaMimeTypes(tipiFileAccettatiDefinitivi)
  const acceptString = creaAcceptString(tipiFileAccettatiDefinitivi)

  const {
    label,
    value,
    error,
    warning,
    helperText,
    inputRef,
    onBlur,
    onChange,
    style,
    fullWidth,
    disabled,
    hidden,
    ...inputGenericoProps
  } = useInputGenerico({
    ...restProps,
    ...unisciMessaggiERegoleValidazione(validazioneOriginale, validate, messaggiOriginali, messaggi)
  })

  const [fileCaricato, setFileCaricato] = useStateWithLabel(value || null, 'fileCaricato')
  const fileCaricato_Definitivo = value || fileCaricato

  // Devo cliccare in modo imperativo sull'input per aprirlo
  // Non posso mettere un ref perché c'è già quello di hook form
  // Ho dovuto mettere l'input nascosto fuori dal bottone perché
  // altrimenti hook form esegue un unregister appena tolgo l'input dal DOM
  const inputId = useMemo(() => `fileInput-${generaUUID()}`, [])
  function apriFileInput() {
    document.getElementById(inputId).click()
  }

  function onFileCaricato(e) {
    const file = e.target.files[0]
    setFileCaricato(file)
    onChange?.(e)
    onBlur?.(e)

    const senzaValidazione = !triggerValidazione
    if (senzaValidazione) {
      onFileCorretto?.(file)
      return
    }
    triggerValidazione(name).then(noErrori => {
      if (noErrori) onFileCorretto?.(file)
    })
  }

  function eliminaFileCaricato() {
    setFileCaricato(null)
    onChange?.(null)
    onFileCorretto?.(null)
    setValueHookForm?.(name, null)
    clearErrorsHookForm?.(name)
  }

  useImperativeHandle(refFileInput, () => ({
    eliminaFileCaricato
  }))

  let borderColor = 'grey.400'
  if (error) borderColor = 'error.main'
  else if (warning) borderColor = 'warning.main'

  return (
    <Box
      border={1}
      borderRadius={1}
      borderColor={borderColor}
      padding={1}
      marginTop={1}
      display={fullWidth ? 'block' : 'inline-block'}
      textAlign='center'
      style={{
        ...(disabled && {
          cursor: 'not-allowed',
          backgroundColor: 'rgba(0, 0, 0, 0.04)'
        }),
        ...(hidden && { display: 'none' }),
        ...style
      }}
    >
      <InputLabel
        required={required}
        error={error}
        {...(warning && { sx: { color: 'warning.main' } })}
      >
        {label}
      </InputLabel>

      {labelTipiFileAccettati &&
        <FormHelperText style={{ textAlign: 'center' }}>
          Formati validi:
          <BaseGrassetto>{` ${labelTipiFileAccettati}`}</BaseGrassetto>
        </FormHelperText>
      }

      {dimensioneMaxInMegabyte !== undefined &&
        <FormHelperText style={{ textAlign: 'center' }}>
          Dimensione massima consentita:
          <BaseGrassetto>{` ${dimensioneMaxInMegabyte} MB`}</BaseGrassetto>
        </FormHelperText>
      }

      <input
        type='file'
        ref={inputRef}
        accept={acceptString}
        onChange={onFileCaricato}
        hidden
        id={inputId}
        disabled={disabled}
        {...inputGenericoProps}
      />

      {fileCaricato_Definitivo ? (
        <>
          <BaseGridLayout justifyCenter alignCenter spacing={0}>
            <BaseDownloadLink
              nomeFile={fileCaricato_Definitivo.name}
              mimetype={fileCaricato_Definitivo.type}
              onClick={() => fileDownload(fileCaricato_Definitivo, fileCaricato_Definitivo.name)}
              toastProps={{ messaggio: 'File scaricato.' }}
            />
            <BaseIconButtons.Elimina
              contenutoTooltip='Elimina (permette di caricare un altro file)'
              onClick={eliminaFileCaricato}
              disabled={disabled}
            />
          </BaseGridLayout>

          {anteprimaFileCaricato}
        </>
      ) : (
        <BaseButtons.CaricaFile
          testo='Carica nuovo file'
          onClick={apriFileInput}
          disabled={disabled}
        />
      )}

      <FormHelperText
        error={error}
        {...(warning && { sx: { color: 'warning.main' } })}
        style={{ textAlign: 'center' }}
      >
        {helperText}
      </FormHelperText>
    </Box>
  )
}
