import { string, number, bool, object, oneOf, oneOfType, arrayOf, shape, func, node } from 'prop-types'
import { BaseButtons, BaseCheckbox, BaseForm, BaseSelect, BaseTextInput, useCampiForm } from 'inputs'
import { BaseGridLayout } from 'layout'
import { TIPI_VARIABILE_PER_IMPOSTAZIONI } from './TipiVariabilePerImpostazioni'

const { STRING, INT, DOUBLE, BOOL, JSON } = TIPI_VARIABILE_PER_IMPOSTAZIONI

FormModificaImpostazione.propTypes = {
  tipo: oneOf(Object.values(TIPI_VARIABILE_PER_IMPOSTAZIONI)).isRequired,
  nome: string.isRequired,
  valore: oneOfType([string, number, bool, object]).isRequired,
  valoriAmmissibili: arrayOf(shape({
    key: string.isRequired,
    label: string.isRequired
  })),
  desc: string.isRequired,
  salvaImpostazioneModificata: func, // Riceve 2 argomenti: nome, nuovoValore
  inAttesaSalvataggio: bool,
  campoGiaPronto: node,
  annullaModifiche: func.isRequired
}

export default function FormModificaImpostazione(props) {
  const {
    tipo,
    nome,
    valore,
    valoriAmmissibili,
    desc,
    salvaImpostazioneModificata,
    inAttesaSalvataggio,
    campoGiaPronto,
    annullaModifiche
  } = props

  // La form tratta un'impostazione di tipo JSON come una normale stringa
  const valoreDefinitivo = (tipo === JSON)
    ? window.JSON.stringify(valore, null, 2)
    : valore

  // Se il nome contiene punti, react hook form pensa erroneamente che sia un campo annidato
  // Quindi sostituisco i punti con underscore nel nome usato per registrare il campo
  const nomePerRegistrareCampo = nome.replaceAll('.', '_')
  
  async function onSubmit(valoriCampi) {
    let nuovoValore = valoriCampi[nomePerRegistrareCampo]
    if (tipo === JSON) nuovoValore = window.JSON.parse(nuovoValore)
    salvaImpostazioneModificata(nome, nuovoValore)
  }

  return (
    <BaseForm
      defaultValues={{ [nomePerRegistrareCampo]: valoreDefinitivo }}
      onSubmit={onSubmit}
      inAttesa={inAttesaSalvataggio}
      larghezzaInputs={20}
    >
      {campoGiaPronto ||
        <CampoModificaImpostazione
          tipo={tipo}
          nome={nomePerRegistrareCampo}
          valoriAmmissibili={valoriAmmissibili}
          desc={desc}
        />
      }
      <BaseGridLayout>
        <BaseButtons.Salva />
        <BaseButtons.AnnullaModifiche
          onClick={annullaModifiche}
          color='secondary'
        />
      </BaseGridLayout>
    </BaseForm>
  )
}



function CampoModificaImpostazione(props) {
  const { tipo, nome, valoriAmmissibili, desc } = props

  let Input = BaseTextInput
  let options
  let propsInput = {}

  switch (tipo) {
    case STRING:
      if (valoriAmmissibili) {
        Input = BaseSelect
        options = valoriAmmissibili.map(({ key, label }) => ({ value: key, label }))
      }
      break
    case INT:
      propsInput = { type: 'numeroIntero' }
      break
    case DOUBLE:
      propsInput = {
        type: 'number',
        inputProps: { step: 0.01 }
      }
      break
    case BOOL:
      Input = BaseCheckbox
      propsInput = { required: false }
      break
    case JSON:
      propsInput = {
        multiline: true,
        larghezza: 50,
        validazione: {
          validate: {
            JSONValido: JSONStringa => {
              try {
                window.JSON.parse(JSONStringa)
                return true
              } catch (error) {
                return false
              }
            }
          }
        },
        messaggiErrore: {
          JSONValido: 'JSON non valido. Controllare la sintassi'
        }
      }
      break
    default:
      break
  }

  return useCampiForm({
    campo: {
      name: nome,
      label: desc,
      Input,
      options,
      props: { required: true, ...propsInput}
    }
  }).campo
}