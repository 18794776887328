import { createContext, useContext } from 'react'
import { element, number, object, shape, string, bool } from 'prop-types'
import { AppBar, Box, Drawer, Toolbar, LinearProgress } from '@mui/material'
import { BaseIconButtons } from 'inputs'
import { useStateWithLabel } from 'utils'
import { useUser } from '../incloudApps/userManagement/userProvider/UserProvider'
import LinkHomeIcon from '../incloudApps/userManagement/LinkHomeIcon'

/**
 * @typedef {import('../common/layout/BaseDrawerLayout').BaseDrawerLayoutProps} BaseDrawerLayoutProps
 * @typedef {import('../common/layout/BaseDrawerLayout').BaseDrawerLayout} BaseDrawerLayout 
 * @typedef {import('../common/layout/BaseDrawerLayout').useDrawer} useDrawer
 * @typedef {import('../common/layout/BaseDrawerLayout').BaseDrawerContext} BaseDrawerContext
 */

BaseDrawerLayout.propTypes = {
  drawerWidth: shape({
    whenClosed: number,
    whenOpen: number
  }),
  isLoading: bool,
  mainContentPadding: number,
  appBarContent: element,
  appBarColor: string,
  appBarTextColor: string,
  appBarElevation: number,
  appBarHeight: number,
  drawerContent: element,
  mainContent: element.isRequired,
  contextInfo: object,
  wrapperMainContentVisibile: bool,
  mainContentBackground: string
}

/**
 * @type {BaseDrawerContext}
 */
const BaseDrawerContext = createContext()

/**
 * 
 * @param {BaseDrawerLayoutProps} props 
 * @type {BaseDrawerLayout}
 */
function BaseDrawerLayout(props) {
  const {
    isLoading = false,
    drawerWidth = { whenClosed: 7, whenOpen: 28 }, // Valori da passare alla funzione spacing
    mainContentPadding = 3, // Valore da passare alla funzione spacing
    appBarContent,
    appBarColor = 'primary.dark',
    appBarTextColor = 'common.white',
    appBarElevation = 3,
    appBarHeight = 8,
    drawerContent,
    mainContent,
    contextInfo,
    wrapperMainContentVisibile,
    mainContentBackground
  } = props

  const [drawerOpen, setDrawerOpen] = useStateWithLabel(false, 'drawerOpen')

  const {
    sxAppBar,
    sxDrawer,
    sxMainContent
  } = calcolaStili({
    drawerOpen,
    drawerWidth,
    mainContentPadding,
    appBarColor,
    appBarTextColor,
    mainContentBackground
  })

  function toggleDrawerOpen() {
    setDrawerOpen(drawerOpen => !drawerOpen)
  }

  const DrawerToggleButton = drawerOpen
    ? BaseIconButtons.Minimizza
    : BaseIconButtons.Hamburger


  const contextValue = {
    drawerOpen,
    toggleDrawerOpen,
    mainContentPadding,
    appBarHeight,
    ...contextInfo
  }

  const { routesVisibiliNeiMenu_VisibiliDalloUser } = useUser()

  return (
    <BaseDrawerContext.Provider value={contextValue}>
      <Box display='flex'>
        {appBarContent &&
          <AppBar position='fixed' elevation={appBarElevation} sx={sxAppBar}>
            <Toolbar>
              <LinkHomeIcon
                sx={{
                  ml: { xs: 1, sm: -1 },
                  mr: 2,
                  display: { xs: 'block', sm: 'block' }
                }}
              />

              {routesVisibiliNeiMenu_VisibiliDalloUser.length > 0 && !drawerOpen &&
                <DrawerToggleButton
                  onClick={toggleDrawerOpen}
                  contenutoTooltip={drawerOpen ? 'Chiudi menu' : 'Apri menu'}
                  color='inherit'
                  edge='start'
                  sx={{ ml: 1, mr: 2 }}
                />
              }

              {appBarContent}
            </Toolbar>

            {isLoading && <LinearProgress color='secondary' />}
          </AppBar>
        }

        {drawerContent &&
          <>
            <Drawer
              variant='temporary'
              open={drawerOpen}
              onClose={() => setDrawerOpen(false)}
              ModalProps={{
                keepMounted: true // Better open performance on mobile.
              }}
              sx={{
                display: { xs: 'block', sm: 'none' },
                '& .MuiDrawer-paper': {
                  boxSizing: 'border-box',
                  width: drawerWidth
                }
              }}
            >
              {drawerContent}
            </Drawer>

            <Drawer
              variant='permanent'
              PaperProps={{ elevation: 5 }}
              sx={sxDrawer}
            >
              {drawerContent}
            </Drawer>
          </>
        }

        {wrapperMainContentVisibile ? (
          <Box sx={sxMainContent} className={'contenutoPagine'}>
            {/* Inserisco spazio per far scendere il mainContent sotto la appBar */}
            <Box sx={({ spacing }) => ({ minHeight: spacing(appBarHeight) })} />

            {mainContent}
          </Box>
        ) : (
          mainContent
        )}
      </Box>
    </BaseDrawerContext.Provider>
  )
}



function calcolaStili(props) {
  const {
    drawerOpen,
    drawerWidth,
    mainContentPadding,
    mainContentBackground,
    appBarColor,
    appBarTextColor,
  } = props

  function createTransition(createParam) {
    return ({ transitions }) => transitions.create(createParam, {
      easing: transitions.easing.sharp,
      duration: transitions.duration.enteringScreen
    })
  }

  const sxAppBar = ({ zIndex, spacing }) => ({
    zIndex: { xs: zIndex.drawer - 1, sm: zIndex.drawer + 1 },
    transition: createTransition(['width', 'margin']),
    bgcolor: appBarColor,
    color: appBarTextColor,
    ml: { xs: 0 },
    width: '100%',
    left: 0,
    ...(drawerOpen && {
      ml: { sm: drawerWidth.whenOpen },
      width: { sm: `calc(100% - ${spacing(drawerWidth.whenOpen)})` }
    }),
    p: 0
  })

  const drawerTransition = createTransition('width')
  const stileDrawer = spacing => ({
    transition: drawerTransition,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    ...(drawerOpen ? {
      width: spacing(drawerWidth.whenOpen)
    } : {
      width: spacing(drawerWidth.whenClosed),
      overflowX: 'hidden'
    })
  })
  const sxDrawer = ({ spacing }) => ({
    ...stileDrawer(spacing),
    display: { xs: 'none', sm: 'block' },
    '& .MuiDrawer-paper': {
      borderRight: 'none',
      ...stileDrawer(spacing)
    },
  })

  const currentDrawerWidth = drawerOpen ? drawerWidth.whenOpen : drawerWidth.whenClosed
  const sxMainContent = ({ spacing }) => ({
    flexGrow: 1,
    px: { xs: 0, sm: mainContentPadding },
    pt: { xs: 0, sm: mainContentPadding },
    backgroundColor: mainContentBackground,
    pb: 0,
    width: { xs: 0, sm: `calc(100% - ${spacing(currentDrawerWidth)} - ${spacing(mainContentPadding * 2)})` },
    transition: drawerTransition
  })

  return { sxAppBar, sxDrawer, sxMainContent }
}

/**
 * @type {useDrawer}
 */
function useDrawer() {
  return useContext(BaseDrawerContext)
}

export {
  BaseDrawerLayout,
  useDrawer
}
