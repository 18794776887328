import { forwardRef } from 'react'
import { bool, object } from 'prop-types'
import { Slide } from '@mui/material'
import { BaseToasts } from 'feedback'
import { sx } from 'utils'

export const Toast = forwardRef(function Toast(props, ref) {
  const {
    inBasso,
    snackbarProps,
    ...restProps
  } = props

  return (
    <BaseToasts.Custom
      snackbarProps={{
        TransitionComponent: Slide,
        anchorOrigin: {
          vertical: inBasso ? 'bottom' : 'top',
          horizontal: 'center'
        },
        ...snackbarProps
      }}
      ref={ref}
      {...restProps}
      {...sx(restProps, !inBasso && { mt: 6 })}
    />
  )
})

Toast.propTypes = {
  inBasso: bool,
  snackbarProps: object
}