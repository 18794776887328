import { createContext, useContext, useMemo } from 'react'
import { node, bool } from 'prop-types'

ReadOnlyProvider.propTypes = {
  children: node.isRequired,
  readOnly: bool.isRequired
}

const ReadOnlyContext = createContext()

export default function ReadOnlyProvider(props) {
  const { children, readOnly } = props

  const contextValue = useMemo(
    () => ({ readOnly }),
    [readOnly]
  )

  return (
    <ReadOnlyContext.Provider value={contextValue}>
      {children}
    </ReadOnlyContext.Provider>
  )
}

function useReadOnly() {
  return useContext(ReadOnlyContext)
}

export {
  ReadOnlyProvider,
  useReadOnly
}
