import { useState, createContext, useContext } from 'react'
import { node } from 'prop-types'
import { ICONE } from 'icons'
import { BaseButtons, BaseIconButtons } from 'inputs'
import { BaseScatola, BaseGridLayout } from 'layout'
import { BaseGrassetto } from 'text'

PinProvider.propTypes = {
  children: node.isRequired
}

const PinContext = createContext()

function PinProvider(props) {
  const { children } = props

  const [pinCorrente, setPinCorrente] = useState(null)
  const [bottonePinPremuto, setBottonePinPremuto] = useState(false)
  
  function togliPin() {
    setPinCorrente(null)
    setBottonePinPremuto(false)
  }

  function impostaPin(pin) {
    setPinCorrente(pin)
    setBottonePinPremuto(false)
  }

  const [pathCreazione, setPathCreazione] = useState(null)

  const contextValue = {
    pinCorrente,
    bottonePinPremuto,
    setBottonePinPremuto,
    impostaPin,
    togliPin,
    pathCreazione,
    setPathCreazione
  }


  return (
    <PinContext.Provider value={contextValue}>
      {children}

      {pinCorrente &&
        <BaseScatola
          centraContenuto
          sx={{
            position: 'fixed',
            bottom: 20,
            right: 20,
            bgcolor: 'warning.light',
            maxWidth: '20rem'
          }}
          onClick_BottoneChiudi={togliPin}
        >
          <BaseGridLayout alignCenter justifyCenter spacing={1}>
            <BaseGrassetto>{pinCorrente.titolo}</BaseGrassetto>
            <BaseIconButtons.Aiuto
              contenutoTooltip={pinCorrente.tooltip}
              tooltipProps={{ placement: 'top' }}
              sx={{ p: 0 }}
            />
          </BaseGridLayout>

          <BaseButtons.Custom
            iconaFine={ICONE.FRECCIA_DESTRA_CERCHIATA}
            color='info'
            sx={{ mb: 0 }}
            {...pinCorrente.propsBottone}
          />
        </BaseScatola>
      }
    </PinContext.Provider>
  )
}

function usePin() {
  return useContext(PinContext)
}

export {
  PinProvider,
  usePin
}