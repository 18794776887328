import { useMemo, useRef } from 'react'
import { bool, func, object, elementType } from 'prop-types'
import { Chip } from '@mui/material'
import { BaseInputTabella, BaseButtons } from 'inputs'
import { BaseGridLayout } from 'layout'
import { useStateWithLabel } from 'utils'
import FormPerUploadFile from './FormPerUploadFile'

BaseInputTabellaFiles.propTypes = {
  nascondiTags: bool,
  ComponentePerDownloadFile: elementType.isRequired, // Riceve una prop "file" con i valori della riga corrente
  ComponenteToast: elementType.isRequired, // Serve per il feedback dopo l'eliminazione di un file sul server
  useGestioneFile: func.isRequired,
  propsFormUpload: object,
  propsComponentePerDownloadFile: object
}

const COLONNE_TABELLA_FILES = [
  {
    Header: 'Titolo',
    accessor: 'titolo',
    minWidth: 200
  },
  {
    Header: 'Descrizione',
    accessor: 'descrizione',
    minWidth: 200
  },
  {
    Header: 'File caricato',
    accessor: 'filename',
    minWidth: 400,
    Cell: ({ ComponentePerDownloadFile, propsComponentePerDownloadFile,  row }) =>
      <ComponentePerDownloadFile file={row.values} {...propsComponentePerDownloadFile} />
  },
  {
    Header: 'Etichette',
    accessor: 'tags',
    minWidth: 200,
    Cell: ({ value }) =>
      <BaseGridLayout spacing={1}>
        {value.map(tag =>
          <Chip label={tag} key={tag} />
        )}
      </BaseGridLayout>
  },
  {
    accessor: 'mimetype',
    nascosta: true
  },
  {
    accessor: 'uuid',
    nascosta: true
  }
]

export default function BaseInputTabellaFiles(props) {
  const {
    nascondiTags,
    ComponentePerDownloadFile,
    ComponenteToast,
    useGestioneFile,
    propsFormUpload,
    propsComponentePerDownloadFile,
    propsModal,
    propsModalAggiuntaRiga,
    propsUseTabellaModificabile,
    ...restProps
  } = props

  const colonneDefinitive = useMemo(
    () => nascondiTags
      ? COLONNE_TABELLA_FILES.filter(({ accessor }) => accessor !== 'tags')
      : COLONNE_TABELLA_FILES,
    [nascondiTags]
  )

  const {
    OPERAZIONI: { BINARIO_ELIMINA },
    ScatolaMessaggi,
    inviaOperazione,
    inAttesaDelServer
  } = useGestioneFile()

  const [
    uuidFileDaEliminare, setUuidFileDaEliminare
  ] = useStateWithLabel('', 'uuidFileDaEliminare')

  async function eliminaFileSulServer(fileDaEliminare) {
    const { uuid } = fileDaEliminare
    setUuidFileDaEliminare(uuid)
    return inviaOperazione(BINARIO_ELIMINA, { uuid })
  }

  function mostraFeedbackEliminazioneFile() {
    toastEliminazioneFile_Ref.current?.mostraToast()
    setUuidFileDaEliminare('')
  }

  const toastEliminazioneFile_Ref = useRef()

  return (
    <>
      {ScatolaMessaggi}

      <BaseInputTabella
        columns={colonneDefinitive}
        ComponentePerDownloadFile={ComponentePerDownloadFile}
        propsComponentePerDownloadFile={propsComponentePerDownloadFile}
        nomeEntita_PerMessaggioErroreRequired='un file'
        messaggioNessunaRiga='Nessun file inserito'
        inAttesaEliminazioneRigaConUuid={
          inAttesaDelServer ? uuidFileDaEliminare : ''
        }
        {...restProps}
        propsModal={{
          titolo: 'Caricamento file',
          renderForm: propsInterneTabella => (
            <FormPerUploadFile
              useGestioneFile={useGestioneFile}
              {...propsInterneTabella}
              {...propsFormUpload}
            />
          ),
          ...propsModal
        }}
        propsModalAggiuntaRiga={{
          labelBottoneApriModal: 'Carica nuovo file',
          renderBottoneApriModal: (openModal, labelBottoneApriModal) => (
            <BaseButtons.CaricaFile
              testo={labelBottoneApriModal}
              onClick={openModal}
              color='primary'
            />
          ),
          ...propsModalAggiuntaRiga
        }}
        propsUseTabellaModificabile={{
          primaDiEliminareRiga: eliminaFileSulServer,
          dopoAverEliminatoRiga: mostraFeedbackEliminazioneFile,
          ...propsUseTabellaModificabile
        }}
      />

      <ComponenteToast
        messaggio='Eliminazione file effettuata con successo'
        color='success'
        ref={toastEliminazioneFile_Ref}
      />
    </>
  )
}