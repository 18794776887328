import { useState } from 'react'
import { object, bool } from 'prop-types'
import { InputAdornment } from '@mui/material'
import { ICONE } from 'icons'
import { sx } from 'utils'
import { BaseIconButtons } from './BaseIconButtons'
import BaseTextInput from './BaseTextInput'

BasePassword.propTypes = {
  defaultShow: bool,
  propsAdornment: object,
  propsIconButton: object
}

export default function BasePassword(props) {
  const {
    defaultShow = false,
    propsAdornment,
    propsIconButton,
    ...restProps
  } = props

  const [showPassword, setShowPassword] = useState(defaultShow)

  return (
    <BaseTextInput
      {...restProps}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end' {...propsAdornment}>
            <BaseIconButtons.Custom
              onClick={() => setShowPassword(show => !show)}
              {...(showPassword ? {
                Icon: ICONE.NASCOSTO,
                contenutoTooltip: 'Nascondi'
              } : {
                Icon: ICONE.VISIBILE,
                contenutoTooltip: 'Mostra'
              })}
              {...propsIconButton}
              {...sx(propsIconButton, { p: 0 })}
            />
          </InputAdornment>
        ),
        ...restProps.InputProps
      }}
    />
  )
}