import { string, func, arrayOf, shape, bool } from 'prop-types'
import { ICONE } from 'icons'
import BaseMenu from '../BaseMenu'

CompilazioneVeloceCampoTesto.propTypes = {
  testoBottone: string.isRequired,
  compilaCampo: func.isRequired,
  scelteCompilazioneVeloce: arrayOf(shape({
    label: string.isRequired,
    contenuto: string
  })),
  readOnly: bool,
}

export default function CompilazioneVeloceCampoTesto(props) {
  const {
    testoBottone,
    compilaCampo,
    scelteCompilazioneVeloce,
    readOnly,
    ...restProps
  } = props

  if (!scelteCompilazioneVeloce || scelteCompilazioneVeloce.length === 0) {
    return null
  }
  
  let options = scelteCompilazioneVeloce
    .filter(({ contenuto }) => contenuto)
    .map(({ label, contenuto }) => ({
      label: `${label} - ${contenuto}`,
      onClick: () => compilaCampo(contenuto)
    }))

  if (options.length === 0) {
    options = [{
      label: '(nessuna opzione disponibile)'
    }]
  }
  
  return (
    <BaseMenu
      options={options}
      {...restProps}
      propsOpenButton={{
        testo: testoBottone,
        iconaInizio: ICONE.MODIFICA,
        ...(readOnly && { disabled: readOnly }),
        ...restProps.propsOpenButton
      }}
    />
  )
}