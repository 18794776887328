import { createContext, useContext, useEffect, useRef } from 'react'
import { node, string } from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useStateWithLabel } from 'utils'
import { useEntita } from './EntitaContext'

VaiAlMessaggioProvider.propTypes = {
  children: node.isRequired,
  urlPannelloCorrente: string.isRequired
}

const VaiAlMessaggioContext = createContext()

function VaiAlMessaggioProvider(props) {
  const { children, urlPannelloCorrente } = props

  /********* Logica per andare al messaggio *********/

  const [pathForm, setPathForm] = useStateWithLabel('', 'pathForm')
  const [nomeCampo, setNomeCampo] = useStateWithLabel('', 'nomeCampo')
  
  const {
    urlBase,
    funzioniAlbero: { trovaNodoAlberoConPath_PiuVicinoA }
  } = useEntita()

  const history = useHistory()

  function portaUtenteAlPannelloColMessaggio() {
    const nodoAlbero = trovaNodoAlberoConPath_PiuVicinoA(pathForm)
    const urlPannelloDestinazione = nodoAlbero.urlPannello
    if (urlPannelloCorrente !== urlPannelloDestinazione) {
      history.push(urlBase + urlPannelloDestinazione)
    }
  }
  
  const portaUtenteAlPannelloColMessaggio_Ref = useRef(portaUtenteAlPannelloColMessaggio)
  portaUtenteAlPannelloColMessaggio_Ref.current = portaUtenteAlPannelloColMessaggio
  
  useEffect(() => {
    if (!pathForm) return
    portaUtenteAlPannelloColMessaggio_Ref.current()
  }, [pathForm, nomeCampo])

  /**************************************************/

  /********* Info passate nel context *********/

  function vaiAlMessaggio(pathForm, nomeCampo) {
    setPathForm(pathForm)
    setNomeCampo(nomeCampo)
  }
  
  function pulisciInfoVaiAlMessaggio() {
    setPathForm('')
    setNomeCampo('')
  }
  
  const contextValue = {
    pathForm,
    nomeCampo,
    vaiAlMessaggio,
    pulisciInfoVaiAlMessaggio
  }

  /********************************************/

  return (
    <VaiAlMessaggioContext.Provider value={contextValue}>
      {children}
    </VaiAlMessaggioContext.Provider>
  )
}

function useVaiAlMessaggio() {
  return useContext(VaiAlMessaggioContext)
}

export {
  VaiAlMessaggioProvider,
  useVaiAlMessaggio
}