import { random } from 'lodash'

function isNumeric(value) {
  if (typeof value === 'number') return true
  if (typeof value !== 'string') return false

  value = value.replace(',', '.')
  return !isNaN(value) && !isNaN(parseFloat(value))
}

function isNumeroPositivo(value) {
  if (value === '') return true
  return isNumeric(value) && Number(value) >= 0
}

function isNumeroIntero(value) {
  if (value === '') return true
  return isNumeric(value) && Number.isInteger(Number(value))
}

export {
  isNumeric,
  isNumeroPositivo,
  isNumeroIntero,
  random as getRandomNumber
}