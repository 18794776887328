import { arrayOf, shape, string, bool } from 'prop-types'
import { VOTI } from 'feedback'
import { BaseListaImmagini } from 'images'
import useRichiestaServer from '../../network/RichiestaServerHook'
import DownloadFile from '../../util/DownloadFile'
import useEsportaImmagini from './LogicaEsportazioneImmagini'

ListaImmagini.propTypes = {
  immagini: arrayOf(shape({
    titolo: string,
    uuid: string.isRequired
  })).isRequired,
  abilitaEsportazione: bool,
  // Passare se abilitaEsportazione = true
  infoEntitaPrincipale: shape({
    nome: string.isRequired,
    uuid: string.isRequired
  })
}

export default function ListaImmagini(props) {
  const {
    immagini,
    abilitaEsportazione,
    infoEntitaPrincipale,
    ...restProps
  } = props

  const { getAttachmentURL } = useRichiestaServer()

  const immaginiConSrc = immagini.map(({ uuid, voto, ...rest }) => ({
    ...rest,
    uuid,
    voto: voto ?? VOTI.NEUTRO,
    src: {
      fullSize: getAttachmentURL(uuid, false),
      thumbnail: getAttachmentURL(uuid, true)
    }
  }))

  return (
    <BaseListaImmagini
      immagini={immaginiConSrc}
      ComponentePerDownloadFile={propsFile =>
        <DownloadFile
          {...propsFile}
          nascondiIconaFormatoFile
          nascondiNomeFile
        />
      }
      useGestioneFile={useRichiestaServer}
      {...(abilitaEsportazione && {
        useEsportaImmagini,
        infoExtraPerEsportazione: {
          entityUUID: infoEntitaPrincipale.uuid,
          entityName: infoEntitaPrincipale.nome
        }
      })}
      {...restProps}
    />
  )
}