import { string, object } from 'prop-types'
import { FormHelperText, FormLabel } from '@mui/material'
import { useTabellaModificabile, BaseTabella } from 'tables'
import { sx } from 'utils'
import useInputGenerico from './hooks/InputGenericoHook'
import useControllerSeServe from './hooks/ControllerSeServeHook'

BaseInputTabella.propTypes = {
  nomeEntita_PerMessaggioErroreRequired: string,
  propsUseTabellaModificabile: object,
  ...useInputGenerico.propTypes
}

export default function BaseInputTabella(props) {
  const {
    label,
    nomeEntita_PerMessaggioErroreRequired = 'una riga',
    tableContainerProps,
    propsUseTabellaModificabile = {},
    ...restProps
  } = props

  // Per una tabella modificabile, "required" significa che deve esserci almeno una riga
  // Inserisco una regola di validazione custom per controllare questa condizione
  let propsValidazioneRequired = {}
  if (restProps.required) {
    const { validazione = {}, messaggiErrore } = restProps
    propsValidazioneRequired = {
      validazione: {
        ...validazione,
        validate: {
          almenoUnaRiga: righe => righe.length > 0,
          ...validazione.validate
        }
      },
      messaggiErrore: {
        almenoUnaRiga: `Inserire almeno ${nomeEntita_PerMessaggioErroreRequired}`,
        ...messaggiErrore
      }
    }
  }

  const {
    error,
    warning,
    required,
    helperText,
    disabled,
    hidden,
    ...inputGenericoProps
  } = useInputGenerico({
    componenteControllato: true,
    underlyingComponent: 'tabella',
    ...restProps,
    ...propsValidazioneRequired
  })

  const {
    value: righe,
    onChange,
    onBlur,
    propsRimanenti
  } = useControllerSeServe(inputGenericoProps)

  const {
    setData: setData_Custom,
    ...altrePropsUseTabella
  } = propsUseTabellaModificabile

  const propsTabellaModificabile = useTabellaModificabile({
    setData(calcolaNuoveRighe_DaQuelleAttuali) {
      if (setData_Custom) {
        // Passare setData dall'esterno è necessario quando le righe della tabella
        // sono un sottoinsieme dei dati complessivi (es. tabella con filtri)
        // In questo modo all'interno della funzione calcolaNuoveRighe_DaQuelleAttuali
        // vengono passati i dati completi e non si perde nulla in fase di aggiornamento
        setData_Custom(calcolaNuoveRighe_DaQuelleAttuali)
      } else {
        const nuoveRighe = calcolaNuoveRighe_DaQuelleAttuali(righe)
        onChange(nuoveRighe)
      }
      onBlur() // Serve per triggerare la validazione in certi casi
    },
    ...altrePropsUseTabella
  })

  return (
    <BaseTabella
      data={righe}
      title={
        <>
          <FormLabel
            required={required}
            error={error}
            {...(warning && { sx: { color: 'warning.main' } })}
          >
            {label}
          </FormLabel>

          <FormHelperText
            error={error}
            {...(warning && { sx: { color: 'warning.main' } })}
          >
            {helperText}
          </FormHelperText>
        </>
      }
      tableContainerProps={{
        variant: 'outlined',
        ...tableContainerProps,
        ...sx(tableContainerProps, [
          warning && { borderColor: 'warning.main' },
          error && { borderColor: 'error.main' },
          hidden && { display: 'none' }
        ])
      }}
      {...propsTabellaModificabile}
      datiModificabili={!disabled}
      {...propsRimanenti}
    />
  )
}