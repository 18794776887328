import { forwardRef } from 'react'
import { object, string, node, elementType } from 'prop-types'
import { generatePath } from 'react-router-dom'
import { BaseLink } from '../../navigation/BaseLink'
import { useRoutes } from './RoutesProvider'

export const LinkPath = forwardRef(function LinkPath(props, ref) {
  const {
    chiave,
    params = {},
    append,
    children,
    as = BaseLink,
    ...linkProps
  } = props


  const elaboraAppend = () =>{
    if(append){
      if(append.startsWith('?')){
        return append
      }else{
        return '?'+append
      }
    }
    return ''
  }

  const { getPath } = useRoutes()
  const to = `${generatePath(getPath(chiave), params)}${elaboraAppend()}`
  const As = as

  return (
    <As
      ref={ref}
      to={to}
      {...linkProps}
    >
      {children}
    </As>
  )
})

LinkPath.propTypes = {
  chiave: string.isRequired,
  append: string,
  params: object,
  children: node.isRequired,
  as: elementType,
}