import { BaseFeedbackConIcona } from 'feedback'
import { ICONE } from 'icons'
import { BaseGridLayout, BaseHeaderConIcona } from 'layout'
import DownloadFile from '../../../util/DownloadFile'
import { isDocumentoGeneratoDaTemplate, creaDescrizioneDocumentoAllegato } from '../utilDocumenti'

export default function HeaderDocumentoAllegato(props) {
  const {
    documento,
    trovaTemplateConNome,
    gridProps,
    renderContenutoCustom,
    propsHeader: propsHeader_PassateDaFuori,
    propsDownloadFile,
    ...restProps
  } = props

  const isGenerato = isDocumentoGeneratoDaTemplate(documento)
  const propsHeader = isGenerato ? {
    icona: <ICONE.TEMPLATE_DOCUMENTO />,
    coloreIcona: 'warning.light',
    ...(trovaTemplateConNome && {
      sottotitolo: `Modello: ${trovaTemplateConNome(documento.template)?.descr || documento.template}`
    }),
    ...propsHeader_PassateDaFuori
  } : {
    icona: <ICONE.ALLEGATO />,
    sottotitolo: 'Caricato dall\'utente',
    ...propsHeader_PassateDaFuori
  }

  return (
    <BaseGridLayout alignCenter noWrap spacing={1} {...gridProps}>
      <BaseHeaderConIcona
        titolo={creaDescrizioneDocumentoAllegato(documento)}
        {...propsHeader}
        {...restProps}
      />

      {documento.isBozza ? (
        <BaseFeedbackConIcona.Info messaggio='Bozza' />
      ) : (
        <DownloadFile
          file={documento.allegato}
          nascondiIconaFormatoFile
          nascondiNomeFile
          mostraBottoneVisualizzaFile
          gridProps={{ spacing: 0 }}
          {...propsDownloadFile}
        />
      )}

      {renderContenutoCustom?.({ documento, isGenerato })}
    </BaseGridLayout>
  )
}