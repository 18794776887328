import { useMemo } from 'react'
import { object } from 'prop-types'
import { BaseScatolaConTitolo } from 'layout'
import { ENTITA_BASE } from '../EntitaBase'
import ElenchiManager, { ELENCHI } from '../../form/ElenchiManager'
import FormModificaEntita, { PROP_TYPES_WRAPPER_FORM_MODIFICA } from '../entitaGenerica/components/FormModificaEntita'
import SmartPanel from '../entitaGenerica/components/SmartPanel'
import SceltaAgenti from './SceltaAgenti'
import { fixListaAgenti } from './utilAgenti'

ListaAgentiModifica.propTypes = {
  ...PROP_TYPES_WRAPPER_FORM_MODIFICA,
  propsSceltaAgenti: object
}

ListaAgentiModifica.defaultConfig = {
  path: 'agenti',
  nomeEntita: ENTITA_BASE.AGENTI,
  url: 'agenti',
  label: 'Agenti',
  validate: validaAgenti
}

export default function ListaAgentiModifica(props) {
  const { label, isSubPanel, propsSceltaAgenti } = props

  return (
    <SmartPanel {...props}>
      <BaseScatolaConTitolo
        {...(isSubPanel && { titolo: label })}
        sx={{ witdh: 'fit-content' }}
      >
        <ElenchiManager
          elenchi={[ELENCHI.AGENTI]}
          render={el =>
            <FormAgenti
              agentiTutti={el(ELENCHI.AGENTI)}
              propsSceltaAgenti={propsSceltaAgenti}
              {...props}
            />
          }
        />
      </BaseScatolaConTitolo>
    </SmartPanel>
  )
}



function FormAgenti(props) {
  const {
    propsEntita: { valoriInput, ...propsEntita },
    label,
    agentiTutti,
    propsSceltaAgenti
  } = props

  const agentiScelti = useMemo(
    () => fixListaAgenti(valoriInput, agentiTutti),
    [agentiTutti, valoriInput]
  )

  return (
    <FormModificaEntita
      valoriInput={{ agentiScelti }}
      trasformaValoriOutput={({ agentiScelti }) => agentiScelti}
      {...propsEntita}
    >
      <SceltaAgenti
        label={label} name='agentiScelti'
        agentiScelti={agentiScelti}
        agentiTutti={agentiTutti}
        {...propsSceltaAgenti}
      />
    </FormModificaEntita>
  )
}



export function validaAgenti(utilValidazione) {
  const { valoriInput: agenti, error } = utilValidazione
  
  if (agenti.length === 0) {
    error('Inserire almeno un agente')
  }
}