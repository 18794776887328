import { func, number, bool, object } from 'prop-types'
import { Box } from '@mui/material'
import { BaseButtons } from 'inputs'
import ModalConForm from './ModalConForm'

ModalAggiuntaRiga.propTypes = {
  aggiungiRiga: func.isRequired,
  numeroRigheAttuali: number.isRequired,
  numeroMassimoRighe: number,
  nascondiComponenteAggiuntaRiga: bool,
  propsModal: object,
  propsModalAggiuntaRiga: object
}

export default function ModalAggiuntaRiga(props) {
  const {
    aggiungiRiga,
    numeroRigheAttuali,
    numeroMassimoRighe,
    nascondiComponenteAggiuntaRiga,
    propsModal,
    propsModalAggiuntaRiga
  } = props

  const daNascondere = nascondiComponenteAggiuntaRiga || (
    numeroMassimoRighe !== undefined
    && numeroRigheAttuali >= numeroMassimoRighe
  )

  return (
    <Box
      textAlign='center'
      p={1}
      display={daNascondere ? 'none' : 'block'}
    >
      <ModalConForm
        salvaDati={aggiungiRiga}
        labelBottoneApriModal='Aggiungi'
        renderBottoneApriModal={(openModal, labelBottoneApriModal) =>
          <BaseButtons.Aggiungi
            testo={labelBottoneApriModal}
            onClick={openModal}
            color='success'
          />
        }
        {...propsModal}
        {...propsModalAggiuntaRiga}
      />
    </Box>
  )
}