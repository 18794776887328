import { useRichiestaServer, BaseModal, FormCambioPassword, BaseButtons, BaseTextInput, BaseGridLayout, BaseForm  } from '@lucan/base-ui';
import MessageBox from 'components/MessageBox';
import Loading from 'components/Loading';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { OPERAZIONI } from 'Configurazione';
import Login2fa from './Login2fa';
import { Alert, Box } from '@mui/material';



const Login2faInit = ({username, onComplete, token, logout, ripristina = false}) => {

 //1 pulsante invio codice via mail
 //2 campo verifica codice via mail
 //3 visualizza qr e permetti invio codice autogenerato

	const [step, setStep] = useState(1)
	const nextStep = () => setStep(step+1)

	return(<>
			{step === 1 && <InviaMail token={token}  onSuccess={nextStep} ripristina={ripristina} />}
			{step === 2 && <ConfermaMail token={token} onSuccess={nextStep} />}
			{step === 3 && <>
				<Login2faQRcode token={token} />
				<Login2fa.LoginVerifyQRcode token={token} onComplete={onComplete}/>
			</>}

			<BaseButtons.Logout testo="torna al login" size={'small'} color={'default'} variant="outlined" onClick={logout}  className='full' />
		</>)

}

const InviaMail =  ({token, onSuccess, ripristina}) =>{

  const {
    inviaOperazione,
    inAttesaDelServer,
  } = useRichiestaServer()
	
	const [error, setError] = useState('')

	async function inviaMail(){
		setError('')
		const { ok, data, errorRichiesta } = await inviaOperazione(OPERAZIONI.TOTP_SEND_MAIL, {token})
		if (ok && data){
			onSuccess()
			return
		}
		setError(errorRichiesta?.message ?? "Impossibile inviare la mail");
				 
	}

	return(
		<>
			<Alert sx={{textAlign:'left',maxWidth:'320px', margin:'0 auto'}} icon={false} severity="info">
				<div style={{textAlign: 'center'}}>{Login2fa.MicrosoftLogo}</div>
				{ripristina ? 
					(<p>
						<h4 style={{marginTop:0}}>Ripristino autenticazione a 2 fattori</h4>
						Stai ripristinando l'autenticazione a 2 fattori.<br />
						Quando confermerai il codice attivazione inviato via mail, la vecchia matrice verrà disattivata e verrà mostrata una nuova matrice QR per inizializzare l'app 
					</p>
					):
					(<>
						<h4 style={{marginTop:0}}>Inizializzazione autenticazione a 2 fattori</h4>
						Il processo di attivazione dell'autenticazione a 2 fattori è composto da 3 passi:
						<ol>
							<li>Ricezione mail con un codice di conferma</li>
							<li>Inserimento codice codice di conferma</li>
							<li>Attivazione dispositivo mobile con QR Code</li>
						</ol>
					</>)
				}
			</Alert>

			<BaseButtons.Custom  color={'primary'} testo={"Invia Mail"} className='full' onClick={inviaMail}/>
		</>
	)

}
const ConfermaMail =  ({token, onSuccess}) =>{
	const {
    inviaOperazione,
    inAttesaDelServer,
  } = useRichiestaServer()


	const [error, setError] = useState('')
	useEffect(() => {
   
		function keyboardListener(e) {
			if (e.key === 'Enter') formRef?.current?.submit()
		}

		window.addEventListener('keydown', keyboardListener)
		return () => window.removeEventListener('keydown', keyboardListener)
 
	}, [])


	
	async function validacodice({code}){
		const { ok, data, error:errorRichiesta} = await inviaOperazione(OPERAZIONI.TOTP_VERIFY_MAIL,{token, code})

		if (ok){
			if(data){
				onSuccess()
			}else{
				setError("Codice non corretto")
			}				
		}else{
			setError(errorRichiesta.message);
		}		 
	}

	const formRef = useRef()

	return(<>
			<Loading loading={inAttesaDelServer} />
			{!inAttesaDelServer && error && <MessageBox  errorMsg={{main:error}}/>}
			<Alert sx={{textAlign:'left',maxWidth:'320px', margin:'0 auto'}} icon={false} severity="info">
				<h4 style={{marginTop:0}}>Passaggio 2 di 3</h4>
				Controllare la posta ed inserire il codice ricevuto via mail
			</Alert>
			<BaseForm
				onSubmit={validacodice}
				ref={formRef}
			>
				<BaseGridLayout vertical spacing={0}>
					<BaseTextInput  label='Codice Mail' name='code' defaultValue='' required />
					<BaseButtons.Invia className='full' submit  testo='Conferma'/>
				</BaseGridLayout>
			</BaseForm></>)


}
Login2faInit.ConfermaMail = ConfermaMail
Login2faInit.InviaMail = InviaMail

const Login2faQRcode =  ({token}) =>{

  const {
    inviaOperazione,
    inAttesaDelServer,
  } = useRichiestaServer()
	const [immagineQr, setimmagineQr] = useState('')
	const [error, setError] = useState('')
	async function ottieniQrCode(){
		const { ok, data, errorRichiesta } = await inviaOperazione(OPERAZIONI.TOTP_SETUP, {token, "applicazione":"login"})
		if (ok){
			setimmagineQr(data)
		}else{
			setError(errorRichiesta?.message ?? "Impossibile ottenere l'immagine QR");
		}		 
	}
	useEffect(() => {
		ottieniQrCode()
	}, [])

	return(<>
		<Loading loading={inAttesaDelServer} />
		{immagineQr !== '' && 
		<><img src={immagineQr} alt="qr accesso"/><br /> 
			<Alert sx={{textAlign:'left', maxWidth:'320px', margin:'0 auto'}} icon={false} severity="info">
				<Box >
					<h4 style={{marginTop:0}}>Passaggio 3 di 3</h4>
					Attivazione app autenticazione a 2 fattori
				</Box>
				
				<AiutoModal attivatore={<BaseButtons.Legenda testo="Istruzioni" size={'small'} color={'default'} variant="outlined"   />} />
			</Alert>
		</>}
		{!inAttesaDelServer && error && <MessageBox  errorMsg={{main:error}}/>}
	</>)


}


const AiutoModal = ({attivatore}) => {
		
	const [open, setOpen] = useState(false) 
	
	const attivatoreCloned = React.cloneElement(attivatore, {
		onClick: () => setOpen(true),
	});
	
	

	return(
		<>
			{attivatoreCloned}
			<BaseModal
				maxWidth='lg'
				open={open}
				onClose={() => setOpen(false)}
				disabilitaChiusuraCliccandoFuori={true}>
					<h1 style={{margin:"10px",color:"#1085ce"}}>Attivazione App autenticazione a 2 fattori</h1>
					<div>
						Scarica l'applicazione <b>Microsoft Authenticator</b> dal relativo store
						<br />
							{Login2fa.MicrosoftLogo}						
						<p>
							{Login2fa.PlayStoreMicrosoft}
							{Login2fa.AppStoreMicrosoft}
						</p>
						<p>
							<ol style={{"display":"inline-block","textAlign":"left"}}>
								<li>
									Aggiungi un account dalla schermata principale o selezionando il simbolo <b>+</b>
								</li>
								<li>
									Scegli la seconda voce <i>Account aziendale o dell'istituto di istruzione</i>
								</li>
								<li>
									Inquadra il QR code generato da connet, per attivare la generazione dei codici 
								</li>
							</ol>
							<img src={process.env.PUBLIC_URL +'/2fa/microsoft-authenticator-screenshot.jpg'} alt="appios" width="1020" height="688"/>
						</p>
					</div>
			</BaseModal>
		</>
	)
	
}


export default Login2faInit;