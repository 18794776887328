import { useContext, useEffect, useState, createContext, useRef } from 'react'
import { node, string, number, bool } from 'prop-types'
import { SsoIncloud, Ticker } from '@sso/sso-integration'
import { useUser, useUserUpdate, UserUpdateContext } from './userProvider/UserProvider'

const SsoContext = createContext()

SsoProvider.propTypes = {
  children: node,
  loginEndPoint: string,
  nameApp: string,
  expireCookieSeconds: number,
  tokenQueryString: bool, 
  log: bool,
  cookieAppendName: string
}

function SsoProvider(props) {
  const {
    children,
    loginEndPoint = '',
    nomeApp = '',
    titoloApp = '',
    expireCookieSeconds = 24*60*60,
    tokenQueryString = false, 
    log = false,
    cookieAppendName = '',
  } = props

  /***** Istanza SsoIncloud e gestione tokenState locale *****/

  const [sso] = useState(() =>
    new SsoIncloud(
      loginEndPoint,
      nomeApp,
      titoloApp,
      expireCookieSeconds,
      tokenQueryString,
      log,
      cookieAppendName
    )
  )

  const [tokenState, setTokenState] = useState(sso.getToken())

  function getToken() {
    const tokenFresco = sso.getToken()
    setTokenState(tokenFresco)
    return tokenFresco
  }

  function setToken(token) {
    sso.setToken(token)
    setTokenState(token)
  }

  function expireToken() {
    if (tokenState) {
      sso.expireToken()
      setTokenState(null)
    }
  }

  /***********************************************************/

  /***** Controllo coerenza token tra sessione/stato e cookie *****/

  const { isUserLoggato, userInfo } = useUser()
  const isUserLoggatoFlag = isUserLoggato()

  function isTokenChanged() {
    const tokenDaVerificare = isUserLoggatoFlag ? userInfo.token : tokenState
    const nuovoToken = getToken()
    return (nuovoToken !== tokenDaVerificare || !nuovoToken)
  }

  /****************************************************************/

  /***** Arricchimento handleLogout per eseguire expireToken *****/

  const modificaInfoUser = useUserUpdate()
  const {
    setIsSessioneScaduta,
    handleLogout: handleLogout_Originale
  } = modificaInfoUser

  // Cancella il cookie se il token è cooerente con la sessione,
  // altrimenti lo risparmia es (cambio utente in un altra app)
  // Dopo esegue la funzione handleLogout originale
  function handleLogout_Arricchito() {
    if (!isTokenChanged()) {
      expireToken()
    }
    handleLogout_Originale()
  }

  const modificaInfoUser_Arricchito = {
    ...modificaInfoUser,
    ssoAbilitato: true,
    handleLogout: handleLogout_Arricchito,
  }

  /***************************************************************/

  /***** Ticker per controllo sessione scaduta al focus sulla tab *****/

  // Se il token non è coerente con il cookie, esegue il logout
  function checkExpireSession() {
    // NON INVERTIRE LE 2 CONDIZIONI perché isTokenChanged ha l'effetto 
    // collatarale di fare setTokenState, quindi va chiamata sempre 
    if (isTokenChanged() && isUserLoggatoFlag) {
      setIsSessioneScaduta(true)
    }
  }

  const checkExpireSession_Ref = useRef(checkExpireSession)
  checkExpireSession_Ref.current = checkExpireSession

  const [ticker, setTicker] = useState(null)

  useEffect(() => {
    const serviceName = 'checkToken'
    let t = new Ticker()
    t.addService(() => checkExpireSession_Ref.current(), 0, serviceName)
    setTicker(t)

    return () => {
      ticker && ticker.removeService(serviceName)
    }
  }, [])

  /********************************************************************/

  // Redirezione sulla pagina di login dell'Sso
  function login(urlRitorno_DopoLogin) {
    if (!urlRitorno_DopoLogin) throw new Error('sso non abilitato, manca il backUrl')
    sso.login(urlRitorno_DopoLogin)
  }
  
  // Redirezione sulla pagina di logout dell'Sso
  function logout(urlRitorno_DopoLogin) {
    if (!urlRitorno_DopoLogin) throw new Error('sso non abilitato, manca il backUrl')
    sso.logout(urlRitorno_DopoLogin)
  }

  const contextValue = {
    getToken,
    setToken,
    expireToken,
    login,
    logout,
    tokenState
  }

  return (
    <SsoContext.Provider value={contextValue}>
      <UserUpdateContext.Provider value={modificaInfoUser_Arricchito}>
        {children}
      </UserUpdateContext.Provider>
    </SsoContext.Provider>
  )
}



function useSsoIncloud() {
  return useContext(SsoContext)
}

export {
  SsoProvider,
  useSsoIncloud
}