import { forwardRef } from 'react'
import { arrayOf, bool, func, node, object, shape, string } from 'prop-types'
import { Box, IconButton, Menu, MenuItem } from '@mui/material'
import { BasePopoverConfermaOperazione } from 'feedback'
import { ICONE } from 'icons'
import { BaseGridLayout } from 'layout'
import { BaseTesto } from 'text'
import { arricchisciPropOggetto, useStateWithLabel } from 'utils'
import { BaseButtons } from './BaseButtons'

BaseMenu.propTypes = {
  options: arrayOf(shape({
    label: node.isRequired,
    Icon: node,
    onClick: func,
    conferma: bool,
    messaggioConferma: string,
    propsPopoverConferma: object,
    propsMenuItem: object,
    renderCustomOption: func // Riceve la funzione chiudiMenu e le props dell'oggetto option
  })).isRequired,
  trePuntini: bool,
  renderOpenButton: func,
  callbackOpen: func,
  propsOpenButton: object,
  size: string,
}

export default function BaseMenu(props) {
  const {
    options,
    trePuntini,
    renderOpenButton,
    propsOpenButton,
    callbackOpen,
    size,
    ...propsMenu
  } = props

  const [anchorEl, setAnchorEl] = useStateWithLabel(null)

  function apriMenu(e) {
    setAnchorEl(e.currentTarget)
    callbackOpen?.(true)
  }

  function chiudiMenu() {
    setAnchorEl(null)
    callbackOpen?.(false)
  }

  return (
    <>
      {(() => {
        if (renderOpenButton) {
          return renderOpenButton({ apriMenu })
        }
        if (trePuntini) {
          return (
            <IconButton onClick={apriMenu}>
              <ICONE.TRE_PUNTINI />
            </IconButton>
          )
        }
        return (
          <BaseButtons.Custom
            testo='Menu'
            iconaFine={ICONE.DROPDOWN}
            onClick={apriMenu}
            size={size}
            {...propsOpenButton}
          />
        )
      })()}

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={chiudiMenu}
        {...propsMenu}
        {...arricchisciPropOggetto(propsMenu, 'anchorOrigin', {
          vertical: 'bottom',
          horizontal: 'center'
        })}
        {...arricchisciPropOggetto(propsMenu, 'transformOrigin', {
          vertical: 'top',
          horizontal: 'center'
        })}
      >
        {options.map(option => {
          const OpzioneMenu = option.renderCustomOption || OpzioneMenuDefault
          return (
            <OpzioneMenu
              key={option.label}
              chiudiMenu={chiudiMenu}
              {...option}
            />
          )
        })}
      </Menu>
    </>
  )
}

const OpzioneMenuDefault = forwardRef(function OpzioneMenuDefault(props, ref) {
  const {
    chiudiMenu,
    propsMenuItem,
    label,
    Icon,
    onClick,
    conferma,
    messaggioConferma,
    propsPopoverConferma
  } = props

  function onClickEChiudiMenu() {
    onClick()
    chiudiMenu()
  }

  const contenutoOpzione = (
    <BaseGridLayout alignCenter spacing={1}>
      <Box sx={{ pt: 1 }}>{Icon}</Box>
      <BaseTesto>{label}</BaseTesto>
    </BaseGridLayout>
  )

  return (
    <MenuItem ref={ref} {...propsMenuItem} {...(!conferma && {onClick:onClickEChiudiMenu})}>
      {conferma ? (
        <BasePopoverConfermaOperazione
          messaggio={messaggioConferma || `${label}?`}
          onConferma={onClickEChiudiMenu}
          onAnnulla={chiudiMenu}
          {...propsPopoverConferma}
        >
          {contenutoOpzione}
        </BasePopoverConfermaOperazione>
      ) : (
        contenutoOpzione
      )}
    </MenuItem>
  )
})