import { useLocation } from 'react-router-dom'
import { Box, Stack, Link } from '@mui/material'
import LogoIncloud from 'assets/img/LogoIncloud.png'
import { BaseDivider } from 'layout'
import { BaseTesto, BaseTitolo } from 'text'
import { useRoutes } from '../navigation/RoutesProvider'
import { useContextGenerico } from '../util/ContextGenericoProvider'

export default function Footer() {
  const { titoloApp, dataBuild, versione, versioneLibreria } = useContextGenerico()

  const { routeHome } = useRoutes()
  const { pathname } = useLocation()
  // Mostro il footer solo nella pagina home
  // Nelle altre pagine inserisco solo un piccolo padding 
  if (pathname !== routeHome.pathAssoluto) return <Box sx={{ pb: 1 }} />

  const sxLogo = {
    height: '51px',
    paddingTop: '4px',
    paddingBottom: '4px'
  }

  return (
    <Box sx={{ pb: 1, textAlign: 'center' }} className='footer'>
      <BaseDivider sx={{ borderColor: 'grey.400' }} />

      <Stack direction='row' spacing={0} sx={{ justifyContent: 'center' }}>
        <Box
          component='a'
          sx={sxLogo}
          href='https://www.incloudteam.com'
          target='_blank'
          rel='noreferrer'
        >
          <Box
            component='img'
            sx={sxLogo}
            src={LogoIncloud}
            alt='www.incloudteam.com'
          />
        </Box>

        <Stack direction='column' spacing={0} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', justifyContent: 'center' }}>
            <BaseTitolo noMargine>{titoloApp}</BaseTitolo>
            <Box component='span' sx={{ mx: 1 }}>di</Box>
            <BaseTesto sx={{ fontSize: '1.2em' }}>
              <Link
                href='https://www.incloudteam.com'
                underline='hover'
                target='_blank'
                rel='noreferrer'
              >
                in<b>cloud</b>team
              </Link>
            </BaseTesto>
          </Box>

          <BaseTesto>
            <Box component='span' sx={{ fontSize: '0.8em', ml: 3, lineHeight: '1.125' }}>
              Versione rilasciata il: {dataBuild} | Versione applicazione: {versione} | Versione libreria: {versioneLibreria}
            </Box>
          </BaseTesto>
        </Stack>
      </Stack>
    </Box>
  )
}