import { string, shape, oneOfType, number, func, bool } from 'prop-types'
import { BasePopoverConfermaOperazione } from 'feedback'
import { BaseButtons, BaseCheckbox, BaseSelect } from 'inputs'
import { BaseGridLayout, BaseScatolaConTitolo } from 'layout'
import { BaseCoppiaChiaveValore, BaseGrassetto, BaseTesto } from 'text'
import { generaOptionsAnniPassati, useStateWithLabel } from 'utils'
import { OPERAZIONI_BASE } from '../../network/OperazioniBase'
import useRichiestaServer from '../../network/RichiestaServerHook'
import { useUser } from '../../userManagement/userProvider/UserProvider'
import ModificaManualeNumeroRegistro from './ModificaManualeNumeroRegistro'
import { creaLabelNumeroEAnno, isNumeroRegistroAssegnato } from './utilNumeroRegistro'

const ANNO_CORRENTE = String(new Date().getFullYear())
const OPTIONS_ANNI_PASSATI = generaOptionsAnniPassati(4, true)

RichiestaNumeroRegistro.propTypes = {
  nomeEntita: string.isRequired,
  uuidEntita: string.isRequired,
  numeroEAnnoAttuali: shape({
    numero: oneOfType([string, number]).isRequired,
    annoRiferimento: number.isRequired
  }).isRequired,
  onNumeroOttenuto: func.isRequired,
  onErroreOttenimentoNumero: func,
  labelNumero: string,
  rottaModificaContatore: string,
  readOnly: bool,
  tuttoSalvato: bool,
  forzaSalvataggio: func
}

export default function RichiestaNumeroRegistro(props) {
  const {
    nomeEntita,
    uuidEntita,
    numeroEAnnoAttuali: {
      numero: numeroAttuale,
      annoRiferimento: annoAttuale
    },
    onNumeroOttenuto,
    onErroreOttenimentoNumero,
    labelNumero,
    rottaModificaContatore,
    readOnly,
    tuttoSalvato,
    forzaSalvataggio
  } = props

  const [anno, setAnno] = useStateWithLabel(ANNO_CORRENTE, 'anno')

  const {
    ScatolaMessaggi,
    inviaOperazione,
    inAttesaDelServer
  } = useRichiestaServer()

  async function richiediNumeroRegistro() {
    const { ok, data, error } = await inviaOperazione(OPERAZIONI_BASE.ENTITY_RICHIEDI_NUMERO, {
      entityName: nomeEntita,
      anno: Number(anno),
      uuidEntitaDaAggiornareSulServer: uuidEntita
    })

    if (ok) {
      const { numRegistro, anno } = data
      onNumeroOttenuto({
        numero: String(numRegistro),
        annoRiferimento: anno
      }, true)
    } else {
      if (onErroreOttenimentoNumero && error?.messaggi?.[0]?.codice === 'ILLEGAL_PARAMS') {
        onErroreOttenimentoNumero(error)
      }
    }
  }

  const { isUserStaff } = useUser()

  function eseguiModificaManualeNumero(nuovoNumero) {
    onNumeroOttenuto({
      numero: String(nuovoNumero),
      annoRiferimento: annoAttuale
    })
  }

  return (
    <BaseScatolaConTitolo
      titolo={`Numero ${labelNumero}`}
      sx={{ width: 'fit-content', minWidth: '30rem', boxSizing: 'border-box' }}
    >
      {isNumeroRegistroAssegnato(numeroAttuale) ? (
        <>
          <BaseCoppiaChiaveValore
            chiave={`Numero ${labelNumero} assegnato`}
            valore={creaLabelNumeroEAnno(numeroAttuale, annoAttuale)}
          />
          {isUserStaff() &&
            <ModificaManualeNumeroRegistro
              numeroAttuale={numeroAttuale}
              eseguiModificaNumero={eseguiModificaManualeNumero}
              rottaModificaContatore={rottaModificaContatore}
            />
          }
        </>
      ) : (
        <>
          {ScatolaMessaggi}

          <BaseTesto>Una volta assegnato il numero, la procedura esce dallo stato di "Bozza".</BaseTesto>
          <BaseGrassetto>L'assegnazione del numero è definitiva e non annullabile.</BaseGrassetto>

          <BaseGridLayout alignCenter>
            <BasePopoverConfermaOperazione
              messaggio="Confermare l'assegnazione del numero?"
              onConferma={richiediNumeroRegistro}
              onAperturaPopover={() => {
                forzaSalvataggio()
                // Aspetto un attimo e rifaccio l'invio per evitare di rimenare in attesa
                // se vengono messe in coda delle modifiche dopo l'apertura del popover
                setTimeout(() => forzaSalvataggio(), 3000)
              }}
              inAttesaPrimaDiChiedereConferma={!tuttoSalvato}
            >
              <BaseButtons.Custom
                testo={`Assegna numero ${labelNumero}`}
                color='success'
                inAttesa={inAttesaDelServer}
                disabled={readOnly || inAttesaDelServer}
              />
            </BasePopoverConfermaOperazione>

            <BaseCheckbox
              label="Per l'anno corrente"
              value={anno === ANNO_CORRENTE}
              onChange={e => setAnno(e.target.value ? ANNO_CORRENTE : OPTIONS_ANNI_PASSATI[0].value)}
              nonUsareHookForm
              readOnly={readOnly}
            />

            {anno !== ANNO_CORRENTE &&
              <BaseSelect
                label='Anni precedenti'
                options={OPTIONS_ANNI_PASSATI}
                value={anno}
                onChange={e => setAnno(e.target.value)}
                nonUsareHookForm
                readOnly={readOnly}
                sx={{ my: 0 }}
              />
            }
          </BaseGridLayout>
        </>
      )}
    </BaseScatolaConTitolo>
  )
}