import { useEffect, useMemo } from 'react'
import { CircularProgress, Box } from '@mui/material'
import { throttle } from '../Funzioni'
import useStateWithLabel from './StateWithLabel'

export default function useListaConScrollingInfinito(props) {
  const {
    dimensioneBloccoElementi,
    lista,
    elementoDOMcontenitore = document.documentElement,
    elementoDOMdaScrollare = window, // Di solito è il padre dell'elementoDOMcontenitore
    offsetScroll = 200, // Altezza non ancora scrollata che triggera l'aggiunta di altri elementi
    disabilitaResetScroll_QuandoListaCambia
  } = props
  const numeroTotaleElementi = lista.length

  const [
    numeroElementiAttualmenteVisualizzati, setNumeroElementiAttualmenteVisualizzati
  ] = useStateWithLabel(dimensioneBloccoElementi, 'numeroElementiAttualmenteVisualizzati')

  const ciSonoAltriElementiDaCaricare =
    (numeroTotaleElementi > numeroElementiAttualmenteVisualizzati)

  useEffect(() => {
    function caricaPiuElementiSeServe() {
      if (!ciSonoAltriElementiDaCaricare) return

      // Scelgo l'elemento da cui estrarre clientHeight e scrollTop
      // Se sto lavorando sulla window e non su un altro elemento specificato,
      // uso l'elementoDOMcontenitore. Altrimenti uso l'elementoDOMdaScrollare
      const elementoDaCuiEstrarre = (elementoDOMcontenitore === document.documentElement && elementoDOMdaScrollare === window)
        ? elementoDOMcontenitore
        : elementoDOMdaScrollare

      const {
        clientHeight: altezzaVisibile,
        scrollTop: altezzaGiaScrollata_NonVisibile,
      } = elementoDaCuiEstrarre

      const {
        scrollHeight: altezzaTotaleScrollabile
      } = elementoDOMcontenitore

      // La pagina è scrollata fino in fondo se:
      // altezzaVisibile + altezzaGiaScrollata_NonVisibile === altezzaTotaleScrollabile
      // Quindi (altezzaTotaleScrollabile - offsetScroll) significa che sono arrivato quasi in fondo
      const utenteHaScrollato_QuasiInFondoAlContenitore =
        (altezzaVisibile + altezzaGiaScrollata_NonVisibile >= altezzaTotaleScrollabile - offsetScroll)

      if (utenteHaScrollato_QuasiInFondoAlContenitore && ciSonoAltriElementiDaCaricare) {
        const numeroElementiDaVisualizzare_Incrementato =
          numeroElementiAttualmenteVisualizzati + dimensioneBloccoElementi

        setNumeroElementiAttualmenteVisualizzati(
          Math.min(numeroElementiDaVisualizzare_Incrementato, numeroTotaleElementi)
        )
      }
    }

    // Al primo render potrebbe non essere ancora disponibile l'elementoDOMdaScrollare
    if (!elementoDOMdaScrollare) return

    // Miglioro le performance controllando solo ogni 200 millisecondi
    const caricaPiuElementiSeServe_Throttled = throttle(caricaPiuElementiSeServe, 200)

    elementoDOMdaScrollare.addEventListener('scroll', caricaPiuElementiSeServe_Throttled)
    return () => elementoDOMdaScrollare.removeEventListener('scroll', caricaPiuElementiSeServe_Throttled)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numeroElementiAttualmenteVisualizzati, dimensioneBloccoElementi, numeroTotaleElementi, elementoDOMcontenitore, elementoDOMdaScrollare, offsetScroll])


  useEffect(() => {
    if (disabilitaResetScroll_QuandoListaCambia) return

    // Quando cambia la lista, resetta il numero di elementi visualizzati a quello iniziale
    // Si presuppone che tutti gli elementi siano cambiati, quindi non avrebbe senso 
    // renderizzare tantissimi elementi in un colpo solo e impattare le performance
    setNumeroElementiAttualmenteVisualizzati(dimensioneBloccoElementi)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dimensioneBloccoElementi, lista])

  const listaDaVisualizzare = useMemo(
    () => lista.slice(0, numeroElementiAttualmenteVisualizzati),
    [lista, numeroElementiAttualmenteVisualizzati]
  )

  return {
    listaDaVisualizzare,
    spinnerCaricamento: (
      <>
        {ciSonoAltriElementiDaCaricare &&
          <Box textAlign='center' m={3}>
            <CircularProgress />
          </Box>
        }
      </>
    )
  }
}