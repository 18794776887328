import { forEachValue, useStateWithLabel } from 'utils'
import BaseScatolaMessaggi from '../BaseScatolaMessaggi'

/**
 * 
 * @typedef {import("../../common/feedback/hooks/MessaggiBase").MessageType} MessageType
 * @typedef {import("../../common/feedback/hooks/MessaggiBase").MessageCollection} MessageCollection
 */

/**
 * @type {MessageType}
 */
const TIPI_MESSAGGI = {
  ERROR: 'ERROR',
  WARNING: 'WARNING',
  INFO: 'INFO',
  SUCCESS: 'SUCCESS'
}


/**
 * @type {MessageCollection}
 */
const ERRORI_STANDARD = {
  ERRORE_COMUNICAZIONE_CON_SERVER: {
    key: 'ERRORE_COMUNICAZIONE_CON_SERVER',
    msg: 'Si è verificato un errore di comunicazione con il server.'
  },
  ERRORE_INTERNO_AL_SERVER: {
    key: 'ERRORE_INTERNO_AL_SERVER',
    msg: 'Si è verificato un errore interno al server.'
  },
  ERRORE_SERVER_NON_RISPONDE: {
    key: 'ERRORE_SERVER_NON_RISPONDE',
    msg: 'Si è verificato un errore: il server non risponde.'
  },
  ERRORE_GENERICO: {
    key: 'ERRORE_GENERICO',
    msg: 'Si è verificato un errore.'
  }
}
forEachValue(ERRORI_STANDARD, messaggio => messaggio.tipo = TIPI_MESSAGGI.ERROR)

/**
 * @type {MessageCollection}
 */
const WARNING_STANDARD = {
  CREDENZIALI_ERRATE: {
    key: 'CREDENZIALI_ERRATE',
    msg: 'Attenzione: login fallito. Controllare che le credenziali inserite siano valide.'
  },
  SESSIONE_SCADUTA: {
    key: 'SESSIONE_SCADUTA',
    msg: 'Attenzione: la sessione corrente è scaduta.'
  }
}
forEachValue(WARNING_STANDARD, messaggio => messaggio.tipo = TIPI_MESSAGGI.WARNING)

function useMessaggiBase(MESSAGGI_CUSTOM = {}) {
  const MESSAGGI_TUTTI = {
    ...ERRORI_STANDARD,
    ...WARNING_STANDARD,
    ...MESSAGGI_CUSTOM
  }

  const [chiaviMessaggiCorrenti, setChiaviMessaggiCorrenti] = useStateWithLabel(new Set(), 'chiaviMessaggiCorrenti')

  function setMessaggio(messaggioDaAggiungere) {
    chiaviMessaggiCorrenti.add(messaggioDaAggiungere.key)
    setChiaviMessaggiCorrenti(new Set(chiaviMessaggiCorrenti))
  }

  // Ritorna true se ha effettivamente eliminato un errore che era presente
  function eliminaMessaggio(messaggioDaEliminare) {
    const cancellatoEffettivamente = chiaviMessaggiCorrenti.delete(messaggioDaEliminare.key)
    setChiaviMessaggiCorrenti(new Set(chiaviMessaggiCorrenti))
    return cancellatoEffettivamente
  }

  function eliminaMessaggioPassandoChiave(chiaveMessaggioDaEliminare) {
    return eliminaMessaggio({ key: chiaveMessaggioDaEliminare })
  }

  function eliminaTuttiMessaggi() {
    if (chiaviMessaggiCorrenti.size > 0) setChiaviMessaggiCorrenti(new Set())
  }

  return {
    MESSAGGI: MESSAGGI_TUTTI,
    setMessaggio,
    eliminaMessaggio,
    eliminaMessaggioPassandoChiave,
    eliminaTuttiMessaggi,
    ScatolaMessaggi: 
      <BaseScatolaMessaggi
        MESSAGGI={MESSAGGI_TUTTI}
        chiaviMessaggiCorrenti={Array.from(chiaviMessaggiCorrenti)}
        eliminaMessaggioPassandoChiave={eliminaMessaggioPassandoChiave}
      />
  }
}

export {
  useMessaggiBase,
  ERRORI_STANDARD,
  WARNING_STANDARD,
  TIPI_MESSAGGI
}
