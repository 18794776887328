import { useEffect } from 'react'
import useRichiestaServer from '../network/RichiestaServerHook'
import { useUser, useUserUpdate } from './userProvider/UserProvider'

export default function EseguiLogoutSeSessioneScaduta() {
  const { isSessioneScaduta } = useUser()
  const { handleLogout, ssoAbilitato } = useUserUpdate()

  const {
    OPERAZIONI: { LOGOUT },
    inviaOperazione
  } = useRichiestaServer()

  useEffect(() => {
    if (isSessioneScaduta) {
      if (!ssoAbilitato) inviaOperazione(LOGOUT)
      handleLogout()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSessioneScaduta, ssoAbilitato])

  return null
}
