import { useRef, useEffect } from 'react'

// Ritorna un booleano che dice se il componente è mounted
export default function useIsMounted() {
  const isMountedRef = useRef(true)

  useEffect(() => {
    return () => isMountedRef.current = false
  }, [])

  return isMountedRef.current
}