import { useState } from 'react'
import { string, object } from 'prop-types'
import { Popover } from '@mui/material'

BaseImageHover.propTypes = {
  url_small: string.isRequired,
  url_big: string.isRequired,
  popover: object,
  small: object,
  big: object
}

export default function BaseImageHover(props) {
  const {
    url_small,
    url_big,
    small,
    big,
    popover = {
      sx: {
        pointerEvents: 'none',
      },
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'left',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
      },
      disableRestoreFocus: true
    }
  } = props

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  function handlePopoverOpen(event) {
    setAnchorEl(event.currentTarget)
  }

  function handlePopoverClose() {
    setAnchorEl(null)
  }

  return (
    <>
      {url_small &&
        <>
          <img
            src={url_small}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            width={75}
            height='auto'
            {...small}
          />

          <Popover
            {...popover}
            open={open}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
          >
            <img
              src={url_big}
              width={300}
              height='auto'
              {...big}
            />
          </Popover>
        </>
      }
    </>
  )
}
