import { node } from 'prop-types'
import { Tooltip } from '@mui/material'

/**
 * @typedef {import("../common/feedback/BaseTooltip").BaseTooltipProps} BaseTooltipProps 
 * @typedef {import("../common/feedback/BaseTooltip").BaseTooltip} BaseTooltip 
 */

BaseTooltip.propTypes = {
  children: node.isRequired,
  contenuto: node.isRequired,
}

/**
 * 
 * @param {BaseTooltipProps} props 
 * @type {BaseTooltip}
 */
export default function BaseTooltip(props) {
  const {
    contenuto,
    children,
    ...restProps
  } = props

  return (
    <Tooltip
      title={contenuto}
      arrow
      {...restProps}
    >
      {children}
    </Tooltip>
  )
}
