import { any, bool, elementType, func, object, string } from 'prop-types'
import { Box, CircularProgress, IconButton } from '@mui/material'
import { BaseTooltip } from 'feedback'
import { ICONE } from 'icons'
import { sx } from 'utils'


/**
 * @typedef {import("../common/inputs/BaseIconButtons").BaseIconButtonProps} BaseIconButtonProps 
 * @typedef {import("../common/inputs/BaseIconButtons").BaseIconButton} BaseIconButton 
 * @typedef {import("../common/inputs/BaseIconButtons").BaseIconButtonsType} BaseIconButtonsType 
 */

BaseIconButton.propTypes = {
  contenutoTooltip: string.isRequired,
  Icon: elementType.isRequired,
  onClick: func,
  iconProps: object,
  tooltipProps: object,
  bordo: bool,
  inputRef: any,
  inAttesa: bool,
  noMarginY: bool
}

/**
 * 
 * @param {BaseIconButtonProps} props 
 * @type {BaseIconButton}
 */
function BaseIconButton(props) {
  const {
    contenutoTooltip,
    Icon,
    onClick,
    iconProps,
    tooltipProps,
    bordo,
    inputRef,
    inAttesa,
    noMarginY,
    ...iconButtonProps
  } = props

  return (
    <BaseTooltip contenuto={contenutoTooltip} {...tooltipProps}>
      {/* Lo span serve per avere il tooltip anche su bottoni disabilitati */}
      <Box component='span'>
        {inAttesa ? (
          <CircularProgress
            color='secondary' size={25}
            sx={{ mt: 2, py: 1 }}
          />
        ) : (
          <IconButton
            onClick={onClick}
            ref={inputRef}
            {...iconButtonProps}
            {...sx(iconButtonProps, [
              bordo && { border: 2, borderRadius: '50%', p: 0.5 },
              !noMarginY && { my: 1 }
            ])}
          >
            <Icon {...iconProps} />
          </IconButton>
        )}
      </Box>
    </BaseTooltip>
  )
}

/**
 * @type {BaseIconButtonsType}
 */
export const BaseIconButtons = {

  Custom(props) {
    return <BaseIconButton {...props} />
  },

  Aggiungi(props) {
    return (
      <BaseIconButton
        contenutoTooltip='Aggiungi'
        Icon={ICONE.AGGIUNGI}
        {...props}
      />
    )
  },

  Elimina(props) {
    return (
      <BaseIconButton
        contenutoTooltip='Elimina'
        color='secondary'
        Icon={ICONE.ELIMINA}
        {...props}
      />
    )
  },

  Modifica(props) {
    return (
      <BaseIconButton
        contenutoTooltip='Modifica'
        Icon={ICONE.MODIFICA}
        {...props}
      />
    )
  },

  Salva(props) {
    return (
      <BaseIconButton
        contenutoTooltip='Salva'
        Icon={ICONE.SALVA}
        {...props}
      />
    )
  },

  AnnullaModifiche(props) {
    return (
      <BaseIconButton
        contenutoTooltip='Annulla modifiche'
        Icon={ICONE.ANNULLA_MODIFICHE}
        {...props}
      />
    )
  },

  Chiudi(props) {
    return (
      <BaseIconButton
        contenutoTooltip='Chiudi'
        Icon={ICONE.CHIUDI}
        {...props}
      />
    )
  },

  Hamburger(props) {
    return (
      <BaseIconButton
        contenutoTooltip='Apri menu'
        Icon={ICONE.HAMBURGER}
        {...props}
      />
    )
  },

  Minimizza(props) {
    return (
      <BaseIconButton
        contenutoTooltip='Minimizza'
        Icon={ICONE.CHEVRON_LEFT}
        {...props}
        iconProps={{
          fontSize: 'large',
          ...props.iconProps
        }}
      />
    )
  },

  EspandiPiu(props) {
    return (
      <BaseIconButton
        contenutoTooltip='Espandi'
        Icon={ICONE.ESPANDI_PIU}
        {...props}
        iconProps={{
          style: { color: 'black' },
          ...props.iconProps
        }}
      />
    )
  },

  EspandiMeno(props) {
    return (
      <BaseIconButton
        contenutoTooltip='Minimizza'
        Icon={ICONE.ESPANDI_MENO}
        {...props}
        iconProps={{
          style: { color: 'black' },
          ...props.iconProps
        }}
      />
    )
  },

  AccountCerchio(props) {
    return (
      <BaseIconButton
        contenutoTooltip='Azioni account'
        Icon={ICONE.ACCOUNT_CERCHIO}
        color='inherit'
        {...props}
      />
    )
  },

  Scarica(props) {
    return (
      <BaseIconButton
        contenutoTooltip='Scarica'
        Icon={ICONE.SCARICA}
        {...props}
        iconProps={{
          style: { color: 'black' },
          ...props.iconProps
        }}
      />
    )
  },

  Aiuto(props) {
    return (
      <BaseIconButton
        contenutoTooltip='Aiuto'
        Icon={ICONE.AIUTO}
        {...props}
        iconProps={{
          fontSize: 'small',
          ...props.iconProps
        }}
        tooltipProps={{
          placement: 'right',
          ...props.tooltipProps
        }}
      />
    )
  },

  Conferma(props) {
    return (
      <BaseIconButton
        contenutoTooltip='Conferma'
        Icon={ICONE.CONFERMA}
        {...props}
      />
    )
  },

  Opzioni(props) {
    return (
      <BaseIconButton
        contenutoTooltip='Opzioni'
        Icon={ICONE.OPZIONI}
        {...props}
      />
    )
  },

  Avanti(props) {
    return (
      <BaseIconButton
        contenutoTooltip='Avanti'
        Icon={ICONE.CHEVRON_RIGHT}
        {...props}
        iconProps={{
          fontSize: 'large',
          ...props.iconProps
        }}
      />
    )
  },

  Indietro(props) {
    return (
      <BaseIconButton
        contenutoTooltip='Indietro'
        Icon={ICONE.CHEVRON_LEFT}
        {...props}
        iconProps={{
          fontSize: 'large',
          ...props.iconProps
        }}
      />
    )
  },

  Disattiva(props) {
    return (
      <BaseIconButton
        contenutoTooltip='Disattivato'
        Icon={ICONE.DISATTIVATO}
        {...props}
      />
    )
  },

  Visibile(props) {
    return (
      <BaseIconButton
        contenutoTooltip='Visibile'
        Icon={ICONE.VISIBILE}
        {...props}
      />
    )
  },

  Nascosto(props) {
    return (
      <BaseIconButton
        contenutoTooltip='Nascosto'
        Icon={ICONE.NASCOSTO}
        {...props}
      />
    )
  },

  Pulisci(props) {
    return (
      <BaseIconButton
        contenutoTooltip='Pulisci'
        Icon={ICONE.PULISCI}
        {...props}
      />
    )
  },

  Drag(props) {
    return (
      <BaseIconButton
        contenutoTooltip='Trascina'
        Icon={ICONE.DRAG}
        {...props}
      />
    )
  },
}
