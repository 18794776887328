import { BaseTextInput } from 'inputs'
import { BaseModal } from 'layout'
import { generaUUID } from 'utils'
import { ENTITA_BASE } from '../EntitaBase'
import FormModificaEntita from '../entitaGenerica/components/FormModificaEntita'
import ListaScatoleEntita from '../entitaGenerica/components/ListaScatoleEntita'
import HeaderContenutoMultimediale from './components/HeaderContenutoMultimediale'

export default function ModalContenutiMultimediali(props) {
  const {
    documentoInput,
    propsEntita,
    chiudiModifica
  } = props

  return (
    <BaseModal
      titolo='Contenuti multimediali associati al documento'
      open={true}
      onClose={chiudiModifica}
      contenutoNonCentrato
      maxWidth='md'
    >
      <ContenutoModalMetadati
        documentoInput={documentoInput}
        pathDocumento={propsEntita.pathEntita}
      />
    </BaseModal>
  )
}



function ContenutoModalMetadati(props) {
  const { documentoInput, pathDocumento } = props
  const { fileMultimediali = [] } = documentoInput

  return (
    <ListaScatoleEntita
      propsEntita={{
        nomeEntita: ENTITA_BASE.DOCUMENTO_FILE_MULTIMEDIALE,
        pathEntita: pathDocumento.addPath('fileMultimediali'),
        valoriInput: fileMultimediali
      }}
      renderEntita={contenuto => <HeaderContenutoMultimediale contenuto={contenuto} />}
      renderFormModifica={FormContenutoMultimediale}
      propsBottoneCrea={{
        testo: 'Aggiungi contenuto multimediale',
        opzioniCreazione: {
          entitaGiaCreata: {
            uuid: generaUUID(),
            titolo: '',
            note: ''
          }
        }
      }}
    />
  )
}



const NOMI = {
  TITOLO: 'titolo',
  NOTE: 'note'
}

function FormContenutoMultimediale(props) {
  const { propsEntita } = props

  return (
    <FormModificaEntita {...propsEntita} larghezzaInputs={40}>
      <BaseTextInput
        label='Titolo' name={NOMI.TITOLO}
      />
      <br/>
      <BaseTextInput
        label='Note' name={NOMI.NOTE}
        multiline rows={2}
      />
    </FormModificaEntita>
  )
}