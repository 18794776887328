import { useEffect, useMemo, useRef } from 'react'
import { array, arrayOf, bool, element, func, node, object, string } from 'prop-types'
import { BaseButtons, BaseForm, RiepilogoValoriCampi } from 'inputs'
import { BaseGridLayout, BaseScatola } from 'layout'
import { useDatiCondivisiTraRotte } from '../navigation/DatiCondivisiTraRotteProvider'
import useRichiestaServer from '../network/RichiestaServerHook'

/**
 * @typedef {import("../../common/incloudApps/form/FormRicerca").FormRicercaProps} FormRicercaProps
 * @typedef {import("../../common/incloudApps/form/FormRicerca").FormRicerca} FormRicerca
 * @typedef {import("../../common/incloudApps/form/FormRicerca").useFormRicercaConSalvataggioFiltri} useFormRicercaConSalvataggioFiltri
 */

FormRicerca.propTypes = {
  children: node.isRequired,
  // È utile passare i campi form generati solo se si vuole usare
  // la funzione di default per generare il riepilogo dei filtri
  campiFormGenerati: arrayOf(element),
  defaultValues: object.isRequired,
  defaultValuesPerReset: object,

  // Se viene passato nomeEntita, viene inviata l'operazione 
  // entity.search, e operationIdRicerca viene ignorato
  nomeEntita: string,
  operationIdRicerca: string,

  // Utile per passare info su paginazione e sort di entity.search
  // Passare oggetto memoizzato perché useEffect dipende da lui
  opzioniRichiesta: object, 
  setRicercaInCorso: func,
  resetPaginazione: func,
  altriArgomentiFunzioneRichiesta: array,
  setDati: func.isRequired,
  trasformaFiltri: func,
  creaRiepilogoFiltri: func,
  trasformaDati: func,
  gestisciErroreRicerca: func,
  onResetFiltri: func,
  conScatola: bool,
  cercaSubito: bool,
  cercaOnChangeTuttiCampi: bool,
  nascondiBottoni: bool,
  propsBottoneCerca: object,
  propsBottoneReset: object
}

function creaRiepilogoFiltri_Default(filtri, campiFormGenerati) {
  return campiFormGenerati ? (
    <RiepilogoValoriCampi
      campiGenerati={campiFormGenerati}
      mappaNomiCampi_ValoriCampi={filtri} />
  ) : (
    <>{JSON.stringify(filtri)}</>
  )
}

/**
 * 
 * @param {FormRicercaProps} props 
 * @type {FormRicerca}
 */
export default function FormRicerca(props) {
  const {
    children,
    campiFormGenerati,
    defaultValues,
    defaultValuesPerReset,
    nomeEntita,
    operationIdRicerca,
    opzioniRichiesta,
    setRicercaInCorso,
    resetPaginazione,
    altriArgomentiFunzioneRichiesta = [],
    setDati,
    trasformaFiltri = filtri => filtri,
    creaRiepilogoFiltri = creaRiepilogoFiltri_Default,
    trasformaDati = (dati, filtri) => dati,
    gestisciErroreRicerca = (error, filtri) => { },
    onResetFiltri,
    conScatola = true,
    cercaSubito,
    cercaOnChangeTuttiCampi,
    nascondiBottoni,
    propsBottoneCerca,
    propsBottoneReset,
    ...propsBaseForm
  } = props

  /***** Chiamata al server per la ricerca *****/

  const {
    ScatolaMessaggi,
    inviaOperazione,
    searchEntity,
    inAttesaDelServer: ricercaInCorso
  } = useRichiestaServer()

  const filtriUltimaRicerca = useRef(null)

  async function impostaFiltriRicerca(filtri, opzioni = {}) {
    const { eseguiResetPaginazione = true } = opzioni

    let opzioniRichiestaDefinitive = opzioniRichiesta
    if (eseguiResetPaginazione && resetPaginazione) {
      opzioniRichiestaDefinitive = resetPaginazione()
    }

    const filtriTrasformati = trasformaFiltri(filtri)

    const operazioneDaInviare = nomeEntita
      ? searchEntity(nomeEntita, filtriTrasformati, opzioniRichiestaDefinitive, ...altriArgomentiFunzioneRichiesta)
      : inviaOperazione(operationIdRicerca, filtriTrasformati, opzioniRichiestaDefinitive, ...altriArgomentiFunzioneRichiesta)

    const { ok, data, error } = await operazioneDaInviare
    if (ok) {
      setDati({
        filtri: { ...filtri },
        filtriTrasformati,
        RiepilogoFiltri: creaRiepilogoFiltri(filtri, campiFormGenerati),
        ...trasformaDati(data, filtri)
      })
      filtriUltimaRicerca.current = filtri
    }
    if (error) {
      gestisciErroreRicerca(error, filtri)
    }
  }

  /*********************************************/

  /* Se cambia opzioniRichiesta, rifai richiesta con gli ultimi filtri */

  const impostaFiltriRicerca_Ref = useRef(impostaFiltriRicerca)
  impostaFiltriRicerca_Ref.current = impostaFiltriRicerca

  useEffect(() => {
    if (filtriUltimaRicerca.current && opzioniRichiesta.page > 1) {
      impostaFiltriRicerca_Ref.current(filtriUltimaRicerca.current, {
        eseguiResetPaginazione: false
      })
    }
  }, [opzioniRichiesta])

  /*********************************************************************/

  const formRef = useRef()

  useEffect(() => {
    if (cercaSubito) formRef.current.submit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function onResetForm() {
    formRef.current?.reset(defaultValuesPerReset || defaultValues)
    onResetFiltri?.()
  }

  useEffect(() => {
    setRicercaInCorso?.(ricercaInCorso)
  }, [ricercaInCorso, setRicercaInCorso])

  const form = (
    <BaseForm
      ref={formRef}
      titolo='Criteri di ricerca'
      defaultValues={defaultValues}
      onSubmit={impostaFiltriRicerca}
      {...(cercaOnChangeTuttiCampi && {
        onChangeTuttiCampi: () => formRef.current.submit()
      })}
      inAttesa={ricercaInCorso}
      messaggioCampiModificati={!cercaOnChangeTuttiCampi &&
        <>
          Alcuni criteri sono stati modificati dall'ultima ricerca.
          <br />
          Premere il bottone "Cerca" per ottenere i dati relativi ai criteri attuali.
        </>
      }
      {...propsBaseForm}
    >
      {children}

      {!nascondiBottoni &&
        <BaseGridLayout sx={{ mt: 0.25 }}>
          <BaseButtons.Cerca {...propsBottoneCerca} />
          <BaseButtons.ResetForm
            testo='Imposta criteri iniziali'
            onClick={onResetForm}
            {...propsBottoneReset}
          />
        </BaseGridLayout>
      }
    </BaseForm>
  )

  return (
    <>
      {ScatolaMessaggi}

      {conScatola
        ? <BaseScatola>{form}</BaseScatola>
        : form
      }
    </>
  )
}


/**
 * @type {useFormRicercaConSalvataggioFiltri}
 */
export function useFormRicercaConSalvataggioFiltri(props) {
  const {
    chiaveSalvataggio,
    optionsSalvataggio,
    defaultValues,
    setDatiRicerca
  } = props

  const {
    getDatoCondiviso,
    setDatoCondiviso,
    eliminaDatoCondiviso
  } = useDatiCondivisiTraRotte()

  const filtriSalvatiInPrecedenza = useMemo(
    () => getDatoCondiviso(chiaveSalvataggio),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  function setDatiRicerca_ConSalvataggioFiltri(datiRicerca) {
    setDatoCondiviso(chiaveSalvataggio, datiRicerca.filtri, optionsSalvataggio)
    setDatiRicerca(datiRicerca)
  }

  function pulisciFiltriSalvati() {
    eliminaDatoCondiviso(chiaveSalvataggio)
  }

  return {
    defaultValues: filtriSalvatiInPrecedenza || defaultValues,
    defaultValuesPerReset: defaultValues,
    setDati: setDatiRicerca_ConSalvataggioFiltri,
    onResetFiltri: pulisciFiltriSalvati
  }
}