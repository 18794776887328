import { useEffect, useRef } from 'react'
import { string, node, object, bool, func } from 'prop-types'
import { Collapse } from '@mui/material'
import { BaseButtons } from 'inputs'
import { BaseDivider, BaseGridLayout, BaseScatolaConTitolo } from 'layout'
import { creaFunzioneUnica, useStateWithLabel } from 'utils'
import { checkValoriInputPresenti as checkValoriInputPresenti_Default } from '../../utils'
import BottoneCreaEntita from './BottoneCreaEntita'
import BottoneEliminaEntita from './BottoneEliminaEntita'
import { useEntita } from './EntitaContext'
import { PROP_TYPES_WRAPPER_FORM_MODIFICA } from './FormModificaEntita'
import LinkPannello from './LinkPannello'
import ListaMessaggiValidazione from './ListaMessaggiValidazione'
import { useVaiAlMessaggio } from './VaiAlMessaggioContext'

ScatolaEntita.propTypes = {
  ...PROP_TYPES_WRAPPER_FORM_MODIFICA,
  checkValoriInputPresenti: func,

  // Visualizzazione
  children: node,
  conDivider: bool,
  senzaScatola: bool,

  // Creazione
  noCrea: bool,
  testoBottoneCrea: string,
  renderModalCreazione: func, // Passata al BottoneCreaEntita
  propsBottoneCrea: object,
  isEntitaAppenaCreata: bool,
  disabilitaPortaUtenteAllaModifica: bool,

  // Modifica
  noModifica: bool,
  propsBottoneModifica: object,
  propsLinkModifica: object,
  // Passare se il bottone di modifica deve aprire una form
  // contestuale anziché essere un link ad un altro pannello
  renderFormModifica: func,

  // Eliminazione
  noElimina: bool,
  propsBottoneElimina: object,

  // Altre azioni possono essere inserite vicino al bottone modifica
  renderAltreAzioni: func
}

export default function ScatolaEntita(props) {
  const {
    propsEntita: { pathEntita, nomeEntita, valoriInput },
    label,
    checkValoriInputPresenti = checkValoriInputPresenti_Default,

    children,
    conDivider,
    senzaScatola,

    noCrea,
    testoBottoneCrea,
    renderModalCreazione,
    propsBottoneCrea,
    isEntitaAppenaCreata = false
  } = props

  const valoriInputPresenti = checkValoriInputPresenti(valoriInput)

  /* Gestione entità appena creata */

  const [appenaCreata, setAppenaCreata] = useStateWithLabel(false, 'appenaCreata')

  useEffect(() => {
    setAppenaCreata(isEntitaAppenaCreata)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEntitaAppenaCreata])

  useEffect(() => {
    if (!valoriInputPresenti) setAppenaCreata(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valoriInputPresenti])

  /*********************************/

  const contenuto = (
    <>
      {valoriInputPresenti ? (
        <EntitaConModificaEdEliminazione
          appenaCreata={appenaCreata}
          {...props}
        >
          {children}
        </EntitaConModificaEdEliminazione>
      ) : (
        !noCrea && (
          <BottoneCreaEntita
            testo={testoBottoneCrea}
            nomeEntita={nomeEntita}
            path={pathEntita}
            creazioneInLista={false}
            renderModalCreazione={renderModalCreazione}
            {...propsBottoneCrea}
            onCreazioneCompletata={creaFunzioneUnica(
              () => setAppenaCreata(true),
              propsBottoneCrea?.onCreazioneCompletata
            )}
          />
        )
      )}

      {conDivider && <BaseDivider sx={{ mt: 2 }} />}
    </>
  )

  if (senzaScatola) return contenuto

  return (
    <BaseScatolaConTitolo titolo={label}>
      {contenuto}
    </BaseScatolaConTitolo>
  )
}



function EntitaConModificaEdEliminazione(props) {
  const {
    children,
    propsEntita,

    renderAltreAzioni,

    noModifica,
    propsBottoneModifica,
    propsLinkModifica,
    renderFormModifica,
    appenaCreata = false,
    disabilitaPortaUtenteAllaModifica,

    noElimina,
    propsBottoneElimina
  } = props

  const { pathEntita, nomeEntita, urlPannello } = propsEntita

  const [formVisibile, setFormVisibile] = useStateWithLabel(false, 'formVisibile')
  const BottonePerFormModifica = formVisibile ? BaseButtons.Minimizza : BaseButtons.Modifica

  /* Porta l'utente alla modifica subito dopo la creazione */

  const linkRef = useRef()

  function portaUtenteAllaModifica() {
    if (noModifica || disabilitaPortaUtenteAllaModifica) return
    if (renderFormModifica) setFormVisibile(true)
    else linkRef.current?.click()
  }

  const portaUtenteAllaModifica_Ref = useRef(portaUtenteAllaModifica)
  portaUtenteAllaModifica_Ref.current = portaUtenteAllaModifica

  useEffect(() => {
    if (appenaCreata) portaUtenteAllaModifica_Ref.current()
  }, [appenaCreata])

  /*********************************************************/

  /************* Gestione messaggi validazione *************/

  const {
    gestioneValidazione: { getMessaggiConPathCheIniziaCon }
  } = useEntita()

  const { pathForm } = useVaiAlMessaggio()

  // L'utente può arrivare su questa form da un bottone "Vai al messaggio"
  // Se il pathForm del messaggio è relativo a questa scatola, apro la form
  useEffect(() => {
    if (renderFormModifica && pathForm === pathEntita) {
      setFormVisibile(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathForm])

  /*********************************************************/

  return (
    <>
      <BaseGridLayout alignCenter justifySpaceBetween>
        <>{children}</>

        <BaseGridLayout alignCenter>
          <ListaMessaggiValidazione
            messaggi={getMessaggiConPathCheIniziaCon(pathEntita)}
            hidden={renderFormModifica && formVisibile}
          />

          {renderAltreAzioni && renderAltreAzioni({ propsEntita, formVisibile, setFormVisibile })}

          {!noModifica && (
            renderFormModifica ? (
              <BottonePerFormModifica
                onClick={() => setFormVisibile(mostra => !mostra)}
                {...(formVisibile && { testo: 'Chiudi modifica' })}
                noMarginY
                {...propsBottoneModifica}
              />
            ) : (
              <LinkPannello
                to={urlPannello}
                linkRef={linkRef}
                {...propsLinkModifica}
              >
                <BaseButtons.Modifica noMarginY {...propsBottoneModifica} />
              </LinkPannello>
            )
          )}

          {!noElimina &&
            <BottoneEliminaEntita
              nomeEntita={nomeEntita}
              path={pathEntita}
              {...propsBottoneElimina}
            />
          }
        </BaseGridLayout>
      </BaseGridLayout>

      {!noModifica && renderFormModifica &&
        <Collapse in={formVisibile}>
          {renderFormModifica({ propsEntita, formVisibile, setFormVisibile })}
        </Collapse>
      }
    </>
  )
}