import { useMemo, useState } from 'react'
import { Box } from '@mui/material'
import { ICONE } from 'icons'
import { BaseRadioBoolean, BaseSelectModal } from 'inputs'
import { BaseHeaderConIcona } from 'layout'
import { filtraListaTemplate } from '../utilDocumenti'
import BadgeHtmlForm from './BadgeHtmlForm'

export default function SceltaTemplate(props) {
  const {
    templateDisponibili,
    tagsTemplate = [],
    labelModelliFiltratiPerTag = 'Solo modelli pertinenti',
    onTemplateScelto
  } = props

  const nessunTagPerFiltrare = tagsTemplate.length === 0
  const [mostraTutti, setMostraTutti] = useState(nessunTagPerFiltrare)

  const tagsConcatenati = tagsTemplate.join(',')

  const templateFiltrati = useMemo(() => {
    if (mostraTutti) return templateDisponibili
    return filtraListaTemplate(templateDisponibili, {
      tags: tagsConcatenati.split(',')
    })
  }, [mostraTutti, templateDisponibili, tagsConcatenati])

  return (
    <>
      {!nessunTagPerFiltrare &&
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: -2 }}>
          <BaseRadioBoolean
            labelTrue='Tutti i modelli'
            labelFalse={labelModelliFiltratiPerTag}
            value={mostraTutti}
            onChange={e => setMostraTutti(e.target.value)}
          />
        </Box>
      }

      <BaseSelectModal
        onChange={e => onTemplateScelto(e.target.value)}
        options={templateFiltrati}
        renderOption={template =>
          <BaseHeaderConIcona
            titolo={template.descr || template.nome}
            sottotitolo={<BadgeHtmlForm template={template} />}
            icona={<ICONE.TEMPLATE_DOCUMENTO />}
            coloreIcona='warning.light'
            senzaPadding={false}
          />
        }
        getOptionKey={({ id }) => id}
        getOptionLabel={({ descr }) => descr}
        getOptionSearchString={({ descr }) => descr}

        soloContenutoModal
        nascondiRiassuntoOpzioniSelezionate
        labelRicerca='nome modello'
      />
    </>
  )
}