import { trasformaInArray_SeElementoSingolo } from './Array'

// Facilita l'uso della prop sx per personalizzare lo stile dei componenti Mui
// Fare lo spread del risultato della funzione DOPO LO SPREAD DELLE PROPS DEL PADRE
// Esempio:
// <BaseTesto
//   {...props}
//   {...sx(props, { fontWeight: 'fontWeightBold' })}
// >
function sx(propsProvenientiDalPadre = {}, sxDaUnire = []) {
  const { sx: sxProvenienteDalPadre } = propsProvenientiDalPadre
  return {
    sx: [
      ...trasformaInArray_SeElementoSingolo(sxDaUnire),
      ...trasformaInArray_SeElementoSingolo(sxProvenienteDalPadre)
    ]
  }
}

export {
  sx
}