import { useMemo } from 'react'

// Esempio options
// const OPTIONS_STATO_VIOLAZIONE = [
//   { value: CAMPO_DA_SCARTARE, label: 'Tutti' },
//   { value: 'pagato', label: 'Pagato' },
//   { value: 'nonPagato', label: 'Non pagato' },
//   { value: 'notificato', label: 'Notificato' },
//   { value: 'annullato', label: 'Annullato' }
// ]

// Esempio optionGenerator
// const OPTIONS_ANNO_COMPETENZA_generator = ({ numeroAnniPassati }) => [
//   { value: CAMPO_DA_SCARTARE, label: 'Tutti' },
//   ...generaOptionsAnniPassati(numeroAnniPassati)
// ]

// Esempio campo predefinito
// const ANNO_COMPETENZA = {
//   label: 'Anno competenza',
//   name: 'infrazioneAnnoRegistro',
//   options: OPTIONS_ANNO_COMPETENZA_generator({ numeroAnniPassati: 3 }),
//   optionGenerator: OPTIONS_ANNO_COMPETENZA_generator,
//   Input: BaseSelect,
//   defaultValue: CAMPO_DA_SCARTARE,
//   props: {},
//   aiuto: 'Aiutoooo'
// }

const CAMPO_NON_PRESENTE = <span key='campoNonPresente'>CAMPO NON PRESENTE</span>

// Riceve come argomento un oggetto o un array di oggetti che descrivono i campi da generare (vedi esempi sopra)
// Se viene passato un oggetto (descrizione di un singolo campo), restituisce l'oggetto creato dal metodo "generaSingoloCampo"
// Se viene passato un array (descrizione di più campi), restituisce un oggetto che contiene:
// - campi: array di campi generati (elementi React pronti per la renderizzazione)
// - defaultValues: oggetto che mappa ogni name di un campo al default value corrispondente
// - messaggiAiuto: oggetto che mappa ogni name di un campo al messaggio di aiuto corrispondente
export default function useCampiForm(listaDescrizioniCampi) {
  return useMemo(() => {
    if (Array.isArray(listaDescrizioniCampi)) {
      // I default values ed i messaggi di aiuto sono costruiti progressivamente insieme ai campi
      const defaultValues = {}
      const messaggiAiuto = {}
  
      const campiGenerati = listaDescrizioniCampi.map(descrizioneCampo => {
        const { campo, name, defaultValue, aiuto } = generaSingoloCampo(descrizioneCampo)
        defaultValues[name] = defaultValue
        messaggiAiuto[name] = aiuto
        return campo
      })
  
      return {
        campi: campiGenerati,
        defaultValues,
        messaggiAiuto
      }
    }

    return generaSingoloCampo(listaDescrizioniCampi)
  }, [listaDescrizioniCampi])
}

// Riceve come argomento una descrizione di un singolo campo da generare
// Restituisce un oggetto che contiene:
// - campo: campo generato (elemento React) con le props oppurtune
// - name
// - defaultValue
// - aiuto
function generaSingoloCampo(descrizioneCampo) {
  const {
    campo: campo_Predefinito,
    props: props_PassateDaFuori = {},
    defaultValue: defaultValue_PassatoDaFuori,
    parametriOptionGenerator,
    custom: customInputGiaPronto,
    aiuto: aiuto_PassatoDaFuori
  } = descrizioneCampo

  // Se non è presente né un campo predefinito, né un input custom, ritorna un elemento placeholder
  if (!campo_Predefinito && !customInputGiaPronto) {
    return { campo: CAMPO_NON_PRESENTE } 
  }

  // Se viene specificato un input custom (non presente tra quelli predefiniti), ritornalo
  if (customInputGiaPronto) {
    const nameCustom = customInputGiaPronto.props.name
    return {
      campo: {
        ...customInputGiaPronto,
        key: nameCustom
      },
      name: nameCustom,
      defaultValue: defaultValue_PassatoDaFuori,
      aiuto: aiuto_PassatoDaFuori
    } 
  }

  // Da qui in poi siamo nel caso del campo predefinito
  const {
    label,
    name: name_Predefinito,
    options: options_Predefinite,
    optionGenerator,
    Input,
    defaultValue: defaultValue_Predefinito,
    props: props_Predefinite,
    readOnly,
    aiuto: aiuto_Predefinito
  } = campo_Predefinito

  // Imposta le props essenziali
  const nameDefinitivo = props_PassateDaFuori.name || name_Predefinito
  let props_Essenziali = {
    label,
    name: nameDefinitivo,
    readOnly,
    key: nameDefinitivo
  }

  // Aggiungi le options per i campi che ne hanno bisogno
  // Alcuni campi prevedono options generate dinamicamente da una funzione optionGenerator
  if (Input.campoConOptions) {
    // parametriOptionGenerator deve essere un oggetto
    props_Essenziali.options = (optionGenerator && parametriOptionGenerator)
      ? optionGenerator(parametriOptionGenerator)
      : options_Predefinite
  }

  // Metti insieme tutte le prop, lasciando la possibilità di sovrascrivere
  // le props predefinite ed essenziali con quelle passate da fuori
  const propsDefinitive = {
    ...props_Predefinite,
    ...props_Essenziali,
    ...props_PassateDaFuori
  }

  return {
    campo: <Input {...propsDefinitive} />,
    name: nameDefinitivo,
    defaultValue: defaultValue_PassatoDaFuori ?? defaultValue_Predefinito,
    aiuto: aiuto_PassatoDaFuori || aiuto_Predefinito
  }
}