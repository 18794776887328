import { forwardRef } from 'react'
import { any, bool, element, elementType, oneOfType, string } from 'prop-types'
import { Button } from '@mui/material'
import { ICONE } from 'icons'
import { sx } from 'utils'
import { BaseButtonConExtra } from './BaseButtonConExtra'

/**
 * @typedef {import("../common/inputs/BaseButtons").BaseButtonProps} BaseButtonProps 
 * @typedef {import("../common/inputs/BaseButtons").BaseButton} BaseButton 
 * @typedef {import("../common/inputs/BaseButtons").BaseButtonsType} BaseButtonsType 
 */

/**
 * 
 * @param {BaseButtonProps} props 
 * @type {BaseButton}
 */
const BaseButton = forwardRef(function BaseButton(props, ref) {
  // Se ci sono alcune props, renderizza un bottone con funzionalità extra
  const { submit, esporta, ...restProps } = props
  if (esporta || submit || restProps.inAttesa) {
    return <BaseButtonConExtra ref={ref} {...props} />
  }

  const {
    testo,
    noMarginY,
    iconaInizio: IconaInizio,
    iconaInizioColore = 'inherit',
    iconaFine: IconaFine,
    iconaFineColore = 'inherit',
    color,
    conDivWrapper,
    inputRef,
    children,

    // Estrai le prop delle funzionalità extra per evitare di passarle sotto
    inAttesa,
    ciSonoErrori,
    messaggioToast,
    propsProgress,

    ...buttonProps
  } = restProps

  // Il colore 'default' era un colore che esisteva in MUI v4
  // Se mi arriva questo valore, lo trasformo in 'inherit'
  const colorDefinitivo = (color === 'default') ? 'inherit' : color

  const propsSeDisabled = buttonProps.disabled ? {
    onClick: () => { }
  } : {}

  const button = (
    <Button
      variant='contained'
      color={colorDefinitivo}
      type='button'
      ref={inputRef}
      {...(IconaInizio && { startIcon: <IconaInizio color={iconaInizioColore} /> })}
      {...(IconaFine && { endIcon: <IconaFine color={iconaFineColore} /> })}
      {...buttonProps}
      {...sx(buttonProps, !noMarginY && { my: 1 })}
      {...propsSeDisabled}
    >
      {testo}{children}
    </Button>
  )

  return conDivWrapper ? <div>{button}</div> : button
})

BaseButton.propTypes = {
  testo: oneOfType([string, element]),
  noMarginY: bool,
  iconaInizio: elementType,
  iconaFine: elementType,
  color: string,
  conDivWrapper: bool,
  inputRef: any,
  ...BaseButtonConExtra.propTypes
}


/**
 * @type {BaseIconButtonsType}
 */
const BaseButtons = {
  Custom(props) {
    return <BaseButton {...props} />
  },

  Login(props) {
    return (
      <BaseButton
        testo='Accedi'
        iconaInizio={ICONE.LOGIN}
        submit
        {...props}
      />
    )
  },

  Logout(props) {
    return (
      <BaseButton
        testo='Logout'
        iconaInizio={ICONE.LOGOUT}
        color='secondary'
        {...props}
      />
    )
  },

  Cerca(props) {
    return (
      <BaseButton
        testo='Cerca'
        iconaInizio={ICONE.CERCA}
        submit
        {...props}
      />
    )
  },

  Modifica(props) {
    return (
      <BaseButton
        testo='Modifica'
        iconaInizio={ICONE.MODIFICA}
        {...props}
      />
    )
  },

  AnnullaModifiche(props) {
    return (
      <BaseButton
        testo='Annulla modifiche'
        iconaInizio={ICONE.ANNULLA_MODIFICHE}
        {...props}
      />
    )
  },

  Aggiorna(props) {
    return (
      <BaseButton
        testo='Aggiorna'
        iconaInizio={ICONE.AGGIORNA}
        {...props}
      />
    )
  },

  Dettagli(props) {
    return (
      <BaseButton
        testo='Dettagli'
        iconaFine={ICONE.CHEVRON_RIGHT}
        {...props}
      />
    )
  },

  MenoDettagli(props) {
    return (
      <BaseButton
        testo='Meno dettagli'
        iconaInizio={ICONE.CHEVRON_LEFT}
        {...props}
      />
    )
  },

  Aggiungi(props) {
    return (
      <BaseButton
        testo='Aggiungi'
        iconaInizio={ICONE.AGGIUNGI}
        {...props}
      />
    )
  },

  CaricaFile(props) {
    return (
      <BaseButton
        testo='Carica file'
        iconaInizio={ICONE.CARICA}
        color='default'
        {...props}
      />
    )
  },

  Esporta: forwardRef(function Esporta(props, ref) {
    return (
      <BaseButton
        testo='Esporta'
        iconaInizio={ICONE.ESPORTA}
        esporta
        ref={ref}
        {...props}
      />
    )
  }),

  Elimina(props) {
    return (
      <BaseButton
        testo='Elimina'
        iconaInizio={ICONE.ELIMINA}
        color='secondary'
        {...props}
      />
    )
  },

  Impostazioni(props) {
    return (
      <BaseButton
        testo='Impostazioni'
        iconaInizio={ICONE.OPZIONI}
        color='default'
        {...props}
      />
    )
  },

  Salva(props) {
    return (
      <BaseButton
        testo='Salva'
        submit
        iconaInizio={ICONE.SALVA}
        {...props}
      />
    )
  },

  Conferma(props) {
    return (
      <BaseButton
        testo='Conferma'
        iconaInizio={ICONE.CONFERMA}
        {...props}
      />
    )
  },

  Annulla(props) {
    return (
      <BaseButton
        testo='Annulla'
        iconaInizio={ICONE.CHIUDI}
        {...props}
      />
    )
  },

  Legenda(props) {
    return (
      <BaseButton
        testo='Legenda'
        color='default'
        iconaInizio={ICONE.AIUTO}
        {...props}
      />
    )
  },

  ResetForm(props) {
    return (
      <BaseButton
        testo='Pulisci campi'
        color='default'
        iconaInizio={ICONE.ANNULLA_MODIFICHE}
        {...props}
      />
    )
  },

  Chiudi(props) {
    return (
      <BaseButton
        testo='Chiudi'
        color='default'
        iconaInizio={ICONE.CHIUDI}
        {...props}
      />
    )
  },

  Invia(props) {
    return (
      <BaseButton
        testo='Invia'
        iconaInizio={ICONE.INVIA}
        {...props}
      />
    )
  },

  Accetta(props) {
    return (
      <BaseButton
        testo='Accetta'
        iconaInizio={ICONE.POLLICE_SU}
        {...props}
      />
    )
  },

  Rifiuta(props) {
    return (
      <BaseButton
        testo='Rifiuta'
        iconaInizio={ICONE.POLLICE_GIU}
        {...props}
      />
    )
  },

  Minimizza(props) {
    return (
      <BaseButton
        testo='Minimizza'
        iconaInizio={ICONE.ESPANDI_MENO}
        {...props}
      />
    )
  }
}

export {
  BaseButton,
  BaseButtons
}
