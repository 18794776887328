import { func, bool, object, node } from 'prop-types'
import { Alert } from '@mui/material'
import { BaseButtons, BaseIconButtons } from 'inputs'
import { BaseGridLayout } from 'layout'
import BasePopoverConfermaOperazione from './BasePopoverConfermaOperazione'

BaseBottoneEFeedbackEliminazione.propTypes = {
  mostraMessaggioEliminazione: bool.isRequired,
  messaggioEliminazione: node,
  azioneDopoEliminazione: node,
  onConfermaEliminazione: func.isRequired,
  inAttesaEliminazione: bool,
  propsAlert: object,
  propsPopoverConferma: object,
  propsBottoneElimina: object,
  tooltipAiuto: node,
  tooltipAiutoProps: object
}

export default function BaseBottoneEFeedbackEliminazione(props) {
  const {
    mostraMessaggioEliminazione,
    messaggioEliminazione = 'Eliminazione effettuata con successo',
    azioneDopoEliminazione,
    onConfermaEliminazione,
    inAttesaEliminazione = false,
    propsAlert,
    propsPopoverConferma,
    propsBottoneElimina,
    tooltipAiuto,
    tooltipAiutoProps
  } = props

  if (mostraMessaggioEliminazione) {
    return (
      <div>
        <Alert
          elevation={5}
          variant='filled'
          severity='success'
          {...propsAlert}
        >
          {messaggioEliminazione}
        </Alert>

        <br />

        {azioneDopoEliminazione}
      </div>
    )
  }

  return (
    <BaseGridLayout alignCenter>
      {tooltipAiuto &&
        <BaseIconButtons.Aiuto
          contenutoTooltip={tooltipAiuto}
          tooltipProps={{
            placement: 'top',
            ...tooltipAiutoProps
          }}
        />
      }

      <BasePopoverConfermaOperazione
        messaggio="Confermare l'eliminazione?"
        onConferma={onConfermaEliminazione}
        {...propsPopoverConferma}
      >
        <BaseButtons.Elimina
          inAttesa={inAttesaEliminazione}
          submit
          {...propsBottoneElimina}
        />

      </BasePopoverConfermaOperazione>
    </BaseGridLayout>
  )
}