import { object, func, shape, string } from 'prop-types'
import { BaseModal, useModal } from 'layout'

ModalConForm.propTypes = {
  renderForm: func.isRequired, // Riceve salvaDati, defaultValues e closeModal
  renderBottoneApriModal: func, // Riceve la funzione openModal
  labelBottoneApriModal: string,
  salvaDati: func.isRequired, // Riceve i valori del submit della form (tra cui l'uuid)
  defaultValues: object,
  // Per controllare la modal anche dall'esterno, il padre 
  // può chiamare lui useModal e poi passare i risultati 
  risultatiUseModal: shape({
    modalRef: object.isRequired,
    openModal: func.isRequired,
    closeModal: func.isRequired
  })
}

export default function ModalConForm(props) {
  const {
    renderForm,
    renderBottoneApriModal,
    labelBottoneApriModal,
    salvaDati,
    defaultValues,
    risultatiUseModal: risultatiUseModal_Prop,
    ...restProps
  } = props

  const risultatiUseModal_Locali = useModal()
  const risultatiUseModal_Definitivi = risultatiUseModal_Prop || risultatiUseModal_Locali
  const { modalRef, openModal, closeModal } = risultatiUseModal_Definitivi

  return (
    <>
      {renderBottoneApriModal(openModal, labelBottoneApriModal, defaultValues)}

      <BaseModal
        ref={modalRef}
        disabilitaChiusuraCliccandoFuori
        {...restProps}
      >
        {renderForm({ salvaDati, defaultValues, closeModal })}
      </BaseModal>
    </>
  )
}