import { useEffect, useState } from 'react'
import { bool, instanceOf, arrayOf, shape, string, oneOfType, oneOf, object } from 'prop-types'
import { Box, TextField } from '@mui/material'
import { DesktopDatePicker, DesktopDateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { startOfDay, endOfDay, isValid } from 'date-fns'
import { it } from 'date-fns/locale'

import { ICONE } from 'icons'
import { BaseDivider, BaseGridLayout } from 'layout'
import { BaseTitolo } from 'text'
import { OPZIONI_RAPIDE_DATE_PASSATE, OPZIONI_RAPIDE_DATE_FUTURE, DATE_RAPIDE, getDataRapida, convertiDataSeServe } from 'utils'
import useInputGenerico from './hooks/InputGenericoHook'
import useControllerSeServe from './hooks/ControllerSeServeHook'
import { creaRegoleValidazioneData } from './validazione/RegoleCustom'
import { unisciMessaggiERegoleValidazione } from './validazione/UtilValidazione'
import { BaseButtons } from './BaseButtons'
import BaseSelect from './BaseSelect'



BaseDateInput.propTypes = {
  minDate: instanceOf(Date),
  maxDate: instanceOf(Date),
  disablePast: bool, // Ignorato se viene passata minDate
  disableFuture: bool, // Ignorato se viene passata maxDate
  dateTime: bool, // per abilitare l'ora
  portaOraAFineGiornata: bool,
  disabilitaToolbarCustom: bool,
  opzioniRapide: oneOfType([
    oneOf(['passate', 'future', 'tutte']),
    arrayOf(shape({
      label: string.isRequired,
      value: oneOf(Object.values(DATE_RAPIDE))
    }))
  ]),
  propsDatePicker: object,
  ...useInputGenerico.propTypes
}

export default function BaseDateInput(props) {
  const {
    minDate,
    maxDate,
    disablePast,
    disableFuture,
    portaOraAFineGiornata,
    disabilitaToolbarCustom,
    dateTime,
    opzioniRapide,
    propsDatePicker,
    ...restProps
  } = props

  const {
    validazione: validazioneOriginale = {},
    messaggiErrore: messaggiOriginali
  } = restProps

  function uniformaData(dataConOra) {
    if (!dataConOra) return null
    const dataConOra_Parsata = convertiDataSeServe(dataConOra)
    if (dateTime) return dataConOra_Parsata
    return portaOraAFineGiornata
      ? endOfDay(dataConOra_Parsata)
      : startOfDay(dataConOra_Parsata)
  }

  const minDate_Definitiva = uniformaData(minDate || (disablePast && new Date()))
  const maxDate_Definitiva = uniformaData(maxDate || (disableFuture && new Date()))

  const { validate, messaggi } = creaRegoleValidazioneData(minDate_Definitiva, maxDate_Definitiva)

  const inputGenericoProps = useInputGenerico({
    componenteControllato: true,
    widthType: 'width',
    ...restProps,
    ...unisciMessaggiERegoleValidazione(validazioneOriginale, validate, messaggiOriginali, messaggi)
  })

  const {
    value,
    onBlur,
    onChange,
    ref,
    propsRimanenti
  } = useControllerSeServe(inputGenericoProps)

  // Serve controllare da qui lo stato di apertura del popup per permettere
  // la chiusura quando viene scelta una data con le opzioni rapide
  // Questo stato rimane sincronizzato con quello interno del
  // date picker tramite gli eventi onOpen e onClose
  const [popupAperto, setPopupAperto] = useState(false)

  const DatePicker = dateTime ? DesktopDateTimePicker : DesktopDatePicker
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={it}>
      <DatePicker
        // Props fornite dal controller
        value={uniformaData(value)}
        onChange={nuovaData => onChange(uniformaData(nuovaData))}
        inputRef={ref}

        // Campo da renderizzare
        renderInput={params =>
          <TextField
            {...params}
            onBlur={onBlur}
            {...propsRimanenti}
            inputProps={{
              ...params.inputProps,
              placeholder: dateTime ? 'gg/mm/aaaa hh:mm' : 'gg/mm/aaaa',
              ...propsRimanenti.inputProps
            }}
          />
        }

        // Gestione apertura e chiusura del popup
        open={popupAperto}
        onOpen={() => setPopupAperto(true)}
        onClose={() => {
          setPopupAperto(false)
          onBlur()
        }}

        // Toolbar con le opzioni rapide nel popup
        {...(!disabilitaToolbarCustom) && {
          showToolbar: true,
          ToolbarComponent: toolbarProps => (
            <ToolbarConOpzioniRapide
              {...toolbarProps}
              opzioniRapide={
                opzioniRapide || (disableFuture && 'passate') || (disablePast && 'future')
              }
              chiudiPopup={() => setPopupAperto(false)}
            />
          )
        }}

        // Altre props del date picker
        {...(isValid(minDate_Definitiva) && { minDate: minDate_Definitiva })}
        {...(isValid(maxDate_Definitiva) && { maxDate: maxDate_Definitiva })}
        {...(dateTime && { components: { OpenPickerIcon: ICONE.DATE_TIME } })}
        {...(propsRimanenti.disabled && { disabled: true })}
        {...propsDatePicker}
      />
    </LocalizationProvider>
  )
}



const MAPPA_OPZIONI_RAPIDE = {
  passate: OPZIONI_RAPIDE_DATE_PASSATE,
  future: OPZIONI_RAPIDE_DATE_FUTURE,
  tutte: [...OPZIONI_RAPIDE_DATE_PASSATE, ...OPZIONI_RAPIDE_DATE_FUTURE]
}

function ToolbarConOpzioniRapide(props) {
  const {
    onChange,
    chiudiPopup,
    opzioniRapide = 'passate'
  } = props

  const opzioniRapideDefinitive = (typeof opzioniRapide === 'string')
    ? MAPPA_OPZIONI_RAPIDE[opzioniRapide]
    : opzioniRapide

  const [opzioneScelta, setOpzioneScelta] = useState('nessuna')

  useEffect(() => {
    if (opzioneScelta !== 'nessuna') {
      onChange(getDataRapida(opzioneScelta))
      chiudiPopup?.()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opzioneScelta, opzioniRapideDefinitive])

  return (
    <>
      <Box py={1}>
        <Box textAlign='center'>
          <BaseTitolo colorato noMargine>Opzioni rapide</BaseTitolo>
        </Box>

        <BaseGridLayout justifyCenter alignItems='flex-end'>
          <BaseButtons.Custom
            testo='Oggi'
            color='success'
            onClick={() => {
              onChange(new Date())
              chiudiPopup?.()
            }}
          />

          <BaseSelect
            label='Altre opzioni rapide'
            options={[
              { label: 'Scegliere...', value: 'nessuna' },
              ...opzioniRapideDefinitive
            ]}
            value={opzioneScelta}
            onChange={e => setOpzioneScelta(e.target.value)}
            larghezza='corto'
            nonUsareHookForm
          />
        </BaseGridLayout>
      </Box>

      <BaseDivider />
    </>
  )
}