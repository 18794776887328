import { useState } from 'react'
import { bool } from 'prop-types'
import { useFormContext } from 'react-hook-form'
import { ICONE } from 'icons'
import { BaseTextInput, BaseSelect, BaseCheckbox, BaseIconButtons, BaseMenu, BaseRadioBoolean } from 'inputs'
import { BaseScatolaConTitolo, BaseGridLayout } from 'layout'
import { ENTITA_BASE } from '../EntitaBase'
import ElenchiManager, { ELENCHI } from '../../form/ElenchiManager'
import { useEntita } from '../entitaGenerica/components/EntitaContext'
import FormModificaEntita, { PROP_TYPES_WRAPPER_FORM_MODIFICA } from '../entitaGenerica/components/FormModificaEntita'
import ScatolaEntita from '../entitaGenerica/components/ScatolaEntita'
import ComuneModifica from './ComuneModifica'
import SceltaViaDaStradario from './SceltaViaDaStradario'
import { formattaIndirizzo, isItaly, ITALY } from './utilIndirizzo'

const NOMI = {
  TIPO: 'tipo',
  VIA: 'via',
  CAP: 'cap',
  NAZIONE: 'nazione',
  COMUNE: 'comune',
  TAG: 'tag',
  PROVINCIA: 'provincia',
  NUMERO_CIVICO: 'numeroCivico',
  PRESSO: 'presso',
  FRONTE_CIVICO: 'fronteCivico',
  ID_STRADARIO: 'idStradario',
  TIPO_INTERSEZIONE: 'tipoIntersezione',
  QUARTIERE: 'quartiere',
  IS_DOMICILIO_TEMPORANEO: 'isDomicilioTemporaneo'
}

IndirizzoModifica.propTypes = {
  ...PROP_TYPES_WRAPPER_FORM_MODIFICA,
  senzaScatola: bool,
  usaStradario: bool,
  stradarioNonObbligatorio: bool,
  /*
    La modalita light nasconde alcuni campi:
    - fronte
    - quartiere
    - presso
  */
  modalitaLight: bool,
  /*
    La modalita light con presso nasconde alcuni campi:
    - fronte
    - quartiere
  */
  modalitaLightConPresso: bool
}

IndirizzoModifica.defaultConfig = {
  path: 'indirizzo',
  nomeEntita: ENTITA_BASE.INDIRIZZO,
  url: 'indirizzo',
  label: 'Indirizzo',
  validate: validaIndirizzo
}

export default function IndirizzoModifica(props) {
  const {
    propsEntita,
    label = 'Indirizzo',
    senzaScatola,
    usaStradario,
    stradarioNonObbligatorio,
    indirizziCompilazioneVeloce,
    readOnly: readOnly_Prop,
    mostraDomicilioTemporaneoOAbituale,
    modalitaLight,
    modalitaLightConPresso
  } = props

  const {
    gestioneModifica: { modificaEntita },
    gestionePermessi: { readOnly: readOnly_Contesto }
  } = useEntita()

  const readOnly = readOnly_Prop || readOnly_Contesto

  const [keyRefreshForm, setKeyRefreshForm] = useState(Date.now())

  if (!propsEntita.valoriInput) {
    return (
      <ScatolaEntita testoBottoneCrea='Aggiungi indirizzo' {...props} />
    )
  }

  let elenchiNecessari = [ELENCHI.INDIRIZZO_TIPO, ELENCHI.NAZIONI]
  if (usaStradario) elenchiNecessari.push(ELENCHI.STRADARIO)

  function compilaIndirizzoEsistente(indirizzo) {
    modificaEntita(
      ENTITA_BASE.INDIRIZZO,
      propsEntita.pathEntita,
      indirizzo,
      { fondiConValoreAttuale: false }
    )
    setTimeout(() => setKeyRefreshForm(Date.now()), 250)
  }

  const propsFormIndirizzo = {
    usaStradario,
    stradarioNonObbligatorio,
    indirizziCompilazioneVeloce,
    compilaIndirizzoEsistente,
    ...(readOnly && { readOnly }),
    mostraDomicilioTemporaneoOAbituale,
    modalitaLight,
    modalitaLightConPresso
  }

  return (
    <ElenchiManager
      elenchi={elenchiNecessari}
      render={el => (
        <FormModificaEntita
          nomeEntita={ENTITA_BASE.INDIRIZZO}
          key={keyRefreshForm}
          {...propsEntita}
          {...(readOnly && { readOnly })}
        >
          {senzaScatola ? (
            <FormIndirizzo el={el} {...propsFormIndirizzo} />
          ) : (
            <BaseScatolaConTitolo titolo={label}>
              <FormIndirizzo el={el} {...propsFormIndirizzo} />
            </BaseScatolaConTitolo>
          )}
        </FormModificaEntita>
      )}
    />
  )
}



export function FormIndirizzo(props) {
  const {
    el,
    usaStradario,
    stradarioNonObbligatorio,
    indirizziCompilazioneVeloce,
    compilaIndirizzoEsistente,
    readOnly,
    mostraDomicilioTemporaneoOAbituale,
    modalitaLight,
    modalitaLightConPresso
  } = props

  const { setValue, onChange, watch } = useFormContext()

  function compilaCampiDaSceltaStradario(indirizzoScelto) {
    const {
      codice, tipo = 'via', via, quartiere,
      cap, comune, provincia, nazione = ITALY
    } = indirizzoScelto

    setValue(NOMI.ID_STRADARIO, codice)
    setValue(NOMI.TIPO, tipo)
    setValue(NOMI.VIA, via)
    setValue(NOMI.QUARTIERE, quartiere)
    setValue(NOMI.CAP, cap)
    setValue(NOMI.COMUNE, comune)
    setValue(NOMI.PROVINCIA, provincia)
    setValue(NOMI.NAZIONE, nazione)
    onChange()
  }

  const propsConStradario = usaStradario ? {
    ...(!stradarioNonObbligatorio) && { readOnly: true }
  } : {}

  let disposizioneRighe = [4, 1, 4]
  if (usaStradario || indirizziCompilazioneVeloce?.length > 0) {
    disposizioneRighe = [1, ...disposizioneRighe]
  }

  const [viaWatch, quartiereWatch] = watch([NOMI.VIA, NOMI.QUARTIERE])
  const shrinkProp = { InputLabelProps: { shrink: true } }

  return (
    <BaseGridLayout alignCenter disposizioneRighe={disposizioneRighe}>
      {usaStradario &&
        <BaseGridLayout alignCenter spacing={1}>
          <SceltaViaDaStradario
            stradario={el(ELENCHI.STRADARIO)}
            nascondiIndirizzoScelto
            nascondiRiassuntoOpzioniSelezionate
            nonUsareHookForm
            onChange={e => compilaCampiDaSceltaStradario(e.target.value)}
          />
          <BaseIconButtons.Aiuto
            contenutoTooltip='Usare lo stradario per compilare automaticamente i campi'
          />
          <BaseTextInput
            name={NOMI.ID_STRADARIO} type='number' hidden
          />
        </BaseGridLayout>
      }

      {!usaStradario && indirizziCompilazioneVeloce?.length > 0 &&
        <BaseMenu
          options={indirizziCompilazioneVeloce.map(({ label, indirizzo }) => ({
            label: `${label} - ${formattaIndirizzo(indirizzo)}`,
            onClick: () => compilaIndirizzoEsistente(indirizzo)
          }))}
          propsOpenButton={{
            testo: 'Indirizzo esistente',
            iconaInizio: ICONE.MODIFICA,
            ...(readOnly && { disabled: readOnly })
          }}
        />
      }

      <BaseSelect
        label='Tipo' name={NOMI.TIPO}
        options={el(ELENCHI.INDIRIZZO_TIPO)}
        larghezza={12}
        {...propsConStradario}
      />
      <BaseTextInput
        label='Via' name={NOMI.VIA}
        larghezza={23}
        {...propsConStradario}
        {...(viaWatch && shrinkProp)}
      />
      <BaseTextInput
        label='Civico' name={NOMI.NUMERO_CIVICO}
        larghezza={5}
      />
      <BaseCheckbox
        label='Fronte' name={NOMI.FRONTE_CIVICO}
        formControlLabelProps={{ sx: { mr: 0 } }}
        hidden={modalitaLight || modalitaLightConPresso}
      />

      <ComuneModifica
        nazioni={el(ELENCHI.NAZIONI)}
        {...propsConStradario}
      />

      <BaseTextInput
        label='Quartiere' name={NOMI.QUARTIERE}
        {...propsConStradario}
        {...(quartiereWatch && shrinkProp)}
        larghezza={12} hidden={modalitaLight || modalitaLightConPresso}
      />
      <BaseTextInput
        label='Presso' name={NOMI.PRESSO}
        larghezza={29} hidden={modalitaLight}
      />
      <BaseRadioBoolean
        label='Tipologia domicilio' name={NOMI.IS_DOMICILIO_TEMPORANEO}
        labelTrue='Temporaneo' labelFalse='Abituale'
        hidden={!mostraDomicilioTemporaneoOAbituale}
      />
      <></>
    </BaseGridLayout>
  )
}



export function validaIndirizzo(utilValidazione) {
  const { valoriInput, required } = utilValidazione
  const { nazione } = valoriInput

  if (isItaly(nazione)) {
    required('CAP non inserito', NOMI.CAP)
    required('Via non inserita', NOMI.VIA)
    required('Provincia non inserita', NOMI.PROVINCIA)
  }
  required('Comune non inserito', NOMI.COMUNE)
  required('Nazione non inserita', NOMI.NAZIONE)
}