import { useEffect } from 'react'
import { useUser, useUserUpdate } from './userProvider/UserProvider'

const SganciaComando = ({children}) => {

  const {
    comandoScelto
	} = useUser()
	const cmd = comandoScelto

	const { handleSceltaComando, setMostraCambioComando } = useUserUpdate()
  useEffect(() => {
    if(cmd){
      handleSceltaComando(null)
    }
    setMostraCambioComando(false)

    return () =>{
      setMostraCambioComando(true)
    }
  }, [])
  
  return(<>{children}</>)
}

export default SganciaComando