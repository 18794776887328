import { node, any, string, oneOfType, object } from 'prop-types'
import { BaseTitolo, BaseTesto } from 'text'

BaseContenutoConTitoloEFallback.propTypes = {
  children: node.isRequired,
  datiPresentiSe: any,
  titolo: oneOfType([string, node]),
  fallback: oneOfType([string, node]),
  propsTitolo: object,
  propsFallback: object
}

export default function BaseContenutoConTitoloEFallback(props) {
  const {
    children,
    datiPresentiSe,
    titolo,
    fallback = 'Nessun dato.',
    propsTitolo,
    propsFallback
  } = props

  const titoloDefinitivo = (typeof titolo === 'string')
    ? <BaseTitolo noMargine {...propsTitolo}>{titolo}</BaseTitolo>
    : titolo

  const fallbackDefinitivo = (typeof fallback === 'string')
    ? <BaseTesto {...propsFallback}>{fallback}</BaseTesto>
    : fallback

  return (
    <>
      {titoloDefinitivo}

      {datiPresentiSe ? children : fallbackDefinitivo}
    </>
  )
}