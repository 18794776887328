import { BaseForm, BaseButtons, BaseTextInput, BasePassword } from 'inputs'
import { BaseScatola, BaseGridLayout } from 'layout'
import { BaseTitolo } from 'text'
import useRichiestaServer from '../network/RichiestaServerHook'
import { useUserUpdate } from './userProvider/UserProvider'
import { useContextGenerico } from '../util/ContextGenericoProvider'

const NOMI = {
  USERNAME: 'username',
  PASSWORD: 'password'
}

const DEFAULT_VALUES = {
  USERNAME: '',
  PASSWORD: ''
}

export default function LoginConCredenziali() {
  const { titoloApp } = useContextGenerico()

  const { handleLogin } = useUserUpdate()

  const {
    ScatolaMessaggi,
    inviaRichiestaLogin,
    inAttesaDelServer
  } = useRichiestaServer()

  async function eseguiTentativoLogin(credenziali) {
    const { ok, data } = await inviaRichiestaLogin(credenziali)
    if (ok) handleLogin(data)
  }

  return (
    <>
      {ScatolaMessaggi}

      <BaseScatola>
        <BaseTitolo grande>{titoloApp}</BaseTitolo>

        <BaseForm
          defaultValues={DEFAULT_VALUES}
          onSubmit={eseguiTentativoLogin}
          inAttesa={inAttesaDelServer}
          larghezzaInputs={20}
        >
          <BaseGridLayout vertical spacing={0}>
            <BaseTextInput
              label='Nome utente' name={NOMI.USERNAME}
              required autoFocus
            />
            <BasePassword
              label='Password' name={NOMI.PASSWORD}
              required
            />
            <BaseButtons.Login />
          </BaseGridLayout>
        </BaseForm>
      </BaseScatola>
    </>
  )
}
