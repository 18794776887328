import { BaseIconaSVG } from 'icons'

export default function LogoIncloud(props) {
  return (
    <BaseIconaSVG viewBox='0 0 675.000000 457.000000' {...props}>
      <g transform="translate(0.000000,457.000000) scale(0.100000,-0.100000)">
        <path d="M3120 4560 c-365 -36 -709 -155 -1000 -347 -127 -84 -267 -200 -349
-290 l-61 -66 -157 2 c-238 3 -412 -30 -613 -114 -688 -291 -1060 -1021 -899
-1764 62 -285 198 -532 408 -741 252 -251 547 -389 916 -430 68 -7 422 -10
1117 -8 l1017 3 -606 517 c-334 284 -609 516 -610 514 -2 -2 17 -125 42 -274
25 -149 45 -275 45 -281 0 -18 -897 -15 -1003 4 -405 71 -751 392 -853 793
-141 556 209 1134 771 1273 161 40 385 34 571 -15 l51 -13 32 66 c47 95 149
244 228 333 296 333 692 536 1193 613 142 22 479 31 606 16 139 -16 282 -47
401 -86 57 -18 103 -31 103 -30 0 8 -181 105 -265 142 -180 81 -371 136 -580
168 -123 18 -390 27 -505 15z"/>
        <path d="M3655 4089 c-374 -41 -750 -198 -1046 -437 -95 -77 -120 -105 -59
-68 364 221 817 296 1239 205 435 -94 806 -351 1018 -704 26 -44 76 -150 110
-235 70 -174 56 -162 165 -134 158 40 352 54 475 34 321 -52 597 -312 684
-645 30 -114 30 -289 1 -407 -40 -162 -106 -280 -231 -411 -124 -129 -243
-194 -436 -238 -99 -22 -112 -23 -1027 -28 l-927 -6 642 -502 c353 -276 643
-500 645 -499 1 2 -18 116 -43 253 -25 138 -45 256 -45 262 0 8 94 11 338 11
462 0 584 21 827 139 610 298 903 992 695 1641 -86 266 -290 537 -524 694
-229 154 -454 225 -735 232 l-153 5 -50 82 c-227 378 -605 643 -1053 737 -118
25 -371 34 -510 19z"/>
      </g>
    </BaseIconaSVG>
  )
}
