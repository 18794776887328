import { useFormContext } from 'react-hook-form'
import { ICONE } from 'icons'
import { BaseSelectModal } from 'inputs'
import { BaseGridLayout } from 'layout'
import { creaLabelUtente } from './utilPermessiEntita'
import HeaderUtente from './HeaderUtente'

export default function SelectSceltaUtenti(props) {
  const {
    utentiScelti,
    options,
    contenutoPrimaDelBottone,
    ...restProps
  } = props

  const risultatiUseForm = useFormContext()

  let utentiSceltiDefinitivi = (
    utentiScelti
    || risultatiUseForm?.watch(restProps.name)
    || []
  )

  return (
    <BaseGridLayout vertical spacing={1}>
      <BaseGridLayout alignCenter>
        {contenutoPrimaDelBottone}

        <BaseSelectModal
          sceltaMultipla
          options={options}
          getOptionKey={({ id }) => id}
          renderOption={utente =>
            <HeaderUtente utente={utente} senzaPadding={false} />
          }
          getOptionLabel={creaLabelUtente}
          getOptionSearchString={
            ({ info }) => [info.nome, info.cognome, info.nickname].join(' ')
          }

          label='Utenti'
          chipIcon={<ICONE.PERSONA />}
          labelRicerca='nome, cognome, username'
          {...restProps}
        />
      </BaseGridLayout>

      <BaseGridLayout alignCenter spacing={4}>
        {utentiSceltiDefinitivi.map(utente =>
          <HeaderUtente
            utente={utente}
            key={utente.id}
            iconaNonColorata
          />
        )}
      </BaseGridLayout>
    </BaseGridLayout>
  )
}