import { Children, useMemo } from 'react'
import { arrayOf, bool, node, number, object } from 'prop-types'
import { Grid } from '@mui/material'
import { dividiInSubArray, sx } from 'utils'

/**
 * @typedef {import("../common/layout/BaseGridLayout").BaseGridLayoutProps} BaseGridLayoutProps 
 * @typedef {import("../common/layout/BaseGridLayout").BaseGridLayout} BaseGridLayout 
 */

BaseGridLayout.propTypes = {
  children: node,
  numeroRighe: number,
  spacing: number,
  vertical: bool,
  justifyCenter: bool,
  justifySpaceBetween: bool,
  alignCenter: bool,
  noWrap: bool,
  disposizioneRighe: arrayOf(number),
  propsGridItem: object,
  hidden: bool
}

/**
 * 
 * @param {BaseGridLayoutProps} props 
 * @type {BaseGridLayout}
 */
export default function BaseGridLayout(props) {
  const {
    children,
    numeroRighe = 1,
    vertical,
    justifyCenter,
    justifySpaceBetween,
    alignCenter,
    noWrap,
    disposizioneRighe,
    propsGridItem,
    hidden,
    ...propsGridContainer
  } = props

  const righe = useMemo(
    () => dividiInSubArray(Children.toArray(children), disposizioneRighe || numeroRighe),
    [children, numeroRighe, disposizioneRighe]
  )

  if (!children || children.length === 0) return null

  return righe.map((riga, index) =>
    <Grid
      container
      spacing={2}
      direction={vertical ? 'column' : 'row'}
      {...(justifyCenter && { justifyContent: 'center' })}
      {...(justifySpaceBetween && { justifyContent: 'space-between' })}
      {...(alignCenter && { alignItems: 'center' })}
      {...(noWrap && { wrap: 'nowrap' })}
      {...propsGridContainer}
      {...sx(propsGridContainer, hidden && { display: 'none' })}
      // Non mi interessa in questo caso se la chiave è l'indice della riga
      // Non posso calcolare una chiave sulla base delle chiavi delle celle,
      // perché questo porta ad un re-render di tutta la riga quando cambia 
      // la chiave di una singola cella (no bueno)
      key={index}
    >
      {riga.map(cella =>
        <Grid
          item
          {...propsGridItem}
          {...sx(propsGridItem, (hidden || cella.props?.hidden) && { display: 'none' })}
          key={cella.key}
        >
          {cella}
        </Grid>
      )}
    </Grid>
  )
}
