import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react'
import { bool, func, shape, object, oneOf, oneOfType, objectOf, arrayOf, number, string, node } from 'prop-types'
import { useForm, FormProvider } from 'react-hook-form'
import { BaseTitolo, BaseTesto } from 'text'
import { haAlmenoUnaChiave, creaFunzioneUnica, deepEquals, useStateWithLabel, haAlmenoUnValoreNonVuoto } from 'utils'
import { BaseIconButtons } from './BaseIconButtons'

/**
 * @typedef {import("../common/inputs/BaseForm").BaseFormProps} BaseFormProps 
 * @typedef {import("../common/inputs/BaseForm").BaseForm} BaseForm 
 */

/**
 * @type {BaseForm}
 */
export const BaseForm = forwardRef(
  /**
   * @type {BaseForm}
   * @param {BaseFormProps} props 
   */
  function BaseForm(props, ref) {
    const {
      titolo,
      nonMostrareTooltipAiuto,
      tooltipAiuto = 'Asterisco (*) indica un campo obbligatorio',
      children,
      defaultValues,
      onSubmit,
      onError,
      inAttesa,
      opzioniUseForm,
      shouldUnregister = true,
      larghezzaInputs = 'medio',
      onBlurTuttiCampi,
      onChangeTuttiCampi,
      onCambioStatoValidazione, // Deve essere memoizzata
      messaggiValidazioneEsterna,
      messaggioCampiModificati,
      messaggioAlmenoUnCampoCompilato,
      disabilitaSubmitTastiera,
      nonUsareFormTagHtml,
      readOnly,
      style
    } = props

    /******** Chiamo React Hook Form ********/

    const risultatiUseForm = useForm({
      defaultValues,
      mode: 'onTouched',
      shouldUnregister,
      ...opzioniUseForm
    })

    const {
      watch,
      handleSubmit,
      reset: resetOriginale,
      resetField,
      setError,
      clearErrors,
      setValue,
      getValues,
      trigger,
      setFocus,
      formState
    } = risultatiUseForm

    const { errors, isSubmitSuccessful } = formState

    /****************************************/

    /* Controllo se almeno un campo è compilato */

    const [nessunCampoCompilato, setNessunCampoCompilato] = useStateWithLabel(
      () => messaggioAlmenoUnCampoCompilato && !haAlmenoUnValoreNonVuoto(defaultValues),
      'nessunCampoCompilato'
    )

    function controllaSe_AlmenoUnCampoCompilato() {
      if (messaggioAlmenoUnCampoCompilato) {
        // Ritardo leggermente il controllo per garantire di avere i valori dei campi attuali aggiornati
        setTimeout(() => {
          const valoriCampiAttuali = getValues()
          const almenoUnCampoCompilato = haAlmenoUnValoreNonVuoto(valoriCampiAttuali)
          setNessunCampoCompilato(!almenoUnCampoCompilato)
        }, 500)
      }
    }

    /********************************************/

    /* Controllo se i campi sono stati modificati dall'ultimo submit */

    const [
      campiModificati_DallUltimoSubmit, setCampiModificati_DallUltimoSubmit
    ] = useStateWithLabel(false, 'campiModificati_DallUltimoSubmit')

    const valoriCampiUltimoSubmit = useRef({})

    function controllaSe_CampiModificati_DallUltimoSubmit() {
      if (messaggioCampiModificati && isSubmitSuccessful) {
        // Ritardo leggermente il controllo per garantire di avere i valori dei campi attuali aggiornati
        setTimeout(() => {
          const valoriCampiAttuali = getValues()
          const campiSonoModificati = !deepEquals(valoriCampiUltimoSubmit.current, valoriCampiAttuali)
          setCampiModificati_DallUltimoSubmit(campiSonoModificati)
        }, 500)
      }
    }

    /*****************************************************************/

    /********* Gestione errori *********/

    const ciSonoErrori = (
      haAlmenoUnaChiave(errors)
      || Boolean(messaggioAlmenoUnCampoCompilato && nessunCampoCompilato)
    )

    useEffect(
      () => onCambioStatoValidazione?.(ciSonoErrori),
      [ciSonoErrori, onCambioStatoValidazione]
    )

    /***********************************/

    /* Preparo le funzioni submit, onChange e reset */

    function onSubmitDefinitivo(valoriCampi) {
      if (messaggioCampiModificati) {
        setCampiModificati_DallUltimoSubmit(false)
        valoriCampiUltimoSubmit.current = valoriCampi
      }
      onSubmit(valoriCampi)
    }

    const submit = handleSubmit(onSubmitDefinitivo, onError)

    const onChange = creaFunzioneUnica(
      onChangeTuttiCampi,
      controllaSe_CampiModificati_DallUltimoSubmit,
      controllaSe_AlmenoUnCampoCompilato
    )

    const reset = creaFunzioneUnica(
      resetOriginale,
      controllaSe_CampiModificati_DallUltimoSubmit,
      controllaSe_AlmenoUnCampoCompilato
    )

    /************************************************/

    /***** Funzionalità esposte ai figli ed al padre *****/

    // Varie informazioni sono passate tramite context ai figli
    // - i campi ricevono tutto automaticamente tramite useInputGenerico
    // - un BaseButton con submit = true può sapere se ci sono errori o se la form è in attesa
    // - qualsiasi altro figlio può ricevere tutto chiamando useFormContext
    const formContext = {
      ...risultatiUseForm,
      inAttesa,
      ciSonoErrori,
      messaggiValidazioneEsterna,
      larghezzaInputs,
      readOnly,
      shouldUnregister,
      onBlur: onBlurTuttiCampi,
      onChange,
      submit,
      reset
    }

    // Il componente padre può effettuare operazioni in modo imperativo,
    // attaccando un ref alla form ed invocando i metodi su ref.current
    useImperativeHandle(ref, () => ({
      onChange,
      submit,
      reset,
      resetField,
      watch,
      setError,
      clearErrors,
      setValue,
      getValues,
      trigger,
      setFocus
    }))

    /*****************************************************/

    function disabilitaSubmitTastiera_SeServe(e) {
      if (disabilitaSubmitTastiera && e.key === 'Enter') e.preventDefault()
    }

    return (
      <FormProvider {...formContext}>
        {titolo &&
          <BaseTitolo>
            {titolo}
            {!nonMostrareTooltipAiuto &&
              <BaseIconButtons.Aiuto contenutoTooltip={tooltipAiuto} />
            }
          </BaseTitolo>
        }

        {nonUsareFormTagHtml ? (
          <div style={style}>
            {children}
          </div>
        ) : (
          <form
            noValidate
            onSubmit={submit}
            onKeyDown={disabilitaSubmitTastiera_SeServe}
            style={style}
          >
            {children}
          </form>
        )}

        {messaggioCampiModificati && campiModificati_DallUltimoSubmit &&
          <BaseTesto color='textSecondary'>
            {messaggioCampiModificati}
          </BaseTesto>
        }

        {messaggioAlmenoUnCampoCompilato && nessunCampoCompilato &&
          <BaseTesto color='textPrimary'>
            {messaggioAlmenoUnCampoCompilato}
          </BaseTesto>
        }
      </FormProvider>
    )
  })



BaseForm.propTypes = {
  children: node,
  titolo: string,
  nonMostrareTooltipAiuto: bool,
  tooltipAiuto: string,
  defaultValues: object,
  onSubmit: func,
  onError: func,
  inAttesa: bool,
  opzioniUseForm: shape({
    mode: oneOf(['onSubmit', 'onBlur', 'onChange', 'onTouched', 'all'])
    // In questo oggetto si possono passare anche tutte le altre opzioni di useForm
  }),
  shouldUnregister: bool,
  larghezzaInputs: oneOfType([
    number,
    oneOf(['fullWidth', 'lungo', 'medio', 'corto', 'cortissimo', 'default'])
  ]),
  onBlurTuttiCampi: func,
  onChangeTuttiCampi: func,
  onCambioStatoValidazione: func,
  messaggiValidazioneEsterna: objectOf(arrayOf(shape({
    livello: oneOf(['ERROR', 'WARNING']).isRequired,
    msg: string.isRequired
  }))),
  messaggioCampiModificati: node,
  messaggioAlmenoUnCampoCompilato: node,
  disabilitaSubmitTastiera: bool,
  nonUsareFormTagHtml: bool,
  readOnly: bool,
  style: object,
  mostraDevTool: bool
}