import { useEffect, useState } from 'react'
import { useDatiCondivisiTraRotte } from '../../navigation/DatiCondivisiTraRotteProvider'
import { OPERAZIONI_BASE } from '../../network/OperazioniBase'
import useRichiestaServer from '../../network/RichiestaServerHook'

const ORIGINE_PARAMETRO = {
  TIPO: 'tipo',
  TEMPLATE: 'template'
}

function isDocumentoGeneratoDaTemplate(documento) {
  return Boolean(documento.template)
}

function isTemplateHtmlForm(template) {
  return template.engine === 'htmlForm'
}

function creaDescrizioneDocumentoAllegato(documento) {
  const { titolo, filename } = documento.allegato
  return titolo || filename || '(titolo non inserito)'
}

const chiaveSalvataggio = 'DOCS_TEMPLATES'

function useFetchListaTemplate(props) {
  const {
    ambitiTemplate,
    nomiTemplate = [],
    nomiTemplateEsclusi = [],
    soloHtmlForm
  } = props

  const [templateDisponibili, setTemplateDisponibili] = useState(null)

  const {
    ScatolaMessaggi,
    inviaOperazione,
    inAttesaDelServer
  } = useRichiestaServer()

  const ambitiNomiConcatenati = [...ambitiTemplate, ...nomiTemplate, ...nomiTemplateEsclusi].join('')

  const {
    getDatoCondiviso,
    setDatoCondiviso
  } = useDatiCondivisiTraRotte()

  useEffect(() => {
    (async function fetchTemplateDisponibili() {
      let templates

      const mappaTemplateGiaScaricati = getDatoCondiviso(chiaveSalvataggio) ?? {}
      const templateGiaScaricati = mappaTemplateGiaScaricati[ambitiTemplate]
      if (templateGiaScaricati) {
        templates = templateGiaScaricati
      } else {
        const { ok, data } = await inviaOperazione(OPERAZIONI_BASE.DOCUMENTI_LISTA_TEMPLATE, {
          ambiti: ambitiTemplate
        })
        if (ok) {
          templates = data.templates
          setDatoCondiviso(chiaveSalvataggio, {
            ...mappaTemplateGiaScaricati,
            [ambitiTemplate]: templates
          })
        }
      }

      if (templates) setTemplateDisponibili(filtraListaTemplate(templates, {
        nomi: nomiTemplate,
        nomiEsclusi: nomiTemplateEsclusi,
        soloHtmlForm
      }))
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ambitiNomiConcatenati])

  return {
    templateDisponibili,
    ScatolaMessaggi,
    inAttesaDelServer
  }
}

function filtraListaTemplate(listaTemplate, filtri = {}) {
  const {
    nomi: nomiCercati = [],
    nomiEsclusi = [], 
    tags: tagsCercati = [],
    soloHtmlForm = false
  } = filtri

  const filtroPerNomi = ({ nome }) => {
    if (nomiCercati.length === 0) return true
    return nomiCercati.includes(nome)
  }

  const filtroPerNomiEsclusi = ({ nome }) => {
    if (nomiEsclusi.length === 0) return true
    return !nomiEsclusi.includes(nome)
  }

  const filtroPerTag = ({ tags: tagsStringa = '' }) => {
    if (tagsCercati.length === 0) return true
    if (!tagsStringa) return false
    const tags = tagsStringa.split(',')
    return tagsCercati.some(tagCercato => tags.includes(tagCercato))
  }

  const filtroHtmlForm = template => {
    if (!soloHtmlForm) return true
    return isTemplateHtmlForm(template)
  }

  return listaTemplate.filter(template => 
    filtroPerNomi(template) 
    && filtroPerNomiEsclusi(template) 
    && filtroPerTag(template)
    && filtroHtmlForm(template)
  )
}

function trasformaTipiDocumento(tipi) {
  return [
    {
      value: '',
      label: ''
    },
    ...tipi.map(tipo => {
      const { codice, nome, ...restTipo } = tipo
      return {
        ...restTipo,
        value: codice,
        label: nome
      }
    })
  ]
}

// Trasforma i tags in array se serve
function trasformaAllegatoInput(allegato) {
  const { tags } = allegato
  let tagsDefinitive = tags
  if (typeof tags === 'string') {
    tagsDefinitive = (tags === '') ? [] : tags.split(',')
  }
  return { ...allegato, tags: tagsDefinitive }
}

// Trasforma i tags in stringa se serve
function trasformaAllegatoOutput(allegato) {
  const { tags } = allegato
  return {
    ...allegato,
    tags: Array.isArray(tags) ? tags.join(',') : tags
  }
}

// Trasforma un array di parametri [{ chiave, valoreJson }]
// in un oggetto del tipo { chiave: valoreJson, ... }
// Passando il flag mantieniTutteProprieta = true, ad ogni chiave
// viene assegnato l'oggetto originale presente nell'array
function trasformaParametriInput(listaParametriChiaveValore, mantieniTutteProprieta = false) {
  return listaParametriChiaveValore.reduce(
    (oggettoValoriParametri, param) => ({
      ...oggettoValoriParametri,
      [param.chiave]: mantieniTutteProprieta ? param : param.valoreJson
    }),
    {}
  )
}

// Partendo dai 2 oggetti { chiave: valoreJson, ... } che contengono i valori
// dei parametri dei template ed i valori dei parametri dei metadati,
// costruisce un array di oggetti [{ chiave, valoreJson, origine }]
// assegnando ad ognuno l'origine corretta ("template" oppure "tipo")
// Serve passare anche un oggetto che contiene i parametri originali per
// essere sicuri di non perdere altri eventuali valori preesistenti
function trasformaParametriOutput(
  valoriParametriTemplate = {},
  valoriParametriMetadati = {},
  oggettoParametriOriginali = {},
  oggettoDefParametri = {}
) {
  function trasformaParametriConOrigine(valori, origine) {
    return Object.entries(valori).map(([chiave, valoreJson]) => ({
      ...oggettoParametriOriginali[chiave],
      tipo: oggettoDefParametri[chiave]?.type ?? '',
      chiave,
      valoreJson,
      origine
    }))
  }

  return [
    ...trasformaParametriConOrigine(valoriParametriTemplate, ORIGINE_PARAMETRO.TEMPLATE),
    ...trasformaParametriConOrigine(valoriParametriMetadati, ORIGINE_PARAMETRO.TIPO)
  ]
}

// Trasforma opportunamente l'allegato ed i parametri del documento
// per essere gestiti più facilmente nei componenti interni
function trasformaDocumentoInput(documento) {
  const { allegato, parametri, ...restDocumento } = documento

  // Separo sulla base dell'origine i parametri del template dai metadati
  let parametriTemplate = []
  let parametriMetadati = []
  parametri.forEach(parametro => {
    if (parametro.origine === ORIGINE_PARAMETRO.TIPO) {
      parametriMetadati.push(parametro)
    } else {
      parametriTemplate.push(parametro)
    }
  })

  return {
    ...restDocumento,
    allegato: trasformaAllegatoInput(allegato),
    parametri: trasformaParametriInput(parametriTemplate),
    parametriMetadati: trasformaParametriInput(parametriMetadati),
    parametriOriginali: trasformaParametriInput(parametri, true)
  }
}

// Inverte le trasformazioni applicate da trasformaDocumentoInput
// per riportare il documento nel formato da inviare al server
function trasformaDocumentoOutput(documento, definizioneTemplate) {
  const {
    allegato,
    parametri,
    parametriMetadati,
    parametriOriginali,
    htmlCustom,
    ...restDocumento
  } = documento

  if (!definizioneTemplate) definizioneTemplate = {}
  const { params = {} } = definizioneTemplate
  const { campi = [] } = params
  const oggettoDefParametri = campi.reduce((oggettoInCostruzione, defParam) => ({
    ...oggettoInCostruzione,
    [defParam.name]: defParam
  }), {})

  return {
    ...restDocumento,
    allegato: trasformaAllegatoOutput(allegato),
    parametri: trasformaParametriOutput(
      parametri,
      parametriMetadati,
      parametriOriginali,
      oggettoDefParametri
    ),
    // Fix per evitare che react hook form provi a eliminare la 
    // proprietà htmlForm.second (per qualche interazione strana
    // con ckeditor, avveniva un unregister di quel campo e 
    // veniva subdolamente eliminato il valore dall'oggetto)
    ...(htmlCustom && {
      htmlCustom: Object.freeze(htmlCustom)
    })
  }
}

function costruisciSequenzaDocumentiSostituiti(documento, path, opzioni = {}) {
  const { sequenzaGiaCostruita = [], risolviRif } = opzioni
  if (!documento) return sequenzaGiaCostruita

  sequenzaGiaCostruita.push({ documento, path })
  const rifDocumentoSostituito = documento.sostituisce
  if (!rifDocumentoSostituito) return sequenzaGiaCostruita

  const documentoSostituito = risolviRif(rifDocumentoSostituito)
  return costruisciSequenzaDocumentiSostituiti(
    documentoSostituito,
    rifDocumentoSostituito.path,
    { sequenzaGiaCostruita, risolviRif }
  )
}

function filtraDocumentiSostituiti(documentiTutti) {
  const listaUuidDocumentiSostituiti = documentiTutti
    .filter(({ sostituisce }) => Boolean(sostituisce))
    .map(({ sostituisce }) => sostituisce.identificativo.uuid)

  const documentiNonSostituiti = documentiTutti
    .filter(({ uuid }) => !listaUuidDocumentiSostituiti.includes(uuid))

  return documentiNonSostituiti
}

export {
  ORIGINE_PARAMETRO,
  isDocumentoGeneratoDaTemplate,
  isTemplateHtmlForm,
  creaDescrizioneDocumentoAllegato,
  useFetchListaTemplate,
  filtraListaTemplate,
  trasformaTipiDocumento,
  trasformaAllegatoInput,
  trasformaAllegatoOutput,
  trasformaDocumentoInput,
  trasformaDocumentoOutput,
  costruisciSequenzaDocumentiSostituiti,
  filtraDocumentiSostituiti
}