import { string, node, any } from 'prop-types'
import { IconButton } from '@mui/material'
import { ICONE } from 'icons'
import { BaseGridLayout, BaseScatolaConTitolo } from 'layout'
import { BaseTesto } from 'text'
import LinkPannello from './LinkPannello'

ScatolaRiassunto.propTypes = {
  children: node,
  titolo: string,
  to: string,
  valoriInput: any,
  testoEmpty: string
}

export default function ScatolaRiassunto(props) {
  const {
    children,
    titolo,
    to,
    valoriInput,
    testoEmpty,
    ...restProps
  } = props

  return (
    <BaseScatolaConTitolo titolo={titolo}>
      {(valoriInput && (!Array.isArray(valoriInput) || valoriInput.length > 0)) ? (
        <BaseGridLayout {...restProps}>
          {children}
        </BaseGridLayout>
      ) : (
        <BaseTesto>{testoEmpty}</BaseTesto>
      )}

      {to && <ScatolaLinkPannello to={to} />}
    </BaseScatolaConTitolo>
  )
}



export function ScatolaLinkPannello({ to }) {
  return (
    <LinkPannello to={to}>
      <IconButton sx={{ position: 'absolute', right: 0, bottom: 0, p: 0.25 }}>
        <ICONE.FRECCIA_DESTRA_CERCHIATA color='primary' sx={{ fontSize: '2rem' }} />
      </IconButton>
    </LinkPannello>
  )
}