import { object, node, string, bool } from 'prop-types'
import { BaseScatolaConTitolo } from 'layout'

SmartPanel.propTypes = {
  children: node,
  label: string,
  titolo: node,
  
  isSubPanel: bool,
  conScatola: bool,
  propsScatola: object
}

export default function SmartPanel(props) {
  const {
    children,
    label,
    titolo,
    
    isSubPanel,
    conScatola,
    propsScatola
  } = props

  if (isSubPanel) {
    if (!conScatola) return children
    return (
      <BaseScatolaConTitolo titolo={label} {...propsScatola}>
        {children}
      </BaseScatolaConTitolo>
    )
  }

  return (
    <>
      {titolo}
      {children}
    </>
  )
}