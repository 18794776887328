import { useApiRequest } from './ApiRequestProvider'
import { useRichiestaServerConFeedback, WARNING_STANDARD } from 'feedback'
import { nonInProduzione, saveInLocalStorage, trasformaFileInBase64 } from 'utils'
import { useUser, useUserUpdate } from '../userManagement/userProvider/UserProvider'
import { useContextGenerico } from '../util/ContextGenericoProvider'
import { OPERAZIONI_BASE } from './OperazioniBase'

/**
 * @typedef {import("../../common/incloudApps/network/RichiestaServerHook").ServerCodeMap} ServerCodeMap
 * @typedef {import("../../common/incloudApps/network/RichiestaServerHook").inviaOperazione} inviaOperazione
 * @typedef {import("../../common/incloudApps/network/RichiestaServerHook").RequestOption} RequestOption
 * @typedef {import("../../common/incloudApps/network/RichiestaServerHook").Response} Response
 * @typedef {import("../../common/incloudApps/network/RichiestaServerHook").RequestHTTPOption} RequestHTTPOption
 * @typedef {import("../../common/incloudApps/network/RichiestaServerHook").ResponseWithRecord} ResponseWithRecord
 * @typedef {import("../../common/incloudApps/network/RichiestaServerHook").ResponseWithRecord} ResponseWithRecord
 * @typedef {import("../../common/incloudApps/network/RichiestaServerHook").ModificaDaFare} ModificaDaFare
 * @typedef {import("../../common/incloudApps/network/RichiestaServerHook").InfoEntitaPrincipale} InfoEntitaPrincipale
 * 
 */

// Corrispondenze tra i codici inviati dai server
// ed alcuni messaggi definiti qui nel front end
/**
 * @type {ServerCodeMap}
 */
const MAPPA__CODICI_SERVER__MESSAGGI = {
  SESSIONE_SCADUTA: WARNING_STANDARD.SESSIONE_SCADUTA,
  'Login fallito': WARNING_STANDARD.CREDENZIALI_ERRATE
}

/**
 * Hook to use API
 * @param {object} props 
 * @returns {UseRichiestaServer} function helpers
 */
export default function useRichiestaServer(props = {}) {
  const {
    MESSAGGI_RICHIESTA_CUSTOM,
    calcolaErroreCustom: calcolaErroreCustom_Prop
  } = props

  const {
    CHIAVI_STORAGE: { DATA_ULTIMA_RICHIESTA }
  } = useContextGenerico()

  /* Ottieni le implementazioni delle richieste API e i dati dell'utente da inviare */

  const {
    inviaRichiestaLogin_Implementazione,
    inviaOperazione_Implementazione,
    getAttachmentURL_Implementazione,
    getAttachmentURLfromPartialURL_Implementazione,
    OPERAZIONI,
    ENTITA,
    URL_SSO_BE,
  } = useApiRequest()

  const { userInfo, comandoScelto } = useUser()
  const userInfoPerRichiesta = { ...userInfo, comando: comandoScelto }
  const { handleLogout } = useUserUpdate()

  /**********************************************************************************/

  /* Configura e chiama l'hook generico per fare richieste con attesa e gestione errori */

  function condizioneErroreCustom(error) {
    // Situazione in cui il server ha ritornato esito = false
    return Boolean(error.messaggi)
  }

  function calcolaErroreCustom(error) {
    let messaggioPresente_CorrispondenteAlCodice
    let deveRitornareNull

    const messaggiServerConcatenati = error.messaggi
      .filter(({ livello }) => livello !== 'DEBUG')
      .map(({ msg, codice }) => {
        if (codice === 'SESSIONE_SCADUTA') {
          // Non faccio setIsSessioneScaduta(true) perché quello fa scattare una richiesta
          // di logout al server, cosa inutile visto che il server mi ha appena detto che
          // per lui la sessione è già scaduta. Devo solo fare il logout qui sul frontend
          handleLogout()

          // Risolve il problema per cui veniva mostrato il messaggio di sessione  
          // scaduta quando l'utente chiudeva la tab, lasciava il browser aperto 
          // e tornava dopo un tempo sufficiente a far scadere il token sul server
          // Se nel local storage ci sono salvati i dati dell'utente, l'app pensa (giustamente)
          // di essere anora loggata; quindi appena l'utente entra, parte subito la richiesta 
          // dei permessi; il fatto che sul server fosse scaduto il token faceva venire fuori
          // il messaggio di sessione scaduta. Con questo controllo faccio in modo che non venga
          // mostrato alcun messaggio se la richiesta dei permessi fallisce per token scaduto
          if (error.opId === OPERAZIONI_BASE.PERMESSI_UTENTE_ELENCO) deveRitornareNull = true
        }
        messaggioPresente_CorrispondenteAlCodice = MAPPA__CODICI_SERVER__MESSAGGI[codice]
        return msg
      })
      .join(', ')

    if (deveRitornareNull) return null
    return messaggioPresente_CorrispondenteAlCodice
      || { key: `Si è verificato un errore: ${messaggiServerConcatenati}` }
  }

  const {
    richiestaConAttesaEGestioneErrori,
    downloadFileConAttesaEGestioneErrori,
    ...risultatiUseRichiesta
  } = useRichiestaServerConFeedback({
    MESSAGGI_RICHIESTA_CUSTOM,
    condizioneErroreCustom,
    calcolaErroreCustom: calcolaErroreCustom_Prop || calcolaErroreCustom
  })

  /**************************************************************************************/

  /* Funzioni comode da chiamare nei componenti */

  /**
   * Invia una richiesta di operazione al server, con la gestione dell'attesa e degli errori
   * Il server riceve un POST su "/op" che smista le operazioni in base all'operation id
   * 
   * Esempio di una richiesta inviata al server:
   * {
   *   "opId": "entity.get",
   *   "username": "lucan",
   *   "comando": "tdev",
   *   "token": "254a01b4-1b60-41d8-8096-9318ca87282e",
   *   "data": {
   *     "entityName": "Sinistro",
   *     "entityUUID": "9194ea41-752f-46e8-8fcf-6546015da04b"
   *   }
   * }
   * "opId" e "data" vengono passati a questa funzione dall'esterno
   * "username", "comando" e "token" sono inseriti automaticamente, recuperandoli dai dati
   * dell'utente attualmente loggato; in casi particolari è possibile fare un override di
   * questi attributi, passandoli nelle opzioni (terzo parametro della funzione)
   * 
   * Esempio di una risposta inviata dal server, con esito positivo:
   * {
   *   "esito": true,
   *   "opId": "entity.get",
   *   "messaggi": [],
   *   "data": {
   *     "uuid": "9194ea41-752f-46e8-8fcf-6546015da04b",
   *     "stato": "IN_LAVORAZIONE",
   *     ...
   *   }
   * }
   * "esito": true indica che l'operazione è stata effettuata con successo,
   * "data" contiene l'eventuale risposta inviata dal server
   * La promessa ritornata da questa funzione si risolve con { ok: true, data }
   * 
   * Esempio di una risposta inviata dal server, con esito negativo:
   * {
   *   "esito": false,
   *   "opId": "entity.get",
   *   "messaggi": [
   *     {
   *       "codice": "NONE",
   *       "livello": "ERROR",
   *       "msg": "Oggetto non trovato EntityGetReq(entityName=Sinistro, entityUUID=9194ea41-752f-46e8-8fcf-654601)",
   *       "percorso": "",
   *       "path": ""
   *     }
   *   ],
   *   "data": {}
   * }
   * "esito": false indica che l'operazione è fallita generando un errore,
   * i "messaggi" contengono i messaggi di errore inviati dal server
   * La promessa ritornata da questa funzione si risolve con { ok: false, error }
   * 
   * @template D Tipo di body response dalla request
   * @template T Tipo di payload per data. Default value {}
   * 
   * @param {string} opId - operation id che identifica l'operazione da fare sul server.
   * Viene lanciato un warning "Operation id sconosciuto" se l'opId
   * non è presente nell'oggetto "OPERAZIONI" passato ad AppSkeleton
   * 
   * @param {T} [data] - payload da inserire nell'attributo "data" della richiesta.
   * Il contenuto di questo oggetto dipende dal tipo di operazione
   * Se al primo livello di questo oggetto sono presenti attributi con valore
   * uguale alla stringa "$__SCARTA__$", questi vengono scartati e non inviati
   * 
   * @param {RequestOption} [opzioni={}] - opzioni usate da questa funzione e da richiestaConAttesaEGestioneErrori
   * @param {string} opzioni.comando - tag del comando da inserire nell'attributo "comando" della richiesta
   * @param {string} opzioni.username - username da inserire nell'attributo "username" della richiesta
   * @param {string} opzioni.token - token di autenticazione da inserire nell'attributo "token" della richiesta
   * @param {callback} opzioni.setInAttesa - funzione di useState per impostare uno stato booleano di attesa.
   * L'hook useRichiestaServerConFeedback gestisce automaticamente un solo stato di attesa; usare questa
   * opzione è utile se vengono fatte chiamate diverse ed ognuna deve avere il suo stato di attesa
   * @param {boolean} opzioni.abilitaCatchDefault - passare false per disabilitare la gestione errori automatica
   * @param {string} opzioni.chiaveMessaggioSuccesso - chiave del messaggio da mostrare in caso di esito positivo.
   * La chiave deve riferirsi ad uno dei messaggi di default oppure ad un messaggio in MESSAGGI_RICHIESTA_CUSTOM.
   * Usato raramente, perché spesso in caso di esito positivo non serve mostrare un messaggio ma fare altre azioni
   * 
   * @param {RequestHTTPOption<D>} [opzioniRichiestaHttp] - opzioni usate da inviaOperazione_Implementazione e da Axios
   * @param {Response<D>} opzioniRichiestaHttp.mockResponse - risposta mockata da restituire dopo un setTimeout. Esempio:
   * {
   *   "esito": true,
   *   "data": {
   *     "uuid": "9194ea41-752f-46e8-8fcf-6546015da04b",
   *     "stato": "IN_LAVORAZIONE",
   *     ...
   *   }
   * }
   * 
   * @returns {Promise<Response<D>>} Si risolve in un oggetto { ok, data, error }.
   * In caso di esito positivo, "ok" è true e "data" contiene la risposta inviata dal server.
   * In caso di esito negativo, "ok" è false e "error" contiene l'errore inviato dal server.
   * Se si vuole solo mostrare l'errore nella ui (come accade quasi sempre), non serve usare
   * "error" all'esterno, basta inserire nella ui la ScatolaMessaggi ritornata dall'hook
   * 
   * @type {inviaOperazione}
   */
  function inviaOperazione(opId, data, opzioni = {}, opzioniRichiestaHttp) {
    if (nonInProduzione() && !Object.values(OPERAZIONI).includes(opId)) {
      console.warn('Operation id sconosciuto:', opId)
    }

    if (opId !== OPERAZIONI_BASE.LOGOUT) {
      saveInLocalStorage(DATA_ULTIMA_RICHIESTA, new Date())
    }

    // In qualche situazione può servire passare come opzioni le informazioni
    // dello user e del comando, magari se devo fare una chiamata in un momento
    // in cui non sono ancora disponibili nel context dello user
    let userInfoDaMandare = { ...userInfoPerRichiesta }
    // eslint-disable-next-line no-prototype-builtins
    if (opzioni.hasOwnProperty('comando')) userInfoDaMandare.comando = opzioni.comando
    if (opzioni.username) userInfoDaMandare.info.nickname = opzioni.username
    if (opzioni.token) userInfoDaMandare.token = opzioni.token

    return richiestaConAttesaEGestioneErrori(
      inviaOperazione_Implementazione(opId, userInfoDaMandare, data, opzioniRichiestaHttp),
      opzioni
    )
  }

  function checkValiditaNomeEntita(nomeEntita) {
    if (nonInProduzione() && !Object.values(ENTITA).includes(nomeEntita)) {
      console.warn('Nome entita sconosciuto:', nomeEntita)
    }
  }

  /**
   * Invia al server un'operazione "entity.new" per creare un'entità
   * 
   * Esempio di richiesta:
   * {
   *   "opId": "entity.new",
   *   "username": "lucan",
   *   "comando": "tdev",
   *   "token": "5371948d-d6a6-4383-8fd4-f74bd9f4ac4d",
   *   "data": {
   *     "entityName": "Sinistro",
   *     "opts": {}
   *   }
   * }
   * 
   * Esempio di risposta:
   * {
   *   "esito": true,
   *   "opId": "entity.new",
   *   "messaggi": [],
   *   "data": {
   *     "uuid": "1e09608a-1e28-4213-a762-b32d3731153f",
   *     "stato": "IN_LAVORAZIONE",
   *     ...
   *   }
   * }
   * 
   * @template D Tipo di risposta 
   * @template T Tipologia di opzioni creazione 
   * 
   * @param {string} entityName - nome dell'entità da creare
   * @param {T} [opzioniCreazione={}] - opzioni da inserire nell'attributo "opts" della richiesta.
   * Non ci sono sempre e dipendono dal tipo di entità
   * @param {RequestOption} [opzioni] - opzioni da passare come terzo parametro di inviaOperazione
   * @returns {Promise<Response<D>>} Risposta di inviaOperazione; "data" contiene la nuova entità creata
   */
  function newEntity(entityName, opzioniCreazione = {}, opzioni) {
    checkValiditaNomeEntita(entityName)
    const corpoRichiesta = { entityName, opts: opzioniCreazione }
    return inviaOperazione(OPERAZIONI_BASE.ENTITY_NEW, corpoRichiesta, opzioni)
  }

  /**
   * Invia al server un'operazione "entity.get" per ottenere i dati completi di un'entità
   * 
   * Esempio di richiesta:
   * {
   *   "opId": "entity.get",
   *   "username": "lucan",
   *   "comando": "tdev",
   *   "token": "254a01b4-1b60-41d8-8096-9318ca87282e",
   *   "data": {
   *     "entityName": "Sinistro",
   *     "entityUUID": "9194ea41-752f-46e8-8fcf-6546015da04b"
   *   }
   * }
   * 
   * Esempio di risposta
   * {
   *   "esito": true,
   *   "opId": "entity.get",
   *   "messaggi": [],
   *   "data": {
   *     "uuid": "9194ea41-752f-46e8-8fcf-6546015da04b",
   *     "stato": "IN_LAVORAZIONE",
   *     ...
   *   }
   * }
   * 
   * @template D Tipo di risposta 
   * 
   * @param {string} entityName - nome dell'entità da ottenere
   * @param {string} entityUUID - uuid dell'entità da ottenere
   * @param {RequestOption} [opzioni] - opzioni da passare come terzo parametro di inviaOperazione
   * @returns {Promise<Response<D>>} Risposta di inviaOperazione; "data" contiene l'entità richiesta
   */
  function fetchEntity(entityName, entityUUID, opzioni) {
    checkValiditaNomeEntita(entityName)
    const corpoRichiesta = { entityName, entityUUID }
    return inviaOperazione(OPERAZIONI_BASE.ENTITY_GET, corpoRichiesta, opzioni)
  }

  /**
   * Invia al server un'operazione "entity.search" per cercare le entità che rispettano certi criteri.
   * Il server non ritorna i dati completi di ogni entità, solo una versione riassuntiva di ognuna
   * 
   * Esempio di richiesta:
   * {
   *   "opId": "entity.search",
   *   "username": "lucan",
   *   "comando": "tdev",
   *   "token": "5371948d-d6a6-4383-8fd4-f74bd9f4ac4d",
   *   "data": {
   *     "entity": "Sinistro",
   *     "criteria": {
   *       "anno": 2023
   *     },
   *     "options": {}
   *   }
   * }
   * 
   * Esempio di risposta:
   * {
   *   "recordsCount": -1,
   *   "recordsPerPage": 25,
   *   "page": 1,
   *   "records": [
   *     {
   *       "entity": "Sinistro",
   *       "uuid": "9194ea41-752f-46e8-8fcf-6546015da04b",
   *       "numero": 6,
   *       "anno": 2023,
   *       ...
   *     },
   *     ...
   *   ]
   * }
   * 
   * @template D
   * @template C - default SimpleMapObjectPrimitive
   * 
   * @param {string} entity - nome dell'entità da cercare
   * @param {C} [criteria={}] - criteri di ricerca da inviare al server.
   * Dipendono dal tipo di entità e sono inseriti nell'attributo "criteria" della richiesta
   * @param {unkown} [optionsRicerca={}] - ulteriori opzioni della ricerca da inviare al server.
   * Dipendono dal tipo di entità e sono inseriti nell'attributo "options" della richiesta
   * @param {RequestOption} [opzioni] - opzioni da passare come terzo parametro di inviaOperazione
   *
   * @param {RequestHTTPOption<D>} [opzioniRichiestaHttp] - opzioni usate da inviaOperazione_Implementazione e da Axios
   * @returns {Promise<ResponseWithRecord<D>>} Risposta di inviaOperazione; il campo "records" contiene le entità trovate
   */
  function searchEntity(entity, criteria = {}, optionsRicerca = {}, opzioni, opzioniRichiestaHttp = {}) {
    checkValiditaNomeEntita(entity)
    const corpoRichiesta = { entity, criteria, options: optionsRicerca }
    return inviaOperazione(OPERAZIONI_BASE.ENTITY_SEARCH, corpoRichiesta, opzioni, opzioniRichiestaHttp)
  }

  /**
   * Invia al server un'operazione "entity.update" per modificare i dati di un'entità principale.
   * La modifica di un'entità principale consiste nella modifica di una sua parte (sottoentità) 
   * posizionata in un certo path. Le operazioni possono essere "CREA", "AGGIORNA" o "ELIMINA".
   * Il server applica la modifica e salva una nuova revisione dell'entità principale. 
   * In caso di più modifiche simultanee sulla stessa porzione di dati, il server rifiuta
   * la modifica e segnala la presenza di un conflitto
   * 
   * Esempio di richiesta:
   * {
   *   "opId": "entity.update",
   *   "username": "lucan",
   *   "comando": "tdev",
   *   "token": "5371948d-d6a6-4383-8fd4-f74bd9f4ac4d",
   *   "data": {
   *     "mainEntity": "Sinistro",
   *     "mainEntityUUID": "9194ea41-752f-46e8-8fcf-6546015da04b",
   *     "mainEntityRev": 63,
   *     "entity": "Indirizzo",
   *     "entityUUID": "f6bc0980-2cd5-485c-a99a-bdab64b2e583",
   *     "path": "luogo.indirizzi[uuid:f6bc0980-2cd5-485c-a99a-bdab64b2e583]",
   *     "operazione": "AGGIORNA",
   *     "data": {
   *       "uuid": "f6bc0980-2cd5-485c-a99a-bdab64b2e583",
   *       "tipo": "via",
   *       "via": "BORGO DEGLI ALBIZI",
   *       "cap": "50122"
   *     }
   *   }
   * }
   * 
   * Esempio di risposta (modifica effettuata con successo):
   * {
   *   "esito": true,
   *   "opId": "entity.update",
   *   "messaggi": [],
   *   "data": {
   *     "rev": 64
   *   }
   * }
   * 
   * Esempio di risposta (modifica rifiutata per conflitto):
   * {
   *   "esito": false,
   *   "opId": "entity.update",
   *   "messaggi": [
   *     {
   *       "codice": "NONE",
   *       "livello": "ERROR",
   *       "msg": "conflitto",
   *       "percorso": "",
   *       "path": ""
   *     }
   *   ],
   *   "data": {
   *     "operations": [
   *       {
   *         "mainEntity": "Sinistro",
   *         "mainEntityUUID": "9194ea41-752f-46e8-8fcf-6546015da04b",
   *         "createdTs": "2023-02-07T10:40:43",
   *         "username": "lucan",
   *         "path": "luogo.indirizzi[uuid:f6bc0980-2cd5-485c-a99a-bdab64b2e583]"
   *       }
   *     ],
   *     "conflictIndex": 0,
   *     "rev": 65
   *   }
   * }
   * 
   * @template R Response of request
   * @template E Tipo della modifica da effettuare
   * 
   * @param {InfoEntitaPrincipale} infoEntitaPrincipale - informazioni sull'entità principale da modificare
   * @param {string} infoEntitaPrincipale.nome - nome dell'entità principale
   * @param {string} infoEntitaPrincipale.uuid - uuid dell'entità principale
   * @param {number} infoEntitaPrincipale.revisione - revisione dell'entità principale
   * 
   * @param {ModificaDaFare<E>} modificaDaFare - descrizione della modifica da applicare all'entità
   * @param {string} modificaDaFare.nomeEntita - nome della sottoentità da creare / aggiornare / eliminare
   * @param {string} modificaDaFare.path - path della sottoentità da creare / aggiornare / eliminare
   * @param {E} modificaDaFare.nuovoValore - nuovo valore della sottoentità da creare / aggiornare
   * @param {string} modificaDaFare.operazione - tipo di modifica: "CREA" / "AGGIORNA" / "ELIMINA"
   * 
   * @param {RequestOption} opzioni  - opzioni da passare come terzo parametro di inviaOperazione
   * 
   * @param {RequestHTTPOption<R>} [opzioniRichiestaHttp] - opzioni da passare come quarto parametro di inviaOperazione_Implementazione e da Axios
   * @returns {Promise<Response<R>>} Risposta di inviaOperazione; contiene il nuovo numero di revisione
   */
  function updateEntity(infoEntitaPrincipale, modificaDaFare, opzioni = {}, opzioniRichiestaHttp = {}) {
    const { nome, uuid, revisione } = infoEntitaPrincipale
    const { nomeEntita, path, nuovoValore, operazione } = modificaDaFare
    checkValiditaNomeEntita(nome)
    checkValiditaNomeEntita(nomeEntita)
    return inviaOperazione(OPERAZIONI_BASE.ENTITY_UPDATE, {
      mainEntity: nome,
      mainEntityUUID: uuid,
      mainEntityRev: revisione,
      entity: nomeEntita,
      entityUUID: nuovoValore?.uuid,
      path,
      operazione,
      data: nuovoValore
    }, opzioni, opzioniRichiestaHttp)
  }

  // Chiamata vecchia con le credenziali, senza SSO
  function inviaRichiestaLogin(credenziali, opzioni) {
    return richiestaConAttesaEGestioneErrori(
      inviaRichiestaLogin_Implementazione(credenziali),
      opzioni
    )
  }

  function getAttachmentURL(uuid, thumb, original) {
    return getAttachmentURL_Implementazione(uuid, userInfoPerRichiesta, thumb, original)
  }

  function getAttachmentURLfromPartialURL(partialURL) {
    return getAttachmentURLfromPartialURL_Implementazione(partialURL, userInfoPerRichiesta)
  }

  function downloadFileWithUUID(uuid, nomeFileScaricato, opzioni = {}) {
    const { thumb, original, ...opzioniDownloadFile } = opzioni
    return downloadFileConAttesaEGestioneErrori(
      getAttachmentURL(uuid, thumb, original),
      nomeFileScaricato,
      opzioniDownloadFile
    )
  }

  function downloadFileWithPartialURL(partialURL, nomeFileScaricato, opzioni) {
    return downloadFileConAttesaEGestioneErrori(
      getAttachmentURLfromPartialURL(partialURL),
      nomeFileScaricato,
      opzioni
    )
  }

  function ssoApiUrl(urlPartial) {
    return URL_SSO_BE ? URL_SSO_BE + urlPartial : ''
  }

  function userAvatarSrc(avatar, token) {
    const { token: currentUserToken, avatar: currentUserAvatar } = userInfo
    if (!avatar || avatar == '') return undefined
    const selectedAvatar = avatar ?? currentUserAvatar
    const currentToken = token ?? currentUserToken
    return ssoApiUrl(`${selectedAvatar}&token=${currentToken}`)
  }

  function logoComandoSrc(cmd, withDefault = true, token) {
    const { token: currentUserToken } = userInfo
    const selectedCmd = cmd ?? comandoScelto
    const currentToken = token ?? currentUserToken
    if (cmd == '') return undefined
    const baseLogoComando = '/v1/base/' + (withDefault ? `logo?cmd=${selectedCmd}` : `file?filename=logo&cmd=${selectedCmd}`)
    return ssoApiUrl(`${baseLogoComando}&token=${currentToken}`)
  }

  function logoModuloSrc(modulo, withDefault = true, token) {
    const { token: currentUserToken } = userInfo
    if (!modulo || modulo == '') return undefined
    const baseLogoModulo = '/v1/base/' + (withDefault ? `modulo_logo?modulo=${modulo}` : `file?filename=modulo.${modulo}`)
    const currentToken = token ?? currentUserToken
    return ssoApiUrl(`${baseLogoModulo}&token=${currentToken}`)
  }

  async function uploadFile(file, data, onUploadProgress, opzioni = {}) {
    const {
      opIdBinarioSalva = OPERAZIONI_BASE.BINARIO_SALVA,
      ...restOpzioni
    } = opzioni

    // Se il file non esiste, significa che NON voglio caricare un nuovo binario,
    // ma voglio solo modificare le altre info (titolo, descrizione, tags)
    if (!file) {
      return inviaOperazione(opIdBinarioSalva, data, restOpzioni)
    }

    // Se il file esiste, significa che voglio caricare un nuovo binario
    // Trasformo il binario in base64 prima di inviarlo al server
    const fileDefinitivo = (file instanceof FileList) ? file[0] : file
    const { ok, base64, error } = await trasformaFileInBase64(fileDefinitivo)

    if (ok) {
      const dataCompleto = {
        base64,
        filename: fileDefinitivo.name,
        mimetype: fileDefinitivo.type,
        ...data
      }
      return inviaOperazione(opIdBinarioSalva, dataCompleto, restOpzioni, { onUploadProgress })
    }

    if (error) return { ok: false, error }
  }

  /**********************************************/

  return {
    OPERAZIONI,
    ENTITA,
    ...risultatiUseRichiesta,
    inviaOperazione,
    newEntity,
    fetchEntity,
    searchEntity,
    updateEntity,
    inviaRichiestaLogin,
    getAttachmentURL,
    getAttachmentURLfromPartialURL,
    downloadFileWithUUID,
    downloadFileWithPartialURL,
    uploadFile,
    userAvatarSrc,
    logoComandoSrc,
    logoModuloSrc
  }
}