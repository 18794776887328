import { LinearProgress, Box } from '@mui/material'
import { BasePopoverConfermaOperazione } from 'feedback'
import { BaseButtons } from 'inputs'
import { BaseGridLayout } from 'layout'

function creaColonnaAzioniRiga(propsColonnaAzioniRiga) {
  return {
    accessor: 'azioniRiga',
    minWidth: 300,
    ...propsColonnaAzioniRiga,
    Cell: tableState => {
      const {
        ComponenteModificaRiga,
        modificaRiga,
        row,
        propsModal,
        propsModalModificaRiga,
        eliminaRiga,
        inAttesaEliminazioneRigaConUuid,
        disabilitaEliminazioneRiga,
        isRigaEliminabile
      } = tableState
      
      const valoriRigaAttuali = row.values

      return (
        <BaseGridLayout justifyCenter sx={{ p: 1 }}>
          <ComponenteModificaRiga
            modificaRiga={modificaRiga}
            valoriRigaAttuali={valoriRigaAttuali}
            propsModal={propsModal}
            propsModalModificaRiga={propsModalModificaRiga}
          />

          {(!disabilitaEliminazioneRiga && isRigaEliminabile(tableState)) &&
            <>
              <BasePopoverConfermaOperazione
                messaggio="Confermare l'eliminazione?"
                onConferma={() => eliminaRiga(valoriRigaAttuali)}
              >
                <BaseButtons.Elimina />
              </BasePopoverConfermaOperazione>

              {inAttesaEliminazioneRigaConUuid === valoriRigaAttuali.uuid &&
                <Box p={1}>
                  <LinearProgress />
                </Box>
              }
            </>
          }
        </BaseGridLayout>
      )
    }
  }
}

function creaColonnaUUID() {
  return {
    accessor: 'uuid',
    nascosta: true
  }
}

export {
  creaColonnaAzioniRiga,
  creaColonnaUUID
}