import { Fragment } from 'react'
import { string, element, array, func, oneOfType, object } from 'prop-types'
import { Box } from '@mui/material'
import { BaseTitolo, BaseTesto } from 'text'
import BaseDivider from './BaseDivider'
import BaseGridLayout from './BaseGridLayout'

BaseListaConDividers.propTypes = {
  titolo: string.isRequired,
  sottotitolo: oneOfType([string, element]),
  lista: array.isRequired,
  campoKey: string,
  // Alternativa al campoKey quando gli item non hanno un valore
  // che li identfifica direttamente sull'oggetto stesso
  getKeyOfItem: func,
  renderItem: func.isRequired,
  propsTitolo: object,
  propsSottotitolo: object,
  propsGrid: object
}

export default function BaseListaConDividers(props) {
  const {
    titolo,
    sottotitolo,
    lista,
    campoKey = 'uuid',
    getKeyOfItem,
    renderItem,
    propsTitolo,
    propsSottotitolo,
    propsGrid
  } = props

  return (
    <>
      <BaseTitolo {...propsTitolo}>
        {titolo} ({lista.length})
      </BaseTitolo>

      {sottotitolo &&
        <Box mb={1}>
          {typeof sottotitolo === 'string'
            ? <BaseTesto {...propsSottotitolo}>{sottotitolo}</BaseTesto>
            : sottotitolo
          }
        </Box>
      }

      <BaseGridLayout vertical {...propsGrid}>
        {lista.map((item, index) =>
          <Fragment key={getKeyOfItem?.(item) ?? item[campoKey] ?? index}>
            <BaseDivider sx={{ mb: 2 }} />
            {renderItem(item)}
          </Fragment>
        )}
      </BaseGridLayout>
    </>
  )
}