import { capitalizza, formattaCognomeNome } from 'utils'

function formattaInfoUtente(infoUtente) {
  return `${formattaCognomeNome(infoUtente)} (${infoUtente.nickname})`
}

function nomeCompleto(utente, sep = '-') {
  if (!utente?._nomeCompleto) {
    const nome = nominativo(utente)
    utente._nomeCompleto = nome.trim() === ''
      ? utente.info.nickname
      : `${nome} ${sep ? sep + ' ' : sep}${utente.info.nickname}`
  }
  return utente._nomeCompleto
}

function nominativo(utente) {
  if (!utente?._nominativo) {
    utente._nominativo = `${capitalizza(utente.info.nome)} ${capitalizza(utente.info.cognome)}`.trim()
  }
  return utente._nominativo
}

export {
  nomeCompleto,
  nominativo,
  formattaInfoUtente
}