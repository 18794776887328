import { object, bool, number } from 'prop-types'
import { FormHelperText, FormLabel, Box } from '@mui/material'
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { deepMerge, nonInProduzione } from 'utils'
import useControllerSeServe from './hooks/ControllerSeServeHook'
import useInputGenerico from './hooks/InputGenericoHook'

BaseEditor.propTypes = {
  ...useInputGenerico.propTypes,
  containerSx: object,
  height: number,
  config: object,
  attivaTitoli: bool,
  attivaListe: bool,
  attivaTabelle: bool,
  attivaAllineamenti: bool,
  attivaImmagini: bool,
  attivaLink: bool,
  attivaFunzioniAvanzate: bool,
}

export default function BaseEditor(props) {
  const {
    label,
    containerSx,
    ...restProps
  } = props

  const {
    required,
    error,
    warning,
    helperText,
    hidden,
    ...inputGenericoProps
  } = useInputGenerico({
    componenteControllato: true,
    underlyingComponent: 'editor',
    ...restProps
  })

  const {
    value,
    onBlur,
    onChange,
    propsRimanenti
  } = useControllerSeServe(inputGenericoProps)

  return (
    <Box
      sx={{
        textAlign: 'left',
        ...(hidden && { display: 'none' }),
        ...containerSx
      }}
    >
      <FormLabel
        required={required}
        error={error}
        {...(warning && { sx: { color: 'warning.main' } })}
      >
        {label}
      </FormLabel>

      <Box
        sx={{
          border: 2,
          borderColor: 'grey.400',
          borderRadius: 1,
          ...(warning && { borderColor: 'warning.main' }),
          ...(error && { borderColor: 'error.main' })
        }}
      >
        <Editor
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          {...propsRimanenti}
        />
      </Box>

      <FormHelperText
        error={error}
        {...(warning && { sx: { color: 'warning.main' } })}
      >
        {helperText}
      </FormHelperText>
    </Box>
  )
}



function Editor(props) {
  const {
    value,
    onBlur,
    onChange,
    disabled,

    height = 500,
    config: configEsterna,
    attivaTitoli,
    attivaListe,
    attivaTabelle,
    attivaAllineamenti,
    attivaImmagini,
    attivaLink,
    attivaFunzioniAvanzate,

    ...restProps
  } = props

  const flagAttivazioniToolbar = {
    attivaTitoli,
    attivaListe,
    attivaTabelle,
    attivaAllineamenti,
    attivaImmagini,
    attivaLink,
    attivaFunzioniAvanzate,
  }

  function onEditorChange(event, editor) {
    onChange(editor.getData().trim())
  }

  return (
    <CKEditor
      editor={ClassicEditor}
      config={creaConfig(configEsterna, flagAttivazioniToolbar)}
      debug={nonInProduzione()}

      data={value}
      onBlur={onBlur}
      onChange={onEditorChange}
      disabled={disabled}

      onReady={editor => {
        editor.editing.view.change(writer => {
          writer.setStyle('height', `${height}px`, editor.editing.view.document.getRoot())
        })
      }}

      {...restProps}
    />
  )
}



function creaConfig(configEsterna, flagAttivazioniToolbar) {
  const {
    attivaTitoli,
    attivaListe,
    attivaTabelle,
    attivaAllineamenti,
    attivaImmagini,
    attivaLink,
    attivaFunzioniAvanzate,
  } = flagAttivazioniToolbar

  return deepMerge({
    htmlSupport: {
      allow: [
        {
          // name: /^(div|p|table|tbody|td|tr|th|h1|h2|h3|h4|h5|h6)$/,
          name: /.*/,
          attributes: true,
          classes: true,
          styles: true
        },
      ]
    },
    image: {
      toolbar: [
        'imageTextAlternative',
        'toggleImageCaption',
        'imageStyle:inline',
        'imageStyle:block',
        'imageStyle:side',
        'linkImage'
      ]
    },
    table: {
      contentToolbar: [
        'tableColumn',
        'tableRow',
        'mergeTableCells',
        'tableCellProperties',
        'tableProperties'
      ]
    },
    toolbar: {
      items: [
        'bold', 'italic', 'underline',
        ...(attivaTitoli ? ['|', 'heading'] : []),
        ...(attivaAllineamenti ? ['|', 'alignment', 'indent', 'outdent', 'horizontalLine'] : []),
        ...(attivaListe ? ['|', 'bulletedList', 'numberedList'] : []),
        ...(attivaTabelle ? ['|', 'insertTable'] : []),
        ...(attivaImmagini ? ['|', 'imageInsert'] : []),
        ...(attivaLink ? ['|', 'link'] : []),
        '|', 'undo', 'redo', 'findAndReplace',
        ...(attivaFunzioniAvanzate ? ['|', 'restrictedEditingException', 'sourceEditing'] : [])
        
        /*
          Opzioni relative a plugin non inclusi nella build corrente di ckeditor
          Se serve includerli:
          - andare in ckeditor/src/ckeditor.j
          - decommentare gli import e le voci corrispondenti dentro Editor.builtinPlugins
          - rifare la build andando nella cartella ckeditor e lanciando npm run build
        */
        // 'pageBreak',
        // 'removeFormat',
        // 'specialCharacters',
        // 'htmlEmbed',
        // 'style',
        // 'textPartLanguage',
        // 'fontBackgroundColor',
        // 'fontColor',
        // 'fontFamily',
        // 'fontSize',
        // 'blockQuote',
        // 'todoList',
      ],
      shouldNotGroupWhenFull: true
    },
  }, configEsterna)
}