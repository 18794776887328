export const INIT = 'INIT';
export const UND = 'undefined';
export const ABILITATO = 'ABILITATO';
export const BLOCCATO = 'BLOCCATO';
export const LOGIN_UTENTE_BLOCCATO = 'LOGIN_UTENTE_BLOCCATO';
export const LOGIN_UTENTE_DISABILITATO = 'LOGIN_UTENTE_DISABILITATO';
export const LOGIN_UTENTE_DISABILITATO_TEMPORANEAMENTE = 'LOGIN_UTENTE_DISABILITATO_TEMPORANEAMENTE';
export const LOGIN_2FA = 'LOGIN_2FA';
export const LOGIN_2FA_INIT = 'LOGIN_2FA_INIT';
export const LOGIN_CREDENZIALI_ERRATE = 'LOGIN_CREDENZIALI_ERRATE';
export const PASSWORD_SCADUTA = 'PASSWORD_SCADUTA';
export const ERROR = 'ERROR';