import { ENTITA_BASE } from './EntitaBase'
import SceltaAgenti from './agenti/SceltaAgenti'
import { FormIndirizzoBase_Modal } from './indirizzo/FormIndirizzoBase'

function inputEntitaBase_SelectModal(nomeEntita, propsDaIntegrare, inputEntitaBase_SelectModal_Custom) {
  switch (nomeEntita) {
    case ENTITA_BASE.AGENTI:
      return SceltaAgenti
    default:
      if (inputEntitaBase_SelectModal_Custom) {
        return inputEntitaBase_SelectModal_Custom(nomeEntita, propsDaIntegrare)
      }
      break
  }
}

function inputEntitaBase_FormEntita(nomeEntita, propsDaIntegrare) {
  switch (nomeEntita) {
    case ENTITA_BASE.INDIRIZZO:
      return FormIndirizzoBase_Modal
    default:
      break
  }
}



export {
  inputEntitaBase_SelectModal,
  inputEntitaBase_FormEntita
}