import { element, number } from 'prop-types'
import { useDrawer } from './BaseDrawerLayout'
import BaseGridLayout from './BaseGridLayout'

BaseFooterLayout.propTypes = {
  mainContent: element.isRequired,
  footer: element,
  appBarHeight: number,
  mainContentPadding: number
}

export default function BaseFooterLayout(props) {
  const {
    mainContent,
    footer,
    appBarHeight: appBarHeight_Prop,
    mainContentPadding: mainContentPadding_Prop
  } = props

  const {
    appBarHeight: appBarHeight_Context,
    mainContentPadding: mainContentPadding_Context
  } = useDrawer()

  const mainContentPadding_Definitivo = mainContentPadding_Prop ?? mainContentPadding_Context
  const appBarHeight_Definitiva = appBarHeight_Prop ?? appBarHeight_Context

  return (
    <BaseGridLayout
      vertical
      spacing={0}
      justifySpaceBetween
      sx={({ spacing }) => ({
        // Fa in modo che il footer sia in fondo alla pagina, tenendo conto
        // dell'altezza degli offset dati dall'appBar e dal mainContentPadding
        minHeight: `calc(100vh - ${spacing(appBarHeight_Definitiva + mainContentPadding_Definitivo)})`
      })}
      propsGridItem={{ sx: { width: '100%' } }}
    >
      {mainContent}
      
      {footer}
    </BaseGridLayout>
  )
}