import { useState } from 'react'
import { Box } from '@mui/material'
import { BaseRadioGroup } from 'inputs'
import { PROP_TYPES_WRAPPER_FORM_MODIFICA } from '../entitaGenerica/components/FormModificaEntita'
import ListaScatoleEntita from '../entitaGenerica/components/ListaScatoleEntita'
import Persona from '../persona/Persona'

PasseggeriModifica.propTypes = PROP_TYPES_WRAPPER_FORM_MODIFICA

export default function PasseggeriModifica(props) {
  const { propsEntita, label, personaProprietario } = props

  const { valoriInput: passeggeri } = propsEntita
  const conducenteGiaPresente = passeggeri.find(({ conducente }) => conducente === true)

  const altreProps = conducenteGiaPresente ? {
    propsBottoneCrea: {
      opzioniCreazione: {
        entitaDaFondere: { conducente: false }
      }
    }
  } : {
    renderModalCreazione: ({ onSubmit }) => (
      <CreazionePasseggero
        onSubmit={onSubmit}
        personaProprietario={personaProprietario}
      />
    )
  }

  return (
    <ListaScatoleEntita
      label={label}
      propsEntita={propsEntita}
      renderEntita={({ persona, conducente }) =>
        <Persona
          persona={persona}
          visioneRiassuntiva
          propsHeader={{ sottotitolo: conducente ? 'Conducente' : 'Passeggero' }}
        />
      }
      testoBottoneCrea={conducenteGiaPresente
        ? 'Aggiungi passeggero'
        : 'Aggiungi conducente / passeggero'
      }
      {...altreProps}
    />
  )
}



function CreazionePasseggero(props) {
  const { onSubmit, personaProprietario } = props

  const [ruoloScelto, setRuoloScelto] = useState('')

  function terminaCreazione(ruolo, usaProprietario) {
    onSubmit({
      conducente: ruolo === 'conducente',
      ...(usaProprietario && { persona: personaProprietario })
    })
  }

  if (ruoloScelto === 'conducente' && personaProprietario) {
    return (
      <Box sx={{ display: 'inline-block' }}>
        <BaseRadioGroup
          options={[
            { label: 'Crea nuova anagrafica', value: 'nuova' },
            { label: 'Usa anagrafica proprietario', value: 'proprietario' }
          ]}
          onChange={e => terminaCreazione(ruoloScelto, e.target.value === 'proprietario')}
          visualizzazioneOrizzontale
          key='radioAnagrafica'
        />
      </Box>
    )
  }

  return (
    <Box sx={{ display: 'inline-block' }}>
      <BaseRadioGroup
        options={[
          { label: 'Conducente', value: 'conducente' },
          { label: 'Passeggero', value: 'passeggero' }
        ]}
        value={ruoloScelto}
        onChange={e => {
          const ruolo = e.target.value
          if (ruolo === 'conducente' && personaProprietario) {
            setRuoloScelto(ruolo)
          } else {
            terminaCreazione(ruolo)
          }
        }}
        visualizzazioneOrizzontale
        key='radioRuolo'
      />
    </Box>
  )
}