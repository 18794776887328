import { string, number } from 'prop-types'

BaseIcona.propTypes = {
  src: string.isRequired,
  alt: string,
  width: number
}

export default function BaseIcona(props) {
  const {
    src,
    alt = '',
    width = 40
  } = props

  return (
    <img
      src={src}
      alt={`Icona ${alt}`}
      style={{ width }}
    />
  )
}
