import { useEffect } from 'react'
import { Stack, Grid, Box } from '@mui/material'
import loggedOut from 'assets/img/loggedout.png'
import loggedOutLoading from 'assets/img/loggedout_loading.png'
import { BaseButtons } from 'inputs'
import { BaseScatola } from 'layout'
import { BaseTesto, BaseTitolo } from 'text'
import { OPERAZIONI_BASE } from '../network/OperazioniBase'
import useRichiestaServer from '../network/RichiestaServerHook'
import { useContextGenerico } from '../util/ContextGenericoProvider'
import { useSsoIncloud } from './SsoProvider'
import { useUserUpdate } from './userProvider/UserProvider'

export default function LoginConSso() {
  const { titoloApp, nomeApp } = useContextGenerico()

  const { handleLogin } = useUserUpdate()

  const {
    tokenState: token,
    login
  } = useSsoIncloud()

  const {
    ScatolaMessaggi,
    inviaOperazione,
    inAttesaDelServer
  } = useRichiestaServer()

  function redirigiSuLogin() {
    const URLdiRitorno_DopoLogin = window.location.href
    login(URLdiRitorno_DopoLogin)
  }

  useEffect(() => {
    if (!token) {
      // Decommentare per abilitare la redirezione automatica senza premere il bottone
      // redirigiSuLogin()
      return
    }

    if (token) {
      async function validaToken() {
        const { ok, data } = await inviaOperazione(OPERAZIONI_BASE.TOKEN_VALIDATE, { application: nomeApp }, { token })
        if (ok) handleLogin(data)
      }
      validaToken()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  return (
    <BaseScatola>
      <Grid container spacing={2}>
        <Grid item md={6}>
          <Stack direction='column' spacing={2}>
            <BaseTitolo sx={{ mt: 2, color: 'primary.main' }} grande>
              {inAttesaDelServer ? 'Valutazione Autenticazione' : 'Sei stato disconnesso'}
            </BaseTitolo>

            {ScatolaMessaggi}

            {inAttesaDelServer ? (
              <BaseTitolo>Verifica dati utente in corso...</BaseTitolo>
            ) : (
              <BaseTesto sx={{ mt: 5, color: '#999', fontSize: { xs: '1.2rem', md: '1.5rem' } }}>
                Sei stato disconnesso con successo da <b>{titoloApp}</b>.
                <br />
                Se vuoi continuare ad operarare con la suite Incloud, effettua il login dalla pagina di accesso.
              </BaseTesto>
            )}

            {!inAttesaDelServer &&
              <LoginConSso.LoginButton
                onClick={redirigiSuLogin}
                inAttesa={inAttesaDelServer}
              />
            }
          </Stack>
        </Grid>

        <Grid item md={6} sx={{ textAlign: 'center', margin: '0 auto', my: 2 }}>
          <Box
            component='img'
            src={inAttesaDelServer ? loggedOutLoading : loggedOut}
            sx={{ borderRadius: '320px', maxWidth: { xs: '260px', md: '350px' } }}
          />
        </Grid>
      </Grid>
    </BaseScatola>
  )
}



LoginConSso.LoginButton = (props) => {
  const { onClick, inAttesa = false, ...rest } = props

  return (
    <BaseButtons.Login
      testo='Vai alla pagina di accesso'
      onClick={onClick}
      inAttesa={inAttesa}
      size='large'
      style={{ maxWidth: 'fit-content', margin: '70px auto 30px' }}
      {...rest}
    />
  )
}