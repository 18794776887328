import { useMemo } from 'react'
import { func, object } from 'prop-types'
import { ICONE } from 'icons'
import { BaseHeaderConIcona } from 'layout'
import { useEntita } from '../entitaGenerica/components/EntitaContext'
import ModificaRif from '../entitaGenerica/components/ModificaRif'

ModificaRifPersona.propTypes = {
  creaDescrittoriPersone: func.isRequired,
  propsHeader: object
}

export default function ModificaRifPersona(props) {
  const { creaDescrittoriPersone, propsHeader, ...restProps } = props

  const {
    infoEntitaPrincipale: { entita },
    elenchi
  } = useEntita()

  const descrittori = useMemo(
    () => creaDescrittoriPersone(entita, elenchi),
    [creaDescrittoriPersone, entita, elenchi]
  )

  return (
    <ModificaRif
      descrittori={descrittori}
      renderEntita={(persona, label) =>
        <BaseHeaderConIcona
          titolo={label}
          icona={<ICONE.PERSONA />}
          dimensioneIcona='media'
          {...propsHeader}
        />
      }
      label='Persona'
      {...restProps}
    />
  )
}