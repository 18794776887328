import { v4 as UUIDv4 } from 'uuid'

function generaUUID() {
  return UUIDv4()
}

function nonInProduzione() {
  // eslint-disable-next-line no-undef
  return process.env.NODE_ENV !== 'production'
}

function gererateRandomPassword(length = 10) {
  const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
  return new Array(length)
    .fill('')
    .map(_ => {
      let umax = Math.pow(2, 32)
      let r = new Uint32Array(1)
      let max = umax - (umax % chars.length)

      do crypto.getRandomValues(r)
      while (r[0] > max)

      return chars[r[0] % chars.length]
    })
    .join('')
}

export {
  generaUUID,
  nonInProduzione,
  gererateRandomPassword
}