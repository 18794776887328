import { useRichiestaServer, FormCambioPassword, BaseButtons } from '@lucan/base-ui';
import MessageBox from 'components/MessageBox';
import Loading from 'components/Loading';
import React, { useMemo, useState } from 'react';
import { zxcvbn, zxcvbnOptions } from '@zxcvbn-ts/core'
import zxcvbnITPackage from '@zxcvbn-ts/language-it'

zxcvbnOptions.setOptions({
  translations:zxcvbnITPackage.translations,
	dictionary: {
    ...zxcvbnITPackage.dictionary,
  },
})

const AggiornaPassword = ({username, onComplete, token, logout}) => {

  const {
    inviaOperazione,
    inAttesaDelServer,
  } = useRichiestaServer()

	const [errorOp, setErrorOp] = useState('')

	const errorMsg =  useMemo(() => {

		if("LOGIN_PASSWORD_GIA_UTILIZZATA" === errorOp.codice){
			return  {main:'Password già utilizzata',secondary: 'inserire una password diversa da quelle usate in passato' }
		}
		if(errorOp)
			return {main:'Errore invio',secondary:errorOp.msg};	
  }, [ errorOp])


	async function eseguiAggiornaPassword(credenziali) {
		const {newPassword, oldPassword} = credenziali;
		const { ok, error:errorRichiesta} = await inviaOperazione(
			"password.update", { newPassword, oldPassword },{token, username})
		if (ok){
			onComplete()	
		}else{
			setErrorOp(errorRichiesta.messaggi[0]);
		}		 
  }

	return(<>
			<Loading loading={inAttesaDelServer} />
			<MessageBox severity='warning'  errorMsg={{ main: 'La tua password è scaduta',secondary:"Inserire la vecchia password e la nuova per accedere"}} />
			{!inAttesaDelServer && errorMsg && <><br /><MessageBox  errorMsg={errorMsg}/><br /></>}		
			<FormCambioPassword onSubmit={eseguiAggiornaPassword}  zxcvbn={zxcvbn} variant={'outlined'} propsButton={{className:'full'}}/>
			<hr style={{marginTop:'30px',  borderTop: "1px solid #ccc"}}/>
			<div style={{color:'#999', marginTop:'20px',marginBottom:'5px', fontSize:'0.9em'}}>oppure</div>      
			<BaseButtons.Logout testo="torna al login" size={'small'} color={'default'} variant="outlined" onClick={logout}  className='full' />
		</>)

}


export default AggiornaPassword;