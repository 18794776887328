import { useFormContext } from 'react-hook-form'
import { ICONE } from 'icons'
import { BaseSelectModal } from 'inputs'
import { BaseGridLayout } from 'layout'
import { useUser } from '../../userManagement/userProvider/UserProvider'
import HeaderGruppo from './HeaderGruppo'
import { creaLabelGruppo } from './utilPermessiEntita'

export default function SelectSceltaGruppi(props) {
  const { getGruppiUtente } = useUser()

  const {
    gruppiScelti,
    options = getGruppiUtente(),
    contenutoPrimaDelBottone,
    ...restProps
  } = props

  const risultatiUseForm = useFormContext()

  let gruppiSceltiDefinitivi = (
    gruppiScelti
    || risultatiUseForm?.watch(restProps.name)
    || []
  )

  return (
    <BaseGridLayout vertical spacing={1}>
      <BaseGridLayout alignCenter>
        {contenutoPrimaDelBottone}

        <BaseSelectModal
          sceltaMultipla
          options={options}
          getOptionKey={({ codice }) => codice}
          renderOption={gruppo =>
            <HeaderGruppo gruppo={gruppo} senzaPadding={false} />
          }
          getOptionLabel={creaLabelGruppo}
          getOptionSearchString={
            ({ nome, descr }) => [nome, descr].join(' ')
          }

          label='Gruppi'
          chipIcon={<ICONE.GRUPPO />}
          labelRicerca='nome, descrizione'
          {...restProps}
        />
      </BaseGridLayout>

      <BaseGridLayout alignCenter spacing={4}>
        {gruppiSceltiDefinitivi.map(gruppo =>
          <HeaderGruppo
            gruppo={gruppo}
            ignoraLivelloProfondita
            key={gruppo.codice}
            iconaNonColorata
          />
        )}
      </BaseGridLayout>
    </BaseGridLayout>
  )
}