import { useState } from 'react'
import { Box } from '@mui/material'
import { BaseContenutoConTitoloEFallback, BaseModal, useModal } from 'layout'
import { BaseTitolo } from 'text'
import Azienda from '../azienda/Azienda'
import Persona from '../persona/Persona'

export default function Proprietario(props) {
  const {
    proprietario: { persona, azienda },
    visioneRiassuntiva
  } = props

  const propsDaPassare = {
    visioneRiassuntiva: true,
    propsHeader: {
      iconaNonColorata: true,
      ...(visioneRiassuntiva && { sottotitolo: 'Proprietario' })
    }
  }

  const { openModal, modalRef } = useModal()
  const [proprietarioEspanso, setProprietarioEspanso] = useState(null)

  const proprietarioDaMostrare = (
    <Box
      {...(!visioneRiassuntiva && {
        sx: { cursor: 'pointer', '&:hover': { border: 1, borderColor: 'info.main', borderRadius: 1 } },
        onClick: () => {
          setProprietarioEspanso({ persona, azienda })
          openModal()
        }
      })}
    >
      {persona && <Persona persona={persona} {...propsDaPassare} />}
      {azienda && <Azienda azienda={azienda} {...propsDaPassare} />}
    </Box>
  )

  if (visioneRiassuntiva) return proprietarioDaMostrare

  return (
    <BaseContenutoConTitoloEFallback
      datiPresentiSe={persona || azienda}
      titolo='Proprietario'
      propsTitolo={{ colorato: true }}
    >
      {proprietarioDaMostrare}

      <BaseModal
        ref={modalRef}
        // Il ritardo serve per avere un miglior effetto visivo alla chiusura della modals
        onClose={() => setTimeout(() => setProprietarioEspanso(null), 500)}
        maxWidth='lg'
      >
        {proprietarioEspanso ? (
          <>
            <BaseTitolo>Dettaglio Proprietario</BaseTitolo>
            <div style={{ textAlign: 'left' }}>
              {persona && <Persona persona={persona} espanso propsHeader={{ senzaPadding: false }} />}
              {azienda && <Azienda azienda={azienda} espanso propsHeader={{ senzaPadding: false }} />}
            </div>
          </>
        ) : <></>}
      </BaseModal>
    </BaseContenutoConTitoloEFallback>
  )
}