import { Box, ClickAwayListener, Stack } from '@mui/material'
import { useGridApiContext } from '@mui/x-data-grid/hooks/utils/useGridApiContext'
import { useState } from 'react'
import PropTypes from 'prop-types'
import * as _ from 'lodash'
import { numberOperators, stringOperators } from './dataGridFilters'
import BaseDataGridFilterElement from './BaseDataGridFilterElement'
import { BaseButtons } from 'inputs'
import { ICONE } from 'icons'

/**
 * @typedef {import("../../common/tables/datagrid/BaseDataGridFilterPanel").BaseDataGridFilterPanelProps} BaseDataGridFilterPanelProps 
 * @typedef {import("../../common/tables/datagrid/BaseDataGridFilterPanel").BaseDataGridFilterPanel} BaseDataGridFilterPanel 
 */

/**
 * 
 * @param {BaseDataGridFilterPanelProps} param0 
 * @type {BaseDataGridFilterPanel}
 */
const BaseDataGridFilterPanel = ({
  columns,
  initialFilters = [],
  onClose,
  onFiltersChange
}) => {
  const [filters, setFilters] = useState(initialFilters)
  const apiRef = useGridApiContext()

  const setOperators = (column) => {
    switch (column?.type) {
      case 'number':
      case 'date':
        return numberOperators
      case 'string':
        return stringOperators
      case 'singleSelect':
        return setSingleSelectOperators(column.options)
      default:
        return []
    }
  }

  const setSingleSelectOperators = (optionValues = []) => {
    if (optionValues.every((o) => typeof o === 'number')) {
      return numberOperators
    }
    return stringOperators
  }

  /*** handle events*/

  const onAddFilterClick = () => {
    const newFilter = {}
    setFilters((oldFilter) => [...oldFilter, newFilter])
  }

  const onConfirmClick = () => {
    closePanel(filters)
  }

  const handleDeleteFilter = (id) => {
    const newFilters = filters.filter((_f, index) => index !== id)
    setFilters(newFilters)
    if (newFilters.length === 0) {
      closePanel(newFilters)
    }
  }

  const closePanel = (filters) => {
    apiRef.current.hideFilterPanel()
    onClose()
    onFiltersChange(filters)
  }

  const handleClickAway = () => {
    apiRef.current.hideFilterPanel()
    onClose()
  }

  const handleColumnChange = (column, index) => {
    setFilters((oldFilters) =>
      oldFilters.map((oldFilter, i) => {
        if (index === i) {
          return { ...oldFilter, column }
        }
        return oldFilter
      })
    )
  }

  const handleOperatorChange = (operator, index) => {
    setFilters((oldFilters) =>
      oldFilters.map((oldFilter, i) => {
        if (index === i) {
          return { ...oldFilter, operator }
        }
        return oldFilter
      })
    )
  }

  const handleValueChange = (value, index) => {
    setFilters((oldFilters) =>
      oldFilters.map((oldFilter, i) => {
        if (index === i) {
          return { ...oldFilter, value }
        }
        return oldFilter
      })
    )
  }

  const filterIsInvalid = () => {
    return filters.find(
      (filter) => _.isUndefined(filter.column) || _.isUndefined(filter.operator) || _.isUndefined(filter.value)
    )
      ? true
      : false
  }
  return (
    <>
      {/**
       * onMouseUp fix problem to trigger onClickAway when click in form inside
       */}
      <ClickAwayListener mouseEvent='onMouseUp' onClickAway={handleClickAway}>
        <Stack sx={{ p: 2 }}>
          {filters.map(
            (filter, index) => (
              <BaseDataGridFilterElement
                key={index}
                id={index}
                filter={filter}
                columns={columns}
                operators={setOperators(filter.column)}
                onDelete={handleDeleteFilter}
                onColumnChange={handleColumnChange}
                onOperatorChange={handleOperatorChange}
                onValueChange={handleValueChange}
              />
            )
          )}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between'
            }}>
            <BaseButtons.Custom
              testo='Aggiungi filtro'
              variant='outlined'
              color='secondary'
              size='small'
              startIcon={<ICONE.AGGIUNGI_SEMPLICE />}
              onClick={onAddFilterClick}
            />
            {filters.length ? (
              <BaseButtons.Custom
                testo='Conferma'
                variant='contained'
                color='primary'
                size='small'
                startIcon={<ICONE.CHECK_SEMPLICE />}
                onClick={onConfirmClick}
                disabled={filterIsInvalid()}
              />
            ) : (
              <></>
            )}
          </Box>
        </Stack>
      </ClickAwayListener>
    </>
  )
}


BaseDataGridFilterPanel.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  initialFilters: PropTypes.arrayOf(PropTypes.object),
  onFiltersChange: PropTypes.func,
  onClose: PropTypes.func,
}



export default BaseDataGridFilterPanel
