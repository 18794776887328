import { node, string, bool, object, oneOf } from 'prop-types'
import { CardHeader } from '@mui/material'
import { BaseAvatar } from 'icons'
import { BaseTesto } from 'text'
import { sx } from 'utils'

BaseHeaderConIcona.propTypes = {
  icona: node.isRequired,
  coloreIcona: string,
  iconaNonColorata: bool,
  dimensioneIcona: oneOf(['grande', 'media', 'piccola']),
  titolo: node.isRequired,
  sottotitolo: node,
  propsTitolo: object,
  propsIcona: object,
  senzaPadding: bool
}

export default function BaseHeaderConIcona(props) {
  const {
    icona,
    coloreIcona,
    iconaNonColorata,
    dimensioneIcona,
    titolo,
    sottotitolo,
    propsTitolo = {},
    propsIcona = {},
    senzaPadding = true,
    ...restProps
  } = props

  const propsTitoloDefinitive = {
    ...propsTitolo,
    ...sx(propsTitolo, {
      fontSize: '1rem',
      fontWeight: 'fontWeightBold'
    })
  }

  return (
    <CardHeader
      avatar={
        <BaseAvatar
          icona={icona}
          coloreIcona={coloreIcona}
          iconaNonColorata={iconaNonColorata}
          dimensioneIcona={dimensioneIcona}
          {...propsIcona}
        />
      }
      title={
        <BaseTesto {...propsTitoloDefinitive} >
          {titolo}
        </BaseTesto>
      }
      subheader={sottotitolo}
      {...restProps}
      {...sx(restProps, senzaPadding && { p: 0 })}
    />
  )
}