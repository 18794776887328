import {
  AccountCircle,
  Add,
  AddCircleOutline,
  Alarm,
  ArrowCircleRight,
  ArrowDownward,
  ArrowDropDown,
  ArrowForward,
  AttachFile,
  Agriculture,
  AspectRatio,
  Balance,
  Bloodtype,
  Brush,
  Business,
  Cached,
  CalendarToday,
  Cancel,
  CheckCircle,
  Check,
  ChevronLeft,
  ChevronRight,
  Circle,
  Clear,
  Code,
  Construction,
  ContentCopy,
  Delete,
  Devices,
  DirectionsWalk,
  Done,
  DragHandle,
  DriveEta,
  Edit,
  Email,
  Error,
  ExitToApp,
  ExpandLess,
  ExpandMore,
  Extension,
  FolderOpen,
  Forum,
  Gavel,
  GetApp,
  Groups,
  HelpOutline,
  Home,
  Image,
  Inventory,
  ListAlt,
  LocalBar,
  LockOpen,
  LockClock,
  Lock,
  MedicalServices,
  Menu,
  MiscellaneousServices,
  MoreVert,
  NotInterested,
  NoMeals,
  Numbers,
  Password,
  Person,
  Phone,
  Place,
  Public,
  Publish,
  Refresh,
  RemoveRedEye,
  Restaurant,
  Restore,
  Room,
  Rotate90DegreesCcw,
  Rotate90DegreesCwOutlined,
  Save,
  SaveAlt,
  Scale,
  Search,
  Send,
  Settings,
  Signpost,
  Star,
  StickyNote2,
  ThumbDown,
  ThumbUp,
  Update,
  Visibility,
  VisibilityOff,
  VpnKey,
  Warning,
  EmojiPeople,
  Close,
  FilterList,
  Bolt,
  Videocam,
  Notifications,
  Article,
  Info,
  CompareArrows,
  FormatAlignLeft,
  Loop,
  Storefront,
  Tag,
  LocationOff,
  LocationOn,
  Sell,
  GpsFixedSharp,
  ArrowBack,
  ArrowUpward,
} from '@mui/icons-material'

import ExcelIcon from 'assets/icons/ExcelIcon.png'
import IconaComando from 'assets/icons/IconaComando'
import IconaDateTime from 'assets/icons/IconaDateTime'
import IconaRuolo from 'assets/icons/IconaRuolo'
import IconaTemplateDocumento from 'assets/icons/IconaTemplateDocumento'
import LogoIncloud from 'assets/icons/LogoIncloud'
import LogoIncloudMini from 'assets/icons/LogoIncloudMini.png'
import PdfIcon from 'assets/icons/PdfIcon.png'
import WordIcon from 'assets/icons/WordIcon.png'

const ICONE = {
  CERCA: Search,
  CALENDARIO: CalendarToday,
  MAP_MARKER: Room,
  LOGIN: VpnKey,
  LOGOUT: ExitToApp,
  MODIFICA: Edit,
  ANNULLA_MODIFICHE: Restore,
  CHEVRON_RIGHT: ChevronRight,
  CHEVRON_LEFT: ChevronLeft,
  AGGIORNA: Update,
  ESPORTA: SaveAlt,
  FRECCIA_GIU: ArrowDownward,
  FRECCIA_SU: ArrowUpward,
  ACCOUNT_CERCHIO: AccountCircle,
  LUCCHETTO_APERTO: LockOpen,
  LUCCHETTO_CHIUSO: Lock,
  LUCCHETTO_TEMPO: LockClock,
  ELIMINA: Delete,
  SALVA: Save,
  CHIUDI: Cancel,
  HAMBURGER: Menu,
  ESPANDI_PIU: ExpandMore,
  ESPANDI_MENO: ExpandLess,
  AGGIUNGI: AddCircleOutline,
  AGGIUNGI_SEMPLICE: Add,
  CARICA: Publish,
  SCARICA: GetApp,
  AIUTO: HelpOutline,
  CONFERMA: Done,
  OPZIONI: Settings,
  PERSONA: Person,
  VEICOLO: DriveEta,
  AZIENDA: Business,
  OROLOGIO: Alarm,
  DISATTIVATO: NotInterested,
  NASCOSTO: VisibilityOff,
  VISIBILE: Visibility,
  TELEFONO: Phone,
  EMAIL: Email,
  INVIA: Send,
  POLLICE_SU: ThumbUp,
  POLLICE_GIU: ThumbDown,
  CODICE: Code,
  PULISCI: Clear,
  TRE_PUNTINI: MoreVert,
  HOME: Home,
  BILANCIA: Balance,
  CARTELLA: FolderOpen,
  ALLEGATO: AttachFile,
  MANUTENZIONE: Construction,
  SCATOLONE: Inventory,
  LUOGO: Place,
  MARTELLETTO: Gavel,
  FRECCIA_DESTRA_CERCHIATA: ArrowCircleRight,
  SALUTE: MedicalServices,
  SANGUE: Bloodtype,
  DRINK: LocalBar,
  STRUMENTO_MISURA: Scale,
  GRUPPO: Groups,
  PENNELLO: Brush,
  PEDONE: DirectionsWalk,
  CERCHIO: Circle,
  SEGNALE: Signpost,
  LISTA_FORMATTATA: ListAlt,
  NUMERO: Numbers,
  FRAMMENTO: Extension,
  DRAG: DragHandle,
  DROPDOWN: ArrowDropDown,
  COPIA: ContentCopy,
  IMMAGINE: Image,
  VPN_USER: Public,
  SISTEMA_USER: Public,
  SERVIZIO_INERNO_USER: MiscellaneousServices,
  SERVIZIO_ESTERNO_USER: MiscellaneousServices,
  DISPOSITIVO_USER: Devices,
  PERSONA_USER: Person,
  STAFF: Star,
  PASSWORD: Password,
  PERMESSO: VpnKey,
  FRECCIA_A_DESTRA: ArrowForward,
  RUOTA_90_GRADI_SENSO_ORARIO: Rotate90DegreesCwOutlined,
  RUOTA_90_GRADI_SENSO_ANTIORARIO: Rotate90DegreesCcw,
  RUOTA_180_GRADI: Cached,
  DOMANDE_RISPOSTE: Forum,
  FILTRA_LISTA: FilterList,
  CHECK_SEMPLICE: Check,
  CHIUDI_SEMPLICE: Close,
  PRESENZA: EmojiPeople,
  FULMINE: Bolt,
  VIDEO: Videocam,
  CAMPANA: Notifications,
  NOTIZIA: Article,
  REFRESH: Refresh,
  VISUALIZZA: RemoveRedEye,
  FOOD: Restaurant,
  NO_FOOD: NoMeals,
  PRODUTTORI_AGRICOLI: Agriculture,
  NOTE: StickyNote2,
  AREA_SIZE: AspectRatio,
  COMPARE: CompareArrows,
  MODULO: Extension,
  FORMATTAZIONE: FormatAlignLeft,
  LOOP: Loop,
  BANCARELLA: Storefront,
  TAG: Tag,
  MARKER: LocationOn,
  NO_MARKER: LocationOff,
  CARTELLINO_PREZZO: Sell,
  GPS: GpsFixedSharp,
  FRECCIA_INDIETRO: ArrowBack,

  INFO(props) {
    return <Info color='info' {...props} />
  },
  ERRORE(props) {
    return <Error color='error' {...props} />
  },
  OK(props) {
    return <CheckCircle color='success' {...props} />
  },
  ATTENZIONE(props) {
    return <Warning color='warning' {...props} />
  },

  PDF_ICON: PdfIcon,
  EXCEL_ICON: ExcelIcon,
  WORD_ICON: WordIcon,
  LOGO_INCLOUD: LogoIncloud,
  TEMPLATE_DOCUMENTO: IconaTemplateDocumento,
  COMANDO: IconaComando,
  DATE_TIME: IconaDateTime,
  RUOLO: IconaRuolo,

  LOGO_INCLOUD_MINI(props) {
    return (
      <img
        src={LogoIncloudMini}
        {...props}
        style={{ width: '40px', borderRadius: 5, ...props?.style }}
      />
    )
  }
}

const ICONE_VALORI = Object.values(ICONE)

export { ICONE, ICONE_VALORI }
