import { string, arrayOf, shape, object } from 'prop-types'
import { BaseGridLayout, BaseContenutoConTitoloEFallback } from 'layout'
import { BaseLink } from 'navigation'
import { BaseTesto } from 'text'
import { buildURL_WithQueryString, apriLinkInNuovaTab } from 'utils'
import { useRoutes } from '../navigation/RoutesProvider'

ListaLinkInfrazioni.propTypes = {
  infrazioni: arrayOf(shape({
    idConnet: string.isRequired,
    articolo: string.isRequired,
    numero: string.isRequired
  })).isRequired,
  propsTitoloEFallback: object
}

export default function ListaLinkInfrazioni(props) {
  const { infrazioni, propsTitoloEFallback } = props

  const { CONNET_BASE_URL } = useRoutes()

  function creaLinkInfrazione(id) {
    return buildURL_WithQueryString(
      CONNET_BASE_URL,
      { page: 'dettagli', id }
    )
  }

  return (
    <BaseContenutoConTitoloEFallback
      datiPresentiSe={infrazioni.length > 0}
      titolo='Infrazioni'
      fallback='Non sono presenti infrazioni.'
      {...propsTitoloEFallback}
    >
      <BaseGridLayout vertical spacing={0}>
        {infrazioni.map(({ idConnet, articolo, numero, uuidInfrazione }) => {
          const linkInfrazione = creaLinkInfrazione(idConnet)
          return (
            <BaseTesto key={uuidInfrazione}>
              <BaseLink
                onClick={e => {
                  e.preventDefault()
                  apriLinkInNuovaTab(linkInfrazione)
                }}
                href={linkInfrazione}
                component='a'
              >
                {numero}
              </BaseLink>

              {` (${articolo})`}
            </BaseTesto>
          )
        })}
      </BaseGridLayout>
    </BaseContenutoConTitoloEFallback>
  )
}