import { bool, arrayOf, object } from 'prop-types'
import { useFormContext } from 'react-hook-form'
import { ICONE } from 'icons'
import { BaseSelectModal } from 'inputs'
import { BaseGridLayout } from 'layout'
import { formattaCognomeNome } from 'utils'
import Agente from './Agente'
import ListaAgenti from './ListaAgenti'

SceltaAgenti.propTypes = {
  agentiScelti: arrayOf(object),
  agentiTutti: arrayOf(object),
  visualizzazioneOrizzontale: bool,
  propsGrid: object,
  propsListaAgenti: object
}

SceltaAgenti.campoConOptions = true

export default function SceltaAgenti(props) {
  const {
    agentiScelti,
    agentiTutti,
    visualizzazioneOrizzontale = true,
    propsGrid,
    propsListaAgenti,
    ...propsSelect
  } = props

  const risultatiUseForm = useFormContext()

  let agentiSceltiDefinitivi = (
    agentiScelti
    || risultatiUseForm?.watch(propsSelect.name)
    || []
  )

  // ListaAgenti vuole sempre un array
  if (!Array.isArray(agentiSceltiDefinitivi)) {
    agentiSceltiDefinitivi = [agentiSceltiDefinitivi]
  }

  return (
    <BaseGridLayout
      vertical={!visualizzazioneOrizzontale}
      {...visualizzazioneOrizzontale && { alignCenter: true }}
      {...propsGrid}
    >
      <BaseSelectModal
        sceltaMultipla
        options={agentiTutti}
        getOptionKey={({ id }) => id}
        renderOption={agente =>
          <Agente
            agente={agente}
            visioneRiassuntiva
            propsHeader={{ senzaPadding: false }}
          />
        }
        getOptionLabel={formattaCognomeNome}
        getOptionSearchString={
          ({ nome, cognome, matricola, qualifica }) => [nome, cognome, matricola, qualifica].join(' ')
        }

        label='Agenti'
        chipIcon={<ICONE.PERSONA />}
        labelRicerca='nome, cognome, matricola, qualifica'
        {...propsSelect}
      />

      <ListaAgenti
        agenti={agentiSceltiDefinitivi}
        visioneRiassuntiva
        hidden={propsSelect.hidden || agentiSceltiDefinitivi.length === 0}
        {...propsListaAgenti}
      />
    </BaseGridLayout>
  )
}