import { useMemo } from 'react'
import { arrayOf, shape, string, object, bool } from 'prop-types'
import { TextField, Autocomplete } from '@mui/material'
import useInputGenerico from './hooks/InputGenericoHook'
import useControllerSeServe from './hooks/ControllerSeServeHook'

BaseAutocompletamento.propTypes = {
  options: arrayOf(shape({
    label: string.isRequired,
    value: string.isRequired
  })).isRequired,
  pair: bool,
  controlloValueCaseInsensitive: bool,
  propsAutocomplete: object,
  ...useInputGenerico.propTypes
}

BaseAutocompletamento.campoConOptions = true

export default function BaseAutocompletamento(props) {
  const {
    options,
    pair = false,
    controlloValueCaseInsensitive = false,
    propsAutocomplete,
    ...restProps
  } = props

  const inputGenericoProps = useInputGenerico({
    componenteControllato: true,
    messaggioAiuto: 'Scrivere per avere suggerimenti',
    widthType: 'width',
    ...restProps
  })

  const {
    value,
    onBlur,
    onChange,
    ref,
    propsRimanenti
  } = useControllerSeServe(inputGenericoProps)

  const valueDefinitivo = useMemo(() => {
    const valoreAttuale = pair ? value?.first : value
    const opzioneRelativaAlValoreAttuale = options.find(({ value }) =>
      controlloValueCaseInsensitive
        ? value.toUpperCase() === (valoreAttuale ?? '').toUpperCase()
        : value === valoreAttuale
    )
    // Al componente MUI piace value = null quando nessuna opzione è selezionata
    return opzioneRelativaAlValoreAttuale || null
  }, [pair, value, options, controlloValueCaseInsensitive])

  function onChangeConConversioneInPair(_, nuovoValore) {
    if (nuovoValore) {
      const { value, label } = nuovoValore
      onChange(pair ? { first: value, second: label } : value)
    } else {
      onChange(pair ? { first: '', second: '' } : '')
    }
  }

  return (
    <Autocomplete
      options={options}
      value={valueDefinitivo}
      onChange={onChangeConConversioneInPair}
      getOptionLabel={option => option.label}
      disabled={propsRimanenti.disabled}
      openOnFocus
      autoHighlight
      {...propsAutocomplete}
      renderInput={params =>
        <TextField
          {...params}
          inputRef={ref}
          onBlur={onBlur}
          {...propsRimanenti}
        />
      }
    />
  )
}
