import { string } from 'prop-types'
import { useHistory } from 'react-router-dom'
import { BaseButtons } from 'inputs'

BaseBottoneTornaIndietro.propTypes = {
  rotta: string
} 

export default function BaseBottoneTornaIndietro(props) {
  const { rotta } = props

  const history = useHistory()

  return (
    <BaseButtons.MenoDettagli
      testo='Torna indietro'
      onClick={() => rotta ? history.push(rotta) : history.goBack()}
      {...props}
    />
  )
}
