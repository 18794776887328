import { object, bool, node } from 'prop-types'
import { Grid, Box } from '@mui/material'
import Contatti from './Contatti'
import DatiAnagrafici, { DataELuogoDiNascita } from './DatiAnagrafici'
import Documento from './Documento'
import Indirizzo from '../indirizzo/Indirizzo'
import { ICONE } from 'icons'
import { BaseAccordion, BaseHeaderConIcona } from 'layout'
import { BaseListaCoppieChiaveValore } from 'text'
import { formattaCognomeNome } from 'utils'

Persona.propTypes = {
  persona: object.isRequired,
  visioneRiassuntiva: bool,
  propsHeader: object,
  contenutoExtraNonEspanso: node,
  contenutoExtraEspanso: node,
  nascondiCittadinanza: bool
}

export default function Persona(props) {
  const {
    persona,
    visioneRiassuntiva,
    propsHeader,
    contenutoExtraNonEspanso,
    contenutoExtraEspanso,
    nascondiCittadinanza,
    ...restProps
  } = props

  if (!persona) return null

  const {
    cittadinanza,
    codiceFiscale,
    cognome,
    contatti,
    documento,
    indirizzoResidenza,
    nascita,
    nome,
    pec,
    sesso
  } = persona

  const cognomeNome = formattaCognomeNome(persona)
  const contattiConPec = contatti ? { ...contatti, pec } : { pec }

  const header = (
    <BaseHeaderConIcona
      icona={<ICONE.PERSONA />}
      titolo={cognomeNome}
      sottotitolo={codiceFiscale}
      {...propsHeader}
    />
  )

  if (visioneRiassuntiva) return header

  return (
    <BaseAccordion
      contenutoNonEspanso={
        <Grid container alignItems='center'>
          <Grid item md={3}>{header}</Grid>

          <Grid item md={contenutoExtraNonEspanso ? 7 : 9}>
            <Box padding={2}>
              <DataELuogoDiNascita
                nascita={nascita}
                visioneRiassuntiva
              />
              <Indirizzo
                indirizzo={indirizzoResidenza}
                label='Residenza'
              />
            </Box>
          </Grid>

          {contenutoExtraNonEspanso &&
            <Grid item md={2}>
              <Box padding={2}>
                {contenutoExtraNonEspanso}
              </Box>
            </Grid>
          }
        </Grid>
      }

      contenutoEspansoHeader={
        <>
          <Grid item>{header}</Grid>

          {/* Contenuto invisibile per mantenere la stessa altezza
            dell'header sia nel caso di accordion espanso, sia non espanso. */}
          <Box p={2} visibility='hidden'>
            <BaseListaCoppieChiaveValore coppie={{ a: 'a', b: 'b' }} />
          </Box>
        </>
      }
      contenutoEspanso={
        <Grid container spacing={2}>
          <Grid item md={6}>
            <DatiAnagrafici
              cognomeNome={cognomeNome}
              codiceFiscale={codiceFiscale}
              sesso={sesso}
              indirizzoResidenza={indirizzoResidenza}
              nascita={nascita}
              cittadinanza={cittadinanza}
              nascondiCittadinanza={nascondiCittadinanza}
            />
          </Grid>

          <Grid item md={3}>
            <Documento documento={documento} />
          </Grid>

          <Grid item md={3}>
            <Contatti contatti={contattiConPec} />
          </Grid>

          {contenutoExtraEspanso &&
            <Grid item>{contenutoExtraEspanso}</Grid>
          }
        </Grid>
      }

      {...restProps}
    />
  )
}