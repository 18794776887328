import { sample } from 'lodash'

// Se il secondo argomento è un numero = X, l'array viene diviso in X subArray di
// dimensioni tutte uguali (a meno dell'ultimo, che può essere più corto)
// --- dividiInSubArray([1, 2, 3, 4, 5], 3) => [[1, 2], [3, 4], [5]]
// Se il secondo argomento è un array di numeri (che definisce la disposizione dei subArray),
// l'array viene diviso in subArray con le dimensioni specificate nella disposizione
// --- dividiInSubArray([1, 2, 3, 4, 5], [1, 1, 3]) => [[1], [2], [3, 4, 5]]
function dividiInSubArray(array, numeroSubArray_Oppure_DisposizioneSubArray) {
  if (typeof numeroSubArray_Oppure_DisposizioneSubArray === 'number') {
    const numeroSubArray = numeroSubArray_Oppure_DisposizioneSubArray
    const perGroup = Math.ceil(array.length / numeroSubArray)
    return new Array(numeroSubArray)
      .fill('')
      .map((_, i) => array.slice(i * perGroup, (i + 1) * perGroup))
  }

  if (Array.isArray(numeroSubArray_Oppure_DisposizioneSubArray)) {
    const disposizioneSubArray = numeroSubArray_Oppure_DisposizioneSubArray
    let indiceCorrente = 0
    return disposizioneSubArray.map(numeroElementiSubArray => {
      const indiceFineSubArray = Math.min(indiceCorrente + numeroElementiSubArray, array.length)
      const subArray = array.slice(indiceCorrente, indiceFineSubArray)
      indiceCorrente = indiceFineSubArray
      return subArray
    })
  }
}

function trasformaInArray_SeElementoSingolo(variabile) {
  if (variabile === undefined || variabile === null) return []
  return Array.isArray(variabile) ? variabile : [variabile]
}

function ciSonoDuplicati(array) {
  if (!Array.isArray(array)) return false
  const arraySenzaDuplicati = new Set(array)
  return array.length > arraySenzaDuplicati.length
}

function creaArrayRandomizzato(numeroElementi, creaElemento) {
  return new Array(numeroElementi).fill().map(creaElemento)
}

export {
  sample as getRandomElementFrom,
  dividiInSubArray,
  trasformaInArray_SeElementoSingolo,
  ciSonoDuplicati,
  creaArrayRandomizzato
}