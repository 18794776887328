import { cloneDeep, isEqual, mergeWith } from 'lodash'

function haAlmenoUnaChiave(oggetto) {
  if (typeof oggetto !== 'object') return false
  return Object.keys(oggetto).length > 0
}

function deepEquals(oggetto, altroOggetto) {
  return isEqual(oggetto, altroOggetto)
}

function forEachValue(oggetto, funzione) {
  Object.values(oggetto).forEach(funzione)
}

// filterFunction riceve la coppia [chiave, valore]
function filterObject(object, filterFunction) {
  return Object.fromEntries(
    Object.entries(object).filter(filterFunction)
  )
}

// mapFunction riceve la coppia [chiave, valore]
function mapObject(object, mapFunction) {
  return Object.fromEntries(
    Object.entries(object).map(mapFunction)
  )
}

function haAlmenoUnValoreNonVuoto(valoriCampi) {
  return Object.values(valoriCampi)
    .some(valore => valore === 0 || valore === false || Boolean(valore))
}

function cambiaAlcuneChiavi(oggetto, mappaturaChiavi) {
  if (!oggetto || typeof oggetto !== 'object'
    || !mappaturaChiavi || typeof mappaturaChiavi !== 'object'
  ) return

  let oggettoDaRitornare = { ...oggetto }

  Object.entries(mappaturaChiavi).forEach(([chiaveVecchia, chiaveNuova]) => {
    // eslint-disable-next-line no-prototype-builtins
    if (oggetto.hasOwnProperty(chiaveVecchia)) {
      oggettoDaRitornare[chiaveNuova] = oggetto[chiaveVecchia]
      delete oggettoDaRitornare[chiaveVecchia]
    }
  })

  return oggettoDaRitornare
}

// ATTENZIONE: non elimina niente negli oggetti annidati
function eliminaCampiInutili(data, valoreCampoDaScartare) {
  let dataPulito = {}

  Object.entries(data).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      if (!value.includes(valoreCampoDaScartare)) {
        dataPulito[key] = value
      }
    } else {
      if (value !== valoreCampoDaScartare) {
        dataPulito[key] = value
      }
    }
  })

  return dataPulito
}

// Prende un oggetto e appiattisce gli oggetti annidati al primo livello
// { a:1, b: { c:2, d:3 } } => { a:1, c:2, d:3 }
function appiattisciOggettoUnLivello(oggetto) {
  if (!oggetto || typeof oggetto !== 'object') return oggetto

  let oggettoDaRitornare = {}
  Object.entries(oggetto).forEach(([chiave, valore]) => {
    if (typeof valore === 'object' && !Array.isArray(valore)) {
      oggettoDaRitornare = {
        ...oggettoDaRitornare,
        ...valore
      }
    } else {
      oggettoDaRitornare[chiave] = valore
    }
  })

  return oggettoDaRitornare
}

// Prende un oggetto e raggruppa alcune chiavi in sotto-oggetti (gruppi)
// Le chiavi non presenti in nessun gruppo sono mantenute al primo livello
// ({a:1, b:2, c:3}, { ab: ['a', 'b'] }) => { ab:{ a:1, b:2 }, c:3 }
function raggruppaProprieta(oggetto, mappaturaGruppi) {
  if (!oggetto || typeof oggetto !== 'object'
    || !mappaturaGruppi || typeof mappaturaGruppi !== 'object'
  ) return oggetto

  let oggettoDaRitornare = { ...oggetto }
  Object.entries(mappaturaGruppi).forEach(([chiaveGruppo, listaChiaviDaInserire]) => {
    let gruppo = {}
    listaChiaviDaInserire.forEach(chiave => {
      gruppo[chiave] = oggettoDaRitornare[chiave]
      delete oggettoDaRitornare[chiave]
    })
    oggettoDaRitornare[chiaveGruppo] = gruppo
  })

  return oggettoDaRitornare
}

// Funzione comoda per arricchire una prop oggetto, lasciando la 
// possibilità che le aggiunte siano sovrascritte anche dal padre
// Fare lo spread del risultato della funzione DOPO LO SPREAD DELLE PROPS DEL PADRE
// Esempio:
// <BaseIconButtons.Chiudi
//   {...propsBottoneChiudi}
//   tooltipProps={{ disableHoverListener: true, ...propsBottoneChiudi.tooltipProps }}
// />
// DIVENTA:
// <BaseIconButtons.Chiudi
//   {...propsBottoneChiudi}
//   {...arricchisciPropOggetto(propsBottoneChiudi, 'tooltipProps', { disableHoverListener: true })}
// />
function arricchisciPropOggetto(propsProvenientiDalPadre = {}, nomePropOggetto = '', valorePropDaUnire = {}) {
  return {
    [nomePropOggetto]: {
      ...valorePropDaUnire,
      ...propsProvenientiDalPadre[nomePropOggetto]
    }
  }
}

function deepFreeze(object) {
  // Retrieve the property names defined on object
  const propNames = Object.getOwnPropertyNames(object)

  // Freeze properties before freezing self
  for (const name of propNames) {
    const value = object[name]
    if (value && typeof value === 'object') deepFreeze(value)
  }

  return Object.freeze(object)
}

function deepClone(oggetto) {
  return cloneDeep(oggetto)
}

function deepMerge(oggettoDestinazione, oggettoSorgente, opzioni = {}) {
  const {
    // La funzione standard di lodash fa il merge dentro gli array 
    // Questo è un comportamento che crea errori e quindi di default
    // lo disabilito. Gli array vengono sovrascritti completamente
    mergeArray = false
  } = opzioni

  // Faccio una copia per evitare che venga modificato l'oggetto destinazione
  const copiaOggettoDestinazione = cloneDeep(oggettoDestinazione)
  
  // Devo fare qui questo controllo perché la funzione di customizzazione 
  // che uso sotto NON viene chiamata con gli oggetti destinazione e 
  // sorgente iniziali, ma solo con ciascuna proprietà al loro interno
  // Invece voglio evitare il merge di array anche all'inizio
  if (!mergeArray && Array.isArray(copiaOggettoDestinazione)) {
    return oggettoSorgente
  }

  return mergeWith(
    copiaOggettoDestinazione,
    oggettoSorgente,
    // Funzione per customizzare il merge
    (valoreProp_NellaDestinazione, valoreStessaProp_NellaSorgente) => {
      // Impedisco il merge di un array
      if (!mergeArray && Array.isArray(valoreProp_NellaDestinazione)) {
        return valoreStessaProp_NellaSorgente
      }
    }
  )
}

export {
  haAlmenoUnaChiave,
  deepEquals,
  forEachValue,
  filterObject,
  mapObject,
  haAlmenoUnValoreNonVuoto,
  cambiaAlcuneChiavi,
  eliminaCampiInutili,
  appiattisciOggettoUnLivello,
  raggruppaProprieta,
  arricchisciPropOggetto,
  deepFreeze,
  deepClone,
  deepMerge
}