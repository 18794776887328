import { useCallback, useState } from 'react'
import { oneOfType, func, object, string, node, arrayOf, shape } from 'prop-types'
import { Box, Grid, Stepper, Step, StepLabel } from '@mui/material'
import { BaseFeedbackConIcona } from 'feedback'
import { ICONE } from 'icons'
import { BaseButtons } from 'inputs'
import { BaseGridLayout } from 'layout'

const VALIDATE_SHAPE = shape({
  condition: func.isRequired,
  message: string.isRequired
})

BaseWizard.propTypes = {
  steps: arrayOf(shape({
    key: string.isRequired,
    label: string.isRequired,
    Content: func.isRequired,
    validate: oneOfType([VALIDATE_SHAPE, arrayOf(VALIDATE_SHAPE)])
  })),
  FinalContent: func.isRequired,
  propsContent: object,
  finishButtonProps: object,
  contentUnderStepper: node
}

export default function BaseWizard(props) {
  const {
    steps,
    FinalContent,
    propsContent,
    finishButtonProps,
    contentUnderStepper
  } = props

  /*********** Gestione step corrente ***********/

  // Indice dello step corrente
  const [currentStep, setCurrentStep] = useState(0)

  const FIRST_STEP = 0
  const LAST_STEP = steps.length - 1
  const FINAL_CONTENT_STEP = steps.length

  const isFirstStep = (currentStep === FIRST_STEP)
  const isLastStep = (currentStep === LAST_STEP)
  const isFinalContent = (currentStep === FINAL_CONTENT_STEP)

  const prevStep = useCallback(() => {
    setCurrentStep(currentStep => Math.max(FIRST_STEP, currentStep - 1))
  }, [])

  const nextStep = useCallback(() => {
    setCurrentStep(currentStep => Math.min(currentStep + 1, LAST_STEP))
  }, [LAST_STEP])

  const showFinalContent = useCallback(() => {
    setCurrentStep(FINAL_CONTENT_STEP)
  }, [FINAL_CONTENT_STEP])

  const resetWizard = useCallback(() => {
    setCurrentStep(FIRST_STEP)
  }, [])

  /**********************************************/

  /**** CurrentContent e bottoni navigazione ****/

  const propsContentArricchite = {
    wizard: {
      prevStep,
      nextStep,
      showFinalContent,
      resetWizard
    },
    ...propsContent
  }

  let CurrentContent
  let messaggioDaMostrare = ''
  if (isFinalContent) {
    CurrentContent = FinalContent
  } else {
    const { Content, validate = [] } = steps[currentStep]
    CurrentContent = Content
    const validateLista = Array.isArray(validate) ? validate : [validate]
    const primoValidate_ConCondizioneVera = validateLista.find(
      ({ condition }) => condition(propsContentArricchite)
    )
    messaggioDaMostrare = primoValidate_ConCondizioneVera?.message ?? ''
  }

  function calcolaPropsBottoneIndietro() {
    if (isFirstStep) {
      return { sx: { visibility: 'hidden' } }
    }
    
    return {
      testo: 'Indietro',
      onClick: prevStep
    }
  }

  function calcolaPropsBottoneAvanti() {
    if (isFinalContent) {
      return { sx: { visibility: 'hidden' } }
    }

    if (isLastStep) {
      async function onClickUnico(event) {
        const onClickEsterno = finishButtonProps?.onClick
        if (!onClickEsterno) {
          showFinalContent()
          return
        }
        const { ok } = await onClickEsterno(event)
        if (ok) showFinalContent()
      }
      
      return {
        testo: 'Concludi',
        iconaFine: ICONE.CONFERMA,
        color: 'success',
        ...finishButtonProps,
        ...(messaggioDaMostrare ? { disabled: true } : { onClick: onClickUnico })
      }
    }
    
    return {
      testo: 'Avanti',
      ...(messaggioDaMostrare ? { disabled: true } : { onClick: nextStep })
    }
  }

  /**********************************************/

  return (
    <BaseGridLayout vertical noWrap>
      <Box sx={{ width: '100%' }}>
        <Grid container columns={20} justifyContent='space-between'>
          <Grid item xs={4} sx={{ textAlign: 'left' }}>
            <BaseButtons.MenoDettagli {...calcolaPropsBottoneIndietro()} />
          </Grid>

          <Grid item xs={12}>
            <Stepper activeStep={currentStep} alternativeLabel>
              {steps.map(({ key, label }) => (
                <Step key={key}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>

          <Grid item xs={4} sx={{ textAlign: 'right' }}>
            <BaseButtons.Dettagli {...calcolaPropsBottoneAvanti()} />

            {messaggioDaMostrare &&
              <BaseFeedbackConIcona.Errore messaggio={messaggioDaMostrare} noWrap />
            }
          </Grid>
        </Grid>
      </Box>

      {contentUnderStepper}

      <CurrentContent {...propsContentArricchite} />
    </BaseGridLayout>
  )
}